import { FaUsers, FaGraduationCap, FaBriefcase, FaBookReader } from 'react-icons/fa';
import "../style.scss"
import { useTranslation } from "react-i18next";
import DonutChartComponent from '../common/charts/donutChart';
import { Card, Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import MessageInDashboard from '../common/message';
import useGetAllData from 'hooks/useGetAllData';
import TeachersStatistic from './statistic_pages/teachers';
import { useEffect, useState } from 'react';
import ForTitle from 'components/ForTitles';
import { MdPeopleOutline } from 'react-icons/md';
import { GiTeacher } from 'react-icons/gi';
import { GrUserWorker } from 'react-icons/gr';

const RectorDashboard: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();
    const [allStd, setAllStd] = useState<number>(0)
    const [allTeachers, setAllTeachers] = useState<number>(0)

    const _students = useGetAllData({ url: '/statistics/student-count-by-faculty?expand=studentsCountByGender?per-page=0', isCall: 'auto' });
    const teachers = useGetAllData({url: '/users?sort=-id&filter={%22-role_name%22:[%22student%22],%22role_name%22:%22teacher%22}&filter-like={}&per-page=15&page=1', isCall: 'auto'})
    const masters = useGetAllData({url: '/users?sort=-id&filter={%22-role_name%22:[%22student%22]}&filter-like={}&per-page=15&page=1', isCall: 'auto'})

    useEffect(() => {
        (
            async () => {
                if (_students?.data?.items?.length) {
                    let a: number = 0;
                    _students?.data?.items?.forEach(async (item: {
                        id: number,
                        name: string,
                        studentsCount: number
                    }) => {
                        a += item?.studentsCount;
                    })
                    console.log(a);

                    setAllStd(a)
                }
            }
        )()
    }, [_students?.data?.items?.length])

    return (
        <>
            <ForTitle title='Dashboard' buttons={[null]}/>
            <Row className='d-flex justify-content-between'>
                <Col lg={24} sm={24} md={24} xl={6} xs={24}>
                    <div className='forBorder mx-1'>
                        <Card bordered={false} onClick={() => history.push('student_statistic')} style={{ cursor: 'pointer' }}>
                            <p className="text-truncate mb-2">{t("Students")}</p>
                            <h4 className="mb-0">{allStd}</h4>
                            <MdPeopleOutline className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.23% </span>
                            </div> */}
                        </Card>
                        <Card bordered={false} onClick={() => history.push('employee_statistic')} style={{ cursor: 'pointer' }}>
                            <p className="text-truncate mb-2">{t("Teachers")}</p>
                            <h4 className="mb-0">{teachers.data._meta.totalCount}</h4>
                            <GiTeacher className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.03% </span>
                            </div> */}
                        </Card>
                        <Card bordered={false}>
                            <p className="text-truncate mb-2">{t("Masters")}</p>
                            <h4 className="mb-0">{masters.data._meta.totalCount}</h4>
                            <GrUserWorker className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.05% </span>
                            </div> */}
                        </Card>
                    </div>
                </Col>
                <Col lg={24} sm={24} md={24} xl={18} xs={24} className='mb-3'>
                    <Row>
                        <Col lg={24} sm={24} md={24} xl={12}>
                            <Card className='mx-1'>
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            {t('Tarkib')}
                                        </h4>
                                    </div>
                                    <div className="col-12">
                                        <DonutChartComponent />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={24} sm={24} md={24} xl={12}>
                            <Card style={{ height: '100%'}} className='mx-1'>
                                <div className="row">
                                    <div className="col-12">
                                        <h4>
                                            {t('Habarlar')}
                                        </h4>
                                    </div>
                                    <div className="col-12">
                                        <MessageInDashboard/>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col lg={24} sm={24} md={24} xl={24} xs={24}>
                    <Card className='mx-1'>
                        <div className="row">
                            <div className="col-12">
                                <h4>
                                    {t('Teachers and subjects by texnikumes')}
                                </h4>
                            </div>
                            <div className="col-12">
                                <TeachersStatistic setAllTeachers={setAllTeachers}/>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default RectorDashboard;