import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import instance from "config/_axios";




const getAsyncInitialData = createAsyncThunk(
    'data/initial_data',
    async (data: { key: string, url: string, path_prefix?: string, params?: any }) => {
        try {
            const response = await instance({ url: `/${data.url}${data.path_prefix ? '?' + data.path_prefix : ''}`, method: 'GET', params: data.params ? data.params : null });
            if (response.data?.status === 1) {
                return {
                    key: data.key,
                    data: response.data?.data?.items,
                    _meta: response.data?.data?._meta
                }
            } else {
                message.error("Something went wrong while getting region data");
            }
        } catch (error: any) {
            message.error(error?.response?.message);
        }
    }
)

export default getAsyncInitialData;
