import { FC, useEffect, useState } from 'react';
import { Col, Row, Form, Upload, Button, message, Switch, Tooltip, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaPlus, FaQuestion, FaUpload } from 'react-icons/fa';
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import SunEditor from 'suneditor-react';
import BadgeStatus from 'components/BadgeStatus';
import CustomBreadcrumb from 'components/Breadcrumb';
import { FILE_URL } from 'config/utils';
import instance from 'config/_axios';
import isHasAuthRoute from 'utils/isHasRoute';
import { asyncN } from 'utils/notifications';
import {delete_data} from 'services';
import { getAllQuestionOptions, getOneQuestions } from '../servise';
import { optionType } from './types';

const QuestionOption: FC = (): JSX.Element => {
    const { t } = useTranslation()
    const _breadcrump = [
        {
            name: "Home",
            path: '/',
        },
        {
            name: "Questions",
            path: '/questions'
        },
        {
            name: "Create question options",
            path: 'question-options'
        },
    ];
    const [form] = Form.useForm();
    const history = useHistory()
    const state: any = useLocation().state;
    const [fileListOptionFile, setFileListOptionFile] = useState<any>([]);
    const [options, setOptions] = useState<Array<any>>()
    const [question, setQuestion] = useState<any>();
    const [edit, setEdit] = useState<{ id: number, status: boolean }>({ id: 0, status: false })
    const [content, setContent] = useState<string>()
    const [refresh, setRefresh] = useState<boolean>(false);
    const [switches, setSwitches] = useState<{ status: boolean, is_correct: boolean }>({ status: false, is_correct: false })
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isLoading1, setIsLoading1] = useState<boolean>();



    useEffect(() => {
        (
            async () => {
                if (state?.id) {
                    setIsLoading1(true)
                    try {
                        const optionsResponse = await getAllQuestionOptions(state?.id)
                        setOptions(optionsResponse?.items);
                        setIsLoading1(false)
                    } catch (e: any) {
                        asyncN("error", "read", "Read question options error!!!")
                        setIsLoading1(false)
                    }
                } else {
                    history.push("/questions")
                }
            }
        )()
    }, [refresh])

    useEffect(() => {
        (
            async () => {
                try {
                    setIsLoading(true)
                    const response = await getOneQuestions(state?.id);
                    setQuestion(response)
                    setIsLoading(false)

                } catch (e: any) {
                    asyncN("error", "read", "Read question error for question option!!!")
                }
            }
        )()
    }, [state?.id])

    const onChangeOption = ({ fileList: newFileList }: any) => {

        if (Array.isArray(newFileList) && newFileList.length) {
            if (newFileList[0]?.size < 20480000) {
                setFileListOptionFile(newFileList);
            } else {
                message.error("Rasm hajmi 2Mb dan oshmasligi kerak !");
            }
        }
    };

    const dummyRequestOption = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    const onFinish = async (values: any) => {
        let data = new FormData();
        Object.entries(values).forEach(([key, value]: any) => {
            if (key === "option_file") {
                data.append(key, value?.file?.originFileObj)
            } else if (key === "is_correct") {
                data.append(key, value ? "1" : "0")
            } else if (key === "status") {
                data.append(key, value ? "1" : "0")
            } else {
                data?.append(key, value)
            }
        })

        data.append('question_id', state?.id)
        try {
            const response = await instance({
                url: edit?.status ? `/question-options/${edit?.id}` : '/question-options',
                method: edit?.status ? 'put' : 'post',
                data: data
            })
            if (response?.data?.status === 1) {
                asyncN("success", "create", response?.data?.message).then(() => {
                    setRefresh(!refresh)
                    setContent("");
                    setFileListOptionFile([]);
                    setEdit({ id: 0, status: false });
                    form.resetFields()
                    setContent("")
                })
            }
        } catch (e: any) {
            asyncN("error", "update", "create option");
        }


    }

    const downloadFile = (url: string): void => {
        let a = document.createElement('a');
        a.target = "'_blank";
        a.href = FILE_URL + url;
        a.click();
        a.remove();
    }

    return (
        <Row gutter={24}>
            <CustomBreadcrumb arr={_breadcrump} />
            {isLoading ? (
                <div className="loader-line"></div>
            ) : (
                <div style={{ height: "3px" }}></div>
            )}
            <Col className="pt-3" span={24}>
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <label htmlFor="question-text"><strong>{t("Question")}:</strong></label>
                            <div id='question-text' dangerouslySetInnerHTML={{ __html: question?.question }}></div>
                        </Col>
                        <Col lg={20}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field!")
                                    }
                                ]}
                                label={t("Question variant")}
                                name="option"
                            >
                                <SunEditor setContents={content} height={150 + "px"} placeholder="Please input question" setAllPlugins={true} />
                            </Form.Item>
                        </Col>
                        <Col lg={4}>
                            <Row gutter={24}>
                                <Col lg={24}>
                                    <Form.Item
                                        label={t("Option file")}
                                        name='option_file'
                                    >
                                        <Upload
                                            style={{ width: "100%" }}
                                            customRequest={dummyRequestOption}
                                            listType="picture"
                                            fileList={fileListOptionFile}
                                            onChange={onChangeOption}
                                            accept=".pdf,.doc,.docx,.png,.img"
                                            onRemove={() => setFileListOptionFile([])}
                                        >
                                            {fileListOptionFile.length < 1 && <Button type="default"><FaUpload className="me-1" />{t("Upload")}</Button>}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item

                                        label={t("Status")}
                                        name="status"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            checkedChildren="1" unCheckedChildren="0" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item

                                        label={t("Is correct")}
                                        name="is_correct"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            checkedChildren={<FaCheck />}
                                            unCheckedChildren={<FaQuestion />} />
                                    </Form.Item>
                                </Col>
                                <Col lg={12}>

                                    <Button type="primary" htmlType='submit' icon={
                                        !edit?.status && <FaPlus />
                                    }>{!edit?.status ? t("Add") : t("Update")}</Button>
                                </Col>
                                {edit?.status ? <Col lg={12}>

                                    <Button disabled={!isHasAuthRoute("question-option_create")&&isHasAuthRoute("question-option_update")&&!edit?.status} type='default' onClick={() => {
                                        form.resetFields()
                                        setContent("");
                                        setFileListOptionFile([])
                                        setEdit({
                                            id: 0,
                                            status: false
                                        })
                                    }}>{t("Cancel")}</Button>
                                </Col> : ""}
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col span={24}>
                {isLoading1 ? (
                    <div className="loader-line"></div>
                ) : (
                    <div style={{ height: "3px" }}></div>
                )}
                <Table className="table table-striped table-bordred">
                    <Thead>
                        <Tr>
                            <Th>
                                №
                            </Th>
                            <Th>
                                {t("Option")}
                            </Th>
                            <Th>
                                {t("Is correct")}
                            </Th>
                            <Th>
                                {t("File")}
                            </Th>
                            <Th>
                                {t("Status")}
                            </Th>
                            <Th>
                                {t("Actions")}
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            options && options?.map((item: optionType, i: number) =>
                                <Tr key={i}>
                                    <Td>
                                        {
                                            i + 1
                                        }
                                    </Td>
                                    <Td>
                                        <div dangerouslySetInnerHTML={{ __html: item?.option }}>
                                        </div>
                                    </Td>
                                    <Td>
                                        <Switch checkedChildren="1" unCheckedChildren="0" size='small' checked={item?.is_correct === 1} />
                                    </Td>
                                    <Td>
                                        {item?.file ? <Button type='link' onClick={() => {
                                            downloadFile(item?.file)
                                        }}>{("Download")}</Button> : <Button type='link'>😶</Button>}
                                    </Td>
                                    <Td><BadgeStatus status={item?.status} /></Td>
                                    <Td>
                                        <div className="crud_actions_table">

                                            {isHasAuthRoute("question-option_update") && (
                                                <Tooltip placement="top" title={t("Edit")}>
                                                    <TbFilePencil 
                                                        className="edit_btn"
                                                        onClick={() => {
                                                            setContent(item?.option);
                                                            if (item?.file) {
                                                                setFileListOptionFile([
                                                                    {
                                                                        uid: '-1',
                                                                        name: 'Question file',
                                                                        status: 'done',
                                                                        url: FILE_URL + item?.file,
                                                                        thumbUrl: "https://www.seaicons.com/wp-content/uploads/2015/11/document-file-icon.png"
                                                                    }
                                                                ])
                                                            }
                                                            form.setFieldsValue({
                                                                status: item?.status === 1,
                                                                is_correct: item?.is_correct === 1
                                                            })
                                                            setEdit({
                                                                id: item?.id,
                                                                status: true
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {isHasAuthRoute("question-option_delete") && (
                                                <Tooltip placement="top" title={t("Delete")}>
                                                    <Popconfirm
                                                        placement="topLeft"
                                                        title={`${t("Deleted it")} ?`}
                                                        okText={t("Yes")}
                                                        cancelText={t("No")}
                                                        onConfirm={() => delete_data("/question-options", item?.id).then((resp) => {
                                                            setRefresh(!refresh)
                                                        })}
                                                    >
                                                        <TbFileOff className="delete_btn" />
                                                    </Popconfirm>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default QuestionOption