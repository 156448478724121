import { Button, Card, Col, Empty, Pagination, Popconfirm, Row, Select, Tag, Timeline, Tooltip } from 'antd';
import { createRef, FC, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import isHasAuthRoute from 'utils/isHasRoute';
import { asyncN } from 'utils/notifications';
import { questionType } from './crud/types';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { FaPrint, FaFileSignature, FaRegFilePdf } from 'react-icons/fa';
import { delete_data } from 'services';
import ViewQuestion from './crud/view';
import { getAllLanguages } from 'services';
import EmptyTable from 'components/EmptyTable';
import './style.scss'
import DiscriptionStyle from 'components/DescriptionStyle';
import { question_confirm, question_confirm_status } from './confirm_status';
import useGetAllData from 'hooks/useGetAllData';
import { clearObjectFields, getLanguageAsObject, getQuestionStatusesByRole, getTextWithoutAnyStyles } from 'utils/auxiliary_functions';
import instance from 'config/_axios';
import isRole from 'utils/isRole';
import ReactToPrint from 'react-to-print';
import { FILE_URL } from 'config/utils';
import { questionLevel } from 'utils/variables';
import axios from 'axios';
import ForTable from 'components/ForTable';
import { AiOutlineCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import ForTitle from 'components/ForTitles';

const { Option } = Select


const QuestionsExport: FC = (): JSX.Element => {

    const { t } = useTranslation()
    const history = useHistory()
    let questionRef: any = useRef()
    const [loader, setLoader] = useState<boolean>(false)
    const [langs, setLangs] = useState<Array<{ id: number, name: string }>>([])
    const [statuses, setStatuses] = useState<string>('');
    const [languages, setLanguages] = useState<any[]>([]);
    const [questions, setQuestions] = useState<questionType>()
    const [filterByField, setFilterByField] = useState<any>(undefined)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)

    const _subjects = useGetAllData({ url: '/subjects', perPage: 0 });

    useEffect(() => {
        (
            async () => {
                if (filterByField?.subject_id) {
                    try {
                        setLoader(true);
                        const resp = await instance({
                            method: 'GET',
                            url: `/questions?sort=-id&expand=subject,createdBy,updatedBy,course,semestr,questionType,language&per-page=${perPage}&page=${currentPage}&filter=${JSON.stringify(filterByField)}`
                        })
                        if (resp?.data?.status === 1) {                            
                            setQuestions(resp?.data?.data)
                            setLoader(false);
                        } else {
                            asyncN("error", "read", resp?.data?.message)
                            setLoader(false);
                        }
                    } catch (error: any) {
                        asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message)
                        setLoader(false);
                    }
                } else {
                    setQuestions({items: []})
                }
            }
        )()
    }, [filterByField?.subject_id, filterByField?.lang_id, currentPage, perPage])

    useEffect(() => {
        (
            async () => {
                try {
                    const resp = await getAllLanguages();
                    setLanguages(resp?.items || []);
                } catch (e: any) {
                    asyncN("error", "read", "Error for get languages!!!")
                }
            }
        )()
    }, [])

    const _getLanguages = async () => {
        try {
            const resp = await getAllLanguages();
            if (resp?.items) {
                setLangs(resp?.items)
            }
        } catch (e: any) {
            asyncN("error", "read", "Error for get languages!!!")
        }
    }


    useEffect(() => {
        _getLanguages();
    }, [])

    const getLangName = (id: number): string => {
        let a = ""
        if (Array.isArray(langs)) {
            a = langs?.filter((item: { id: number, name: string }) => {
                return item?.id === id && item
            })[0]?.name
        }
        return a
    }

    const handleChange = (name: string, value: number | undefined) => { setFilterByField({ ...filterByField, [name]: value }) }

    const toPrintFunc = (refEl: any) => {
        return refEl;
    }

    const getText = (html: string): string => {
        let div = document.createElement('div');
        div.innerHTML = html;
        return div.innerText;
    }

    return (
        <div>
            <ForTitle title="Questions" buttons={[
                    <ReactToPrint
                        trigger={() => {
                            return <div className='ant-col'>
                                <Button disabled={!filterByField?.subject_id} style={{color: 'white'}} type="primary" className='me-1' icon={<FaPrint size={20} className="pe-1" />}>Chop etish</Button>
                            </div>;
                        }}
                        content={() => {
                            return toPrintFunc(questionRef);
                        }}
                    />
            ]}/>
            <Row gutter={[12, 12]} className="mb-2">
                <Col xl={8}>
                    <Select
                        status={!filterByField?.subject_id ? 'error' : ''}
                        allowClear
                        onClear={() => {
                            setFilterByField(undefined)
                        }}
                        onChange={(e: number) => handleChange('subject_id', e)}
                        onFocus={() => { _subjects.fetch() }}
                        showSearch
                        filterOption={(input: any, option: any) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        className='w-100'
                        placeholder="Filter by subject"
                        loading={_subjects.loading}
                    >
                        {
                            _subjects.items.length ? _subjects.items.map((item: { id: number, name: string }, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>) : null
                        }
                    </Select>
                </Col>
                <Col xl={4}>
                    <Select
                        allowClear
                        onClear={() => {
                            setFilterByField(Object.entries(filterByField).filter(([key, value]: any) => {
                                return key !== "lang_id"
                            }))
                        }}
                        disabled={filterByField?.subject_id === undefined}
                        onChange={(e: number) => handleChange('lang_id', e)}
                        onFocus={_getLanguages}
                        className="w-100"
                        placeholder="Filter by language"
                    >
                        {
                            langs.length ? langs?.map((item: { id: number, name: string }, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>) : null
                        }
                    </Select>
                </Col>
            </Row>
            <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={questions?._meta?.totalCount} loading={loader} datas={questions?.items} theads={
                    [
                        <>{t("Question")}</>,
                        <>{t("Subject")}</>,
                        <>{t("Language")}</>,
                        <>{t("Type")}</>,
                        <>{t("Status")}</>,
                        /* {
                            isRole(['mudir', 'dean', 'edu_admin']) ?
                                <>
                                    <Th className="text-center">{t("Confirmation")}</Th>
                                    <Th className="text-center">{t("Cancel")}</Th>
                                </> : null
                        } */
                        /* <Th className="text-center">{t("Actions")}</Th> */
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            <DiscriptionStyle wrapper={getTextWithoutAnyStyles(this?.question)} col={2}>{this?.question}</DiscriptionStyle>,
                            <div className="question-window-line-clamp">{this?.subject?.name}</div>,
                            <>{getLangName(this?.lang_id)}</>,
                            <>{this?.questionType?.name}</>,
                            <>{question_confirm_status(this?.status)}</>,
                            /* {
                        isRole(['mudir', 'dean', 'edu_admin']) ?
                            <>
                                <Td><Button type="primary" size="small" style={{ fontSize: '12px' }} disabled={question_confirm(this?.status).disabled_confirm} onClick={() => _updateQuestion(this?.id, '1')}>Tasdiqlash</Button></Td>
                                <Td><Button type="primary" size="small" danger style={{ fontSize: '12px' }} disabled={question_confirm(this?.status).disabled_cancel} onClick={() => _updateQuestion(this?.id, '0')} >Bekor qilish</Button></Td>
                            </> : null
                    } */
                            /* <Td>
                        <div className="crud_actions_table">
                            {isHasAuthRoute("question_view") && (
                                <Tooltip placement="top" title={t("View")}>
                                    <TbFileInvoice  onClick={() => {
                                        if (this?.subQuestion?.length) {
                                            history.push(`/question-view/${this?.id}`)
                                        } else {
                                            setVisible(true)
                                            setItem(this)
                                        }
                                    }} className="view_btn"
                                    />
                                </Tooltip>
                            )}
                            {isHasAuthRoute("question_update") && (
                                <Tooltip placement="top" title={t("Edit")}>
                                    <TbFilePencil 
                                        onClick={() => {
                                            history.push({
                                                pathname: "/edit-questions/" + this?.id
                                            })
                                        }}
                                        className="edit_btn"
                                    />
                                </Tooltip>
                            )}
                            {isHasAuthRoute("question_delete") && (
                                <Tooltip placement="top" title={t("Delete")}>
                                    <Popconfirm
                                        placement="topLeft"
                                        title={`${t("Deleted it")} ?`}
                                        okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                                        onConfirm={() => delete_data("/questions", this?.id).then((e: any) => {
                                            setRefetch(!refetch)
                                        })}
                                    >
                                        <TbFileOff className="delete_btn" />
                                    </Popconfirm>
                                </Tooltip>
                            )}
                        </div>
                    </Td> */
                        ]
                    }
                }/>
            <div className="questions-to-print-container" ref={(el: any) => questionRef = el}>
                {
                    questions?.items.length && questions?.items?.map((
                        _question: questionType['items'][0], i: number) =>
                        <div className='mb-3' key={i}>
                            {/* <Card className='mt-4' title={(i + 1) + "-kazus"}>
                                <Row gutter={[12, 0]} className="bg-white">
                                    <Col xl={6} lg={8} md={8} sm={8} xs={12}><span className='info_text_key'>Fan:</span> <span className='info_text_value'>{_question?.subject?.name}</span></Col>
                                    <Col xl={6} lg={8} md={8} sm={8} xs={12}><span className='info_text_key'>Semester:</span> <span className='info_text_value'>{_question?.semestr?.name} - semester</span></Col>
                                    <Col xl={6} lg={8} md={8} sm={8} xs={12}><span className='info_text_key'>{t("Status")}:</span><span>{question_confirm_status(_question?.status)}</span></Col>
                                    <Col xl={6} lg={8} md={8} sm={8} xs={12}><span className='info_text_key'>Kiritilgan:</span><span>{_question?.createdBy?.last_name}&nbsp;{_question?.createdBy?.first_name}</span></Col>
                                </Row>
                            </Card> */}
                            <Card
                                style={{ marginTop: 16 }}
                                type="inner"
                                className='px-0'
                                title={
                                    <span>
                                        {(i + 1)+"-" + _question?.questionType?.name}
                                        <span className='info_text_key ms-2'>Semester:</span> <span className='info_text_value'>{_question?.semestr?.name} - semester</span>
                                        <span className='info_text_key ms-2'>Savol darajasi:</span> <span className='info_text_value'>{questionLevel[_question?.level]}</span>
                                        <span className='info_text_key ms-2'>Tili:</span> <span className='info_text_value'>{getLanguageAsObject(languages)[_question?.lang_id]}</span>
                                        <span className='info_text_key ms-2'>{t("Status")}:</span><span>{question_confirm_status(_question?.status)}</span><br />
                                        <span className='info_text_key ms-2'>Kiritilgan:</span><span>{_question?.createdBy?.last_name}&nbsp;{_question?.createdBy?.first_name}</span>
                                    </span>}
                            >
                                <div className="mt-3" style={{ backgroundColor: '#FDFEFE' }}>
                                    <span className={`question_main_view`} dangerouslySetInnerHTML={{ __html: _question?.question }}></span>
                                    {_question?.file && <div>
                                        {String(_question?.file).endsWith('.png' || ".jpg") ?
                                            <div className='question_img_box'>
                                                <img src={FILE_URL + _question?.file} alt="" />
                                            </div> :
                                            <a href={FILE_URL + _question?.file} download={true} target="_blank" className='rounded py-1 px-3 mt-2 ' style={{ backgroundColor: '#EDF2F9' }} ><FaRegFilePdf className='me-3' /> Savol fayli</a>}
                                    </div>}
                                    <div className='mt-5'>
                                        <Timeline mode='left'>
                                            {
                                                _question?.subQuestion?.length ? _question?.subQuestion.map((element: any, index: number) => {
                                                    return <Timeline.Item key={index}> <span style={{ color: '#1890FF' }}>{index + 1}-savol ({element?.percent}%)</span><br /><span className={`question_main_view`}>{getText(element?.question)}</span></Timeline.Item>
                                                }) : null
                                            }
                                        </Timeline>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default QuestionsExport