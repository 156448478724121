import { FC, useEffect, useState } from "react";
import { Avatar, Button, Col, Form, Image, message, Modal, Row, Upload } from 'antd';
import { FaCloudUploadAlt } from "react-icons/fa";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { FILE_URL } from "config/utils";
import { useAppSelector } from "store/services";
import useGetAllData from "hooks/useGetAllData";
import useGetAll from "hooks/useGetAll";
import useGetOneData from "hooks/useGetOneData";

const ContractUpload: FC<{
    upload?: boolean,
    setAvatar?: any,
    fileType?: 'image/png' | 'image/jpeg' | 'image/*',
    avatar?: any,
    text?: string,
    size: number,
    uploadedFile?: any,
    className?: string,
    fileSize?: number,
    preview?: boolean
}> = ({ fileType = 'image/*', upload, uploadedFile, size = 600, fileSize = 2048000 }) => {
    const user: any = useAppSelector(state => state.auth)
    
    const [form] = Form.useForm()
    const [url, setUrl] = useState<string | undefined>(undefined)
    const [selectedImage, setSelectedImage] = useState('');

    const onFinish = async (values: { image: any }) => {
        try {
            let data = new FormData();
            data.append('photoFile', selectedImage)
            const resp = await instance({
                url: `/tex-contact-files`,
                method: "post",
                data: data
            })
            if (resp?.data?.status === 1) {
                setUrl(FILE_URL + resp.data?.data?.image)
                asyncN("success", 'update', resp?.data?.message)
            } else {
                asyncN("error", 'update' , resp?.data?.message);
            }
        } catch (error: any) {
            asyncN("error", 'update', error?.response ? error?.response?.data?.message : error?.message);
        }

    }

    useEffect(() => {
        (
            async () => {
                const res = await instance({
                    url: '/tex-contact-files',
                    method: "GET"
                })
                if (res.status !== 404) {
                    setUrl(FILE_URL + res.data?.data?.image)
                }
            }
        )()
    }, [])

    return (
        <>
            <Row>
                <Col span={12}>
                    <Form form={form} onFinish={onFinish}>
                        <Form.Item label="Rasmni yuklang" name={"image"}>
                            <Upload
                                maxCount={1}
                                accept={fileType && fileType}
                                multiple={false}
                                showUploadList={false}
                                onChange={(event: any) => {
                                    setSelectedImage(event.file.originFileObj);
                                }}
                                customRequest={(e: any) => {

                                    if (fileSize) {
                                        if (e?.file?.size > fileSize) {
                                            message.error("Fayl o'lchami 200kb dan kam bo'lishi kerak!!!")
                                        } else {
                                            if (uploadedFile) {
                                                uploadedFile(e);
                                            }

                                        }
                                        setUrl(URL.createObjectURL(e?.file))
                                    } else {
                                        if (uploadedFile) {
                                            uploadedFile(e);
                                        }
                                        setUrl(URL.createObjectURL(e?.file))
                                    }
                                }}
                            >
                                <div style={{
                                    fontSize: size / 5 + "px",
                                }}>
                                    <FaCloudUploadAlt color="black" />
                                </div>
                            </Upload>
                        </Form.Item>
                        <div className="ant-col">
                            <Button className="m-1" type="primary" htmlType="submit">Saqlash</Button>
                        </div>
                    </Form>
                </Col>
                <Col span={12}>
                    <img src={url}/>
                </Col>
            </Row>
        </>
    )
};

export default ContractUpload;
