import instance from "config/_axios";
import { ITimeOptions, ITimeTable } from "models/others";
import { excelExport } from "utils/excelExport";


export const exportExcelTimeOption = async (isLoadExport: (value: boolean) => void) => {

    try {

        isLoadExport(true);
        const response = await instance({ url: `/time-options?expand=eduPlan,timeTables.para,timeTables.week,timeTables.subject,timeTables.building,timeTables.room,timeTables.selectedCount,timeTables.eduPlan,timeTables.child.subject,timeTables.child.para,timeTables.child.week,timeTables.child.room,timeTables.child.building,timeTables.child.selectedCount,timeTables.child.eduPlan,timeTables.seminar.subject,timeTables.seminar.para,timeTables.seminar.week,timeTables.seminar.room,timeTables.seminar.building,timeTables.seminar.selectedCount,timeTables.seminar.eduPlan`, method: "GET", params: { "per-page": 0 } });

        if (response.data.status === 1) {

            const items = response.data.data.items as ITimeOptions[];


            if (items.length) {

                let excel_arr: any[] = []
                const pushToArray = (variant_name: string | null, item: ITimeTable) => {
                    excel_arr.push({
                        "variant nomi": variant_name,
                        "ta'lim reja": item.eduPlan?.name,
                        "fan": item.subject?.name,
                        "hafta kuni": item.week?.name,
                        akademik_soat: `${item.para?.start_time}-${item.para?.end_time}`,
                        bino: item.building?.name,
                        xona: item.room?.name,
                        tanlangan: `${item.selectedCount}/${item.room?.capacity}`
                    })
                }


                items.forEach((timeOptionItem) => {

                    if (timeOptionItem.timeTables.length) {

                        timeOptionItem.timeTables.forEach((timeTableItem) => {

                            pushToArray(timeOptionItem.name, timeTableItem)

                            if (timeTableItem.child.length) {

                                timeTableItem.child.forEach((childItem) => pushToArray(timeOptionItem.name, childItem))

                            }

                            if (timeTableItem.seminar.length) {
                                timeTableItem.child.forEach((semenarItem) => pushToArray(timeOptionItem.name, semenarItem))
                            }

                        })

                    }


                })


                excelExport(excel_arr, "TimeOptions");
                isLoadExport(false);

            }
        }


    } catch { }


}
