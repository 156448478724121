import { FC, useEffect, useState } from "react";
import { elementType, examType, subjectCategoryType } from "./type";
import { Table, Tr, Td } from 'react-super-responsive-table'
import { Col, Form, Input, Row, Select, Checkbox, Button, Tooltip } from "antd";
import { getAllSubjectTypes } from "../../subject_type/service";
import { asyncN } from "utils/notifications";
import { FaEdit } from 'react-icons/fa'
import { getAllSubjectCategories } from "../../subject_category/service";
import { getAllExamTypes } from "../../exam_types/service";
import instance from "config/_axios";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const UpdateEduSemestrSubjects: FC<elementType> = ({ visible, edit, item, setElement, element, subjectName, refresh, setRefresh }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [subjectTypes, setSubjectTypes] = useState<any>();
    const [times, setTimes] = useState<any>({});
    const [subjectCategory, setSubjectCategory] = useState<Array<subjectCategoryType>>([]);
    const [allBalsCount, setAllBalsCount] = useState<{ jn_all: number, on_all: number, all: number }>({
        jn_all: 0,
        on_all: 0,
        all: 0
    });
    const [examTypes, setExamTypes] = useState<Array<examType>>([]);
    const [datas, setDatas] = useState<{
        subject_type_id: number,
        credit: number
    }>({
        subject_type_id: 0,
        credit: 0
    });


    const [balls, setBalls] = useState<any>({});
    const [allTimesCount, setAllTimesCount] = useState<number>();

    useEffect(() => {
        (
            async () => {
                try {
                    const response = await getAllSubjectTypes();
                    setSubjectTypes(response?.items);
                    const res1 = await getAllSubjectCategories();
                    let a: Array<subjectCategoryType> = [];
                    let b: any = {};
                    res1?.items?.map((item: subjectCategoryType) => {
                        a.push({
                            id: item?.id,
                            name: item?.name,
                        })
                        b = {
                            ...b, ["item-" + item?.id]: {
                                id: item?.id, status: false
                            }
                        }
                    });
                    setSubjectCategory(a);
                    const res2 = await getAllExamTypes();
                    setExamTypes(res2?.items);
                    let c: any = {};
                    res2?.items?.map((item: examType) => {
                        c = { ...c, ["item1-" + item?.id]: { id: item?.id, status: false } }
                    });
                    const response1 = await instance({
                        method: 'get',
                        url: '/edu-semestr-subjects/' + item?.id + '?expand=eduSemestrExamsTypes,eduSemestrSubjectCategoryTimes,EduSemestrExamType,subject'
                    });
                    const data = response1?.data?.data;
                    setDatas({
                        subject_type_id: data?.subject_type_id ? data?.subject_type_id : "",
                        credit: data?.credit ? data?.credit : ""
                    });

                    form.setFieldsValue({
                        subjectName: data?.subject?.name,
                        subject_type_id: data?.subject_type_id ? data?.subject_type_id : "",
                        credit: data?.credit ? data?.credit : ""

                    });

                    data?.eduSemestrExamsTypes?.map((item: any) => {
                        c = {
                            ...c,
                            ["item1-" + item?.exams_type_id]: {
                                ...c["item1-" + item?.exams_type_id],
                                status: true,
                                value: item?.max_ball
                            }
                        }
                    });
                    data?.eduSemestrSubjectCategoryTimes?.map((item: any) => {
                        b = {
                            ...b,
                            ["item-" + item?.subject_category_id]: {
                                ...b["item-" + item?.subject_category_id],
                                status: true,
                                value: item?.hours
                            }
                        }
                    });
                    setBalls(c);
                    setTimes(b);
                } catch (e: any) {
                    asyncN("error", "read", "error get subject types")
                }
            }
        )()
    }, [visible, item, edit]);

    const setChanges = (e: any): void => {
        setTimes({
            ...times,
            [e.target.name]: {
                ...times[e.target.name],
                status: e.target.checked,
                value: ""
            }
        })
    };

    useEffect(() => {
        countAllBall()
    }, [balls]);

    const countAllBall = async () => {

        let a: number = 0;
        let b: number = 0;
        let c: number = 0;
        examTypes?.map((item, i: number) => {
            if (i < 2) a += parseInt(balls["item1-" + item?.id].value ? balls["item1-" + item?.id].value : "0");
            if (i > 1 && i < 4) b += parseInt(balls["item1-" + item?.id].value ? balls["item1-" + item?.id].value : "0");
            c += parseInt(balls["item1-" + item?.id].value ? balls["item1-" + item?.id].value : "0");
        });

        setAllBalsCount({
            jn_all: a,
            on_all: b,
            all: c
        })

    };

    const setChangesBall = (e: any): void => {
        setBalls({
            ...balls,
            [e.target.name]: {
                ...balls[e.target.name],
                value: "",
                status: e.target.checked
            }
        })
    };
    useEffect(() => {
        countAllTimes()
    }, [times]);


    const countAllTimes = () => {
        let a = 0;
        subjectCategory?.map((item: subjectCategoryType) => {
            if (times["item-" + item.id]?.value) {
                a += parseInt(times["item-" + item.id]?.value)
            }
        });
        setAllTimesCount(a)

    };

    const submit = async () => {
        let d = {};
        for (const [key, value] of Object.entries<any>(times)) {
            if (value?.status) {
                d = { ...d, [value?.id]: value?.value }
            }
        }

        let b = {};
        for (const [key, value] of Object.entries<any>(balls)) {
            if (value?.status) {
                b = { ...b, [value?.id]: value?.value }
            }
        }


        if (allBalsCount.all <= 100) {
            form.validateFields().then(async() => {
                let data = new FormData();
                data.append("SubjectCategory", JSON.stringify(d));
                data.append("subject_type_id", JSON.stringify(datas?.subject_type_id));
                data.append("credit", JSON.stringify(datas?.credit));
                data.append("EduSemestrExamType", JSON.stringify(b));
                try {
                    const response = await instance({
                        method: 'put',
                        url: '/edu-semestr-subjects/' + item?.id,
                        data: data
                    });
                    if (response?.data?.status === 1) {
                        asyncN("success", "update", response?.data?.message);
                        setElement({
                            visible: false,
                            edit: false,
                            item: {}
                        })
                        setRefresh(!refresh)
                    }
                } catch (e: any) {
                    asyncN("error", "update", e?.respose?.data?.message)
                }
            }).catch(er => {

            })

        }

    };

    return (
        <div>
            <Row gutter={24}>
                <Col sm={24} md={24} lg={24} xl={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={24}>
                            <Col sm={6} md={6} lg={6} xl={6}>
                                <Form.Item
                                    name="subjectName"
                                    label={t("Subject name")}
                                >
                                    <Input readOnly={true} />
                                </Form.Item>
                            </Col>

                            <Col sm={6} md={6} lg={6} xl={6}>
                                <Form.Item
                                    name="subject_type_id"
                                    label={t("Subject type")}
                                    rules={[{ required: true, message: t("Fill in the field!") }]}
                                >
                                    <Select onChange={(e: number) => {
                                        setDatas({
                                            ...datas,
                                            subject_type_id: e
                                        })
                                    }} disabled={!edit}>
                                        {
                                            subjectTypes && subjectTypes.map((item: any, i: number) =>
                                                <Option key={i} value={item?.id}>{item?.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col sm={6} md={6} lg={6} xl={6}>
                                <Form.Item
                                    name="credit"
                                    label={t("Credit")}
                                    rules={[{ required: true, message: t("Fill in the field!") }]}
                                >
                                    <Input onChange={(e) => {
                                        setDatas({
                                            ...datas,
                                            credit: parseInt(e?.target?.value)
                                        })
                                    }} readOnly={!edit} type="number" />
                                </Form.Item>
                            </Col>

                            <Col sm={6} md={6} lg={6} xl={6}>
                                <p className="m-1">{t("Total hours")}</p>
                                <Input value={allTimesCount} readOnly={true} type="number" />

                            </Col>
                            <Row>
                                <Col sm={24} md={24} lg={24} xl={24}>
                                    <p className="m-1">{t("Subject hours")}</p>
                                    <Table style={{ width: "100%" }} className="table table-bordered table-striped">
                                        <Tr>
                                            {
                                                subjectCategory && subjectCategory?.map((item: subjectCategoryType, i: number) =>
                                                    <Td key={i}>
                                                        <Checkbox checked={times["item-" + item?.id]?.status}
                                                            disabled={!edit} name={"item-" + item?.id}
                                                            // checked={times["item-"+]?.status}
                                                            onChange={setChanges}
                                                        >{item?.name}</Checkbox>
                                                    </Td>
                                                )
                                            }
                                        </Tr>
                                        <Tr>
                                            {
                                                subjectCategory && subjectCategory?.map((item: subjectCategoryType, i: number) =>
                                                    <Td key={i}>
                                                        <Input
                                                            value={times?.["item-" + item?.id]?.value ? times?.["item-" + item?.id]?.value : ""}
                                                            type="number"
                                                            onChange={async (e: any) => {
                                                                if (parseInt(e?.target?.value) >= 0 && parseInt(e?.target?.value) < 1000) {
                                                                    await setTimes({
                                                                        ...times,
                                                                        ["item-" + item?.id]: {
                                                                            ...times?.["item-" + item?.id],
                                                                            value: parseInt(e?.target?.value)
                                                                        }
                                                                    });

                                                                }
                                                            }} name="lecture"
                                                            disabled={!times["item-" + item?.id]?.status}
                                                            readOnly={!edit} />
                                                    </Td>
                                                )
                                            }
                                        </Tr>
                                    </Table>
                                </Col>
                                <Col sm={24} md={24} lg={24} xl={24}>
                                    <p className="m-1">{t("Distribution of score")}</p>
                                    <Table style={{ width: "100%" }} className="table table-bordered table-striped">

                                        <Tr>
                                            {
                                                examTypes && examTypes?.map((item: examType, i: number) =>
                                                    examTypes.length - 1 > i && <Td key={i}>
                                                        <Checkbox disabled={!edit} name={"item1-" + item?.id}
                                                            checked={balls["item1-" + item?.id]?.status}
                                                            onChange={setChangesBall}
                                                        >{item?.name}</Checkbox>
                                                    </Td>
                                                )
                                            }
                                            <Td>
                                                {("Current control")}
                                            </Td>
                                            <Td>
                                                {("Intermediate control")}
                                            </Td>
                                            {
                                                examTypes && <Td>
                                                    <Checkbox disabled={!edit}
                                                        name={"item1-" + examTypes[examTypes.length - 1]?.id}
                                                        checked={balls["item1-" + examTypes[examTypes.length - 1]?.id]?.status}
                                                        onChange={setChangesBall}
                                                    >{examTypes[examTypes.length - 1]?.name}</Checkbox>
                                                </Td>
                                            }
                                            <Td>
                                                {t("Total score")}
                                            </Td>
                                        </Tr>

                                        <Tr>
                                            {
                                                examTypes && examTypes?.map((item: examType, i: number) =>
                                                    i < examTypes.length - 1 && <Td key={i}>
                                                        <Input type="text" onBlur={countAllBall}
                                                            value={balls["item1-" + item?.id]?.value}
                                                            // onChange={handleChange}
                                                            onChange={(e) => {

                                                                setBalls({
                                                                    ...balls,
                                                                    [e?.target?.name]: {
                                                                        ...balls[e?.target?.name],
                                                                        value: parseInt(e?.target?.value)
                                                                    }
                                                                })
                                                            }}
                                                            name={"item1-" + item?.id}
                                                            disabled={!balls["item1-" + item?.id]?.status}
                                                            readOnly={!edit} />
                                                    </Td>
                                                )
                                            }
                                            <Td>
                                                <Input type="number" value={
                                                    allBalsCount?.jn_all
                                                }
                                                    readOnly={true} name="all" />
                                            </Td>
                                            <Td>
                                                <Input type="number" value={
                                                    allBalsCount?.on_all
                                                }
                                                    readOnly={true} name="all" />
                                            </Td>
                                            {
                                                examTypes && <Td>
                                                    <Input type="text" onBlur={countAllBall}
                                                        value={balls["item1-" + examTypes[examTypes.length - 1]?.id]?.value}
                                                        onChange={(e) => {
                                                            setBalls({
                                                                ...balls,
                                                                [e?.target?.name]: {
                                                                    ...balls[e?.target?.name],
                                                                    value: e?.target?.value ? parseInt(e?.target?.value) : ''
                                                                }
                                                            })
                                                        }}
                                                        name={"item1-" + examTypes[examTypes.length - 1]?.id}
                                                        disabled={!balls["item1-" + examTypes[examTypes.length - 1]?.id]?.status}
                                                        readOnly={!edit} />
                                                </Td>
                                            }
                                            <Td>
                                                <Tooltip title={"Umumiy ball 100dan kichik bo'lishi kerak"} color={"#ff4d4f"} visible={allBalsCount.all > 100}>
                                                    <Input className={allBalsCount.all > 100 ? "text-danger" : ""} type="number" value={
                                                        allBalsCount?.all
                                                    }
                                                        readOnly={true} name="all" />
                                                </Tooltip>
                                            </Td>
                                        </Tr>
                                    </Table>
                                </Col>
                            </Row>
                            {!edit ? <Col xs={24} sm={24} lg={24} xl={24}>
                                <Button className="float-end m-1" type="primary" onClick={() => {
                                    setElement({
                                        ...element,
                                        edit: true
                                    })
                                }
                                }><FaEdit /></Button>
                            </Col> :
                                <Col xs={24} sm={24} lg={24} xl={24}>
                                    <Button className="float-end m-1" type="primary" onClick={submit}>{t("Save")}</Button>
                                    <Button className="float-end m-1"
                                        onClick={() => setElement({ visible: false, edit: false, item: {} })}
                                        type="default">{t("Cancel")}</Button>
                                </Col>}
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    )
};
export default UpdateEduSemestrSubjects
