import { useCallback, useState } from 'react';
import { useHistory, useRouteMatch, match } from 'react-router';
import { useTranslation } from 'react-i18next';
import useGetOneData from "hooks/useGetOneData";
import moment from 'moment';
import { Alert, Button, Card, Col, Collapse, Divider, message, Popconfirm, Row, Spin, Tag, Typography, Image } from 'antd';
import { BiChevronDown } from 'react-icons/bi';
import { CaretRightOutlined } from '@ant-design/icons';
import { EmptySpaceLine } from 'components/SimpleComponents';
import PageContentTitle from 'components/Title';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import UpdateExamStudents from './crud/update';
import isHasAuthRoute from 'utils/isHasRoute';
import "../styles.scss";
import useGetAllData from 'hooks/useGetAllData';
import { getLanguageAsObject } from 'utils/auxiliary_functions';
import { FILE_URL } from 'config/utils';

const { Panel } = Collapse;
const { Text } = Typography;

const appealType = (id: number) => {
    switch (id) {
        case 1:
            return "Asosli"
        case 2:
            return "Asossiz"
        case 3:
            return "Texnik"
        case 4:
            return "Asosli va texnik"
    }
}

const ExamStudentAnswer = () => {

    const { t } = useTranslation();
    const match: match<any> | null = useRouteMatch("/student_exam_detail/:exam_student_id");

    const history = useHistory();
    const student_exam_id = match?.params?.exam_student_id;
    const [fullViewQuestion, setFullViewQuestion] = useState<boolean>(true);
    const [updateModal, setUpdateModal] = useState<boolean>(false);
    const [refetch, setRefetch] = useState<boolean>(false);

    if (!student_exam_id) {
        history.goBack();
    }

    const { data, loading } = useGetOneData({ url: `/exam-students/${student_exam_id}?expand=allBall,appeal,examStudentAnswers.examStudentAnswerSubQuestion,examStudentAnswers.question,student.profile,exam,exam.eduPlan,exam.subjectName`, isCall: 'auto' });
    const languages = useGetAllData({ url: '/languages', isCall: 'auto' })

    const getAnswerBySubQuestionId = useCallback((question_id: number, sub_question_id: number) => {

        const { examStudentAnswers } = data;

        if (Array.isArray(examStudentAnswers) && examStudentAnswers.length) {

            const findAnswerQuestion = examStudentAnswers.find(element => element.id === question_id);

            if (findAnswerQuestion && Object.getOwnPropertyNames(findAnswerQuestion).length) {

                const { examStudentAnswerSubQuestion } = findAnswerQuestion;

                const findAnswer = examStudentAnswerSubQuestion.find((element: any) => element.sub_question_id === sub_question_id);

                if (findAnswer && Object.getOwnPropertyNames(findAnswer).length) {

                    return findAnswer;

                }
            }
        }

        return {};

    }, [data])


    const _deleteOne = async () => {

        if (data?.id) {
            const response = await instance({ url: `/exam-students/${student_exam_id}`, method: "DELETE" });
            if (response?.data?.status === 1) {
                asyncN("success", "delete", "Ma'lumot o'chirildi !");
                history.goBack();
            }
        } else {
            message.error("Id ma'lumoti topilmadi !")
        }
    }





    return (
        <div>
            <Spin tip="Loading..." spinning={loading} >
                <UpdateExamStudents visible={updateModal} setVisible={setUpdateModal} id={data?.id} refetch={refetch} setRefetch={setRefetch} />
                <div className="w-100 d-f justify-content-between mb-3 align-items-center" onClick={e => e.stopPropagation()}>
                    <PageContentTitle title={`${data?.student?.profile?.first_name} ${data?.student?.profile?.last_name} ${data?.student?.profile?.middle_name}`} />
                    <span>
                        {isHasAuthRoute("exam-student_update") ? <Button type="primary" className='me-3' onClick={() => setUpdateModal(true)} >{t('Edit')}</Button> : null}
                        {isHasAuthRoute("exam-student_delete") ? <Popconfirm placement="leftTop" className='mt-3' title={"Talabani o'chirmoqchimisiz !"} onConfirm={_deleteOne} okText="Ha" cancelText="Yo'q">
                            <Button type="primary">{t('Delete')}</Button>
                        </Popconfirm> : null}
                    </span>
                </div>
                <Divider className='mb-3 mt-1'></Divider>
                <div className="site-card-wrapper card p-2 mb-3">
                    <Row gutter={[16, 12]}>
                        <Col span={8}>
                            <Card title={t('Exam')} bordered={true} size="small">
                                {data?.exam?.name || <EmptySpaceLine />}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={t('Education plan')} bordered={true} size="small">
                                {data?.exam?.eduPlan?.name + `  (${getLanguageAsObject(languages.items)[data?.student?.edu_lang_id]})` || <EmptySpaceLine />}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={t('Subject name')} bordered={true} size="small">
                                {data?.exam?.subjectName || <EmptySpaceLine />}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={
                                <div className='d-f justify-content-around'>
                                    <span>{t('Start time')}</span>
                                    <span>{t('Finish time')}</span>
                                    <span>{t('Duration')}</span>
                                </div>
                            } bordered={true} size="small">
                                <div className='d-f justify-content-around'>
                                    <span>{data?.exam?.start}</span>
                                    <span>{data?.exam?.finish}</span>
                                    <span>{data?.exam?.duration ? moment.utc(Number(data?.exam?.duration) * 1000).format("HH:mm:ss") : null}</span>
                                </div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={
                                <div className='d-f justify-content-around'>
                                    <span>{t('Student')}</span>
                                    <span> - </span>
                                    <span>{t('Start')}</span>
                                    <span> - </span>
                                    <span>{t('Finish')}</span>
                                    <span> - </span>
                                    <span>{t('Duration')}</span>
                                </div>
                            } bordered={true} size="small">
                                <div className='student_exam_time_detail'>
                                    <span></span>
                                    <span>{data?.start ? moment(Number(data?.start) * 1000).format("DD-MM-YYYY HH:mm:ss") : null}</span>
                                    <span>{data?.finish ? moment(Number(data?.finish) * 1000).format("DD-MM-YYYY HH:mm:ss") : null}</span>
                                    <span>{data?.duration ? moment(Number(data?.duration) * 1000).format("DD-MM-YYYY HH:mm:ss") : null}</span>
                                </div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={
                                <div className='d-f justify-content-around'>
                                    <span>{t('Created at')}</span>
                                    <span>{t('Updated at')}</span>
                                </div>
                            } bordered={true} size="small">
                                <div className='d-f justify-content-around'>
                                    <span>{data?.exam?.created_at ? moment(data?.created_at * 1000).format("DD-MM-YYYY HH:mm:ss") : <EmptySpaceLine />}</span>
                                    <span>{data?.exam?.created_at ? moment(data?.updated_at * 1000).format("DD-MM-YYYY HH:mm:ss") : <EmptySpaceLine />}</span>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Divider style={{ color: '#020202' }}>Appelation</Divider>
                <Alert
                    message={
                        <span>
                            {data?.appeal?.appeal_text}
                            <Divider orientation='left' orientationMargin="left" >Apelatsiya javobi  <Text type="secondary">({appealType(data?.appeal?.type)})</Text> </Divider>
                            {data?.appeal?.teacher_conclusion}
                        </span>
                    }
                    showIcon={false}
                    type="info"
                    banner
                />
                {
                    Array.isArray(data?.examStudentAnswers) && data?.examStudentAnswers?.length ?
                        data?.examStudentAnswers?.map((element: any, index: number) => {
                            return (
                                <Row gutter={[12, 12]} className="card pb-4" style={{ backgroundColor: 'white' }}>
                                    <Col xl={24} className="pt-3 px-4">
                                        <div className='d-flex justify-content-between'>
                                            <span onClick={() => setFullViewQuestion(!fullViewQuestion)} style={{ color: '#096DE0', fontSize: '14px' }} className="cursor-pointer">{element?.question_type} <BiChevronDown size={24} className={fullViewQuestion ? "rotate_arrow_top" : "rotate_arrow_bottom"} /></span>
                                            <span style={{ color: '#096DE0', fontSize: '14px' }}>Max ball: <strong>{data?.allBall ? data?.allBall + "/" : ""}{element?.max_ball}</strong></span>
                                        </div>
                                        <Divider className='my-2' />
                                        <div onClick={() => setFullViewQuestion(!fullViewQuestion)} className={fullViewQuestion ? 'question_text_area_full' : 'question_text_area'}>
                                            <span dangerouslySetInnerHTML={{ __html: element?.question?.question }}></span>
                                            {element?.question?.question ? <Image src={FILE_URL + `${element?.question?.file}`} className="w-100" /> : null}
                                            <hr />

                                            <span className='question_part_check' dangerouslySetInnerHTML={{ __html: element?.teacher_conclusion }} ></span>

                                        </div>
                                    </Col>
                                    <Col xl={24} className="py-3">
                                        <Collapse
                                            bordered={false}
                                            defaultActiveKey={['0']}
                                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                            className="site-collapse-custom-collapse"
                                        >
                                            {
                                                Array.isArray(element?.question?.subQuestion) && element?.question?.subQuestion.length ?
                                                    element?.question?.subQuestion.map((e: any, i: number) => {
                                                        const options = getAnswerBySubQuestionId(element?.id, e?.id);
                                                        return (
                                                            <Panel
                                                                key={i}
                                                                header={
                                                                    <div className='w-100'>
                                                                        <div className='d-flex w-100'>
                                                                            <span className='info_text_value fs-14'>{i + 1}-{t('Question')}. &nbsp;</span>
                                                                            <span dangerouslySetInnerHTML={{ __html: e?.question }}></span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                className="site-collapse-custom-panel"
                                                            >
                                                                <hr className='mt-1' />
                                                                <span className='question_part_check' dangerouslySetInnerHTML={{ __html: options?.answer }} ></span>
                                                                <hr />
                                                                <span className='d-flex align-items-center' >
                                                                    <Tag color="#E6F4EA" className="cursor-pointer rounded py-1" ><span style={{ color: "#137333" }}>{options?.old_ball ?? options?.ball}  </span></Tag>
                                                                    <span className='question_part_check' dangerouslySetInnerHTML={{ __html: options?.teacher_conclusion }} ></span>
                                                                </span>
                                                                {
                                                                    options.appeal_teacher_conclusion ?
                                                                        <>
                                                                            <Divider className='my-2' orientation='left' orientationMargin="left"> Apelatsiya izohi</Divider>
                                                                            <span className='d-flex align-items-center' >
                                                                                <Tag color="#E6F4EA" className="cursor-pointer rounded py-1" ><span style={{ color: "#137333" }}>{options?.ball}  </span></Tag>
                                                                                <span className='question_part_check' dangerouslySetInnerHTML={{ __html: options?.appeal_teacher_conclusion }} ></span>
                                                                            </span>
                                                                        </> : null
                                                                }
                                                                <div className='text-end'>
                                                                    <span className='info_text_key text-right'>
                                                                        <span className='info_text_value'>{t('Created at')}:&nbsp;</span> {moment(options?.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")} &nbsp;&nbsp;&nbsp;
                                                                        <span className='info_text_value'>{t('Updated at')}:&nbsp;</span> {moment(options?.updated_at * 1000).format("YYYY-MM-DD HH:mm:ss")}</span>
                                                                </div>
                                                            </Panel>
                                                        )
                                                    }) : null
                                            }
                                        </Collapse>
                                    </Col>
                                </Row>
                            )
                        }) : null
                }
            </Spin>
        </div>
    )


}

export default ExamStudentAnswer;


/**
 * @permissions
    exam-student-answer_create
    exam-student-answer_delete
    exam-student-answer_get-question
    exam-student-answer_index
    exam-student-answer_update
    exam-student-answer_view
    exam-student_create
    exam-student_delete
    exam-student_index
    exam-student_update
    exam-student_view
 */
