import store from '../store/index';
import { sidebarState, customContentResponsive, windowSizeChecker } from '../store/structure';

const ui: any = store.getState().ui;

const manageResponsiveScreen = (width: number) => {
    if (width > 1200) {
        store.dispatch(sidebarState({ width: '240px', type: 'lg', side: 'left' }));
        store.dispatch(windowSizeChecker({ sidebar_state: 'lg_sm', width: '240px', type: 'lg' }));
    }
    if (width < 1200 && width > 768) {
        store.dispatch(sidebarState({ width: '50px', type: 'sm', side: 'left' }));
        store.dispatch(windowSizeChecker({ sidebar_state: 'sm_xs', width: '50px', type: 'sm' }));
    }
    if (width < 768) {
        if (ui.sidebar.sidebar_state === 'lg_sm' || ui.sidebar.sidebar_state === 'lg_sm_xs') {
            store.dispatch(sidebarState({ width: '50px', type: 'sm', side: 'left' }));
            store.dispatch(windowSizeChecker({ sidebar_state: 'sm_xs', width: '0px', type: 'xs' }));
        } else if (ui.sidebar.sidebar_state === "sm_xs") {
            store.dispatch(sidebarState({ width: '0px', type: 'xs', side: 'right' }));
        }
    } else {
        store.dispatch(customContentResponsive({ size: 'desktop' }))
    }
    if (width < 576) {
        store.dispatch(customContentResponsive({ size: 'mobile' }))
    }
}


export default manageResponsiveScreen;