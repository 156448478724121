import instance from "config/_axios"
import { asyncN } from "utils/notifications"

export const getAllSubjectTopics = async (prefix?: string, perPage?: number, currentPage?: number) => {
    try {
        const response = await instance({
            url: `/subject-topics?${prefix ? prefix : ''}${prefix ? '&' : ''}per-page=${perPage ? perPage : 0}&page=${currentPage ? currentPage : 1}`,
            method: 'get'
        })
        if (response?.data?.status === 1) {
            return response?.data
        } else {
            asyncN('error', 'read', response?.data?.message)
        }
    } catch (err: any) {
        asyncN('error', 'read', err?.response?.data ? err?.response?.data?.message : "Error for red subject topics!")
    }
}

export const getOneSubjectTopic = async (id: number, prefix?: string) => {
    try {
        const response = await instance({
            url: `/subject-topics/${id}${prefix ? '?' + prefix : ''}`,
            method: 'get'
        })
        if (response?.data?.status === 1) {
            return response?.data
        } else {
            asyncN('error', 'read', response?.data?.message)
        }
    } catch (err: any) {
        asyncN('error', 'read', err?.response?.data ? err?.response?.data?.message : "Error for red subject topics!")
    }
}

export const saveOrEditSubjectTopic = async (data: any, id?: number) => {
    try {
        const response = await instance({
            url: `/subject-topics${id ? '/' + id : ''}`,
            method: `${id ? 'put' : 'post'}`,
            data: data
        })
        if (response?.data?.status === 1) {
            asyncN('success', id ? 'update' : 'create', response?.data?.message)
            return {status:1,id:response?.data?.data?.id}
        } else {
            asyncN('error', id ? 'update' : 'create', response?.data?.message)
        }
    } catch (err: any) {
        asyncN('error', id ? 'update' : 'create', err?.response?.data ? err?.response?.data?.message : "Error for red subject topics!")
    }
}

export const getSubjectIdWithUrl = async (url: string,type:boolean) => {
    let a:Array<string>=url?.split('/');
    
    return type?Number(a[2]):{subjectId:a[2],topicId:a[a?.length-1]}
}

