import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const Semestr: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="semestrs"
                indexTitle="Semestr"
                editTitle="Semestr edit"
                viewTitle="Semestr view"
                createTitle="Semestr create"
                permissions={{
                    view_: "semestr_view",
                    delete_: "semestr_delete",
                    update_: "semestr_update",
                    create_: "semestr_create"
                }}
            />
        </div>
    )
}

export default Semestr;