import { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, Select, Switch } from 'antd';
import { Option } from "antd/es/mentions";
import { useTranslation } from "react-i18next";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { getDepartmentTypes, getOneDepartment } from '../service';
import { getAllUsers } from 'pages/user/servise';


const layout = {
    labelCol: { offset: 0, span: 24 },
    wrapperCol: { offset: 0, span: 24 },
};

const EditDepartment = (props: any) => {
    const [form] = Form.useForm();

    const { i18n, t } = useTranslation();
    const [types, setTypes] = useState<any>({});
    const [createdBy,setCreatedBy]=useState<any>({})
    const [users, setUsers] = useState<Array<{ id: number, first_name: string, last_name: string, role: Array<string> }>>([]);
    const [userAccesses, setUserAccesses] = useState<Array<{ id: number, is_leader: number, user_id: number }>>([]);

    const onFinish = (values: any) => {
        let a = new FormData();
        let status: number = values?.status ? 1 : 0
        a.append(`name[${i18n.language}]`, values[`name[${i18n.language}]`]);
        a.append("parent_id", values?.parent_id);
        a.append("status", "" + status);
        a.append("type", values?.type);
        a.append("user_id", values?.user_id);
        instance({
            method: 'put',
            data: a,
            url: '/departments/' + props?.item?.id
        }).then((r) => {
            asyncN("success", "update", r?.data?.message).then((confirm) => {
                props.setRefresh(!props.refresh);
                props.setVisible(false)
            });
        }).catch((error: any) => {
            asyncN("error", "update", "Xatolik yuz berdi!");
        })
    };

    useEffect(() => {
        (
            async () => {
                const r = await getOneDepartment(props?.item?.id, 'expand=createdBy,userAccess');
                setCreatedBy(r?.createdBy)
                form.setFieldsValue({
                    ...r, status: r?.status === 1,type:r?.type,[`name[${i18n.language}]`]: r?.name
                })
                const resType=await getDepartmentTypes();
                setTypes(resType)
                setUserAccesses(r?.userAccess)
                if(Array.isArray(r?.userAccess)){
                    r?.userAccess?.map((item:{ id: number, is_leader: number, user_id: number })=>{
                        if(item?.is_leader===1){
                            form.setFieldsValue({
                                user_id:item?.user_id
                            })
                        }
                    })
                }
            }
        )()
    }, [props]);

    useEffect(() => {
        (
            async () => {
                const response = await getAllUsers(`filter={"-role_name":["student","test"]}&per-page=0&page=1`);
                setUsers(response?.data?.items)
            }
        )()
    }, [])

    const getUserName=(id:number):string=>{
        let a:{ id: number, first_name: string, last_name: string, role: Array<string> }=users?.filter((item:{ id: number, first_name: string, last_name: string, role: Array<string> })=>item?.id===id)[0]
        return a?.first_name+" "+a?.last_name
    }

    return (
        <>

            <Row >
                <Form
                    {...layout}
                    layout="vertical"
                    form={form}
                    name="advanced_search"
                    className="container"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name={`name[${i18n.language}]`}
                                label={`${t("Name")}(${i18n.language})`}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field!"),
                                    },
                                ]}
                            >
                                <Input placeholder={`${t("Name")}(${i18n.language})`} />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item name="parent_id" label={t("Department")} rules={[{ required: props?.type, message: t("Fill in the field!") }]}>
                                <Select
                                    placeholder={t("Select a department")}
                                    allowClear
                                >
                                    {
                                        props?.items && props?.items.map((item: any, i: number) =>
                                            <Option key={"" + i} value={item?.id}>{item?.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="type" label={t("Tip")} rules={[{ required: true, message: t("Select a tip") }]}>
                                <Select
                                    placeholder={t("Select a tip")}
                                    allowClear
                                >
                                    {
                                        Object.entries(types).map(([key,value]: any, i: number) =>
                                            <Option key={"" + i} value={key}>{value}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="user_id" label={t("Department leader")} rules={[{ required: true, message: t("Select a leader") }]}>
                                <Select
                                    placeholder={t("Select a leader")}
                                    allowClear
                                >
                                    {
                                        userAccesses.map((item:{ id: number, is_leader: number, user_id: number }, i: number) =>
                                            <Option key={"" + i} value={String(item?.user_id)}>{getUserName(item?.user_id)}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="status" valuePropName='checked' label={t("Status")}>
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <strong>{t("Created by")}: </strong>{createdBy?.username}
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                {t("Clear")}
                            </Button>
                            <Button type="primary" htmlType="submit">
                                {t("Save")}
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </Row>
        </>
    )
}

export default EditDepartment
