import { configureStore } from "@reduxjs/toolkit"
import uiSlice from './structure';
import SignInSlice from "./auth";
import dataSlice from "./data";
import studentSlice from "./student";
import examSlice from "./exams";
import defaultsSlice from "./defaults";
import KPISlice from "./key_performance_indicator";
import dormitorySlice from "./dormitory/indext";
import sidebarSlice from "./structure/newSidebar";
import timeTableSlice from "./time_table";



const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        sidebar: sidebarSlice.reducer,
        auth: SignInSlice.reducer,
        data: dataSlice.reducer,
        student: studentSlice.reducer,
        exam: examSlice.reducer,
        defaults: defaultsSlice.reducer,
        kpi: KPISlice.reducer,
        dormitory: dormitorySlice.reducer,
        time_table: timeTableSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;

export default store;