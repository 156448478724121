import { FC } from "react"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table"
import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
import { getAllParas } from "./service";
import { asyncN } from "utils/notifications";
import EmptyTable from "components/EmptyTable";
import ParaView from "./crud/view";
import EditPara from "./crud/edit";
import useDebounce from "hooks/useDebounce";
import Actions from "components/Actions";
import CreatePara from "./crud/create";
import { ButtonCreate } from "components/Buttons";
import TotalCount from "components/totalCountTag";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";

const Para: FC = (): JSX.Element => {

    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [reRequest, setReRequest] = useState<boolean>(false);
    const [tableOption, setTableOption] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(15)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [text, setText] = useState<string>('')

    const debouncedValue = useDebounce<string>(text, 500)

    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true)
                    const response = await getAllParas(perPage, currentPage, `expand=description&q=${debouncedValue}`);
                    setData(response?.items)
                    setTableOption(response?._meta)
                    setLoading(false);
                } catch (error: any) {
                    asyncN('error', 'read', error?.response?.message)
                }
            }
        )()
    }, [i18n.language, reRequest, perPage, currentPage, debouncedValue])

    return (
        <div>
            <ForTitle title="Para" buttons={[isHasAuthRoute('para_create') && <ButtonCreate onClick={() => setVisibleCreate(true)} />]}/>
            { visibleView && id ? <ParaView visible={visibleView} setVisible={setVisibleView} id={id} /> : null}
            { visibleEdit && id ? <EditPara visible={visibleEdit} setVisible={setVisibleEdit} id={id} setReRequest={setReRequest} reRequest={reRequest} /> : null}
            { visibleCreate ? <CreatePara visible={visibleCreate} setVisible={setVisibleCreate} setReFetch={setReRequest}/>  : null}
            {/* <Row gutter={[12, 8]}>
                <Col xl={{ span: 6, offset: 18 }} lg={{ span: 8, offset: 16 }} md={{ span: 10, offset: 14 }} sm={{ span: 14, offset: 10 }} xs={{ span: 24 }}>
                    <Search placeholder={`${t("Input search text")} ...`} value={text} onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)} />
                </Col>
            </Row> */}
            <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={tableOption?.totalCount} loading={loading} datas={data} theads={
                    [
                        <>{t("Name")}</>,
                        <>{t("Start time")}</>,
                        <>{t("End time")}</>,
                        <>{t("Description")}</>,
                        <>{t("Status")}</>,
                        <>{t("Actions")}</>
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            <>{this.name}</>,
                            <>{this?.start_time}</>,
                            <>{this?.end_time}</>,
                            <>{this?.description}</>,
                            <><BadgeStatus status={this.status} /></>,
                            <>
                            <Actions
                                    id={this?.id}
                                    url={'paras'}
                                    setId={setId}
                                    setReFetch={setReRequest}
                                    setVisibleView={setVisibleView}
                                    setVisibleEdit={setVisibleEdit}
                                    viewPermission= {"para_view"}
                                    editPermission= {"para_update"}
                                    deletePermission= {"para_delete"}
                                />
                            </>
                            ]
                    }
                }/>
        </div>
    )
}

export default Para;