import { message } from "antd";
import instance from "config/_axios";
import { ITimeTable, ITimeTableParentNull } from "models/others";
import { useEffect } from "react";
import store from "store";
import { TIMETABLE_ACTIONS } from "store/time_table";
import isHasAuthRoute from "utils/isHasRoute";

export const getOneTimeTable = async (id: number | null, path_prefix?: string) => {
    try {
        if (!id) {
            return new Error("Id bo'yicha ma'lumot olinmadi !");
        }

        const response = await instance({ url: `/time-tables/${id}${path_prefix ? "?" + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllTimeTables = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/time-tables${path_prefix ? "?" + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const updateTimeTable = (item: ITimeTableParentNull | ITimeTable, paraItem?: any, weekItem?: any) => {

    store.dispatch(TIMETABLE_ACTIONS.openModal({
        modal: 'update',
        selected: { ...item, week_name: weekItem?.name ?? '', para_name: `${paraItem?.start_time} - ${paraItem?.end_time}` }
    }))
}


export const createTimeTable = (item: ITimeTableParentNull | ITimeTable) => {
    if (isHasAuthRoute('time-table_create')) {
        if (!item.parent_id) {
            store.dispatch(TIMETABLE_ACTIONS.childCreate({ parent: { ...item, week_name: item.week?.name ?? '', para_name: `${item?.para?.start_time} - ${item?.para?.end_time}` } }))
        }
    }
}

export const createChildTimeTable = (item: ITimeTableParentNull | ITimeTable) => {
    console.log("time table item -> ", item)
    if (isHasAuthRoute('time-table_create')) {
        if (!item.parent_id) {
            store.dispatch(TIMETABLE_ACTIONS.childCreate({ parent: { ...item, week_name: item.week?.name ?? '', para_name: `${item?.para?.start_time} - ${item?.para?.end_time}` } }))
        }
    }
}

export const getAllEduPlansForTimeTable = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/edu-plans${path_prefix ? '?' + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export const getAllRoomsForTimeTable = async (path_prefix?: string, formdata?: any) => {
    try {
        const response = await instance({ url: `/rooms/free${path_prefix ? '?' + path_prefix : ''}`, method: "POST", data: formdata });
        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllParaForTimaTable = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/paras${path_prefix ? "?" + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllSubjectsForTimaTable = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/edu-semestr-subjects${path_prefix ? "?" + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllLangsForTimaTable = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/languages${path_prefix ? "?" + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllTeacherAccForTimaTable = async (path_prefix?: string, data?: any) => {
    try {
        const response = await instance({ url: `/teacher-accesses/free${path_prefix ? "?" + path_prefix : ''}`, method: "GET", params: data });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.resonse?.message);
    }
}

export const getAllSubCategoriesForTimaTable = async (path_prefix?: string) => {
    try {
        const response = await instance({ url: `/subject-categories${path_prefix ? "?" + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error("klkl", error?.message);
    }
}


export const getAllTimeTableParenIdNull = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/time-tables/parent-null${path_prefix ? "?" + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}