import { InformerDRDT } from "models/base.models";


/**
 * @ default response data structure
 */
export const DEFAULT_RD: InformerDRDT = {
    items: [],
    _meta: {
        currentPage: 0,
        pageCount: 0,
        perPage: 0,
        totalCount: 0,
    },
    _links: {
        first: { href: '' },
        last: { href: '' },
        self: { href: '' },
    }
}