import React from "react";
import { Row, Col, Form, FormInstance, Input, Button } from "antd"
import FormLabelCrud from "components/FormLabel/FormLabelCrud"
import InputMask from 'react-input-mask';
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom";

type PropsTypeParentsInfo = {
    form: FormInstance<any>,
    onSubmit?: (values: Record<string | number, any>) => void
}

const Contract_info: React.FC<PropsTypeParentsInfo> = ({ form, onSubmit }) => {

    const { t } = useTranslation();
    const location = useLocation();



    return (
        <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
        >
            <Row gutter={[12, 0]}>
            <Col span={12}>
                <Form.Item
                  label={<FormLabelCrud>{`Contract number`}</FormLabelCrud>}
                  name={`contract_number`}
                  rules={[{ required: false, message: t("Fill in the field!") }]}
                >
                  <InputMask mask="" className='ant-input' placeholder='0123456789' />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                  label={<FormLabelCrud>{`Contract id`}</FormLabelCrud>}
                  name={`contract_id`}
                  rules={[{ required: false, message: t("Fill in the field!") }]}
                >
                  <InputMask mask="" className='ant-input' placeholder='0123456789' />
                </Form.Item>
            </Col>
        </Row>
            <div className="text-end">
                <Button type="primary" htmlType='submit' className='px-4' disabled={!location.pathname.includes('/student_update')}  >{t("Save")}</Button>
            </div>
        </Form>
    )

}


export default Contract_info;
