import {
  Alert,
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Space,
  Spin,
  Tag,
  Timeline,
  Tooltip,
} from "antd";
import BadgeStatus from "components/BadgeStatus";
import CustomBreadcrumb from "components/Breadcrumb";
import { FILE_URL } from "config/utils";
import instance from "config/_axios";
import { _meta } from "pages/user/components/types";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from 'antd'
import {
  FaFile,
  FaFilm,
  FaImage,
  FaMusic,
  FaTextWidth,
  FaDownload,
  FaPlusCircle,
  FaEdit,
  FaCheck,
} from "react-icons/fa";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import { getAllSubjectContents, getSubjectContentTypes } from "./service";
import { SubjectContentType } from "./types";
import styles from "./components/style.module.scss";
import wordImg from '../../assets/images/files_images/word.png'
import pptImg from '../../assets/images/files_images/ppt.png'
import xlsImg from '../../assets/images/files_images/xls.jpg'
import zipImg from '../../assets/images/files_images/zip11.png'
import AudioPlayer from "./components/audioPlayer";
import { RiListSettingsLine } from "react-icons/ri";
import { BsArrowDown, BsArrowUp, BsCheck2 } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { useAppSelector } from "store/services";
import useGetAllData from "hooks/useGetAllData";
import TopicTitle from "./components/topic_title/topic_title";
import { subjectTopicReferenceType } from "./components/topic_title/types";
import BasicLiterature from "./components/topic_title/basic_literature";
import AdditionalLiterature from "./components/topic_title/additional_literature";
import QuestionForDiscussion from "./components/topic_title/questions_for_discussion";
import CaseOrQuestion from "./components/topic_title/case_study_or_problem_question_for_discussion";
import { delete_data } from "services";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { useQueryParams } from "pages/subjects/service/useQueryParams";
import ForTable from "components/ForTable";
import { ButtonCreate } from "components/Buttons";
import CreateButton from "components/CreateButton";
import useUrlQueryParams from "hooks/useUrlQueryParams";


const SubjectContent: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { writeToUrl } = useUrlQueryParams({});
  const [form] = Form.useForm();
  const auth: any = useAppSelector(state => state.auth);
  const params: any = useRouteMatch("/subjects/:subjectId/:teacherId/topics/:topicId/contents")?.params
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subjectContents, setSubjectContents] = useState<
    Array<SubjectContentType>
  >([]);
  const [canSort, setCanSort] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false);
  const [contentType, setContentType] = useState<any>();
  const [activeFilter, setActiveFilter] = useState<number>(0);
  const [played, setPlayed] = useState<number>(-1);
  const role: any = auth?.user?.role;
  const [contentCheckModal, setContentCheckModal] = useState<boolean>(false);
  const [topic, setTopic] = useState<{ id: number, name: string, subject: { id: number, name: string }, subjectCategory: { id: number, name: string } }>();
  const [refreshRef, setRefreshRef] = useState<boolean>(false)
  const [refreshMark, setRefreshMark] = useState<boolean>(false)
  const [contentMarks, setContentMark] = useState<any>();
  const [referenceModal, setSetReferenceModal] = useState<{ open: boolean, type: number | null, item?: subjectTopicReferenceType }>({ open: false, type: null })
  const [sort, setSort] = useState<{ isSort: boolean, sortedList: any }>({ isSort: false, sortedList: {} });
  const { lang_id, taid, set } = useQueryParams();
  const _breadcrump = set === undefined ? auth?.user?.role?.length === 1 && auth?.user?.role[0] === "teacher" ? [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Subjects",
      path: "subjects",
    },
    {
      name: "Subject topics",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
    },
    {
      name: "Subject contents",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/contents`,
    },
  ] : [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Subjects",
      path: "subjects",
    },
    {
      name: "Subject teachers",
      path: `/subjects/${params?.subjectId}/teachers`,
    },
    {
      name: "Subject topics",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
    },
    {
      name: "Subject contents",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/contents`,
    },
  ] : [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Contenter subjects",
      path: "/subjects-content-checking",
    },
    {
      name: "Contenter subjects teachers",
      path: `/subjects/${params?.subjectId}/teachers?set=0`,
    },
    {
      name: "Subject topics",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics?lang_id=${lang_id}&taid=${taid}&set=0`,
    },
    {
      name: "Subject contents",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/contents?lang_id=${lang_id}&taid=${taid}`,
    },
  ];

  useEffect(() => {
    if(role.includes('contenter')) {
      writeToUrl({name: 'set', value: '0'})
    }
    else {
      setCanSort(true)
    }
  }, [auth])

  const referenceTypeText: any = {
    1: "Asosiy maqsad va vazifalar, talabalar qanday bilim va ko‘nikmaga ega bo‘lishi kutilmoqda.",
    3: "O‘qilishi tavsiya etiladigan qo‘shimcha adabiyotlar.",
    4: "Muhokama uchun savollar: ",
    5: "Muhokama uchun kazus yoki muammoli savol:"
  }

  const _teacherReference = useGetAllData({
    url: `/subject-topic-references?sort=-id&filter={"subject_topic_id":${params?.topicId},"user_id":${params?.teacherId}}`,
    isCall: 'auto',
    refetch: [refreshRef]
  })

  useEffect(() => {
    (
      async () => {
        if (Number(set) === 0) {
          try {
            const resp = await instance({
              url: `/subject-content-marks?filter={"userId":${params?.teacherId},"subject_topic_id":${params?.topicId},"lang_id":${lang_id},"teacher_access_id":${taid}}`,
              method: 'GET'
            })
            if (resp?.data?.status === 1) {
              setContentMark(resp?.data?.data?.items[0])
            } else {
              asyncN("error", "read", resp?.data?.message)
            }
          } catch (error: any) {
            asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message)
          }
        }
      }
    )()
  }, [refreshMark, set])

  const saveSortesList = async () => {
    try {
      let data = new FormData();
      data.append("subject_topic_id", params?.topicId)
      data.append("orders", JSON.stringify(sort?.sortedList))
      const resp = await instance({
        method: 'post',
        url: '/subject-contents/order',
        data: data
      })
      if (resp?.data?.status === 1) {
        asyncN("success", "create", resp?.data?.message).then(() => {
          setRefresh(!refresh);
          setSort({
            isSort: false,
            sortedList: {}
          })
        })
      } else {
        asyncN("error", "create", resp?.data?.message)
      }
    } catch (error: any) {
      asyncN("error", "create", error?.response ? error?.response?.data?.message : error?.message)
    }
  }

  useEffect(() => {
    (async () => {
      if (params?.topicId) {
        setIsLoading(true);
        const response = await getAllSubjectContents(
          `expand=subjectTopic,subject,lang,updatedBy,createdBy&sort=order&filter={"subject_topic_id":${params?.topicId}${auth?.user?.role?.length == 1 && auth?.user?.role[0] === "teacher" ? '' : ',"user_id":' + params?.teacherId}${activeFilter !== 0 && activeFilter !== 6 ? ',"type":' + activeFilter : ''}}`,
          0,
          1
        );
        setSubjectContents(response?.items);
        setIsLoading(false);
      }
    })();
  }, [refresh, activeFilter, params?.topicId]);

  useEffect(() => {
    (
      async () => {
        if (params?.topicId) {
          try {
            const resp = await instance({
              method: 'get',
              url: `subject-topics/${params?.topicId}?expand=subject,subjectCategory`
            })
            if (resp?.data?.status == 1) {
              setTopic(resp?.data?.data)
            } else {
              asyncN("error", "read", resp?.data?.message);
            }
          } catch (err: any) {
            asyncN("error", "read", err?.response ? err?.response?.data?.message : err?.message);
          }
        }
      }
    )()
  }, [params?.topicId])

  const deleteItem = async (id: number) => {
    try {
      const resp = await instance({
        url: "/subject-contents/" + id,
        method: "delete",
      });
      if (resp?.data?.status === 1) {
        asyncN("success", "delete", resp?.data?.message);
        setRefresh(!refresh);
      } else {
        asyncN("error", "delete", resp?.data?.message);
      }
    } catch (err: any) {
      asyncN(
        "error",
        "delete",
        err?.response?.data
          ? err?.response?.data?.message
          : "Error delete subject topic!"
      );
    }
  };

  const isFileReadInPageByType = (url: string) => {
    let a: Array<string> = url.split(".");
    return a[a.length - 1];
  };

  useEffect(() => {
    (async () => {
      const resp = await getSubjectContentTypes();
      setContentType(resp);
    })();
  }, []);

  const filterTypes = (typeId: number) => {
    setActiveFilter(typeId);
  };

  const getImgThumb = (url: string): any => {
    let parts: Array<string> = url?.split(".");
    let ext: string = parts[parts?.length - 1];
    switch (ext) {
      case "doc": return wordImg;
      case "docx": return wordImg;
      case "xls": return xlsImg;
      case "xlsx": return xlsImg;
      case "zip": return zipImg;
      case "ppt": return pptImg;
      case "pptx": return pptImg;
      default: return null
    }
  }

  const hasLinkInDescription = (desc: string) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return desc.replace(urlRegex, function (url: string) {
      return '<a target="blank" href="' + url + '">' + url + '</a>';
    })
  }

  const getLibraryPagesCount = (type: number): number => {
    let a: number = 0;
    _teacherReference?.items?.forEach((item: subjectTopicReferenceType) => {
      if (type == item?.type) {
        a += (item?.end_page - item?.start_page);
      }

    })
    return a;
  }

  const contentFilter = (): Array<SubjectContentType> => {
    if (activeFilter === 6) {
      return subjectContents?.filter((item: SubjectContentType) => item.file_url?.endsWith(".ppt") || item.file_url?.endsWith(".pptx"))
    } else if (activeFilter === 2) {
      return subjectContents?.filter((item: SubjectContentType) => !(item.file_url?.endsWith(".ppt") || item.file_url?.endsWith(".pptx")))
    } else {
      return subjectContents
    }
  }
  

  const fileView = (typeId: number, item: SubjectContentType) => {
    let type = contentType[`${typeId}`]
    switch (type[0]) {
      case "TEXT": return item?.content && item?.content !== "undefined" ? (
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: item?.content }}
          />
          {item?.createdBy || item?.updatedBy ? <div className="content-footer d-f flex-wrap justify-content-end">
            <div className="w-100">
              <hr />
            </div>
            {item?.createdBy ? <Row className="bg-white">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Kiritilgan:</span><span>{item?.createdBy?.last_name}&nbsp;{item?.createdBy?.first_name}</span></Col>
            </Row> : ''}
            {item?.updatedBy ? <Row className="bg-white ms-2">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Yangilangan:</span><span>{item?.updatedBy?.last_name}&nbsp;{item?.updatedBy?.first_name}</span></Col>
            </Row> : ''}
          </div> : ''}
        </div>
      ) : <div>
        {t("Ma'ruza matni mavjud emas")}
      </div>
      case "FILE": return item?.file_url && (
        <>
          {
            isFileReadInPageByType(item?.file_url) === "pdf" ? (
              <>
                <div className="d-flex justify-content-center">
                  <iframe
                    src={FILE_URL + item?.file_url}
                    style={{
                      justifyContent: "center",
                      width: "70%",
                      height: "70vh",
                    }}
                  ></iframe>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <strong>{t("Description")}: </strong>
                  <span>{item?.description ? <span dangerouslySetInnerHTML={{ __html: hasLinkInDescription(item?.description) }} /> : ''}</span>
                </div>
                {item?.createdBy || item?.updatedBy ? <div className="content-footer d-f flex-wrap justify-content-end">
                  <div className="w-100">
                    <hr />
                  </div>
                  {item?.createdBy ? <Row className="bg-white">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Kiritilgan:</span><span>{item?.createdBy?.last_name}&nbsp;{item?.createdBy?.first_name}</span></Col>
                  </Row> : ''}
                  {item?.updatedBy ? <Row className="bg-white ms-2">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Yangilangan:</span><span>{item?.updatedBy?.last_name}&nbsp;{item?.updatedBy?.first_name}</span></Col>
                  </Row> : ''}
                </div> : ''}
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center flex-wrap">
                  <div className="text-center w-100">
                    {item?.file_url ? <iframe style={{ width: "70%", height: "70vh" }} src={`https://view.officeapps.live.com/op/embed.aspx?src=${FILE_URL + item?.file_url}`}></iframe> : <img width="250px" src={getImgThumb(item?.file_url)} alt="" />}
                  </div>
                  <div>
                    <a
                      href={FILE_URL + item?.file_url}
                      target="_blank"
                    >
                      <FaDownload className="me-2" />{" "}
                      {t("Download file")}
                    </a>
                    {/* <Button onClick={() => setDocViewer({ open: true, url: FILE_URL + item?.file_url })} icon={<TbFileInvoice  />} type="link"></Button> */}
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <strong className="me-1">
                    {t("Description")}: {''}
                  </strong>
                  <span>{item?.description ? <span dangerouslySetInnerHTML={{ __html: hasLinkInDescription(item?.description) }} /> : t('Tavsif mavjud emas')}</span>
                </div>
                {item?.createdBy || item?.updatedBy ? <div className="content-footer d-f flex-wrap justify-content-end">
                  <div className="w-100">
                    <hr />
                  </div>
                  {item?.createdBy ? <Row className="bg-white">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Kiritilgan:</span><span>{item?.createdBy?.last_name}&nbsp;{item?.createdBy?.first_name}</span></Col>
                  </Row> : ''}
                  {item?.updatedBy ? <Row className="bg-white ms-2">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Yangilangan:</span><span>{item?.updatedBy?.last_name}&nbsp;{item?.updatedBy?.first_name}</span></Col>
                  </Row> : ''}
                </div> : ''}
              </>
            )}
        </>
      );
      case "IMAGE": return <>
        <div className="d-flex justify-content-center">
          <img width={"70%"} src={FILE_URL + item?.file_url} />
        </div>
        <div className="d-flex justify-content-center mt-3">
          <strong>{t("Description")}: </strong>
          <span>{item?.description ? <span dangerouslySetInnerHTML={{ __html: hasLinkInDescription(item?.description) }} /> : ''}</span>
        </div>
        {item?.createdBy || item?.updatedBy ? <div className="content-footer d-f flex-wrap justify-content-end">
          <div className="w-100">
            <hr />
          </div>
          {item?.createdBy ? <Row className="bg-white">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Kiritilgan:</span><span>{item?.createdBy?.last_name}&nbsp;{item?.createdBy?.first_name}</span></Col>
          </Row> : ''}
          {item?.updatedBy ? <Row className="bg-white ms-2">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Yangilangan:</span><span>{item?.updatedBy?.last_name}&nbsp;{item?.updatedBy?.first_name}</span></Col>
          </Row> : ''}
        </div> : ''}
      </>;
      case "VIDEO": return <>
        <div className="d-flex justify-content-center">
          <AudioPlayer type="VIDEO" id={item?.id} played={played} setPlayed={setPlayed} drums={FILE_URL + item?.file_url} />
        </div>
        <div className="d-flex justify-content-center mt-3">
          <strong>{t("Description")}: </strong>
          <span>{item?.description ? <span dangerouslySetInnerHTML={{ __html: hasLinkInDescription(item?.description) }} /> : ''}</span>
        </div>
        {item?.createdBy || item?.updatedBy ? <div className="content-footer d-f flex-wrap justify-content-end">
          <div className="w-100">
            <hr />
          </div>
          {item?.createdBy ? <Row className="bg-white">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Kiritilgan:</span><span>{item?.createdBy?.last_name}&nbsp;{item?.createdBy?.first_name}</span></Col>
          </Row> : ''}
          {item?.updatedBy ? <Row className="bg-white ms-2">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Yangilangan:</span><span>{item?.updatedBy?.last_name}&nbsp;{item?.updatedBy?.first_name}</span></Col>
          </Row> : ''}
        </div> : ''}
      </>;
      case "AUDIO": return <>
        <div className="d-flex justify-content-center">
          {/* <audio controls onPlay={(e) => {
            setPlayed(item?.id)
            // if (playedRef.current) {
            //   playedRef.current?.pause();
            //   playedRef = e;
            // } else playedRef = e;

          }} onPause={() => setPlayed(-1)} autoPlay={played===item?.id} src={FILE_URL + item?.file_url} /> */}
          <AudioPlayer type="AUDIO" id={item?.id} played={played} setPlayed={setPlayed} drums={FILE_URL + item?.file_url} />
        </div>
        <div className="d-flex justify-content-center mt-3">
          <strong>{t("Description")}: </strong>
          <span>{item?.description ? <span dangerouslySetInnerHTML={{ __html: hasLinkInDescription(item?.description) }} /> : ''}</span>
        </div>
        {item?.createdBy || item?.updatedBy ? <div className="content-footer d-f flex-wrap justify-content-end">
          <div className="w-100">
            <hr />
          </div>
          {item?.createdBy ? <Row className="bg-white">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Kiritilgan:</span><span>{item?.createdBy?.last_name}&nbsp;{item?.createdBy?.first_name}</span></Col>
          </Row> : ''}
          {item?.updatedBy ? <Row className="bg-white ms-2">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}><span className='info_text_key'>Yangilangan:</span><span>{item?.updatedBy?.last_name}&nbsp;{item?.updatedBy?.first_name}</span></Col>
          </Row> : ''}
        </div> : ''}
      </>;
    }
  }

  const sortContent = async (type: "FIRST_SORT" | "LIST_SORT", oldIndex?: number, newIndex?: number) => {
    if (type === "FIRST_SORT") {
      if (sort?.isSort) {
        setSort({
          isSort: false,
          sortedList: sort?.sortedList
        })
      } else {
        let list: { [index: number]: number } = {}
        subjectContents?.forEach((item: SubjectContentType, i: number) => {
          list = { ...list, [item?.id]: i + 1 }
        })
        setSort({
          isSort: true,
          sortedList: list
        })
      }
    } else {
      if (oldIndex != undefined && newIndex != undefined) {
        setSort((prev: { isSort: boolean, sortedList: { [index: number]: number } }) => {
          let a: number;
          let data: { [index: number]: number } = prev?.sortedList
          a = data[subjectContents[oldIndex].id];
          data[subjectContents[oldIndex].id] = data[subjectContents[newIndex].id];
          data[subjectContents[newIndex].id] = a;
          return { isSort: prev?.isSort, sortedList: data }
        })
        setSubjectContents((data: Array<SubjectContentType>) => {
          let item: SubjectContentType = data[oldIndex];
          data[oldIndex] = data[newIndex];
          data[newIndex] = item
          return data
        })
      }
    }
  }

  const submitContentBall = async (values: any) => {
    try {
      let data = new FormData();
      data.append("ball", values?.ball);
      data.append("user_id", params?.teacherId);
      data.append("teacher_access_id", taid);
      data.append("lang_id", lang_id);
      data.append("subject_topic_id", params?.topicId);
      const resp = await instance({
        url: `/subject-content-marks${contentMarks ? '/' + contentMarks?.id : ''}`,
        method: contentMarks ? 'put' : 'POST',
        data: data
      })
      if (resp?.data?.status === 1) {
        asyncN("success", contentMarks ? "update" : 'create', resp?.data?.message).then(() => {
          form?.resetFields(); setRefreshMark(!refreshMark); setContentCheckModal(false)
        })
      } else {
        asyncN("error", contentMarks ? "update" : 'create', resp?.data?.message)
      }
    } catch (error: any) {
      asyncN("error", contentMarks ? "update" : 'create', error?.response ? error?.response?.data?.message : error?.message)
    }
  }

  return (
    <div>
      <Modal width={"70%"} title={referenceModal?.type ? referenceTypeText[`${referenceModal?.type}`] : ''} visible={referenceModal.open} onCancel={() => setSetReferenceModal({ open: false, type: null })} footer={false}>
        {
          referenceModal?.type === 1 ? <TopicTitle setRefresh={setRefreshRef} refresh={refreshRef} closeModal={(e) => setSetReferenceModal({ open: false, type: null, item: undefined })} topicId={Number(params?.topicId)} userId={params?.teacherId} data={referenceModal?.item} /> : ''
        }
        {
          referenceModal?.type === 2 ? <BasicLiterature setRefresh={setRefreshRef} refresh={refreshRef} closeModal={(e) => setSetReferenceModal({ open: false, type: null, item: undefined })} topicId={Number(params?.topicId)} userId={params?.teacherId} data={referenceModal?.item} /> : ''
        }
        {
          referenceModal?.type === 3 ? <AdditionalLiterature setRefresh={setRefreshRef} refresh={refreshRef} closeModal={(e) => setSetReferenceModal({ open: false, type: null, item: undefined })} topicId={Number(params?.topicId)} userId={params?.teacherId} data={referenceModal?.item} /> : ''
        }
        {
          referenceModal?.type === 4 ? <QuestionForDiscussion setRefresh={setRefreshRef} refresh={refreshRef} closeModal={(e) => setSetReferenceModal({ open: false, type: null, item: undefined })} topicId={Number(params?.topicId)} userId={params?.teacherId} data={referenceModal?.item} /> : ''
        }
        {
          referenceModal?.type === 5 ? <CaseOrQuestion setRefresh={setRefreshRef} refresh={refreshRef} closeModal={(e) => setSetReferenceModal({ open: false, type: null, item: undefined })} topicId={Number(params?.topicId)} userId={params?.teacherId} data={referenceModal?.item} /> : ''
        }
      </Modal>
      <Modal onCancel={() => setContentCheckModal(false)} footer={false} visible={contentCheckModal} title="Baholash!">
        <Form onFinish={submitContentBall} layout="vertical" form={form}>
          <Row gutter={24}>
            <Col span={24} className="py-0 mt-2">
              <Form.Item label="Baho" name="ball" className="d-inline-block w-100">
                <Input onChange={(e) => {
                  let val = e.target?.value
                  if (Number(val) >= 0 && Number(val) <= 10) {
                    if (Number(val[0]) === 0) {
                      form.setFieldsValue({
                        "ball": 0
                      })
                    }
                  } else {
                    form.setFieldsValue({
                      "ball": Number(val[1]) == 0 && Number(val[0]) == 1 ? val[0] + val[1] : val[0]
                    })
                  }
                }} min={0} max={10} type="number" placeholder="Bahoni kiriting" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={24} className={`d-f ${isHasAuthRoute("subject-content-mark_delete")
              && contentCheckModal && contentMarks ? 'justify-content-between' : 'justify-content-end'}`}>
              {isHasAuthRoute("subject-content-mark_delete") && contentCheckModal && contentMarks ?
                <Popconfirm title="Delete content ball!" onConfirm={() => delete_data("/subject-content-marks", contentMarks?.id).then(() => {
                  form?.resetFields(); setContentCheckModal(false); setRefreshMark(!refreshMark);
                })}>
                  <Button htmlType="button"
                    type="primary" danger className="w-25" > {t("Delete")} </Button>
                </Popconfirm> : ''}
              <Button htmlType="submit" type="primary" className="w-25" > {t("Save")} </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Row>
        <Col span={12} className="text-start">
          <CustomBreadcrumb
            arr={_breadcrump.map((item) => {
              item = { ...item, name: t(item?.name) };
              return item;
            })}
          />
        </Col>
        <Col span={12} className="d-f justify-content-end">
          {
            isHasAuthRoute("subject-content-mark_create") && (role.includes("edu_quality") || role.includes("admin") || role.includes("contenter")) && !contentMarks && set == 0 ?
              <ButtonCreate onClick={() => setContentCheckModal(true)}/> : null
          }
          {
            isHasAuthRoute("subject-content-mark_update") && (role.includes("edu_quality") || role.includes("admin") || role.includes("contenter")) && contentMarks && set == 0 ?
              <Tag style={{ cursor: "pointer" }} className="mx-2 p-1 bg-warning text-light" onClick={() => {
                setContentCheckModal(true);
                form?.setFieldsValue({
                  ball: contentMarks?.ball
                })
              }}>Kontent uchun ball: {contentMarks.ball}</Tag> : null
          }
          {set === undefined && isHasAuthRoute("subject-content_create") ? < ButtonCreate
            onClick={() =>
              history.push(`/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-create`)
            }
          /> : null}
        </Col>
      </Row>
      <Row gutter={24} className="mt-2">
        <Col span={24}>
          <div className={styles.content}>
            <div className={styles.content_header}>
          {
            canSort && !sort?.isSort && set === undefined ? <button style={{
              backgroundColor: sort?.isSort ? "#1890ff" : "#EEF1F6",
              color: sort?.isSort ? "white" : "grey",
            }} className={styles.sort_btn} onClick={() => sortContent("FIRST_SORT")}><RiListSettingsLine /></button> : null
          }
          {
            sort?.isSort ? <button className={styles.sort_btn_cancel} onClick={() => {
              setSort({
                isSort: false,
                sortedList: {}
              })
              setRefresh(!refresh)
            }}><MdOutlineCancel size={20} /></button> : null
          }
          {
            sort?.isSort ? <button className={styles.sort_btn_save} onClick={() => {
              saveSortesList()
            }}><BsCheck2 size={20} /></button> : null
          }
          </div>
          </div>
          {topic ? <><div>
                <strong style={{fontSize: '18px'}}>{t("Subject")}: </strong>
                <span>
                  {topic?.subject?.name}
                </span>
              </div>
              <div>
                <strong style={{fontSize: '18px'}}>{t("Topic")}: </strong>
                <span>
                  {topic?.name}
                </span>
              </div></> : null}
        </Col>
        <Col span={24}>
          <Tabs onTabClick={(key) => filterTypes(Number(key))}>
            <Tabs.TabPane tab={<>Mavzu varog'i</>} key={'0'}>
              {
                isLoading ? (
                  <div className="loader-line"></div>
                ) : (
                  <div style={{ height: "3px" }}></div>
                )
              }
              {
                !sort.isSort ?
                  <Row gutter={24}>
                    <Col span={24}>
                      <div>
                        <b className="d-f justify-content-between">
                          Asosiy maqsad va vazifalar, talabalar qanday bilim va ko‘nikmaga ega bo‘lishi kutilmoqda: 
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 1, item: _teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1)[0] }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaEdit/>} /> : null}
                        </b>
                        <div>
                          <ForTable showAllData={true} loading={false} totalCount={_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1).length} datas={_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1)} theads={[
                            <>{t('Nomi')}</>,
                            <>{t('Amallar')}</>
                          ]} tbodys={function(this: any){
                            return [
                              <>{_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1)[0]?.name}</>,
                              <>{
                                set === undefined ? <Popconfirm
                                  placement="topLeft"
                                  title={`${t("Deleted it")} ?`}
                                  okText={t("Yes")}
                                  cancelText={t("No")}
                                  onConfirm={() => delete_data("/subject-topic-references", _teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1)[0]?.id).then(() => {
                                    setRefreshRef(!refreshRef)
                                  })}>
                                  <Button
                                    danger
                                    icon={<TbFileOff />} type="link" />
                                </Popconfirm> : null
                              }</>
                            ]
                          }}/>
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="mt-3">
                        <b className="d-f justify-content-between">
                          O‘qilishi tavsiya etiladigan qo‘shimcha adabiyotlar.
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 3, item: undefined }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaPlusCircle />} /> : null}
                        </b>
                        <div className="text-end">Umumiy tavsiya etilgan sahifalar soni: {getLibraryPagesCount(3)} ta sahifa</div>
                      </div>
                      <div>
                          <ForTable loading={false} showAllData={true} datas={_teacherReference?.items.filter((item: any, index: number) => item.type === 3)} totalCount={_teacherReference?.items?.length} theads={
                            [
                              t("Asar nomi"),
                              t("Sahifalar soni"),
                              t("Amallar")
                            ]
                          } tbodys={
                            function(this: any){
                              return [
                                this?.link ?
                                  <a href={this?.link} target="_blank" rel="noopener noreferrer">
                                    {this?.name}
                                  </a>
                                  :
                                  <div dangerouslySetInnerHTML={{__html: this?.name}}></div>,
                                this?.start_page && this?.end_page ? `${this?.start_page}-${this?.end_page}-sahifalar.` : 'Sahifalar mavjud emas',
                                <>
                                  {set === undefined ? <Button
                                    type="link"
                                    icon={<FaEdit />}
                                    onClick={() => { setSetReferenceModal({ open: true, type: 3, item: this }) }}
                                  /> : null}
                                  {set === undefined ? <Popconfirm
                                    placement="topLeft"
                                    title={`${t("Deleted it")} ?`}
                                    okText={t("Yes")}
                                    cancelText={t("No")}
                                    onConfirm={() => delete_data("/subject-topic-references", this?.id).then(() => {
                                      setRefreshRef(!refreshRef)
                                    })}>
                                    <Button
                                      danger
                                      icon={<TbFileOff />} type="link" />
                                  </Popconfirm> : null}
                                </>
                              ]
                            }
                          }/>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="mt-3">
                        <b className="d-f justify-content-between">
                          Muhokama uchun savollar: 
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 4, item: undefined }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaPlusCircle />} /> : null}
                        </b>
                      </div>
                      <div>
                        <ForTable loading={false} totalCount={_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 4).length} showAllData={true} datas={_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 4)} theads={[
                          <>{t('Nomi')}</>,
                          <>{t('Amallar')}</>,
                        ]} tbodys={function(this: any){
                          return [
                            <>{this.name}</>,
                            <>
                              {set === undefined ? <Button
                                type="link"
                                icon={<FaEdit />}
                                onClick={() => { setSetReferenceModal({ open: true, type: 4, item: this }) }}
                              /> : null}
                              {set === undefined ? <Popconfirm
                                placement="topLeft"
                                title={`${t("Deleted it")} ?`}
                                okText={t("Yes")}
                                cancelText={t("No")}
                                onConfirm={() => delete_data("/subject-topic-references", this?.id).then(() => {
                                  setRefreshRef(!refreshRef)
                                })}>
                                <Button
                                  danger
                                  icon={<TbFileOff />} type="link" />
                              </Popconfirm> : null}
                            </>
                          ]
                        }}/>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="mt-3">
                        <b className="d-f justify-content-between">
                          Muhokama uchun kazus yoki muammoli savol: 
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 5, item: undefined }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaPlusCircle />} /> : null}
                        </b>
                      </div>
                      <div>
                        <ForTable showAllData={true} loading={false} datas={_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 5)} totalCount={_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 5)?.length} theads={[
                          <>{t('Kazus')}</>,
                          <>{t('Amallar')}</>
                        ]} tbodys={function(this: any){
                          return [
                            <div dangerouslySetInnerHTML={{ __html: this?.name }} />,
                            <>
                              {set === undefined ? <Button
                                type="link"
                                icon={<FaEdit />}
                                onClick={() => { setSetReferenceModal({ open: true, type: 5, item: this }) }}
                              /> : null}
                              {set === undefined ? <Popconfirm
                                placement="topLeft"
                                title={`${t("Deleted it")} ?`}
                                okText={t("Yes")}
                                cancelText={t("No")}
                                onConfirm={() => delete_data("/subject-topic-references", this?.id).then(() => {
                                  setRefreshRef(!refreshRef)
                                })}>
                                <Button
                                  danger
                                  icon={<TbFileOff />} type="link" />
                              </Popconfirm> : null}
                            </>
                          ]
                        }}/>
                      </div>
                    </Col>
                  </Row> 
                : null
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><FaTextWidth /> Ma'ruza matni</>} key={'1'}>
              {
                isLoading ? (
                  <div className="loader-line"></div>
                ) : (
                  <div style={{ height: "3px" }}></div>
                )
              }
              {
                contentFilter()?.length ? (
                  <Row gutter={24}>
                    {contentFilter()?.map((item: SubjectContentType, i: number) => (
                      <Col key={i} span={24}>
                        <Card
                          id={String(i)}
                          className=""
                          title={
                            <div style={{ position: "relative" }} className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                {t('Holati')}:&nbsp;
                                <BadgeStatus status={item?.status} />
                              </div>
                              <div className="crud_actions_table">
                                {
                                  sort?.isSort ? <div>
                                    <Button onClick={() => sortContent("LIST_SORT", i, i - 1)} disabled={i === 0} className="mx-1" icon={<BsArrowUp />} />
                                    <Button onClick={() => sortContent("LIST_SORT", i, i + 1)} disabled={i === subjectContents?.length - 1} icon={<BsArrowDown />} />
                                  </div> : <>
                                    {isHasAuthRoute("subject-content_update") && set === undefined && (
                                      <Tooltip placement="top" title={t("Edit")}>
                                        <TbFilePencil 
                                          className="edit_btn"
                                          onClick={() => {
                                            history.push({
                                              pathname: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${item?.id}`,
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {isHasAuthRoute("subject-content_delete") && set === undefined && (
                                      <Tooltip placement="top" title={t("Delete")}>
                                        <Popconfirm
                                          placement="topLeft"
                                          title={`${t("Deleted it")} ?`}
                                          okText={t("Yes")}
                                          cancelText={t("No")}
                                          onConfirm={() => deleteItem(item.id)}
                                        >
                                          <TbFileOff className="delete_btn" />
                                        </Popconfirm>
                                      </Tooltip>
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          }
                        >
                          {
                          fileView(item?.type, item)
                          }
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : <Empty />
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><HiOutlinePresentationChartLine /> Taqdimot</>} key={'6'}>
              {
                isLoading ? (
                  <div className="loader-line"></div>
                ) : (
                  <div style={{ height: "3px" }}></div>
                )
              }
              {
                contentFilter()?.length ? (
                  <Row gutter={24}>
                    {contentFilter()?.map((item: SubjectContentType, i: number) => (
                      <Col key={i} span={24}>
                        <Card
                          id={String(i)}
                          className=""
                          title={
                            <div style={{ position: "relative" }} className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                {t('Holati')}:&nbsp;
                                <BadgeStatus status={item?.status} />
                              </div>
                              <div className="crud_actions_table">
                                {
                                  sort?.isSort ? <div>
                                    <Button onClick={() => sortContent("LIST_SORT", i, i - 1)} disabled={i === 0} className="mx-1" icon={<BsArrowUp />} />
                                    <Button onClick={() => sortContent("LIST_SORT", i, i + 1)} disabled={i === subjectContents?.length - 1} icon={<BsArrowDown />} />
                                  </div> : <>
                                    {isHasAuthRoute("subject-content_update") && set === undefined && (
                                      <Tooltip placement="top" title={t("Edit")}>
                                        <TbFilePencil 
                                          className="edit_btn"
                                          onClick={() => {
                                            history.push({
                                              pathname: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${item?.id}`,
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {isHasAuthRoute("subject-content_delete") && set === undefined && (
                                      <Tooltip placement="top" title={t("Delete")}>
                                        <Popconfirm
                                          placement="topLeft"
                                          title={`${t("Deleted it")} ?`}
                                          okText={t("Yes")}
                                          cancelText={t("No")}
                                          onConfirm={() => deleteItem(item.id)}
                                        >
                                          <TbFileOff className="delete_btn" />
                                        </Popconfirm>
                                      </Tooltip>
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          }
                        >
                          {
                          fileView(item?.type, item)
                          }
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : <Empty />
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><FaFilm /> Video</>} key={'4'}>
              {
                isLoading ? (
                  <div className="loader-line"></div>
                ) : (
                  <div style={{ height: "3px" }}></div>
                )
              }
              {
                contentFilter()?.length ? (
                  <Row gutter={24}>
                    {contentFilter()?.map((item: SubjectContentType, i: number) => (
                      <Col key={i} span={24}>
                        <Card
                          id={String(i)}
                          className=""
                          title={
                            <div style={{ position: "relative" }} className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                {t('Holati')}:&nbsp;
                                <BadgeStatus status={item?.status} />
                              </div>
                              <div className="crud_actions_table">
                                {
                                  sort?.isSort ? <div>
                                    <Button onClick={() => sortContent("LIST_SORT", i, i - 1)} disabled={i === 0} className="mx-1" icon={<BsArrowUp />} />
                                    <Button onClick={() => sortContent("LIST_SORT", i, i + 1)} disabled={i === subjectContents?.length - 1} icon={<BsArrowDown />} />
                                  </div> : <>
                                    {isHasAuthRoute("subject-content_update") && set === undefined && (
                                      <Tooltip placement="top" title={t("Edit")}>
                                        <TbFilePencil 
                                          className="edit_btn"
                                          onClick={() => {
                                            history.push({
                                              pathname: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${item?.id}`,
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {isHasAuthRoute("subject-content_delete") && set === undefined && (
                                      <Tooltip placement="top" title={t("Delete")}>
                                        <Popconfirm
                                          placement="topLeft"
                                          title={`${t("Deleted it")} ?`}
                                          okText={t("Yes")}
                                          cancelText={t("No")}
                                          onConfirm={() => deleteItem(item.id)}
                                        >
                                          <TbFileOff className="delete_btn" />
                                        </Popconfirm>
                                      </Tooltip>
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          }
                        >
                          {
                          fileView(item?.type, item)
                          }
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : <Empty />
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><FaMusic /> Audio</>} key={'5'}>
              {
                isLoading ? (
                  <div className="loader-line"></div>
                ) : (
                  <div style={{ height: "3px" }}></div>
                )
              }
            {
                contentFilter()?.length ? (
                  <Row gutter={24}>
                    {contentFilter()?.map((item: SubjectContentType, i: number) => (
                      <Col key={i} span={24}>
                        <Card
                          id={String(i)}
                          className=""
                          title={
                            <div style={{ position: "relative" }} className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                {t('Holati')}:&nbsp;
                                <BadgeStatus status={item?.status} />
                              </div>
                              <div className="crud_actions_table">
                                {
                                  sort?.isSort ? <div>
                                    <Button onClick={() => sortContent("LIST_SORT", i, i - 1)} disabled={i === 0} className="mx-1" icon={<BsArrowUp />} />
                                    <Button onClick={() => sortContent("LIST_SORT", i, i + 1)} disabled={i === subjectContents?.length - 1} icon={<BsArrowDown />} />
                                  </div> : <>
                                    {isHasAuthRoute("subject-content_update") && set === undefined && (
                                      <Tooltip placement="top" title={t("Edit")}>
                                        <TbFilePencil 
                                          className="edit_btn"
                                          onClick={() => {
                                            history.push({
                                              pathname: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${item?.id}`,
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {isHasAuthRoute("subject-content_delete") && set === undefined && (
                                      <Tooltip placement="top" title={t("Delete")}>
                                        <Popconfirm
                                          placement="topLeft"
                                          title={`${t("Deleted it")} ?`}
                                          okText={t("Yes")}
                                          cancelText={t("No")}
                                          onConfirm={() => deleteItem(item.id)}
                                        >
                                          <TbFileOff className="delete_btn" />
                                        </Popconfirm>
                                      </Tooltip>
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          }
                        >
                          {
                          fileView(item?.type, item)
                          }
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : <Empty />
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><FaImage /> Rasmlar</>} key={'3'}>
              {
                isLoading ? (
                  <div className="loader-line"></div>
                ) : (
                  <div style={{ height: "3px" }}></div>
                )
              }
              {
                contentFilter()?.length ? (
                  <Row gutter={24}>
                    {contentFilter()?.map((item: SubjectContentType, i: number) => (
                      <Col key={i} span={24}>
                        <Card
                          id={String(i)}
                          className=""
                          title={
                            <div style={{ position: "relative" }} className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                {t('Holati')}:&nbsp;
                                <BadgeStatus status={item?.status} />
                              </div>
                              <div className="crud_actions_table">
                                {
                                  sort?.isSort ? <div>
                                    <Button onClick={() => sortContent("LIST_SORT", i, i - 1)} disabled={i === 0} className="mx-1" icon={<BsArrowUp />} />
                                    <Button onClick={() => sortContent("LIST_SORT", i, i + 1)} disabled={i === subjectContents?.length - 1} icon={<BsArrowDown />} />
                                  </div> : <>
                                    {isHasAuthRoute("subject-content_update") && set === undefined && (
                                      <Tooltip placement="top" title={t("Edit")}>
                                        <TbFilePencil 
                                          className="edit_btn"
                                          onClick={() => {
                                            history.push({
                                              pathname: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${item?.id}`,
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {isHasAuthRoute("subject-content_delete") && set === undefined && (
                                      <Tooltip placement="top" title={t("Delete")}>
                                        <Popconfirm
                                          placement="topLeft"
                                          title={`${t("Deleted it")} ?`}
                                          okText={t("Yes")}
                                          cancelText={t("No")}
                                          onConfirm={() => deleteItem(item.id)}
                                        >
                                          <TbFileOff className="delete_btn" />
                                        </Popconfirm>
                                      </Tooltip>
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          }
                        >
                          {
                          fileView(item?.type, item)
                          }
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : <Empty />
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><FaFile /> Qo'shimcha materiallar</>} key={'2'}>
              {
                isLoading ? (
                  <div className="loader-line"></div>
                ) : (
                  <div style={{ height: "3px" }}></div>
                )
              }
              {
                contentFilter()?.length ? (
                  <Row gutter={24}>
                    {contentFilter()?.map((item: SubjectContentType, i: number) => (
                      <Col key={i} span={24}>
                        <Card
                          id={String(i)}
                          className=""
                          title={
                            <div style={{ position: "relative" }} className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                {t('Holati')}:&nbsp;
                                <BadgeStatus status={item?.status} />
                              </div>
                              <div className="crud_actions_table">
                                {
                                  sort?.isSort ? <div>
                                    <Button onClick={() => sortContent("LIST_SORT", i, i - 1)} disabled={i === 0} className="mx-1" icon={<BsArrowUp />} />
                                    <Button onClick={() => sortContent("LIST_SORT", i, i + 1)} disabled={i === subjectContents?.length - 1} icon={<BsArrowDown />} />
                                  </div> : <>
                                    {isHasAuthRoute("subject-content_update") && set === undefined && (
                                      <Tooltip placement="top" title={t("Edit")}>
                                        <TbFilePencil 
                                          className="edit_btn"
                                          onClick={() => {
                                            history.push({
                                              pathname: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${item?.id}`,
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {isHasAuthRoute("subject-content_delete") && set === undefined && (
                                      <Tooltip placement="top" title={t("Delete")}>
                                        <Popconfirm
                                          placement="topLeft"
                                          title={`${t("Deleted it")} ?`}
                                          okText={t("Yes")}
                                          cancelText={t("No")}
                                          onConfirm={() => deleteItem(item.id)}
                                        >
                                          <TbFileOff className="delete_btn" />
                                        </Popconfirm>
                                      </Tooltip>
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                          }
                        >
                          {
                          fileView(item?.type, item)
                          }
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : <Empty />
              }
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Row gutter={24}>
            
            {/* {
              activeFilter === 0 && !sort.isSort ?
                <Col span={24}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <div>
                        <b className="d-f">
                          <i>&emsp;Asosiy maqsad va vazifalar, talabalar qanday bilim va ko‘nikmaga ega bo‘lishi kutilmoqda: </i>
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 1, item: _teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1)[0] }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaEdit />} /> : ''}
                        </b>
                        {_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1)?.length > 0 ? <span className="d-f">
                          {_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1)[0]?.name}
                          {set === undefined ? <Popconfirm
                            placement="topLeft"
                            title={`${t("Deleted it")} ?`}
                            okText={t("Yes")}
                            cancelText={t("No")}
                            onConfirm={() => delete_data("/subject-topic-references", _teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 1)[0]?.id).then(() => {
                              setRefreshRef(!refreshRef)
                            })}>
                            <Button
                              danger
                              icon={<TbFileOff />} type="link" />
                          </Popconfirm> : ''}
                        </span> : <Alert description="Ma'lumot kiritilmagan!" type="warning" />}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="d-f justify-content-between mt-3">
                        <b className="d-f">
                          &emsp;O‘qilishi tavsiya etiladigan asosiy adabiyotlar.
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 2, item: undefined }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaPlusCircle />} /> : ''}
                        </b>
                        <span>Umumiy tavsiya etilgan sahifalar soni: {getLibraryPagesCount(2)} ta sahifa</span>
                      </div>
                      {_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 2)?.length > 0 ? <ol style={{ fontSize: "13px" }}>
                        {
                          _teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 2)?.map((item: subjectTopicReferenceType, i: number) =>
                            <li>
                              <div>
                                {
                                  item?.link ?
                                    <a href={item?.link} target="_blank" rel="noopener noreferrer">
                                      {item?.name}
                                    </a>
                                    :
                                    <span>
                                      {item?.name}
                                    </span>
                                } // <i>{item?.start_page}-{item?.end_page}-sahifalar.</i>
                                {set === undefined ? <Button
                                  type="link"
                                  icon={<FaEdit />}
                                  onClick={() => { setSetReferenceModal({ open: true, type: 2, item: item }) }}
                                /> : ''}
                                {set === undefined ? <Popconfirm
                                  placement="topLeft"
                                  title={`${t("Deleted it")} ?`}
                                  okText={t("Yes")}
                                  cancelText={t("No")}
                                  onConfirm={() => delete_data("/subject-topic-references", item?.id).then(() => {
                                    setRefreshRef(!refreshRef)
                                  })}>
                                  <Button
                                    danger
                                    icon={<TbFileOff />} type="link" />
                                </Popconfirm> : ''}
                              </div>
                            </li>
                          )
                        }
                      </ol>
                        : <Alert description="Ma'lumot kiritilmagan!" type="warning" />}
                    </Col>
                    <Col span={24}>
                      <div className="d-f justify-content-between mt-3">
                        <b className="d-f">
                          &emsp;O‘qilishi tavsiya etiladigan qo‘shimcha adabiyotlar.
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 3, item: undefined }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaPlusCircle />} /> : ''}
                        </b>
                        <span>Umumiy tavsiya etilgan sahifalar soni: {getLibraryPagesCount(3)} ta sahifa</span>
                      </div>
                      {_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 3)?.length > 0 ? <ol style={{ fontSize: "13px" }}>
                        {
                          _teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 3)?.map((item: subjectTopicReferenceType, i: number) =>
                            <li>
                              <div>
                                {
                                  item?.link ?
                                    <a href={item?.link} target="_blank" rel="noopener noreferrer">
                                      {item?.name}
                                    </a>
                                    :
                                    <span>
                                      {item?.name}
                                    </span>
                                } // <i>{item?.start_page}-{item?.end_page}-sahifalar.</i>
                                {set === undefined ? <Button
                                  type="link"
                                  icon={<FaEdit />}
                                  onClick={() => { setSetReferenceModal({ open: true, type: 3, item: item }) }}
                                /> : ''}
                                {set === undefined ? <Popconfirm
                                  placement="topLeft"
                                  title={`${t("Deleted it")} ?`}
                                  okText={t("Yes")}
                                  cancelText={t("No")}
                                  onConfirm={() => delete_data("/subject-topic-references", item?.id).then(() => {
                                    setRefreshRef(!refreshRef)
                                  })}>
                                  <Button
                                    danger
                                    icon={<TbFileOff />} type="link" />
                                </Popconfirm> : ''}
                              </div>
                            </li>
                          )
                        }
                      </ol>
                        : <Alert description="Ma'lumot kiritilmagan!" type="warning" />}
                    </Col>
                    <Col span={24}>
                      <div className="text-start mt-3">
                        <b className="d-f">
                          <i>&emsp;Muhokama uchun savollar: </i>
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 4, item: undefined }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaPlusCircle />} /> : ''}
                        </b>
                      </div>
                      {_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 4)?.length > 0 ? <ol style={{ fontSize: "13px" }}>
                        {
                          _teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 4)?.map((item: subjectTopicReferenceType, i: number) =>
                            <li>
                              <div>
                                <i>
                                  {item?.name}
                                </i>
                                {set === undefined ? <Button
                                  type="link"
                                  icon={<FaEdit />}
                                  onClick={() => { setSetReferenceModal({ open: true, type: 4, item: item }) }}
                                /> : ''}
                                {set === undefined ? <Popconfirm
                                  placement="topLeft"
                                  title={`${t("Deleted it")} ?`}
                                  okText={t("Yes")}
                                  cancelText={t("No")}
                                  onConfirm={() => delete_data("/subject-topic-references", item?.id).then(() => {
                                    setRefreshRef(!refreshRef)
                                  })}>
                                  <Button
                                    danger
                                    icon={<TbFileOff />} type="link" />
                                </Popconfirm> : ''}
                              </div>
                            </li>
                          )
                        }
                      </ol>
                        : <Alert description="Ma'lumot kiritilmagan!" type="warning" />}
                    </Col>
                    <Col span={24}>
                      <div className="text-start mt-3">
                        <b className="d-f">
                          <i>&emsp;Muhokama uchun kazus yoki muammoli savol: </i>
                          {set === undefined ? <Button
                            onClick={() => { setSetReferenceModal({ open: true, type: 5, item: undefined }) }}
                            className="p-0 m-0"
                            type="link"
                            icon={<FaPlusCircle />} /> : ''}
                        </b>
                      </div>
                      {
                        _teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 5)?.length > 0 ?
                          <div>
                            {_teacherReference?.items?.filter((item: subjectTopicReferenceType) => item?.type == 5)?.map((item: subjectTopicReferenceType, i: number) =>
                              <span key={i} className={`question_main_view text_size_question_14 d-f flex-wrap`}>
                                <i style={{ color: "#1890FF" }}>&emsp;{i + 1}-kazus: </i>
                                {set === undefined ? <Button
                                  type="link"
                                  icon={<FaEdit />}
                                  onClick={() => { setSetReferenceModal({ open: true, type: 5, item: item }) }}
                                /> : ''}
                                {set === undefined ? <Popconfirm
                                  placement="topLeft"
                                  title={`${t("Deleted it")} ?`}
                                  okText={t("Yes")}
                                  cancelText={t("No")}
                                  onConfirm={() => delete_data("/subject-topic-references", item?.id).then(() => {
                                    setRefreshRef(!refreshRef)
                                  })}>
                                  <Button
                                    danger
                                    icon={<TbFileOff />} type="link" />
                                </Popconfirm> : ''}
                                <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: item?.name }} />
                              </span>
                            )}
                          </div>
                          : <Alert description="Ma'lumot kiritilmagan!" type="warning" />

                      }
                    </Col>
                  </Row>
                </Col> : null
            }
            {activeFilter != 0 ? <Col span={24} className="mt-2">
              {
              isLoading ? <div className="w-100 "><Spin spinning={isLoading}/></div> : 
              contentFilter()?.length > 0 ? (
                <Row gutter={24}>
                  {contentFilter()?.map((item: SubjectContentType, i: number) => (
                    <Col key={i} span={24}>
                      <Card
                        id={String(i)}
                        className=""
                        title={
                          <div style={{ position: "relative" }} className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              {t('Holati')}:&nbsp;
                              <BadgeStatus status={item?.status} />
                            </div>
                            <div className="crud_actions_table">
                              {
                                sort?.isSort ? <div>
                                  <Button onClick={() => sortContent("LIST_SORT", i, i - 1)} disabled={i === 0} className="mx-1" icon={<BsArrowUp />} />
                                  <Button onClick={() => sortContent("LIST_SORT", i, i + 1)} disabled={i === subjectContents?.length - 1} icon={<BsArrowDown />} />
                                </div> : <>
                                  {isHasAuthRoute("subject-content_update") && set === undefined && (
                                    <Tooltip placement="top" title={t("Edit")}>
                                      <TbFilePencil 
                                        className="edit_btn"
                                        onClick={() => {
                                          history.push({
                                            pathname: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${item?.id}`,
                                          });
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  {isHasAuthRoute("subject-content_delete") && set === undefined && (
                                    <Tooltip placement="top" title={t("Delete")}>
                                      <Popconfirm
                                        placement="topLeft"
                                        title={`${t("Deleted it")} ?`}
                                        okText={t("Yes")}
                                        cancelText={t("No")}
                                        onConfirm={() => deleteItem(item.id)}
                                      >
                                        <TbFileOff className="delete_btn" />
                                      </Popconfirm>
                                    </Tooltip>
                                  )}
                                </>
                              }
                            </div>
                          </div>
                        }
                      >
                        {
                        fileView(item?.type, item)
                        }
                      </Card>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Empty />
              )
              }
            </Col> : null} */}
          </Row>
        </Col>
      </Row>
    </div >
  );
};

export default SubjectContent;
