import instance from "config/_axios";
import { asyncN } from "utils/notifications";

export const getAllUsers = async (prefix: string) => {
    try {
        const response = await instance({
            method: 'get',
            url: `/users${prefix ? '?' + prefix : ''}`
        })
        if (response?.data?.status === 1) {
            return response?.data
        } else {
            asyncN("error","read",response?.data?.message)
        }

    } catch (e: any) {
        asyncN("error","read","Error read users!!!")
    }
}

export const getOneUsers = async (id: number, prefix: string) => {
    try {
        const response = await instance({
            method: 'get',
            url: `/users/${id}${prefix ? '?' + prefix : ''}`
        })
        if (response?.data?.status === 1) {
            return response?.data
        } else {
            return response?.data
        }

    } catch (e: any) {
        return e;
    }
}


export const getTeacherAccessByUserId = async (id: number) => {
    try {
        const response = await instance({
            method: 'get',
            url: `teacher-accesses?filter={"user_id":${id}}&expand=subject,language`
        })
        if (response?.data?.status === 1) {
            return response?.data
        } else {
           asyncN("error", "read", response?.data.message)
        }

    } catch (e: any) {
        asyncN("error", "read", "error for getTeacherAccess by user id!!!")
    }
}


export const getUserPasswordById = async (id: number) => {
    try {
        const response = await instance({
            method: 'get',
            url: `/passwords/${id}`
        })

        return response?.data
    } catch (e: any) {
        return e?.response?.data
    }
}


export const getUserStatuses = async () => {
    try {
        const resp = await instance({
            method: 'get',
            url: '/user-statuses'
        })

        if(resp?.data?.status===1){
            return resp?.data?.data
        }else{
            asyncN("error","read",resp?.data?.message);
        }
    }catch(e){
        asyncN("error","read","Error for read user statuses!!!")
    }

}

export const getGenders=async ()=>{
    try{
        const resp=await instance({
            method: 'get',
            url: '/genders'
        })
        if(resp?.data?.status===1){
            return resp?.data?.data
        }
        else{
            asyncN("error","read",resp?.data?.message)
        }
    }catch(e){
        asyncN("error","read","Error get genders!!!")
    }
}

export const getUserAccessTypes=async (prefix?:string) => {
    try{
        const response=await instance({
            method:'get',
            url:`/user-access-types${prefix?'?'+prefix:''}`
        });
        if(response?.data?.status===1){
            return response?.data?.data;
        }else{
            asyncN("error","read",response?.data?.message)
        }
    }catch{
        asyncN("error","read","Error for read user access types!!!")
    }
}