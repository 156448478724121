import { FC, useEffect, useState } from 'react';
import { Form, Modal, message, Input, Button, Switch, Select, InputNumber } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getOneRoom } from '../service';
import { getAllBuildings } from '../../buildings/service';
import { useTranslation } from 'react-i18next';
import ModalTitle from 'components/ModalTitle';
import FormLabel from 'components/FormLabel';
import { BiX } from 'react-icons/bi';
import { Col, Row } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

type EditRoomType = {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setReRequest: (reRequest: boolean) => void,
    id: number | null,
    reRequest: boolean
}

const EditRoom: FC<EditRoomType> = ({ visible, setVisible, id, setReRequest, reRequest }) => {
    const { i18n, t } = useTranslation();
    const [form] = Form.useForm();
    const [buildings, setBuildings] = useState<Array<any>>([]);
    const [status, setStatus] = useState<boolean>(true);
    const [clicked, setClicked] = useState<boolean>(false);

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOneRoom(id, 'expand=description&self=1');
                        form.setFieldsValue({
                            name: response?.name,
                            description: response?.description,
                            capacity: response?.capacity,
                            building_id: response?.building_id,
                            status: Number(response?.status) === 1 ? setStatus(true) : setStatus(false)
                        })
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        await getAllBuildings()
                            .then((response: any) => {
                                setBuildings(response?.items)
                            }).catch((error: any) => {
                                asyncN('error', 'read', error)
                            })
                    } catch (error: any) {
                        message.error(error?.response?.message)
                    }
                }
            )()
        } else {
            setBuildings([])
        }
    }, [visible])


    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            formdata.append(`name[${i18n.language}]`, values?.name)
            formdata.append(`description[${i18n.language}]`, values?.description)
            formdata.append('building_id', values?.building_id)
            formdata.append('capacity', values?.capacity)
            formdata.append('status', status ? '1' : '0');

            const response = await instance({ url: `/rooms/${id}`, method: 'PUT', data: formdata })

            if (response?.data?.status === 1) {
                asyncN("success", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)

                    })
            } else {
                asyncN("error", 'update', response.data?.message).then(() => {
                    setVisible(false);
                    resetFields()
                    setReRequest(!reRequest)
                })
            }
            setClicked(false)
        } catch (error) {
            setClicked(false)
            setReRequest(!reRequest)
        }
    }

    const resetFields = () => form.resetFields();


    return (
        <>
            <Modal
                title={'Room edit'}
                visible={visible}
                onCancel={() => { setVisible(false); resetFields() }}
                okText="Submit"
                footer={null}
                closeIcon={<BiX size={20} />}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Form.Item
                        label={<FormLabel>Name</FormLabel>}
                        name="name"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                        className="my-2"
                    >
                        <Input placeholder={t("Enter room name") + " ..."} />
                    </Form.Item>
                    <Form.Item
                        label={<FormLabel>Description</FormLabel>}
                        name="description"
                        shouldUpdate
                        rules={[{ required: false }]}
                        className="my-2"
                    >
                        <TextArea rows={2} placeholder={t("Enter description for room") + " ..."} />
                    </Form.Item>
                    <Form.Item
                        label={<FormLabel>Building</FormLabel>}
                        name="building_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                        className="my-2"
                    >
                        <Select
                            showSearch
                            placeholder="Binoni tanlang"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                buildings?.length ? buildings?.map((e: any, i: number) => {
                                    return <Option key={i} value={e?.id}>{e?.name}</Option>
                                }) : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormLabel>Capacity</FormLabel>}
                        name="capacity"
                        className="my-2"
                    >
                        <InputNumber className='w-100' placeholder={t("Enter capacity of room")} />
                    </Form.Item>
                    <Row className='mt-4 justify-content-between align-items-center'>
                        <Col>
                            <Switch checkedChildren={t("active")} unCheckedChildren={t("inActive")} checked={status} onChange={() => setStatus(!status)} />
                        </Col>
                        <Col>
                            <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                            <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

export default EditRoom;