import { Button, Col, message, Row, Select } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabelCrud";
import useStoreData, { ReturnTypeUseStoreData } from "hooks/useStoreData";
import { ILanguage } from "models/language";
import { ISubject } from "models/subject";
import { FC, useState } from "react";
import { BiPlus, BiX } from "react-icons/bi";
import { cf_filterOption } from "utils/auxiliary_functions";

type teacherAccessPropType = {
  teacherAccess: { [key: number]: number[] },
  setTeacherAccess: React.Dispatch<React.SetStateAction<{ [key: number]: number[] }>>
}

const NewTeacherAccess: FC<teacherAccessPropType> = ({ teacherAccess, setTeacherAccess }): JSX.Element => {
  const [subjectId, setSubjectId] = useState<number | undefined>();
  const [languageIds, setLanguageIds] = useState<number[]>([])

  const subjects = useStoreData({ key: 'subjects', params: { "per-page": 0 } }) as ReturnTypeUseStoreData<ISubject>;
  const languages = useStoreData({ key: 'languages', params: { "per-page": 0 } }) as ReturnTypeUseStoreData<ILanguage>;

  const getName = (type: "subject" | "lang", id: number) => {
    return type === "subject"
      ? subjects.items.find((item) => item?.id === id)?.name
      : languages.items.find((item) => item?.id === id)?.name;
  };

  const addSubject = () => {
    if (subjectId && languageIds.length) {
      setTeacherAccess({
        ...teacherAccess,
        [subjectId]: [...languageIds],
      });
      setSubjectId(undefined);
      setLanguageIds([])
    } else {
      message.warning("Fanni va tilni tanlang!");
    }
  };

  const removeSubject = (key: number) => {
    let obj: any = {...teacherAccess};
    delete obj[key];
    setTeacherAccess(obj);
  };

  console.log("Teacger access:", teacherAccess);
  return (
    <>
      <Row gutter={[12, 0]}>
        <Col xl={10} lg={10} md={12} sm={24} xs={24}>
          <FormLabelCrud>Fan</FormLabelCrud>
          <Select
            value={subjectId}
            onChange={e => setSubjectId(e)}
            onFocus={() => !subjects.items && subjects._fetch()}
            allowClear
            loading={subjects.loading}
            showSearch
            filterOption={cf_filterOption}
            className="w-100"
          >
            {
              subjects.items.length ?
                subjects.items.map((element) => <Select.Option key={element.id} value={element.id} >{element.name}</Select.Option>) : null
            }
          </Select>
        </Col>
        <Col xl={10} lg={10} md={12} sm={24} xs={24}>
          <FormLabelCrud>Til</FormLabelCrud>
          <Select
            mode="multiple"
            value={languageIds}
            disabled={!subjectId}
            onChange={e => setLanguageIds(e)}
            onFocus={() => !languages.items && languages._fetch()}
            loading={languages.loading}
            allowClear
            showSearch
            filterOption={cf_filterOption}
            className="w-100"
          >
            {
              languages.items.length ?
                languages.items.map((element) => <Select.Option key={element.id} value={element.id} >{element.name}</Select.Option>) : null
            }
          </Select>
        </Col>
        <Col xl={4} lg={4} md={12} sm={24} xs={24} className="text-end">
          <Button
            type="primary"
            size="small"
            className="w_70"
            style={{ marginTop: "26px" }}
            disabled={!(subjectId && languageIds?.length)}
            onClick={addSubject}
          >
            <BiPlus size={20} />
          </Button>
        </Col>
      </Row>
      <table className="table table-bordered w-100 mt-3 mb-1">
        <tbody>
          {
            Object.entries(teacherAccess)?.map(([key, value]) => {
              return (
                <tr key={key}>
                  <td className="px-2 py-0">{getName('subject', Number(key))}</td>
                  <td className="px-2 py-0">{value.map((e, i) => <span key={i} className="me-2" >{getName('lang', e)}</span>)}</td>
                  <td className="text-center w_70 py-0"><Button type="link" size="small" danger onClick={() => removeSubject(Number(key))}><BiX size={18} /></Button></td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </>
  )
}

export default NewTeacherAccess;