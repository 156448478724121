import { Form, message, Spin, Button } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useAppSelector } from "store/services";
import { saveOrEditSubjectContent } from "../service";
import FeildsUi from "./ui";
import styles from './style.module.scss';
import { useForm } from "antd/lib/form/Form";

const SubjectContentCreate: FC = (): JSX.Element => {
    const [form] = useForm()
    const { t } = useTranslation()
    const auth: any = useAppSelector(state => state.auth);
    const params: any = useRouteMatch("/subjects/:subjectId/:teacherId/topics/:topicId/content-create")?.params
    const [clickFinish, setClickFinish] = useState<boolean>(false)
    const [files, setFiles] = useState<Array<any>>([])
    const history = useHistory()
    const [fileKey, setFileKey] = useState<string>()
    const [savedForms, setSavedForms] = useState<Array<string>>([])
    const [loading, setLoading] = useState<boolean>(false);

    const _breadcrump = auth?.user?.role?.length === 1 && auth?.user?.role[0] === "teacher" ? [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "subjects",
        },
        {
            name: "Subject topics",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
        },
        {
            name: "Subject contents",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/contents`,
        },
        {
            name: "Subject content create",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-create`,
        }
    ] : [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "subjects",
        },
        {
            name: "Subject teachers",
            path: `/subjects/${params?.subjectId}/teachers`,
        },
        {
            name: "Subject topics",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
        },
        {
            name: "Subject contents",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/contents`,
        },
        {
            name: "Subject content create",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-create`,
        }
    ];

    const onFinish = async (values?: any, info?: any) => {
        if (values === "sava_all_form") {
            setLoading(true)
            let savedF: Array<string> = savedForms?.length > 0 ? savedForms : [], lenght = 0;
            Object.entries(info?.forms)?.forEach(async ([key, value]: any) => {
                if (key !== "sava_all_form" && !savedForms?.includes(key)) {
                    setTimeout(async () => {
                        const feildsValues = value?.getFieldsValue(key);

                        let data = new FormData()
                        Object.entries(feildsValues)?.map(([key1, value1]: any, i: number) => {
                            let a = key1?.split("-")
                            if (["file_file", "file_image", "file_video", "file_audio"].includes(a[1])) {
                                if (a[1]) {
                                    data.append(a[1], value1?.file?.originFileObj)
                                }
                            } else if (a[1] === "status") {
                                data.append(a[1], value1 ? "1" : "0")
                            } else if (a[1] === "text") {
                                data.append("content", value1)
                            } else {
                                data.append(a[1], value1)
                            }
                        })
                        data.append("subject_topic_id", params?.topicId);
                        if (!(auth?.user?.role?.length === 1 && auth?.user?.role[0] === "teacher")) data?.append("user_id", String(params?.teacherId));
                        const res = await saveOrEditSubjectContent(data)
                        if (res === 1) {
                            savedF = [...savedF, key];
                            lenght += 1;
                            setSavedForms(savedF)
                        }
                    }, 1000)
                    setTimeout(() => {
                        setLoading(false)
                    }, (Object.keys(info).length - 1) * 1000)

                }
            })
            history.goBack()
        } else {
            setLoading(true)
            let data = new FormData()
            Object.entries(info?.values)?.map(([key1, value1]: any, i: number) => {
                let a = key1?.split("-")
                if (["file_file", "file_image", "file_video", "file_audio"].includes(a[1])) {
                    if (a[1]) {
                        data.append(a[1], value1?.file?.originFileObj)
                    }
                } else if (a[1] === "status") {
                    data.append(a[1], value1 ? "1" : "0")
                } else if (a[1] === "text") {
                    data.append("content", value1?.toString())
                } else if (a[1] === "order") {
                    data.append(a[1], a[0])
                } else {
                    data.append(a[1], value1)
                }
            })
            data.append("subject_topic_id", params?.topicId);
            if (!(auth?.user?.role?.length === 1 && auth?.user?.role[0] === "teacher")) data?.append("user_id", String(params?.teacherId));
            const res = await saveOrEditSubjectContent(data)
            if (res === 1) {
                setSavedForms([
                    ...savedForms,
                    values
                ])
            }
            setLoading(false)
            history.goBack()
        }
        // if (values?.file_file || values?.content) {
        //     let data = new FormData()
        //     Object.entries(values)?.map(([key, value]: any, i: number) => {
        //         if (key === "file_file") {

        //             if (fileKey) {
        //                 data.append(fileKey, value?.file?.originFileObj)
        //             }
        //         } else if (key === "status") {
        //             data.append(key, value ? "1" : "0")
        //         } else {
        //             data.append(key, value)
        //         }
        //     })
        //     data.append("subject_topic_id", String(a[4]));
        //     const res = await saveOrEditSubjectContent(data)
        //     if (res === 1) {
        //         history.push(`/subjects/${a[2]}/topics/${a[4]}/contents`)
        //     }
        // } else {
        //     message.error(t("Please input content or upload file!!!"))
        // }
    }

    return (
        <Spin spinning={loading}>
            <div className="d-f justify-content-between">
                <CustomBreadcrumb arr={_breadcrump} />
            </div>
            <Form.Provider
                onFormFinish={onFinish}
            >
                <FeildsUi savedForms={savedForms} setFileKey={setFileKey} form={form} fileKey={fileKey} files={files} setFiles={setFiles} disabledFinish={clickFinish} />
            </Form.Provider>
        </Spin>
    )
}

export default SubjectContentCreate;