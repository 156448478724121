import { ChangeEvent } from "react";
import { Col, Input, Popconfirm, Row, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";
import isHasAuthRoute from "utils/isHasRoute";
import { _meta } from "./crud/types";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import InputMask from 'react-input-mask';
import useGetAllData from "hooks/useGetAllData";
import useDeleteOne from "hooks/useDeleteOne";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { BiRightArrowAlt } from "react-icons/bi";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import StatusChecker from "components/Status";
import { ButtonCreate } from "components/Buttons";
import ForTable from "components/ForTable";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import ForTitle from "components/ForTitles";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-phone-input-2/lib/style.css";
import FilterSelectComponent from "components/FilterSelect";
import CustomPagination from "components/Pagination";

const { Search } = Input;

const Teachers = () => {

  const { i18n, t } = useTranslation();
  const history = useHistory();

  const { value, writeToUrl } = useUrlQueryParams({})

  const debouncedUsername = useDebounce<string>(value.filter_like?.username, 500)
  const debouncedFirstName = useDebounce<string>(value.filter_like?.first_name, 500)
  const debouncedLastName = useDebounce<string>(value.filter_like?.last_name, 500)
  const debouncedPhone = useDebounce<string>(value.filter_like?.phone, 500)
  const debouncedEmail = useDebounce<string>(value.filter_like?.email, 500)
  const debouncedPassportNumber = useDebounce<string>(value.filter_like?.passport_number, 500)
  const debouncedPassportPin = useDebounce<string>(value.filter_like?.passport_pin, 500)

  const _delete = useDeleteOne();
  const users = useGetAllData({
    url: `users?expand=profile,userAccess&sort=-id${value.filter.kafedra_id ? "&kafedra_id="+value.filter.kafedra_id : ""}&filter-like=${JSON.stringify(value.filter_like)}&filter=${JSON.stringify({role_name: "teacher", region_id: value.filter.region_id, area_id: value.filter.area_id })}`,
    isCall: 'auto',
    perPage: value.perPage,
    currentPage: value.currentPage,
    refetch: [i18n.language, value.filter.kafedra_id, value.filter.region_id, value.filter.area_id, debouncedUsername, debouncedEmail, debouncedPassportNumber, debouncedPassportPin, debouncedPhone, debouncedFirstName, debouncedLastName, _delete.refetch],
  });

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (true) {
      writeToUrl({ name, value })
    }
  }

  return (
    <>
      <div>
        <ForTitle title="Teachers" buttons={[isHasAuthRoute("user_create") && <ButtonCreate onClick={() => history.push("/teacher_create")} />]}/>
        <Row gutter={[12, 12]} className='mb-2'>
        <FilterSelectComponent span={{lg: 6, xl: 4}} label="kafedra" name="kafedra_id" url="kafedras" permission="kafedra_index" />
        <FilterSelectComponent span={{lg: 6, xl: 4}} label="region" name="region_id" url="regions" permission="region_index" child_names={["area_id"]} />
        <FilterSelectComponent span={{lg: 6, xl: 4}} label="area" name="area_id" url="areas" permission="area_index" parent_name="region_id" />
        <Col xs={24} sm={24} md={12} lg={6} xl={{ span: 6, offset: 6 }}>
          <Search
            allowClear
            placeholder={t("Search by passport PIN ...")}
            name='passport_pin'
            value={value.filter_like?.passport_pin}
            onChange={handleSearch}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
        <ForTable toUrl={true} currentPage={value.currentPage} perPage={value.perPage} totalCount={users.data._meta.totalCount} setCurrentPage={() => {}} setPerPage={() => {}} searchInputs={[
          {
            index: 1,
            input:
            <Input allowClear placeholder={t("Search by last name") + " ..."} name='last_name' value={value.filter_like?.last_name} onChange={handleSearch}/>
          },
          {
            index: 2,
            input:
            <Input allowClear placeholder={t("Search by first name") + " ..."} name='first_name' value={value.filter_like?.first_name} onChange={handleSearch}/>
          },
          {
            index: 3,
            input:
            <Input allowClear placeholder={t("Search by username") + " ..."} name='username' value={value.filter_like?.username} onChange={handleSearch}/>
          },
          {
            index: 4,
            input:
            <Input allowClear placeholder={t("Search by phone") + " ..."} value={value.filter_like?.phone} name='phone' onChange={handleSearch} />
          },
          {
            index: 5,
            input:
            <Input allowClear placeholder={t("Search by email") + " ..."} name='email' value={value.filter_like?.email} onChange={handleSearch} />
          },
          {
            index: 6,
            input:
            <div className="d-flex">
                <span className="ant-input-affix-wrapper wp-25">
                    <InputMask mask="aa" placeholder="AA" className='ant-input' name="passport_seria" value={value.filter_like?.passport_seria} onChange={handleSearch} style={{ width: "50px" }} />
                </span>
                <span className="ant-input-affix-wrapper wp-85">
                    <InputMask mask="9999999" placeholder="9999999" className='ant-input' name="passport_number" value={value.filter_like?.passport_number} onChange={handleSearch} style={{ width: "100px" }} />
                </span>
            </div>
          }
        ]} loading={users.loading} datas={users.data.items} theads={
            [
              <>
                {t("Last name")}
              </>,
              <>
                {t("First name")}
              </>,
              <>
                {t("Username")}
              </>,
              <div className="res-1000" >
                {t("Phone")}
              </div>,
              <>
                {t("Email")}
              </>,
              <>
                {t("Passport seria and number")}
              </>,
              <>
                KPI
              </>,
              <>
                {t("Status")}
              </>,
              <>
                {t("Actions")}
              </>
            ]
          } tbodys={
            function(this: any){
              return [
                <>{isHasAuthRoute("user_view") ? <Link to={`/teacher-view/${this?.id}`}>{this?.profile?.last_name}</Link> : this?.profile?.last_name}</>,
                <>{isHasAuthRoute("user_view") ? <Link to={`/teacher-view/${this?.id}`}>{this?.profile?.first_name}</Link> : this?.profile?.first_name}</>,
                <>{isHasAuthRoute("user_view") ? <Link to={`/teacher-view/${this?.id}`}>{this?.username}</Link> : this?.username}</>,
                <div className="w_80">{this.profile?.phone}</div>,
                <>{this.email}</>,
                <>{this?.profile?.passport_seria && this?.profile?.passport_number ? this?.profile?.passport_seria + " " + this?.profile?.passport_number : ''}</>,
                <>
                <Link to={{
                  pathname: `/teacher_kpi/${this?.id}`,
                  search: `${this?.profile?.last_name} ${this?.profile?.first_name} ${this?.profile?.middle_name}`
                }} className="d-flex justify-content-center align-items-center">
                  {t('View')} <BiRightArrowAlt />
                </Link>
                </>,
                <>
                  <StatusChecker status={this?.status} isText="badge" />
                </>,
                <>
                  <div className="crud_actions_table">
                    {isHasAuthRoute("user_view") && (
                      <Tooltip placement="top" title={t("View")}>
                        <TbFileInvoice
                          className="view_btn"
                          onClick={() => {
                            history.push({
                              pathname: "teacher-view/" + this?.id,
                            });
                          }}
                        />
                      </Tooltip>
                    )}
                    {isHasAuthRoute("user_update") && (
                      <Tooltip placement="top" title={t("Edit")}>
                        <TbFilePencil
                          className="edit_btn"
                          onClick={() => {
                            history.push({
                              pathname: "/update_teacher/" + this?.id,
                            });
                          }}
                        />
                      </Tooltip>
                    )}
                    {isHasAuthRoute("user_delete") && (
                      <Tooltip placement="top" title={t("Delete")}>
                        <Popconfirm
                          placement="topLeft"
                          title={`${t("Deleted it")} ?`}
                          okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                          onConfirm={() => _delete.fetch(`/users/${this.id}`)}
                        >
                          <TbFileOff className="delete_btn" />
                        </Popconfirm>
                      </Tooltip>
                    )}
                  </div>
                </>
              ]
            }
          }/>
      </div>
    </>
  );
};
export default Teachers;
