import { Button, Divider, Form, Modal, Switch } from "antd";
import SingleInputLanguage from "components/MultipleInput/SingleInputLanguage";
import React from "react";
import { useTranslation } from "react-i18next";

const DormitoryCreateType: React.FC<{ visible: boolean, setVisible: React.Dispatch<React.SetStateAction<boolean>> }> = ({ visible, setVisible }) => {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [status, setStatus] = React.useState<boolean>(true)

    return (
        <Modal
            title="Talabalar turar joyi hujjat toifasini qo'shish"
            visible={visible}
            width={700}
            onCancel={() => setVisible(prevState => !prevState)}
            footer={null}
        >
            <Form
                form={form}
                layout='vertical'
            >
                <SingleInputLanguage layout="vertical" />
                <Divider />
                <div className="d-flex justify-content-between">
                    <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
                    <div className="ant-col">
                        <Button danger className="me-3" onClick={() => { setVisible(prevState => !prevState); form.resetFields() }} >{t('Cancel')}</Button>
                        <Button type="primary" htmlType="submit">{t('Save')}</Button>
                    </div>
                </div>
            </Form>
        </Modal>
    )

}



export default DormitoryCreateType;