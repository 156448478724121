import { Tag } from "antd";
import isRole from "utils/isRole";


/*

Exam status list
  STATUS_INACTIVE = 0;
  STATUS_ACTIVE = 1;
  STATUS_FINISHED = 2;
  STATUS_DISTRIBUTED = 3;
  STATUS_ANNOUNCED = 4;

Appeal status list 

    const STATUS_APPEAL_INACTIVE = 0;
    const STATUS_APPEAL_ACTIVE = 1;
    const STATUS_APPEAL_FINISHED = 2;
    const STATUS_APPEAL_DISTRIBUTED = 3;
    const STATUS_APPEAL_ANNOUNCED = 4;

 */


export const examStatusList: Array<{ value: number, name: string }> = [
    {
        name: "Faolmas",
        value: 0
    },
    {
        name: "Faol",
        value: 1
    },
    {
        name: "Yakunlangan",
        value: 2
    },
    {
        name: "Taqsimlangan",
        value: 3
    },
    {
        name: "E'lon qilingan",
        value: 4
    },
]

const checking_status = <Tag color="#E8F0FE" style={{ color: '#1A73E8', minWidth: '120px', textAlign: 'center' }} >Taqsimlandi</Tag>;
const active_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Faol</Tag>;
const inactive_status = <Tag color="#FCE8E6" style={{ color: '#C5221F', minWidth: '120px', textAlign: 'center' }} >Faolmas</Tag>;
const announced_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >E'lon qilindi</Tag>;
const finished_status = <Tag color="#E8F0FE" style={{ color: '#1A73E8', minWidth: '120px', textAlign: 'center' }} >Yakunlangan</Tag>;


export const exam_status_list = (status: number) => {

    if (isRole(['mudir', 'dean', 'edu_admin', 'rektor', 'admin'])) {

        switch (status) {
            case 0:
                return inactive_status;
            case 1:
                return active_status;
            case 2:
                return finished_status;
            case 3:
                return checking_status;
            case 4:
                return announced_status;
            default:
                return inactive_status;
        }

    } else {
        switch (status) {
            case 0:
                return inactive_status;
            case 1:
                return active_status;
            default:
                return active_status;
        }
    }
}




/*
Exam Student Status List

STATUS_INACTIVE = 0
STATUS_TAKED = 1
STATUS_COMPLETE = 2
STATUS_IN_CHECKING = 3
STATUS_CHECKED = 4
STATUS_SHARED = 5
 */

const exam_student_inactive_status = <Tag color="#FCE8E6" style={{ color: '#C5221F', minWidth: '120px', textAlign: 'center' }} >Faolmas</Tag>;
const exam_student_taked_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Topshirilmoqda</Tag>;
const exam_student_complete_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Complete</Tag>;
const exam_student_in_checking_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Tekshirilmoqda</Tag>;
const exam_student_checked_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Tekshirildi</Tag>;
const exam_student_shared_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >E'lon qilindi</Tag>;


export const exam_student_status_list = (status: number) => {

    switch (status) {
        case 0:
            return exam_student_inactive_status;
        case 1:
            return exam_student_taked_status;
        case 2:
            return exam_student_complete_status;
        case 3:
            return exam_student_in_checking_status;
        case 4:
            return exam_student_checked_status;
        case 5:
            return exam_student_shared_status;
        default:
            return exam_student_shared_status;
    }
}


const exam_isComformedTrue = <Tag className="mt-1" color="#bae7ff" style={{ color: '#002766', minWidth: '120px', textAlign: 'center' }}  >Taqsimlangan</Tag>;
const exam_isComformedFalse = "";


export const exam_isComformed = (status: number) => {

    switch (status) {
        case 0:
            return exam_isComformedFalse;
        case 1:
            return exam_isComformedTrue;
        default:
            return exam_isComformedFalse;
    }
}
