import styled, { css } from "styled-components";


export const ButtonMessageUi = styled.button`
    border:none;
    border-radius: 4px;
    padding: 3px 4px;
    width: 80px;

    svg {
        color:#595959;
    }

    &:hover {
         svg {
            color:#1890ff;
        }
    }
`


export const MessageUsersBoxUi = styled.div<{ isOpen: boolean }>`
    width: 250px;
    border-radius: 10px;
    background-color: #f4f6f8;
    padding: 10px 0;
    position:relative;

    & > span {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        svg {
            font-size: 22px;
            margin-right: 10px;
            cursor: pointer;
            display: none;
            transition: all 0.1s ease-in-out;
        }
    }

    @media (max-width: 786px) {
        width: 100%;
        ${props => props?.isOpen ?
        css`
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            `:
        css`
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            `
    }
        & > span {
            svg {
                display: block;
            }
        }
        & > div {
            position:absolute;
            width:100%;
            top:40px;
            left:0;
            display: ${props => props?.isOpen ? 'block' : 'none'};
            padding-bottom: ${props => props?.isOpen ? '10px' : '0'};
            background-color:#F4F6F8;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

  
`