import { FC } from "react";
import { Badge, Tag, Typography, Switch } from 'antd';
import './styles.scss';
import { useTranslation } from "react-i18next";
const { Text } = Typography;




export const StatusData: React.FC<{ status: number }> = ({ status }): JSX.Element => status ? <span className="status_data status_data_active">active</span> : <span className="status_data status_data_inactive">inactive</span>

const CustomBadgeComponent: FC<{ text: string, color: string, isText: any }> = ({ text, color, isText }): JSX.Element => {
    if (isText === "text") {
        return (
            <Text type="success">{text}</Text>
        )
    }
    else {
        return (
            <Badge count={text} style={{ backgroundColor: color }}></Badge>
        )
    }
}

const StatusChecker: FC<{ status: number | undefined | null, isText: 'text' | 'badge' }> = ({ status, isText }): JSX.Element => {
    const {t} = useTranslation()
    
        if (isText === "badge") {
            switch (Number(status)) {
                case 10:
                    return <Tag color="#E6F4EA" className="text-center min_w_100" style={{ color: '#137333' }}>{t("Active")}</Tag>
                case 5:
                    return <Tag color="#FCE8E6" className="text-center min_w_100" style={{ color: '#C5221F' }}>{t("Banned")}</Tag>
                case 0:
                    return <Tag color="#fff1b8" className="text-center min_w_100" style={{ color: '#ffc53d' }}>{t("Pending")}</Tag>
                case 20:
                    return <Tag color="#C26C03" className="text-center min_w_100" style={{ color: 'white' }}>{t("Ended")}</Tag>
                default:
                    return <CustomBadgeComponent text="error" color="#fa541c" isText={isText} />
            }
        } else {
            switch (Number(status)) {
                case 10:
                    return <CustomBadgeComponent text="Active" color="success" isText={isText} />
                case 5:
                    return <CustomBadgeComponent text="Pending" color="warning" isText={isText} />
                case 0:
                    return <CustomBadgeComponent text="Banned" color="danger" isText={isText} />
                default:
                    return <CustomBadgeComponent text="error" color="danger" isText={isText} />
            }
        }
    
    }
    
    
    
    
    export default StatusChecker;