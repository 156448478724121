
export const ServerErrors = (arr: any) => {

    let newArr: Array<{ name: string, errors: any }> = [];
    Object.entries(arr).forEach(([key, value]) => {
        newArr.push({ name: key, errors: value })
    })
    return newArr;
}


export const ServerErrorsAsObject = (arr: any) => {

    let newArr: Array<{ name: string, errors: any }> = [];
    Object.entries(arr).forEach(([key, value]) => {
        newArr.push({ name: key, errors: [value] })
    })
    return newArr;
}
