import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Select, Spin, message } from "antd";
import isHasAuthRoute from "utils/isHasRoute";
import "../styles/style.scss";
import styles from '../styles/styles.module.scss'
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { ITimeOptions, ITimeTable, ITimeTableParentNull, IWeek } from "models/others";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import CreateTimeTable from "../crud/create";
import { IPara } from "models/education";
import useDeleteOne from "hooks/useDeleteOne";
import { useAppDispatch } from "store/services";
import { TIMETABLE_ACTIONS } from "store/time_table";
import UpdateTimeTable from "../crud/edit";
import CreateChildTimeTable from '../crud/create_child';
import TimeTableUniqueCode from '../components/TimeTableUniqueCode';
import { ChildTimeTableItem, ParentTimeTableItem } from "../components/TimeTableItems";
import PageContentTitle from "components/Title";
import CustomPagination from "components/Pagination";
import useStoreData, { ReturnTypeUseStoreData } from "hooks/useStoreData";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { createTimeTable, updateTimeTable } from "../service";
import AttachingVariantsToTimeTable from "../components/AttachingVariantsToTimeTable";
import FilterSelectComponent from "components/FilterSelect";
const { Option } = Select;



const TimeTableByChild: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const dispatch: any = useAppDispatch();
    const [refetch, setRefetch] = React.useState<boolean>(false)
    const { value, writeToUrl } = useUrlQueryParams({ perPage: 100 });
    const _delete = useDeleteOne();
    const [timeOptionItems, setTimeOptionItems] = React.useState<ITimeOptions[]>([]);
    const timeOptionsAll: ReturnTypeUseStoreData<ITimeOptions> = useStoreData({ key: 'time_options', params: { "per-page": 0 } });
    const para = useGetAllData({ url: `/paras`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IPara>;
    const weeks = useGetAllData({ url: `/weeks`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IWeek>;
    const time_options = useGetAllData({ url: `/time-options?expand=faculty,eduSemester&filter=${JSON.stringify({ edu_semester_id: value.filter.edu_semester_id })}`, perPage: 0 }) as TypeReturnUseGetAllData<ITimeOptions>;


    const [checkingTimeTables, setCheckingTimeTables] = React.useState<ITimeTable[]>([]);


    // `/time-tables?sort=-id&expand=subject,teacher,subjectCategory&filter=${JSON.stringify({ room_id: value.filter?.room_id })}`
    const timeTables = useGetAllData({
        url: `/time-tables/parent-null?sort=-id&expand=seminar,week,para,subject,teacher,subjectCategory,building,room,language,child.seminar,child.week,child.para,child.subject,child.teacher,child.subjectCategory,child.building,child.room,child.language,seminar.subject,seminar.week,seminar.para,seminar.teacher,seminar.subjectCategory,seminar.building,seminar.room,seminar.language,seminar.child.subject,seminar.child.week,seminar.child.para,seminar.child.teacher,seminar.child.subjectCategory,seminar.child.building,seminar.child.room,seminar.child.language&filter=${JSON.stringify({ ...value.filter, archived: 0 })}`,
        isCall: 'auto',
        perPage: value.perPage,
        currentPage: value.currentPage,
        params: { kafedra_id: value.filter.kafedra_id },
        refetch: [
            value.filter?.room_id,
            _delete.refetch, refetch,
            value.filter.subject_id,
            value.filter.edu_semester_id,
            value.filter.teacher_access_id,
            value.filter.language_id,
            value.filter.subject_category_id,
            value.filter.para_id,
            value.filter.kafedra_id,
            value.filter.week_id,
            value.filter.time_option_id,
        ]
    }) as TypeReturnUseGetAllData<ITimeTableParentNull>;


    const deleteTimeTable = (time_table_id: number | null | undefined) => {
        _delete.fetch(`/time-tables/${time_table_id}`);
        dispatch(TIMETABLE_ACTIONS.closeModal())
    }


    const findElement = (id: number | null): ITimeOptions | Partial<ITimeOptions> => {
        if (!id) return {};

        const findTimeOption = timeOptionsAll.items.find(e => e.id === id);

        return findTimeOption ?? {};
    }


    const getVariants = async (edu_semester_id: number, language_id: number) => {
        try {
            const response = await instance({ url: `/time-options?expand=faculty,eduSemester&filter=${JSON.stringify({ edu_semester_id, language_id })}` });
            if (response.data?.status === 1) {
                setTimeOptionItems(response.data?.data?.items);
                if (Array.isArray(response.data?.data?.items) && !response.data?.data.items.length) {
                    message.warning("Ma'lumot topilmadi!");
                }
            } else {
                message.error(new Error(response.data?.message));
            }
        } catch (error) {

        }
    }

    const submitOption = async (time_table_id: number, time_option_id: number) => {
        try {
            const formdata = new FormData();
            formdata.append('time_option_id', String(time_option_id));

            const response = await instance({ url: `/time-tables/${time_table_id}`, method: "PUT", data: formdata });

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message).then(() => {
                    setRefetch(prevState => !prevState);
                    dispatch(TIMETABLE_ACTIONS.closeModal());
                })
            } else {
                asyncN("error", 'update', response.data?.message)
            }
        } catch (error) { }
    }

    return (
        <>
            <div className="not_user_select">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <PageContentTitle title={"Dars jadvalni tekshirish"} />
                </div>
                <hr />
                {isHasAuthRoute('time-table_create') && <CreateTimeTable setRefetch={setRefetch} />}
                {isHasAuthRoute('time-table_create') && <CreateChildTimeTable setRefetch={setRefetch} weeks={weeks.items} para={para.items} />}
                {isHasAuthRoute('time-table_update') && <UpdateTimeTable setRefetch={setRefetch} deleteTimeTable={deleteTimeTable} />}
                <div>
                    {
                        <span>
                            {
                                checkingTimeTables?.map((timeTableItem) => {
                                    return (
                                        <span
                                            key={timeTableItem.id}
                                            className={styles.created_time_table_box}
                                        >
                                            <span className={styles.info_text_subject} ><span>{timeTableItem?.building?.name}&nbsp;&nbsp;{timeTableItem?.room?.name?.replace(/\D/g, '')}-xona ({timeTableItem?.room?.capacity}).&nbsp;&nbsp;/&nbsp;<i style={{ color: 'blue' }}>{timeTableItem?.subjectCategory?.name}</i>&nbsp;/&nbsp;<i style={{ color: 'blue' }}>{timeTableItem?.language?.name}</i></span>{timeTableItem?.subject?.name}&nbsp;</span><br />
                                            <span className={styles.info_text_teacher}>{timeTableItem.teacher?.last_name}&nbsp;{timeTableItem.teacher?.first_name}&nbsp;{String(timeTableItem.teacher?.middle_name).charAt(0)}.</span>
                                            <TimeTableUniqueCode id={timeTableItem.id} lecture_id={timeTableItem.lecture_id} parent_id={timeTableItem.parent_id} subject_category_id={timeTableItem.subject_category_id} />
                                        </span>
                                    )
                                })
                            }
                        </span>
                    }
                </div>
                <Row gutter={[12, 12]}>
                    <FilterSelectComponent label="faculty" name="faculty_id" url="faculties" permission="faculty_index" child_names={["edu_plan_id", "edu_semestr_id"]} />
                    <FilterSelectComponent label="edu paln" name="edu_plan_id" url="edu-plans" permission="edu-plan_index" parent_name="faculty_id" child_names={["edu_semestr_id"]} />
                    <FilterSelectComponent label="edu semestr" name="edu_semestr_id" url="edu-semestrs" permission="edu-semestr_index" parent_name="edu_plan_id" />
                    <FilterSelectComponent label="para" name="para_id" url="paras" permission="para_index" />
                    <FilterSelectComponent label="kafedra" name="kafedra_id" url="kafedras" permission="kafedra_index" child_names={["subject_id"]} />
                    <FilterSelectComponent label="subject" name="subject_id" url="subjects" permission="subject_index" parent_name="kafedra_id" />
                    <FilterSelectComponent label="building" name="building_id" url="buildings" permission="building_index" child_names={["room_id"]} />
                    <FilterSelectComponent label="room" name="room_id" url="rooms" permission="room_index" parent_name="building_id" />
                    <FilterSelectComponent label="week" name="week_id" url="weeks" permission="week_index" />
                    <FilterSelectComponent label="language" name="language_id" url="languages" permission="languages_index" />
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Select
                            className="w-100"
                            placeholder="Select a time option"
                            allowClear
                            value={value.filter?.time_option_id}
                            onFocus={() => time_options.fetch()}
                            disabled={!Boolean(value.filter?.edu_semester_id)}
                            onChange={(e: number) => writeToUrl({ name: 'time_option_id', value: e, items: time_options.items })}
                            showSearch
                            filterOption={(input, option) =>
                                (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                            }
                        >
                            {
                                time_options.items.length ? time_options.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name} - {element.faculty?.name} - {element.eduSemester?.name}</Option>) :
                                    value.item?.room_id ? [value.item?.time_option_id].map((element) => <Option key={element?.id} value={element?.id}>{element?.name}</Option>) : null
                            }
                        </Select>
                    </Col>
                </Row>
                <hr />
                <Spin spinning={timeTables.loading} >
                    <Row gutter={[12, 12]} >
                        {
                            timeTables.items.length ?
                                timeTables.items.sort((a, b) => Number(a?.lecture_id) - Number(b?.lecture_id)).map((parentElement) => {

                                    if (parentElement.lecture_id) return null;
                                    // only seminar subjects

                                    const timeOptionItem = findElement(parentElement.time_option_id);
                                    if (parentElement.subject_category_id !== 1) {
                                        return (
                                            <Col key={parentElement.id} xl={12} lg={12} md={12} sm={12} xs={21} style={{ border: '1px solid #1890FF', borderRadius: "4px" }} className="px-2 pb-4 pt-2">
                                                <AttachingVariantsToTimeTable
                                                    isType="2"
                                                    item={timeOptionItem}
                                                    timeOptionItems={timeOptionItems}
                                                    getVariants={getVariants}
                                                    submitOption={submitOption}
                                                    element={parentElement}
                                                />
                                                <div>
                                                    <ParentTimeTableItem update={updateTimeTable} create={createTimeTable} element={parentElement} />
                                                    {
                                                        parentElement.child?.map((childElement) => <ChildTimeTableItem key={childElement.id} update={updateTimeTable} element={childElement} />)
                                                    }
                                                </div>
                                            </Col>
                                        )
                                    } else {
                                        // subjects with maruza and seminar

                                        return (
                                            <Col key={parentElement.id} xl={24} style={{ border: '1px solid #1890FF', borderRadius: "4px" }} className="px-2 pb-4 pt-2">
                                                <Row gutter={[12, 12]}>
                                                    {
                                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                            <AttachingVariantsToTimeTable
                                                                isType="1"
                                                                item={timeOptionItem}
                                                                timeOptionItems={timeOptionItems}
                                                                getVariants={getVariants}
                                                                submitOption={submitOption}
                                                                element={parentElement}
                                                            />
                                                        </Col>
                                                    }
                                                    <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                                                        <div>
                                                            <ParentTimeTableItem create={createTimeTable} update={updateTimeTable} element={parentElement} />
                                                            {
                                                                parentElement.child?.map((childElement) => <ChildTimeTableItem key={childElement.id} update={updateTimeTable} element={childElement} />)
                                                            }
                                                        </div>
                                                    </Col>
                                                    {
                                                        parentElement.seminar?.map((seminarParentItem) => {
                                                            return (
                                                                <Col xl={6} lg={6} md={8} sm={12} xs={24} key={seminarParentItem.id}>
                                                                    <div className="px-2">
                                                                        <ParentTimeTableItem create={createTimeTable} update={updateTimeTable} element={seminarParentItem} />
                                                                        {
                                                                            seminarParentItem.child?.map((childSeminarItem) => <ChildTimeTableItem key={childSeminarItem.id} update={updateTimeTable} element={childSeminarItem} />)
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Col>
                                        )
                                    }
                                }) : null
                        }
                    </Row>
                </Spin>
                <div className="mt-3">
                    <CustomPagination totalCount={timeTables?.data?._meta?.totalCount} perPage={value?.perPage} currentPage={value?.currentPage} />
                </div>
            </div >
        </>
    );
};

export default TimeTableByChild;


/*


time-table_create
time-table_delete
time-table_index
time-table_parent-null
time-table_update
time-table_view

*/