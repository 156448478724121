import { Route } from 'react-router-dom';
import Layout from "components/structure/LayoutStructure/Layout";
import NonLayout from "components/structure/LayoutStructure/NonLayout";


const RenderComponent = ({ component: Component, structure }: any):JSX.Element => {

    return (
        <Route
            render={(props: any) =>  {
                if (structure === 'layout') {
                    return (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )
                } else {
                    return (
                        <NonLayout>
                            <Component {...props} />
                        </NonLayout>
                    )
                }

            }}
        />
    )
}

export default RenderComponent;