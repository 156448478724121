import React, { useMemo } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import FormLabel from "components/FormLabel";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import useLanguage from "hooks/useLanguage";

const { Option } = Select;

type PropsMultipleInput = {
    layout: 'vertical' | 'horizontal',
    url?: Boolean
}

function MultipleInput({layout, url }: PropsMultipleInput): JSX.Element {

    const { t } = useTranslation();
    const { languages } = useLanguage();
    const [names, setNames] = React.useState<Array<string>>(['uz']);



    const onAddNewForm = React.useCallback(() => {
        const langId = languages.items.filter(e => !names.includes(e.lang_code))
        if (langId.length) {
            setNames(prevState => ([...prevState, langId[0].lang_code]))
        }
    }, [languages, names])


    const removeInput = React.useCallback((id: string) => {
        const newNames = names.filter(e => e !== id);
        setNames(newNames)
    }, [names]);


    const selectLanguage = React.useCallback((event_lang_code: string, element_lang_code: string) => {

        const index = names.indexOf(element_lang_code);

        if (!names.includes(event_lang_code)) {

            names.splice(index, 1, event_lang_code);
            setNames([...names]);

        } else {
            return
        }

    }, [names])

    const xl_lg = useMemo(() => layout === 'horizontal' ? 12 : 24, [layout])

    return (
        <Row gutter={[12, 0]}>
            {
                names?.map((e: any) => {
                    return (
                        <React.Fragment key={e} >
                            <Col xl={{ span: xl_lg }} lg={{ span: xl_lg }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                <Form.Item
                                    label={<FormLabel>{`${t("Name")} (${e})`}</FormLabel>}
                                    name={url ? ('name') : (`name[${e}]`)}
                                    shouldUpdate
                                    rules={[{ required: true, message: `Please input name(${e}) ...` }]}
                                    className="mb-2"
                                >
                                    <Input size="small" placeholder={`${t("Enter the name")} (${e}) ...`} className="ant_input_custom" />
                                </Form.Item>

                            </Col>
                            <Col key={e} xl={{ span: xl_lg }} lg={{ span: xl_lg }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                <Form.Item
                                    label={<FormLabel>{`${t("Description")} (${e})`}</FormLabel>}
                                    name={`description[${e}]`}
                                    shouldUpdate
                                    rules={[{ required: false, message: `Please input description(${e}) ...` }]}
                                    className="mb-2"
                                >
                                    <Row justify="space-between" align="middle" wrap={false}>
                                    <Input.TextArea rows={1} placeholder={`${t("Enter the description")} (${e}) ...`}  className="ant_input_custom" />
                                    <Select defaultValue={e} className="mx-2 w_110" onChange={(event_lang_code: string) => selectLanguage(event_lang_code, e)}>
                                        {
                                            languages.items.length && languages.items.map((l: any) => {
                                                if (!names.includes(l.lang_code) || l.lang_code === e) {
                                                    return <Option key={l.id} value={l.lang_code}>{l.name}</Option>
                                                }
                                            })
                                        }
                                    </Select>
                                    <Button ghost className="bg-white" onClick={() => removeInput(e)} disabled={names.length === 1} >
                                        <FaTimes />
                                    </Button>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </React.Fragment>
                    )
                })
            }
            <Col xl={24} xs={24} className="">
                {names.length !== languages.items.length ?
                    <Button type="link" className="ps-0" onClick={onAddNewForm}>+ {t("Add translation")}</Button> :
                    <Button type="link" className="ps-0" danger>+ {t("Add translation")}</Button>}
            </Col>
        </Row>
    )
}



export default MultipleInput;