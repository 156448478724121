import { FC } from "react";
import { Empty } from "antd";



const EmptyTable: FC<{ data: Array<any>, loading: boolean, description?: string, searchText?: string }> = ({ data, loading, description, searchText }): any => {


    if (data && !data.length && !loading) {
        return (
            <>
                <Empty description={<span style={{ color: '#0d8a71' }}> {description ? description : (searchText ? "Ma'lumot topilmadi !" : "Ma'lumot qo'shilmagan !")}</span>} className="mt-5" />
            </>
        )
    }

    return null;
}

export default EmptyTable;