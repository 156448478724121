import { useState } from "react";
import { useTranslation } from "react-i18next";
import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
import useGetAllData from "hooks/useGetAllData";
import useDeleteOne from "hooks/useDeleteOne";
import SimpleEdit from "pages/common/simple_edit";
import SimpleView from "pages/common/simple_view";
import SimpleCreate from "./simple_create";
import { ButtonCreate } from "components/Buttons";
import Actions from "components/Actions";
import TotalCount from "components/totalCountTag";
import { Pagination } from "antd";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import CustomPagination from "components/Pagination";
import useUrlQueryParams from "hooks/useUrlQueryParams";

type TypeSimpleCreateProps = {
    url: string,
    indexTitle?: string,
    createTitle?: string,
    editTitle: string,
    viewTitle: string,
    isPagination?: boolean,
    permissions: {
        view_: string,
        delete_: string,
        update_: string,
        create_: string
    },
    expand?: boolean,
    customInputs?: Array<{
        name: string,
        title: string
    }> | undefined
}

const SimpleIndex: React.FC<TypeSimpleCreateProps> = ({ customInputs, indexTitle, createTitle, editTitle, permissions, url, viewTitle, isPagination, expand }): JSX.Element => {

    const { t } = useTranslation();
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [reFetch, setReFetch] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const {value, writeToUrl} = useUrlQueryParams({})

    const _delete = useDeleteOne();
    const { data, loading } = useGetAllData({
        url: expand ? url + '?expand=description' : url,
        perPage: value.perPage,
        currentPage: value.currentPage,
        isCall: 'auto',
        refetch: [_delete.refetch, reFetch]
    });

    return (
        <div>
            {indexTitle ? <ForTitle title={indexTitle} buttons={[isHasAuthRoute(permissions.create_) && <ButtonCreate onClick={() => setVisibleCreate(true)} />]}/>
            : null}
            { visibleView && id ? <SimpleView visible={visibleView} setVisible={setVisibleView} url={expand ? `/${url}/${id}?expand=createdBy,updatedBy,description` : `${url}/${id}`} title={viewTitle} /> : null}
            { visibleEdit && id ? <SimpleEdit visible={visibleEdit} setVisible={setVisibleEdit} setReFetch={setReFetch} url={expand ? `/${url}/${id}?expand=description` : `${url}/${id}`} title={editTitle} /> : null}
            { createTitle && visibleCreate ? <SimpleCreate customInputs={customInputs} visible={visibleCreate} setVisible={setVisibleCreate} setReFetch={setReFetch} url={`/${url}`} title={createTitle} /> : null}
            <ForTable perPage={value.perPage} toUrl={true} setPerPage={() => {}} currentPage={value.currentPage} setCurrentPage={() => {}} totalCount={data._meta.totalCount} datas={data.items} theads={
                    [
                        "Name",
                        "Description",
                        "Status",
                        "Actions"
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            <>{this.name}</>,
                            <>{this?.description}</>,
                            <><BadgeStatus status={this.status} /></>,
                            <>
                                <Actions
                                    id={this?.id}
                                    url={url}
                                    setId={setId}
                                    setReFetch={setReFetch}
                                    setVisibleView={setVisibleView}
                                    setVisibleEdit={setVisibleEdit}
                                    viewPermission= {permissions.view_}
                                    editPermission= {permissions.update_}
                                    deletePermission= {permissions.delete_}
                                />
                            </>
                        ]
                    }
                } loading={loading}/>
        </div>
    )
}

export default SimpleIndex;
