import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table";
import { Row, Button, Spin } from "antd";
import isHasAuthRoute from "utils/isHasRoute";
import IndexPageTitle from "components/IndexPageTitle";
import "./styles/style.scss";
import styles from './styles/styles.module.scss'
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { ITimeTable, IWeek } from "models/others";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import CreateTimeTable from "./crud/create";
import { IPara } from "models/education";
import useDeleteOne from "hooks/useDeleteOne";
import { useAppDispatch } from "store/services";
import { TIMETABLE_ACTIONS } from "store/time_table";
import UpdateTimeTable from "./crud/edit";
import CreateChildTimeTable from './crud/create_child';
import TimeTableUniqueCode from './components/TimeTableUniqueCode';
import { NavLink } from "react-router-dom";
import CustomPagination from "components/Pagination";
import { createChildTimeTable, updateTimeTable } from "./service";
import CardContainer from "components/Widgets/CardContainer";
import { FiInfo } from "react-icons/fi";
import { FaRegBuilding } from "react-icons/fa";
import { BiBookOpen, BiUserVoice } from "react-icons/bi";
import FilterSelectComponent from "components/FilterSelect";

const TimeTable: FC = (): JSX.Element => {

  const { t } = useTranslation();
  const dispatch: any = useAppDispatch();
  const [refetch, setRefetch] = React.useState<boolean>(false)
  const [currentId, setCurrentId] = React.useState<undefined | number>(undefined);
  const { value } = useUrlQueryParams({ perPage: 100 });
  const _delete = useDeleteOne();
  const para = useGetAllData({ url: `/paras`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IPara>;
  const weeks = useGetAllData({ url: `/weeks`, isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<IWeek>;
  const [checkingTimeTables, setCheckingTimeTables] = React.useState<ITimeTable[]>([])

  const timeTables = useGetAllData({
    url: `/time-tables?sort=-id&expand=subject,teacher,subjectCategory,building,room,language,week,para,archived&filter=${JSON.stringify({ ...value.filter })}`,
    isCall: 'auto',
    perPage: value.perPage,
    currentPage: value.currentPage,
    refetch: [value.filter?.room_id, _delete.refetch, refetch, value.filter.subject_id, value.filter.edu_semester_id, value.filter.teacher_access_id, value.filter.language_id, value.filter.subject_category_id, value.filter.para_id, value.filter.faculty_id]
  }) as TypeReturnUseGetAllData<ITimeTable>;

  const filterTimeTable = React.useCallback((para_id: number, week_id: number) => {
    if (timeTables.items.length) {

      const filteredTimeTables = timeTables.items.filter(element => element.week_id === week_id && element.para_id === para_id);

      return filteredTimeTables;

    }
  }, [timeTables])


  const deleteTimeTable = (time_table_id: number | null | undefined) => {
    _delete.fetch(`/time-tables/${time_table_id}`);
    dispatch(TIMETABLE_ACTIONS.closeModal())
  }

  console.log("para and week", para, weeks)

  return (
    <div className="not_user_select">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <IndexPageTitle title={t("Time table")} />
        {isHasAuthRoute('time-table_index') ? <NavLink to="/time-table_child">
          <Button type="primary" ghost >Fan bo'yicha dars jadval</Button>
        </NavLink> : null}
      </div>
      <hr />
      {isHasAuthRoute('time-table_create') && <CreateTimeTable setRefetch={setRefetch} />}
      {isHasAuthRoute('time-table_create') && <CreateChildTimeTable setRefetch={setRefetch} weeks={weeks.items} para={para.items} />}
      {isHasAuthRoute('time-table_update') && <UpdateTimeTable setRefetch={setRefetch} deleteTimeTable={deleteTimeTable} />}
      <div>
        {
          <span>
            {
              checkingTimeTables?.map((timeTableItem) => {
                return (
                  <span
                    key={timeTableItem.id}
                    className={styles.created_time_table_box}
                  >
                    <span className={styles.info_text_subject} ><span>{timeTableItem?.building?.name}&nbsp;&nbsp;{timeTableItem?.room?.name?.replace(/\D/g, '')}-xona ({timeTableItem?.room?.capacity}).&nbsp;&nbsp;/&nbsp;<i style={{ color: 'blue' }}>{timeTableItem?.subjectCategory?.name}</i>&nbsp;/&nbsp;<i style={{ color: 'blue' }}>{timeTableItem?.language?.name}</i></span>{timeTableItem?.subject?.name}&nbsp;</span><br />
                    <span className={styles.info_text_teacher}>{timeTableItem.teacher?.last_name}&nbsp;{timeTableItem.teacher?.first_name}&nbsp;{String(timeTableItem.teacher?.middle_name).charAt(0)}.</span>
                    <TimeTableUniqueCode id={timeTableItem.id} lecture_id={timeTableItem.lecture_id} parent_id={timeTableItem.parent_id} subject_category_id={timeTableItem.subject_category_id} />
                  </span>
                )
              })
            }
          </span>
        }
      </div>
      <Row gutter={[12, 12]}>
        <FilterSelectComponent label="faculty" name="faculty_id" url="faculties" permission="faculty_index" child_names={["edu_plan_id", "edu_semestr_id", "subject_id"]} />
        <FilterSelectComponent label="edu paln" name="edu_plan_id" url="edu-plans" permission="edu-plan_index" parent_name="faculty_id" child_names={["edu_semestr_id", "subject_id"]} />
        <FilterSelectComponent label="edu semestr" name="edu_semestr_id" url="edu-semestrs" permission="edu-semestr_index" parent_name="edu_plan_id" child_names={["subject_id"]} />
        <FilterSelectComponent label="subject" name="subject_id" url="edu-semestr-subjects?expand=subject" permission="edu-semestr-subject_index" parent_name="edu_semestr_id" value_name="subject" />
        <FilterSelectComponent label="para" name="para_id" url="paras" permission="para_index" />
        <FilterSelectComponent label="language" name="language_id" url="languages" permission="languages_index" />
        <FilterSelectComponent label="building" name="building_id" url="buildings" permission="building_index" child_names={["room_id"]} />
        <FilterSelectComponent label="room" name="room_id" url="rooms" permission="room_index" parent_name="building_id" />
        <FilterSelectComponent label="subject category" name="subject_category_id" url="subject-categories" permission="subject-category_index" />
      </Row>
      <Spin spinning={timeTables.loading} >
        <Table
          id="tech-companies-1"
          className="table table-bordered mt-3"
        >
          <Thead style={{ position: 'sticky', top: -13, backgroundColor: '#EEF1F3', zIndex: 1 }}>
            <Tr>{weeks.items.length ? weeks.items.map((weekItem, weekIndex) => Boolean(weekItem?.status) ? <Th key={weekIndex}>{weekItem?.name}</Th> : null) : null}</Tr>
          </Thead>
          <Tbody>

            {
              para.items.length ? para.items.map((paraItem) => {

                return (
                  <Tr key={paraItem.id}>
                    {
                      weeks.items.length ? weeks.items.map((weekItem) => {

                        if (!Boolean(weekItem?.status)) return null;

                        const _options = filterTimeTable(paraItem.id, weekItem.id);

                        return (
                          <Td key={weekItem.id} style={{ width: 'calc(100% / 5)' }}>
                            <div className={styles.time_table_title}>
                              <span className={styles.time_lesson} >
                                {paraItem?.name}&nbsp;&nbsp;◷&nbsp;{paraItem?.start_time}-{paraItem?.end_time}
                              </span>
                              <Button
                                type="primary"
                                size="small"
                                className="py-0 fs-17 d-flex align-items-center justify-content-center"
                                shape="circle"
                                onClick={() => {
                                  dispatch(TIMETABLE_ACTIONS.create({ modal: 'create', element: { week_id: weekItem?.id, week_name: weekItem?.name, para_id: paraItem?.id, para_name: `${paraItem?.start_time} - ${paraItem?.end_time}` } }))
                                }}
                              >+</Button>
                            </div>
                            {
                              <span>
                                {
                                  _options?.map((timeTableItem) => {
                                    return (
                                      <span
                                        key={timeTableItem.id}
                                        className={styles.created_time_table_box}
                                        style={timeTableItem.parent_id === currentId || timeTableItem.id === currentId ? { border: '1px solid #faad14' } : {}}
                                        onClick={() => createChildTimeTable(timeTableItem)}
                                        onContextMenu={(e) => { { updateTimeTable(timeTableItem, paraItem, weekItem); e.preventDefault() } }}
                                        onMouseEnter={() => setCurrentId(timeTableItem.parent_id ?? timeTableItem.id)}
                                        onMouseLeave={() => setCurrentId(undefined)}
                                      >
                                        {/* <p><FiKey />&nbsp;<span>ID: <i style={{ color: 'red' }}>{timeTableItem?.id}</i></span></p> */}
                                        <p ><FaRegBuilding />&nbsp;<span>{timeTableItem?.building?.name}&nbsp;/&nbsp;{timeTableItem?.room?.name} ({timeTableItem?.room?.capacity})</span></p>
                                        <p><FiInfo />&nbsp;<span style={{ fontWeight: 500 }} ><i style={{ color: 'blue' }}>{timeTableItem?.subjectCategory?.name}</i>&nbsp;/&nbsp;<i style={{ color: 'blue' }}>{timeTableItem?.language?.name}</i></span></p>
                                        <p className={styles.info_text_subject}><BiBookOpen />&nbsp;{timeTableItem.subject?.name}</p>
                                        <p className={styles.info_text_teacher}><BiUserVoice />&nbsp;{timeTableItem.teacher?.last_name}&nbsp;{timeTableItem.teacher?.first_name}.&nbsp;{String(timeTableItem.teacher?.middle_name).charAt(0).toUpperCase()}</p>

                                        {/* <span className={styles.info_text_subject} ><span>{timeTableItem?.building?.name}&nbsp;&nbsp;{timeTableItem?.room?.name?.replace(/\D/g, '')}-xona ({timeTableItem?.room?.capacity}).&nbsp;&nbsp;/&nbsp;
                                          <i style={{ color: 'blue' }}>{timeTableItem?.subjectCategory?.name}</i>&nbsp;/&nbsp;<i style={{ color: 'blue' }}>{timeTableItem?.language?.name}</i></span>{timeTableItem?.subject?.name}&nbsp;</span><br />
                                          <span className={styles.info_text_teacher}>{timeTableItem.teacher?.last_name}&nbsp;{timeTableItem.teacher?.first_name}&nbsp;{String(timeTableItem.teacher?.middle_name).charAt(0)}.</span> */}
                                        <TimeTableUniqueCode id={timeTableItem.id} lecture_id={timeTableItem.lecture_id} parent_id={timeTableItem.parent_id} subject_category_id={timeTableItem.subject_category_id} />
                                      </span>
                                    )
                                  })
                                }
                              </span>
                            }
                          </Td>
                        )

                      }) : null
                    }
                  </Tr>
                )
              }) : null
            }
          </Tbody>
        </Table>
      </Spin>
      <CustomPagination totalCount={timeTables?.data?._meta?.totalCount} perPage={value?.perPage} currentPage={value?.currentPage} />
    </div >
  );
};

export default TimeTable;


/*

time-table_create
time-table_delete
time-table_index
time-table_parent-null
time-table_update
time-table_view

*/