import { TypeStudentEDD } from "./types";

/**
 * @description Student Excel Default Data (initial values that showing in modal excel export)
 */

export const StudentEDD: TypeStudentEDD = {
    visible: false,
    address: true,
    area: true,
    birthday: true,
    country: true,
    edu_plan: true,
    faculty: true,
    first_name: true,
    lang: true,
    last_name: true,
    middle_name: true,
    nationality: true,
    passport_given_by: true,
    passport_given_date: true,
    passport_issued_date: true,
    passport_pin: true,
    passport_seria: true,
    password: true,
    region: true,
    phone: true,
    secound_phone: true,
    username: true
}

export const personal_data_fields = [
    "avatar",
    "first_name",
    "last_name",
    "middle_name",
    "phone",
    "phone_secondary",
    "birthday",
    "status",
    "gender",
    "description",
    "passport_seria_and_number",
    "passport_pin",
    "passport_given_by",
    "passport_date",
    "citizenship_id",
    "nationality_id",
    "diplom_seria",
    "diplom_number",
    "diplom_file",
    "email"
];
export const cu_education_fields = [
    "is_contract",
    "course_id",
    "faculty_id",
    "direction_id",
    "edu_year_id",
    "edu_plan_id",
    "edu_type_id",
    "edu_form_id",
    "edu_lang_id",
    "student_category_id",
    "tutor_id"
];

export const cu_additional_fields = [
    'email',
    'phone_secondary',
    'country_id',
    'region_id',
    'area_id',
    'address',
    "diplom_seria",
    "diplom_number",
    "diplom_file",
    "last_education",
    "social_category_id",
    "partners_count",
    "category_of_cohabitant_id",
    "residence_status_id",
    "live_location",
];
export const cu_parent_info_fields = [
    'father_name',
    'phone_secondary',
    'father_info',
    'mather_name',
    'mather_phone',
    'mather_info',
];


export const studentTabFields: Record<number, string[]> = {
    1: cu_education_fields,
    2: cu_additional_fields,
    3: cu_parent_info_fields,
}





export const checkStatusState = (status: number) => {
    switch (status) {
        case 10:
            return {
                color: "#73d13d",
                text: "active"
            }
        case 5:
            return {
                color: "#ffe58f",
                text: "banned"
            }
        default:
            return {
                color: "#ff7875",
                text: "pending"
            }
    }
}


/*

from hemis
*/


export type TypeComparingStudentData = {
    id: number,
    title: string,
    field1: string,
    field2: string,
    type: 'select' | 'input' | 'check' | 'input_mask' | 'date',
    required: false,
    mask: string,
    placeholder: string
}

export const comparingStudentData: TypeComparingStudentData[] = [
    {
        id: 3,
        title: "Talaba ismi",
        field1: 'firstname',
        field2: 'first_name',
        type: 'input',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 4,
        title: "Talaba familiyasi",
        field1: 'lastname',
        field2: 'last_name',
        type: 'input',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 5,
        title: "Talaba otasining ismi",
        field1: 'fathername',
        field2: 'middle_name',
        type: 'input',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 2,
        title: "Shaxsni tasdiqlovchi hujjat seriyasi va raqami",
        field1: 'serial_number',
        field2: 'passport_seria_and_number',
        type: 'input_mask',
        required: false,
        mask: "aa 9999999",
        placeholder: "AA 9999999"
    },
    {
        id: 1,
        title: "Shaxsni tasdiqlovchi hujjat JSHSHIR",
        field1: 'pinfl',
        field2: 'passport_pin',
        type: 'input_mask',
        required: false,
        mask: "99999999999999",
        placeholder: "_ _ _ _ _ _ _ _ _ _ _ _ _ _"
    },
    {
        id: 6,
        title: "Tug'ilgan sana",
        field1: 'birthday',
        field2: 'birthday',
        type: 'date',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 7,
        title: "Jinsi",
        field1: 'gender_name',
        field2: 'gender',
        type: 'check',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 8,
        title: "Millati",
        field1: 'nationality_name',
        field2: 'nationality_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 9,
        title: "Fuqaroligi",
        field1: 'citizenship_name',
        field2: 'citizenship_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 10,
        title: "Ta'lim turi",
        field1: 'education_type_name',
        field2: 'edu_type_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 11,
        title: "Ta'lim shakli",
        field1: 'education_form_name',
        field2: 'edu_form_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 12,
        title: "To'lov shakli",
        field1: 'payment_type_name',
        field2: 'is_contract',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 13,
        title: "Fakultet",
        field1: 'faculty_name',
        field2: 'faculty_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 14,
        title: "Yo'nalish",
        field1: 'speciality_name',
        field2: 'direction_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 15,
        title: "Kursi",
        field1: 'course',
        field2: 'course_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 16,
        title: "Mamlakat",
        field1: 'country_name',
        field2: 'permanent_country_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 17,
        title: "Viloyat",
        field1: 'region',
        field2: 'permanent_region_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 18,
        title: "Tuman",
        field1: 'district',
        field2: 'permanent_area_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 19,
        title: "Doimiy manzil",
        field1: 'address',
        field2: 'permanent_address',
        type: 'input',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 20,
        title: "Vaqtincha manzil",
        field1: 'address_current',
        field2: 'address',
        type: 'input',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 21,
        title: "",
        field1: 'accomodation_name',
        field2: '',
        type: 'input',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 22,
        title: "Birga yashaydiganlar toifasi",
        field1: 'social_category_name',
        field2: 'social_category_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 23,
        title: "Ta'lim tili",
        field1: 'education_language_name',
        field2: 'edu_lang_id',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
    {
        id: 24,
        title: "Holat",
        field1: 'status_name',
        field2: 'status',
        type: 'select',
        required: false,
        mask: "",
        placeholder: ""
    },
]