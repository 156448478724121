import { Button, Layout } from "antd";
// import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./style.scss";
import SidebarMenu from "./sidebarMenu";
import { BiLeftIndent, BiRightIndent } from "react-icons/bi";
import logo from "assets/images/logo.svg";
import { RiWechatLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import RippleButton from "components/structure/dashboard/common/Header/components/RippleButton";
import LanguageDropdown from "components/structure/dashboard/common/Header/components/DropdownLanguage";
import { ThemeType } from "config/theme/type";
import { activeColor } from "config/theme/color";
import UserProfile from "components/structure/dashboard/common/Header/components/UserProfile";
import { useAppSelector } from "store/services";
import { BsBellFill } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";
import { sidebarStateNew } from "store/structure/newSidebar";
import { useDispatch } from "react-redux";
import { TbBuildingBank } from "react-icons/tb";

const { Header, Sider, Content } = Layout;

const LayoutNew = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { i18n } = useTranslation();
  const sidebar = useAppSelector((state) => state?.sidebar);
  const history = useHistory();
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
  const [collapsed, setCollapsed] = useState(windowSize < 740);
  const theme: ThemeType = activeColor();
  const dispatch = useDispatch();
  const auth: any = useAppSelector((state) => state.auth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize(window.innerWidth);
      setWindowHeight(window.innerHeight);
      if (window.innerWidth < 1200) {
        dispatch(sidebarStateNew({ collapse: true }));
      } else {
        dispatch(sidebarStateNew({ collapse: false }));
      }
    });
  });

  return (
    <Layout style={{ position: "relative" }}>
      <Sider
        width={320}
        collapsedWidth={windowSize < 768 ? 0 : 80}
        style={{
          backgroundColor: sidebar?.collapse ? "rgb(13,138,114)" : "white",
          height: windowHeight - 20,
          width: "320px",
          position: windowSize < 768 ? "absolute" : "fixed",
          top: 0,
          left: 0,
          zIndex: 1000,
          margin: '10px',
          borderRadius: 8,
          overflow: 'hidden',
        }}
        trigger={null}
        collapsible
        collapsed={sidebar?.collapse}
      >
        <div>
          <div
            className="d-f justify-content-center"
            style={{
              height: "65px",
              backgroundColor: "rgb(13,138,114)",
              color: "white",
              fontSize: "22px",
              fontWeight: "600",
              // borderBottom: "1px solid rgba(255,255,255,.5)",
            }}
          >
            {sidebar?.collapse ? (
              // <img width={40} height={40} src={logo} alt="" />
              <TbBuildingBank size={35} />
            ) : (
              <>
                <div className="d-f justify-content-center align-center">
                  <span className={"me-3"}>TEXNIKUM</span>
                  <TbBuildingBank />
                </div>
                {windowSize < 768 ? (
                  <span style={{ position: "absolute", right: 0 }}>
                    <Button
                      className="collape-sidebar-button"
                      style={{
                        backgroundColor: "rgba(13,138,114,0)",
                        border: "none",
                      }}
                      onClick={() =>
                        dispatch(
                          sidebarStateNew({ collapse: !sidebar?.collapse })
                        )
                      }
                    >
                      {sidebar?.collapse ? (
                        <FaBars color="white" />
                      ) : (
                        <FaTimes color="white" />
                      )}
                    </Button>
                  </span>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <SidebarMenu windowSize={windowSize} collapce={sidebar?.collapse} />
        </div>
        <UserProfile theme={theme} auth={auth} collapse={sidebar?.collapse}/>
      </Sider>
      <Layout className="site-layout" style={{
        marginLeft: windowSize > 768 ? sidebar?.collapse ? 100 : 340 : 0,
        transition: 'all .2s'
      }}>
        <Header
          style={{
            height: "65px",
            marginRight: 10,
            marginTop: 10,
            borderRadius: 8,
            marginLeft: 5,
            zIndex: 1000,
          }}
          className="layout-header-style d-f justify-content-between"
        >
          <Button
            className="collape-sidebar-button border-0"
            style={{
                    backgroundColor: "rgb(13,138,114)",
                  }}
            onClick={() =>
              dispatch(sidebarStateNew({ collapse: !sidebar?.collapse }))
            }
          >
            {sidebar?.collapse ? (
              <BiRightIndent color="white" size={20} />
            ) : (
              <BiLeftIndent color="white" size={20} />
            )}
          </Button>
          <div className="d-f">
            <div className="me-2">
              <RippleButton
                color={`rgb(13,138,114)`}
                item={<LanguageDropdown color={"rgb(13,138,114)"} />}
              ></RippleButton>
            </div>
            <Link to={'/message'}
              className="me-2"
            ><RiWechatLine size={20} color="#fff" /></Link>
          </div>
        </Header>
        <Content
          className="site-layout-background content_card"
          style={{
            marginTop: 15,
            marginRight: 10,
            marginLeft: 5,
            marginBottom: 10,
            padding: 10,
            borderRadius: 8,
            overflow: 'auto',
            height: windowHeight - 100
          }}
        >
          <div className="w-100">{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutNew;