import { Button, Col, Form, Modal, Popconfirm, Row, Select, Switch, Tag, Tooltip } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import ForTable from "components/ForTable";
import instance from "config/_axios";
import UserAccessUpdate from "pages/employee_tabel/options/user_access_update";
import { editUserAccessStateType } from "pages/employee_tabel/type";
import { _meta } from "pages/user/components/types";
import { getAllUsers } from "pages/user/servise";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CgCheckO } from "react-icons/cg";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { delete_data } from "services";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import { getOneFaculty } from "../service";

type userAccessType = {
    id: number,
    is_leader: number,
    user: {
        id: number
        role: Array<string>
        profile: {
            last_name: string
            first_name: string
            middle_name: string
        }
    }
    tabel_number: number
    workRate: { id: number, name: string }
    jobTitle: { id: number, name: string }
}
const FacultyEmployees: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const [form] = Form.useForm()
    const location: any = useLocation();
    const history = useHistory();
    const [refreshAll, setRefreshAll] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [editUserAccess, setEditUserAccess] = useState<editUserAccessStateType>({ item: {}, visibleModal: false, edit: false })
    const _breadcrump = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Technikumes",
            path: "faculties",
        },
        {
            name: location?.state?.faculty?.name,
            path: "faculty-employees",
        },
    ];

    const [users, setUsers] = useState<Array<{ id: number, first_name: string, last_name: string, role: Array<string> }>>([]);
    const [userAccesses, setUserAccesses] = useState<Array<userAccessType>>([]);
    const [userIds, setUserIds] = useState<Array<number>>([])

    useEffect(() => {
        (
            async () => {
                if (!location?.state?.faculty) history.push('/faculties')
                const response = await getAllUsers(`filter={"-role_name":["student","test"]}&per-page=0&page=1`);
                setUsers(response?.data?.items)
            }
        )()
    }, [])

    useEffect(() => {
        refreshFaculty(location?.state?.faculty?.id)
    }, [location?.state?.faculty?.id, refreshAll])

    useEffect(() => {
        (
            async () => {
                let a: Array<number> = []
                userAccesses?.map(async (item: any) => {
                    a.push(item?.user_id)
                })
                setUserIds(a);
            }
        )()
    }, [userAccesses])

    const onFinish = async (values: any) => {
        let val: any = values?.user_access?.map((item: any) => "" + item)

        let data = new FormData();

        data.append("user_access", JSON.stringify(val));
        data.append("table_id", location?.state?.faculty?.id)

        try {
            const response = await instance({
                method: 'post',
                url: '/faculties/user-access',
                data: data
            })
            if (response?.data?.status === 1) {
                asyncN("success", "create", response?.data?.message).then((e) => {
                    refreshFaculty(location?.state?.faculty?.id)
                    form.resetFields()
                })
            } else {
                asyncN("error", "create", response?.data?.message);
            }
        } catch (e) {
            asyncN("error", "create", "Error create add employee for " + location?.state?.faculty?.name + " faculty")
        }

    }

    const refreshFaculty = async (id: number) => {
        setLoading(true)
        const response = await getOneFaculty(id, `expand=userAccess.user.profile,userAccess.workRate,userAccess.jobTitle`)
        
        setUserAccesses(response?.userAccess);
        setLoading(false)
    }


    return (
        <div>
            <CustomBreadcrumb arr={_breadcrump} />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row className="mt-2" gutter={24}>
                    <Col xs={24} sm={24} md={20} lg={14}>
                        <Form.Item name="user_access" label="User" rules={[
                            {
                                required: true,
                                message: t("Fill in the field!")
                            }
                        ]}>
                            <Select
                                mode="multiple"
                                placeholder={t("Please select user") + " ..."}
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return option?.children[0]?.props?.children?.props?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {
                                    users?.map((item: { id: number, first_name: string, last_name: string, role: Array<string> }, i: number) =>
                                        !userIds?.includes(item?.id) && <Select.Option key={i} value={item?.id}>
                                            <span>
                                                <strong>{item?.first_name + ' ' + item?.last_name + ' '}</strong>
                                            </span>
                                            <span style={{ float: "right" }}>
                                                {
                                                    item?.role?.map((role: string) =>
                                                        <Tag>{role}</Tag>
                                                    )
                                                }
                                            </span>
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={8} sm={6} md={3} lg={2}>
                        <Form.Item
                            name=""
                            label=" "
                        >
                            <Button icon={
                                <CgCheckO
                                    color={"#40a9ff"}
                                    style={{ fontSize: "25px", cursor: "pointer" }}
                                />
                            } type="link" htmlType="submit"></Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row gutter={24}>
                <Col span={24}>
                    {
                        <ForTable totalCount={userAccesses.length} showAllData={true} loading={loading} datas={userAccesses?.sort((item1: { is_leader: number }, item2: { is_leader: number }) => item2?.is_leader - item1?.is_leader)} theads={[
                            <>{t("Full name")}</>,
                            <>{t("Role")}</>,
                            <>{t("Job title")}</>,
                            <>{t("Work rate")}</>,
                            <>{t("Tabel number")}</>,
                            <>{t("Is leader")}</>,
                            <>{t("Actions")}</>,
                        ]} tbodys={function(this: any){
                            return [
                                    <>
                                        <Link to={`/view-user/${this?.user?.id}`}>
                                            {
                                                `${this?.user?.profile?.first_name ?? ""} ${this?.user?.profile?.last_name ?? ""} ${this?.user?.profile?.middle_name}`.toUpperCase()
                                            }
                                        </Link>
                                    </>,
                                    <>
                                        {
                                            this?.user?.role?.map((item: string, i: number) =>
                                                <Tag key={i}>
                                                    {item}
                                                </Tag>
                                            )
                                        }
                                    </>,
                                    <>
                                        {this?.jobTitle?.name}
                                    </>,
                                    <>{this?.workRate?.name}</>,
                                    <>{this?.tabel_number}</>,
                                    <>
                                        <Switch checked={this?.is_leader === 1} />
                                    </>,
                                    <>
                                        <div className="crud_actions_table">
                                            {isHasAuthRoute("user-access_update") && <Tooltip placement="top" title={t("Edit")}><TbFilePencil className="edit_btn" onClick={() => {
                                                setEditUserAccess({
                                                    visibleModal: true,
                                                    item: this,
                                                    edit: true
                                                })
                                            }} /></Tooltip>}
                                            {isHasAuthRoute("user-access_delete") && <Tooltip placement="top" title={t("Delete")}>
                                                <Popconfirm
                                                    placement="topLeft"
                                                    title={`${t("Deleted it")} ?`}
                                                    okText={t("Yes")}
                                                    cancelText={t("No")}
                                                    onConfirm={() => {
                                                        delete_data('/user-accesses', this?.id).then((e) => {
                                                            if (e === "success") {
                                                                refreshFaculty(location?.state?.faculty?.id)
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <TbFileOff 
                                                        className="delete_btn"
                                                        style={{
                                                            display: isHasAuthRoute("user-access_delete")
                                                                ? ""
                                                                : "none",
                                                        }}
                                                    />
                                                </Popconfirm>
                                            </Tooltip>}
                                        </div>
                                    </>
                            ]
                        }}/>
                    }
                    {/* {userAccesses?.length > 0 && <Table className="table table-bordered table-striped">
                        <Thead>
                            <Tr>
                                
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                userAccesses?.sort((item1: { is_leader: number }, item2: { is_leader: number }) => item2?.is_leader - item1?.is_leader)?.map((item: userAccessType, i: number) =>
                                    <Tr className="table-row-hover">
                                        <Td>
                                            <Link to={`/view-user/${item?.user?.id}`}>
                                                {
                                                    `${item?.user?.profile.first_name ?? ""} ${item?.user?.profile?.last_name ?? ""} ${item?.user?.profile?.middle_name}`.toUpperCase()
                                                }
                                            </Link>
                                        </Td>
                                        <Td>
                                            {
                                                item?.user?.role?.map((item: string, i: number) =>
                                                    <Tag key={i}>
                                                        {item}
                                                    </Tag>
                                                )
                                            }
                                        </Td>
                                        <Td>
                                            {item?.jobTitle?.name}
                                        </Td>
                                        <Td>{item?.workRate?.name}</Td>
                                        <Td>{item?.tabel_number}</Td>
                                        <Td>
                                            <Switch checked={item?.is_leader === 1} />
                                        </Td>
                                        <Td>
                                            <div className="crud_actions_table">
                                                {isHasAuthRoute("user-access_update") && <Tooltip placement="top" title={t("Edit")}><TbFilePencil className="edit_btn" onClick={() => {
                                                    setEditUserAccess({
                                                        visibleModal: true,
                                                        item: item,
                                                        edit: true
                                                    })
                                                }} /></Tooltip>}
                                                {isHasAuthRoute("user-access_delete") && <Tooltip placement="top" title={t("Delete")}>
                                                    <Popconfirm
                                                        placement="topLeft"
                                                        title={`${t("Deleted it")} ?`}
                                                        okText={t("Yes")}
                                                        cancelText={t("No")}
                                                        onConfirm={() => {
                                                            delete_data('/user-accesses', item?.id).then((e) => {
                                                                if (e === "success") {
                                                                    refreshFaculty(location?.state?.faculty?.id)
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <TbFileOff 
                                                            className="delete_btn"
                                                            style={{
                                                                display: isHasAuthRoute("user-access_delete")
                                                                    ? ""
                                                                    : "none",
                                                            }}
                                                        />
                                                    </Popconfirm>
                                                </Tooltip>}
                                            </div>
                                        </Td>
                                    </Tr>
                                )
                            }
                        </Tbody>
                    </Table>} */}
                </Col>
                <Modal footer={false} visible={editUserAccess?.visibleModal} onCancel={() => setEditUserAccess({
                    item: null, visibleModal: false, edit: editUserAccess?.edit
                })}>
                    <UserAccessUpdate
                        refresh={refreshAll}
                        setRefresh={setRefreshAll}
                        editUserAccessState={editUserAccess}
                        setEditUserAccessState={setEditUserAccess}
                    />
                </Modal>
            </Row>
        </div>
    )
}
export default FacultyEmployees