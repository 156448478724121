import { number2color } from '@antv/util';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import axios from 'axios';
import instance from 'config/_axios';
import { subjectCategoryType } from 'pages/edu_plan/attachSubjects/type';
import { getAllSubjects } from 'pages/subjects/service';
import { getAllSubjectCategories } from 'pages/subject_category/service';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getAllLanguages } from 'services';


const { Option } = Select;

const FeildsUi: FC<{ disabledFinish: boolean }> = ({ disabledFinish }): JSX.Element => {

    const { t } = useTranslation()
    const history = useHistory()
    const [refreshSubject, setRefreshSubject] = useState<boolean>(false)
    const [refreshLang, setRefreshLang] = useState<boolean>(false)
    const [refreshSubjectCategory, setRefreshSubjectCategory] = useState<boolean>(false)
    const [subjects, setSubjects] = useState<Array<{ id: number, name: string }>>([])
    const [langs, setLangs] = useState<Array<{ id: number, name: string }>>([]);
    const [subjectCategory, setSubjectCategory] = useState<Array<subjectCategoryType>>([])

    useEffect(() => {
        (
            async () => {
                if (!subjects?.length) {
                    const response = await getAllSubjects(0, 1);
                    setSubjects(response?.items)
                }
            }
        )()
    }, [refreshSubject]);

    useEffect(() => {
        (
            async () => {
                if (!langs?.length) {
                    const response = await getAllLanguages();
                    if (response) {
                        setLangs(response?.items)
                    }
                }
            }
        )()
    }, [refreshLang]);

    useEffect(() => {
        (
            async () => {
                if (!subjectCategory?.length) {
                    const response = await getAllSubjectCategories();
                    if (response) {
                        setSubjectCategory(response?.items)
                    }
                }
            }
        )()
    }, [refreshSubjectCategory]);


    return (
        <Row gutter={24}>
            <Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item
                    label="Name"
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: t("Please fill the feild!")
                        }
                    ]}
                >
                    <Input placeholder='Input topic name!' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item
                    label="Hours"
                    name='hours'
                    rules={[
                        {
                            required: true,
                            message: t("Please fill the feild!")
                        }
                    ]}
                >
                    <Input type="number" placeholder='Input hours!' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item
                    label="Language"
                    name='lang_id'
                    rules={[
                        {
                            required: true,
                            message: t("Please fill the feild!")
                        }
                    ]}
                >
                    <Select onFocus={() => setRefreshLang(!refreshLang)} placeholder='Select language!'>
                        {
                            langs?.map((item: { id: number, name: string }, i: number) =>
                                <Option key={i} value={item?.id}>{item?.name}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item
                    label="Subject category"
                    name='subject_category_id'
                    rules={[
                        {
                            required: true,
                            message: t("Please fill the feild!")
                        }
                    ]}
                >
                    <Select onFocus={() => setRefreshSubjectCategory(!refreshSubjectCategory)} placeholder='Select language!'>
                        {
                            subjectCategory?.map((item: subjectCategoryType, i: number) =>
                                <Option key={i} value={item?.id}>{item?.name}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label="Description"
                    name='description'
                    rules={[
                        {
                            required: false,
                            message: t("Please fill the feild!")
                        }
                    ]}
                >
                    <Input placeholder='Input description for topic!' />
                </Form.Item>
            </Col>
            <Col span={24} className="d-flex justify-content-end">
                <Button htmlType='reset' type='default' className='m-1'>{t("Reset")}</Button>
                <Button disabled={disabledFinish} htmlType='submit' type='primary' className='m-1'>{t("Submit")}</Button>
                <Button onClick={() => history.goBack()} type='primary' className='m-1' danger>{t("Cancel")}</Button>

            </Col>
        </Row>
    )
}

export default FeildsUi