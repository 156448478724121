import { message } from "antd";
import instance from "config/_axios";



export const getOneEduSemester = async (id: number | null, path_prefix?: string) => {
    try {
        if (!id) {
            return new Error("Id bo'yicha ma'lumot olinmadi !");
        }

        const response = await instance({ url: `/edu-semestrs/${id}${path_prefix ? '?' + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}
export const getAllSubject = async (path_prefix?: string) => {
    try {
        const response = await instance({ url: `/subjects${path_prefix ? '?' + path_prefix : ''}`, method: 'GET', params: { "per-page": 0 }  })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllSillabuses = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {
        const response = await instance({
            method: 'get',
            url: `/subject-sillabuses?${perPage?'per-page='+perPage:''}${currentPage?perPage?'&page='+currentPage:currentPage:''}${path_prefix?((perPage||currentPage)?'&'+path_prefix:''+path_prefix):''}`
        });
        if (response?.data?.status === 1) {
            return response?.data?.data
        } else {
            return new Error(response?.data?.message)
        }
    } catch (e: any) {
        message.error(e?.response?.message);
    }
};


