import { Button, Checkbox, Col, Input, List, message, Modal, Pagination, Popconfirm, Row, Spin, Tag, Typography } from "antd";
import instance from "config/_axios";
import useDebounce from "hooks/useDebounce";
import useGetAllData from "hooks/useGetAllData";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TbFileOff } from "react-icons/tb";
import { delete_data } from "services";
import { asyncN } from "utils/notifications";
import { teacherContentRefModalType } from "../../teachers/crud/types";

const { Text, Link } = Typography;

const TeacherContentRef: FC<teacherContentRefModalType> = ({ open, item, setOpen }): JSX.Element => {
    const {t} = useTranslation()
    const [teacherContentIds, setTeacherContentIds] = useState<Array<number>>([])
    const [currentPageSubject, setCurrentPageSubject] = useState<number>(1);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [text, setText] = useState<string>("");
    const debouncedVal = useDebounce(text, 500);
    const [windowSize, setWindowSize] = useState<number | undefined>()

    useEffect(() => {
        if(window.innerWidth > 740 ) setWindowSize(window.innerWidth / 100 * 80)
        else setWindowSize(window.innerWidth - 10)
    }, [window.innerWidth])

    const _subjects = useGetAllData({
        url: `/subjects?per-page=10&page=${currentPageSubject}&q=${debouncedVal}`, isCall: 'auto', refetch: [currentPageSubject], debouncedValue: [debouncedVal]
    })

    const _teacherContents = useGetAllData({
        url: `/teacher-contents?expand=subject&filter={"user_id":${item?.id}}`, isCall: 'auto', refetch: [refresh]
    })

    useEffect(() => {
        if (_teacherContents?.items?.length > 0) {
            setTeacherContentIds(_teacherContents?.items?.map((item: any) => item?.subject_id))
        } else {
            setTeacherContentIds([])
        }
    }, [_teacherContents?.items])

    const submitTeacherContent = async (subjectId: number) => {
        if (!teacherContentIds?.includes(subjectId)) {
            try {
                let data = new FormData();
                data.append("user_id", `${item?.id}`);
                data.append("subject_id", `${subjectId}`)
                const resp = await instance({
                    url: `/teacher-contents`,
                    method: 'post',
                    data: data
                })
                if (resp?.data?.status === 1) {
                    asyncN("success", "create", resp?.data?.message).then(() => {
                        setRefresh(!refresh)
                    })
                }
            } catch (error: any) {

            }
        } else {
            message.warning("Subject already exists!")
        }
    }

    return (
        <Modal footer={false} width={windowSize} visible={open} title={(item?.profile?.first_name + " " + item?.profile?.last_name + " " + item?.profile?.middle_name)?.toLocaleUpperCase()} onCancel={() => setOpen(false)}>
            <Row gutter={24}>
                <Col style={{ borderRight: "1px solid rgba(0,0,0,.3)" }} span={12}>
                    {_subjects.loading ? (<div className="loader-line"></div>) : (<div style={{ height: "3px" }}></div>)}
                    <List
                        header={
                            <div>
                                <div>Subjects</div>
                                <div>
                                    <Input.Search onChange={(e) => setText(e?.target?.value)} placeholder="Type to search!" type={"search"} />
                                </div>
                                <div className="mt-2 d-flex justify-content-end"><Tag color="#F2F2F2" className="text-dark"> Jami {_subjects.data._meta?.totalCount} ta </Tag></div>
                            </div>
                        }
                        itemLayout="horizontal"
                        dataSource={_subjects?.items}
                        renderItem={(item, i) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<Checkbox onChange={() => submitTeacherContent(item?.id)} checked={teacherContentIds?.includes(item?.id)}>
                                        {((currentPageSubject - 1) * 10 + (i + 1)) + ". " + item.name}
                                    </Checkbox>}
                                />
                            </List.Item>
                        )}
                    />
                    <div className="mt-3">
                        {_subjects.data._meta.totalCount && _subjects.data.items?.length ? (
                            <div className="table_bottom_footer">
                                <Pagination
                                    current={currentPageSubject}
                                    total={_subjects.data._meta?.totalCount}
                                    defaultPageSize={15}
                                    showSizeChanger={false}
                                    pageSize={_subjects.data._meta?.perPage}
                                    onChange={(e) => setCurrentPageSubject(e)}
                                />
                            </div>
                        ) : ("")}
                    </div>
                </Col>
                <Col span={12}>
                    {_teacherContents.loading ? (<div className="loader-line"></div>) : (<div style={{ height: "3px" }}></div>)}
                    {/* <Input.Search placeholder="Type to search!" type={"search"} /> */}
                    <List
                        header={<>
                            <div>{t('Contenter subjects')}</div>
                            {_teacherContents.data._meta.totalCount && _teacherContents.data.items?.length ? (
                            <div className="table_bottom_footer">
                                <div><Tag color="#F2F2F2" className="text-dark"> Jami {_teacherContents.data._meta?.totalCount} ta </Tag></div>
                            </div>
                        ) : null}
                        </>}
                        itemLayout="horizontal"
                        dataSource={_teacherContents?.items}
                        renderItem={(item, i) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<div className="d-f justify-content-between pe-2">
                                        <span>{(i + 1) + ". " + item?.subject?.name}</span>
                                        <Popconfirm onConfirm={() => delete_data("/teacher-contents", item?.id)?.then(() => setRefresh(!refresh))} placement="top" title="Whether to delete the reference?">
                                            <a style={{ color: "red", cursor: "pointer" }}><TbFileOff /></a>
                                        </Popconfirm>
                                    </div>}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    )
}

export default TeacherContentRef;