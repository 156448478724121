import { ChangeEvent, FC } from "react";
import { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table";
import { Tooltip, Popconfirm, Button, Pagination, Input, Tag, Select, Row, Col } from "antd";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
import EduPlanView from "./crud/view";
import { delete_data } from "services";
import { getAllEduPlans } from "./service";
import { asyncN } from "utils/notifications";
import EmptyTable from "components/EmptyTable";
import useDebounce from "hooks/useDebounce";
import IndexPageTitle from 'components/IndexPageTitle';
import CreateButton from 'components/CreateButton';
import { getAllFaculties } from "pages/faculty/service";
import { ButtonCreate } from "components/Buttons";
import ForTable from "components/ForTable";
import { AiOutlineCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import ForTitle from "components/ForTitles";

const { Search } = Input;

const EduPlan: FC = (): JSX.Element => {
  const history = useHistory();

  const { i18n, t } = useTranslation();
  const [data, setData] = useState([]);
  const [reRequest, setReRequest] = useState<boolean>(false);
  const [tableOption, setTableOption] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleView, setVisibleView] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(15)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [id, setId] = useState<number | null>(null)
  const [text, setText] = useState<string>('')
  const [selectedFaculty, setSelectedFaculty] = useState<number | undefined>(undefined)
  const [faculties, setFaculties] = useState<{ refresh: boolean, items: Array<{ id: number, name: string }> }>({ refresh: false, items: [] })
  const debouncedValue = useDebounce<string>(text, 500)


  useEffect(() => {
    (
      async () => {
        const resp = await getAllFaculties(0, 1);
        setFaculties({
          ...faculties,
          items: resp?.items
        })
      }
    )()
  }, [faculties?.refresh])


  useEffect(() => {
    (
      async () => {
        try {
          setLoading(true)
          const response = await getAllEduPlans(perPage, currentPage, `expand=description,eduYear,faculty,eduType,direction&q=${debouncedValue}${selectedFaculty ? '&filter={"faculty_id":' + selectedFaculty + '}' : ''}`);
          setData(response?.items)
          setTableOption(response?._meta)
          setLoading(false);
        } catch (error: any) {
          asyncN('error', 'read', error?.response?.message)
        }
      }
    )()
  }, [i18n.language, reRequest, perPage, currentPage, debouncedValue, selectedFaculty])

  const deleteOne = (id: number) => {
    delete_data("edu-plans", id).then(() => {
      setReRequest(!reRequest);
    });
  };

  return (
    <div>
      <ForTitle title="Education Plans" buttons={[isHasAuthRoute('edu-plan_create') && <NavLink to={'/edu-plan_create'}><ButtonCreate /></NavLink>]}/>
      <EduPlanView id={id} visible={visibleView} setVisible={setVisibleView} />
      <div className="text-end my-2">
        <Row gutter={24} className="d-flex justify-content-end">
          <Col xs={24} sm={24} md={8} lg={6}>
            <Select allowClear onChange={(e: number) => setSelectedFaculty(e)} onClear={() => setSelectedFaculty(undefined)} className="me-1 text-start" style={{ width: "100%" }} onFocus={() => {
              if (faculties?.items?.length === 0) {
                setFaculties({
                  ...faculties,
                  refresh: !faculties?.refresh
                })
              }
            }} placeholder={t("FIlter by faculty")} >
              {
                faculties?.items?.map((item: { id: number, name: string }, i: number) => {
                  return <Select.Option key={i} value={item?.id}>{item?.name}</Select.Option>
                })
              }
            </Select>
          </Col>
          <Col className="d-flex" xs={24} sm={24} md={10} lg={8}>
            <Search
              placeholder={`${t("Input search text")} ...`}
              value={text}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setText(e.target.value)
              }
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </div>
      <ForTable setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} totalCount={tableOption?.totalCount} datas={data} theads={
          [
            <>{t("id")}</>,
            <>{t("Name")}</>,
            <>{t("Texnikum")}</>,
            <>{t("Direction")}</>,
            <>{t("Edu Type")}</>,
            <>{t("Edu Year")}</>,
            <>{t("Semestr")}</>,
            <>{t("Course")}</>,
            <>{t("Its semestrs")}</>,
            <>{t("Status")}</>,
            <>{t("Actions")}</>
          ]
        } tbodys={
          function(this: any){
            return [
              <>{this?.id}</>,
              <>{this?.name}</>,
              <>{this?.faculty?.name}</>,
              <>{this?.direction?.name}</>,
              <>{this?.eduType?.name}</>,
              <>{this?.eduYear?.name}</>,
              <>{this?.semestr}</>,
              <>{this?.course}</>,
              <>
                <Link to={{
                    pathname: `/edu-plan_view/${this?.id}`,
                    state: {
                      id: this.id,
                    }
                  }}>
                  {t("Semestr")}
                </Link>
              </>,
              <>
                <BadgeStatus status={this.status} />
              </>,
              <>
                <div className="crud_actions_table">
                  {isHasAuthRoute("edu-plan_view") && (
                    <Tooltip placement="top" title={t("View")}>
                      <TbFileInvoice  className="view_btn" onClick={() => { setVisibleView(true); setId(this.id) }} />
                    </Tooltip>
                  )}
                  {isHasAuthRoute("edu-plan_update") && (
                    <Tooltip placement="top" title={t("Edit")}>
                      <NavLink
                        to={{
                          pathname: `edu-plan_update/${this?.id}`,
                          state: {
                            id: this.id,
                          },
                        }}
                      >
                        <TbFilePencil className="edit_btn" />
                      </NavLink>
                    </Tooltip>
                  )}
                  {isHasAuthRoute("edu-plan_delete") && (
                    <Tooltip placement="top" title={t("Delete")}>
                      <Popconfirm
                        placement="topLeft"
                        title={`${t("Deleted it")} ?`}
                        okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                        onConfirm={() => deleteOne(this.id)}
                      >
                        <TbFileOff className="delete_btn" />
                      </Popconfirm>
                    </Tooltip>
                  )}
                </div>
              </>
            ]
          }
        } loading={loading}/>
    </div>
  );
};

export default EduPlan;
