import { useTranslation } from "react-i18next"
import styles from './styles.module.scss';


const TableTh: React.FC<{ children: string | React.ReactNode }> = ({ children }): JSX.Element => {

    const { t } = useTranslation();

    return typeof children === 'string' ? <span className={styles.table_th} >{t(children)}</span> : <span className={styles.table_th} >{children}</span>

}

export default TableTh;