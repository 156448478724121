import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Tabs, Divider } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { NavLink, useRouteMatch, match } from "react-router-dom";
import isHasAuthRoute from "utils/isHasRoute";
import CustomBreadcrumb from "components/Breadcrumb";
import { FILE_URL } from "config/utils";
import { useTranslation } from "react-i18next";
import { BiEditAlt, BiLink } from "react-icons/bi";
import { getPassword } from "services";
import moment from "moment";
import user_male from 'assets/images/user_male.png';
import user_female from 'assets/images/user_female.png';
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { getLanguageAsObject } from "utils/auxiliary_functions";
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import ChangePassword from "pages/additional_pages/profilePage/changePassword";
import { useAppSelector } from "store/services";
import { IStudent } from "models/student";
import CardContainer from "components/Widgets/CardContainer";
import { FaEdit, FaEnvelope, FaPhoneAlt, FaUserLock } from "react-icons/fa";
import { IExamStudents } from "models/exam";
import { CCFieldSetUi } from "components/FieldSet";
import useGetOneData, { ReturnTypeUseGetOneData } from "hooks/useGetOneData";
import "../styles.scss"
import { checkStatusState } from "./utils";
// import StudentOrders from "../components/StudentOrders";


const { TabPane } = Tabs;

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Students",
        path: 'student_index'
    },
    {
        name: "Student view",
        path: 'student_view'
    },
]
const NewStudentView: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const auth: any = useAppSelector(state => state.auth);
    const _roles = auth.user?.role;
    const history = useHistory();
    const match: match<any> | null = useRouteMatch("/view_student/:student_id");
    const [visible, setVisible] = useState<boolean>(false);

    const [auth_data, setAuth_data] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    if (!match) { history.goBack(); }

    const _languages = useGetAllData({ url: "/languages", isCall: 'auto' });
    const _languageAsObject = getLanguageAsObject(_languages.items)

    const exam_students = useGetAllData({ url: `/exam-students?expand=exam.subjectName,allBall&filter=${JSON.stringify({ student_id: match?.params?.student_id })}` }) as TypeReturnUseGetAllData<IExamStudents>;

    const { data } = useGetOneData({
        url: `/students/${match?.params?.student_id}?expand=profile,user,attends.subject,faculty,direction,eduYear,eduType,eduPlan,eduLangs,eduForm,course,createdBy,updatedBy,region,area,country,permanentArea,permanentCountry,permanentRegion,citizenship,nationality,socialCategory,residenceStatus,categoryOfCohabitant,studentCategory`,
        isCall: 'auto'
    }) as ReturnTypeUseGetOneData<IStudent>


    const getAuthData = async () => {
        if (!auth_data && data.profile?.user_id) {
            setLoading(true);
            const _data = await getPassword(data.profile?.user_id)
            setAuth_data(_data)
            setLoading(false);
        }
    }



    return (
        <div className="bg-light p_12" >
            <CardContainer className="mb-3">
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <CustomBreadcrumb arr={_breadcrump} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className="text-end">
                        {isHasAuthRoute("student_update") && <NavLink to={{ pathname: `/student_update/${match?.params?.student_id}` }} ><Button type="primary" className="float-end d-f" size="small" icon={<EditOutlined />}>{t("Edit")}</Button></NavLink>}
                    </Col>
                </Row>
            </CardContainer>
            <Row gutter={[12, 12]} >
                <Col xl={8}>
                    <CardContainer>
                        <div className="d-flex user_profile_top_box py-2" style={{ backgroundColor: "#F8FBFD" }}>
                            <img src={data?.profile?.image ? FILE_URL + data?.profile?.image : Number(data?.profile?.gender) ? user_male : user_female} alt="Rasm topilmadi" />
                            <div className="info_box_top">
                                <p>{data?.profile?.last_name}&nbsp;{data?.profile?.first_name}&nbsp;{data?.profile?.middle_name}</p>
                                <p className="user_status" style={{ backgroundColor: checkStatusState(Number(data?.status)).color }}>
                                    {checkStatusState(Number(data?.status)).text}
                                </p>
                                <div>
                                    <div className="login_password">
                                        <span>{data?.user?.username}</span>
                                        <span>{auth_data?.password ?? "*******"} </span>
                                        {
                                            isHasAuthRoute('password_view') ?
                                                (
                                                    auth_data && isHasAuthRoute('password_update') ?
                                                        <Button type="primary" size="small" onClick={() => setVisible(true)}><BiEditAlt size={16} /></Button> :
                                                        <Button type="primary" size="small" loading={loading} onClick={getAuthData}><FaUserLock size={16} /></Button>
                                                ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row gutter={[10, 6]} className="mt-3">
                            <Col xs={8}><span className="info_profile_phone_key"><FaPhoneAlt /> <span>1</span></span><span className="info_profile_value" style={{ whiteSpace: "nowrap" }}>{data?.profile?.phone}</span></Col>
                            <Col xs={8}><span className="info_profile_phone_key"><FaPhoneAlt /> <span>2</span></span><span className="info_profile_value" style={{ whiteSpace: "nowrap" }}>{data?.profile?.phone_secondary}</span></Col>
                            <Col xs={8}><span className="info_profile_key"><FaEnvelope /></span><span className="info_profile_value" style={{ whiteSpace: "nowrap" }}>{data?.profile?.email}</span></Col>
                            <Col xs={24}>
                                <hr className="my-0" />
                            </Col>
                            <Col xl={12} lg={12} md={24} xs={24}><CCFieldSetUi title="Citizenship" text={data?.citizenship?.name} /></Col>
                            <Col xl={12} lg={12} md={24} xs={24}><CCFieldSetUi title="Nationality" text={data?.nationality?.name} /></Col>
                            <Col xl={12} lg={12} md={24} xs={24}><CCFieldSetUi title="Birth date" text={data?.profile?.birthday} /></Col>
                            <Col xl={12} lg={12} md={24} xs={24}><CCFieldSetUi title="Gender" text={Number(data?.profile?.gender) ? "Erkak" : "Ayol"} /></Col>
                            <Col xl={12} lg={12} md={24} xs={24}><CCFieldSetUi title="Seria and number" text={`${data?.profile?.passport_seria} ${data?.profile?.passport_number}`} /></Col>
                            <Col xl={12} lg={12} md={24} xs={24}><CCFieldSetUi title="JSHIR" text={data?.profile?.passport_pin} /></Col>
                            <Col xl={12} lg={12} md={24} xs={24}><CCFieldSetUi title="Amal qilish muddati" text={`${data?.profile?.passport_given_date} / ${data?.profile?.passport_issued_date}`} /></Col>
                            <Col xl={12} lg={12} md={24} xs={24}>
                                <CCFieldSetUi
                                    title="Hujjat fayli"
                                    text={data?.profile?.passport_file && data?.profile?.passport_file !== 'null' ?
                                        <a href={FILE_URL + data?.profile?.passport_file} target="_blank" rel="noopener noreferrer"><BiLink />&nbsp;link</a> : <span className="text-danger">Yuklanmagan</span>}
                                />
                            </Col>
                            <Col xl={24} ><CCFieldSetUi title="Kim tomonidan berilgan" text={data?.profile?.passport_given_by} /></Col>
                            <Col xl={12} ><CCFieldSetUi title="Country" text={data?.permanentCountry?.name} /></Col>
                            <Col xl={12} ><CCFieldSetUi title="Region" text={data?.permanentRegion?.name} /></Col>
                            <Col xl={24} ><CCFieldSetUi title="Area" text={data?.permanentArea?.name} /></Col>
                            <Col xl={24} ><CCFieldSetUi title="Address" text={data?.profile?.permanent_address} /></Col>
                            <Col xl={24} ><CCFieldSetUi title="Description" text={data?.description} /></Col>
                            {/* <Col xs={24}>
                                <div className="d-flex justify-content-between">
                                    <a className="info_text_key" style={{ color: '#1890ff' }} onClick={getAuthData}>{t("Login and password")}: </a>
                                    {_roles.includes("admin") && auth_data && Object.getOwnPropertyNames(auth_data).length ? <Button type="link" size="small" onClick={() => setVisible(true)}><BiEditAlt /></Button> : null}
                                </div>
                            </Col> */}
                            {/* {loading ? <div className="loader-line"></div> : <div style={{ height: '3px' }}></div>}
                            {
                                auth_data && Object.getOwnPropertyNames(auth_data).length ?
                                    <>
                                        <Col xs={12}><span className="info_text_key">username: </span><span className="info_text_value" style={{ whiteSpace: "nowrap" }}>{auth_data?.username}</span></Col>
                                        <Col xs={12}><span className="info_text_key">password: </span><span className="info_text_value" style={{ whiteSpace: "nowrap", fontFamily: "Roboto Mono", fontWeight: 'bold' }}>{auth_data?.password}</span></Col>
                                    </> : null
                            } */}
                        </Row>
                    </CardContainer>
                </Col>
                <Col xl={16}>
                    <CardContainer className="h-100">
                        <Tabs
                            defaultActiveKey="1"
                            onTabClick={(e) => {
                                if (e === "4") {
                                    exam_students.fetch();
                                }
                            }}>
                            <TabPane tab={t("Education details")} key="1">
                                <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Education type" text={data?.eduType?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Education form" text={data?.eduForm?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Faculty" text={data?.faculty?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Direction" text={data?.direction?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Education year" text={String(data?.eduYear?.name).split('-')[0]} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Course" text={data?.course?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Education plan" text={data?.eduPlan?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Education language" text={data?.edu_lang_id ? _languageAsObject[data?.edu_lang_id] : ''} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Form of payment" text={data?.is_contract ? t("Kontrakt") : t("Grant")} /></Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Qo'shimcha ma'lumotlar" key="2">
                                <Row gutter={[16, 8]} className="px-2 pb-4 pt-1">
                                    <Col xl={24} xs={24}><Divider orientation="left" orientationMargin={"marginLeft"} className="my-2"><span className="info_profile_key">Aloqa ma'lumotlari</span></Divider></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Telefon 1" text={data?.profile?.phone} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Telefon 2" text={data?.profile?.phone_secondary} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Email" text={data?.profile?.email} /></Col>
                                    {/* <Col xs={8}><span className="info_profile_phone_key"><FaPhoneAlt /> <span>1</span></span><span className="info_profile_value" style={{ whiteSpace: "nowrap" }}>{data?.profile?.phone}</span></Col>
                                    <Col xs={8}><span className="info_profile_phone_key"><FaPhoneAlt /> <span>2</span></span><span className="info_profile_value" style={{ whiteSpace: "nowrap" }}>{data?.profile?.phone_secondary}</span></Col>
                                    <Col xs={8}><span className="info_profile_key"><FaEnvelope /></span><span className="info_profile_value" style={{ whiteSpace: "nowrap" }}>{data?.profile?.email}</span></Col> */}
                                    <Col xl={24} xs={24}><Divider orientation="left" orientationMargin={"marginLeft"} className="my-2"><span className="info_profile_key">Vaqtincha yashash manzili</span></Divider></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Country" text={data?.country?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Region" text={data?.region?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Area" text={data?.area?.name} /></Col>
                                    <Col lg={24} sm={24} md={24} xs={24}><CCFieldSetUi title="Address" text={data?.profile?.address} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Birga yashaydiganlar soni" text={data?.partners_count} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Birga yashaydiganlar toifasi" text={data?.categoryOfCohabitant?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Yashash hududi holati" text={data?.residenceStatus?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Yashash joyi geolokatsiyasi" text={data?.live_location} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Ota onasi telefon raqami" text={data?.parent_phone} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Ma'sul shaxs telefon raqami" text={data?.res_person_phone} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Ijtimoiy toifa" text={data?.socialCategory?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Talaba toifasi" text={data?.studentCategory?.name} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Avvalgi ta'lim ma'lumoti" text={data?.last_education} /></Col>
                                    <Col xl={24} xs={24}><Divider orientation="left" orientationMargin={"marginLeft"}><span className="info_profile_key">{t("Parent information")}</span></Divider></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Father F.I.O" text={null} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Father phone number" text={null} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Father info" text={null} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Mather F.I.O" text={null} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Mather phone number" text={null} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Mather info" text={null} /></Col>
                                    {/* <Col span={24}>
                                        <Divider orientation="left">{t("Sport certificates")}</Divider>
                                        <Table id="tech-companies-1" className="table table-striped table-bordered table-hover">
                                            <Thead>
                                                <Tr>
                                                    <Th>{t("Name")}</Th>
                                                    <Th>{t("Date")}</Th>
                                                    <Th>{t("Place")}</Th>
                                                    <Th>{t("File")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>

                                            </Tbody>
                                        </Table>
                                    </Col>
                                    <Col span={24}>
                                        <Divider orientation="left">{t("Olimpic certificates")}</Divider>
                                        <Table id="tech-companies-1" className="table table-striped table-bordered table-hover">
                                            <Thead>
                                                <Tr>
                                                    <Th>{t("Name")}</Th>
                                                    <Th>{t("Date")}</Th>
                                                    <Th>{t("Place")}</Th>
                                                    <Th>{t("File")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>

                                            </Tbody>
                                        </Table>
                                    </Col>
                                    <Col span={24}>
                                        <Divider orientation="left">{t("Other certificates")}</Divider>
                                        <Table id="tech-companies-1" className="table table-striped table-bordered table-hover">
                                            <Thead>
                                                <Tr>
                                                    <Th>{t("Name")}</Th>
                                                    <Th>{t("Date")}</Th>
                                                    <Th>{t("Place")}</Th>
                                                    <Th>{t("File")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>

                                            </Tbody>
                                        </Table>
                                    </Col> */}
                                </Row>
                            </TabPane>
                            {/* <TabPane tab={t("Student documents")} key="3">
                                <StudentOrders />
                            </TabPane> */}
                            <TabPane tab="Kiritilgan va o'zgartirilgan" key="4">
                                <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Kiritgan foydalanuvchi" text={`${data?.createdBy?.last_name} ${data?.createdBy?.first_name} ${data?.createdBy?.middle_name}`} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Roles" text={Array.isArray(data?.createdBy?.role) && data?.createdBy?.role.map((e: string) => <span className="mx-2" key={e}>{e},</span>)} /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Kiritigan vaqt" text={moment.unix(data?.created_at).format("DD-MM-YYYY HH:mm:ss")} /></Col>
                                    {data?.updatedBy && Object.getOwnPropertyNames(data?.updatedBy).length ?
                                        <>
                                            <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="O'zgartirgan foydalanuvchi" text={`${data?.updatedBy?.last_name} ${data?.updatedBy?.first_name} ${data?.updatedBy?.middle_name}`} /></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="Roles" text={Array.isArray(data?.updatedBy?.role) && data?.updatedBy?.role.map((e: string) => <span className="mx-2" key={e}>{e},</span>)} /></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><CCFieldSetUi title="O'zgartirgan vaqt" text={moment.unix(Number(data?.updated_at)).format("DD-MM-YYYY HH:mm:ss")} /></Col>
                                        </> : null
                                    }
                                </Row>
                            </TabPane>
                        </Tabs>
                    </CardContainer>
                </Col>
            </Row>
            <ChangePassword id={Number(data?.user_id)} visible={visible} setVisible={setVisible} />
        </div >
    )
}


export default NewStudentView;