
import { ChangeEvent, FC } from "react"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination, Input, Row, Col, Select, Divider, Popconfirm, Spin, Tooltip } from "antd";
import isHasAuthRoute from "utils/isHasRoute";
import RoomView from "./crud/view";
import EditRoom from "./crud/edit";
import EmptyTable from "components/EmptyTable";
import useDebounce from "hooks/useDebounce";
import IndexPageTitle from "components/IndexPageTitle";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import TotalCount from "components/totalCountTag";
import CreateRoom from "./crud/create";
import { ButtonCreate } from "components/Buttons";
import { Card, CardBody } from "reactstrap";
import { IBuilding, IRoom } from "models/others";
import styles from './styles.module.scss';
import useDeleteOne from "hooks/useDeleteOne";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { BiEditAlt, BiX } from "react-icons/bi";
import ForTitle from "components/ForTitles";

const { Search } = Input;
const { Option } = Select;

const Room: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [visibleActionId, setVisibleActionId] = useState<number | null>(null);
    const [id, setId] = useState<number | null>(null);
    const [reFetch, setReFetch] = useState<boolean>(false);


    const { value, writeToUrl } = useUrlQueryParams({ perPage: 0, currentPage: 1 });
    const debouncedValue = useDebounce<string>(value.filter_like.text, 500);
    const _delete = useDeleteOne();
    const _buildings = useGetAllData({ url: `buildings`, isCall: 'auto' }) as TypeReturnUseGetAllData<IBuilding>;
    const _rooms = useGetAllData({
        url: `rooms?sort=-id&expand=description,building&q=${debouncedValue ?? ''}&filter=${JSON.stringify(value.filter)}`,
        perPage: 0,
        currentPage: value.currentPage,
        isCall: 'auto',
        refetch: [_delete.refetch, reFetch, value.perPage, value.currentPage, debouncedValue, value.filter?.building_id]
    }) as TypeReturnUseGetAllData<IRoom>;



    return (
        <div className="not_user_select">
            <Spin tip="Loading..." spinning={_rooms.loading} >
                <ForTitle title="Rooms" buttons={[isHasAuthRoute('room_create') && <ButtonCreate onClick={() => setVisibleCreate(true)} />]}/>
                {visibleCreate ? <CreateRoom visible={visibleCreate} setVisible={setVisibleCreate} reFetch={reFetch} setReFetch={setReFetch} /> : null}
                {visibleView && id ? <RoomView id={id} visible={visibleView} setVisible={setVisibleView} /> : null}
                {visibleEdit && id ? <EditRoom visible={visibleEdit} setVisible={setVisibleEdit} id={id} setReRequest={setReFetch} reRequest={reFetch} /> : null}
                <Row gutter={[12, 8]}>
                    <Col xl={{ span: 4 }} lg={{ span: 6 }} md={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 24 }}>
                        <Select
                            className="w-100"
                            placeholder="Filter by building ..."
                            onChange={(e: number) => writeToUrl({ name: 'building_id', value: e })}
                            allowClear
                        >
                            {_buildings.items?.length ? _buildings.items?.map((e) => <Option key={e?.id} value={e?.id}>{e?.name}</Option>) : null}
                        </Select>
                    </Col>
                    <Col className="d-flex" xl={{ span: 6, offset: 14 }} lg={{ span: 8, offset: 10 }} md={{ span: 10, offset: 4 }} sm={{ span: 14 }} xs={{ span: 24 }}>
                        <Search placeholder={t("Input search text ...")} value={value.filter_like?.text} onChange={(e: ChangeEvent<HTMLInputElement>) => writeToUrl({ name: 'text', value: e.target.value })} />
                    </Col>
                </Row>
                <Row gutter={[12, 12]} style={{ backgroundColor: "#F5F7F9" }} className="px-2 py-3 mt-2">
                    {
                        _rooms.items.map((element) => {
                            return (
                                <Col key={element.id} xl={4} lg={6} md={8} sm={12} xs={24}>
                                    <Card className={styles.room_card + ' ' + (Boolean(element.status) ? styles.status_room_active : styles.status_room_in_active)} onClick={() => setVisibleActionId(element.id)}>
                                        <CardBody>
                                            <span className={styles.room_actions} style={{ visibility: visibleActionId === element.id ? 'visible' : 'hidden' }} >
                                                <button>booking</button>
                                                <Tooltip placement="top" title={"Tahrirlash"}>
                                                    <BiEditAlt size={13} onClick={() => { setVisibleEdit(true); setId(element.id) }} />
                                                </Tooltip>
                                                <Popconfirm placement="top" title={"O'chirilsinmi?"} onConfirm={() => _delete.fetch(`/rooms/${element.id}`)} okText="Ha" cancelText="Yo'q">
                                                    <Tooltip placement="top" title={"O'chirish"}>
                                                        <BiX size={16} />
                                                    </Tooltip>
                                                </Popconfirm>
                                            </span>
                                            <div className="pb-0 mb-0" onClick={() => { if (element.id === visibleActionId) { setVisibleActionId(null) } }}>
                                                <span className={styles.room_number + ' ' + (Boolean(element.status) ? styles.status_room_active : styles.status_room_in_active)}>
                                                    {/* <span className={styles.circle_status} ></span> */}
                                                    {element.name}
                                                    <span className={styles.info_text_room}>&nbsp;&nbsp;{element?.building?.name}&nbsp;/&nbsp;{element?.name ? element?.name[0] : '-'} - qavat &nbsp;|&nbsp; {element?.capacity}</span></span>
                                                <Divider className="my-1" ></Divider>
                                                <span className={styles.info_text_room} >{Number(element?.description) ? null : element?.description}</span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
                <EmptyTable data={_rooms.items} loading={_rooms.loading} searchText={debouncedValue} />
                {_rooms.data._meta.totalCount && _rooms.items?.length ?
                    <div className="table_bottom_footer mt-2">
                        <TotalCount totalCount={_rooms.data._meta.totalCount} />
                        <Pagination
                            defaultCurrent={value.currentPage}
                            total={_rooms.data._meta.totalCount}
                            defaultPageSize={value.perPage}
                            onChange={(e) => { writeToUrl({ name: 'currentPage', value: e }) }}
                            onShowSizeChange={(e, pageSize) => { writeToUrl({ name: 'perPage', value: pageSize }) }}
                            pageSizeOptions={['10', '15', '20', '30', '50', '100']}
                        />
                    </div> : null}
            </Spin>
        </div>
    )
}

export default Room;
