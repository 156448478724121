import { FC, useEffect, useState } from "react";
import { Table, Row, Col } from 'antd';
import LabellineChart from "pages/dashboards/common/charts/LabellineChart";
import FacetChart from "pages/dashboards/common/charts/FacedChart";
import CustomBreadcrumb from 'components/Breadcrumb';
import { columns, data, edu_form_data, edu_type_data, gender_data, region_data } from './utils'
import useGetAllData from "hooks/useGetAllData";



const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Student detail statistics",
        path: 'student_statistic'
    },
]


const StudentStatisticPage: FC = (): JSX.Element => {

    const [data, setData] = useState<any[]>([]);



    const _students = useGetAllData({ url: '/statistics/student-count-by-faculty?expand=studentsCountByGender', isCall: 'auto' });

    useEffect(() => {
        if (_students.data.items.length) {
            const new_arr = _students.data.items.map((e) => {
                return {
                    name: e?.name,
                    male: e?.studentsCountByGender[1]?.count,
                    female: e?.studentsCountByGender[0]?.count,
                    total: e?.studentsCount
                }
            })
            setData(new_arr)
        }
    }, [_students.data.items.length])



    return (
        <div>
            <CustomBreadcrumb arr={_breadcrump} />
            <h6 className="mt-3 text-center">Talabalarnig batafsil statistikasi</h6>
            <Row gutter={[20, 12]} className="mt-3" >
                <Col lg={6} md={6} sm={12} xs={24}>
                    <div className="card px-2 py-3">
                        <h6 className="ps-3">Ta'lim shakli</h6>
                        <LabellineChart data={edu_form_data} />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                    <div className="card px-2 py-3">
                        <h6 className="ps-3">Ta'lim shakli</h6>
                        <LabellineChart data={edu_form_data} />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                    <div className="card px-2 py-3">
                        <h6 className="ps-3">Ta'lim turi</h6>
                        <LabellineChart data={edu_type_data} />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                    <div className="card px-2 py-3">
                        <h6 className="ps-3">Jinsi</h6>
                        <LabellineChart data={gender_data} />
                    </div>
                </Col>
                <Col lg={24} xs={24}>
                    <div className="mt-4 card">
                        <Table
                            className="components-table-demo-nested w-100"
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );

}

export default StudentStatisticPage;
