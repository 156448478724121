import { FC, useEffect } from "react"
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Pagination, Tag } from "antd";
import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
import EmptyTable from "components/EmptyTable";
import IndexPageTitle from "components/IndexPageTitle";
import DiscriptionStyle from "components/DescriptionStyle";
import useGetAllData from "hooks/useGetAllData";
import { ButtonCreate } from "components/Buttons";
import SimpleCreate from "pages/common/simple_create";
import SimpleView from "pages/common/simple_view";
import SimpleEdit from "pages/common/simple_edit";
import Actions from "components/Actions";
import TotalCount from "components/totalCountTag";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";

export type TypeFacultyModalVisible = 'create' | 'update' | 'view' | null

const Faculty: FC = (): JSX.Element => {
    
    const { i18n, t } = useTranslation();
    const history = useHistory()
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [reFetch, setReFetch] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(10)
    const allData = useGetAllData({ url: `/faculties?expand=description,userAccess`, isCall: 'auto', refetch: [reFetch], perPage, currentPage });
    const {data, loading} = allData

    return (
        <div>
            <ForTitle title={"Technikumes"} buttons={[isHasAuthRoute('faculty_create') && <ButtonCreate onClick={() => setVisibleCreate(true)} />]}/>
            { visibleView && id ? <SimpleView url={`/faculties/${id}?expand=createdBy,updatedBy,description`} visible={visibleView} setVisible={setVisibleView} title="Faculty view" /> : null }
            { visibleUpdate && id ? <SimpleEdit url={`/faculties/${id}?expand=description`} visible={visibleUpdate} setVisible={setVisibleUpdate} setReFetch={setReFetch} title="Faculty view" /> : null }
            { visibleCreate ? <SimpleCreate url="/faculties" visible={visibleCreate} setVisible={setVisibleCreate} title="Texnikum yaratish" setReFetch={setReFetch} /> : null }
            <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={data._meta?.totalCount} loading={loading} datas={data?.items} theads={
                    [
                        <>{t("Name")}</>,
                        <>{t("Description")}</>,
                        <>{t("Employees")}</>,
                        <>{t("Status")}</>,
                        <>{t("Actions")}</>
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            this.name,
                            <DiscriptionStyle>{this?.description}</DiscriptionStyle>,
                            <Link to={{
                                pathname: "/faculty-employees",
                                state: {
                                    faculty: this
                                }
                            }}>
                                {t("Edit")}
                            </Link>,
                            <BadgeStatus status={this.status} />,
                            <Actions
                                id={this?.id}
                                url="/faculties"
                                setId={setId}
                                setReFetch={setReFetch}
                                setVisibleView={setVisibleView}
                                setVisibleEdit={setVisibleUpdate}
                                viewPermission="faculty_view"
                                editPermission="faculty_update"
                                deletePermission="faculty_delete"
                            />
                        ]
                    }
                }/>
        </div>
    )
}

export default Faculty;
