import { FC, useEffect, useState } from "react";
import { Modal, Row, Col, Typography, Button } from "antd";
import moment from "moment";
import { useHistory } from 'react-router'
import BadgeStatus from "components/BadgeStatus";
import { getOneSubject } from "../service";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";
import { sillabusType } from "../types";
import { getAllSubjectTypes } from "../../subject_type/service";
import { FaEdit } from "react-icons/all";

const { Text } = Typography;

const SubjectView: FC<{ visible: boolean, setVisible: (visible: boolean) => void, id: number | null, sillabus?: sillabusType }> = ({ visible, setVisible, id, sillabus }): JSX.Element => {

    const [data, setData] = useState<any>(null);
    const history = useHistory();
    const [subjectTypes, setSubjectTypes] = useState<Array<{ id: number, name: string }>>([]);
    const [max, setMax] = useState<{ max_ball: number, max_hour: number }>({ max_ball: 0, max_hour: 0 })
    const { t } = useTranslation();
    const [parent, setParent] = useState<string>('');

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOneSubject(id, 'expand=createdBy,updatedBy, description')
                        setData(response)
                        _getOneSubject(response?.parent_id)
                        const subjectTypes = await getAllSubjectTypes();
                        setSubjectTypes(subjectTypes?.items);
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible]);

    const _getOneSubject = async (id: number | string) => {
        if (id) {
            const res = await getOneSubject(Number(id));
            setParent(res.name)
        }
    }

    const getType = (id: number) => {
        let a = "";
        if (subjectTypes) {
            subjectTypes.map((item: { id: number, name: string }) => {
                if (item?.id === id) {
                    a = item?.name
                }
            })
        }
        return a
    };

    useEffect(() => {
        if (sillabus) {
            let maxBall: number = 0;
            let maxHour: number = 0;
            Object.entries(JSON.parse(sillabus?.edu_semestr_exams_types))?.forEach(([key, value]: any) => {
                if (value) {
                    maxBall += Number(value)
                }
            })
            Object.entries(JSON.parse(sillabus?.edu_semestr_subject_category_times))?.forEach(([key, value]: any) => {
                if (value) {
                    maxHour += Number(value)
                }
            })

            setMax({
                max_ball: maxBall,
                max_hour: maxHour
            })
        }
    }, [id, sillabus])


    return (
        <>
            {data && <Modal
                title={t("Subject view")}
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Row gutter={[12, 12]}>
                    <Col span={24} ><Text>{t("Name of subject")}: </Text>&nbsp;{data.name}</Col>
                    <Col span={24} ><Text>{t("Description for subject")}: </Text>&nbsp;{data?.description}</Col>
                    {parent ? <Col span={24} ><Text>{t("Parent")}: </Text>&nbsp;{parent}</Col> : null}
                    <Col span={24} ><Text>{t("Semester")}: </Text>&nbsp;{data?.semestr_id}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Status")}: </Text><BadgeStatus status={data.status} /></Col>
                </Row>
                {sillabus ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Sillabus")}</Text></Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Type")}: </Text>{getType(sillabus?.subject_type_id)}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Credit")}: </Text>{sillabus?.credit}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Max ball")}: </Text>{max.max_ball}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Total hour")}: </Text>{max.max_hour}</Col>
                    <Col lg={{ span: 12, offset: 12 }} xs={{ span: 24 }}><Button style={{ float: "right" }} onClick={() => history.push({
                        pathname: '/subject-sillabus',
                        state: {
                            id: data?.id,
                            name: data?.name,
                            sillabus: sillabus
                        }
                    })} type="primary"><FaEdit /></Button></Col>
                </Row> : null}
                {data.createdBy ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Created By")}</Text></Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Firstname")}: </Text>{data.createdBy.first_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Lastname")}: </Text>{data.createdBy.last_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Login")}: </Text>{data.createdBy.username}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data.createdBy.role}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Created at")}: </Text>{moment.unix(data?.created_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}
                {data.updatedBy ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Updated By")}</Text></Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("First Name")}: </Text>{data.updatedBy.first_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data.updatedBy.last_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Login")}: </Text>{data.updatedBy.username}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data.updatedBy.role}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Updated at")}: </Text>{moment.unix(data?.updated_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}
            </Modal>}
        </>
    )
}


export default SubjectView;