import { FC, useState } from 'react';
import { Card, Row, Col, Calendar } from 'antd';
import { useTranslation } from "react-i18next";
import { FaBookReader, FaBriefcase, FaGraduationCap, FaUsers } from 'react-icons/fa';
import { EverySubjectOnCard, TimeTableCardMain } from '../TeacherPanel/style';
import { BiDotsVerticalRounded, BiEditAlt, BiTrash } from 'react-icons/bi';
import MessageInDashboard from '../common/message';
import "../style.scss"
import ForTitle from 'components/ForTitles';
import LessonCard from '../TeacherPanel/lessonCard';
import { MdPeopleOutline } from 'react-icons/md';
import { GiTeacher } from 'react-icons/gi';
import { GrUserWorker } from 'react-icons/gr';
import useGetAllData from 'hooks/useGetAllData';

const lessons = [
    {
        day: 'Dushanba',
        lessons: [
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '2 bino',
                room: '16 A'
            },
            {
                name: 'Xalqaro',
                type: 'Yozma',
                build: '3 bino',
                room: '20 B'
            },
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '1 bino',
                room: '11 A'
            },
        ]
    },
    {
        day: 'Seshanba',
        lessons: null
    },
    {
        day: 'Chorshanba',
        lessons: [
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '2 bino',
                room: '16 A'
            },
            {
                name: 'Xalqaro',
                type: 'Yozma',
                build: '3 bino',
                room: '20 B'
            },
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '1 bino',
                room: '11 A'
            },
        ]
    },
    {
        day: 'Payshanba',
        lessons: [
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '2 bino',
                room: '16 A'
            },
            {
                name: 'Xalqaro',
                type: 'Yozma',
                build: '3 bino',
                room: '20 B'
            },
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '1 bino',
                room: '11 A'
            },
        ]
    },
    {
        day: 'Juma',
        lessons: [
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '2 bino',
                room: '16 A'
            },
            {
                name: 'Xalqaro',
                type: 'Yozma',
                build: '3 bino',
                room: '20 B'
            },
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '1 bino',
                room: '11 A'
            },
        ]
    },
]

const MudirPanel: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const [isSetting, setIsSetting] = useState<number | null>(null);
    const [canOpen, setCanOpen] = useState<number>(-1)
    const allStd = useGetAllData({url: '/students', isCall: 'auto'})
    const allTeach = useGetAllData({url: 'users?filter={%22-role_name%22:[%22student%22],%22role_name%22:%22teacher%22}&filter-like={}', isCall: 'auto'})
    const all = useGetAllData({url: '/users?per-page=15&currentPage=1', isCall: 'auto'})

    const isOpenSetting = (id: number) => {
        if (id === isSetting) {
            setIsSetting(null)
        } else {
            setIsSetting(id)
        }
    }

    return (
        <>
            <ForTitle title='Dashboard' buttons={[null]}/>
            <Row>
                <Col lg={24} sm={24} md={24} xl={6} xs={24}>
                    <div className='forBorder mx-1'>
                        <Card bordered={false}>
                            <p className="text-truncate mb-2">{t("Students")}</p>
                            <h4 className="mb-0">{allStd.data._meta.totalCount}</h4>
                            <MdPeopleOutline className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.23% </span>
                            </div> */}
                        </Card>
                        <Card bordered={false}>
                            <p className="text-truncate mb-2">{t("Teachers")}</p>
                            <h4 className="mb-0">{allTeach.data._meta.totalCount}</h4>
                            <GiTeacher className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.03% </span>
                            </div> */}
                        </Card>
                        <Card bordered={false}>
                            <p className="text-truncate mb-2">{t("Masters")}</p>
                            <h4 className="mb-0">{all.data._meta.totalCount}</h4>
                            <GrUserWorker className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.05% </span>
                            </div> */}
                        </Card>
                    </div>
                </Col>
                {/* <Col sm={24} md={24} lg={24} xl={18} className='mb-3'>
                    <Card className='forLessons mx-1' title={<h5>Dars jadvali</h5>}>
                        <Row>
                            {
                                lessons.map((item: any, index: number) => (
                                    <Col sm={24} md={24} lg={12} xl={12} key={index}>
                                        <LessonCard title={item.day} lessons={item.lessons} index={index} canOpen={canOpen} setCanOpen={setCanOpen}/>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Card>
                </Col> */}
                <Col sm={24} md={24} lg={24} xl={24} xs={24}>
                    <Row>
                        <Col sm={24} md={24} lg={12} xl={12} xs={24}>
                            <Card title={<h5>Kalendar</h5>} className='mx-1'>
                                <Calendar fullscreen={false} />
                            </Card>
                        </Col>
                        <Col sm={24} md={24} lg={12} xl={12} xs={24}>
                            <Card title={<h5>Xabarlar</h5>} className='mx-1' style={{height: '100%'}}>
                                <MessageInDashboard/>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}


export default MudirPanel;