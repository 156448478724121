export const vacancy_data = [
    { item: "Bo'sh ish o'rni", count: 4600, percent: 4600 / 60 },
    { item: "Umumiy shtatlar", count: 300, percent: 300 / 60 },
    { item: "Band bo'lgan shtatlar", count: 1100, percent: 1100 / 60 }
]
export const gender_data = [
    { item: 'Ayol', count: 381, percent: 381 * 100 / 644 },
    { item: 'Erkak', count: 263, percent: 263 * 100 / 644 }
]
export const language_data = [
    { item: 'Ingliz tili', count: 381, percent: 381 * 100 / 644 },
    { item: 'Rus tili', count: 263, percent: 263 * 100 / 644 }
]
export const edu_level_data = [
    { item: "O'rta-maxsus", count: 381, percent: 381 * 100 / 644 },
    { item: "Bakalavriat", count: 381, percent: 381 * 100 / 644 },
    { item: "magistratura, ilmiy unvon olishga yoʼnaltirilgan oliy taʼlimdan keyingi taʼlim toʼgʼrisida diplom (sertifikat), kasbiy tajriba", count: 381, percent: 381 * 100 / 644 },
    { item: "Oliy", count: 381, percent: 381 * 100 / 644 },
]
export const age_data = [
    { item: "18-35", count: 381, percent: 381 * 100 / 644 },
    { item: "25-40", count: 381, percent: 381 * 100 / 644 },
    { item: "40-60", count: 381, percent: 381 * 100 / 644 },
    { item: "60 yoshdan yuqorilar", count: 381, percent: 381 * 100 / 644 },
]
export const country_data = [
    { item: "O'zbekiston fuqorosi", count: 200, percent: 200 / 60 },
    { item: 'Chet el fuqorosi', count: 5800, percent: 5800 / 60 },
]

export const data = [
    {
        name: 'Jinoiy odil sudlov',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
    {
        name: 'Ommaviy huquq',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
    {
        name: 'Xalqaro huquq va qiyosiy huquqshunoslik',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
    {
        name: 'Xususiy huquq',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
    {
        name: 'Magistratura va sirtqi ta’lim',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
];


export const dataSourceLanguage1 = [
    {
        key: '1',
        name: 'Ingliz tili',
        count: 32
    },
    {
        key: '2',
        name: 'Rus tili',
        count: 42
    },
];

export const columnsLanguage = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Count',
        dataIndex: 'count',
        key: 'count'
    },
];
export const dataSourceLanguage2 = [
    {
        key: '1',
        name: 'Ingliz tili',
        count: 32
    },
    {
        key: '2',
        name: 'Rus tili',
        count: 42
    },
];


export const dataSourceEduLevel1 = [
    {
        key: '1',
        name: "O'rta-maxsus",
        count: 32
    },
    {
        key: '2',
        name: 'Bakalavriat',
        count: 42
    },
    {
        key: '3',
        name: "Magistratura, ilmiy unvon olishga yoʼnaltirilgan oliy taʼlimdan keyingi taʼlim toʼgʼrisida diplom (sertifikat), kasbiy tajriba",
        count: 42
    },
    {
        key: '4',
        name: 'Oliy',
        count: 42
    },
];

export const columnsEduLevel = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Count',
        dataIndex: 'count',
        key: 'count'
    },
];
export const dataSourceEduLevel2 = [
    {
        key: '1',
        name: 'Ingliz tili',
        count: 32
    },
    {
        key: '2',
        name: 'Rus tili',
        count: 42
    },
];
