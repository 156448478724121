import { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tbody, Tr, Td } from "react-super-responsive-table";
import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
import { getAllBuildings } from "./service";
import { asyncN } from "utils/notifications";
import EmptyTable from "components/EmptyTable";
import IndexPageTitle from "components/IndexPageTitle";
import DiscriptionStyle from "components/DescriptionStyle";
import SimpleCreate from "pages/common/simple_create";
import SimpleEdit from "pages/common/simple_edit";
import { ButtonCreate } from "components/Buttons";
import SimpleView from "pages/common/simple_view";
import Actions from "components/Actions";
import TotalCount from "components/totalCountTag";
import SimpleTable from "components/simpleTable";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";

const Building: FC = (): JSX.Element => {

    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [reFetch, setReFetch] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [tableOption, setTableOption] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true)
                    const response = await getAllBuildings(perPage, currentPage, 'expand=description');
                    setData(response?.items)
                    setTableOption(response?._meta)
                    setLoading(false);
                } catch (error: any) {
                    asyncN('error', 'read', error?.response?.message)
                }
            }
        )()
    }, [i18n.language, reFetch, currentPage, perPage]);

    return (
        <div>
            <ForTitle title="Buildings" buttons={[isHasAuthRoute('building_create') && <ButtonCreate onClick={() => setVisibleCreate(true)} />]}/>
            {id && visibleView ? <SimpleView visible={visibleView} setVisible={setVisibleView} url={`/buildings/${id}?expand=description,createdBy,updatedBy`} title="Building view" /> : null}
            {id && visibleEdit ? <SimpleEdit url={`/buildings/${id}?expand=description`} visible={visibleEdit} setVisible={setVisibleEdit} setReFetch={setReFetch} title='Building Edit' /> : null}
            {visibleCreate ? <SimpleCreate url="/buildings" visible={visibleCreate} setVisible={setVisibleCreate} title='Building Create' setReFetch={setReFetch} /> : null}
            {/* {loading ? <div className="loader-line"></div> : <div style={{ height: '3px' }}></div>}
            <Table id="tech-companies-1" className="table">
                <TableHeader thData={["Name", "Description", "Status"]} />
                <Tbody>
                    {
                        data?.map((element: any, i: number) => (
                            <Tr key={i}>
                                <Td>{!loading ? i + 1 : '-'}</Td>
                                <Td>{element.name}</Td>
                                <Td><DiscriptionStyle dis={element?.description} /></Td>
                                <Td><BadgeStatus status={`${t(element.status)}`} /></Td>
                                <Td>
                                    <Actions
                                        id={element?.id}
                                        url="buildings"
                                        setId={setId}
                                        setReFetch={setReFetch}
                                        setVisibleView={setVisibleView}
                                        setVisibleEdit={setVisibleEdit}
                                        viewPermission="building_view"
                                        editPermission="building_update"
                                        deletePermission="building_delete"
                                    />
                                </Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table> */}
            <ForTable setCurrentPage={setCurrentPage} setPerPage={setPerPage} currentPage={currentPage} perPage={perPage} totalCount={tableOption?.totalCount} loading={loading} datas={data} theads={
                    [
                        "Name",
                        "Description",
                        "Status",
                        "Actions"
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            <>{this.name}</>,
                            <><DiscriptionStyle>{this?.description}</DiscriptionStyle></>,
                            <><BadgeStatus status={`${t(this.status)}`} /></>,
                            <>
                                <Actions
                                    id={this?.id}
                                    url="/buildings"
                                    setId={setId}
                                    setReFetch={setReFetch}
                                    setVisibleView={setVisibleView}
                                    setVisibleEdit={setVisibleEdit}
                                    viewPermission="building_view"
                                    editPermission="building_update"
                                    deletePermission="building_delete"
                                />
                            </>
                        ]
                    }
                }/>
        </div>
    )
}

export default Building;