import instance from "config/_axios"
import { asyncN } from "utils/notifications"

export const getAllSubjectContents = async (prefix?: string, perPage?: number, currentPage?: number) => {
    try {
        const response = await instance({
            url: `/subject-contents?${prefix ? prefix : ''}${prefix ? '&' : ''}per-page=${perPage ? perPage : 0}&page=${currentPage ? currentPage : 1}`,
            method: 'get'
        })
        if (response?.data?.status === 1) {
            return response?.data?.data
        } else {
            asyncN('error', 'read', response?.data?.message)
        }
    } catch (err: any) {
        asyncN('error', 'read', err?.response?.data ? err?.response?.data?.message : "Error for red subject contents!")
    }
}

export const getOneSubjectContent = async (id: number, prefix?: string) => {
    try {
        const response = await instance({
            url: `/subject-contents/${id}?${prefix}`,
            method: 'get'
        })
        if (response?.data?.status === 1) {
            return response?.data?.data
        } else {
            asyncN('error', 'read', response?.data?.message)
        }
    } catch (err: any) {
        asyncN('error', 'read', err?.response?.data ? err?.response?.data?.message : "Error for red subject contents!")
    }
}

export const saveOrEditSubjectContent = async (data:any,id?:number) => {
    try{
        const response=await instance({
            url:`/subject-contents${id?'/'+id:''}`,
            method:`${id?'put':'post'}`,
            data:data
        })
        if(response?.data?.status===1){
            asyncN('success',id?'update':'create',response?.data?.message)
            return 1
        }else{
            asyncN('error',id?'update':'create',response?.data?.message)
        }
    }catch(err:any){
        asyncN('error',id?'update':'create',err?.response?.data?err?.response?.data?.message:"Error for red subject contents!")
    }
}

export const getSubjectContentTypes = async () => {
    try {
        const response = await instance({
            url: `/subject-contents/types`,
            method: 'get'
        })
        return response?.data
    } catch (err: any) {
        asyncN('error', 'read', err?.response?.data ? err?.response?.data?.message : "Error for red subject content types!")
    }
}