import { message } from "antd";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";



export const getOneRole = async (id: number | null, path_prefix?: string) => {
    try {
        if (!id) {
            return new Error("Id bo'yicha ma'lumot olinmadi !");
        }

        const response = await instance({ url: `/roles/${id}${path_prefix ? '?' + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
};





export const getAllRoles = async (perPage?: number, currentPage?: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/roles${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });
        
        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllPermisions= async () => {
    try{
        const response = await instance({
            method: 'get',
            url: 'permissions'
        })
    
        if (response.data.status === 1) {
            return response?.data?.data
        }else{
            asyncN("error","read",response?.data?.message)
        }
    }catch(e:any){
        asyncN("error","read","Error read permissions!!!");
    }
}
