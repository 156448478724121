import React, { FC } from "react";
import { TableLineLoader } from "components/SimpleComponents";
import TableHeader from "components/tableHeader";
import { Table, Tbody } from "react-super-responsive-table";

const SimpleTable: FC<{ children: any, header: Array<string>, loading: boolean }> = ({ children, header, loading })=> {

  return (
    <>
      <TableLineLoader loading={loading} />
                <div className="tableWrap">
      <Table id="tech-companies-1" className="table for-table">
        <TableHeader thData={header} />
        <Tbody>
          {children}
        </Tbody>
      </Table>
                </div>
    </>
  )
}

export default SimpleTable;