import { FC, useState } from "react";
import { useHistory } from "react-router";
import { Menu, Dropdown } from 'antd';
import { FaShareSquare, FaUser, FaUserGraduate, FaUserTie } from 'react-icons/fa';
import RippleButton from "./RippleButton";
import { ThemeType } from '../../../../../../config/theme/type';
import { HeaderUserBox } from "./ui_styled";
import _logout from "../../../../../../config/_axios/logout";
import './styles.scss';

const UserProfile: FC<{ theme: ThemeType, auth: any, collapse?: boolean }> = ({ theme, auth, collapse }): JSX.Element => {

    const history: any = useHistory();

    const [open, setOpen] = useState<Array<string>>([''])

    return (
        <div>
            <Menu
                className='forUl'
                mode="inline"
                openKeys={open}
            >
                <Menu.SubMenu
                onTitleClick={() => {
                    if(open.includes('open')){
                        setOpen([''])
                    } else {
                        setOpen(['open'])
                    }
                }}
                key='open'
                title={
                    <div className={`d-f justify-content-${collapse ? 'center' : 'between'} mx-2`}>
                        <div style={{ color: "white", lineHeight: 1, fontSize: "16px", display: collapse ? 'none' : 'block' }}>
                            {auth?.user?.first_name} {auth?.user?.last_name}
                        </div>
                        <span className="d-f ms-1 p-1" style={{ borderRadius: "50%", border: "1px solid" }}>
                            <FaUserGraduate size={20}/>
                        </span>
                    </div>
                }>
                    <Menu.Item onClick={() => history.push('/user-profile')} key="1">
                        <FaUser />&nbsp;Profile
                    </Menu.Item>
                    <Menu.Item key="2" onClick={() => _logout()}>
                        <FaShareSquare />&nbsp;Logout
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        </div>
    )
}

export default UserProfile;