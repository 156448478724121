import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export type DormitoryInitialStateType = {
    category_id: null | number,
    visible: 'create' | 'update' | 'view' | null,
    reFetch: boolean
}

const initialState: DormitoryInitialStateType = {
    category_id: null,
    visible: null,
    reFetch: false
}


const dormitorySlice = createSlice({
    name: 'dormitory',
    initialState,
    reducers: {
        createModal(state) {
            state.visible = 'create'
        },
        updateModal(state, action: PayloadAction<{ category_id: number }>) {
            state.visible = 'update';
            state.category_id = action.payload.category_id;
        },
        closeModal(state) {
            state.visible = null;
            state.category_id = null;
        },
        reFetch(state) {
            state.reFetch = !state.reFetch
        }
    }
})


export const DORMITORY_ACTIONS = dormitorySlice.actions;

export default dormitorySlice;