import { Button, Col, Form, Input, Row } from "antd";
import instance from "config/_axios";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/services";
import { asyncN } from "utils/notifications";
import { referenceModalType, subjectTopicReferenceType } from "./types";

const QuestionForDiscussion: FC<referenceModalType> = ({ topicId, userId, data, refresh, setRefresh, closeModal }): JSX.Element => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const auth: any = useAppSelector(state => state.auth);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                ...data
            })
        }
    }, [data])

    const onFinish = async (values: any) => {
        try {
            let dataReq = new FormData();
            dataReq.append("name", values?.name);
            dataReq.append("subject_topic_id", `${topicId}`);
            if (!auth?.user?.role?.includes("teacher")) {
                dataReq.append("user_id", `${userId}`);
            }
            dataReq.append("type", `4`);
            dataReq.append("status", `1`);
            const resp = await instance({
                url: `/subject-topic-references${data ? '/' + data?.id : ''}`,
                method: data ? 'put' : 'post',
                data: dataReq
            })
            if (resp?.data?.status === 1) {
                asyncN("success", data ? "update" : "create", resp?.data?.message).then(() => {
                    setRefresh(!refresh);
                    closeModal(false);
                    form.resetFields()
                })
            } else {
                asyncN("error", data ? "update" : "create", resp?.data?.message)
            }
        } catch (error: any) {
            asyncN("error", data ? "update" : "create", error?.response ? error?.response?.data?.message : error?.message)
        }
    }

    return (
        <Form onFinish={onFinish} form={form}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name={'name'}>
                        <Input.TextArea style={{ height: "105px" }} placeholder="Muhokama uchun savol!" />
                    </Form.Item>
                </Col>
                <Col className="d-f justify-content-end" span={24}>
                    <Button onClick={() => {
                        closeModal(false)
                        form.resetFields()
                    }} danger type="primary">{t("Cancel")}</Button>
                    <Button className="mx-1" htmlType="reset">{t("Reset")}</Button>
                    <Button type="primary" htmlType="submit">{t("Save")}</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default QuestionForDiscussion;