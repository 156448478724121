import { IDefaultData } from "models/data";
import React from "react";
import asyncThunkDefaults from "services/asyncThunkDefaults";
import { TypeStoredDataKeys } from "store/defaults";
import { useAppDispatch, useAppSelector } from "store/services";
import { DEFAULT_RD } from "utils";


export type StoredDataTypes = 'languages' | 'subjects' | 'countries' | 'nationalities' | 'courses' | 'edu_forms' | 'edu_types' | 'statuses' | 'faculties' | 'student_categories' | 'citizenships' | 'time_options' | 'buildings' | 'week' | 'para' | 'cohabitants' | 'regions' | 'kafedra' | 'department'

const stored_data: Record<StoredDataTypes, { key: StoredDataTypes, url: string }> = {
    languages: {
        key: 'languages',
        url: '/languages'
    },
    subjects: {
        key: 'subjects',
        url: '/subjects'
    },
    countries: {
        key: 'countries',
        url: '/countries'
    },
    nationalities: {
        key: 'nationalities',
        url: '/nationalities'
    },
    courses: {
        key: 'courses',
        url: '/courses'
    },
    edu_forms: {
        key: 'edu_forms',
        url: '/edu-forms'
    },
    edu_types: {
        key: 'edu_types',
        url: '/edu-types'
    },
    statuses: {
        key: 'statuses',
        url: '/user-statuses'
    },
    faculties: {
        key: 'faculties',
        url: '/faculties'
    },
    student_categories: {
        key: 'student_categories',
        url: '/student-categories'
    },
    citizenships: {
        key: 'citizenships',
        url: '/citizenships'
    },
    time_options: {
        key: 'time_options',
        url: '/time-options?expand=faculty,eduSemester'
    },
    buildings: {
        key: 'buildings',
        url: '/buildings'
    },
    week: {
        key: 'week',
        url: '/weeks'
    },
    para: {
        key: 'para',
        url: '/paras'
    },
    cohabitants: {
        key: 'cohabitants',
        url: '/category-of-cohabitants'
    },
    regions: {
        key: 'regions',
        url: '/regions'
    },
    kafedra: {
        key: 'kafedra',
        url: '/kafedras'
    },
    department: {
        key: 'department',
        url: '/departments'
    },
}

export type PropsTypeUseStoreData = {
    key: StoredDataTypes,
    url?: string,
    params?: Record<string | number, any>
}


export type ReturnTypeUseStoreData<T> = {
    items: T[],
    _meta: IDefaultData<any>['_meta'],
    status: TypeStoredDataKeys,
    loading: boolean
    _fetch: () => void,
}



const useStoreData = <T>({ key, params }: PropsTypeUseStoreData): ReturnTypeUseStoreData<T> => {

    const data = useAppSelector(state => state.defaults[key]);
    const dispatch: any = useAppDispatch();

    React.useEffect(() => {
        if (!data?.items.length || !data) {
            dispatch(asyncThunkDefaults({ ...stored_data[key], params }));
        }
    }, []);

    const _fetch = React.useCallback(async () => {

        if (!data?.items.length || !data) {
            dispatch(asyncThunkDefaults({ ...stored_data[key], params }));
        }

    }, [data?.items])

    if (data) {
        return { ...data, _fetch } as const;
    }

    return {
        items: [],
        _meta: DEFAULT_RD._meta,
        status: 'error',
        loading: false,
        _fetch
    }


}

export default useStoreData;
