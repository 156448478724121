import { FC } from "react";
import { Tag } from 'antd';


export const EmptySpaceLine: FC<{ color?: string, [key: string]: any }> = (props): JSX.Element => <Tag color={props?.color || 'transparent'} {...props}></Tag>;

/**
 * @goal to make line loader
 * @param props props includs loading and any html tag attributes
 * @returns jsx element
 */
export const TableLineLoader: FC<{ loading: boolean, [key: string]: any }> = (props): JSX.Element => props.loading ? <div className="loader-line"></div> : <div style={{ height: '3px' }}></div>;

export function LabelServerData({ text }: { text: any }) {
  return (
      <span className="label_server_text">{text ? ' (' + text + ')' : null}</span>
  )
}