import { message } from "antd";
import instance from "config/_axios";

// export const getOneSubjectType = async (id: number | null, path_prefix?: string) => {
//     try {
//         if (!id) {
//             return new Error("Id bo'yicha ma'lumot olinmadi !");
//         }

//         const response = await instance({ url: `/subject-types/${id}${path_prefix ? '?' + path_prefix : ''}`, method: 'GET' })

//         if (response.data?.status === 1) {
//             return response.data?.data
//         } else {
//             return new Error(response.data?.message)
//         }
//     } catch (error: any) {
//         message.error(error?.response?.message)
//     }
// }

export const getAllSubjectTypes = async (perPage?: number, currentPage?: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/subject-types${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}