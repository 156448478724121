import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";



const ModalTitle: FC<{ children: ReactNode }> = ({ children }): JSX.Element => {

    const { t } = useTranslation();

    return (
        <span className="custom_modal_title" >{typeof children === 'string' ? t(children) : children}</span>
    )
}

export default ModalTitle;