import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Form } from 'antd';
import CustomBreadcrumb from "components/Breadcrumb";
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import EduPlanUi from './ui';
import moment from 'moment';

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Exams",
        path: 'exam_index'
    },
    {
        name: "Exam create",
        path: 'exam_create'
    },
]


const CreateExam: FC = (): JSX.Element => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [clicked, setClicked] = useState<boolean>(false)





    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            Object.entries(values).forEach(([key, value]: any) => {
                if (key === 'is_protected') {
                    formdata.append(key, value ? '1' : '0');
                } else if (key === "start_exam") {
                    formdata.append("start", moment(value).format("YYYY-MM-DD HH:mm:ss"))
                } else if (key === "finish_exam") {
                    formdata.append("finish", moment(value).format("YYYY-MM-DD HH:mm:ss"))
                } else if (key === "duration") {
                    formdata.append("duration", moment(value).format("HH:mm"))
                } else if (["edu_semestr_id","edu_plan_id"].includes(key)) {
                    
                } else {
                    formdata.append(key, String(value));
                }
            });

            const response = await instance({ url: '/exams', method: 'POST', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); history.goBack() })
            } else {
                asyncN("error", 'create', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();


    return (
        <>
            <CustomBreadcrumb arr={_breadcrump} />
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
                className="mt-4"
            >
                <EduPlanUi resetFields={resetFields} clicked={clicked} type="create" form={form} />
            </Form>
        </>
    )
}


export default CreateExam;