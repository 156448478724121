import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import 'assets/scss_css/index.scss';
import 'assets/scss_css/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import reportWebVitals from './reportWebVitals';
import store from './store/index'
import {Provider} from 'react-redux';
import App from "./App";


ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
