import { createSlice } from '@reduxjs/toolkit';
import { ui_var } from 'config/utils/variables';

const sidebarSlice = createSlice({
    name: 'ui',
    initialState: {
        collapse: window.innerWidth < 420
    },
    reducers: {
        sidebarStateNew(state, action) {
            state.collapse = action?.payload?.collapse
        }
    }
})


export const { sidebarStateNew } = sidebarSlice.actions;

export default sidebarSlice;