import { ChangeEvent, FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table";
import { Tooltip, Pagination, Switch, Row, Col, Select, Input, Tag, Form, Popover, Button, Popconfirm } from "antd";
import { TbFileOff, TbFilePencil } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import EmptyTable from "components/EmptyTable";
import { getAllEduSemesters } from "services";
import instance from "config/_axios";
import { getAllCoueses } from "../courses/service";
import { getAllEduYear } from "../edu_years/service";
import { getAllSemestrs } from "../semestr/service";
import { getAllEduPlans } from "../edu_plan/service";
import { NavLink } from "react-router-dom";
import InputMask from 'react-input-mask';
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";

const { Option } = Select;

const EduSemester: FC = (): JSX.Element => {

  const [form] = Form.useForm();
  const { i18n, t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [reRequest, setReRequest] = useState<boolean>(false);
  const [tableOption, setTableOption] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [courses, setcourses] = useState([]);
  const [course_id, setcourse_id] = useState<number | string>("");
  const [edu_years, setedu_years] = useState([]);
  const [edu_years_id, setedu_years_id] = useState<number | string>("");
  const [semesters, setsemesters] = useState([]);
  const [semester_id, setsemester_id] = useState<number | string>("");
  const [edu_plans, setedu_plans] = useState([]);
  const [edu_plan_id, setedu_plan_id] = useState<number | string>("");
  const [credit, setCredit] = useState<any>({});
  const [visible, setVisible] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const filters = JSON.stringify({
          edu_year_id: edu_years_id,
          edu_plan_id: edu_plan_id,
          semestr_id: semester_id,
          course_id: course_id
        })
        const response = await getAllEduSemesters(
          perPage,
          currentPage,
          `sort=-id&expand=description,eduYear,course,semestr,eduPlan&filter=${filters}`
        );
        setData(response?.items);
        setTableOption(response?._meta);
        setLoading(false);
      } catch (error: any) {
        asyncN("error", "read", error?.response?.message);
      }
    })();
  }, [i18n.language, reRequest, perPage, currentPage, isLoading, edu_years_id, edu_plan_id, semester_id, course_id]);

  const onSubmit = async (value: boolean, id: number, type: string) => {

    try {
      const formdata = new FormData();
      if (type === "status") {
        formdata.append("status", value ? "1" : "0");
      } else if (type === "holat") {
        formdata.append("is_checked", value ? "1" : "0");
      }

      const response = await instance({
        url: `/edu-semestrs/${id}`,
        method: "PUT",
        data: formdata,
      });

      if (response.data.status === 1) {
        // asyncN("success", 'update', response.data?.message)
        setReRequest(!reRequest);
      } else {
        asyncN("error", "update", response.data?.message);
      }
    } catch (error: any) {
      if (error?.response?.status === 422) {
        if (error?.response?.data?.status === 0) {
        }
      }
      asyncN("error", "update", error?.response?.message);
    }
  };

  const getAllCourses = () => {
    if (courses?.length === 0) {
      (async () => {
        try {
          const response = await getAllCoueses(
            "all",
            "all",
            `expand=description`
          );
          setcourses(response?.items);
        } catch (error: any) {
          asyncN("error", "read", error?.response?.message);
        }
      })();

    }
  }

  const getEduYears = () => {
    if (edu_years?.length === 0) {
      (
        async () => {
          try {
            const response = await getAllEduYear("'all", "all", 'expand=description');
            setedu_years(response?.items)
          } catch (error: any) {
            asyncN('error', 'read', error?.response?.message)
          }
        }
      )()
    }
  }

  const getSemesters = () => {
    if (semesters?.length === 0) {
      (
        async () => {
          try {
            const response = await getAllSemestrs("all", "all", `expand=description`);
            setsemesters(response?.items)
          } catch (error: any) {
            asyncN('error', 'read', error?.response?.message)
          }
        }
      )()
    }
  }

  const getEduPlans = () => {
    if (edu_plans?.length === 0) {
      (
        async () => {
          try {
            const response = await getAllEduPlans("all", "all", `expand=description`);
            setedu_plans(response?.items)
          } catch (error: any) {
            asyncN('error', 'read', error?.response?.message)
          }
        }
      )()
    }
  }

  const checkNumber = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    let value = e.target.value;
    const a: string = "1234567890"
    const type = value.slice(value?.length - 1, value?.length);
    if (!a.includes(type)) {
      value = value.slice(0, value?.length - 1);
    }
    setCredit({ ...credit, [`credit_${id}`]: value })
  }

  const submit = async (credit: string, id: number) => {
    try {
      const formdata = new FormData();
      formdata.append("credit", credit);

      const res = await instance({
        url: `/edu-semestrs/${id}`,
        method: 'PUT',
        data: formdata,
      })

      if (res.data.status === 1) {
        asyncN("success", 'update', res.data?.message)
        setIsLoading(!isLoading);
        setVisible(-1);
      } else {
        asyncN("error", 'update', res.data?.message)
      }

    } catch (error: any) {
      asyncN("error", "read", error?.response?.message)
    }
  }

  const onConfirm = async (values: any) => {
    const {id} = values
    try {
      const res = await instance({
        url: `/edu-semestrs/${id}`,
        method: 'DELETE'
      })

      if (res.data.status === 1) {
        asyncN("success", 'update', res.data?.message)
      }
    } catch (error: any) {
      asyncN("error", "read", error?.response?.message)
    }
  }

  return (
    <div>
      <ForTitle title="Education semesters" buttons={[null]}/>
      <Row gutter={[12, 8]} justify="space-between">
        <Col
          xl={{ span: 5 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 12 }}
          xs={{ span: 24 }}
        >
          <Select
            className="w-100"
            defaultValue=""
            placeholder={t("Filter by edu year") + " ..."}
            onChange={(e: number | string) => setedu_years_id(e)}
            onFocus={getEduYears}
          >
            {[...edu_years, { id: "", name: t("By all edu years") }].map((e: any) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xl={{ span: 5 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 12 }}
          xs={{ span: 24 }}
        >
          <Select
            className="w-100"
            defaultValue=""
            placeholder={t("Filter by edu plan") + " ..."}
            onChange={(e: number | string) => setedu_plan_id(e)}
            onFocus={getEduPlans}
          >
            {[...edu_plans, { id: "", name: t("By all edu plan") }].map(
              (e: any) => {
                return (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                );
              }
            )}
          </Select>
        </Col>
        <Col
          xl={{ span: 5 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 12 }}
          xs={{ span: 24 }}
        >
          <Select
            className="w-100"
            defaultValue=""
            placeholder={t("Filter by semester") + " ... "}
            onChange={(e: number | string) => setsemester_id(e)}
            onFocus={getSemesters}
          >
            {[
              ...semesters,
              { id: "", name: t("By all semesters") },
            ].map((e: any) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col
          xl={{ span: 5 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 12 }}
          xs={{ span: 24 }}
          className="d-flex"
        >
          <Select
            className="w-100"
            defaultValue=""
            placeholder={t("Filter by course") + " ... "}
            onChange={(e: number | string) => setcourse_id(e)}
            onFocus={getAllCourses}
          >
            {[
              ...courses,
              { id: "", name: t("By all courses") },
            ].map((e: any) => {
              return (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <ForTable totalCount={tableOption?.totalCount} setCurrentPage={setCurrentPage} currentPage={currentPage} setPerPage={setPerPage} perPage={perPage} loading={loading} datas={data} theads={
          [
            <>{t("Name")}</>,
            <>{t("Course")}</>,
            <>{t("Semester")}</>,
            <>{t("Edu plan")}</>,
            <>{t("Edu Year")}</>,
            <>{t("Start date")}</>,
            <>{t("End date")}</>,
            <>{t("Credit")}</>,
            <>{t("Confirmation")}</>,
            <>{t("Status")}</>,
            <>{t("Subject")}</>,
            <>{t("Actions")}</>,
          ]
        } tbodys={
          function(this: any){
            return [
              <>{this?.name}</>,
              <>{this?.course?.name}</>,
              <>{this?.semestr?.name}</>,
              <>{this?.eduPlan?.name}</>,
              <>{this?.eduYear?.name}</>,
              <>{this?.start_date}</>,
              <>{this?.end_date}</>,
              <>
                <Popover
                  content={<>
                    <Input size="small" name={`credit_${this?.id}`} value={credit[`credit_${this?.id}`]} placeholder={t("Enter credit") + ' ...'} onChange={(e) => checkNumber(e, this?.id)} ></Input>
                    <div className="text-end mt-2" >
                      <Button className="me-2" type="primary" danger size="small" onClick={() => setVisible(-1)} >Cancle</Button>
                      <Button type="primary" size="small" onClick={() => { submit(credit[`credit_${this?.id}`], this?.id) }} >{t('Save')}</Button>
                    </div>
                  </>}
                  trigger="click"
                  visible={visible === this?.id}
                >
                  <Button type="link" size="small" onClick={() => { setVisible(this?.id); setCredit({ ...credit, [`credit_${this?.id}`]: this?.credit }) }} >{this?.credit}</Button>
                </Popover>
              </>,
              <>
                <Switch
                  onChange={(e) => onSubmit(e, this?.id, "holat")}
                  checkedChildren="on"
                  unCheckedChildren="off"
                  checked={this?.is_checked}
                  size="small"
                />
              </>,
              <>
                <Switch
                  onChange={(e) => onSubmit(e, this?.id, "status")}
                  checkedChildren="on"
                  unCheckedChildren="off"
                  checked={this?.status}
                  size="small"
                />
              </>,
              <>
                {isHasAuthRoute("edu-semestr-subject_index") && (
                  <NavLink to={{
                    pathname: `/edu-semestr-subject_create/${this?.id}`,
                    state: {
                      id: this?.id,
                      parent_path:[
                        {
                          name: "Education Semester",
                          path: 'edu_semestr_index'
                        },
                      ]
                    }
                  }}>
                    {t("Attach")}
                  </NavLink>
                )}
              </>,
              <>
                <div className="crud_actions_table">
                  {isHasAuthRoute("edu-semestr_view") && (
                    <Tooltip placement="top" title={t("View")}>
                      <NavLink to={{
                        pathname: `edu-semestr_view/${this?.id}`,
                        state: {
                          id: this?.id,
                          parent_path:[
                            {
                              name: "Education Semester",
                              path: 'edu_semestr_index'
                            },
                          ]
                        }
                      }}>
                        <TbFileInvoice  className="view_btn" />
                      </NavLink>
                    </Tooltip>
                  )}
                  {isHasAuthRoute("edu-semestr_update") && (
                    <Tooltip placement="top" title={t("Edit")}>
                      <NavLink to={{
                        pathname: "edu-semestr_update",
                        state: {
                          id: this?.id,
                        }
                      }}>
                        <TbFilePencil className="edit_btn" />
                      </NavLink>
                    </Tooltip>
                  )}
                </div>
              </>
            ]
          }
        }/>
    </div>
  );
};

export default EduSemester;
