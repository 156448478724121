
import EmptyTable from 'components/EmptyTable';
import { Tag, Pagination, Input, Tooltip, Button, Collapse, Popover } from 'antd';
import CustomBreadcrumb from 'components/Breadcrumb';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Table, Thead, Tr, Th, Tbody, Td } from 'react-super-responsive-table';
import { useRouteMatch, match } from 'react-router';
import useDebounce from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import useGetAllData from 'hooks/useGetAllData';
import { useHistory } from 'react-router-dom';
import _delete from 'pages/jobs/components/delete';
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import isHasAuthRoute from 'utils/isHasRoute';
import moment from 'moment';
import useGetOneData from 'hooks/useGetOneData';
import { exam_status_list, exam_student_status_list } from '../exam_status_list';
import { EmptySpaceLine, TableLineLoader } from 'components/SimpleComponents';
import { BiChevronDown, BiChevronUp, BiPlus } from 'react-icons/bi';
import InputMask from 'react-input-mask';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import "../styles.scss";
import AppelationPopver from './components/AppelationPopover';

const { Panel } = Collapse;



export const encodeKey = (key: string): string => {
    if (Boolean(Number(key))) {
        let a: string = ""
        for (let i = 0; i < key?.length; i++) {
            a += String.fromCharCode(Number(key[i]) + 97)
        }
        return a
    } else {
        return "no encode";
    }
}

export const decodeKey = (key: string): string => {
    let a: string = ""
    for (let i = 0; i < key?.length; i++) {
        a += String(Number(key.charCodeAt(i) - 97));
    }

    return a;
}

const AppealStudents: FC = (): JSX.Element => {

    const match: match<any> | null = useRouteMatch('/exam-appeal_index/:exam_id');
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [refetch, setRefetch] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState<string>('');
    const [popoverBall, setPopoverBall] = useState<number | null>(null);

    const exam_id = match?.params?.exam_id;

    if (!exam_id) {
        history.goBack();
    }


    const { value, writeToUrl } = useUrlQueryParams({});
    const debouncedLastName = useDebounce<string>(value.filter_like?.last_name, 500);
    const debouncedFirstName = useDebounce<string>(value.filter_like?.first_name, 500);
    const debouncedPassportSeria = useDebounce<string>(value.filter_like?.passport_seria, 500);
    const debouncedPassportNumber = useDebounce<string>(value.filter_like?.passport_number, 500);
    const debouncedPassportPin = useDebounce<string>(value.filter_like?.passport_pin, 500);
    // `/exam-appeals?expand=description,appeal_start,appealFinish,teacherAccess,examStudentAnswers,exam,examStudent,examStudent.allBall,student.profile,${exam_id ? `&filter={"exam_id":${exam_id}}` : null}${`&filter-like=${JSON.stringify(value.filter_like)}`}`
    const appeal_students = useGetAllData({
        url: `/exam-appeals?expand=description,appeal_start,appealFinish,examStudentAnswers,exam,examStudent,examStudent.allBall,student.profile,${exam_id ? `&filter={"exam_id":${exam_id}}` : null}${`&filter-like=${JSON.stringify(value.filter_like)}`}`,
        isCall: 'auto',
        perPage: value?.perPage,
        currentPage: value.currentPage,
        refetch: [refetch, debouncedFirstName, debouncedLastName, debouncedPassportSeria, debouncedPassportNumber, debouncedPassportPin]
    });
    const _exam = useGetOneData({ url: `/exams/${exam_id}?expand=subjectName`, isCall: 'auto' });


    const _breadcrump = useMemo(() => {
        return [
            {
                name: "Home",
                path: "/",
            },
            {
                name: "Exams",
                path: "/exam_index",
            },
            {
                name: _exam?.data?.name,
                path: '',
            },
        ];
    }, [_exam]);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;

        if (value !== '_______' && value !== '__') {
            writeToUrl({ name, value });
        }
    }

    useEffect(() => {
        appeal_students.fetch()
    }, [i18n.language, value.perPage, value.currentPage, debouncedLastName, debouncedFirstName, debouncedPassportPin, debouncedPassportSeria, debouncedPassportNumber])


    const updateForAct = async (exam_student_id: number, act: number | string) => {
        try {
            const formdata = new FormData();
            formdata.append("act", Number(act) === 1 ? '0' : '1');
            const response = await instance({ url: `/exam-students/${exam_student_id}`, method: "PUT", data: formdata });
            if (response.data?.status === 1) {
                asyncN("success", 'create', response.data?.message).then(() => { setRefetch(!refetch) })
            } else {
                asyncN("error", 'update', response.data?.message);
            }
        } catch (error) {

        }
    }

    return (
        <div>
            <Collapse
                bordered={false}
                activeKey={activeKey}
                className="site-collapse-custom-collapse"
            >
                <Panel header={
                    <div className="w-100 d-f justify-content-between" onClick={e => e.stopPropagation()}>
                        <CustomBreadcrumb arr={_breadcrump} />
                        <Button type="link" className="text-primary fs-13" onClick={() => { setActiveKey(activeKey === '1' ? '' : '1') }} >Imtihon (batafsil) &nbsp;{activeKey === '1' ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}</Button>
                    </div>
                } key="1" showArrow={false} className="site-collapse-custom-panel ">
                    <Table id="tech-companies-1" className="table mt-2" style={{ border: '' }}>
                        <Thead>
                            <Tr>
                                <Th className="info_text_key text-center">{t('Exam')}</Th>
                                <Th className="info_text_key text-center">{t('Subject')}</Th>
                                <Th className="info_text_key text-center">{t('Ball')}</Th>
                                <Th className="info_text_key text-center">{t('Start time')}</Th>
                                <Th className="info_text_key text-center">{t('Finish time')}</Th>
                                <Th className="info_text_key text-center">{t('Duration')}</Th>
                                <Th className="info_text_key text-center">{t('Status')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td className="info_text_key text-center">{_exam?.data?.name}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.subjectName}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.ball}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.start}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.finish}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.duration ? moment.utc(Number(_exam?.data?.duration) * 1000).format("HH:mm:ss") : null}</Td>
                                <Td className="info_text_key text-center">{exam_status_list(_exam?.data?.status)}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Panel>
            </Collapse>
            <TableLineLoader loading={appeal_students.loading} />
            <Table id="tech-companies-1" className="table mt-2">
                <Thead>
                    <Tr>
                        <Th className="table_head_c" style={{ width: "3%" }}>№</Th>
                        <Th className="table_head_c">{t("Familiya")}</Th>
                        <Th className="table_head_c">{t("Ism")}</Th>
                        {/* <Th className="table_head_c">{t("Teacher")}</Th> */}
                        {/* <Th className="table_head_c">{t("Exam")}</Th> */}
                        <Th className="table_head_c">{t("Passport seria va raqam")}</Th>
                        <Th className="table_head_c">{t("SHRJ")}</Th>
                        <Th className="table_head_c">ON1/ON2/YN/All</Th>
                        <Th className="table_head_c text-center">{t("Chetlatish")}</Th>
                        <Th className="table_head_c">{t("Ball")}</Th>
                        <Th className="table_head_c">{t("Finish time")}</Th>
                        <Th className="table_head_c">{t("Duration")}</Th>
                        <Th className="table_head_c text-center">{t("Status")}</Th>
                        {isHasAuthRoute("exam-student_create") && isHasAuthRoute("exam-student_update") && isHasAuthRoute("exam-student_view") &&
                            <Th className="table_head_c text-center">{t("Actions")}</Th>
                        }
                    </Tr>
                </Thead>
                <Tbody>

                    <Tr className="bg-white" style={{ backgroundColor: "white" }}>
                        <Td></Td>
                        <Td className="table_head_c"><Input name="last_name" placeholder="Search by last name" value={value.filter_like?.last_name} onChange={handleSearch} allowClear /></Td>
                        <Td className="table_head_c"><Input name="first_name" placeholder="Search by first name" value={value.filter_like.first_name} onChange={handleSearch} allowClear /></Td>
                        {/* <Td className="table_head_c"></Td> */}
                        <Td className="table_head_c d-flex w-100" style={{ minWidth: "150px" }}>
                            <InputMask mask="aa" placeholder="AA" className='ant-input' name="passport_seria" value={value.filter_like.passport_seria} onChange={handleSearch} style={{ width: "50px", borderRight: "none" }} />
                            <InputMask mask="9999999" placeholder="9999999" className='ant-input' name="passport_number" value={value.filter_like.passport_number} onChange={handleSearch} style={{ width: "150px" }} />
                        </Td>
                        <Td className="table_head_c" style={{ maxWidth: "150px" }}><Input name="passport_pin" placeholder="Search by SHRJ" value={value.filter_like.passport_pin} onChange={handleSearch} allowClear /></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        {isHasAuthRoute("exam-student_create") && isHasAuthRoute("exam-student_update") && isHasAuthRoute("exam-student_view") &&
                            <Td></Td>
                        }

                    </Tr>
                    {appeal_students.items.length ? appeal_students.items.map((element, i: number) => (
                        <Tr key={i}>
                            <Td> {!appeal_students.loading ? value.currentPage * value.perPage - value.perPage + i + 1 : "-"}</Td>
                            <Td>{element?.student?.profile?.last_name}</Td>
                            <Td>{element?.student?.profile?.first_name}</Td>
                            {/* <Td>{element?.teacherAccess?.teacher?.last_name}&nbsp;{element?.teacherAccess?.teacher?.first_name}&nbsp;{element?.teacherAccess?.teacher?.middle_name} &nbsp; {encodeKey(String(element.student_id)) + '-' + encodeKey(String(element?.id)) + ' - javob'}</Td> */}
                            {/* <Td>{element?.exam?.name}</Td> */}
                            <Td>{element?.student?.profile?.passport_seria}&nbsp;{element?.student?.profile?.passport_number}</Td>
                            <Td>{element?.student?.profile?.passport_pin}</Td>
                            <Td>{element?.examStudent?.on1 ?? "_"} / {element?.examStudent?.on2 ?? "_"} / {element?.examStudent?.allBall ?? "_"} / <span style={{ color: "#1890FF" }} >{(Number(element?.examStudent?.allBall) ?? 0) + element?.examStudent?.on1 + element?.examStudent?.on2}</span></Td>
                            <Td className='text-center'>
                                {
                                    Number(element?.act) === 1 ?
                                        <Tag color="#FCE8E6" style={{ color: '#C5221F' }} className="min_w_120 cursor-pointer" onClick={() => updateForAct(element.id, element?.act)} >Chetlatilgan</Tag> :
                                        <Tag color="#E6F4EA" style={{ color: '#137333' }} className="min_w_120 cursor-pointer" onClick={() => updateForAct(element.id, element?.act)}  >Chetlatish</Tag>
                                }
                            </Td>
                            <Td className="text-center">{element?.examStudent?.ball}</Td>
                            <Td>{element?.finish ? moment.unix(element?.finish).format("YYYY-MM-DD HH:mm:ss") : <EmptySpaceLine />}</Td>
                            <Td>{element?.duration ? moment.utc(Number(element?.duration) * 1000).format("HH:mm") : <EmptySpaceLine />}</Td>
                            <Td className="text-center">{exam_student_status_list(element.status)}</Td>

                            {isHasAuthRoute("exam-student_create") && isHasAuthRoute("exam-student_update") && isHasAuthRoute("exam-student_view") &&
                                <Td>
                                    <div className="crud_actions_table">
                                        {isHasAuthRoute("exam-student_view") &&
                                            <Tooltip placement="top" title={t("View")}> <TbFileInvoice  className="edit_btn" onClick={() => { history.push(`/student_exam_detail/${element?.exam_student_id}`) }} /> </Tooltip>
                                        }
                                    </div>
                                </Td>
                            }
                        </Tr>
                    )) : null}
                </Tbody>
            </Table>
            <EmptyTable data={appeal_students.items} loading={appeal_students.loading} />
            {appeal_students.data?._meta.totalCount ? (
                <div className="table_bottom_footer">
                    <div>
                        <Tag color="#F2F2F2" className="text-dark">Jami {appeal_students.data?._meta.totalCount} ta</Tag>
                    </div>
                    <Pagination
                        defaultCurrent={value.currentPage}
                        total={appeal_students.data?._meta.totalCount}
                        defaultPageSize={value.perPage}
                        onChange={(e) => writeToUrl({ name: 'currentPage', value: e })}
                        onShowSizeChange={(e, pageSize) => writeToUrl({ name: 'perPage', value: pageSize })}
                        showSizeChanger
                        pageSizeOptions={["10", "15", "20", "30", "50", "100"]}
                    />
                </div>
            ) : null}
        </div>
    )
}


export default AppealStudents;



/**
@permissions 
exam-appeal-semeta_create,
exam-appeal-semeta_delete,
exam-appeal-semeta_index,
exam-appeal-semeta_update,
exam-appeal-semeta_view,
exam-appeal_create,
exam-appeal_delete,
exam-appeal_index,
exam-appeal_update,
exam-appeal_view,
 */