export const questionLevel: any = {
    1: "Oddiy",
    2: "O'rta",
    3: "Qiyin"
}


//  STATUS_INACTIVE = 0;
//  STATUS_ACTIVE = 1;
//  STATUS_TEACHER_EDITED = 2;
//  STATUS_MUDIR_REFUSED = 3;
//  STATUS_MUDIR_ACTIVE = 4;
//  STATUS_DEAN_REFUSED = 5;
//  STATUS_DEAN_ACTIVE = 6;
//  STATUS_EDU_ADMIN_REFUSED = 7;
//  STATUS_EDU_ADMIN_ACTIVE = 1;

export const question_mudir_statuses = {
    0: "Ko'rib chiqilmagan",
    3: "Bekor qilingan (Mudir)",
    4: "Qabul qilingan (Mudir)",
    5: "Bekor qilingan (Dekan)",
    6: "Tasdiqlangan (Dekan)",
    7: "Bekor qilingan (O'quv boshqarma)",
    1: "Tasdiqlangan (O'quv boshqarma)"
}
export const question_mudir_statuses_array: Array<{ value: number[], name: string }> = [
    {
        value: [0],
        name: "Ko'rib chiqilmagan"
    },
    {
        value: [4, 6],
        name: "Qabul qilingan"
    },
    {
        value: [3],
        name: "Bekor qilingan (mudir)"
    },
    {
        value: [1],
        name: "Tasdiqlangan"
    },
    {
        value: [7, 5],
        name: "Bekor qilingan"
    },
]

export const question_dean_statuses = {
    4: "Ko'rib chiqilmagan",
    5: "Bekor qilingan (Dekan)",
    6: "Qabul qilingan (Dekan)",
    7: "Bekor qilingan (O'quv boshqarma)",
    1: "Tasdiqlangan (O'quv boshqarma)"
}


export const question_dean_statuses_array: Array<{ value: number[], name: string }> = [
    {
        value: [4],
        name: "Ko'rib chiqilmagan"
    },
    {
        value: [6],
        name: "Qabul qilingan"
    },
    {
        value: [5],
        name: "Bekor qilingan (dekan)"
    },
    {
        value: [1],
        name: "Tasdiqlangan"
    },
    {
        value: [7],
        name: "Bekor qilingan"
    },
]

export const question_edu_admin_statuses = {
    6: "Ko'rib chiqilmagan",
    7: "Bekor qilingan",
    1: "Tasdiqlangan"
}


export const question_edu_admin_statuses_array: Array<{ value: number[], name: string }> = [
    {
        value: [6],
        name: "Ko'rib chiqilmagan"
    },
    {
        value: [1],
        name: "Tasdiqlangan"
    },
    {
        value: [7],
        name: "Bekor qilingan"
    },
]
export const question_teacher_statuses = {
    0: "Ko'rib chiqilmagan",
    3: "Bekor qilingan",
    1: "Tasdiqlangan"
}


export const question_teacher_statuses_array: Array<{ value: number[], name: string }> = [
    {
        value: [0],
        name: "Ko'rib chiqilmagan"
    },
    {
        value: [1],
        name: "Tasdiqlangan"
    },
    {
        value: [3, 5, 7],
        name: "Bekor qilingan"
    },
]

export const question_others_statuses = {
    0: "Faolmas",
    1: "Faol"
}



// locale permissions

export const locale_permissions: string[] = ['statistics_index', 'document_index']
export const question_others_statuses_array: Array<{ value: number[], name: string }> = [
    {
        value: [1],
        name: "Faol"
    },
    {
        value: [0],
        name: "Faolmas"
    },
]

// exam status list

/*
  STATUS_INACTIVE = 0;
  STATUS_ACTIVE = 1;
  STATUS_FINISHED = 2;
  STATUS_DISTRIBUTED = 3;
  STATUS_ANNOUNCED = 4;
 */

export const exam_by_roles_statuses_array: Array<{ value: number[], name: string }> = [
    {
        value: [1],
        name: "Faol"
    },
    {
        value: [0],
        name: "Faolmas"
    },
    {
        value: [2],
        name: "Imtihon yakunlangan"
    },
    {
        value: [3],
        name: "Tarqatilgan"
    },
    {
        value: [4],
        name: "E'lon qilingan"
    },
]
export const exam_others_statuses_array: Array<{ value: number[], name: string }> = [
    {
        value: [1],
        name: "Faol"
    },
    {
        value: [0],
        name: "Faolmas"
    },
]
