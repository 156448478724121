import { FC, useState } from "react";
// import examStds from './jsonformatter.json'
// import exams from './exams.json'
// import teacherAcc from './teacherAccessData.json'
import { Button, Col, Input, Row } from "antd";
// import { excelExport } from "utils/excelExport";
import { encodeWithStr } from "pages/exam_question_types/service/encoderDecoder";
const ExamStudentsIndex: FC = (): JSX.Element => {

    // console.log(examStds);

    const [key, setKey] = useState<{ key: string, code: string, result: string }>({ key: "", code: "-", result: "" })
    // const getAllData = () => {
    //     let respData: Array<any> = []
    //     if (Array.isArray(examStds)) {
    //         respData = examStds?.map((item: any) => {
    //             let exam = exams?.filter((exam: any) => exam?.id == item?.exam_id)[0]
    //             item['subject_id'] = exam?.subject?.id
    //             item['subject_name'] = exam?.subject?.name
    //             item['exam_name'] = exam?.name
    //             item['teacher_access_id'] = teacherAcc?.filter((tAcc: any) => tAcc?.language_id === item?.lang_id && tAcc?.subject?.id == exam?.subject?.id)[0]?.id
    //             // item['teacher_accesses'] = teacherAcc?.filter((tAcc: any) => tAcc?.language_id === item?.lang_id && tAcc?.subject?.id == exam?.subject?.id)[0]
    //             return item
    //         })?.filter((item: any) => [
    //             347,
    //             348,
    //             351,
    //             353,
    //             354,
    //             355,
    //             359,
    //             360,
    //             362,
    //             363,
    //             369,
    //             374,
    //             376,
    //             377,
    //             379,
    //             381,
    //             386,
    //             391,
    //             395,
    //             400,
    //             404,
    //             406,
    //             408,
    //             409,
    //             414,
    //             415,
    //             423,
    //             426,
    //             427,
    //             437
    //         ]?.includes(item?.exam_id) && item?.has_answer == 1 && item?.subject_id != null)

    //         console.log(respData);
    //         excelExport(respData, "data")

    //     }
    // }


    return (
        <div>
            <Row gutter={24}>
                <Col span={6}>
                    <Input onChange={(e) => { setKey({ ...key, key: e?.target?.value }) }} value={key?.key} placeholder="Raqamli kalitni kiriting!" />
                </Col>
                <Col span={2}>
                    <Input placeholder="Belgi!" onChange={(e) => { setKey({ ...key, code: e?.target?.value }) }} value={key?.code} />
                </Col>
                <Col span={2}><Button className="generate" onClick={() => {
                    setKey({
                        ...key,
                        result: encodeWithStr(key?.key, key?.code)
                    })
                }}>Generate</Button></Col>
                <Col span={6}><strong>Result: </strong>{key?.result}</Col>
            </Row>
        </div>
    )
}

export default ExamStudentsIndex