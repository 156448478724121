import { Menu, Dropdown } from 'antd';
import UzbImage from "../../../../../../assets/images/uzb-flag.png"
import RusImage from "../../../../../../assets/images/rus-flag.jpg"
import EnImage from "../../../../../../assets/images/en-flag.jpg"
import { useEffect, useState } from "react";
import i18n from '../../../../../../config/i18n';


const LanguageDropdown = (props: any) => {

  const langs = [
    {
      id: 1,
      title: "Uzbek",
      key: "uz",
      img: UzbImage
    },
    {
      id: 3,
      title: "Ruscha",
      key: "ru",
      img: RusImage
    },
    {
      id: 4,
      title: "Ingliz",
      key: "en",
      img: EnImage
    },
  ]

  const localLang = localStorage.getItem("i18lang") ? langs.find(i => i.key === localStorage.getItem("i18lang")) : langs[0]
  const [selectedItem, setselectedItem] = useState(localLang)
  const [open, setOpen] = useState<Array<string>>([''])

  const changeLang = (event: any) => {
    localStorage.setItem("i18lang", event.key)
    setselectedItem(event)
    i18n.changeLanguage(event.key)
  }

  // const menu = (
  //   <Menu>
  //     {
  //       langs.map((item) => (
  //         <Menu.Item key={item.id} onClick={() => changeLang(item)}>
  //           <img className="header_language_img" src={item?.img} alt="?" />&nbsp;<span>{item.title}</span>
  //         </Menu.Item>
  //       ))
  //     }
  //   </Menu>
  // );
  // return (
  //   <>
  //     <div>
  //       <div className="header_language p-0" >
  //         <img className="header_language_img" src={selectedItem?.img} alt="?" />&nbsp;
  //         <span style={{ color: props?.color }}>{selectedItem?.title}</span>
  //       </div>
  //     </div>
  //   </>
  // )

  return (
    <>
      <Menu
        className='forLang'
        mode='inline'
        openKeys={open}
        >
          <Menu.SubMenu
            onTitleClick={() => {
              if(open.includes('open')){
                  setOpen([''])
              } else {
                  setOpen(['open'])
              }
            }}
            title={<>
              <img className="header_language_img" src={selectedItem?.img} alt="?" />&nbsp;
              <span>{selectedItem?.title}</span>
            </>}
            key='open'
          >
            {langs.filter((item) => item.title !== selectedItem?.title).map((item) => (
              <Menu.Item key={item.id} onClick={() => {
                changeLang(item)
                setOpen([''])
              }}>
                <img className="header_language_img" src={item?.img} alt="?" />&nbsp;<span>{item.title}</span>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
      </Menu>
    </>
  )
}

export default LanguageDropdown