import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITimeTable } from "models/others";



type TypeInitialStateTimeTable = {
    visible: 'create' | 'update' | 'child_create' | null,
    selected: ITimeTable & { para_name: string | null, week_name: string | null } | null,
    parent: ITimeTable & { para_name: string | null, week_name: string | null } | null,
    element: {
        time_table_id?: null | number,
        week_id: number | null,
        week_name: string | null,
        para_id: number | null,
        para_name: string | null
    }
}

const initialState: TypeInitialStateTimeTable = {
    visible: null,
    selected: null,
    parent: null,
    element: {
        time_table_id: null,
        week_id: null,
        week_name: null,
        para_id: null,
        para_name: null
    }
}


const timeTableSlice = createSlice({
    name: 'timeTable',
    initialState: initialState,
    reducers: {
        openModal(state, action: PayloadAction<{ modal: TypeInitialStateTimeTable['visible'], selected: TypeInitialStateTimeTable['selected'] }>) {
            state.visible = action.payload.modal;
            state.selected = action.payload.selected;
        },
        closeModal(state) {
            state.visible = null;
            state.selected = initialState.selected
            state.parent = initialState.parent
        },
        childCreate(state, action: PayloadAction<{ parent: TypeInitialStateTimeTable['parent'] }>) {
            state.visible = 'child_create';
            state.parent = action.payload.parent;
        },
        create(state, action: PayloadAction<{ modal: TypeInitialStateTimeTable['visible'], element: TypeInitialStateTimeTable['element'] }>) {
            state.visible = action.payload.modal;
            state.element = action.payload.element;
        },
    }
})


export const TIMETABLE_ACTIONS = timeTableSlice.actions;


export default timeTableSlice;