import { FC } from "react";
import { Chart, Interval, Tooltip, Axis, Coordinate, Interaction } from 'bizcharts';


type PropDataType = {
    item: string,
    count: number,
    percent: number
}

const LabellineChart: FC<{ data: Array<PropDataType>, height?: number }> = ({ data, height }): JSX.Element => {


    return (
        <div>
            <Chart
                height={height ? height : 200}
                data={data}
                autoFit
                onGetG2Instance={(c: any) => {
                    c.geometries[0].elements.forEach((e: any, idx: number) => {
                        e.setState('selected', idx === 0 ? true : false);
                    })
                }}>
                <Coordinate type="theta" radius={0.75} />
                <Tooltip showTitle={false} />
                <Axis visible={false} />
                <Interval
                    position="percent"
                    adjust="stack"
                    color="item"
                    style={{
                        lineWidth: 1,
                        stroke: '#fff',
                    }}
                    label={['count', {
                        content: (data) => {
                            return `${data.item}: ${data.count}`;
                        },
                    }]}
                />
                <Interaction type='element-single-selected' />
            </Chart>
        </div>
    )
}



export default LabellineChart;