import instance from "config/_axios";
import { message } from "antd";





export const getOneMessage = async (id: number, path_prefix?: string) => {
    try {
        const response = await instance({ url: `/notifications/${id}${path_prefix ? "?" + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data;
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getMyAllNotifications = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/notifications/my${path_prefix ? "?" + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllNotifications = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/notifications${path_prefix ? "?" + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getNotificationStatusList = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/notifications${path_prefix ? "?" + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export const acceptMessage = async (param: { id?: number, path_prefix?: string, data?: any }) => {
    try {

        const { id, path_prefix, data } = param;

        const response = await instance({ url: `/notifications/${id}${path_prefix ? "?" + path_prefix : ''}`, method: 'POST', data })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


