import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Row, Col, Select, Button, Switch, Input, DatePicker, TimePicker } from 'antd';
import { FaTimes } from 'react-icons/fa';
import { asyncN } from 'utils/notifications';
import { getAllLanguages } from 'services'
import { useTranslation } from 'react-i18next';
import { getAllExamTypes } from '../../exam_types/service';
import { getAllEduSemesterSubjects } from '../../edu_plan/edu_semester_subject/service';
import { getAllEduPlans } from '../../edu_plan/service';
import moment from 'moment';
import { examStatusList } from '../exam_status_list';


type PropsType = {
    resetFields: () => void,
    clicked: boolean,
    type: 'create' | 'update',
    form: any,
    subject_id?: number
}
type eduSemesterSubjectsGroupByPlanType = {
    id: number,
    name: string,
    semestr_id: number,
    semestr: string,
    plan_id: number,
    plan_name: string
}
const { Option } = Select;
const EduPlanUi: FC<PropsType> = ({ resetFields, clicked, type, form, subject_id }): JSX.Element => {

    const history = useHistory();
    const { i18n, t } = useTranslation();
    const [languages, setLanguages] = useState<Array<any>>([]);
    const [names, setNames] = useState<Array<string>>(['uz'])
    const [exam_types, setExamTypes] = useState<Array<any>>([]);
    const [plans, setPlans] = useState<Array<{ id: number, name: string, eduSemestrs: any }>>([])
    const [semestrs, setSemestrs] = useState<Array<{ id: number, name: string }>>([])
    const [subjects, setSubjects] = useState<Array<eduSemesterSubjectsGroupByPlanType>>([])
    const [eduSemesterSubjectsGroupByPlan, setEduSemesterSubjectsGroupByPlan] = useState<Array<eduSemesterSubjectsGroupByPlanType>>([]);

    const errorFunc = (error: any) => {
        asyncN('error', 'read', error?.message)
    }

    useEffect(() => {
        (
            async () => {
                const response: any = await getAllLanguages();
                setLanguages(response?.items)
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                try {
                    const getExamTypes = await getAllExamTypes();
                    setExamTypes(getExamTypes?.items)
                    getSemestrSubjects()
                } catch (error) {
                    errorFunc(error)
                }
            }
        )()
    }, [])

    useEffect(() => {
        if (subject_id) {
            eduSemesterSubjectsGroupByPlan?.map((item: eduSemesterSubjectsGroupByPlanType) => {
                if (subject_id === item?.id) {
                    form.setFieldsValue({
                        edu_semestr_id: item?.semestr_id,
                        edu_plan_id: item.plan_id
                    })
                    getContens("semestr", item?.plan_id)
                    getContens("subject", item?.semestr_id)
                }
            })
        }
    }, [subject_id, eduSemesterSubjectsGroupByPlan])


    const getContens = (type: "semestr" | "subject", id: number) => {
        if (type === "semestr") {
            setSemestrs(plans?.filter((item: { id: number, name: string, eduSemestrs: any }) => item?.id === id)[0]?.eduSemestrs);
        } else {
            setSubjects(eduSemesterSubjectsGroupByPlan.filter((item: eduSemesterSubjectsGroupByPlanType) => item?.semestr_id === id));
        }
    }

    const getSemestrSubjects = async () => {
        const eduPlans = await getAllEduPlans('', '', 'expand=eduSemestrs');
        const eduSemesterSubjects = await getAllEduSemesterSubjects('', '', "expand=subject");
        if (Array.isArray(eduPlans?.items) && eduPlans?.items?.length > 0) {
            setPlans(eduPlans?.items);
            // setSemestrs(eduPlans?.items?.eduSemestrs);
            let subjects: Array<any> = [];
            eduPlans?.items?.map(async (plan: { id: number, name: string, eduSemestrs: Array<{ id: number, name: string }> }) => {
                if (Array.isArray(plan?.eduSemestrs) && plan?.eduSemestrs.length > 0) {
                    plan?.eduSemestrs?.map(async (semestr: { id: number, name: string }) => {
                        if (Array?.isArray(eduSemesterSubjects?.items) && eduSemesterSubjects?.items?.length > 0) {

                            eduSemesterSubjects?.items?.map(async (semestrSubject: {
                                id: number,
                                subject: { id: number, name: string },
                                edu_semestr_id: number
                            }) => {
                                if (semestr?.id === semestrSubject?.edu_semestr_id) {
                                    subjects.push({
                                        id: semestrSubject?.id,
                                        name: semestrSubject?.subject?.name,
                                        semestr_id: semestr?.id,
                                        semestr: semestr?.name,
                                        plan_id: plan?.id,
                                        plan_name: plan?.name
                                    })

                                }
                            })
                        }
                    })
                }
            })
            setEduSemesterSubjectsGroupByPlan(subjects);
        }
    }

    const onAddNewForm = () => {
        const langId = languages.filter(e => !names.includes(e.lang_code))
        if (langId.length) {
            setNames(prevState => ([...prevState, langId[0].lang_code]))
        }
    }


    const removeInput = (id: string) => {
        const newNames = names.filter(e => e !== id);
        setNames(newNames)
    }

    const selectLanguage = (newValue: string, oldValue: string) => {
        const index = names.indexOf(oldValue);

        if (!names.includes(newValue)) {
            names.splice(index, 1, newValue)
            setNames([...names]);
        } else {
            return
        }
    }

    function disabledDate(current: any) {
        if (form.getFieldValue("start_exam"))
            return current && current < form.getFieldValue("start_exam");
        else {
            let d = new Date();
            d.setDate(d.getDate() - 1)
            return current && current < moment(d).endOf('day');
        }
    }

    return (
        <>
            <Row gutter={[12, 0]}>
                {
                    type === "create" ? names.map((e: any) => {
                        return (
                            <React.Fragment key={e} >
                                <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                    <Form.Item
                                        label={`${t("Name")} (${e})`}
                                        name={`name[${e}]`}
                                        shouldUpdate
                                        rules={[{ required: true, message: `${t("Fill in the field")}(${e}) !` }]}
                                    >
                                        <Input placeholder={`${t("Enter exam name")} (${e}) ...`} />
                                    </Form.Item>

                                </Col>
                                <Col key={e} xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} className="form_ui_flex">
                                    <Form.Item
                                        label={`${t("Description")} (${e})`}
                                        name={`description[${e}]`}
                                        shouldUpdate
                                        rules={[{ required: false, message: `${t("Fill in the field")}(${e}) !` }]}
                                        className="form_ui_flex_form_item"
                                    >
                                        <Row wrap={false}>
                                        <Input placeholder={`${t("Enter description for exam")} (${e}) ...`} />
                                        <Select defaultValue={e} style={{ width: '100px' }} className="mx-2" onChange={(newElement) => selectLanguage(newElement, e)}>
                                            {
                                                languages.length && languages.map((l: any) => {
                                                    if (!names.includes(l.lang_code) || l.lang_code === e) {
                                                        return <Option key={l.id} value={l.lang_code}>{l.name}</Option>
                                                    }
                                                })
                                            }
                                        </Select>
                                        <Button className='d-flex justify-content-center align-items-center' shape="circle" onClick={() => removeInput(e)} disabled={names.length === 1} >
                                            <FaTimes />
                                        </Button>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </React.Fragment>
                        )
                    }) : null
                }
                {type === "create" ?
                    <Col lg={{ span: 24 }} className="mb-3">
                        <Button type="link" className="ps-0" onClick={onAddNewForm} >+ {t("Add translation")}</Button>
                    </Col> : null}
                {
                    type === "update" ? <>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={`Name (${i18n.language})`}
                                name={`name[${i18n.language}]`}
                                shouldUpdate
                                rules={[{ required: true, message: `${t("Fill in the field!")} (${i18n.language}) ...` }]}
                            >
                                <Input placeholder={t("Enter exam name") + " ..."} />
                            </Form.Item>
                        </Col>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={`Description (${i18n.language})`}
                                name={`description[${i18n.language}]`}
                                shouldUpdate
                                rules={[{ required: false }]}
                            >
                                <Input placeholder={t("Enter description for course") + " ..."} />
                            </Form.Item>
                        </Col>
                    </> : null
                }
                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Ball")}
                        name="max_ball"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Input type='number' allowClear min={1} placeholder={t("Enter ball")} className="w-100" />
                    </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Start time")}
                        name="start_exam"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <DatePicker
                            placeholder={t("Select date")}
                            // disabledDate={(current) => {
                            //     let customDate = moment().format("YYYY-MM-DD");
                            //     return current && current < moment(customDate, "YYYY-MM-DD");
                            // }}
                            showNow={false}
                            className="w-100"
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                        />
                    </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Duration")}
                        name="duration"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                        initialValue={moment("1970-01-01 02:00")}
                    >
                        <TimePicker
                            placeholder={t("Select date")}
                            showNow={false}
                            className="w-100"
                            format="HH:mm"
                        />
                    </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Finish time")}
                        name="finish_exam"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <DatePicker
                            placeholder={t("Select date")}
                            showNow={false}
                            className="w-100"
                            showTime
                            // disabledDate={(e) => {
                            //     return disabledDate(e)
                            // }}
                            format="YYYY-MM-DD HH:mm:ss"
                        />
                    </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Exam types")}
                        name="exam_type_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select
                            showSearch
                            placeholder={t(Array.isArray(exam_types) && exam_types.length ? t(`Select a exam type`) : t('Data is loading or empty, please wait'))}
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                Array.isArray(exam_types) && exam_types.length ? exam_types.map((e: any, i: number) => {
                                    return <Option key={i} value={e.id}>{e.name}</Option>
                                }) : null
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Edcation plan")}
                        name="edu_plan_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select
                            showSearch
                            placeholder={t(Array.isArray(plans) && plans.length ? `${t("Select a edu semestr subject")} ...` : t('Data is loading or empty, please wait'))}
                            optionFilterProp="children"
                            filterOption={(input, option: any) => {
                                return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            allowClear
                            onClear={() => {
                                form.setFieldsValue({
                                    edu_semestr_subject_id: null,
                                    edu_semestr_id: null
                                })
                                setSemestrs([])
                                setSubjects([])
                            }}
                            onChange={(e: number) => {
                                getContens("semestr", e);
                                form.setFieldsValue({
                                    edu_semestr_subject_id: null,
                                    edu_semestr_id: null
                                })
                            }}
                        >
                            {
                                plans?.map((e: {
                                    id: number,
                                    name: string
                                }, i: number) => {

                                    return <Option key={i} value={e?.id}>{e?.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Edcation semestr")}
                        name="edu_semestr_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select
                            onChange={(e: number) => {
                                getContens("subject", e);
                                form.setFieldsValue({
                                    edu_semestr_subject_id: null
                                })
                            }}
                            showSearch
                            allowClear
                            onClear={() => {
                                form.setFieldsValue({
                                    edu_semestr_subject_id: null
                                })
                                setSubjects([])
                            }}
                            placeholder={t(Array.isArray(semestrs) && semestrs.length ? `${t("Select a semestr subject")} ...` : t('Data is loading or empty, please wait'))}
                            optionFilterProp="children"
                            filterOption={(input, option: any) => {
                                return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                        >
                            {
                                semestrs?.map((e: {
                                    id: number,
                                    name: string
                                }, i: number) => {

                                    return <Option key={i} value={e?.id}>{e?.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Subjects")}
                        name="edu_semestr_subject_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select
                            showSearch
                            placeholder={t(Array.isArray(subjects) && subjects.length ? `${t("Select a edu semestr subject")} ...` : t('Data is loading or empty, please wait'))}
                            optionFilterProp="children"
                            allowClear
                            filterOption={(input, option: any) => {
                                return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                        >
                            {
                                Array.isArray(subjects) && subjects.length ? subjects?.map((e: {
                                    id: number,
                                    name: string,
                                    semestr: string,
                                    plan_name: string
                                }, i: number) => {

                                    return <Option key={i} value={e?.id}>{e?.name}</Option>
                                })
                                    : null
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Status")}
                        name="status"
                        required
                    >
                        <Select
                            showSearch
                            placeholder={t("Select status")}
                            optionFilterProp="children"
                            allowClear
                            filterOption={(input, option: any) => {
                                return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                        >
                            {
                                type === 'create' ?
                                    examStatusList.slice(0, 2).map((e, i) => <Option key={i} value={e.value} >{e.name}</Option>) :
                                    examStatusList.map((e, i) => <Option key={i} value={e.value} >{e.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} lg={{ span: 6 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Status appeal")}
                        name="status_appeal"
                        required
                    >
                        <Select
                            showSearch
                            placeholder={t("Select status")}
                            optionFilterProp="children"
                            allowClear
                        >
                            {
                                type === 'create' ?
                                    examStatusList.slice(0, 2).map((e, i) => <Option key={i} value={e.value} >{e.name}</Option>) :
                                    examStatusList.map((e, i) => <Option key={i} value={e.value} >{e.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={{ span: 2 }} lg={{ span: 2 }} sm={{ span: 12 }} md={{ span: 6 }} xs={{ span: 12 }}>
                    <Form.Item
                        label={t("Is protected")}
                        name="is_protected"
                        valuePropName='checked'
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" />
                    </Form.Item>
                </Col>
            </Row>
            <hr />
            <Col span={24} className="mt-2 text-end">
                <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { history.goBack() }}>{t("Cancel")}</Button>
                <Button type="primary" htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
            </Col>
        </>
    )
}


export default EduPlanUi;