import { ILanguage } from "models/language";
import React from "react";
import asyncThunkDefaults from "services/asyncThunkDefaults";
import { useAppDispatch, useAppSelector } from "store/services";




const useLanguage = () => {

    const languages = useAppSelector(state => state.defaults.languages);
    const dispatch: any = useAppDispatch();

    React.useEffect(() => {
        if (!languages?.items.length) {
            dispatch(asyncThunkDefaults({ key: 'languages', url: '/languages' }));
        }
    }, [])

    const findLanguageById = React.useCallback((id: number): ILanguage | object => {

        if (languages?.items.length) {

            const element = languages.items.find(e => e.id === id);

            return element ?? {};
        }

        return {}

    }, [languages]);

    /**
     * function return object like this {uz: {...elements}, en: {...elements}, ...}
     */
    const languageAsObjectKeyLangCode = React.useMemo((): Record<string, ILanguage> => {

        let new_object: { [key: string]: ILanguage } = {};

        languages?.items.forEach(element => {

            new_object[element.lang_code] = element;

        });

        return new_object;

    }, [languages]);

    /**
     * function return object like this {1: {...elements}, 2: {...elements}, ...}
     */
    const languageAsObjectKeyId = React.useMemo((): Record<number, ILanguage> => {

        let new_object: { [key: string]: ILanguage } = {};

        languages?.items.forEach(element => {

            new_object[element.id] = element;

        });

        return new_object;

    }, [languages]);

    return { languages: languages ?? {
        items: [],
        loading: false,
        status: 'success'
    }, findLanguageById, languageAsObjectKeyLangCode, languageAsObjectKeyId }

}

export default useLanguage;
