import { FC, useCallback, useEffect, useState } from "react";
import { Row, Col, Typography, Button, Tag, Card, Divider, message, Collapse, Alert } from "antd";
import { useHistory, useRouteMatch, match } from 'react-router';
import moment from "moment";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";
import useGetOneData from "hooks/useGetOneData";
import { getAllLanguages } from "services";
import { exam_status_list } from "../exam_status_list";
import PageContentTitle from "components/Title";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import instance from "config/_axios";
import { BiChevronDown, BiChevronUp, BiCopy, BiEditAlt, BiLinkExternal, BiPlus, BiRefresh, BiRightArrowAlt } from "react-icons/bi";
import isHasAuthRoute from "utils/isHasRoute";
import useGetDistributedExamsToTeacher from "../hooks/useGetDistributedExamsToTeacher";
import { FaEdit } from "react-icons/fa";
import { TbFileInvoice } from "react-icons/tb";
import { EmptySpaceLine } from "components/SimpleComponents";
import { CaretRightOutlined } from '@ant-design/icons';
import { Link, NavLink } from "react-router-dom";
import useGetAllData from "hooks/useGetAllData";
import isHasRoute from "utils/isHasRoute";
import { useAppSelector } from "store/services";
import useLanguage from "hooks/useLanguage";
import { ILanguage } from "models/language";
import ForTable from "components/ForTable";

const { Panel } = Collapse;
const { Text } = Typography;


const totalCount = (arr: Array<{ count: string, lang_id: string }> = []) => {
    const _sum = arr.reduce((previousValue, currentValue) => previousValue + Number(currentValue.count), 0)
    return _sum;
}


function ExamView(): JSX.Element {

    const { t } = useTranslation();
    const match: match<any> | null = useRouteMatch("/exam_view/:id");
    const history = useHistory();
    const auth: any = useAppSelector(state => state.auth);
    const [refetch, setReFetch] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState<string>('');
    const [examData, setExamData] = useState<{ id: number | undefined, password: any }>({ id: undefined, password: '' });;
    const [showingType, setShowingType] = useState<'exam' | 'appeal' | null>(null);

    const [languages, setLanguages] = useState<ILanguage[]>([]);
    // const { languages } = useLanguage()
    const { data, loading } = useGetOneData({ url: `/exams/${match?.params?.id}?expand=oldExam,relExam,subject,examStudentByLang,examAppealByLang,statusName,key,isConfirmed,isConfirmedAppeal,eduPlan,questionCount,createdBy,updatedBy`, isCall: 'auto', refetch: [refetch, match?.params?.id] });
    const _distributedExams = useGetDistributedExamsToTeacher({ ids: { exam_id: data?.id, subject_id: data?.subject?.id } });
    const _questionTypes = useGetAllData({ url: '/question-types', isCall: 'auto' });

    useEffect(() => {
        (
            async () => {
                const response: any = await getAllLanguages();
                setLanguages(response?.items);
            }
        )()
    }, [])



    const sortingLang = useCallback((exams: Array<{ count: string, lang_id: string }>): any => {
        const exam_count_by_lang: { [key: string | number]: number | string | undefined } = {};
        if (Array.isArray(languages) && languages.length && Array.isArray(exams) && exams.length) {
            languages.forEach((element) => {
                const find_lang = exams.find(e => Number(e.lang_id) === element.id);
                exam_count_by_lang[element.lang_code] = find_lang?.count;
            })
        }
        return exam_count_by_lang;
    }, [languages])

    const _navigate = (type: string) => {
        const exam_id = match?.params?.id;
        const subject_id = data?.subject?.id;
        if (exam_id && subject_id) {
            history.push(`/exams_for_checking/${subject_id}/exam_distribution/${match?.params?.id}/${type}`)
        } else {
            message.warning("Ma'lumot topilmadi !");
        }
    }


    const confirmDistribution = async (type: 'exam' | 'appeal') => {
        if (data?.id) {
            const response = await instance({ url: `/exams/${data?.id}/${type === 'exam' ? 'distribution' : 'appeal-distribution'}`, method: "GET" });
            if (response.data?.status === 1) {
                asyncN("success", 'create', response.data?.message).then(() => { setReFetch(!refetch) })
                _distributedExams._fetch(type);
            } else {
                asyncN("error", 'update', response.data?.message).then(() => setReFetch(!refetch))
            }
        } else {
            message.warning("Ma'lumot yetarli emas !");
        }
    }

    const getQuestionCountByLanguage = useCallback((_jsonData: string) => {

        if (_questionTypes.items.length && _jsonData) {

            const generatObject: { items: Array<{ name: string, count: string | number, ball: string | number }>, totalCount: number } = { items: [], totalCount: 0 };
            const questionsByLang: any = JSON.parse(_jsonData);

            let sum = 0

            _questionTypes.items.forEach((element) => {
                if (typeof questionsByLang === 'object' && questionsByLang.hasOwnProperty(element.id)) {
                    generatObject.items.push({
                        name: element?.name,
                        count: questionsByLang[element?.id]?.count,
                        ball: questionsByLang[element?.id]?.ball
                    })
                    sum = sum + Number(questionsByLang[element?.id].count)
                }
                generatObject.totalCount = sum;
            })

            return generatObject;

        }
    }, [_questionTypes])


    const generateKeys = async (exam_id: number) => {

        try {
            const formdata = new FormData()
            formdata.append("exam_id", String(exam_id))
            const response = await instance({ url: "/exams/generate-passwords", method: 'POST', data: formdata })
            if (response?.data?.status === 1) {
                asyncN("success", "create", response?.data?.message)
                setReFetch(!refetch);
            }
        } catch (e: any) {
            // asyncN("error", "create", e?.response?.data?.errors[0]);
        }
    }

    const copyPassword = (name: string, password: string) => {
        if (name && password) {
            message.success("Password copied !");
            navigator.clipboard.writeText(`Imtihon: ${name}. parol: ${password}`)
        }
    }



    return (
        <>
            <Row gutter={[12, 12]}>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <PageContentTitle title={data?.name} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className="text-end">
                    {
                        isHasAuthRoute("exam_update") ?
                            <>
                                {
                                    Number(data?.category) === 2 ?
                                        <>
                                            <NavLink to={`/exam_view/${data?.old_exam_id}`}><Button type="link" className="mt-3" icon={<BiLinkExternal className="me-2" />}>{t("Exam")}</Button></NavLink>&nbsp;&nbsp;
                                            <NavLink to={`/exam_intensiv/${data?.id}`}  ><Button type="primary" className="mt-3" icon={data?.old_exam_id ? <FaEdit className="me-2" /> : <BiPlus className="me-2" />}>{t("Edit")} </Button></NavLink>
                                        </> :
                                        <>
                                            <NavLink to={`/exam_view/${data?.relExam?.id}`}><Button type="link" className="mt-3" icon={<BiLinkExternal className="me-2" />}>{t("Intensiv exam")}</Button></NavLink>&nbsp;&nbsp;
                                            <NavLink to={`/exam_intensiv/${data?.id}`} ><Button type="primary" className="mt-3" icon={data?.old_exam_id ? <FaEdit className="me-2" /> : <BiPlus className="me-2" />}>{"Intentsiv imtihon"} </Button></NavLink>&nbsp;&nbsp;
                                            <NavLink to={`/exam_update/${data?.id}`}><Button type="primary" className="mt-3" icon={<BiEditAlt className="me-2" />}>{t("Edit")} </Button></NavLink>
                                        </>
                                }
                            </> : null
                    }
                </Col>
            </Row>
            <Divider className="m-2"></Divider>
            <Row gutter={[12, 12]}>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Subject")} size="small">{data?.subject?.name || <EmptySpaceLine />}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Education plan")} size="small">{data?.eduPlan?.name || <EmptySpaceLine />}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Question count")} size="small">{data?.questionCount}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Start date")} size="small">{data?.start || <EmptySpaceLine />}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Finish date")} size="small">{data?.finish || <EmptySpaceLine />}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Duration")} size="small">{data?.duration ? moment.utc(Number(data?.duration) * 1000).format("HH:mm:ss") : <EmptySpaceLine />}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Ball")} size="small">{data?.max_ball || <EmptySpaceLine />}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Status")} size="small">{exam_status_list(data?.status)}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} >
                    <Card title={t("Password")} size="small">
                        {
                            isHasRoute("exam_get-passwords") ? 
                                data?.is_protected ? 
                                    data?.key ? 
                                    <>
                                        <div className="ant-col">
                                            <Button
                                                type="primary"
                                                size="small"
                                                style={{color: 'white'}}
                                                ghost
                                                onClick={() => { generateKeys(data.id); setExamData({ id: data?.id, password: data?.key }) }} >
                                                {data?.key} <BiRefresh size={16} className="ms-1" />
                                            </Button>
                                            <div className="ant-col">
                                                <Button type="primary" ghost size="small" style={{color: 'white'}} className="ms-2" onClick={() => copyPassword(data?.name, data?.key)} ><BiCopy /></Button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <Tag
                                        className="cursor-pointer"
                                        onClick={() => generateKeys(data.id)}>
                                        {t("password")} <BiRefresh />
                                    </Tag>
                                :
                                t("Imtihon parolsiz")
                            : 
                            t("Parolni ko`rishga ruhsat yo`q")
                        }
                    </Card>
                </Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Description")} size="small">{data?.description || 'Tavsif mavjud emas'}</Card></Col>
                <Col xl={6} lg={8} md={8} sm={12} xs={24} >
                    <Card title={t("Exam questions count")} size="small">
                    {
                            getQuestionCountByLanguage(data?.question_count_by_type_with_ball)?.items?.map((e) =>
                                <span key={e.name} className="info_text_key d-f justify-content-between fs-12"><span>{e.name}</span><span>{t('Count')}: {e.count}</span><span>{t('Ball')}: {e.ball}</span></span>) &&
                            <EmptySpaceLine />
                    }
                    </Card>
                </Col>
            </Row>
            <Divider></Divider>
            <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                activeKey={activeKey}

            >
                <Panel showArrow={false}
                    header={
                        <div className="d-flex justify-content-between w-100" onClick={e => e.stopPropagation()}>
                            <div className="d-flex">
                                <div className="ant-col"><Button type="primary" className="fs-14 me-3 px-5" disabled={!isHasAuthRoute("exam_update") || Number(data?.status) === 0 || data?.questionCount === 0} onClick={() => history.push(`/attach-questions/${data?.id}`)}>Savol biriktirish +</Button></div>
                                <div className="ant-col"><Button type="primary" className="fs-14 px-5" disabled={!isHasAuthRoute("exam_get-passwords") || data?.is_protected === 0} onClick={() => history.push(`/generate-and-get-secret-keys/${data?.id}`)} >Imtihon parollari</Button></div>
                            </div>
                            <div className="ant-col"><Button type="dashed" className="px-5 text-primary" onClick={() => { setActiveKey(activeKey === '1' ? '' : '1') }} >Batafsil &nbsp;{activeKey === '1' ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}</Button></div>
                        </div>
                    }
                    key="1"
                    className="site-collapse-custom-panel"
                >
                    <div className="bg-white p-2">
                        {data.createdBy ?
                            <Row gutter={[12, 12]} className="py-2 mt-2">
                                <Col span={24}><Text type="warning" className="fs-14">{t("Created By")}</Text></Col>
                                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("F.I.SH")} size="small">{data?.createdBy?.last_name}&nbsp;{data?.createdBy?.first_name}&nbsp;{data?.createdBy?.middle_name}</Card></Col>
                                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Username")} size="small">{data?.createdBy?.username || <EmptySpaceLine />}</Card></Col>
                                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Role")} size="small">{data?.createdBy?.role}</Card></Col>
                                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Created at")} size="small">{data?.created_at ? moment.unix(data?.created_at).format('MM-DD-YYYY HH:mm:ss') : <EmptySpaceLine />}</Card></Col>
                            </Row> : null}
                        {data.updatedBy ?
                            <Row gutter={[12, 12]} className="py-2 mt-2">
                                <Col span={24} ><Text type="warning" className="fs-14">{t("Updated By")}</Text></Col>
                                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("F.I.SH")} size="small">{data?.updatedBy?.last_name}&nbsp;{data?.updatedBy?.first_name}&nbsp;{data?.updatedBy?.middle_name}</Card></Col>
                                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Username")} size="small">{data?.updatedBy?.username}</Card></Col>
                                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Role")} size="small">{data?.updatedBy?.role}</Card></Col>
                                <Col xl={6} lg={8} md={8} sm={12} xs={24} ><Card title={t("Updated at")} size="small">{data?.created_at ? moment.unix(data?.updated_at).format('MM-DD-YYYY HH:mm:ss') : <EmptySpaceLine />}</Card></Col>
                            </Row> : null}
                    </div>
                </Panel>
            </Collapse>

            <div style={{ backgroundColor: "#fafafa" }} className="px-3 py-2 my-3" >
                <div className="px-2 py-3 my-3 card_shadow">
                    <Divider style={{ fontSize: "14px", color: "#8C8C8C" }} orientation="left">Imtihon bo'yicha topshirilgan ishlar</Divider>
                    <ForTable loading={loading} totalCount={1} showAllData={true} datas={[data]} theads={[
                        <>{t('Total')}</>,
                        <>Uzbek</>,
                        <>Russian</>,
                        <>English</>,
                        !auth?.user?.role?.includes("teacher") && isHasAuthRoute("exam-student_index") ? <>{t('Students')}</> : null,
                        <>Taqsimot</>
                    ]} tbodys={function(this: any){
                        return [
                            <>{totalCount(this?.examStudentByLang)}</>,
                            < >{sortingLang(this?.examStudentByLang)?.uz || 0}</>,
                            < >{sortingLang(this?.examStudentByLang)?.ru || 0}</>,
                            < >{sortingLang(this?.examStudentByLang)?.en || 0}</>,
                            !auth?.user?.role?.includes("teacher") && isHasAuthRoute("exam-student_index") ?
                            <>
                                <Link to={`/student-exam-index/${data?.id}`}>{t('View')}<BiRightArrowAlt className="ms-1" /></Link>
                            </> : null,
                            <div className="d-flex">
                                {
                                    data?.isConfirmed === 1 ?
                                    <div className="ant-col"><Button type="primary" size="small" loading={_distributedExams.loading} onClick={() => { _distributedExams._fetch('exam'); setShowingType('exam') }}>Taqsimlanganlar</Button></div>
                                        :
                                    <Tag color="warning">Taqsimlanmagan</Tag>
                                }
                                {
                                    isHasAuthRoute("exam-semeta_create") && data?.examStudentByLang?.length ? 
                                        <div className="ant-col"><Button type="primary" size="small" className="fs-14 px-3 ms-2" onClick={() => _navigate('exam')}>Taqsimlash <BiRightArrowAlt className="ms-2" size={18} /></Button> </div>
                                    : null
                                }
                            </div>
                        ]
                    }}/>
                </div>
                {
                    showingType === 'exam' ? <div className="px-2 pt-1 pb-3 card_shadow">
                        <Divider style={{ fontSize: "14px", color: "#8C8C8C" }} orientation="left">O'qituvchilar uchun taqsimlangan ishlar</Divider>
                        {_distributedExams?.isConfirmed ? <Alert message="Imtihonlar tarqatilgan !" banner className="mb-2" /> : null}
                        <ForTable showAllData={true} loading={_distributedExams.loading} totalCount={Object.values(_distributedExams.distributedExams).length} datas={Object.values(_distributedExams.distributedExams)} theads={[
                            <>{t('Teacher')}</>,
                            <>Uzbek ({sortingLang(data?.examStudentByLang)?.uz || 0})</>,
                            <>Russian ({sortingLang(data?.examStudentByLang)?.ru || 0})</>,
                            <>English ({sortingLang(data?.examStudentByLang)?.en || 0})</>,
                            <>{t('Total')}({totalCount(data?.examStudentByLang)}) </>
                        ]} tbodys={function(this: any){
                            return [
                                <>{this.full_name}</>,
                                <>{this?.countsByLang?.uz || 0}</>,
                                <>{this?.countsByLang?.ru || 0}</>,
                                <>{this?.countsByLang?.en || 0}</>,
                                <>{Number(this?.countsByLang?.uz || 0) + Number(this?.countsByLang?.ru || 0) + Number(this?.countsByLang?.en || 0)}</>
                            ]
                        }}/>
                        {
                            isHasAuthRoute("exam_distribution") ?
                                <div className="text-end ant-col">
                                    <Button type="primary" disabled={_distributedExams.isConfirmed} style={{ minWidth: '100px' }} onClick={() => confirmDistribution('exam')}>Tarqatish</Button>
                                </div> : null
                        }
                    </div> : null
                }
                <div className="px-2 py-3 my-3 card_shadow">
                    <Divider style={{ fontSize: "14px", color: "#8C8C8C" }} orientation="left">Imtihon bo'yicha apelatsiyalar ishlar</Divider>
                    <ForTable showAllData={true} loading={loading} totalCount={1} datas={[data]} theads={[
                        <>{t('Total')}</>,
                        <>Uzbek</>,
                        <>Russian</>,
                        <>English</>,
                        !auth?.user?.role?.includes("teacher") && isHasAuthRoute("exam-appeal_index") ? <>{t('Students')}</> : null,
                        <>Taqsimot</>
                    ]} tbodys={function(this: any){
                        return [
                            <>{totalCount(data?.examAppealByLang)}</>,
                            < >{sortingLang(data?.examAppealByLang)?.uz || 0}</>,
                            < >{sortingLang(data?.examAppealByLang)?.ru || 0}</>,
                            < >{sortingLang(data?.examAppealByLang)?.en || 0}</>,
                            !auth?.user?.role?.includes("teacher") && isHasAuthRoute("exam-appeal_index") ?
                                <>
                                    <Link to={`/exam-appeal_index/${data?.id}`}>{t('View')}<BiRightArrowAlt className="ms-1" /></Link>
                                </> : null,
                            <div className="d-flex">
                                {
                                    data?.isConfirmedAppeal === 1 ?
                                    <div className="ant-col"><Button type="primary" size="small" loading={_distributedExams.loading} onClick={() => { _distributedExams._fetch('appeal'); setShowingType('appeal') }}>Taqsimlanganlar</Button></div>
                                        :
                                    <Tag color="warning">Taqsimlanmagan</Tag>
                                }
                                {isHasAuthRoute("exam-semeta_create") && data?.examStudentByLang?.length ? <div className="ant-col"><Button type="primary" size="small" className="fs-14 px-3 ms-2" onClick={() => _navigate('appeal')}>Taqsimlash <BiRightArrowAlt className="ms-2" size={18} /></Button></div> : null}
                            </div>
                        ]
                    }}/>
                </div>
                {
                    showingType === 'appeal' ? <div className="px-2 pt-1 pb-3 card_shadow">
                        <Divider style={{ fontSize: "14px", color: "#8C8C8C" }} orientation="left">O'qituvchilar uchun taqsimlangan ishlar</Divider>
                        {_distributedExams.isConfirmed ? <Alert message="Imtihonlar tarqatilgan !" banner className="mb-2" /> : null}
                        <ForTable showAllData={true} loading={_distributedExams.loading} totalCount={Object.values(_distributedExams.distributedExams).length} datas={Object.values(_distributedExams.distributedExams)} theads={[
                            <>{t('Teacher')}</>,
                            <>Uzbek ({sortingLang(data?.examAppealByLang)?.uz || 0})</>,
                            <>Russian ({sortingLang(data?.examAppealByLang)?.ru || 0})</>,
                            <>English ({sortingLang(data?.examAppealByLang)?.en || 0})</>,
                            <>{t('Total')}({totalCount(data?.examAppealByLang)}) </>
                        ]} tbodys={function(this: any){
                            return [
                                <>{this.full_name}</>,
                                <>{this?.countsByLang?.uz || 0}</>,
                                <>{this?.countsByLang?.ru || 0}</>,
                                <>{this?.countsByLang?.en || 0}</>,
                                <>{Number(this?.countsByLang?.uz || 0) + Number(this?.countsByLang?.ru || 0) + Number(this?.countsByLang?.en || 0)}</>
                            ]
                        }}/>
                        {
                            isHasAuthRoute("exam_appeal-distribution") ?
                                <div className="text-end ant-col">
                                    <Button type="primary" disabled={_distributedExams.isConfirmed} style={{ minWidth: '100px' }} onClick={() => confirmDistribution('appeal')}>Tarqatish</Button>
                                </div> : null
                        }
                    </div> : null
                }
            </div>
        </>
    )
}


export default ExamView;