import { FC, useRef } from "react";
import { useHistory, useRouteMatch, match } from "react-router";
import { Col, Form, message, Row, Spin } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import { asyncN } from "utils/notifications";
import { ServerErrors } from "utils/validationFunctions";
import { studentTabFields } from "./utils";
import { useAppSelector } from "store/services";
import React from "react";
import instance from "config/_axios";
import { Card, CardBody } from "reactstrap";
import UserMainInfo from "./froms/main_info";
import StudentNewUi from "./new_ui";
import CardContainer from "components/Widgets/CardContainer";

const _breadcrump = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Students",
    path: "student_index",
  },
  {
    name: "Student edit",
    path: "student_update",
  },
];

const NewEditStudent: FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const history = useHistory();
  const match: match<any> | null = useRouteMatch("/student_update/:student_id");
  const { info, activeTabKey } = useAppSelector((state) => state.student);

  if (!match) {
    history.goBack();
  }

  React.useMemo(() => {
    if (info) {
      form.setFieldsValue({
        phone_secondary: info?.profile?.phone_secondary || undefined,
        description: info?.description || undefined,
        diplom_seria: info?.diplom_seria || undefined,
        diplom_number: info?.diplom_number || undefined,
        is_contract: String(info?.is_contract) || undefined,
        course_id: info?.course_id || undefined,
        faculty_id: info?.faculty_id || undefined,
        direction_id: info?.direction_id || undefined,
        edu_year_id: info?.edu_year_id || undefined,
        edu_type_id: info?.edu_type_id || undefined,
        edu_plan_id: info?.edu_plan_id || undefined,
        edu_lang_id: info?.edu_lang_id || undefined,
        country_id: info?.profile?.country_id ? info?.profile?.country_id : 229,
        region_id: info?.profile?.region_id || undefined,
        area_id: info?.profile?.area_id || undefined,
        address: info?.profile?.address || undefined,
        edu_form_id: info?.edu_form_id || undefined,
        student_category_id: info?.student_category_id || undefined,
        category_of_cohabitant_id: info?.category_of_cohabitant_id || undefined,
        residence_status_id: info?.residence_status_id || undefined,
        social_category_id: info?.social_category_id || undefined,
        partners_count: info?.partners_count || undefined,
        live_location: info?.live_location || undefined,
        parent_phone: info?.parent_phone || undefined,
        res_person_phone: info?.res_person_phone || undefined,
        last_education: info?.last_education || undefined,
        email: info?.user?.email || undefined,
        tutor_id: info?.tutor_id,
        contract_number: info?.contract_number,
        contract_id: info?.contract_number,
      });
    }
  }, [info]);

  const onSubmit = async (values: any) => {
    try {
      const { phone_secondary, parent_phone, res_person_phone } = values;
      const isPhoneSecondary =
        phone_secondary && String(phone_secondary).includes("_");
      const isParentPhone = parent_phone && String(parent_phone).includes("_");
      const isResPersonPhone =
        res_person_phone && String(res_person_phone).includes("_");

      if (!isPhoneSecondary && !isParentPhone && !isResPersonPhone) {
        const formdata = new FormData();

        studentTabFields[Number(activeTabKey)].forEach((element) => {
          if (values[element]) {
            if (element === "diplom_file") {
              formdata.append(element, values[element]?.file?.originFileObj);
            } else {
              formdata.append(element, values[element]);
            }
          }
        });

        const response = await instance({
          url: `/students/${match && match.params?.student_id}`,
          method: "PUT",
          data: formdata,
        });

        if (response.data.status === 1) {
          asyncN("success", "create", response.data?.message);
        } else {
          asyncN("error", "update", response.data?.message);
        }
      } else {
        message.error(
          `${isPhoneSecondary ? "Qo'shimcha telefon raqam, " : ""}${
            isParentPhone ? "Ota onasi telefon raqam, " : ""
          }${
            isResPersonPhone ? "Ma'sul shaxs telefon raqam " : ""
          } ma'lumotida format xatoligi !`,
          5
        );
      }
    } catch (error: any) {
      console.log(error);

      if (error.request.status === 422) {
        form.setFields(ServerErrors(error?.response?.data?.errors[0]));
      }
      asyncN("error", "create", error?.response?.message);
    }
  };

  return (
    <>
      <div className="bg-light rounded rounded-3" style={{ padding: 12 }}>
        <Col span={24}>
          <Card style={{ position: "relative", zIndex: 100 }}>
            <CardBody className="p-2">
              <CustomBreadcrumb arr={_breadcrump} />
            </CardBody>
          </Card>
        </Col>
        <Spin spinning={!info}>
          <Row gutter={[12, 12]} className="mt_12">
            <Col xl={10} lg={24} md={24} sm={24} xs={24}>
              <UserMainInfo />
            </Col>
            <Col xl={14} lg={24} md={24} sm={24} xs={24}>
              <CardContainer>
                <StudentNewUi form={form} onSubmit={onSubmit} />
              </CardContainer>
            </Col>
          </Row>
        </Spin>
      </div>
      {/* <Card className='py-2 px-3 mb-2'>
                <CustomBreadcrumb arr={_breadcrump} />
            </Card>
            <Row gutter={[12, 12]} >
                <Col xl={10}>
                    <CardContainer>
                        <UserMainInfo />
                    </CardContainer>
                </Col>
                <Col xl={14}>
                    <StudentNewUi form={form} ref={ref} onSubmit={onSubmit} />
                </Col>
            </Row> */}
      {/* <MilitaryDocs /> */}
    </>
  );
};

export default NewEditStudent;
