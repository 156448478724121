import React from "react";
import { Button, Form, InputNumber, message, Select } from "antd";
import FormLabel from "components/FormLabel";
import instance from "config/_axios";
import { IExamStudents } from "models/exam";
import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { asyncN } from "utils/notifications";


const { Option } = Select;

const StudentsNotIncludedExams = [
    { id: 1, name: 'Sertifikat' },
    { id: 2, name: "Imkoniyati cheklangan" },
    { id: 3, name: "Yapon tili" },
]

interface IUpdateBallByTypeExamStudentProps {
    element: IExamStudents,
    setPopoverBall: React.Dispatch<SetStateAction<undefined | number>>,
    setRefetch: React.Dispatch<SetStateAction<boolean>>,
}

const UpdateBallByTypeExamStudent = React.memo(function ({ element, setPopoverBall, setRefetch }: IUpdateBallByTypeExamStudentProps): JSX.Element {

    const { t } = useTranslation();
    const [form] = Form.useForm()



    const onSubmit = async (values: any) => {
        try {
            const { type, ball } = values;
            if (Number(ball) && Number(ball) >= 0) {
                if (element) {
                    const formdata = new FormData();
                    formdata.append("type", String(type));
                    formdata.append("ball", String(ball));
                    const response = await instance({ url: `/exam-students/${element.id}`, method: "PUT", data: formdata });
                    if (response.data?.status === 1) {
                        asyncN("success", 'create', response.data?.message).then(() => { setPopoverBall(undefined); setRefetch(prevState => !prevState) })
                    } else {
                        asyncN("error", 'update', response.data?.message);
                    }
                } else {
                    message.warning("Ma'lumot yetarli emas !");
                }
            } else {
                message.warning("Ball uchun bunday qiymat qabul qilinmaydi !")
            }
        } catch (error) {

        }
    }

    return (
        <Form
            form={form}
            layout='vertical'
            onFinish={onSubmit}
            className='exam_student_ball_custom w_200'
            initialValues={{ ball: element?.ball, type: element?.type }}
        >
            <Form.Item
                label={<FormLabel>Ball</FormLabel>}
                name="ball"
                className="my-0"
            >
                <InputNumber size="small" min={0} type={'number'} className="d-block w-100" />
            </Form.Item>
            <Form.Item
                label={<FormLabel>Category</FormLabel>}
                name="type"
                className="my-0"
            >
                <Select size="small" className='w-100'>
                    {
                        StudentsNotIncludedExams.map((element: { id: number, name: string }, index: number) => <Option key={index} value={element.id} className="fs-13">{element.name}</Option>)
                    }
                </Select>
            </Form.Item>
            <div className='mt-1 w-100 d-flex justify-content-between'>
                <Button size='small' danger className='fs-13' style={{ width: '48%' }} onClick={() => setPopoverBall(undefined)}>{t('Cancel')}</Button>&nbsp;
                <Button type="primary" size='small' className='fs-13' style={{ width: '48%' }} htmlType='submit' >{t('Save')}</Button>
            </div>
        </Form>
    )
})

export default UpdateBallByTypeExamStudent;