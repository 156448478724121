import { FC, memo } from "react";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";

const TotalCount: FC<{totalCount: number | null}> = ({totalCount}):JSX.Element => {
  const {t} = useTranslation();

  return(
    <div>
      {
        totalCount ? <Tag color="#F2F2F2" className="text-dark">{t("Total")}: &nbsp; {totalCount}</Tag> : null
      }
    </div>
  )
}

export default memo(TotalCount);