import { Button, Col, DatePicker, Divider, Form, message, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import MultipleInput from "components/MultipleInput";
import instance from "config/_axios";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { asyncN } from "utils/notifications";

const HolidayCreate: FC<{ refresh: boolean, visible: boolean, setRefresh: (refresh: boolean) => void, setVisible: (visible: boolean) => void }> = ({ refresh, visible, setRefresh, setVisible }): JSX.Element => {
  const [form] = useForm();
  const { t } = useTranslation();

  const onFinish = async (values: any) => {
    let formdata = new FormData();

    Object.entries(values)?.map(([key, value]: any) => {
      if (key === 'start_date' || key === 'finish_date') {
        formdata.append(key, moment(value).format('YYYY-MM-DD'))
      } else
        formdata.append(key, value)
    })

    try {
      const resp = await instance({
        url: `/holidays?expand=description`,
        method: "post",
        data: formdata
      })

      if (resp.data?.status === 1) {
        asyncN("success", 'create', resp.data?.message).then(() => { form.resetFields(); })
        setVisible(false);
        setRefresh(!refresh);
      } else {
        asyncN("error", 'update', resp.data?.message).then(() => form.resetFields())
      }
    } catch (err) {
      message.warning("Ma'lumot saqlashda xatolik !");
    }
  }

  return (
    <Modal title={t("Holiday create")} visible={visible} onCancel={() => setVisible(false)} footer={false} width={800}>
      <Form
        form={form}
        layout='vertical'
        className=""
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={24} className="mb-3" >
            <MultipleInput layout={'vertical'} />
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("Start date")}
              name={`start_date`}
              rules={[{
                required: true,
                message: t("fill in the feilds")
              }]}
            >
              <DatePicker className="w-100" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("End date")}
              name={`finish_date`}
              rules={[{
                required: true,
                message: t("fill in the feilds")
              }]}
            >
              <DatePicker className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="mt-1" />
        <Row className="justify-content-end">
          <Col>
            <Button type="primary" danger onClick={() => { setVisible(false); form.resetFields() }} >{t("Cancel")}</Button>
            <Button htmlType="submit" type="primary" className="ms-2" >{t("Save")}</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default HolidayCreate