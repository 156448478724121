import { Col, Select } from "antd";
import useGetAllData from "hooks/useGetAllData";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { FC } from "react";
import { cf_filterOption } from "utils/auxiliary_functions";
import isHasAuthRoute from "utils/isHasRoute";

type TypeFilterSelectComponent = {
  url: string,
  label: string,
  name: string,
  permission: string,
  value_name?: string,
  parent_name?: string,
  child_names?: string[],
  span?: { xs?: number, sm?: number, md?: number, lg?: number, xl?: number, xxl?: number },
}

const FilterSelectComponent: FC<TypeFilterSelectComponent> = ({ url, label, name, permission, parent_name, child_names, span, value_name }): JSX.Element => {
  const { value, writeToUrl } = useUrlQueryParams({});

  const { items, loading, fetch } = useGetAllData({ url: `${url.includes("?") ? url : url + "?sort=-id"}${parent_name ? `&filter=${JSON.stringify({ [`${parent_name}`]: value.filter[parent_name] })}` : ""}`, perPage: 0 })

  const handleChange = (value: number) => {
    writeToUrl({ name, value, items });
    child_names?.forEach(e => {
      writeToUrl({ name: e, value: '', items: [] });
    })
  }

  const handleClear = () => {
    writeToUrl({ name, value: '', items: [] });
    child_names?.forEach(e => {
      writeToUrl({ name: e, value: '', items: [] });
    })
  }

  const getSpan = () => {
    let initialSpan = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6 };
    if (span) {
      Object.entries(span)?.forEach(([key, value]) => {
        initialSpan = { ...initialSpan, [key]: value }
      })
    }
    return initialSpan
  }

  return (
    isHasAuthRoute(permission) ?
      <Col {...getSpan()} >
        <Select
          className="w-100"
          placeholder={`Filter by ${label}`}
          allowClear
          disabled={parent_name ? !value.filter[parent_name] : false}
          value={value.filter[name]}
          onFocus={() => {
            if (!parent_name && !items.length) fetch();
            if (parent_name) fetch();
          }}
          onChange={handleChange}
          onClear={handleClear}
          showSearch
          filterOption={cf_filterOption}
          loading={loading}
        >
          {
            items?.length ? items.map((element: any) => !value_name ?
              <Select.Option key={element.id} value={element.id}>{element?.name}</Select.Option>
              : <Select.Option key={element.id} value={element[`${value_name.toLowerCase()}_id`]}>{element[value_name]?.name}</Select.Option>
            ) : value.item[name] ? [value.item[name]].map((element) => <Select.Option key={element.id} value={element.id}>{element.name}</Select.Option>) : null
          }
        </Select>
      </Col>
      : <></>
  )
}

export default FilterSelectComponent;