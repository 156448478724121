import { ifError } from "assert"
import instance from "config/_axios"
import { asyncN } from "utils/notifications";

export const getAllDiplomaTypes = async (page?: number, perPage?: number, path_prefix?: string) => {
    try {
        const response = await instance({
            method: 'get',
            url: `/diploma-types?sort=-id&page=${page ? page : 0}&per-page=${perPage ? perPage : 0}${path_prefix ? '&' + path_prefix : ''}`
        })
        if (response?.data?.status === 1) {
            return response?.data?.data;
        } else {
            asyncN("error", "read", response?.data?.message);
        }
    } catch (error: any) {
        asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message);
    }
}