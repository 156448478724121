import { FC, useState } from 'react';
import { Form, Row, Col, Button, Switch, Modal, Input } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import { useTranslation } from 'react-i18next';
import MultipleInput from 'components/MultipleInput';
import ModalTitle from 'components/ModalTitle';
import { BiX } from 'react-icons/bi';
import FormLabel from 'components/FormLabel';



export type TypeSimpleCreateProps = {
    url: string,
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setReFetch: React.Dispatch<React.SetStateAction<boolean>>,
    title: string,
    customInputs?: Array<{
        name: string,
        title: string
    }> | undefined
}


const SimpleCreate: FC<TypeSimpleCreateProps> = ({ url, visible, setVisible, setReFetch, title, customInputs }): JSX.Element => {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [clicked, setClicked] = useState<boolean>(false);
    const [status, setStatus] = useState<boolean>(true);


    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            for (const [key, value] of Object.entries(values)) {
                
                if (key === 'status') {
                    formdata.append(key, status ? '1' : '0');
                } else {
                    formdata.append(key, String(value));
                }
            }

            const response = await instance({ url, method: 'POST', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => {
                        resetFields();
                        setVisible(false);
                        setReFetch(prevState => !prevState);
                    })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();

    return (
        <Modal
            title={title}
            visible={visible}
            onCancel={() => {setVisible(prevState => !prevState); resetFields()}}
            width={600}
            footer={null}
            closeIcon={<BiX size={20} />}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
            >
                <MultipleInput layout='vertical' />
                {
                    customInputs ? 
                    <Row>
                        {customInputs.map((e: any, index: number) => (<Col xl={{ span: 24 }} lg={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={<FormLabel>{`${t(e.title)}`}</FormLabel>}
                                name={e.name}
                                shouldUpdate
                                rules={[{ required: true, message: `Please input name...` }]}
                                className="mb-2"
                            >
                                <Input size="small" placeholder={`${t("Enter the name")}...`} className="ant_input_custom" />
                            </Form.Item>
                        </Col>))}
                    </Row>
                    : null
                }
                <hr />
                <Col span={24} className="mt-2">
                    <Row gutter={[12, 12]}>
                        <Col lg={{ span: 8 }}>
                            <Form.Item
                                name="status"
                                className='mb-0'
                            >
                                <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
                            </Form.Item>
                        </Col>
                        <Col lg={{ span: 16 }} className="text-end">
                            <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                            <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { setVisible(prevState => !prevState); resetFields() }}>{t("Cancel")}</Button>
                            <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </Modal>
    )
}




export default SimpleCreate;