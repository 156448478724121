import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Tag, Tooltip, Popconfirm, Pagination, Input, Row, Col, Select, Divider, Popover, message, Spin } from "antd";
import EmptyTable from "components/EmptyTable";
import IndexPageTitle from "components/IndexPageTitle";
import useDebounce from "hooks/useDebounce";
import useDeleteOne from "hooks/useDeleteOne";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { useTranslation } from "react-i18next";
import { BiCopy, BiRefresh } from "react-icons/bi";
import { FaPlus, FaCheckSquare, FaMinusSquare, FaFileExcel } from "react-icons/fa";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { useHistory, NavLink, Link } from "react-router-dom";
import { Table, Thead, Tr, Th, Tbody, Td } from "react-super-responsive-table";
import { getExamStatusesByRole } from "utils/auxiliary_functions";
import isHasAuthRoute from "utils/isHasRoute";
import { exam_status_list, exam_isComformed, examStatusList } from "./exam_status_list";
import { getAllLanguages } from "services";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import './styles.scss';
import { TableLineLoader } from "components/SimpleComponents";
import isHasRoute from "utils/isHasRoute";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { IExam } from "models/exam";
import * as XLSX from "xlsx";
import { excelExport } from "utils/excelExport";
import { ButtonCreate } from "components/Buttons";
import ForTable from "components/ForTable";
import { AiOutlineCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import ForTitle from "components/ForTitles";


const { Search } = Input;
const { Option } = Select


const Exam: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const history: any = useHistory();
    const [filterByField, setFilterByField] = useState<{ subject_id: number | undefined, lang_id: number | undefined }>({ subject_id: undefined, lang_id: undefined })
    const [refetch, setReFetch] = useState<boolean>(false);
    const [statuses, setStatuses] = useState<string>('');
    const [examData, setExamData] = useState<{ id: number | undefined, password: any }>({ id: undefined, password: '' });

    const { value, writeToUrl } = useUrlQueryParams({});


    const debouncedValue = useDebounce<string>(value.filter_like?.sr, 500);

    const _delete = useDeleteOne();
    const _subjects = useGetAllData({ url: '/subjects', perPage: 0 });
    const _questionTypes = useGetAllData({ url: '/question-types', isCall: 'auto' });
    const { data, loading } = useGetAllData({
        url: `/exams?sort=-id&expand=subjectName,eduPlan,statusName,questionCount,key,isConfirmed,isConfirmedAppeal${value.filter?.subject_id ? ("&subject_id=" + value.filter?.subject_id) : ''}${statuses ? '&statuses=' + String(statuses) : ''}${filterByField.lang_id ? ("&lang_id=" + filterByField.lang_id) : ''}${debouncedValue ? `&q=${debouncedValue}` : ''}`,
        isCall: 'auto',
        debouncedValue: [debouncedValue],
        perPage: value.perPage, currentPage: value.currentPage,
        refetch: [_delete.refetch, value.filter?.subject_id, refetch, statuses]
    })  as TypeReturnUseGetAllData<IExam>

    useEffect(() => {
        setReFetch(!refetch)
    }, [value.currentPage, value.perPage])

    const announceExamAnswer = async (id: number) => {
        const response = await instance({ url: `/exams/${id}/ad`, method: 'GET' });
        if (response.data.status === 1) {
            asyncN("success", 'update', response.data?.message).then(() => setReFetch(!refetch))
        } else {
            asyncN("error", 'update', response.data?.message)
        }
    }

    const getQuestionCountByLanguage = useCallback((_jsonData: string) => {

        if (_questionTypes.items.length && _jsonData) {

            const generatObject: { items: Array<{ name: string, count: string | number, ball: string | number }>, totalCount: number } = { items: [], totalCount: 0 };
            const questionsByLang: any = JSON.parse(_jsonData);

            let sum = 0

            _questionTypes.items.forEach((element) => {
                if (typeof questionsByLang === 'object' && questionsByLang.hasOwnProperty(element.id)) {
                    generatObject.items.push({
                        name: element?.name,
                        count: questionsByLang[element?.id]?.count,
                        ball: questionsByLang[element?.id]?.ball
                    })
                    sum = sum + Number(questionsByLang[element?.id].count)
                }
                generatObject.totalCount = sum;
            })

            return generatObject;

        }
    }, [_questionTypes])


    const generateKeys = async (exam_id: number) => {

        try {
            const formdata = new FormData()
            formdata.append("exam_id", String(exam_id))
            const response = await instance({ url: "/exams/generate-passwords", method: 'POST', data: formdata })
            if (response?.data?.status === 1) {
                asyncN("success", "create", response?.data?.message)
                setReFetch(!refetch);
            }
        } catch (e: any) {
            // asyncN("error", "create", e?.response?.data?.errors[0]);
        }
    }

    const copyPassword = (password: string) => {
        if (password) {
            message.success("Password copied !");
            navigator.clipboard.writeText(password)
        }
    }

    const loadExcel = async () => {
        let printData: Array<any> = []
        data.items.map((element: any) => {
            printData.push({
                "Id": element?.id,
                "Parol": element?.key,
                "Nomi": element?.name,
                "Fan nomi": element?.subjectName,
                "Ta'lim reja": element?.eduPlan?.name,
                "Boshlash": element?.start,
                "Tugatish": element?.finish,
                "Ball": element?.max_ball,
                "Holat": examStatusList?.filter((item: { name: string, value: number }) => item?.value == element?.status)[0]?.name,
                "Taqsimlanganligi": element?.isConfirmed == 1 ? "Taqsimlangan" : "",
                "E'lon qilinganligi": element?.status === 4 ? "E'lon qilingan" : "E'lon qilinmagan",
                "Savollar soni": element?.questionCount,
                "Imtihon savollar soni": getQuestionCountByLanguage(element?.question_count_by_type_with_ball)?.totalCount
            })
        })
        excelExport(printData, "Exams")
    }

    return (
        <div>
            <ForTitle title="All exams" buttons={[<Button className="excelga mx-1" onClick={loadExcel}>
                        <div>
                            <FaFileExcel />
                            Excel
                        </div>
                    </Button>, isHasAuthRoute("exam_create") && (
                        <NavLink to={"/exam_create"}>
                            <ButtonCreate></ButtonCreate>
                        </NavLink>
                    )]}/>
            <Row gutter={[12, 12]} className="mb-2">
                <Col xl={6}>
                    <Select
                        allowClear
                        onChange={(e: number) => writeToUrl({ name: 'subject_id', value: e, items: _subjects.items })}
                        onFocus={() => { _subjects.fetch() }}
                        showSearch
                        filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        className='w-100'
                        placeholder={t('Filter by subjects')}
                        loading={_subjects.loading}
                        value={value.filter?.subject_id}
                    >
                        {
                            _subjects.items.length ? _subjects.items.map((item: { id: number, name: string }, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>) :
                                [value.item?.subject_id].map((item, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>)
                        }
                    </Select>
                </Col>
                <Col xl={6}>
                    <Select
                        allowClear
                        onChange={(e: string) => setStatuses(e)}
                        className="w-100"
                        placeholder={t("Filter by statuses")}
                    >
                        {
                            getExamStatusesByRole().statuses_as_arr.length ?
                                getExamStatusesByRole().statuses_as_arr.map((element: any, index: number) => <Option key={index} value={JSON.stringify(element.value)}>{element.name}</Option>) : null
                        }
                    </Select>
                </Col>
                <Col className="d-flex" xl={{ span: 8, offset: 4 }}>
                    <Search
                        placeholder={`${t("Input search text")} ...`}
                        value={value.filter_like?.sr}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => { writeToUrl({ name: "sr", value: e?.target?.value }) }}
                        className="w-100"
                    />
                </Col>
            </Row>
            <ForTable toUrl={true} currentPage={value.currentPage} perPage={value.perPage} totalCount={data._meta.totalCount} setCurrentPage={() => {}} setPerPage={() => {}} loading={loading} datas={data.items} theads={
                    [
                        <>ID</>,
                        <>{t("Name")}</>,
                        <>{isHasRoute("exam_get-passwords") && t("Password")}</>,
                        <>{t("Subject name")}</>,
                        <>{t("Education plan")}</>,
                        <>{t("Vaqti")}</>,
                        <>{t("Status")}</>,
                        <>{isHasAuthRoute("exam_update") && `${t("Questions")} | Ball`}</>,
                        <>{t("Actions")}</>,
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            <>{this?.id}</>,
                            <>{isHasAuthRoute("exam_view") ? <NavLink to={`/exam_view/${this?.id}`}>{this?.name}</NavLink> : this?.name}</>,
                            isHasRoute("exam_get-passwords") ?
                                <div className="d-flex justify-content-center w-100">
                                    {Number(this?.is_protected) === 1 ?
                                        (this?.key ? 
                                        <Tooltip placement="top" title={t('Generatsiya uchun bosing')}>
                                            <Button type="primary" size="small" ghost onClick={() => { generateKeys(this.id); setExamData({ id: this?.id, password: this?.key }) }} ><div>{this?.key}</div></Button>
                                        </Tooltip>
                                        : <div className="ant-col">
                                            <Button type="primary" className="cursor-pointer" onClick={() => generateKeys(this.id)}>{t("Password")}</Button>
                                        </div>)
                                        : null}
                                    {Number(this?.is_protected) === 1 ?
                                        (this?.key ? <Button type="primary" ghost size="small" className="cursor-pointer ms-2" onClick={() => copyPassword(this?.key)} ><BiCopy /></Button> 
                                        : null)
                                    : null}
                                </div>
                            : null,
                            <>{this?.subjectName}</>,
                            <>{this?.eduPlan?.name}</>,
                            <>
                                <span style={{ whiteSpace: 'nowrap', color: '#4B4E52', fontSize: '13px' }} >{this?.start}</span><br />
                                {this?.finish}
                            </>,
                            <div className="text-center" style={{ verticalAlign: 'middle' }} >
                                <span className="d-f">{this?.isConfirmed === 1 ? <FaCheckSquare size={20} className="me-1" color="#137333" /> : <FaMinusSquare size={20} className="me-1" color="#D81D24" />}{exam_status_list(this?.status)} <br /></span>
                                {/* {exam_isComformed(this?.isConfirmed)} */}
                                <Divider className="my-1" style={{ backgroundColor: '#DA5903' }}></Divider>
                                <span className="d-f">{this?.isConfirmedAppeal === 1 ? <FaCheckSquare size={20} className="me-1" color="#137333" /> : <FaMinusSquare size={20} className="me-1" color="#D81D24" />}{exam_status_list(this?.status_appeal)} <br /></span>
                                {/* {exam_isComformed(this?.isConfirmed)} */}
                                {/* {exam_isComformed(this?.isConfirmedAppeal)} */}
                            </div >,
                            /* {isHasAuthRoute("exam_ad") ? <Td className="text-center">{<Button type="primary" size="small" danger={this?.status === 4} style={{ fontSize: '12px' }} onClick={() => announceExamAnswer(this?.id)} >{this?.status === 4 ? t("Cancel") : t("Announce")}</Button>}</Td> : null} */
                            isHasAuthRoute("exam_update") && 
                            <div className="text-center" style={{ verticalAlign: 'middle' }}> 
                            {
                                <Popover content={
                                    getQuestionCountByLanguage(this?.question_count_by_type_with_ball)?.items.length ?
                                        getQuestionCountByLanguage(this?.question_count_by_type_with_ball)?.items.map((e) => <span key={e.name} className="info_text_key d-f justify-content-between fs-12"><span>{e.name}</span><span>{e.count}</span><span>{e.ball}</span></span>) : null
                                    }
                                    title={<span className="info_text_value">Imtihon savol / ball</span>}
                                    trigger="hover"
                                >
                                    <Tag color='#E8F0FE' style={{ backgroundColor: 'unset' }} className="cursor-pointer px-3" >
                                        {
                                            (isHasAuthRoute("exam_update") && Number(this?.status) === 1) ?
                                            <Link to={`/attach-questions/${this?.id}`}>
                                                <strong style={{ whiteSpace: 'nowrap' }} >
                                                    {getQuestionCountByLanguage(this?.question_count_by_type_with_ball)?.totalCount ? `${getQuestionCountByLanguage(this?.question_count_by_type_with_ball)?.totalCount} /` : null} {this?.questionCount}&nbsp;&nbsp;|&nbsp;&nbsp;{this?.max_ball}
                                                </strong>
                                            </Link>
                                            : (<strong style={{ whiteSpace: 'nowrap' }} >
                                                {getQuestionCountByLanguage(this?.question_count_by_type_with_ball)?.totalCount ? `${getQuestionCountByLanguage(this?.question_count_by_type_with_ball)?.totalCount} /` : null} {this?.questionCount}&nbsp;&nbsp;|&nbsp;&nbsp;{this?.max_ball}
                                            </strong>)
                                        }
                                    </Tag>
                                </Popover>
                            }
                            </div>,
                            <div style={{ verticalAlign: 'middle' }} >
                                <div className="crud_actions_table">
                                    {isHasAuthRoute("exam_view") && (
                                        <Tooltip placement="top" title={t("View")}>
                                            <NavLink to={`/exam_view/${this?.id}`}><TbFileInvoice  className="view_btn" /></NavLink>
                                        </Tooltip>)}
                                    {isHasAuthRoute("exam_update") && (
                                        <Tooltip placement="top" title={t("Edit")}>
                                            <NavLink to={`exam_update/${this?.id}`}><TbFilePencil className="edit_btn" /></NavLink>
                                        </Tooltip>
                                    )}
                                    {isHasAuthRoute("exam_delete") && (
                                        <Tooltip placement="top" title={t("Delete")}>
                                            <Popconfirm
                                                placement="topLeft"
                                                title={`${t("Deleted it")} ?`}
                                                okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                                                onConfirm={() => _delete.fetch(`/exams/${this?.id}`)}
                                            >
                                                <TbFileOff className="delete_btn" />
                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                </div>
                                <Divider className="my-1" ></Divider>
                                {isHasAuthRoute("exam_ad") ? <div className="ant-col">
                                    <Button type="primary" size="small" className="w-100 fs-12" style={{ border: 'none', color: '#1967D2', fontWeight: 400 }} danger={this?.status === 4} onClick={() => announceExamAnswer(this?.id)} >{this?.status === 4 ? t("Cancel") : t("Announce")}</Button>
                                </div> : null}
                            </div>
                        ]
                    }
                }/>
        </div >
    )

};

export default Exam;
