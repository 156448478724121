import { Alert, Button, Col, DatePicker, Dropdown, Empty, Form, Input, Menu, Modal, Row, Select, Spin, Switch } from "antd";
import instance from "config/_axios";
import useGetAllData from "hooks/useGetAllData";
import moment from "moment";
import { userAccessType } from "pages/user/components/types";
import UserAccess from "pages/user/components/userAccess";
import { getUserAccessTypes } from "pages/user/servise";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { FC } from "react";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaCheck, FaEdit, FaPrint, FaUserCog } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/services";
import { asyncN } from "utils/notifications";
import { jobTitleType } from "./job_title/types";
import UserAccessUpdate from "./options/user_access_update";
import './style.scss'
import { childrenType, editUserAccessStateType, holidaysType, tableStoreType, tableType, userAccessType1, userType, vocationType } from "./type";
import { workRateType } from "./work_rate/types";
import * as XLSX from "xlsx";
import VocationsIndex from "./vocations";
import ReactToPrint from "react-to-print";
import { FiHelpCircle } from "react-icons/fi";

const { Option } = Select;

const EmployeeTabel: FC = (): JSX.Element => {
    const dataRef: any = useRef()
    let bodyRef: any = useRef()
    const auth: any = useAppSelector(state => state.auth);
    const locationPath: string = useLocation().pathname;
    const [form] = Form.useForm()
    const pathParts: Array<string> = locationPath?.split("/");
    const ids = pathParts?.length === 8 ? {
        access_type_id: Number(pathParts[2]),
        table_id: Number(pathParts[3]),
        tabel_store_id: Number(pathParts[4]),
        year: Number(pathParts[5]),
        month: Number(pathParts[6]),
        type: Number(pathParts[7])
    } : undefined
    const [quart, setQuart] = useState<{ currentYear: number, currentMonth: number, active: 1 | 2 }>({ currentYear: ids ? ids?.year : new Date().getFullYear(), currentMonth: ids ? ids?.month - 1 : new Date().getMonth(), active: ids ? ids?.type === 1 ? 1 : 2 : new Date().getDate() > 15 ? 2 : 1 });
    const [days, setDays] = useState<{ start: Array<number>, end: Array<number>, weekends: Array<number> }>({
        start: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        end: [],
        weekends: []
    })
    const [userAccessTypes, setUserAccessTypes] = useState<Array<userAccessType>>([]);
    const [tables, setTables] = useState<Array<tableType>>([]);
    const [currrentTable, setCurrentTable] = useState<tableType>();
    const [userAccesses, setUserAccesses] = useState<Array<userAccessType1>>([])
    const [filter, setFilter] = useState<any>(undefined);
    const [tableStore, setTableStore] = useState<tableStoreType>();
    const [refreshAll, setRefreshAll] = useState<boolean>(false)
    const [editUserAccess, setEditUserAccess] = useState<editUserAccessStateType>({ item: {}, visibleModal: false, edit: false })
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAccesses, setLoadingAccesses] = useState<boolean>(false);
    const [vocationsModal, setVocationsModal] = useState<{ user: userType | null, visible: boolean }>({ user: null, visible: false })
    const [vocations, setVocations] = useState<Array<vocationType>>([]);
    const [holidays, setHolidays] = useState<Array<holidaysType>>([])

    const pageStyle = `
        @page {
            size: 80mm 50mm;
        }

        @media all {
            .pagebreak {
                display: none;
            }
        }

        @media print {
            &{
                size:landscape;
            }
        }
    `;

    const _workRate = useGetAllData({
        url: `/work-rates`, isCall: "auto", refetch: [refreshAll]
    })

    const _jobTitle = useGetAllData({
        url: `/job-titles?filter=${filter ? JSON.stringify(filter) : '{}'}`, isCall: "auto", refetch: [filter?.table_id, refreshAll]
    })

    const getWorkRateOrJobTitle = (type: "workRate" | "jobTitle", id: number) => {
        if (id) {
            if (type === "workRate") {
                return _workRate?.items?.filter((item: workRateType) => item?.id === id)[0];
            } else {
                return _jobTitle?.items?.filter((item: jobTitleType) => item?.id === id)[0]?.name
            }
        } else {
            return ""
        }
    }


    const onFinish = async (values: any) => {
        let newValues: any = {}
        Object.entries(values)?.forEach(([key, value]: any) => {
            let s: Array<string> = key?.split("-");
            if (newValues[`${s[0]}`]) {
                newValues = { ...newValues, [`${s[0]}`]: { ...newValues[`${s[0]}`], [`${s[1]}`]: value } }
            } else {
                newValues = { ...newValues, [`${s[0]}`]: { [`${s[1]}`]: value } }
            }
        })
        let data = new FormData();
        data.append("user_access_type_id", `${ids?.access_type_id}`);
        data.append("table_id", `${ids?.table_id}`);
        data.append("data", JSON.stringify(newValues));
        data.append("year", `${quart.currentYear}`);
        data.append("month", `${quart.currentMonth + 1}`);
        data.append("type", `${quart.active}`);

        try {
            const resp = await instance({
                method: `${tableStore ? 'put' : 'post'}`,
                url: `/table-stores${tableStore ? '/' + ids?.tabel_store_id : ''}`,
                data: data
            })
            if (resp?.data?.status === 1) {
                setRefreshAll(!refreshAll);
                setEditUserAccess({
                    ...editUserAccess,
                    edit: false
                })
                asyncN("success", "update", resp?.data?.message)
            } else {
                asyncN("error", "update", resp?.data?.message)
            }
        } catch (error: any) {
            asyncN("error", "update", error?.response ? error?.response?.data?.message : error?.message)
        }


    }

    useEffect(() => {
        (
            async () => {
                if (ids?.tabel_store_id) {
                    try {
                        setLoading(true)
                        const resp = await instance({
                            url: `/table-stores?month=${quart.currentMonth + 1}&year=${quart.currentYear}&filter={"user_access_type_id":${ids?.access_type_id},"table_id":${ids?.table_id},"type":${quart.active}}&expand=userAccess`,
                            method: 'get'
                        })
                        if (resp?.data?.status === 1) {
                            setLoading(false);
                            setHolidays(resp?.data?.data?.holidays)
                            setTableStore(resp?.data?.data?.data?.items[0])
                            setVocations(resp?.data?.data?.vocations)
                        } else {
                            setLoading(false)
                            asyncN("error", "read", resp?.data?.message)
                        }
                    } catch (error: any) {
                        setLoading(false)
                        asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message)
                    }
                }
            }
        )()
    }, [ids?.access_type_id, ids?.table_id, quart.active, quart?.currentMonth, quart?.currentYear, refreshAll])

    const setStateUserAccessType = async (id: number) => {
        let table: any = tables?.filter(
            (item: { id: number }) => item?.id === id
        )[0]
        setUserAccesses(
            table?.userAccess
        );
        setCurrentTable(table)
    }

    useEffect(() => {
        (
            async () => {
                setLoading(true)
                const response: any = await getUserAccessTypes();
                setUserAccessTypes(response?.items)
                setLoading(false)
            }
        )()
    }, [])

    useEffect(() => {
        if (ids && userAccessTypes?.length > 0) {
            getAccess(ids?.access_type_id)
        }
    }, [userAccessTypes, refreshAll])

    useEffect(() => {
        if (ids && tables?.length > 0) {
            setStateUserAccessType(ids?.table_id)
        }
    }, [tables, refreshAll])

    const getAccess = async (id: number) => {
        if (id) {
            try {
                setLoadingAccesses(true)
                let url = userAccessTypes?.filter((item: { id: number, url: string }) => item?.id === id)[0]?.url;
                const resp = await instance({
                    method: 'get',
                    url: `/${url}?per-page=0&expand=parent,children.userAccess.user.profile,userAccess,userAccess.user.profile,userAccess.workRate,userAccess.jobTitle`
                })
                if (resp?.data?.status === 1) {
                    setTables(resp?.data?.data?.items)
                    setLoading(false)
                    setLoadingAccesses(false)
                } else {
                    setLoading(false)
                    asyncN("error", "read", resp?.data?.message);
                    setLoadingAccesses(false)
                }
            } catch (error: any) {
                setLoadingAccesses(false)
                asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message);
            }
        }
    }

    useEffect(() => {
        let d = new Date();
        let getTot = new Date(d.getFullYear(), d.getMonth(), 0).getDate(); //Get total days in a month
        let sat: Array<number> = [];
        let sun: Array<number> = [];

        for (let i = 1; i <= getTot; i++) {
            let newDate = new Date(d.getFullYear(), d.getMonth(), i)
            if (newDate.getDay() == 0) {
                sun.push(i);
            }
            if (newDate.getDay() == 6) {
                sat.push(i);
            }

        }
        setDays({
            ...days,
            weekends: [...sat, ...sun]
        })
    }, [quart?.currentMonth])


    useEffect(() => {
        let a: Array<number> = [];
        let sat: Array<number> = [];
        let sun: Array<number> = [];
        for (let i = 1; i <= new Date(
            quart.currentYear,
            quart.currentMonth + 1,
            0
        ).getDate(); i++) {
            let newDate = new Date(
                quart.currentYear,
                quart.currentMonth,
                i
            );
            if (newDate.getDay() == 0) {
                sun.push(i);
            }
            if (newDate.getDay() == 6) {
                sat.push(i);
            }
            if (i > 15) {
                a.push(i);
            }

        }
        setDays({
            ...days,
            weekends: [...sat, ...sun],
            end: a
        })
    }, [quart.currentMonth])

    const getDayValue = (userAccessId: number, day: number, workRateId: number, userId: number) => {
        let workRate: any = _workRate?.items?.filter((item: workRateType) => item?.id === workRateId)[0];
        let weekDay: number = new Date(quart.currentYear, quart.currentMonth, day).getDay();
        let vocation: vocationType = vocations?.filter((item: vocationType) => {
            let start = new Date(item?.start_date);
            let finish = new Date(item?.finish_date);
            return day >= start?.getDate()
                && day <= finish?.getDate()
                && item?.user_id === userId
                && (
                    (start.getMonth() + 1 === ids?.month
                        && start?.getFullYear() === ids?.year)
                    ||
                    (finish.getMonth() + 1 === ids?.month
                        && finish?.getFullYear() === ids?.year)
                )
        })[0]
        let typedHolidays: Array<holidaysType> = holidays?.filter((item: holidaysType) => {
            let start = new Date(item?.start_date);
            let finish = new Date(item?.finish_date);
            let now = new Date(quart.currentYear, quart.currentMonth, day);
            now.setHours(24)
            start.setHours(0)
            finish.setHours(24)
            return start.getTime() <= now.getTime() && finish.getTime() >= now?.getTime()
        })
        if (!vocation && typedHolidays?.length === 0) {
            if (tableStore) {
                let dataStore: any = tableStore?.data
                if (dataStore && dataStore[`${userAccessId}`]) {
                    let data: any = dataStore[`${userAccessId}`];
                    if (data[`${day}`]) {
                        form?.setFieldsValue({
                            [`${userAccessId}-${day}`]: data[`${day}`]
                        })
                        return data[`${day}`];
                    } else if (workRate && workRate?.daily_hours[weekDay]) {
                        form?.setFieldsValue({
                            [`${userAccessId}-${day}`]: workRate?.daily_hours[weekDay]
                        })
                        return workRate?.daily_hours[weekDay]
                    } else {
                        form?.setFieldsValue({
                            [`${userAccessId}-${day}`]: "8"
                        })
                        return "8"
                    }
                } else if (workRate && workRate?.daily_hours[weekDay]) {
                    form?.setFieldsValue({
                        [`${userAccessId}-${day}`]: workRate?.daily_hours[weekDay]
                    })
                    return workRate?.daily_hours[weekDay]
                } else {
                    form?.setFieldsValue({
                        [`${userAccessId}-${day}`]: "8"
                    })
                    return "8";
                }
            } else if (workRate && workRate?.daily_hours[weekDay]) {
                form?.setFieldsValue({
                    [`${userAccessId}-${day}`]: workRate?.daily_hours[weekDay]
                })
                return workRate?.daily_hours[weekDay]
            } else {
                form?.setFieldsValue({
                    [`${userAccessId}-${day}`]: "8"
                })
                return "8";
            }
        } else {
            if (vocation) {
                form?.setFieldsValue({
                    [`${userAccessId}-${day}`]: "T"
                })
                return "T"
            } else {
                let s: string = typedHolidays?.filter((item: holidaysType) => {
                    let start = new Date(item?.start_date);
                    let finish = new Date(item?.finish_date);
                    return start?.getDate() <= day && finish?.getDate() >= day
                })?.length > 0 ? "V" : "8"
                return s
            }
        }
    }

    const getWorkedDaysAndNotWorkedDays = (key: string, userAccessId: number) => {
        let dataStore: any = tableStore?.data
        if (dataStore && dataStore[`${userAccessId}`]) {
            let d = dataStore[`${userAccessId}`];
            if (d[key]) {
                return d[key];
            } else {
                return key === "daysOfParticipation" ? quart?.active === 1 ?
                    days.start?.length - days?.weekends?.filter((item: number) => days?.start?.includes(item))?.length
                    : days.end?.length - days?.weekends?.filter((item: number) => days?.end?.includes(item))?.length : ''
            }
        } else {
            return key === "daysOfParticipation" ? quart?.active === 1 ?
                days.start?.length - days?.weekends?.filter((item: number) => days?.start?.includes(item))?.length
                : days.end?.length - days?.weekends?.filter((item: number) => days?.end?.includes(item))?.length : ''
        }
    }

    const getLeaderName = () => {
        if (userAccesses?.length > 0) {
            let a = userAccesses?.filter((item: userAccessType1) => item?.is_leader == 1)[0];
            return { name: a.user?.profile?.first_name[0].toUpperCase() + "." + a.user?.profile?.last_name, leader: a }
        } else return { name: "", leader: undefined }
    }

    const loadExcel = () => {
        const wb = XLSX.utils.table_to_book(dataRef?.current, { sheet: "tabel" });
        XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: 'base64' })
        XLSX.writeFile(wb, `tabel-${currrentTable?.name}-${ids?.year}-${ids?.month}-${ids?.type === 1 ? '1-15' : '16-30|31'}.xlsx`);
    }

    const checkTabel = async () => {
        try {
            let data = new FormData();
            data?.append("is_checked", "1")
            const resp = await instance({
                method: 'put',
                url: `/table-stores/${ids?.tabel_store_id}`,
                data: data
            })
            if (resp?.data?.status === 1) {
                setRefreshAll(!refreshAll);
                setEditUserAccess({
                    ...editUserAccess,
                    edit: false
                })
                asyncN("success", "update", resp?.data?.message)
            } else {
                asyncN("error", "update", resp?.data?.message)
            }
        } catch (error: any) {
            asyncN("error", "update", error?.response ? error?.response?.data?.message : error?.message)
        }
    }

    const toPrintFunc = (refEl: any) => {
        return refEl;
    }

    return (
        <Spin spinning={loading || loadingAccesses}>
            <Row gutter={24}>
                {vocationsModal?.user ? <VocationsIndex refresh={refreshAll} setRefresh={setRefreshAll} month={quart?.currentMonth} year={quart?.currentYear} userAccesses={userAccesses} visible={vocationsModal?.visible} user={vocationsModal?.user} setVisible={(e: boolean) => {
                    setVocationsModal({
                        user: null,
                        visible: e
                    })
                }} /> : ''}
                {tableStore?.is_checked === 1 ? <Col className="d-flex justify-content-end mb-2" span={24}>
                    <div>
                        {!editUserAccess?.edit ? <ReactToPrint
                            trigger={() => {
                                return <Button type="primary" className='me-1' icon={<FaPrint size={20} className="pe-1" />}>Chop etish</Button>;
                            }}
                            content={() => {
                                return toPrintFunc(bodyRef);
                            }}
                        /> : ''}
                        {!editUserAccess?.edit ? <Button type="primary" style={{ backgroundColor: "green" }} onClick={loadExcel}>Excel</Button> : ''}
                    </div>
                </Col> : ''}
                <Col className="d-flex justify-content-end mb-2" span={24}>
                    <div>
                        {
                            !editUserAccess?.edit && tableStore && auth?.user?.role?.some((item: string) => ["hr", "admin"].includes(item)) ?
                                <Button disabled={tableStore?.is_checked === 1} onClick={() => checkTabel()} type="primary" className={`mx-1 ${tableStore?.is_checked === 1 ? 'bg-success text-light' : ''}`} icon={<FaCheck className="me-1" />}>{tableStore?.is_checked === 1 ? "Tadiqlangan" : "Tasdiqlash"}</Button> :
                                ""
                        }
                        {!editUserAccess?.edit && tableStore?.is_checked === 0 ? <Button icon={<FaEdit className="me-1" />} type="primary" onClick={() => {
                            setEditUserAccess({
                                ...editUserAccess,
                                edit: !editUserAccess?.edit
                            })
                        }}>
                            Tahrirlash
                        </Button> :
                            " "
                        }
                    </div>
                </Col>
                {!tableStore ? <Col className="my-2" span={24}>
                    <Alert type="warning" showIcon description={
                        <div className="d-f w-100 justify-content-between">
                            <div>Bu sizga kerakli tabelning tahminiy ko'rinishidir! Tahrirlashga o'ting va tabelni saqlang!</div>
                            {/* <div><Button shape="circle" icon={<FiHelpCircle />} /></div> */}
                        </div>
                    } />
                </Col> : ''}
            </Row>
            <Form onFinish={onFinish} form={form}>
                <Row gutter={24}>
                    <Col className="class-for-print-landscape" ref={(el: any) => bodyRef = el} span={24}>
                        <table ref={dataRef} className={`table table-bordered text-center`}>
                            <thead>
                                <tr>
                                    <th colSpan={days?.end[days?.end?.length - 1] === 31 ? 23 : 22}>
                                        <Row gutter={24}>
                                            {editUserAccess?.edit ? <Col span={24} className="d-flex justify-content-end mb-1">
                                                <div>
                                                    <Button type="primary" htmlType="submit" className="mx-1">
                                                        Saqlash
                                                    </Button>
                                                    <Button onClick={() => setEditUserAccess({
                                                        ...editUserAccess,
                                                        edit: false
                                                    })} danger htmlType="button" type="primary">
                                                        Bekor qilish
                                                    </Button>
                                                </div>
                                            </Col> : ''}
                                            <Col className="text-center" span={18}>
                                                <div className="border-bottom">
                                                    <strong>
                                                        Toshkent davlat yuridik universiteti
                                                    </strong>
                                                </div>
                                                <div style={{ fontSize: "12px", fontWeight: 300 }}>tashkilotning nomi</div>
                                                <div className="border-bottom">
                                                    <strong>
                                                        {currrentTable?.name}
                                                    </strong>
                                                </div>
                                                <div style={{ fontSize: "12px", fontWeight: 300 }}>tarkibiy bo'linma nomi</div>
                                            </Col>
                                            <Col span={6}>
                                                <table className="table text-center">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={3}>Hisobot davri</th>
                                                        </tr>
                                                        {/* <tr>
                                    <th colSpan={3}>
                                        <DatePicker onChange={(e, d) => {
                                            let s = d?.split("/");
                                            setQuart({
                                                active: 1,
                                                currentMonth: Number(s[0]) - 1,
                                                currentYear: Number(s[1])
                                            })
                                        }} defaultValue={moment(new Date().getTime())} picker="month" format={"MM/YYYY"} />
                                        <Select value={quart?.active} style={{ width: "95px" }} onChange={(e: number) => {
                                            setQuart({
                                                ...quart,
                                                active: Number(e) === 1 ? 1 : 2
                                            })
                                        }} placeholder="Half">
                                            <Option value={1}>1-15</Option>
                                            <Option value={2}>16-30|31</Option>
                                        </Select>
                                    </th>
                                </tr> */}
                                                        <tr>
                                                            <th>dan</th>
                                                            <th>
                                                                {/* {new Date("06-19-2022").toString()} */}
                                                            </th>
                                                            <th>gacha</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        {moment(new Date(`${quart?.currentMonth + 1}-${quart?.active === 1 ? "1" : "16"}-${quart.currentYear}`).getTime()).format("DD/MM/YYYY")}
                                                                    </div>
                                                                    {/* <div className="d-flex justify-content-center">
                                                <Button onClick={() => switchMonth("prev")} className="d-flex align-items-center" type="link">
                                                    <FaArrowAltCircleLeft className="me-1" /> Avvalgi
                                                </Button>
                                            </div> */}
                                                                </div>
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        {moment(new Date(`${quart?.currentMonth + 1}-${quart?.active === 1 ? "15" : new Date(
                                                                            new Date().getFullYear(),
                                                                            quart.currentMonth + 1,
                                                                            0
                                                                        ).getDate()}-${quart.currentYear}`).getTime()).format("DD/MM/YYYY")}
                                                                    </div>
                                                                    {/* <div className="d-flex justify-content-center">
                                                <Button onClick={() => switchMonth("next")} className="d-flex align-items-center" type="link">
                                                    Keyingi <FaArrowAltCircleRight className="ms-1" />
                                                </Button>
                                            </div> */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>
                                            <Col span={24} className="text-center">
                                                <div>
                                                    <strong>
                                                        Foydalanilgan ish vaqti hisobini yuritish
                                                    </strong>
                                                </div>
                                                <div>
                                                    <strong>
                                                        TABELI
                                                    </strong>
                                                </div>
                                            </Col>
                                        </Row>
                                    </th>
                                </tr>
                                <tr>
                                    <th style={{ writingMode: "vertical-rl", textOrientation: "mixed", transform: "rotate(180deg)", verticalAlign: "middle" }} rowSpan={3}>Tartib <br /> raqami</th>
                                    <th style={{ writingMode: "vertical-rl", textOrientation: "mixed", transform: "rotate(180deg)", verticalAlign: "middle" }} rowSpan={3}>Tabel <br /> raqami</th>
                                    <th rowSpan={3} style={{ verticalAlign: "middle" }}>Xodimning F.I.Sh</th>
                                    <th rowSpan={3} style={{ verticalAlign: "middle" }}>Lavozimi</th>
                                    <th style={{ writingMode: "vertical-rl", textOrientation: "mixed", transform: "rotate(180deg)", verticalAlign: "middle" }} rowSpan={3}>Stavkasi</th>
                                    <th colSpan={days?.end[days?.end?.length - 1] === 31 ? 16 : 15}>Oy kunlari va belgilar</th>
                                    <th style={{ writingMode: "vertical-rl", textOrientation: "mixed", transform: "rotate(180deg)", verticalAlign: "middle" }} rowSpan={3}>Ishga <br /> qatnashgan <br /> kunlar</th>
                                    <th style={{ writingMode: "vertical-rl", textOrientation: "mixed", transform: "rotate(180deg)", verticalAlign: "middle" }} rowSpan={3}>Ishlamagan <br /> kunlar</th>
                                </tr>
                                <tr style={{ opacity: quart?.active === 1 ? 1 : .5 }}>
                                    {
                                        days?.start?.map((item: number, i: number) =>
                                            <th style={{ backgroundColor: quart?.active === 1 && days?.weekends?.some((item1: number) => item1 === item) ? "#ffe58f" : '' }} key={i}>{item}</th>
                                        )
                                    }
                                    {days?.end[days?.end?.length - 1] === 31 ? <td></td> : ''}
                                </tr>
                                <tr style={{ opacity: quart?.active === 2 ? 1 : .5 }}>
                                    {
                                        days?.end?.map((item: number, i: number) =>
                                            <th style={{
                                                backgroundColor: quart?.active === 2 && days?.weekends?.some((item1: number) => item1 === item) ? "#ffe58f" : ''
                                            }} key={i}>{item}</th>
                                        )
                                    }
                                    {days?.end[days?.end?.length - 1] === 28 ? <><td></td><td></td></> : days?.end[days?.end?.length - 1] === 29 ? <td></td> : ''}
                                </tr>
                                <tr>
                                    <th>1</th>
                                    <th>2</th>
                                    <th>3</th>
                                    <th>4</th>
                                    <th>5</th>
                                    <th colSpan={days?.end[days?.end?.length - 1] === 31 ? 16 : 15}>6</th>
                                    <th>7</th>
                                    <th>8</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={days?.end[days?.end?.length - 1] === 31 ? 23 : 22} style={{ backgroundColor: "#ffe58f" }}>
                                        {currrentTable?.name}
                                    </td>
                                </tr>
                                {
                                    userAccesses?.map((item: userAccessType1, i: number) =>
                                        <tr>
                                            <td>
                                                <span className="d-flex justify-content-center align-items-center">
                                                    {i + 1}
                                                    {editUserAccess?.edit ? <span>
                                                        <Dropdown trigger={["click"]} overlay={
                                                            <Menu>
                                                                <Menu.Item onClick={() => {
                                                                    setEditUserAccess({
                                                                        edit: editUserAccess?.edit,
                                                                        item: item,
                                                                        visibleModal: true
                                                                    })
                                                                }}>
                                                                    Kasbiy ma'lumotlar
                                                                </Menu.Item>
                                                                <Menu.Item onClick={() => {
                                                                    setVocationsModal({
                                                                        user: item?.user,
                                                                        visible: true
                                                                    })
                                                                }}>
                                                                    Ta'tillar
                                                                </Menu.Item>
                                                            </Menu>
                                                        }>
                                                            <Button className="p-0 m-0" type="link" icon={<FaUserCog size={20} />}></Button>
                                                        </Dropdown>
                                                    </span> : ''}
                                                </span>
                                            </td>
                                            <td>{item?.tabel_number}</td>
                                            <td>{(item?.user?.profile?.first_name + " " + item?.user?.profile?.last_name + " " + item?.user?.profile?.middle_name).toUpperCase()}</td>
                                            <td>{getWorkRateOrJobTitle("jobTitle", item?.job_title_id)}</td>
                                            <td>{getWorkRateOrJobTitle("workRate", item?.work_rate_id)?.rate}</td>
                                            {
                                                quart?.active === 1 ? days?.start?.map((day: number, i: number) =>
                                                    days?.weekends?.includes(day) ? <td key={i} style={{ backgroundColor: "#ffe58f" }}>
                                                        {
                                                            editUserAccess?.edit ?
                                                                <Form.Item name={`${item?.id}-${day}`} initialValue={"v"}>
                                                                    <Input />
                                                                </Form.Item>
                                                                : "V"
                                                        }
                                                    </td>
                                                        : <td style={{ backgroundColor: Boolean(Number(getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id))) ? "" : "#ffe58f" }} key={i}>
                                                            {
                                                                editUserAccess?.edit ?
                                                                    <Form.Item name={`${item?.id}-${day}`} initialValue={getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id)}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    : getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id)
                                                            }
                                                        </td>
                                                ) : days?.end?.map((day: number, i: number) =>
                                                    days?.weekends?.includes(day) ? <td key={i} style={{ backgroundColor: "#ffe58f" }}>
                                                        {
                                                            editUserAccess?.edit ?
                                                                <Form.Item name={`${item?.id}-${day}`} initialValue={"v"}>
                                                                    <Input />
                                                                </Form.Item>
                                                                : "V"
                                                        }
                                                    </td>
                                                        : <td style={{ backgroundColor: Boolean(Number(getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id))) ? "" : "#ffe58f" }} key={i}>
                                                            {
                                                                editUserAccess?.edit ?
                                                                    <Form.Item name={`${item?.id}-${day}`} initialValue={getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id)}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    : getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id)
                                                            }</td>
                                                )
                                            }{quart?.active === 1 && days?.end[days?.end?.length - 1] === 31 ? <td></td> : ''}
                                            {days?.end[days?.end?.length - 1] === 28 ? <><td></td><td></td></> : days?.end[days?.end?.length - 1] === 29 ? <td></td> : ''}
                                            <td>
                                                {
                                                    editUserAccess?.edit ?
                                                        <Form.Item name={`${item?.id}-daysOfParticipation`} >
                                                            <Input />
                                                        </Form.Item>
                                                        : getWorkedDaysAndNotWorkedDays("daysOfParticipation", item?.id)
                                                }
                                            </td>
                                            <td>
                                                {
                                                    editUserAccess?.edit ?
                                                        <Form.Item name={`${item?.id}-daysOffWork`}>
                                                            <Input />
                                                        </Form.Item>
                                                        : getWorkedDaysAndNotWorkedDays("daysOffWork", item?.id)
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    currrentTable?.children?.map((item: childrenType, i: number) =>
                                        item?.userAccess?.length > 0 ? <>
                                            <tr key={i}>
                                                <td colSpan={days?.end[days?.end?.length - 1] === 31 ? 23 : 22} style={{ backgroundColor: "#ffe58f" }}>
                                                    {item?.name}
                                                </td>
                                            </tr>
                                            {
                                                item?.userAccess?.map((item: userAccessType1, i: number) =>
                                                    <tr>
                                                        <td>
                                                            <span className="d-flex justify-content-center align-items-center">
                                                                {i + 1}
                                                                {editUserAccess?.edit ? <span>
                                                                    <Dropdown trigger={["click"]} overlay={
                                                                        <Menu>
                                                                            <Menu.Item onClick={() => {
                                                                                setEditUserAccess({
                                                                                    edit: editUserAccess?.edit,
                                                                                    item: item,
                                                                                    visibleModal: true
                                                                                })
                                                                            }}>
                                                                                Kasbiy ma'lumotlar
                                                                            </Menu.Item>
                                                                            <Menu.Item onClick={() => {
                                                                                setVocationsModal({
                                                                                    user: item?.user,
                                                                                    visible: true
                                                                                })
                                                                            }}>
                                                                                Ta'tillar
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    }>
                                                                        <Button className="p-0 m-0" type="link" icon={<FaUserCog size={20} />}></Button>
                                                                    </Dropdown>
                                                                </span> : ''}
                                                            </span>
                                                        </td>
                                                        <td>{item?.tabel_number}</td>
                                                        <td>{item?.user?.profile?.first_name + " " + item?.user?.profile?.last_name + " " + item?.user?.profile?.middle_name}</td>
                                                        <td>{getWorkRateOrJobTitle("jobTitle", item?.job_title_id)}</td>
                                                        <td>{getWorkRateOrJobTitle("workRate", item?.work_rate_id)?.rate}</td>
                                                        {
                                                            quart?.active === 1 ? days?.start?.map((day: number, i: number) =>
                                                                days?.weekends?.includes(day) ? <td style={{ backgroundColor: "#ffe58f" }}>
                                                                    {
                                                                        editUserAccess?.edit ?
                                                                            <Form.Item name={`${item?.id}-${day}`} initialValue={"v"}>
                                                                                <Input />
                                                                            </Form.Item>
                                                                            : "V"
                                                                    }
                                                                </td>
                                                                    : <td style={{ backgroundColor: Boolean(Number(getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id))) ? "" : "#ffe58f" }}>{
                                                                        editUserAccess?.edit ?
                                                                            <Form.Item name={`${item?.id}-${day}`} initialValue={getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id)}>
                                                                                <Input />
                                                                            </Form.Item>
                                                                            : getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id)
                                                                    }</td>
                                                            ) : days?.end?.map((day: number, i: number) =>
                                                                days?.weekends?.includes(day) ? <td key={i} style={{ backgroundColor: "#ffe58f" }}>
                                                                    {
                                                                        editUserAccess?.edit ?
                                                                            <Form.Item name={`${item?.id}-${day}`} initialValue={"v"}>
                                                                                <Input />
                                                                            </Form.Item>
                                                                            : "V"
                                                                    }
                                                                </td>
                                                                    : <td style={{ backgroundColor: Boolean(Number(getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id))) ? "" : "#ffe58f" }} key={i}>{
                                                                        editUserAccess?.edit ?
                                                                            <Form.Item name={`${item?.id}-${day}`} initialValue={getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id)}>
                                                                                <Input />
                                                                            </Form.Item>
                                                                            : getDayValue(item?.id, day, item?.work_rate_id, item?.user?.id)
                                                                    }</td>
                                                            )
                                                        }{quart?.active === 1 && days?.end[days?.end?.length - 1] === 31 ? <td></td> : ''}
                                                        {days?.end[days?.end?.length - 1] === 28 ? <><td></td><td></td></> : days?.end[days?.end?.length - 1] === 29 ? <td></td> : ''}
                                                        <td>
                                                            {
                                                                editUserAccess?.edit ?
                                                                    <Form.Item name={`${item?.id}-daysOfParticipation`}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    : getWorkedDaysAndNotWorkedDays("daysOfParticipation", item?.id)
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                editUserAccess?.edit ?
                                                                    <Form.Item name={`${item?.id}-daysOffWork`}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    : getWorkedDaysAndNotWorkedDays("daysOffWork", item?.id)
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </> : ''
                                    )
                                }
                                <tr style={{ border: "0" }}>
                                    <td style={{ border: "0" }} colSpan={days?.end[days?.end?.length - 1] === 31 ? 23 : 22}>
                                        <Row gutter={24} className="d-flex justify-content-end">
                                            <Col span={8}>
                                                <div>
                                                    <Row gutter={24}>
                                                        <Col span={8} className="text-center">
                                                            <div className="border-bottom">
                                                                <strong>
                                                                    {
                                                                        getLeaderName()?.leader?.jobTitle?.name
                                                                    }
                                                                </strong>
                                                            </div>
                                                            <div style={{ fontSize: "12px" }}>
                                                                Lavozimi
                                                            </div>
                                                        </Col>
                                                        <Col span={8} className="text-center">
                                                            <div style={{ width: "100%", marginTop: "24px" }} className="border-bottom">
                                                                <strong> </strong>
                                                            </div>
                                                            <div style={{ fontSize: "12px" }}>
                                                                imzo
                                                            </div>
                                                        </Col>
                                                        <Col span={8} className="text-center">
                                                            <div style={{ width: "100%" }} className="border-bottom">
                                                                <strong>
                                                                    {
                                                                        getLeaderName()?.name
                                                                    }
                                                                </strong>
                                                            </div>
                                                            <div style={{ fontSize: "12px" }}>
                                                                F.I.Sh
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="mt-3">
                                                    <Row gutter={24}>
                                                        <Col span={8} className="text-center">
                                                            <div className="border-bottom">
                                                                <strong>
                                                                    Kadrlar bo'limi
                                                                </strong>
                                                            </div>
                                                            <div style={{ fontSize: "12px" }}>
                                                                Lavozimi
                                                            </div>
                                                        </Col>
                                                        <Col span={8} className="text-center">
                                                            <div style={{ width: "100%", marginTop: "24px" }} className="border-bottom">
                                                                <strong> </strong>
                                                            </div>
                                                            <div style={{ fontSize: "12px" }}>
                                                                imzo
                                                            </div>
                                                        </Col>
                                                        <Col span={8} className="text-center">
                                                            <div style={{ width: "100%", marginTop: "24px" }} className="border-bottom">
                                                                <strong>

                                                                </strong>
                                                            </div>
                                                            <div style={{ fontSize: "12px" }}>
                                                                F.I.Sh
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Form>
            <Modal footer={false} visible={editUserAccess?.visibleModal} onCancel={() => setEditUserAccess({
                item: null, visibleModal: false, edit: editUserAccess?.edit
            })}>
                <UserAccessUpdate
                    refresh={refreshAll}
                    setRefresh={setRefreshAll}
                    editUserAccessState={editUserAccess}
                    setEditUserAccessState={setEditUserAccess}
                />
            </Modal>
        </Spin>
    )
}

export default EmployeeTabel;
