import { FC } from "react";
import { Badge, Tag, Typography, Switch } from 'antd';
import "./style.scss";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const BadgeStatus: FC<{ status: number | string }> = ({ status }): JSX.Element => {
    const { i18n, t } = useTranslation();

    return (
        <>
            {<Switch size="small" disabled={true} checkedChildren={t("Active")} unCheckedChildren='InActive' defaultChecked={status === 1 || status === '1'}/>}
        </>
    )
}
export const BadgeStatusTag: FC<{ status: number | string }> = ({ status }): JSX.Element => {
    const { i18n, t } = useTranslation();

    return (
        <>
            {<Switch size="small" disabled={true} checkedChildren='active' unCheckedChildren='InActive' defaultChecked={(status === 1 || status === '1')}/>}
        </>
    )
}



const BadgeStatusText: FC<{ status: number | string }> = ({ status }): JSX.Element => {
    const { i18n, t } = useTranslation();

    return (
        <>
            {<Switch size="small" disabled={true} checkedChildren={t("Active")} unCheckedChildren={t('InActive')} defaultChecked={(status === 1 || status === '1')}/>}

        </>
    )
}


export { BadgeStatusText };
export default BadgeStatus;