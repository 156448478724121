import React, { useState, useEffect, FC } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';
import useGetAllData from 'hooks/useGetAllData';
import { useTranslation } from 'react-i18next';

function YearStats({setAllStd, _students}: any): JSX.Element {
    const [data, setData] = useState<any[]>([]);
    const [t] = useTranslation()


    useEffect(() => {
        if (_students.data.items.length) {
            let a = 0;
            const new_arr_fem = _students.data.items.map((e: any, index: any) => {
                a += e?.studentsCount
                return {
                    name: e?.name,
                    type: t(e?.studentsCountByGender[0]?.name),
                    total: e?.studentsCountByGender[0]?.count
                }
            })
            const new_arr_mel = _students.data.items.map((e: any, index: any) => {
                return {
                    name: e?.name,
                    type: t(e?.studentsCountByGender[1]?.name),
                    total: e?.studentsCountByGender[1]?.count
                }
            })
            setAllStd(a)
            setData(new_arr_fem.concat(new_arr_mel))
        }
    }, [_students.data.items.length])

    const config = {
        data,
        xField: 'name',
        yField: 'total',
        seriesField: 'type',
        isGroup: true,
        columnStyle: {
        radius: [20, 20, 0, 0],
        },
    };

    return <Column {...config} />;
}

export default YearStats