import { IExamSmeta, ITeacherAccess } from "models/exam";
import { useEffect, useState } from "react";
import { getAllLanguages } from "services";
import { findElementById } from "utils/auxiliary_functions"
import { getOneExam } from "../service"




interface IPropsType {
    ids: {
        exam_id: number,
        subject_id: number
    }
}


type TypeExmaSmetaItem = Record<number, {
    countsByLang: Record<string, number>,
    full_name: string
}>

const useGetDistributedExamsToTeacher = ({ ids }: IPropsType): { _fetch: (type: 'exam' | 'appeal') => void, distributedExams: TypeExmaSmetaItem, loading: boolean, isConfirmed: boolean } => {

    const [distributedExams, setDistributedExams] = useState<TypeExmaSmetaItem>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

    const { subject_id, exam_id } = ids;


    const _fetch = async (type: 'appeal' | 'exam') => {

        try {

            setLoading(true)

            const response = await getOneExam(exam_id, `sort=-id&expand=subjectName,${type === 'exam' ? 'examSmeta,examStudentByLang' : 'examAppealSemeta,examAppealByLang'},teacherAccess&fields=teacherAccess&filter=${JSON.stringify({ subject_id })}`);


            const { teacherAccess, examSmeta, examAppealSemeta } = response as { teacherAccess: ITeacherAccess[], examSmeta: IExamSmeta[], examAppealSemeta: IExamSmeta[] };

            const semeta = type === 'exam' ? examSmeta : examAppealSemeta;

            setDistributedExams({});

            if (Array.isArray(semeta) && semeta.length) {

                let new_object: TypeExmaSmetaItem = {};

                semeta.forEach((element) => {

                    let lang_code: string = '';

                    switch (element.lang_id) {
                        case 1:
                            lang_code = 'uz';
                            break
                        case 2:
                            lang_code = 'en';
                            break
                        case 3:
                            lang_code = 'ru';
                    }

                    const findTeacherAccess = teacherAccess.find(e => e.id === element.teacher_access_id);

                    if (findTeacherAccess && Object.getOwnPropertyNames(findTeacherAccess).length) {

                        const { user_id, teacher } = findTeacherAccess;

                        if (new_object.hasOwnProperty(findTeacherAccess.user_id)) {

                            new_object[user_id].countsByLang[lang_code] = element.count;

                        } else {

                            new_object[user_id] = {
                                full_name: `${teacher.last_name} ${teacher.first_name} ${teacher.middle_name}`,
                                countsByLang: { [lang_code]: element.count },
                            }

                        }

                    }

                })

                setDistributedExams(new_object);

                if (Array.isArray(semeta) && semeta.length) {

                    const isConfirmedEduAdmin = semeta.every(e => e.status === 2);
                    setIsConfirmed(isConfirmedEduAdmin);
                }

            }

            setLoading(false);

        } catch (error) {
            setLoading(false);
        }

    }
    return { _fetch, distributedExams, loading, isConfirmed }

}

export default useGetDistributedExamsToTeacher;
