export const edu_form_data = [
    { item: 'Kunduzgi', count: 4600, percent: 4600 / 60 },
    { item: 'Kechgi', count: 300, percent: 300 / 60 },
    { item: 'Sirtqi', count: 1100, percent: 1100 / 60 }
]
export const edu_type_data = [
    { item: 'Grant', count: 1800, percent: 1800 / 6000 },
    { item: 'Kontrakt', count: 4200, percent: 4200 / 6000 }
]
export const gender_data = [
    { item: 'Ayol', count: 3200, percent: 3200 / 60 },
    { item: 'Erkak', count: 2800, percent: 2800 / 60 }
]
export const country_data = [
    { item: "O'zbekiston fuqorosi", count: 200, percent: 200 / 60 },
    { item: 'Chet el fuqorosi', count: 5800, percent: 5800 / 60 },
]



export const columns = [
    { title: 'Texnikum nomi', dataIndex: 'name', key: 'name' },
    { title: 'Erkaklar', dataIndex: 'male', key: 'male' },
    { title: 'Ayollar', dataIndex: 'female', key: 'female' },
    { title: 'Umumiy', dataIndex: 'total', key: 'total' },
];

export const data = [
    {
        name: 'Jinoiy odil sudlov',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
    {
        name: 'Ommaviy huquq',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
    {
        name: 'Xalqaro huquq va qiyosiy huquqshunoslik',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
    {
        name: 'Xususiy huquq',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
    {
        name: 'Magistratura va sirtqi ta’lim',
        count_student: 200,
        gender_student: '80 / 120',
        certificate_student: 500,
    },
];

export const region_data: Array<{ class: string, country: string, type: '1' | '2', value: number }> = [
    { class: 'Ayol', country: "Andijon", type: '1', value: 10.8 },
    { class: 'Ayol', country: "Andijon", type: '2', value: 89.2 },
    { class: 'Ayol', country: "Farg'ona", type: '1', value: 10.8 },
    { class: 'Ayol', country: "Farg'ona", type: '2', value: 89.2 },
    { class: 'Ayol', country: 'Namangan', type: '1', value: 10.8 },
    { class: 'Ayol', country: 'Namangan', type: '2', value: 89.2 },
    { class: 'Ayol', country: 'Toshkent vil', type: '1', value: 10.8 },
    { class: 'Ayol', country: 'Toshkent vil', type: '2', value: 89.2 },
    { class: 'Ayol', country: 'Toshkent shahar', type: '1', value: 10.8 },
    { class: 'Ayol', country: 'Toshkent shahar', type: '2', value: 89.2 },
    { class: 'Ayol', country: 'Sirdaryo', type: '1', value: 72.9 },
    { class: 'Ayol', country: 'Sirdaryo', type: '2', value: 27.1 },
    { class: 'Ayol', country: 'Jizzax', type: '1', value: 2.8 },
    { class: 'Ayol', country: 'Jizzax', type: '2', value: 97.2 },
    { class: 'Ayol', country: 'Samarqand', type: '1', value: 49.1 },
    { class: 'Ayol', country: 'Samarqand', type: '2', value: 50.9 },
    { class: 'Ayol', country: 'Surxondaryo', type: '1', value: 11.6 },
    { class: 'Ayol', country: 'Surxondaryo', type: '2', value: 88.4 },
    { class: 'Ayol', country: 'Qashqadaryo', type: '1', value: 23.8 },
    { class: 'Ayol', country: 'Qashqadaryo', type: '2', value: 76.2 },
    { class: 'Ayol', country: 'Buxoro', type: '1', value: 2.9 },
    { class: 'Ayol', country: 'Buxoro', type: '2', value: 97.1 },
    { class: 'Ayol', country: 'Navoiy', type: '1', value: 2.7 },
    { class: 'Ayol', country: 'Navoiy', type: '2', value: 97.3 },
    { class: 'Ayol', country: 'Xorazm', type: '1', value: 3.6 },
    { class: 'Ayol', country: 'Xorazm', type: '2', value: 96.4 },
    { class: 'Ayol', country: "Qoraqalpog'iston", type: '1', value: 1.3 },
    { class: 'Ayol', country: "Qoraqalpog'iston", type: '2', value: 98.7 },
    { class: 'Erkak', country: "Andijon", type: '1', value: 10.8 },
    { class: 'Erkak', country: "Andijon", type: '2', value: 89.2 },
    { class: 'Erkak', country: "Farg'ona", type: '1', value: 10.8 },
    { class: 'Erkak', country: "Farg'ona", type: '2', value: 89.2 },
    { class: 'Erkak', country: 'Namangan', type: '1', value: 10.8 },
    { class: 'Erkak', country: 'Namangan', type: '2', value: 89.2 },
    { class: 'Erkak', country: 'Toshkent vil', type: '1', value: 10.8 },
    { class: 'Erkak', country: 'Toshkent vil', type: '2', value: 89.2 },
    { class: 'Erkak', country: 'Toshkent shahar', type: '1', value: 10.8 },
    { class: 'Erkak', country: 'Toshkent shahar', type: '2', value: 89.2 },
    { class: 'Erkak', country: 'Sirdaryo', type: '1', value: 72.9 },
    { class: 'Erkak', country: 'Sirdaryo', type: '2', value: 27.1 },
    { class: 'Erkak', country: 'Jizzax', type: '1', value: 2.8 },
    { class: 'Erkak', country: 'Jizzax', type: '2', value: 97.2 },
    { class: 'Erkak', country: 'Samarqand', type: '1', value: 49.1 },
    { class: 'Erkak', country: 'Samarqand', type: '2', value: 50.9 },
    { class: 'Erkak', country: 'Surxondaryo', type: '1', value: 11.6 },
    { class: 'Erkak', country: 'Surxondaryo', type: '2', value: 88.4 },
    { class: 'Erkak', country: 'Qashqadaryo', type: '1', value: 23.8 },
    { class: 'Erkak', country: 'Qashqadaryo', type: '2', value: 76.2 },
    { class: 'Erkak', country: 'Buxoro', type: '1', value: 2.9 },
    { class: 'Erkak', country: 'Buxoro', type: '2', value: 97.1 },
    { class: 'Erkak', country: 'Navoiy', type: '1', value: 2.7 },
    { class: 'Erkak', country: 'Navoiy', type: '2', value: 97.3 },
    { class: 'Erkak', country: 'Xorazm', type: '1', value: 3.6 },
    { class: 'Erkak', country: 'Xorazm', type: '2', value: 96.4 },
    { class: 'Erkak', country: "Qoraqalpog'iston", type: '1', value: 1.3 },
    { class: 'Erkak', country: "Qoraqalpog'iston", type: '2', value: 98.7 },
    { class: 'Umumiy（%）', country: "Andijon", type: '1', value: 10.8 },
    { class: 'Umumiy（%）', country: "Andijon", type: '2', value: 89.2 },
    { class: 'Umumiy（%）', country: "Farg'ona", type: '1', value: 10.8 },
    { class: 'Umumiy（%）', country: "Farg'ona", type: '2', value: 89.2 },
    { class: 'Umumiy（%）', country: 'Namangan', type: '1', value: 10.8 },
    { class: 'Umumiy（%）', country: 'Namangan', type: '2', value: 89.2 },
    { class: 'Umumiy（%）', country: 'Toshkent vil', type: '1', value: 10.8 },
    { class: 'Umumiy（%）', country: 'Toshkent vil', type: '2', value: 89.2 },
    { class: 'Umumiy（%）', country: 'Toshkent shahar', type: '1', value: 10.8 },
    { class: 'Umumiy（%）', country: 'Toshkent shahar', type: '2', value: 89.2 },
    { class: 'Umumiy（%）', country: 'Sirdaryo', type: '1', value: 72.9 },
    { class: 'Umumiy（%）', country: 'Sirdaryo', type: '2', value: 27.1 },
    { class: 'Umumiy（%）', country: 'Jizzax', type: '1', value: 2.8 },
    { class: 'Umumiy（%）', country: 'Jizzax', type: '2', value: 97.2 },
    { class: 'Umumiy（%）', country: 'Samarqand', type: '1', value: 49.1 },
    { class: 'Umumiy（%）', country: 'Samarqand', type: '2', value: 50.9 },
    { class: 'Umumiy（%）', country: 'Surxondaryo', type: '1', value: 11.6 },
    { class: 'Umumiy（%）', country: 'Surxondaryo', type: '2', value: 88.4 },
    { class: 'Umumiy（%）', country: 'Qashqadaryo', type: '1', value: 23.8 },
    { class: 'Umumiy（%）', country: 'Qashqadaryo', type: '2', value: 76.2 },
    { class: 'Umumiy（%）', country: 'Buxoro', type: '1', value: 2.9 },
    { class: 'Umumiy（%）', country: 'Buxoro', type: '2', value: 97.1 },
    { class: 'Umumiy（%）', country: 'Navoiy', type: '1', value: 2.7 },
    { class: 'Umumiy（%）', country: 'Navoiy', type: '2', value: 97.3 },
    { class: 'Umumiy（%）', country: 'Xorazm', type: '1', value: 3.6 },
    { class: 'Umumiy（%）', country: 'Xorazm', type: '2', value: 96.4 },
    { class: 'Umumiy（%）', country: "Qoraqalpog'iston", type: '1', value: 1.3 },
    { class: 'Umumiy（%）', country: "Qoraqalpog'iston", type: '2', value: 98.7 },
];