import { FC } from "react"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table"
import { Divider, Switch } from "antd";
import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
import EmptyTable from "components/EmptyTable";
import EditEduYear from "./crud/edit";
import useGetAllData from "hooks/useGetAllData";
import useDeleteOne from "hooks/useDeleteOne";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import IndexPageTitle from "components/IndexPageTitle";
import Actions from "components/Actions";
import CreateEduYear from "./crud/create";
import { ButtonCreate } from "components/Buttons";
import SimpleView from "pages/common/simple_view";
import TotalCount from "components/totalCountTag";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";

const EduYear: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [reRequest, setReRequest] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(15)
    const [currentPage, setCurrentPage] = useState<number>(1)


    const _delete = useDeleteOne();
    const { data, loading } = useGetAllData({ url: '/edu-years?expand=description,teacherCheckingType', isCall: 'auto', perPage, currentPage, refetch: [_delete.refetch, reRequest] })
    const TYPES_ = useGetAllData({ url: '/teacher-checking-types', isCall: 'auto' })


    const changeStatus = async (event: { type: number, edu_year_id: number, semestr_id: number }) => {
        try {
            const formdata = new FormData();
            formdata.append('type', String(event.type));
            formdata.append('semestr_id', String(event.semestr_id));
            formdata.append('edu_year_id', String(event.edu_year_id));

            const response = await instance({ url: '/teacher-checking-types', method: 'POST', data: formdata });

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message).then(() => { setReRequest(!reRequest) })
            } else {
                asyncN("error", 'update', response.data?.message)
            }
        } catch (error) {

        }
    }

    const contvertArrayToObject = (arr: any[]) => {
        const new_obj: any = {};
        if (arr.length) {
            arr.forEach((element: any) => {
                if (element.semestr_id) {
                    new_obj[element.semestr_id] = element?.type
                }
            })
        }

        return new_obj;
    }

    /*
    1-random
    2- o'zi
    */

    return (
        <div>
            <ForTitle title="Edu years" buttons={[isHasAuthRoute('edu-year_create') && <ButtonCreate onClick={() => setVisibleCreate(true)}/>]}/>
            { visibleView && id ? <SimpleView url={`edu-years/${id}?expand=createdBy,updatedBy,description`} visible={visibleView} setVisible={setVisibleView} title={"Edu year view"} /> : null}
            { visibleEdit && id ? <EditEduYear visible={visibleEdit} setVisible={setVisibleEdit} id={id} setReRequest={setReRequest} reRequest={reRequest} /> : null}
            { visibleCreate ? <CreateEduYear visible={visibleCreate} setVisible={setVisibleCreate} setReFetch={setReRequest} /> : null}
            <ForTable totalCount={data._meta.totalCount} setCurrentPage={setCurrentPage} setPerPage={setPerPage} perPage={perPage} currentPage={currentPage} loading={loading} datas={data.items} theads={
                    [
                            <>{t("Id")}</>,
                            <>{t("Name")}</>,
                            <>{t("Edu year")}</>,
                            <>
                                <span>{t("Imtihon tekshirish usuli")}</span>
                                <Divider className="p-0 my-1"></Divider>
                                <div className="d-flex justify-content-around">
                                    <span>Kuzgi semester</span>
                                    <span>Yozgi semester</span>
                                </div>
                            </>,
                            <>{t("Description")}</>,
                            <>{t("Status")}</>,
                            <>{t("Actions")}</>,
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            this.id,
                            this.name,
                            this?.year,
                            <div className="d-flex justify-content-around">
                                <span>
                                    <Switch
                                        size="small"
                                        style={{ width: '90px' }}
                                        checkedChildren="taqsimot"
                                        unCheckedChildren="random"
                                        checked={contvertArrayToObject(this?.teacherCheckingType)?.['1'] === 2}
                                        onChange={(event: boolean) => changeStatus({ type: event ? 2 : 1, edu_year_id: this.id, semestr_id: 1 })} />
                                </span>
                                <span>
                                    <Switch
                                        size="small"
                                        style={{ width: '90px' }}
                                        checkedChildren="taqsimot"
                                        unCheckedChildren="random"
                                        checked={contvertArrayToObject(this?.teacherCheckingType)?.['2'] === 2}
                                        onChange={(event: boolean) => changeStatus({ type: event ? 2 : 1, edu_year_id: this.id, semestr_id: 2 })} />
                                </span>
                            </div>,
                            <>{this?.description}</>,
                            <BadgeStatus status={this.status} />,
                            <Actions
                                id={this?.id}
                                url={'edu-years'}
                                setId={setId}
                                setReFetch={setReRequest}
                                setVisibleView={setVisibleView}
                                setVisibleEdit={setVisibleEdit}
                                viewPermission= {"edu-year_view"}
                                editPermission= {"edu-year_update"}
                                deletePermission= {"edu-year_delete"}
                            />,
                        ]
                    }
                }/>
        </div>
    )
}

export default EduYear;