import { Button, Col, Modal, Popconfirm, Row, Tooltip } from "antd";
import BadgeStatus from "components/BadgeStatus";
import { ButtonCreate } from "components/Buttons";
import EmptyTable from "components/EmptyTable";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import instance from "config/_axios";
import useGetAllData from "hooks/useGetAllData";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import CreateAndUpdateWorkRate from "./crud";
import { visibleType, workRateType } from "./types";

const WorkRate: FC = (): JSX.Element => {

    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [visible, setVisible] = useState<visibleType>({ item: null, type: "hide" });
    const [weekDays, setWeekDays] = useState<Array<String>>([
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)

    const _workRates = useGetAllData({
        url: `/work-rates?sort=-id&expand=description`, isCall: "auto", refetch: [refresh],
        currentPage: currentPage, perPage: perPage
    });


    const deleteOne = async (id: number) => {
        try {
            const resp = await instance({
                url: `/work-rates/${id}`,
                method: 'DELETE'
            })
            if (resp?.data?.status === 1) {
                asyncN("success", 'delete', resp?.data?.message).then(() => {
                    setRefresh(!refresh)
                })
            } else {
                asyncN("error", 'delete', resp?.data?.message);
            }
        } catch (error: any) {
            asyncN("error", 'delete', error?.response ? error?.response?.data?.message : error?.message);
        }
    }

    return (
        <div>
            <Row gutter={24}>
                <Col span={24}>
                    <ForTitle title='Work rates' buttons={[<ButtonCreate onClick={() => setVisible({ item: null, type: "edit" })}/>]}/>
                </Col>
                <Col span={24}>
                <ForTable setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} totalCount={_workRates.data._meta.totalCount} loading={_workRates.loading} datas={_workRates.items} theads={
                        [
                            <>Name</>,
                            <>Rate</>,
                            <>Weekly hour</>,
                            <>Hour day</>,
                            <>Description</>,
                            <>Status</>,
                            <>Type</>,
                            <>Actions</>,
                        ]
                    } tbodys={
                        function(this: any){
                            return [
                                <>{this?.name}</>,
                                <>{this?.rate}</>,
                                <>{this?.weekly_hours}</>,
                                <>{this?.hour_day}</>,
                                <>{this?.description}</>,
                                <><BadgeStatus status={this?.status} /></>,
                                <>{this?.type}</>,
                                <>
                                    <div className="crud_actions_table" >
                                        {isHasAuthRoute("academic-degree_view") && <Tooltip placement="top" title={t("View")}><TbFileInvoice  className="view_btn" onClick={() => { setVisible({ item: this, type: "show" }) }} /></Tooltip>}
                                        {isHasAuthRoute("academic-degree_update") && <Tooltip placement="top" title={t("Edit")}><TbFilePencil className="edit_btn" onClick={() => { setVisible({ item: this, type: "edit" }) }} /></Tooltip>}
                                        {isHasAuthRoute("academic-degree_delete") &&
                                            <Tooltip placement="top" title={t("Delete")}>
                                                <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`}  okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>} onConfirm={() => deleteOne(this.id)}>
                                                    <TbFileOff className="delete_btn" />
                                                </Popconfirm>
                                            </Tooltip>}
                                    </div>
                                </>
                            ]
                        }
                    }/>
                </Col>
                <Modal width={1000} footer={false} title={"Show work rate!"} visible={visible?.type === "show"} onCancel={() => setVisible({ item: null, type: "hide" })}>
                    {visible?.item ? <Row gutter={24}>
                        <Col className="mb-3" span={7}>
                            <strong>Name</strong>: {visible?.item?.name}
                        </Col>
                        <Col className="mb-3" span={17}>
                            <strong>Description</strong>: {visible?.item?.description}
                        </Col>
                        <Col span={4}>
                            <strong>Rate</strong>: {visible?.item?.rate}
                        </Col>
                        <Col span={5}>
                            <strong>Weekly hours</strong>: {visible?.item?.weekly_hours}
                        </Col>
                        <Col span={5}>
                            <strong>Hours day</strong>: {visible?.item?.hour_day}
                        </Col>
                        <Col span={5}>
                            <strong>Type</strong>: {visible?.item?.type}
                        </Col>
                        <Col className="mb-3" span={5}>
                            <strong>Status</strong>: <BadgeStatus status={visible?.item?.status} />
                        </Col>
                        {
                            visible?.item?.daily_hours ? Object.entries(visible?.item?.daily_hours)?.map(([key, value]: any, i: number) =>
                                <Col span={3}>
                                    <strong>{weekDays[Number(key) - 1]}</strong>: {value}
                                </Col>
                            ) : ''
                        }
                    </Row> : ''}
                </Modal>
                <Modal width={1000} footer={false} title={visible?.item ? "Update work rate!" : "Create work rate!"} visible={visible?.type === "edit"} onCancel={() => setVisible({ item: null, type: "hide" })}>
                    <CreateAndUpdateWorkRate refresh={refresh} setRefresh={setRefresh} visible={visible} setVisible={setVisible} />
                </Modal>
            </Row>
        </div>
    )
}

export default WorkRate