import { FC } from "react";
import { useAppSelector } from "store/services";
import { ArrayChecker } from "utils/ArrayChecker";
import AdminPanel from './AdminPanel';
import TeacherPanel from "./TeacherPanel";
import DeanPanel from "./DeanPanel";
import TutorPanel from "./TutorPanel";
import RectorDashboard from "./RectorDashboard";
import MudirPanel from "./mudir_panel";
import { Card, Row, Col, Calendar } from 'antd';
import MessageInDashboard from "./common/message";

const Panel: FC = (): JSX.Element => {

    const auth: any = useAppSelector(state => state.auth);
    const _roles = auth.user?.role;


    const checkRoles = (role_name: string) => {
        if (ArrayChecker(_roles)._) {
            return _roles.includes(role_name);
        } else if (_roles) {
            return _roles === role_name;
        }
    }

    // paneldiv

    if (checkRoles('admin')) {
        return <AdminPanel />
    }
    if (checkRoles('edu_admin')) {
        return <AdminPanel />
    }
    if (checkRoles('teacher')) {
        return <TeacherPanel />
    }
    if (checkRoles('dean')) {
        return <DeanPanel />
    }
    if (checkRoles('tutor')) {
        return <TutorPanel />
    }
    if (checkRoles('rector')) {
        return <RectorDashboard />
    }
    if (checkRoles('mudir')) {
        return <MudirPanel />
    }
    
    return (
        <Col sm={24} md={24} lg={24} xl={24} xs={24}>
            <Row>
                <Col sm={24} md={24} lg={12} xl={12} xs={24}>
                    <Card title={<h5>Kalendar</h5>} className='mx-1'>
                        <Calendar fullscreen={false} />
                    </Card>
                </Col>
                <Col sm={24} md={24} lg={12} xl={12} xs={24}>
                    <Card title={<h5>Xabarlar</h5>} className='mx-1' style={{height: '100%'}}>
                        <MessageInDashboard/>
                    </Card>
                </Col>
            </Row>
        </Col>
    )




}


export default Panel;