
import { useState, ChangeEvent, FC } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";
import { Tooltip, Popconfirm, Input, Row, Col, Select, Button, Modal, Tag, Pagination } from "antd";
import { FaFileExcel, FaHospitalUser, FaUserShield } from 'react-icons/fa';
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import isHasAuthRoute from "utils/isHasRoute";
import useDebounce from "hooks/useDebounce";
import InputMask from 'react-input-mask';
import useGetAllData from "hooks/useGetAllData";
import useDeleteOne from "hooks/useDeleteOne";
import "./styles.scss";
import ExcelExport from "./default/excelExport";
import { TypeStudentEDD } from "./types";
import StatusChecker from "components/Status";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { ButtonCreate } from "components/Buttons";
import { StudentEDD } from "./utils";
import { BiSync } from "react-icons/bi";
import ForTable from "components/ForTable";
import { AiOutlineCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import ForTitle from "components/ForTitles";
import FilterSelectComponent from "components/FilterSelect";
import CustomPagination from "components/Pagination";
import { excelExport } from "utils/excelExport";

const Students: FC = (): JSX.Element => {

    const { i18n, t } = useTranslation();
    const history = useHistory();
    const { value, writeToUrl } = useUrlQueryParams({});
    const [exportModal, setExportModal] = useState<TypeStudentEDD>(StudentEDD)



    const debouncedLastName = useDebounce<string>(value.filter_like?.last_name, 500);
    const debouncedFirstName = useDebounce<string>(value.filter_like?.first_name, 500);
    const debouncedMiddleName = useDebounce<string>(value.filter_like?.middle_name, 500);
    const debouncedUsername = useDebounce<string>(value.filter_like?.username, 500);
    const debouncedPassportSeria = useDebounce<string>(value.filter_like?.passport_seria, 500);
    const debouncedPassportNumber = useDebounce<string>(value.filter_like?.passport_number, 500);
    const debouncedPassportPin = useDebounce<string>(value.filter_like?.passport_pin, 500);
    const debouncedFacultyId = useDebounce<string | number | undefined>(value.filter?.faculty_id, 500);
    const debouncedCourseId = useDebounce<string | number | undefined>(value.filter?.course_id, 500);
    const debouncedEduPlanId = useDebounce<string | number | undefined>(value.filter?.edu_plan_id, 500);
    const debouncedLangId = useDebounce<string | number | undefined>(value.filter?.edu_lang_id, 500);


    const _delete = useDeleteOne();
    const students = useGetAllData({
        url: `students?sort=-id&expand=profile,user,socialCategory,residenceStatus,categoryOfCohabitant,studentCategory,createdBy,updatedBy,eduPlan&filter=${JSON.stringify(value.filter)}&filter-like=${JSON.stringify(value.filter_like)}`,
        isCall: 'auto',
        perPage: value.perPage,
        currentPage: value.currentPage,
        refetch: [
            value.perPage,
            value.currentPage,
            debouncedLastName,
            debouncedFirstName,
            debouncedPassportPin,
            debouncedMiddleName,
            debouncedUsername,
            debouncedPassportSeria,
            debouncedPassportNumber,
            _delete.refetch,
            debouncedFacultyId,
            debouncedCourseId,
            debouncedEduPlanId,
            debouncedLangId]
    });


    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        writeToUrl({ name, value });
    }

    return (
        <div>
            <ForTitle title="Students" buttons={[
                isHasAuthRoute('hostel-app_index') && <Button type="link" ghost className="me-2" onClick={() => history.push('/dormitory_students')} icon={<FaHospitalUser className="me-2" />} >Talabalar turar joyi</Button>,
                isHasAuthRoute('student_view') && isHasAuthRoute('student_index') ? <Button type="link" ghost className="me-2" onClick={() => setExportModal({
                    ...exportModal,
                    visible: true
                })} icon={<FaFileExcel className="me-2" />} >Export students</Button> : null,
                isHasAuthRoute('student_view') && isHasAuthRoute('student_index') ? <Button type="link" ghost className="me-2" onClick={() => history.push("/student-login-password")} icon={<FaUserShield className="me-2" />} >Login va parollar</Button> : null,
                isHasAuthRoute('student_create') && <NavLink to={'/student_create'} className="text-decoration-none"><ButtonCreate /></NavLink>
            ]}/>
            <Row gutter={[12, 12]} className="mb-2">
                <FilterSelectComponent label="faculty" name="faculty_id" permission="faculty_index" url="faculties" child_names={["edu_plan_id"]} />
                <FilterSelectComponent label="edu plan" name="edu_plan_id" permission="edu-plan_index" url="edu-plans" parent_name="faculty_id" />
                <FilterSelectComponent label="course" name="course_id" permission="course_index" url="courses" />
                <FilterSelectComponent label="language" name="edu_lang_id" permission="languages_index" url="languages" />
            </Row>
            {/* <Row gutter={[12, 12]} className="mb-2">
                <Col xl={6}>
                    <Select
                        placeholder={t("Filter by faculty")}
                        allowClear
                        className="w-100"
                        value={value.filter?.faculty_id}
                        onFocus={() => faculties.fetch()}
                        onChange={e => writeToUrl({ name: 'faculty_id', value: e, items: faculties.items })}
                    >
                        {faculties.items?.length ? faculties.items.map((item: { id: number, name: string }, i: number) =>
                            <Option key={i} value={item?.id}>{item?.name}</Option>) :
                            [value.item?.faculty_id].map((item, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>)
                        }
                    </Select>
                </Col>
                <Col xl={6}>
                    <Select
                        placeholder={t("Filter by edu plan")}
                        allowClear
                        className="w-100"
                        value={value.filter?.edu_plan_id}
                        disabled={!value.filter?.faculty_id}
                        onFocus={() => edu_plans.fetch()}
                        onChange={e => writeToUrl({ name: 'edu_plan_id', value: e, items: edu_plans.items })}
                    >
                        {edu_plans.items?.length ? edu_plans.items.map((item: { id: number, name: string }, i: number) =>
                            <Option key={i} value={item?.id}>{item?.name}</Option>) :
                            [value.item?.edu_plan_id].map((item, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>)
                        }
                    </Select>
                </Col>
                <Col xl={6}>
                    <Select
                        placeholder={t("Filter by course")}
                        allowClear
                        className="w-100"
                        value={value.filter?.course_id}
                        onFocus={() => courses.fetch()}
                        onChange={e => writeToUrl({ name: 'course_id', value: e, items: courses.items })}
                    >
                        {courses.items?.length ? courses.items.map((item: { id: number, name: string }, i: number) =>
                            <Option key={i} value={item?.id}>{item?.name}</Option>) :
                            [value.item?.course_id].map((item, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>)
                        }
                    </Select>
                </Col>
                <Col xl={6} className="d-flex">
                    <Select
                        placeholder={t("Filter by language")}
                        allowClear
                        className="w-100"
                        value={value.filter?.edu_lang_id}
                        onFocus={() => languages.fetch()}
                        onChange={e => writeToUrl({ name: 'edu_lang_id', value: e, items: languages.items })}
                    >
                        {languages.items?.length ? languages.items.map((item: { id: number, name: string }, i: number) =>
                            <Option key={i} value={item?.id}>{item?.name}</Option>) :
                            [value.item?.edu_lang_id].map((item, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>)
                        }
                    </Select>
                    <Tag color="#F2F2F2" className="text-dark">Jami {students?.data?._meta?.totalCount} ta</Tag>
                </Col>
            </Row> */}
            <ForTable toUrl={true} setCurrentPage={() => {}} setPerPage={() => {}} totalCount={students.data?._meta?.totalCount} perPage={value.perPage} currentPage={value.currentPage} searchInputs={[
                {
                    index: 1,
                    input:
                    <Input name="sur_name" placeholder="Search by sur name" value={value.filter_like?.sur_name} onChange={handleSearch} allowClear />
                },
                {
                    index: 2,
                    input:
                    <Input name="first_name" placeholder="Search by first name" value={value.filter_like?.first_name} onChange={handleSearch} allowClear />
                },
                {
                    index: 3,
                    input:
                    <Input name="last_name" placeholder="Search by last name" value={value.filter_like?.last_name} onChange={handleSearch} allowClear />
                },
                {
                    index: 4,
                    input:
                    <Input name="username" placeholder="Search by username" value={value.filter_like?.username} onChange={handleSearch} allowClear />
                },
                {
                    index: 5,
                    input:
                    <div className="d-flex">
                        <span className="ant-input-affix-wrapper wp-25">
                            <InputMask mask="aa" placeholder="AA" className='ant-input' name="passport_seria" value={value.filter_like?.passport_seria} onChange={handleSearch} style={{ width: "50px" }} />
                        </span>
                        <span className="ant-input-affix-wrapper wp-85">
                            <InputMask mask="9999999" placeholder="9999999" className='ant-input' name="passport_number" value={value.filter_like?.passport_number} onChange={handleSearch} style={{ width: "100px" }} />
                        </span>
                    </div>
                },
                {
                    index: 6,
                    input:
                    <Input name="passport_pin" placeholder="Search by SHRJ" value={value.filter_like?.passport_pin} onChange={handleSearch} allowClear />
                }
            ]} loading={students.loading} datas={students.data.items} theads={
                    [
                        <>
                            {t('Familiya')}
                        </>,
                        <>
                            {t('Ism')}
                        </>,
                        <>
                            {t('Sharif')}
                        </>,
                        <>
                            {t('Username')}
                        </>,
                        <>
                            {t('Passport seria va raqam')}
                        </>,
                        <>
                            {t('SHRJ')}
                        </>,
                        <div className="w-120px">{t('Phone')}</div>,
                        <>{t('Status')}</>,
                        < >{t("Actions")}</>,
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            isHasAuthRoute("student_view") ? <NavLink to={`/student_view/${this.id}`}>{this?.profile?.last_name}</NavLink> : <>{this?.profile?.last_name}</>,
                            isHasAuthRoute("student_view") ? <NavLink to={`/student_view/${this.id}`}>{this?.profile?.first_name}</NavLink> : <>{this?.profile?.first_name}</>,
                            isHasAuthRoute("student_view") ? <NavLink to={`/student_view/${this.id}`}>{this?.profile?.middle_name}</NavLink> : <>{this?.profile?.middle_name}</>,
                            this?.user?.username,
                            <>{this?.profile?.passport_seria}&nbsp;{this?.profile?.passport_number}</>,
                            this?.profile?.passport_pin,
                            this?.profile?.phone,
                            <StatusChecker status={this?.status} isText="badge" />,
                            <div className="crud_actions_table w_120" >
                                {isHasAuthRoute("student_view") && <Tooltip placement="top" title={t("View")}>
                                    <NavLink to={`/view_student/${this.id}`}>
                                        <TbFileInvoice  className="view_btn" />
                                    </NavLink>
                                </Tooltip>}
                                {isHasAuthRoute("student_update") && <Tooltip placement="top" title={t("Edit")}>
                                    <NavLink to={`/student_update/${this?.id}`}>
                                        <TbFilePencil className="edit_btn" />
                                    </NavLink>
                                </Tooltip>}
                                {isHasAuthRoute("student_delete") &&
                                    <Tooltip placement="top" title={t("Delete")}>
                                        <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`}  okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>} onConfirm={() => _delete.fetch(`/students/${this.id}`)}>
                                            <TbFileOff className="delete_btn" />
                                        </Popconfirm>
                                    </Tooltip>}
                            </div>
                        ]
                    }
                }/>
            {/* <div className="table_bottom_footer">
                <Pagination
                    total={students?.data?._meta?.totalCount}
                    defaultPageSize={value?.perPage}
                    showSizeChanger={false}
                    current={value?.currentPage}
                    onChange={(e: any) => { writeToUrl({ name: 'currentPage', value: e }) }}
                    onShowSizeChange={(e: any, pageSize: any) => { writeToUrl({ name: 'perPage', value: pageSize }) }}
                    pageSizeOptions={['10', '15', '20', '30', '50', '100']}
                />
            </div> */}
            {
                exportModal?.visible &&
                <div className="export_student">
                    <Modal title="Export students!" width="100%" footer={false} visible={exportModal?.visible} onCancel={() => setExportModal(StudentEDD)} wrapClassName="export_student" >
                        <ExcelExport perPage={value.perPage} current={value.currentPage} filter={`&filter=${JSON.stringify(value.filter)}`} excelElements={exportModal} setExcelElements={setExportModal} />
                    </Modal>
                </div>
            }
        </div>
    )
}

export default Students;