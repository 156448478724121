import { FC, useEffect, useState } from "react";
import { useLocation,useHistory } from 'react-router';
import instance from "config/_axios";
import { FaArrowRight, FaRegTimesCircle, FaEdit } from 'react-icons/fa';
import { TbFileInvoice } from "react-icons/tb";
import { asyncN } from "utils/notifications";
import CustomBreadcrumb from "components/Breadcrumb";
import { eduSemestr } from "./type";
import { Badge, Col, Collapse, Divider, Form, List, Modal, Row } from "antd";
import { getAllSubjects } from "../../subjects/service";
import UpdateEduSemestrSubjects from "./update";
import { useTranslation } from "react-i18next";
import ViewSemestrSubjects from "./view";


const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Education Plans",
        path: 'edu_plan_index'
    },
    {
        name: "Education Plan Attach Subjects",
        path: 'edu-plan_create'
    },
];

const { Panel } = Collapse;


const AttachSubject: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const history=useHistory()
    const location: any = useLocation();
    const [data, setData] = useState<Array<eduSemestr>>([]);
    const [subjects, setSubjects] = useState<any>([]);
    const [active, setActive] = useState<string>();
    const [semestrSubjectsList, setSemestrSubjectsList] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [modalElement, setMOdalElement] = useState<{
        visible: boolean, edit: boolean, item: any
    }>({ visible: false, edit: false, item: {} });
    const [refresh, setRefresh] = useState<boolean>(false);
    const [subjectsLista, setSubjectsLista] = useState<Array<{
        eduSemestrId: number,
        subjectsIds: Array<number>
    }>>();

    useEffect(() => {
        (
            async () => {
                setLoading(true)
                if(location?.state?.element){
                    try {
                        const response = await instance({
                            method: 'get',
                            url: `/edu-semestrs?expand=eduPlan,eduSemestrSubjects&filter={"edu_plan_id":${location?.state?.element?.id}}`
                        });
                        if (response?.data?.status === 1) {
                            setData(response?.data?.data?.items);
                            let a = {}, b: any = [];
                            response?.data?.data?.items.map((item: any) => {
                                a = { ...a, [item?.id]: item?.eduSemestrSubjects };
                                let da: any = [];
                                item?.eduSemestrSubjects.map((item1: any) => {
                                    da.push(item1?.subject_id);
                                });
                                b.push({
                                    eduSemestrId: item?.id,
                                    subjectsIds: da
                                })
                            });
                            setSubjectsLista(b);
                            setSemestrSubjectsList(a);
                            const subjects = await getAllSubjects(40, 1, "expand=subjectType");
                            setSubjects(subjects?.items)
                            setLoading(false)
                        } else {
                            asyncN("error", "read", response?.data?.data?.message).then((e:any)=>{
                                history.push("")
                            });
                            setLoading(false)
                        }
                    } catch (e: any) {
                        setLoading(false)
                        asyncN("error", "read", e?.response?.message)
                    }
                }else{
                    history.push("/edu_plan_index")
                }
            }

        )();
    }, [location?.state, refresh]);


    const setSubjectList = async (id: number) => {
        if (active) {
            try {
                let data = new FormData();
                data.append("edu_semestr_id", active);
                data.append("subject_id", "" + id);
                const response = await instance({
                    method: 'post',
                    url: '/edu-semestr-subjects',
                    data: data
                })
                if (parseInt(response?.data?.status) === 1) {
                    setSemestrSubjectsList({
                        ...semestrSubjectsList,
                        [active]: [...semestrSubjectsList[active], response?.data?.data]
                    });

                    setSubjectsLista(subjectsLista?.map((item: {
                        eduSemestrId: number,
                        subjectsIds: Array<number>
                    }) => {
                        if (item.eduSemestrId === parseInt(active))
                            item = {
                                ...item,
                                subjectsIds: [...item.subjectsIds, parseInt(response?.data?.data?.subject_id)]
                            };
                        return item
                    }))


                }
            } catch (e: any) {
                asyncN("error", "create", e?.response?.data?.message)
            }
        }
    };

    const returnSubjectIds = (eduSemestrId: number): any => {
        return subjectsLista?.filter((item) => {
            return item.eduSemestrId === eduSemestrId && item
        })[0].subjectsIds
    }

    const getSubjectName = (id: number): string => {
        return subjects?.filter((item: any) => {
            return item?.id === id && item
        })[0]?.name
    };

    const removeSubjectFromList = async (id: any) => {
        if (active) {
            try {
                const response = await instance({
                    method: 'delete',
                    url: '/edu-semestr-subjects/' + id?.id
                });

                if (response?.data?.status === 1) {
                    setSemestrSubjectsList({
                        ...semestrSubjectsList,
                        [active]: semestrSubjectsList[active].filter((item: any) => {
                            return item.subject_id !== id?.subject_id && item
                        })
                    });

                    setSubjectsLista(subjectsLista?.map((item: {
                        eduSemestrId: number,
                        subjectsIds: Array<number>
                    }) => {
                        if (item.eduSemestrId === parseInt(active))
                            item = {
                                ...item,
                                subjectsIds: item.subjectsIds.filter((ite: number) => {
                                    return ite !== parseInt(id?.subject_id) && ite
                                })
                            };
                        return item
                    }))

                }
            } catch (e: any) {
                asyncN("error", "delete", "Can not read edu semestr subjects")
            }
        }
    };

    return (
        <div>
            <CustomBreadcrumb arr={_breadcrump} />

            <Form form={form}
                layout="vertical"
            // onFinish={onSubmit}
            >
                <Row className="mt-4" gutter={24}>
                    <Col className="border p-2 d-flex align-items-center" sm={24} xs={24} md={12} lg={12}>

                        <p className="d-block p-0 m-0">
                            <strong>
                                {t("Name")}:
                            </strong>
                            <span>
                                {location?.state?.element?.name}
                            </span>
                        </p>
                    </Col>
                    <Col className="border p-2 d-flex align-items-center" sm={24} xs={24} md={12} lg={12}>
                        <p className="d-block p-0 m-0">
                            <strong className="me-1">
                                {t("Description")}:
                            </strong>
                            <span>
                                {location?.state?.element?.description}
                            </span>
                        </p>
                    </Col>
                </Row>
            </Form>
            {loading ? (
                <div className="loader-line"></div>
            ) : (
                <div style={{ height: "3px" }}></div>
            )}
            <Row gutter={24}>
                <Col sm={24} xs={24} md={8} lg={8}>
                    <Divider orientation="left">{t("Subjects")}</Divider>
                    <List
                        size="small"
                        bordered
                        dataSource={subjects}
                        renderItem={(item: any) => <List.Item
                            onClick={() => active && (!returnSubjectIds(parseInt(active)).includes(item?.id)) ? setSubjectList(item?.id) : ""}
                            style={{
                                transition: "600ms ease",
                                cursor: active && (!returnSubjectIds(parseInt(active)).includes(item?.id)) ? 'pointer' : '',
                                backgroundColor: active && (!returnSubjectIds(parseInt(active)).includes(item?.id)) ? "#bae637" : "#ff4d4f",
                                // backgroundColor: active && (!returnSubjectIds(parseInt(active)).includes(item?.id)) ? "#73d13d" : "#ff4d4f",
                                color: active && (!returnSubjectIds(parseInt(active)).includes(item?.id)) ? "#434343" : "#ffffff"
                            }}
                        >
                            {item?.name}<FaArrowRight
                                className="float-end" />
                        </List.Item>}
                    />
                </Col>
                <Col sm={24} xs={24} md={16} lg={16}>
                    <Divider orientation="left">{t("Semesters")}</Divider>
                    <Collapse onChange={(e: any) => {
                        setActive(e);
                    }} accordion>
                        {
                            data && data.map((item: eduSemestr, i) =>
                                <Panel header={<span>
                                    <span>
                                        {
                                            item?.name
                                        }
                                    </span>
                                    {active && item?.id === parseInt(active) && <span className="float-end me-5">
                                        <Badge
                                            className="float-start ms-3"
                                            color={'cyan'}
                                            text={t("Total score")}
                                        />
                                        <Badge
                                            className="float-start ms-3"
                                            color={'gold'}
                                            text={t("Total hours")}
                                        />
                                    </span>}
                                </span>} key={item.id}>
                                    <List
                                        size="small"
                                        dataSource={semestrSubjectsList && semestrSubjectsList[item?.id] ? semestrSubjectsList[item?.id] : null}
                                        renderItem={(it: any) =>
                                            <List.Item>
                                                {getSubjectName(parseInt(it.subject_id))}


                                                <span className="float-end">
                                                    <FaRegTimesCircle
                                                        onClick={() => removeSubjectFromList(it)}
                                                        className="float-end text-danger m-1"
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                    <FaEdit
                                                        onClick={() => setMOdalElement({
                                                            visible: true,
                                                            edit: true,
                                                            item: it
                                                        })}
                                                        className="float-end text-primary m-1"
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                    <TbFileInvoice 
                                                        onClick={() => setMOdalElement({
                                                            visible: true,
                                                            edit: false,
                                                            item: it
                                                        })}
                                                        className="float-end text-primary m-1"
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                </span>
                                                <span className="float-end me-5">
                                                    {it.max_ball > 0 && <Badge
                                                        className="float-start m-1"
                                                        color={'cyan'}
                                                        text={it?.max_ball}
                                                    />}
                                                    {it.all_ball_yuklama > 0 && <Badge
                                                        className="float-start m-1"
                                                        color={'gold'}
                                                        text={it.all_ball_yuklama}
                                                    />}
                                                </span>
                                            </List.Item>}
                                    />
                                </Panel>
                            )
                        }
                    </Collapse>

                </Col>
            </Row>
            <Modal width={modalElement?.edit?1300:600} title={modalElement?.edit ? "Update semestr subject" : "View semestr subject"}
                footer={false} visible={modalElement?.visible}
                onCancel={() => setMOdalElement({ visible: false, edit: false, item: {} })}>
                {modalElement?.item&&modalElement?.edit ? <UpdateEduSemestrSubjects
                    visible={modalElement?.visible}
                    element={modalElement}
                    edit={modalElement?.edit}
                    subjectName={getSubjectName(modalElement?.item?.subject_id) && getSubjectName(modalElement?.item?.subject_id)}
                    item={modalElement?.item}
                    setElement={setMOdalElement}
                    refresh={refresh}
                    setRefresh={setRefresh}
                /> : <ViewSemestrSubjects
                    visible={modalElement?.visible}
                    element={modalElement}
                    edit={modalElement?.edit}
                    subjectName={getSubjectName(modalElement?.item?.subject_id) && getSubjectName(modalElement?.item?.subject_id)}
                    item={modalElement?.item}
                    setElement={setMOdalElement}
                    refresh={refresh}
                    setRefresh={setRefresh} />}
            </Modal>
        </div>
    )
}

// @ts-ignore
export default AttachSubject
