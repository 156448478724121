import { FaUsers, FaGraduationCap, FaBriefcase, FaBookReader, FaChalkboardTeacher } from 'react-icons/fa';
import "../style.scss"
import { useTranslation } from "react-i18next";
import PizzaChart from '../common/charts/pizzaChart';
import { Card, List, Avatar, Table } from 'antd';
import { useState, useEffect } from 'react';
import useGetAllData from 'hooks/useGetAllData';
import { columns } from '../RectorDashboard/statistic_pages/student_statistic/utils';
import { Chart, Interval, Tooltip } from 'bizcharts';
import StudentStatisticByRegion from './student_statistic_by_region';
import MapOfUzbekistan from './map_of_uzb';
import GenderStatistic from './genderStat';
import YearStats from './yearStats';
import ForTitle from 'components/ForTitles';
import { Row, Col } from 'antd';
import { MdPeopleOutline } from 'react-icons/md';
import { GiTeacher } from 'react-icons/gi';
import { GrUserWorker } from 'react-icons/gr';


const data2: any = [
    // {
    //     title: 'User 1',
    //     description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit."
    // },
    // {
    //     title: 'User 2',
    //     description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit."
    // },
    // {
    //     title: 'User 3',
    //     description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit."
    // },
    // {
    //     title: 'User 4',
    //     description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit."
    // },
    // {
    //     title: 'User 5',
    //     description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit."
    // },
    // {
    //     title: 'User 6',
    //     description: " Lorem ipsum dolor, sit amet consectetur adipisicing elit."
    // }
];

const data3: any = [
    // { item: "56-70 ball", percent: 0.60 },
    // { item: "71-85 ball", percent: 0.30 },
    // { item: "86-100 ball", percent: 0.10 },
];

const AdminPanel: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const [allStd, setAllStd] = useState<number>(0);
    const students = useGetAllData({ url: '/statistics/student-count-by-faculty?expand=studentsCountByGender', isCall: 'auto' });
    const teachers = useGetAllData({ url: 'users?sort=-id&filter={%22-role_name%22:[%22student%22],%22role_name%22:%22teacher%22}&filter-like={}&per-page=15&page=1', isCall: 'auto'})
    const masters = useGetAllData({ url: 'users', isCall: 'auto'})

    return (
        <>
            <ForTitle title="Dashboard" buttons={[null]}/>
            <Row className='d-flex justify-content-between'>
                <Col lg={24} sm={24} md={24} xl={12} xs={24}>
                    <div className='forBorder mx-1'>
                        <Card bordered={true}>
                            <p className="text-truncate mb-2">{t("Students")}</p>
                            <h4 className="mb-0">{allStd}</h4>
                            <MdPeopleOutline className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.23% </span>
                            </div> */}
                        </Card>
                        <Card bordered={true}>
                            <p className="text-truncate mb-2">{t("Teachers")}</p>
                            <h4 className="mb-0">{teachers.data._meta.totalCount}</h4>
                            <GiTeacher className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.03% </span>
                            </div> */}
                        </Card>
                        <Card bordered={true}>
                            <p className="text-truncate mb-2">{t("Masters")}</p>
                            <h4 className="mb-0">{masters.data._meta.totalCount}</h4>
                            <GrUserWorker className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.05% </span>
                            </div> */}
                        </Card>
                    </div>
                </Col>
                <Col lg={24} sm={24} md={24} xl={12} xs={24} className='mb-3'>
                    <Card className='mx-1'>
                        <div className="row">
                            <div className="col-12">
                                <h4>
                                    Yillik natijalar
                                </h4>
                            </div>
                            <div className="col-12">
                                <YearStats _students={students} setAllStd={setAllStd}/>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col lg={24} sm={24} md={24} xl={12} xs={24}>
                    <Card className='mx-1'>
                        <h5 className='mb-3'>Hudud bo'yicha talabalar soni</h5>
                        <StudentStatisticByRegion students={students} />
                        {/* <div className="row">
                            <div className="col-12">
                                <h4>
                                    Yillik talabalar soni
                                </h4>
                            </div>
                            <div className="col-12  mt-5">
                                <LineChart data={data1} />
                            </div>
                        </div> */}
                    </Card>
                </Col>
                <Col lg={24} sm={24} md={24} xl={12} xs={24}>
                    <Card style={{ height: '100%'}} className='mx-1'>
                        <List
                            header={<h4>Murojaatlar</h4>}
                            itemLayout="horizontal"
                            dataSource={data2}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                        title={<a href="#">{item.title}</a>}
                                        description={item?.description}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AdminPanel;