import { Row, Col, Form, Input, Select, FormInstance, Divider, Upload, Button } from "antd"
import FormLabelCrud from "components/FormLabel/FormLabelCrud"
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData"
import { IArea, IAreaStatus, ICohabitant, ICountry, IRegion } from "models/others"
import InputMask from 'react-input-mask';
import { useTranslation } from "react-i18next"
import React from "react";
import { useAppSelector } from "store/services";
import { useLocation } from "react-router-dom";


const { Option } = Select;

type PropsTypeAdditionalInfo = {
    form: FormInstance<any>,
    onSubmit?: (values: Record<string | number, any>) => void
}

let ds: any = null;

const AdditionalInfos: React.FC<PropsTypeAdditionalInfo> = ({ form, onSubmit }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const { activeTabKey, passportFile, info } = useAppSelector(state => state.student);

    const countries = useGetAllData({ url: '/countries', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<ICountry>;
    const regions = useGetAllData({ url: `/regions?filter=${JSON.stringify({ country_id: form.getFieldValue('country_id') })}`, isCall: 'auto' }) as TypeReturnUseGetAllData<IRegion>;
    const areas = useGetAllData({ url: `/areas?filter=${JSON.stringify({ region_id: form.getFieldValue('region_id') })}`, perPage: 0 }) as TypeReturnUseGetAllData<IArea>;

    const area_status = useGetAllData({ url: "/residence-statuses", perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<IAreaStatus>;
    const student_partners = useGetAllData({ url: "/category-of-cohabitants", perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<ICohabitant>;
    const social_category = useGetAllData({ url: "/social-categories", perPage: 0, isCall: 'auto' });

    const isRequired = React.useCallback(() => activeTabKey === '2', [activeTabKey])

    React.useMemo(() => {
        if (activeTabKey === '2') {
            if (!area_status.items.length) {
                area_status.fetch();
            }
            if (!student_partners.items.length) {
                student_partners.fetch();
            }
            if (!social_category.items.length) {
                social_category.fetch();
            }
        }
    }, [])

    return (
        <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
        >
            <Row gutter={[12, 0]} className="custom_form_style">
                <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Divider className='mt-1 mb-2' orientation="left" orientationMargin="left">{t("Aloqa ma'lumotlari")}</Divider>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Email</FormLabelCrud>}
                        name="email"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Second phone number</FormLabelCrud>}
                        name="phone_secondary"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <InputMask mask="+\9\98 (99) 999 99 99" className='ant-input' placeholder='+998 (90) 999 99 99' />
                    </Form.Item>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} className="pt-2 mb-2">
                    <Divider className='mt-1 mb-0' orientation="left" orientationMargin="left">{t("Current address")}</Divider>
                </Col>
                <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Country</FormLabelCrud>}
                        name="country_id"
                        rules={[{ required: isRequired(), message: `Please select a region !` }]}
                    >
                        <Select
                            onFocus={() => countries.fetch()}
                            onChange={(e: number) => {
                                form.setFieldsValue({
                                    region_id: undefined,
                                    area_id: undefined,
                                    address: undefined
                                })
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            loading={countries.loading}
                            allowClear
                        >
                            {
                                countries.items.length ? countries.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Region</FormLabelCrud>}
                        name="region_id"
                        rules={[{ required: isRequired(), message: t("Fill in the field!") }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={e => { form.setFieldsValue({ area_id: undefined, address: undefined }); }}
                            onFocus={() => regions.fetch()}
                            loading={regions.loading}
                            allowClear
                        >
                            {
                                regions.items.length ? regions.items.map((e: any, i: number) => {
                                    return <Option key={i} value={e.id}>{e.name}</Option>
                                }) : null
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Area</FormLabelCrud>}
                        name="area_id"
                        rules={[{ required: isRequired(), message: t("Fill in the field!") }]}
                    >
                        <Select
                            showSearch
                            onChange={() => form.setFieldsValue({ address: undefined })}
                            optionFilterProp="children"
                            onFocus={() => areas.fetch()}
                            disabled={!regions.items.length}
                            loading={areas.loading}
                            allowClear
                        >
                            {
                                areas.items.length ?
                                    areas.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) :
                                    info?.area ? [info.area].map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Address</FormLabelCrud>}
                        name="address"
                        rules={[{ required: isRequired(), message: t("Fill in the field!") }]}
                    >
                        <Input.TextArea rows={1} />
                    </Form.Item>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Divider className='mt-1 mb-2' orientation="left" orientationMargin="left">{t("Tugallagan ta'lim ma'lumotlari")}</Divider>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Old educational institution name</FormLabelCrud>}
                        name="last_education"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <div className="text-end">
                <Button type="primary" htmlType='submit' className='px-4' disabled={!location.pathname.includes('/student_update')}  >{t("Save")}</Button>
            </div>
        </Form>
    )

}


export default AdditionalInfos;
