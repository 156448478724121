import { Button, Col, Form, Input, Modal, Row, Tag, Tabs } from 'antd';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowDown, FaArrowUp, FaFile, FaFilm, FaImage, FaMusic, FaTextWidth } from 'react-icons/fa';
import { TbFileOff } from "react-icons/tb";
import { useHistory } from 'react-router-dom';
import { getSubjectContentTypes } from '../service';
import styles from './style.module.scss';
import { Displaying } from './displayType';
import './style.scss'
import { HiOutlinePresentationChartLine } from 'react-icons/hi';
import Card from 'antd/lib/card';


type formType = {
    id: number,
    type: string,
    form: any,
    maxSize?: number,
    acceptTypes?: string,
}

type propsType = { savedForms?: Array<string>, form: any, disabledFinish: boolean, fileKey?: string, files: Array<any>, setFiles: (files: Array<any>) => void, content?: string, setFileKey: (fileKey: string) => void }
const FeildsUi: FC<propsType> = ({ savedForms, form, disabledFinish, files, content, setFiles, fileKey, setFileKey }): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()
    const boxRef: any = useRef();
    const [fileTypes, setFileTypes] = useState<any>({});
    const [forms, setForms] = useState<Array<formType>>([]);
    const [fileUrl, setFileUrl] = useState<any>({});
    const [fileFile, setFileFile] = useState<any>({})
    const [type, setType] = useState<string>('TEXT')
    const [forma, setForma] = useState<{id?: number, type?: string, form?: any, maxSize?: number, acceptTypes?: string}>({})

    useEffect(() => {
        (
            async () => {
                const resp = await getSubjectContentTypes();
                setFileTypes(resp)

            }
        )()
    }, [])

    useEffect(() => {
        if(type !== ''){
            if(type == 'TEXT'){
                setForma(
                    {
                        id: 1,
                        type: type,
                        form: form
                    }
                )
                form?.setFieldsValue({
                    [`order-${forma?.id ? forma.id + 1 : null}`]: 1
                })
            } else {
                let a: any = Object.entries(fileTypes)?.filter(([key, value]: any) => value.includes(type))["0"]
                setForma(
                    {
                        id: 1,
                        type: type,
                        form: form,
                        maxSize: a[1][1],
                        acceptTypes: a[1][2]
                    }
                )
                form?.setFieldsValue({
                    [`order-${forma?.id ? forma.id + 1 : null}`]: 1
                })
            }
        }
        
    }, [type])

    const addContentByType = async (type: "TEXT" | "FILE" | "IMAGE" | "VIDEO" | "AUDIO") => {
        if (forms?.length > 0) {
            if (type === "TEXT") {
                setForms([
                    ...forms,
                    {
                        id: forms[forms?.length - 1]?.id + 1,
                        type: type,
                        form: form,
                    }
                ])
                form?.setFieldsValue({
                    [`order-${forms[forms?.length - 1]?.id + 1}`]: forms?.length + 1
                })
            } else {
                let a: any = Object.entries(fileTypes)?.filter(([key, value]: any) => value.includes(type))["0"]

                setForms([
                    ...forms,
                    {
                        id: forms[forms?.length - 1]?.id + 1,
                        type: type,
                        form: form,
                        maxSize: Number(a[1][1]),
                        acceptTypes: a[1][2]
                    }
                ])
                form?.setFieldsValue({
                    [`order-${forms[forms?.length - 1]?.id + 1}`]: forms?.length + 1
                })
            }
        } else {
            if (type === "TEXT") {
                setForms([
                    {
                        id: 1,
                        type: type,
                        form: form
                    }
                ])
                form?.setFieldsValue({
                    [`order-${forms[forms?.length - 1]?.id + 1}`]: forms?.length + 1
                })
            } else {
                let a: any = Object.entries(fileTypes)?.filter(([key, value]: any) => value.includes(type))["0"]
                setForms([
                    {
                        id: 1,
                        type: type,
                        form: form,
                        maxSize: a[1][1],
                        acceptTypes: a[1][2]
                    }
                ])
                form?.setFieldsValue({
                    [`order-${forms[forms?.length - 1]?.id + 1}`]: forms?.length + 1
                })
            }
        }

    }

    const removeParentItem = async (id: number) => {
        let a: any = {};
        let formN = forms?.filter((item: formType) => item?.id === id)[0];
        let keyProp: string = `${id}-${getType(formN?.type)}`
        Object.entries(fileUrl)?.forEach(([key, value]: any) => {
            if (key !== keyProp) {
                a = { ...a, [`${key}`]: value }
            }
        })
        setFileUrl(a);
        setForms(forms?.filter((item: formType) => item?.id !== id));
    }


    const getType = (type: string): string => {
        switch (type) {
            case "FILE": return "file";
            case "VIDEO": return "video";
            case "AUDIO": return "audio";
            case "IMAGE": return "image";
            default: return ""
        }
    }
    const typeNames: any = {
        "FILE": "Prezentatsiya",
        "VIDEO": "Video",
        "AUDIO": "Audio",
        "IMAGE": "Rasmlar",
        "TEXT": "Qo'shimcha materiallar(Ma'ruza matni)",
    }

    return (
        <Row gutter={24}>
            <Col span={24}>
                <div className={styles.content}>
                    {
                        savedForms && 
                        <Tabs onTabClick={(key) => {
                            if(key == 'ANY') return setType("FILE")
                            return setType(key)
                        }} defaultActiveKey="TEXT">
                            <Tabs.TabPane tab={<>+ <FaTextWidth /> Ma'ruza matni</>} key="TEXT"/>
                            <Tabs.TabPane tab={<>+ <HiOutlinePresentationChartLine /> Taqdimot</>} key="FILE"/>
                            <Tabs.TabPane tab={<>+ <FaFilm /> Video</>} key="VIDEO"/>
                            <Tabs.TabPane tab={<>+ <FaMusic /> Audio</>} key="AUDIO"/>
                            <Tabs.TabPane tab={<>+ <FaImage /> Rasmlar</>} key="IMAGE"/>
                            <Tabs.TabPane tab={<>+ <FaFile /> Qo'shimcha materiallar</>} key="ANY"/>
                        </Tabs>
                    }
                    {
                    // savedForms && <Form name='sava_all_form' className={`${styles.content_header} mt-2 d-f justify-content-start`}>
                    //     <button type='button' className={styles.add_btn} onClick={() => setType("TEXT")}>+ <FaTextWidth /> Ma'ruza matni</button>
                    //     <button type='button' className={styles.add_btn} onClick={() => setType("FILE")}>+ <HiOutlinePresentationChartLine /> Taqdimot</button>
                    //     <button type='button' className={styles.add_btn} onClick={() => setType("VIDEO")}>+ <FaFilm /> Video</button>
                    //     <button type='button' className={styles.add_btn} onClick={() => setType("AUDIO")}>+ <FaMusic /> Audio</button>
                    //     <button type='button' className={styles.add_btn} onClick={() => setType("IMAGE")}>+ <FaImage /> Rasmlar</button>
                    //     <button type='button' className={styles.add_btn} onClick={() => setType("FILE")}>+ <FaFile /> Qo'shimcha materiallar</button>
                    // </Form>
                    }
                    <div ref={boxRef} className="all_card_box" style={{ maxHeight: window.innerHeight * 0.80 + "px", overflow: "auto", width: "100%" }} >
                        {
                        // forms?.map((item: formType, i: number) => {
                        //     return (
                        //         <Row style={{ margin: 0 }} gutter={24} key={i}>
                        //             <Col span={24} className="d-f align-items-start mt-2">
                        //                 {i + 1}.&nbsp;
                        //                 <Form form={form} className='w-100 p-1' name={`form-${item?.id}`} layout='vertical'>
                        //                     <Card title={
                        //                     <div className={`d-f justify-content-between ${styles.controller}`}>
                        //                         <div>
                        //                             {typeNames[item?.type]}
                        //                         </div>
                        //                         <div>
                        //                             {/* <button disabled={i === 0 ? true : false}><FaArrowUp /></button>
                        //                             <button disabled={i === forms.length - 1 ? true : false}><FaArrowDown /></button> */}
                        //                             <button onClick={() => removeParentItem(item?.id)}>{t('Delete')}</button>
                        //                             <button disabled={savedForms && savedForms?.includes(`form-${item?.id}`)} type='submit'>{t("Save")}</button>
                        //                         </div>
                        //                     </div>
                        //                 }>
                        //                         <Displaying
                        //                             maxSize={item?.maxSize ? item?.maxSize : 0}
                        //                             accept={item?.acceptTypes ? "." + item?.acceptTypes.replaceAll(",", ",.") : ""}
                        //                             typeFile={item?.type} urlFile={fileUrl}
                        //                             setFileUrl={setFileUrl}
                        //                             id={item?.id}
                        //                             setFileFile={setFileFile}
                        //                             fileFile={fileFile}
                        //                         />
                        //                     </Card>
                        //                 </Form>
                        //             </Col>
                        //         </Row>
                        //     )
                        // })
                        }
                        <Row style={{ margin: 0 }} gutter={24}>
                            <Col span={24} className="d-f align-items-start mt-2">
                                <Form form={form} className='w-100 p-1' name={`form-${forma?.id}`} layout='vertical'>
                                    <Card title={
                                    <div className={`d-f justify-content-between ${styles.controller}`}>
                                        <div>
                                            {typeNames[forma?.type ? forma.type : '']}
                                        </div>
                                        <div>
                                            {/* <button disabled={i === 0 ? true : false}><FaArrowUp /></button>
                                            <button disabled={i === forms.length - 1 ? true : false}><FaArrowDown /></button> */}
                                            <button onClick={() => removeParentItem(forma?.id ? forma.id : 1)}>{t('Delete')}</button>
                                            <button disabled={savedForms && savedForms?.includes(`form-${forma?.id}`)} type='submit'>{t("Save")}</button>
                                        </div>
                                    </div>
                                }>
                                        <Displaying
                                            maxSize={forma?.maxSize ? forma?.maxSize : 0}
                                            accept={forma?.acceptTypes ? "." + forma?.acceptTypes.replaceAll(",", ",.") : ""}
                                            typeFile={forma?.type ? forma?.type : 'nothing'} urlFile={fileUrl}
                                            setFileUrl={setFileUrl}
                                            id={forma?.id ? forma?.id : -1}
                                            setFileFile={setFileFile}
                                            fileFile={fileFile}
                                        />
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Modal >

                </Modal>
            </Col>
        </Row>
    )
}

export default FeildsUi