import { ChangeEvent, FC, useEffect, useState } from "react";
import { Row, Col, Button, Pagination, Input, Switch, Tag } from "antd";
import BadgeStatus from "components/BadgeStatus";
import { getAllSillabuses, getOneEduSemester } from "../service";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";
import instance from "config/_axios";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import EmptyTable from "components/EmptyTable";
import { useHistory, useLocation } from "react-router-dom";
import { ArrayChecker } from "utils/ArrayChecker";
import CustomBreadcrumb from "components/Breadcrumb";
import { FaCheckCircle } from "react-icons/fa";
import { ImRadioUnchecked } from "react-icons/im";
import { delete_data } from "services";
import useDebounce from "hooks/useDebounce";
import { getAllSubjectTypes } from "pages/subject_type/service";
import DiscriptionStyle from "components/DescriptionStyle";
import ForTable from "components/ForTable";

const { Search } = Input;

type sillabusType = {
  all_ball_yuklama: number | null,
  credit: number | null
  edu_semestr_exams_types: any
  edu_semestr_subject_category_times: any
  id: number
  max_ball: number | null
  status: number
  subject_id: number
  subject_type_id: number
}

const EduSemestrSubjectCreate: FC = (): JSX.Element => {

  const [num, setNum] = useState(-1)

  const { i18n, t } = useTranslation();
  const location: any = useLocation();
  const history = useHistory();
  const [data, setData] = useState<any>(null);
  const [subjects, setSubjects] = useState<Array<any>>([])
  const [tableOption, setTableOption] = useState<any>([]);
  const [eduSemestrSubject, setEduSemestrSubject] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [attach, setAttach] = useState<boolean>(false);
  const [sillabuses, setSillabuses] = useState<any>();
  const [text, setText] = useState<string>('');
  const debouncedValue = useDebounce<string>(text, 500)
  const [subjectTypes, setSubjectTypes] = useState<Array<any>>([]);
  const parentPath: Array<{name: string, path: string}> = location.state?.parent_path?.length ? location.state?.parent_path : [];

  const _breadcrump = [
    {
      name: "Home",
      path: '/',
    },
     ...parentPath,
    {
      name: "Attach subject to edu semester",
      path: 'edu-semestr-subject_create'
    },
  ];

  let a: Array<string> = location.pathname?.split("/");
  let id = a[a?.length - 1]

  useEffect(() => {
    if (id) {
      (
        async () => {
          try {
            const response = await getOneEduSemester(Number(id), 'expand=createdBy,updatedBy,description,eduYear,eduType,eduPlan,semestr,course,eduSemestrSubjects');
            setData(response);
            setEduSemestrSubject(response?.eduSemestrSubjects);

            const sillabus = await getAllSillabuses();
            setSillabuses(sillabus?.items)

            const subtype = await getAllSubjectTypes();
            setSubjectTypes(subtype?.items);

          } catch (error: any) {
            asyncN("error", "read", error?.response?.message)
          }
        }
      )();
      (
        async () => {
          try {
            setLoading(true);
            const subjects = await instance({
              url: `/subjects?expand=description&q=${debouncedValue}`,
              method: "GET",
              params: { "per-page": perPage, page: currentPage }
            });

            setSubjects(subjects?.data?.data?.items);
            setTableOption(subjects?.data?.data?._meta)
            setLoading(false);

          } catch (error: any) {
            asyncN("error", "read", error?.response?.message)
          }
        }
      )()
    }
  }, [i18n.language, perPage, currentPage, attach, debouncedValue]);

  if (!id) {
    history.goBack();
  }

  const onSubmit = async (id: any) => {
    setLoading(true)
    if (location.state?.id) {
      try {
        const formdata = new FormData();
        formdata.append('edu_semestr_id', '' + location.state?.id);
        formdata.append('subject_id', String(id));

        const res = await instance({
          url: `/edu-semestr-subjects`,
          method: 'post',
          data: formdata,
        })
        if(res?.data?.status === 1){
          setAttach(!attach);
          setLoading(false);
          asyncN("success", "update", "Attached subject");
        }

      } catch (error: any) {
        asyncN("error", "read", error?.response?.message);
      }
    }
  }

  const remoteSubject = async (id: any) => {
    let subjectId: any;
    data?.eduSemestrSubjects?.forEach((item: any) => {
      if (item?.subject_id == id) {
        subjectId = item?.id;
      }
    });
    setLoading(true);
    const res = await delete_data("edu-semestr-subjects", subjectId).then(() => {
      setAttach(!attach)
      setLoading(false)
    })
  }

  const getSubjectSillabus = (id: number | string): any => {
    let a = sillabuses?.filter((item: sillabusType) => {
      return id === item?.subject_id && item
    });
    return a?.length > 0 ? a[0] : false;
  };

  const attachSubject = (check: boolean, id: number) => check ? onSubmit(id) : remoteSubject(id);

  const checkSubject = (id: number) => eduSemestrSubject?.filter((item) => item?.subject_id === id).length > 0 ? true : false;

  return (
    <>
      <Row>
        <Col span={24}>
          <CustomBreadcrumb arr={_breadcrump} />
        </Col>

      </Row>
      <hr />
      <Row className="w-100">
        <Col span={12} className="my-3 "> <h6>{t("Edu year") + `: ${data?.name?.split("-")[0]}-${data?.name?.split('-')[1]}`} &nbsp; {t("Course") + `: ${data?.name?.split("-")[2]}`}&nbsp; {t("Semestr")+`: ${data?.name?.split("-")[3]}` }</h6> </Col>
        <Col span={12} className="d-f justify-content-end" >
          <Search allowClear placeholder={`${t("Input search text")} ...`} value={text}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)} style={{ width: 300 }} />
        </Col>
      </Row>
      {
        ArrayChecker(subjects)._ ? <ForTable totalCount={tableOption?.totalCount} setCurrentPage={setCurrentPage} currentPage={currentPage} setPerPage={setPerPage} perPage={perPage} datas={subjects} theads={[
          <>{t("Name")}</>,
          <>{t("Description")}</>,
          <>{t("Subject type")}</>,
          <>{t("Credit")}</>,
          <>{t("Total score")}</>,
          <>{t("Total hours")}</>,
          <>{t("Status")}</>,
          <>{t("Attach")}</>,
        ]} tbodys={function(this: any){
          return [
            <>{this.name} &nbsp;({this.semestr_id}-sm)</>,
            <div style={{ maxWidth: 320 }} ><DiscriptionStyle>{this?.description}</DiscriptionStyle></div>,
            <>{Number(getSubjectSillabus(this?.id)?.subject_type_id) ? subjectTypes.map((item) => {
              if (item?.id === getSubjectSillabus(this?.id)?.subject_type_id) return item?.name
            }) : null}</>,
            <>{getSubjectSillabus(this?.id)?.credit && getSubjectSillabus(this?.id)?.credit}</>,
            <>{getSubjectSillabus(this?.id)?.max_ball && getSubjectSillabus(this?.id)?.max_ball}</>,
            <>{getSubjectSillabus(this?.id)?.all_ball_yuklama && getSubjectSillabus(this?.id)?.all_ball_yuklama}</>,
            <BadgeStatus status={this.status}/>,
            <Switch size="small" checked={checkSubject(this?.id)} defaultChecked={checkSubject(this?.id)} onChange={(e) => { attachSubject(e, this?.id)}} />
            
            // eduSemestrSubject && eduSemestrSubject?.filter((item) => item?.subject_id === element?.id).length > 0 ?
            //   ( <Button size="small"  type="link" onClick={() => deleteSubjectFromEduSemestr(element?.id)} > <FaCheckCircle color="green" /> &nbsp; <span className="text-decoration-underline" >{t("Attached")}</span> </Button>) :
            //   <Button size="small" type="link" onClick={() => onSubmit(element?.id)} > <ImRadioUnchecked color="red" /> &nbsp; <span className="text-decoration-underline" >{t("Attach")}</span> </Button>
          ]
        }} loading={loading}/>
        : null
      }
    </>
  )
}


export default EduSemestrSubjectCreate;