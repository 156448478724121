import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router';




const Documentation = () => {

    const history = useHistory();




    return (
        <>
            <div>
                <h5><FaArrowLeft fontSize={14} style={{ cursor: "pointer" }} onClick={() => history.goBack()} /> &nbsp;&nbsp; Documentation</h5>
                <hr />
               
            </div>
        </>
    )
}

export default Documentation;