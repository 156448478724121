import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const LivingSpaceStatus: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="residence-statuses"
                indexTitle="Hudud holati"
                viewTitle="Yashash hududi holati"
                editTitle="Yashash hududi holatini tahrirlash"
                createTitle="Yashash hududi holatini qo'shish"
                permissions={{
                    view_: "residence-status_view",
                    delete_: "residence-status_delete",
                    update_: "residence-status_update",
                    create_: "residence-status_create",
                }}
            />
        </div>
    )
}

export default LivingSpaceStatus;
