import React from "react";
import { Row, Col, Form, FormInstance, Input, Button } from "antd"
import FormLabelCrud from "components/FormLabel/FormLabelCrud"
import InputMask from 'react-input-mask';
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom";

type PropsTypeParentsInfo = {
    form: FormInstance<any>,
    onSubmit?: (values: Record<string | number, any>) => void
}

const ParentsInfos: React.FC<PropsTypeParentsInfo> = ({ form, onSubmit }) => {

    const { t } = useTranslation();
    const location = useLocation();



    return (
        <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
        >
            <Row gutter={[12, 0]} className="custom_form_style">
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Father F.I.O</FormLabelCrud>}
                        name="father_name"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Father phone number</FormLabelCrud>}
                        name="father_phone"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <InputMask mask="+\9\98 (99) 999 99 99" className='ant-input' placeholder='+998 (90) 999 99 99' />
                    </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Father info</FormLabelCrud>}
                        name="father_info"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Mather F.I.O</FormLabelCrud>}
                        name="mather_name"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Mather phone number</FormLabelCrud>}
                        name="mather_phone"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <InputMask mask="+\9\98 (99) 999 99 99" className='ant-input' placeholder='+998 (90) 999 99 99' />
                    </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label={<FormLabelCrud>Mather info</FormLabelCrud>}
                        name="mather_info"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="text-end">
                <Button type="primary" htmlType='submit' className='px-4' disabled={!location.pathname.includes('/student_update')}  >{t("Save")}</Button>
            </div>
        </Form>
    )

}


export default ParentsInfos;
