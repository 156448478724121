import { Menu } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AiFillFolderOpen,
  AiOutlineFolder,
  AiOutlineFolderOpen,
} from "react-icons/ai";
import { BsCircle, BsRecordCircle } from "react-icons/bs";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RoutesTypeChildren } from "routes/types";
import { filterLinksForSidebar } from "utils/auxiliary_functions";
import IconComponent from "../iconComponent";
import './style.scss'

const SidebarMenu: FC<{ windowSize: number; collapce: boolean }> = ({
  windowSize,
  collapce,
}): JSX.Element => {
  const { t } = useTranslation();
  const [keys, setKeys] = useState<{ selected: string; open: string }>({
    selected: "/",
    open: "",
  });
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let a: Array<string> = location?.pathname?.split("/");
    let select = `/${a[1]}`;
    let openL: string = `${filterLinksForSidebar()?.findIndex(
      (item: RoutesTypeChildren) =>
        item?.submenu?.map((subMenu: any) => subMenu?.path)?.includes(select)
    )}`;
    setKeys({ selected: select, open: openL });
  }, [location?.pathname, collapce, windowSize]);

  useEffect(() => {
    if (collapce && windowSize < 420) {
      setKeys({ open: "", selected: "" });
    }
  }, [collapce, windowSize]);

  return (
    <Menu
      className="sidebar-scroll-style"
      mode="inline"
      selectedKeys={[keys?.selected]}
      openKeys={[keys?.open]}
      style={{
        height: window.innerHeight - 205,
        overflowY: 'auto',
        backgroundColor: collapce ? "rgb(13,138,114)" : "",
      }}
    >
      {filterLinksForSidebar()?.map((item: RoutesTypeChildren, i: number) => {
        if (
          (!item.submenu.length && item.component !== "") ||
          (item.submenu.length && item.component === "")
        ) {
          if (!item.submenu.length) {
            return (
              <Menu.Item
                // onClick={() => {
                //   history.push(item?.path);
                // }}
                style={{
                  color: collapce ? "white" : "rgb(52,157,137)",
                  fontSize: "17px",
                }}
                key={item?.path}
                icon={IconComponent(item.config.icon, { fontSize: "20px" })}
              >
                <Link to={item?.path}>
                  {t(item?.name)}
                </Link>
              </Menu.Item>
            );
          } else {
            return (
              <Menu.SubMenu
                key={`${i}`}
                onTitleClick={() => {
                  if (keys?.open == `${i}`) {
                    setKeys({
                      ...keys,
                      open: ``,
                    });
                  } else {
                    setKeys({
                      ...keys,
                      open: `${i}`,
                    });
                  }
                }}
                style={{
                  color: collapce ? keys.open === i.toString() ? "rgb(52,157,137)" : "white" : "rgb(52,157,137)",
                  fontSize: "17px",
                  background: collapce ? keys.open === i.toString() ? '#fff' : undefined : undefined
                }}
                title={
                  <div className="d-f justify-content-between">
                    <div>{t(item?.name)}</div>
                    <div>
                      {keys?.open == `${i}` ? (
                        <AiFillFolderOpen size={18} />
                      ) : (
                        <AiOutlineFolder size={18} />
                      )}
                    </div>
                  </div>
                }
                icon={IconComponent(item.config.icon, {
                  fontSize: collapce ? "23px" : "20px",
                })}
              >
                { /* windowSize > 740 ? item.submenu.map((subItem: any, j: number) => {
                  return (
                    <Menu.Item
                      // onClick={() => {
                      //   history.push(subItem?.path);
                      // }}
                      key={subItem?.path}
                      style={{ color: "rgb(52,157,137)", fontSize: "16px" }}
                      icon={
                        keys.selected == subItem?.path ? (
                          <BsRecordCircle size={14} />
                        ) : (
                          <BsCircle size={13} />
                        )
                      }
                    >
                      <Link to={subItem?.path}>
                        {t(subItem?.name)}
                      </Link>
                    </Menu.Item>
                  );
                }) : collapce ?  */
                item.submenu.map((subItem: any, j: number) => {
                  return (
                    <Menu.Item
                      // onClick={() => {
                      //   history.push(subItem?.path);
                      // }}
                      key={subItem?.path}
                      style={{ color: "rgb(52,157,137)", fontSize: "16px" }}
                      icon={
                        keys.selected == subItem?.path ? (
                          <BsRecordCircle size={14} />
                        ) : (
                          <BsCircle size={13} />
                        )
                      }
                    >
                      <Link to={subItem?.path}>
                        {t(subItem?.name)}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.SubMenu>
            );
          }
        }
      })}
    </Menu>
  );
};

export default SidebarMenu;
