import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Checkbox, Tabs, Button } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { BiSend, BiReset, BiChevronDown, BiX, BiDownArrowAlt } from 'react-icons/bi';
import "./styles.scss";
import UserItemOfMessage from "./components/Item";
import MessageItem from './components/MessageItem';
import { message } from 'antd';
import { MessageUsersBoxUi } from './styled';
import instance from "config/_axios";
import { useTranslation } from "react-i18next";
import { getMyAllNotifications, getAllNotifications } from "./service";
import { getAllRoles } from "pages/role_and_permission/service";
import { ArrayChecker } from "utils/ArrayChecker";
import { asyncN } from 'utils/notifications';
import { delete_data } from "services";
import { useAppSelector } from "store/services";


const { TabPane } = Tabs;

const Message: FC = (): JSX.Element => {

    const { i18n } = useTranslation();
    const messages = useAppSelector(state => state.data.messages);
    const messageEndRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState<Array<string>>([]);
    const [messages_sent, setMessagesSent] = useState<Array<any>>([]);
    const [received_messages, setReceivedMessages] = useState<Array<any>>([]);
    const [text, setText] = useState<string>('');
    const [isList, setIsList] = useState<boolean>(false);
    const [roles, setRoles] = useState<Array<any>>([]);
    const [request, setRequest] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [isType, setIsType] = useState<'update' | 'create'>('create');
    const [activeKey, setActiveKey] = useState<string | undefined>('1');
    const [loading, setLoading] = useState(false);

    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        if (messages.messages.length) {
            setActiveKey('2')
        }
    }, [messages.messages.length])
    useEffect(() => {
        if (messages.messages.length) {
            setActiveKey('2')
        }
        scrollToBottom();
    }, [messages_sent.length])

    useEffect(() => {
        (
            async () => {
                const response = await getAllRoles();
                setRoles(response?.items.filter((item: any, index: any) => ['rector', 'prorector', 'teacher', 'student'].includes(item.name)));
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                const response = await instance({ url: "notifications/status-list", method: "GET" });
            }
        )()
    }, [])


    const _getAllNotifications = async () => {
        setLoading(true);
        const response = await getAllNotifications('sort=-id&expand=statusName,roles,createdBy&all=1');
        setReceivedMessages(response?.items);
        if (!response?.items.length) {
            message.warning("Ma'lumot topilmadi !");
        }
        setLoading(false);
    }

    useEffect(() => {
        (
            async () => {
                const response = await getMyAllNotifications('expand=roles');
                setMessagesSent(response?.items);
            }
        )()
    }, [request])


    const selectItem = (id: string) => {
        if (!selected.includes(id)) {
            setSelected(prevState => ([...prevState, id]))
        } else {
            setSelected([...selected.filter(e => e !== id)]);
        }
    }
    function _selectAll(event: any) {
        if (event.target.checked) {
            const _ids = roles.map(e => e.name);
            setSelected(_ids);
        } else {
            setSelected([]);
        }
    }


    const _dataEdit = ({ id, value, roles }: { id: number, value: string, roles: Array<{ role: string }> }) => {
        setText(value);
        setSelectedId(id);
        setIsType("update");
        if (ArrayChecker(roles)._) {
            const new_roles = roles.map((e) => e.role)
            setSelected(new_roles);
        }
    }


    const _deleteMessage = (id: number) => {
        delete_data('notifications', id).then(() => {
            setRequest(!request);
        })
    }

    const sendMessage = async () => {
        try {
            if (isType === 'update' && !selectedId) {
                message.warning("Ma'lumotni yangilash uchun id topilmadi !");
                return
            } else if (!text) {
                message.warning("Xabar kiritilishi shart!");
                return
            } else if (!selected.length) {
                message.warning("Qabul qiluvchini tanlang!");
                return
            }

            const formdata = new FormData();
            formdata.append(`name[${i18n.language}]`, text);
            formdata.append('roles', JSON.stringify(selected));
            const response = await instance({ url: isType === "create" ? '/notifications' : `notifications/${selectedId}`, method: isType === "create" ? 'POST' : "PUT", data: formdata });
            if (response.data.status === 1) {
                asyncN("success", isType, response.data?.message)
                    .then(() => {
                        setText('');
                        setRequest(!request);
                        setSelected([]);
                        if (isType === "update") {
                            setSelectedId(null);
                            setIsType("create");
                        }
                    })
            }
        } catch (error) {

        }
    }

    return (
        <div className="message_box">
            <MessageUsersBoxUi isOpen={isList}>
                <span className="messages_user_header"> <Checkbox onChange={_selectAll} className="ms-3">Barchasiga</Checkbox> <BiChevronDown onClick={() => setIsList(!isList)} style={isList ? { transform: "rotate(-180deg)" } : { transform: "rotate(0deg)" }} /></span>
                <div>
                    {
                        ArrayChecker(roles)._ && roles.map((element: any, index) => element.pretty_name ? <UserItemOfMessage key={index} selectItem={selectItem} element={element} active={selected.includes(element.name)} /> : null)
                    }
                </div>
            </MessageUsersBoxUi>
            <div>
                <h6>{isType === "create" ? "Xabar yuborish" : "Xabarni o'zgartirish"}</h6>
                <textarea placeholder="typing ..." value={text} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)}></textarea>
                <form className="ant-form">
                    <div className="d-flex justify-content-end w-80 ant-col">
                        <Button type="primary" danger icon={<BiX />} size="small" className="me-2" style={{ width: "50px" }}></Button>
                        <Button type="primary" icon={<BiReset />} size="small" onClick={() => setText('')} className="me-2" style={{ width: "50px" }}></Button>
                        <Button type="primary" icon={<BiSend />} size="small" onClick={sendMessage} className="w-20" style={{ width: "50px" }}></Button>
                    </div>
                </form>
                <Tabs activeKey={activeKey} onChange={(e: string) => setActiveKey(e)}>
                    <TabPane
                        tab={
                            <span className="d-flex align-items-center">
                                <ArrowUpOutlined />
                                <span>Yuborilgan xabarlar</span>
                            </span>
                        }
                        key="1"
                    >
                        <div className="mesages_container">
                            {
                                ArrayChecker(messages_sent)._ && messages_sent.map((element: any, index) => <MessageItem key={index} isType="1" element={element} update={_dataEdit} _deleteMessage={_deleteMessage} setText={setText} />)
                            }
                            <div ref={messageEndRef} />
                        </div>
                    </TabPane>
                    <TabPane
                        tab={
                            <span className="d-flex align-items-center">
                                <ArrowDownOutlined />
                                <span>Qabul qilingan xabarlar</span>
                            </span>
                        }
                        key="2"
                    >
                        <div className="mesages_container">
                            {
                                ArrayChecker(messages.messages)._ ? messages.messages.map((element: any, index) => <MessageItem key={index} isType="0" _state="unread" element={element} update={_dataEdit} _deleteMessage={_deleteMessage} />) : null
                            }
                            {
                                ArrayChecker(received_messages)._ ? received_messages.map((element: any, index) => {
                                    if (!messages.ids.includes(element.id)) {
                                        return (
                                            <MessageItem key={index} isType="0" _state="read" element={element} update={_dataEdit} _deleteMessage={_deleteMessage} />
                                        )
                                    }
                                }) : null
                            }
                            <div className="text-end"><Button type="link" loading={loading} disabled={loading} onClick={_getAllNotifications}> {!loading ? <BiDownArrowAlt /> : null} more </Button></div>
                            <div ref={messageEndRef} />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}


export default Message;