import * as React from 'react';
import "./style.scss"
import { RipplebuttosStyle } from './ui_styled';

const RippleButton: any = ({ item, color }: { item: string, color: string }): JSX.Element => {
  const [coords, setCoords] = React.useState<{ x: number, y: number }>({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  React.useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  React.useEffect(() => setIsRippling(true), [])

  return (
    <RipplebuttosStyle color={color}>
      <button
        className="ripple-button"
        onClick={e => {
          const rect = e.currentTarget.getBoundingClientRect();
          setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        }}
      >
        {isRippling ? (
          <span
            className="ripple"
            style={{
              left: coords.x,
              top: coords.y
            }}
          />
        ) : (
          ''
        )}
        <span className="content">{item}</span>
      </button>
    </RipplebuttosStyle>
  );
};

export default RippleButton
