import { message } from 'antd';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import _logout from './logout';
import { error_401, error_403, error_500, error_422 } from './errors';

let instance = axios.create();

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const access_token = localStorage.getItem('access_token');
    if (!access_token && config.url !== '/auth/login') {
        message.error("You has not key to get data !")
        // _logout()
    }
    const url_lang = localStorage.getItem('i18lang') || 'uz';
   config.baseURL = `https://api-digital-tex.tsul.uz/api/web/${url_lang}`;
    // config.baseURL = `http://api-digital-tex.tsul.uz/api/web/${url_lang}`;
    /* local */
    // config.baseURL = `http://10.1.1.136:8187/${url_lang}`

    config.headers = {
        'Authorization': "Bearer " + access_token,
        'api-token': "2k8UmgKs36Jb",
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    // config.timeout = 5000
    return config;
}

const onRequestError = async (error: AxiosError): Promise<AxiosError> => {
    if (error.response && error.response.status === 401) {
        error_401(error);
    } else if (error.response && error.response.status === 500) {
        error_500(error);
    } else if (error.response && error.response.status === 403) {
        error_403();
    } else {
        message.error(error.message)
    }
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    if (error.response && error.response.status === 401) {
        error_401(error);
    } else if (error.response && error.response.status === 404) {
        message.error("not found")
    } else if (error.response && error.response.status === 500) {
        error_500(error);
    } else if (error.response && error.response.status === 403) {
        error_403();
    } else if (error.response && error.response.status === 422) {
        error_422(Object.values(error?.response?.data?.errors[0]));
    }
    return Promise.reject(error);
}

instance.interceptors.request.use(onRequest, onRequestError)
instance.interceptors.response.use(onResponse, onResponseError)


export default instance;
