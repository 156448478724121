import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Tabs } from "antd";
import FormLabel from "components/FormLabel";
import ModalTitle from "components/ModalTitle";
import instance from "config/_axios";
import useDeleteOne from "hooks/useDeleteOne";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiX } from "react-icons/bi";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { asyncN } from "utils/notifications";
import { ServerErrors } from "utils/validationFunctions";

const { TabPane } = Tabs;

type TeacherCommentType = {
  topicId: number,
  visible: boolean,
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

type TypeTopicReference = {
  id: number,
  name: string,
  link: string,
  start_page: number,
  end_page: number,
  type: number,
}

const { Option } = Select;

const referenceType = [1, 2]

const TeacherComment: FC<TeacherCommentType> = ({ visible, setVisible, topicId }): JSX.Element => {

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [id, setId] = useState<number | null>(null);

  const _delete = useDeleteOne();
  const { items, fetch } = useGetAllData({ url: `subject-topic-references?sort=-id&filter={"subject_topic_id":${topicId}}` }) as TypeReturnUseGetAllData<TypeTopicReference>

  useEffect(() => {
    if (topicId && visible) {
      fetch();
    }
  }, [topicId, reFetch, _delete.refetch]);

  const getUpdate = (id: number) => {
    const data = items?.filter(e => e?.id === id)[0];
    setId(id);

    form.setFieldsValue({
      "name": data?.name,
      "link": data?.link,
      "start_page": data?.start_page,
      "end_page": data?.end_page,
      "type": data?.type
    });

    setIsUpdate(true);
  }

  const onSubmit = async (values: any) => {
    try {
      setClicked(true)
      const formdata = new FormData();
      for (const [key, value] of Object.entries(values)) {
        formdata.append(key, String(value));
      }
      formdata.append("subject_topic_id", `${topicId}`)

      const response = await instance({ url: `subject-topic-references${isUpdate ? ('/' + id) : ""}`, method: isUpdate ? 'PUT' : 'POST', data: formdata })

      if (response.data.status === 1) {
        asyncN("success", 'create', response.data?.message)
          .then(() => {
            resetFields();
            setReFetch(prevState => !prevState);
            if (isUpdate) setIsUpdate(false);
          })
      } else {
        asyncN("error", 'update', response.data?.message)
          .then(() => resetFields())
      }
      setClicked(false)
    } catch (error: any) {
      if (error?.response?.status === 422) {
        if (error?.response?.data?.status === 0) {
          form.setFields(ServerErrors(error?.response?.data?.errors[0]))
        }
      }
      asyncN("error", 'create', error?.response?.message)
      setClicked(false)
    }
  }

  const resetFields = () => form.resetFields();


  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => { setVisible(false); resetFields() }}
        width={1200}
        footer={null}
        closeIcon={<BiX size={20} />}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{
            status: true
          }}
        >
          <Tabs defaultActiveKey="1">
            <TabPane key={"1"} tab="Mavzu uchun adabiyot qo'shish">
              <Row gutter={[12, 0]} className="mt-2" >
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    label={<FormLabel>Adabiyot nomi</FormLabel>}
                    name="name"
                    rules={[{ required: true, message: t('Fill in the field!') }]}
                  >
                    <Input.TextArea rows={1} />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    label={<FormLabel>Adabiyot havolasi</FormLabel>}
                    name="link"
                    rules={[{ required: true, message: t('Fill in the field!') }]}
                    className="w-100"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="d-flex">
                    <Form.Item
                      label={<FormLabel>Boshlang'ich sahifa</FormLabel>}
                      name="start_page"
                      rules={[{ required: true, message: t('Fill in the field!') }]}
                      className="w-100"
                    >
                      <InputNumber className="w-100" min={0} />
                    </Form.Item>
                    <Form.Item
                      label={<FormLabel>Oxirgi sahifa</FormLabel>}
                      name="end_page"
                      rules={[{ required: true, message: t('Fill in the field!') }]}
                      className="w-100 mx-2"
                    >
                      <InputNumber min={0} className="w-100" />
                    </Form.Item>
                    <Form.Item
                      label={<FormLabel>Adabiyot turi</FormLabel>}
                      name="type"
                      rules={[{ required: true, message: t('Fill in the field!') }]}
                      className="w-100"
                    >
                      <Select  >
                        <Option value={1} >Asosiy adabiyot</Option>
                        <Option value={2} >Qo‘shimcha adabiyot</Option>
                      </Select>
                    </Form.Item>
                    {
                      isUpdate ? <Button danger style={{ margin: "1.9rem 0 0 1rem" }} onClick={() => { setIsUpdate(false); resetFields() }} >{t("Cancle")}</Button> : null
                    }
                    <Button style={{ margin: "1.9rem 0 0 1rem" }} type="primary" htmlType="submit" disabled={clicked}>{t("Save")}</Button>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane key="2" tab="Muhokama uchun savollar, muhokama uchun kazus yoki muammoli savol yaratish!">
              <Row gutter={[12, 0]} className="mt-2" >
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    label={<FormLabel>Adabiyot nomi</FormLabel>}
                    name="name"
                    rules={[{ required: true, message: t('Fill in the field!') }]}
                  >
                    <Input.TextArea rows={1} />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    label={<FormLabel>Adabiyot havolasi</FormLabel>}
                    name="link"
                    rules={[{ required: true, message: t('Fill in the field!') }]}
                    className="w-100"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="d-flex">
                    <Form.Item
                      label={<FormLabel>Boshlang'ich sahifa</FormLabel>}
                      name="start_page"
                      rules={[{ required: true, message: t('Fill in the field!') }]}
                      className="w-100"
                    >
                      <InputNumber className="w-100" min={0} />
                    </Form.Item>
                    <Form.Item
                      label={<FormLabel>Oxirgi sahifa</FormLabel>}
                      name="end_page"
                      rules={[{ required: true, message: t('Fill in the field!') }]}
                      className="w-100 mx-2"
                    >
                      <InputNumber min={0} className="w-100" />
                    </Form.Item>
                    <Form.Item
                      label={<FormLabel>Adabiyot turi</FormLabel>}
                      name="type"
                      rules={[{ required: true, message: t('Fill in the field!') }]}
                      className="w-100"
                    >
                      <Select  >
                        <Option value={1} >Asosiy adabiyot</Option>
                        <Option value={2} >Qo‘shimcha adabiyot</Option>
                      </Select>
                    </Form.Item>
                    {
                      isUpdate ? <Button danger style={{ margin: "1.9rem 0 0 1rem" }} onClick={() => { setIsUpdate(false); resetFields() }} >{t("Cancle")}</Button> : null
                    }
                    <Button style={{ margin: "1.9rem 0 0 1rem" }} type="primary" htmlType="submit" disabled={clicked}>{t("Save")}</Button>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Form>
        {items?.length ? referenceType.map((e, i) => items?.filter(a => a?.type === e)?.length ? (<div key={i}>
          <Divider className="mt-2" orientation="left" plain >{e === 1 ? "Asosiy adabiyotlar" : "Qo'shimcha adabiyotlar"}</Divider>
          <Table id="tech-companies-1" className="table table-bordered table-hover">
            <Thead>
              <Tr>
                <Th className="py-1 px-2 bg-light" >{t("Name")}</Th>
                <Th className="py-1 px-2 bg-light" >{t("Havola")}</Th>
                <Th className="py-1 px-2 bg-light" >{t("Start page")}</Th>
                <Th className="py-1 px-2 bg-light" >{t("End page")}</Th>
                {/* <Th className="py-1 px-2 bg-light" >{t("Type")}</Th> */}
                <Th className="py-1 px-2 bg-light" >{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                items?.map((element, i) => element?.type === e ? (
                  <Tr key={i} style={{ background: isUpdate && element?.id === id ? "#ffccc7" : "" }} >
                    <Td className="py-1 px-2" >{element?.name}</Td>
                    <Td className="py-1 px-2" ><a target="_blank" className="line-clamp d-block max_w_200" href={element.link} >{/*Site <BiLinkExternal />*/}{element.link}</a></Td>
                    <Td className="py-1 px-2" >{element?.start_page}</Td>
                    <Td className="py-1 px-2" >{element?.end_page}</Td>
                    {/* <Td className="py-1 px-2" >{element?.type === 1 ? "Asosiy" : "Qo'shimcha"}</Td> */}
                    <Td className="py-1 px-2 w_60">
                      <div className="crud_actions_table">
                        <TbFilePencil onClick={() => { getUpdate(element?.id) }} className="me-1 edit_btn" />
                        <TbFileOff onClick={() => _delete.fetch(`/subject-topic-references/${element?.id}`)} className="delete_btn" />
                      </div>
                    </Td>
                  </Tr>
                ) : null)
              }
            </Tbody>
          </Table> </div>) : null) : null}
      </Modal>
    </>
  )
}

export default TeacherComment;