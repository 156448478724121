import { ChangeEvent, useEffect, useState, FC, useImperativeHandle, forwardRef } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Upload, Tabs, DatePicker } from "antd";
import UserAvatar from "components/userAvatar/avatar";
import InputMask from 'react-input-mask';
import moment from 'moment';
import instance from "config/_axios";
import { useHistory } from 'react-router-dom'
import { asyncN } from "utils/notifications";
import { FaUpload, FaUndo, FaMapMarkerAlt, FaPassport, FaAddressCard, FaChalkboardTeacher } from "react-icons/fa";
import { getGenders, getUserStatuses } from "../../user/servise";
import { useTranslation } from 'react-i18next';
import { getAllRegionsWithCountryId } from 'services';
import TeacherAccess from './teacherAccess';
import UserAccess from 'pages/user/components/userAccess';
import { diplomaTypeType } from 'pages/diploma_type/types';
import { degreeType } from 'pages/degree/types';
import { academicDegreeType } from 'pages/academic_degree/types';
import { degreeInfos } from 'pages/degree_info/types';
import { partyaType } from 'pages/party/types';
import { getAllDiplomaTypes } from 'pages/diploma_type/service';
import { getAllDegrees } from 'pages/degree/service';
import { getAllAcademicDegree } from 'pages/academic_degree/service';
import { getAllDegreeInfo } from 'pages/degree_info/service';
import { getAllPartiyas } from 'pages/party/service';

const { TabPane } = Tabs;
const { Option } = Select;

type addressCheckType = { region: boolean, area: boolean, regionP: boolean, areaP: boolean, address: boolean, addressP: boolean }

const FeildsUi: FC<{
    ref?: any,
    addressCheck: addressCheckType,
    setAddressCheck: (a: addressCheckType) => void,
    teacher_access_list: any,
    setTeacherAccessList: any,
    form: any,
    accesses: any,
    setAccesses: any,
    responseError: string,
    setResponseError: (responseError: string) => void,
    disabledButton: boolean,
    avatar?: string,
    firstname?: string,
    edit?: boolean,
    files?: any,
}> = forwardRef(({
    addressCheck,
    setAddressCheck,
    teacher_access_list,
    setTeacherAccessList,
    form,
    edit,
    disabledButton,
    accesses,
    setAccesses,
    responseError,
    firstname,
    avatar,
    setResponseError,
    files
}, ref): JSX.Element => {
    const { t, i18n } = useTranslation();
    const [countries, setCountries] = useState<any>([]);
    const dateFormat = 'YYYY-MM-DD';
    const [pass, setPass] = useState<string>("");
    const [errRePass, setErrRePass] = useState<boolean>(false);
    const [userStatus, setUserStatus] = useState<any>([]);
    const history = useHistory()
    const [regions, setRegions] = useState<any>([]);
    const [regionsP, setRegionsP] = useState<any>([]);
    const [areas, setAreas] = useState<any>([]);
    const [fileList, setFileList] = useState<any>([]);
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const [tabPageStatus, setTabPageStatus] = useState<'success' | 'error'>('success');
    const [fields, setFields] = useState<any>(new Object());
    const [areasP, setAreasP] = useState<any>([]);
    const [errorTabs, setErrorTabs] = useState<Array<string>>([]);
    const [refreshSelects, setRefreshSelects] = useState<{ refreshGender: boolean, refreshStatus: boolean }>({ refreshGender: false, refreshStatus: false })
    let _locationTabError: any = {
        address: "3",
        area_id: "3",
        country_id: "3",
        dob: "1",
        email: "1",
        first_name: "1",
        gender: "1",
        last_name: "1",
        middle_name: "1",
        passport_date: "2",
        passport_file: "2",
        passport_given_by: "2",
        passport_pin: "2",
        passport_seria_and_number: "2",
        permanent_address: "3",
        permanent_area_id: "3",
        permanent_country_id: "3",
        permanent_region_id: "3",
        phone: "1",
        phone_secondary: "1",
        region_id: "3",
        role: "1",
        status: "1",
        username: "1"
    }
    const [gender, setGender] = useState<Array<{ id: number, name: string }>>([]);
    const [diplomaTypes, setDiplomaTypes] = useState<Array<diplomaTypeType>>([])
    const [degrees, setDegrees] = useState<Array<degreeType>>([])
    const [academicDegrees, setAcademicDegrees] = useState<Array<academicDegreeType>>([])
    const [degreeInfos, setDegreeInfos] = useState<Array<degreeInfos>>([])
    const [partyas, setPartyas] = useState<Array<partyaType>>([])


    const _diplomaTypes = async () => {
        const resp = await getAllDiplomaTypes(1, 0);
        setDiplomaTypes(resp?.items);
    }

    const _degrees = async () => {
        const resp = await getAllDegrees(1, 0);
        setDegrees(resp?.items);
    }

    const _academicDegrees = async () => {
        const resp = await getAllAcademicDegree(1, 0);
        setAcademicDegrees(resp?.items);
    }

    const _degreeInfos = async () => {
        const resp = await getAllDegreeInfo(1, 0);
        setDegreeInfos(resp?.items);
    }

    const _partyas = async () => {
        const resp = await getAllPartiyas(1, 0);
        setPartyas(resp?.items);
    }

    useEffect(() => {
        _partyas();
        _degreeInfos();
        _academicDegrees();
        _degrees();
        _diplomaTypes();
    }, [])

    const onChangeGetCountry = async () => {
        try {
            const response = await instance({
                method: "get",
                url: `/countries?sort=name&per-page=239`,
            });

            if (response?.data?.status === 1) {
                setCountries(response?.data?.data?.items);
                form.setFieldsValue({
                    country_id: 229,
                    permanent_country_id: 229
                })

            } else {
                asyncN("error", "read", response?.data?.message);
            }
        } catch (e: any) {
            asyncN("error", "read", e?.response?.message);
        }
    };

    useImperativeHandle(ref, () => ({
        getRegionsE(id: number) {
            getRegions(id);
        },
        getRegionsPE(id: number) {
            getRegionsP(id);
        },
        getAreasE(id: number) {
            onChangeGetArea(id);
        },
        getAreasPE(id: number) {
            onChangeGetAreaP(id);
        }
    }))

    useEffect(() => {
        (
            async () => {
                const resp = await getGenders();
                let data: Array<{ id: number, name: string }> = [];
                Object.entries(resp).forEach(([key, value]: any) => {
                    data.push({
                        id: Number(key),
                        name: String(value)
                    })
                })
                setGender(data)
            }
        )()
    }, [refreshSelects.refreshGender])

    useEffect(() => {
        if (responseError !== "-1") {
            setActiveTabKey(responseError)
            setResponseError("-1")
        }
    }, [responseError])

    useEffect(() => {
        (
            async () => {
                const resp = await getUserStatuses();
                let data: Array<{ key: number, value: string }> = [];
                if (resp) {
                    Object.entries(resp).forEach(([key, value]: any) => {
                        data.push({
                            key: Number(key),
                            value: String(value)
                        })
                    })
                    setUserStatus(data)
                }
            }
        )()
    }, [refreshSelects.refreshStatus])

    useEffect(() => {
        if (!edit) {
            getRegions(229)
            getRegionsP(229)
        }
    }, [])

    const getRegions = async (id: number) => {
        const response: any = await getAllRegionsWithCountryId(id)
        setRegions(response?.items);
    };

    const getRegionsP = async (id: number) => {
        const response: any = await getAllRegionsWithCountryId(id)
        setRegionsP(response?.items);
    };

    useEffect(() => {
        onChangeGetCountry();
    }, []);

    useEffect(() => {
        if (files) {
            setFileList(files)
        }
    }, [files])

    const onChangeGetArea = async (e: any) => {
        try {
            const response = await instance({
                method: "get",
                url: `/areas?filter={"region_id":${e}}`,
            });

            if (response?.data?.status === 1) {
                setAreas(response?.data?.data?.items);

            } else {
                asyncN("error", "read", e?.response?.message);
            }
        } catch (e: any) {
            asyncN("error", "read", e?.response?.message);
        }
    };

    const onChangeGetAreaP = async (e: any) => {
        try {
            const response = await instance({
                method: "get",
                url: `/areas?filter={"region_id":${e}}`,
            });

            if (response?.data?.status === 1) {
                setAreasP(response?.data?.data?.items);

            } else {
                asyncN("error", "read", e?.response?.message);
            }
        } catch (e: any) {
            asyncN("error", "read", e?.response?.message);
        }
    };

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    const onChange = ({ fileList: newFileList }: any) => {

        if (Array.isArray(newFileList) && newFileList.length) {
            if (newFileList[0]?.size < 20480000) {
                setFileList(newFileList);
            } else {
                message.error("Rasm hajmi 200kb dan oshmasligi kerak !");
            }
        }
    };

    const onPreview = async (file: any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        let a: any = document.createElement('a')
        a.href = src;
        a.click()
    };

    const imgUpload = () => (<Button> <FaUpload /> &nbsp;&nbsp; {t("Upload")}</Button>);

    const catchTabPageErrors = (tab: string | undefined) => {
        if (tab && tab !== activeTabKey) {
            form.validateFields()
                .then((resp: any) => {
                    let newArr: Array<any> = [];
                    let existingArr: Array<any> = [];
                    for (let key in fields) {
                        key as string;
                        existingArr.push(...fields[key])
                    }
                    for (let key in resp) {
                        key as any;
                        if (!existingArr.includes(key)) {
                            newArr.push(key);
                        }
                    }
                    if (activeTabKey) {
                        setFields({ ...fields, [activeTabKey]: newArr });
                    }
                    let a: Array<string> = []
                    if (errorTabs.length > 0 && activeTabKey) {
                        a = errorTabs?.filter((item: any) => {
                            return String(item) !== activeTabKey
                        });
                    }
                    setErrorTabs(a);
                    setTabPageStatus('success')
                    setActiveTabKey(tab)
                })
                .catch(async (err: any) => {
                    setTabPageStatus('error')
                    let a = await counter(err?.errorFields);
                    if (a === 0) {
                        setErrorTabs(errorTabs?.filter((item: string) => item !== activeTabKey));
                        setActiveTabKey(tab)
                    } else {
                        if (!errorTabs.includes(activeTabKey)) {
                            setErrorTabs([
                                ...errorTabs, activeTabKey
                            ]);
                        }
                        setTabPageStatus('error')
                    }
                })
        }
    }


    const counter = async (feilds: Array<{ errors: Array<string>, name: Array<string> }>) => {
        let a: Array<{ errors: Array<string>, name: Array<string> }> = feilds?.filter((item: { errors: Array<string>, name: Array<string> }) => {
            if (_locationTabError[item?.name[0]] === activeTabKey) {
                return item
            }
        })
        return a.length
    }
    const catchTabPageError = (tab: string | undefined) => {
        if (tab && tab !== activeTabKey) {
            form.validateFields()
                .then((resp: any) => {
                    let a: Array<string> = []
                    a = errorTabs?.filter((item: any) => {
                        return String(item) !== activeTabKey
                    });
                    setErrorTabs(a);
                    setTabPageStatus('success');
                    setActiveTabKey(tab);
                })
                .catch(async (err: any) => {
                    let a = await counter(err?.errorFields);
                    if (a === 0) {
                        setErrorTabs(errorTabs?.filter((item: string) => item !== activeTabKey));
                        setActiveTabKey(tab)
                    } else {
                        if (!errorTabs.includes(activeTabKey)) {
                            setErrorTabs([
                                ...errorTabs, activeTabKey
                            ]);
                        }
                        setActiveTabKey(tab);
                        setTabPageStatus('error')
                    }
                })
        }
    }

    return (
        <>
            <Tabs activeKey={activeTabKey} onTabClick={(e) => catchTabPageError(e)} >
                <TabPane tab={
                    <span style={tabPageStatus === 'error' && errorTabs.includes('1') ? { color: "#f5222d" } : {}}>
                        <FaAddressCard size={20} /> &nbsp;{t("Personal details")}
                    </span>
                } key={1}>
                    <Row gutter={24}>
                        <Col span={24}>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} className="text-center mt-3">
                            <div>
                                <UserAvatar uploadedFile={(e: any) => {

                                }}
                                    upload
                                    fileSize={204800}
                                    fileType='image/png'
                                    avatar={avatar} size={130}
                                    text={firstname ? firstname : "User"} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <Row className="d-flex justify-content-center" gutter={24}>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item name={`username`}
                                        label={t`Username`}
                                        rules={[
                                            {
                                                required: true,
                                                message: `Maydonni to'ldiring!`,
                                            },
                                        ]}>
                                        <Input onBlur={(e: any) => {
                                            let val: string = e?.target?.value;
                                            if (val.includes(" ")) {
                                                form?.setFields([{
                                                    name: ["username"],
                                                    errors: ["Username (Login) maydonini to'ldirishda bo'shliq mavjud! so'zlarni ajratishda _ belgisidan foydalaning!"]
                                                }])
                                            }
                                        }} placeholder={t("Enter username") + " ..."} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item name={`first_name`}
                                        label={t`First name`}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t("Fill in the field!")}`,
                                            },
                                        ]}>
                                        <Input placeholder="First name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <Form.Item name={`last_name`}
                                        label={t`Last name`}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t("Fill in the field!")}`,
                                            },
                                        ]}>
                                        <Input placeholder={t("Enter last name ... ")} />
                                    </Form.Item>
                                </Col>
                                <Col sm={24} xs={24} md={12} lg={12}>
                                    <Form.Item
                                        name={`middle_name`}
                                        label={t`Middle name`}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t("Fill in the field!")}`,
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t`Middle name`} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item name={`birthday`}
                                label={t`Birth date`}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}>
                                <DatePicker defaultPickerValue={moment(new Date().setFullYear(new Date().getFullYear() - 18))} format={dateFormat}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item name={`phone`}
                                label={t`Phone number`}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}>
                                <InputMask
                                    mask="+\9\98 (99) 999 99 99"
                                    className='ant-input'
                                    placeholder={t`Phone number`}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item name={`phone_secondary`}
                                label={t`Second number`}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}>
                                <InputMask
                                    mask="+\9\98 (99) 999 99 99"
                                    className='ant-input'
                                    placeholder={t`Second number`}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item name={`email`}
                                label={t`Email`}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}>
                                <Input type="email" placeholder={t`Enter email`} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item name={`gender`}
                                label={t`Gender`}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}>
                                <Select onFocus={() => { if (gender?.length === 0) setRefreshSelects({ ...refreshSelects, refreshGender: !refreshSelects?.refreshGender }) }} placeholder={t("Select gender")} >
                                    {
                                        gender && gender.map((item: any, i: number) =>
                                            <Option key={"" + i} value={item.id}>{item.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item name={`status`}
                                label={t`Status`}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field!"),
                                    },
                                ]}>
                                <Select onFocus={() => { if (userStatus?.length === 0) setRefreshSelects({ ...refreshSelects, refreshStatus: !refreshSelects?.refreshStatus }) }} placeholder={t("Select status")} >
                                    {
                                        userStatus.map((item: any, i: number) =>
                                            <Option key={"" + i} value={item?.key}>{item?.value}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item name={`password`}
                                label={t`Password`}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}>
                                <Input.Password

                                    onChange={(e) => {
                                        setPass(e.target.value);
                                    }
                                    } autoComplete="new-password" placeholder={t`Enter password`} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item name={`rePassword`}
                                label={t`Re-password`}
                                validateStatus={errRePass ? "error" : ""}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}>
                                <Input.Password onChange={(e) => {
                                    if (e.target.value === pass) {
                                        setErrRePass(false)
                                    } else {
                                        setErrRePass(true)
                                    }
                                }} placeholder={t`Re-password`} />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={
                    <span style={tabPageStatus === 'error' && errorTabs.includes('2') ? { color: "#f5222d" } : {}}>
                        <FaPassport size={18} />&nbsp;
                        &nbsp;{t("Passport details")}
                    </span>
                } key={2}>
                    <Row className="mt-4" gutter={24}>
                        <Col md={24}>
                            <h6 className="fw-normal">
                                <strong>
                                    {t("Passport details")}
                                </strong>
                            </h6>
                            <hr />
                        </Col>
                        <Col sm={24} xs={24} md={12} lg={8}>
                            <Form.Item
                                name={`passport_seria_and_number`}
                                label={t(`Passport seria and number`)}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <InputMask mask="aa 9999999" placeholder={t("Passport seria and number")} className='ant-input' onChange={(e: ChangeEvent<HTMLInputElement>) => { form.setFieldsValue({ passport_seria_and_number: e.target.value?.toUpperCase() }) }} />
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={12} lg={8}>
                            <Form.Item
                                name={`passport_pin`}
                                label={t(`Passport JSHIR`)}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <InputMask

                                    mask="99999999999999"
                                    placeholder="_ _ _ _ _ _ _ _ _ _ _ _ _ _"
                                    className='ant-input'
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={24} lg={8}>
                            <Form.Item
                                name={`passport_file`}
                                label={t(`Passport file`)}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Upload
                                    style={{ width: "100%" }}
                                    customRequest={dummyRequest}
                                    fileList={fileList}
                                    accept={"application/pdf,,.docx,image/png,image/jpeg"}
                                    onChange={onChange}
                                    listType={"text"}
                                    onPreview={onPreview}
                                    onRemove={() => setFileList([])}
                                >
                                    {fileList.length < 1 && imgUpload()}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={12} lg={8}>
                            <Form.Item
                                name={`passport_given_by`}
                                label={t`Passport given by`}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Input placeholder={t`Passport given by`} />
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={12} lg={8}>
                            <Form.Item
                                name={`passport_given_date`}
                                label={t("Given date")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <DatePicker
                                    onChange={(e) => {
                                        if (e && !form.getFieldValue('passport_issued_date')) {
                                            let a = new Date(moment(e).format(dateFormat))
                                            a.setFullYear(a.getFullYear() + 10);
                                            a.setDate(a.getDate() - 1)
                                            form.setFieldsValue({
                                                passport_issued_date: moment(a)
                                            })
                                        }
                                    }}
                                    placeholder={`${t("Given date")}`}
                                    format={dateFormat}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={12} lg={8}>
                            <Form.Item
                                name={`passport_issued_date`}
                                label={t("Issued date")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <DatePicker
                                    onChange={(e) => {
                                        if (e && !form.getFieldValue('passport_given_date')) {
                                            let a = new Date(moment(e).format(dateFormat))
                                            a.setFullYear(a.getFullYear() - 10);
                                            a.setDate(a.getDate() + 1)
                                            form.setFieldsValue({
                                                passport_given_date: moment(a)
                                            })
                                        }
                                    }}
                                    placeholder={`${t("Issued date")}`}
                                    format={dateFormat}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={
                    <span style={tabPageStatus === "error" && errorTabs.includes('3') ? { color: "#f5222d" } : {}}>
                        <FaMapMarkerAlt size={18} />
                        &nbsp;{t("Address details")}
                    </span>
                } key={3}>
                    <Row gutter={24}>
                        <Col className="mt-4" sm={24} xs={24} lg={24} md={24}>
                            <h6 className="fw-normal">
                                <strong>
                                    {t("Address details")}
                                </strong>
                            </h6>
                            <hr />
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={8}>
                            <Form.Item
                                name={`country_id`}
                                label={t("Country")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    onFocus={() => {
                                        if (countries?.length === 0) {
                                            onChangeGetCountry()
                                        }
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder={t("Select a country")}
                                    onChange={(e: number) => {
                                        getRegionsP(e)
                                        getRegions(e)
                                        form.setFieldsValue({
                                            area_id: null,
                                            region_id: null,
                                            address: ""
                                        })
                                        if (form?.getFieldValue("permanaent_country_id")) {
                                            setAddressCheck({ ...addressCheck, region: false })
                                        } else {
                                            setAddressCheck({
                                                ...addressCheck, region: false, regionP: false
                                            })
                                            form.setFieldsValue({
                                                permanent_country_id: e
                                            })
                                        }

                                    }
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countries && countries?.map((value: any, index: number) => (
                                        <Option key={index} value={value.id}>
                                            {t(`${value.name}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={8}>
                            <Form.Item
                                name={`region_id`}
                                label={t("Region")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select
                                    disabled={addressCheck?.region}
                                    showSearch
                                    onFocus={() => {
                                        if (regions?.length === 0 && form?.getFieldValue("country_id")) {
                                            getRegions(Number(form?.getFieldValue("country_id")));
                                        }
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder={t("Select a region")}
                                    onChange={(e: number) => {
                                        onChangeGetArea(e);
                                        onChangeGetAreaP(e);
                                        form.setFieldsValue({
                                            area_id: null
                                        })
                                        if (form.getFieldValue("permanent_region_id")) {
                                            setAddressCheck({ ...addressCheck, area: false });
                                        } else {
                                            setAddressCheck({ ...addressCheck, area: false, areaP: false });
                                            form.setFieldsValue({
                                                permanent_region_id: e
                                            });
                                        }
                                    }
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {regions?.map((value: any, index: number) => (
                                        <Option key={index} value={value.id}>
                                            {value.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={8}>
                            <Form.Item
                                name={`area_id`}
                                label={t("Area")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select
                                    disabled={addressCheck?.area}
                                    onFocus={() => {
                                        if (areas?.length === 0 && form?.getFieldValue("region_id")) {
                                            onChangeGetArea(Number(form?.getFieldValue("region_id")));
                                        }
                                    }}
                                    onChange={
                                        (e) => {

                                            if (form.getFieldValue("permanent_area_id")) {
                                                setAddressCheck({
                                                    ...addressCheck,
                                                    address: false
                                                })
                                            } else {
                                                setAddressCheck({
                                                    ...addressCheck,
                                                    address: false,
                                                    addressP: false
                                                })
                                                form.setFieldsValue({
                                                    permanent_area_id: e
                                                });
                                            }
                                        }
                                    }
                                    showSearch
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    placeholder={t("Select an area")}
                                    filterOption={(input: any, option: any) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {areas?.map((value: any, index: number) => (
                                        <Option key={index} value={value.id}>
                                            {value.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={12} xs={24} md={24} lg={24}>
                            <Form.Item
                                name={`address`}
                                label={t("Address")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Input.TextArea disabled={addressCheck?.address} placeholder={t("Current address")} />
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={8}>
                            <Form.Item
                                name={`permanent_country_id`}
                                label={t("Permanent Country")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    onFocus={() => {
                                        if (countries?.length === 0) {
                                            onChangeGetCountry()
                                        }
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder={t("Select a permanent country")}
                                    onChange={(e: number) => {
                                        form.setFieldsValue({
                                            permanent_region_id: undefined,
                                            permanent_area_id: undefined,
                                            permanent_address: undefined
                                        })
                                        getRegionsP(e);
                                        setAddressCheck({
                                            ...addressCheck,
                                            regionP: false
                                        })
                                    }
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countries && countries?.map((value: any, index: number) => (
                                        <Option key={index} value={value.id}>
                                            {t(`${value.name}`)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={12} lg={8}>
                            <Form.Item
                                name={`permanent_region_id`}
                                label={t("Permanent region")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    onFocus={() => {
                                        if (regionsP?.length === 0 && form?.getFieldValue("permanent_country_id")) {
                                            getRegionsP(Number(form?.getFieldValue("permanent_country_id")));
                                        }
                                    }}
                                    disabled={addressCheck?.regionP}
                                    style={{ width: "100%" }}
                                    placeholder={t("Select a region")}
                                    onChange={(e: number) => {
                                        onChangeGetAreaP(e);
                                        setAddressCheck({
                                            ...addressCheck,
                                            areaP: false
                                        })
                                        form.setFieldsValue({
                                            permanent_area_id: null,
                                            permament_address: undefined
                                        })

                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {regionsP && regionsP?.map((value: any, index: number) => (
                                        <Option key={index} value={value.id}>
                                            {value.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={12} lg={8}>
                            <Form.Item
                                name={`permanent_area_id`}
                                label={t("Permanent area")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select
                                    disabled={addressCheck?.areaP}
                                    showSearch
                                    onFocus={() => {
                                        if (areasP?.length === 0 && form?.getFieldValue("permanent_region_id")) {
                                            onChangeGetAreaP(Number(form?.getFieldValue("permanent_region_id")));
                                        }
                                    }}
                                    onChange={(e: number) => {
                                        form.setFieldsValue({
                                            permanent_address: undefined
                                        })
                                        setAddressCheck({
                                            ...addressCheck,
                                            addressP: false
                                        })
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder={t("Select an area")}
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {areasP && areasP?.map((value: any, index: number) => (
                                        <Option key={index} value={value.id}>
                                            {value.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={12} xs={24} md={24} lg={24}>
                            <Form.Item
                                name={`permanent_address`}
                                label={t("Permanent address")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Input.TextArea disabled={addressCheck?.addressP} placeholder={t("Permanent address")} />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                {<TabPane tab={
                    <span style={tabPageStatus === 'error' && errorTabs.includes('4') ? { color: "#f5222d" } : {}}>
                        <FaChalkboardTeacher size={18} />
                        &nbsp; {t("Teacher details")}
                    </span>
                } key={4}>
                    <Row gutter={24}>
                        <Col sm={24} xs={24} md={8} lg={6}>
                            <Form.Item
                                name={`diploma_type_id`}
                                label={t("Diploma type")}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select onFocus={() => {
                                    if (diplomaTypes?.length === 0) {
                                        _diplomaTypes();
                                    }
                                }} placeholder={t("Select diploma type!")}>
                                    {
                                        diplomaTypes?.map((item: diplomaTypeType, i: number) =>
                                            <Option key={i} value={item?.id}>{
                                                i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                            }</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={6}>
                            <Form.Item
                                name={`degree_id`}
                                label={t("Degree")}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select onFocus={() => {
                                    if (degrees?.length === 0) {
                                        _degrees();
                                    }
                                }} placeholder={t("Select degree!")}>
                                    {
                                        degrees?.map((item: degreeType, i: number) =>
                                            <Option key={i} value={item?.id}>{
                                                i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                            }</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={6}>
                            <Form.Item
                                name={`academic_degree_id`}
                                label={t("Academic degree")}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select onFocus={() => {
                                    if (academicDegrees?.length === 0) {
                                        _academicDegrees();
                                    }
                                }} placeholder={t("Select academic degree!")}>
                                    {
                                        academicDegrees?.map((item: academicDegreeType, i: number) =>
                                            <Option key={i} value={item?.id}>{
                                                i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                            }</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={6}>
                            <Form.Item
                                name={`degree_info_id`}
                                label={t("Degree info")}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select onFocus={() => {
                                    if (degreeInfos?.length === 0) {
                                        _degreeInfos();
                                    }
                                }} placeholder={t("Select degree info!")}>
                                    {
                                        degreeInfos?.map((item: degreeInfos, i: number) =>
                                            <Option key={i} value={item?.id}>{
                                                i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                            }</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={6}>
                            <Form.Item
                                name={`partiya_id`}
                                label={t("Party")}
                                rules={[
                                    {
                                        required: false,
                                        message: `${t("Fill in the field!")}`,
                                    },
                                ]}
                            >
                                <Select onFocus={() => {
                                    if (partyas?.length === 0) {
                                        _partyas();
                                    }
                                }} placeholder={t("Select party!")}>
                                    {
                                        partyas?.map((item: partyaType, i: number) =>
                                            <Option key={i} value={item?.id}>{
                                                i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                            }</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <UserAccess form={form} setUserAccesses={setAccesses} userAccesses={accesses} />
                    <hr />
                    <TeacherAccess edit={true} setTeacherAccessList={setTeacherAccessList} teacher_access_list={teacher_access_list} form={form} />
                </TabPane>}
            </Tabs>
            <hr />
            <Row className="bottom-0">
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        <FaUndo /> &nbsp; {t("Reset")}
                    </Button>
                    <Button danger type='primary' className='ms-2' onClick={() => history.goBack()}>{t("Cancel")}</Button>
                    {activeTabKey !== '1' && <Button type='primary' className='ms-2' onClick={() => catchTabPageErrors(String(Number(activeTabKey) - 1))} >{t("Prev")}</Button>}
                    {activeTabKey !== '4' && <Button type='primary' className='ms-2' onClick={() => catchTabPageErrors(String(Number(activeTabKey) + 1))} >{t("Next")}</Button>}
                    {activeTabKey === '4' && <Button disabled={disabledButton} type="primary" className='ms-2' htmlType={errorTabs.length > 0 ? "button" : "submit"}
                        onClick={() => {
                            if (errorTabs?.length > 0) {
                                setActiveTabKey(errorTabs[0])
                            }
                        }
                        }>
                        {t("Submit")}
                    </Button>}
                </Col>
            </Row>
        </>
    )
})

export default FeildsUi;