import {
  Row,
  Col,
  Form,
  Upload,
  Input,
  Select,
  DatePicker,
  Radio,
  Button,
  FormInstance,
} from "antd";
import InputMask from "react-input-mask";
import FormLabelCrud from "components/FormLabel/FormLabelCrud";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import {
  IArea,
  ICitizenship,
  ICountry,
  INationality,
  IRegion,
} from "models/others";
import React from "react";
import moment from "moment";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { BiUpload } from "react-icons/bi";
import useStoreData, { ReturnTypeUseStoreData } from "hooks/useStoreData";
import { onPreview } from "pages/student/crud/froms/utils";
import { UploadFile } from "antd/lib/upload/interface";
import { Card, CardBody } from "reactstrap";
import CardContainer from "components/Widgets/CardContainer";
import { FILE_URL } from "config/utils";

const { Option } = Select;
const { RangePicker } = DatePicker;

type TypeCreateAndUpdateForm = {
  form: FormInstance<any>;
  fileList: UploadFile[];
  passportFile?: string;
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  isStudent?: boolean;
};

const CreateAndUpdateForm: React.FC<TypeCreateAndUpdateForm> = ({
  form,
  fileList,
  setFileList,
  isStudent,
  passportFile,
}): JSX.Element => {
  const { t } = useTranslation();
  const [citizenshipId, setCitizenshipId] = React.useState<number | null>(1);

  const [passportList, setPassportList] = React.useState<UploadFile[]>([]);
  const statuses = useGetAllData({
    url: "/user-statuses",
    perPage: 0,
    isCall: "auto",
  }) as TypeReturnUseGetAllData<ICitizenship>;
  const nationalities = useStoreData({
    key: "nationalities",
  }) as ReturnTypeUseStoreData<INationality>;
  const citizenships = useStoreData({
    key: "citizenships",
  }) as ReturnTypeUseStoreData<ICitizenship>;
  const countries = useGetAllData({
    url: "/countries",
    perPage: 0,
    isCall: "auto",
  }) as TypeReturnUseGetAllData<ICountry>;
  const regions = useGetAllData({
    url: `/regions?filter=${JSON.stringify({
      country_id: form.getFieldValue("permanent_country_id"),
    })}`,
    isCall: "auto",
  }) as TypeReturnUseGetAllData<IRegion>;
  const areas = useGetAllData({
    url: `/areas?filter=${JSON.stringify({
      region_id: form.getFieldValue("permanent_region_id"),
    })}`,
    perPage: 0,
  }) as TypeReturnUseGetAllData<IArea>;

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <CardContainer>
          <Row gutter={[12, 0]}>
            <Col xl={8} lg={8} md={10} sm={10} xs={10}>
              <Form.Item
                label={<FormLabelCrud>Image</FormLabelCrud>}
                name="avatar"
                className="w-100"
              >
                <Upload
                  listType="picture-card"
                  customRequest={dummyRequest}
                  fileList={fileList}
                  onChange={({ fileList: newFileList }) =>
                    setFileList(newFileList)
                  }
                  onPreview={onPreview}
                  maxCount={1}
                  accept={".png"}
                >
                  {fileList.length < 1 && "Rasm yuklash (PNG)"}
                </Upload>
              </Form.Item>
            </Col>
            <Col xl={16} lg={16} md={14} sm={14} xs={14}>
              <Row gutter={[12, 0]}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={<FormLabelCrud>First name</FormLabelCrud>}
                    name="first_name"
                    rules={[
                      { required: true, message: t("Fill in the field!") },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={<FormLabelCrud>Last name</FormLabelCrud>}
                    name="last_name"
                    rules={[
                      { required: true, message: t("Fill in the field!") },
                    ]}
                  >
                    <Input
                      onBlur={() => {
                        String(form.getFieldValue("last_name")).endsWith("a")
                          ? form.setFieldsValue({ gender: "0" })
                          : form.setFieldsValue({ gender: "1" });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={<FormLabelCrud>Middle name</FormLabelCrud>}
                    name="middle_name"
                    rules={[
                      { required: true, message: t("Fill in the field!") },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Date of birth</FormLabelCrud>}
                name="birthday"
                rules={[{ required: true, message: t("Fill in the field!") }]}
              >
                <DatePicker
                  className="w-100"
                  name="birthday"
                  // placeholder={t("Select date")}
                  defaultPickerValue={moment().subtract(5475, "days")}
                  disabledDate={(current) =>
                    current > moment().subtract(5448, "days")
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Gender</FormLabelCrud>}
                name="gender"
                rules={[{ required: true, message: t("Fill in the field!") }]}
              >
                <Radio.Group>
                  <Radio value="1">{t("Male")}</Radio>
                  <Radio value="0">{t("Female")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {isStudent ? (
              <>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    label={<FormLabelCrud>Main phone number</FormLabelCrud>}
                    name="phone"
                    rules={[
                      { required: true, message: t("Fill in the field!") },
                    ]}
                  >
                    <InputMask
                      mask="+\9\98 (99) 999 99 99"
                      className="ant-input"
                      placeholder="+998 (90) 999 99 99"
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    label={<FormLabelCrud>Second phone number</FormLabelCrud>}
                    name="phone_secondary"
                    rules={[
                      { required: false, message: t("Fill in the field!") },
                    ]}
                  >
                    <InputMask
                      mask="+\9\98 (99) 999 99 99"
                      className="ant-input"
                      placeholder="+998 (90) 999 99 99"
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}
          </Row>
        </CardContainer>
      </Col>
      <Col span={24}>
        <CardContainer>
          <Row gutter={[12, 0]}>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Citizenship</FormLabelCrud>}
                name="citizenship_id"
                rules={[
                  { required: true, message: `Please select a region !` },
                ]}
              >
                <Select
                  onFocus={() => citizenships._fetch()}
                  showSearch
                  onChange={(e) => setCitizenshipId(e)}
                  // placeholder={"Fuqarolikni tanlang"}
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={citizenships.loading}
                >
                  {citizenships.items.length
                    ? citizenships.items.map((e: any, i: number) => (
                        <Option key={i} value={e.id}>
                          {e.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Nationality</FormLabelCrud>}
                name="nationality_id"
                rules={[
                  { required: true, message: `Please select a nationality !` },
                ]}
              >
                <Select
                  onFocus={() => nationalities._fetch()}
                  showSearch
                  // placeholder={"Millati tanlang"}
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={nationalities.loading}
                >
                  {nationalities.items.length
                    ? nationalities.items.map((e: any, i: number) => (
                        <Option key={i} value={e.id}>
                          {e.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>JSHSHIR</FormLabelCrud>}
                name={`passport_pin`}
                className="w-100 m-0"
                rules={[
                  {
                    required: citizenshipId === 1 && true,
                    message: t("Fill in the field!"),
                  },
                ]}
              >
                <InputMask
                  mask="99999999999999"
                  placeholder="_ _ _ _ _ _ _ _ _ _ _ _ _ _"
                  className="ant-input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    form.setFieldsValue({
                      passport_pin: e.target.value?.toUpperCase(),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Passport seria and number</FormLabelCrud>}
                name={`passport_seria_and_number`}
                rules={[
                  {
                    required: citizenshipId === 1 && true,
                    message: `${t("Fill in the field!")}`,
                  },
                ]}
              >
                <InputMask
                  mask="aa 9999999"
                  placeholder="AA 9999999"
                  className="ant-input"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    form.setFieldsValue({
                      passport_seria_and_number: e.target.value?.toUpperCase(),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Passport date</FormLabelCrud>}
                name={`passport_date`}
                rules={[
                  {
                    required: citizenshipId === 1 && true,
                    message: `${t("Fill in the field!")}`,
                  },
                ]}
              >
                <RangePicker
                  onCalendarChange={(e, a, d) => {
                    if (d?.range === "start") {
                      let start = new Date(a[0]);
                      let end = new Date(a[0]);
                      end.setFullYear(start.getFullYear() + 10);
                      end.setDate(start.getDate() - 1);
                      let mmnts = [moment(start), moment(end)];

                      form.setFieldsValue({
                        passport_date: mmnts,
                      });
                    }
                    if (d?.range === "end") {
                      let start = new Date(a[1]);
                      let end = new Date(a[1]);
                      start.setFullYear(end.getFullYear() - 10);
                      start.setDate(end.getDate() + 1);
                      let mmnts = [moment(start), moment(end)];

                      form.setFieldsValue({
                        passport_date: mmnts,
                      });
                    }
                  }}
                  placeholder={[`${t("Given date")}`, `${t("Issued date")}`]}
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Passport given by</FormLabelCrud>}
                name={`passport_given_by`}
                rules={[
                  {
                    required: citizenshipId === 1 && true,
                    message: `${t("Fill in the field!")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Passport file</FormLabelCrud>}
                name="passport_file"
              >
                <Upload
                  listType="text"
                  fileList={passportList}
                  customRequest={dummyRequest}
                  maxCount={1}
                  accept={"application/pdf,.png"}
                  onChange={({ fileList: newFileList }) =>
                    setPassportList(newFileList)
                  }
                  onPreview={onPreview}
                  onRemove={() =>
                    form.setFieldsValue({ passport_file: undefined })
                  }
                >
                  <Button
                    icon={<BiUpload className="mb-1 me-2" size={15} />}
                    className="w-100"
                  >
                    {passportList.length ? (
                      <span style={{ color: "green" }}>
                        {passportList[0].name}
                      </span>
                    ) : (
                      t("Select passport file")
                    )}
                  </Button>
                </Upload>
              </Form.Item>
              {passportFile ? (
                <a
                  href={FILE_URL + passportFile}
                  target="blank"
                  className="mb-1 fs-13"
                >
                  SH.T hujjat fayli.(Saqlangan fayl)
                </a>
              ) : null}
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Permanent country</FormLabelCrud>}
                name="permanent_country_id"
                rules={[{ required: true, message: t("Fill in the feild!") }]}
              >
                <Select
                  showSearch
                  onFocus={() => countries.fetch()}
                  onChange={(e: number) => {
                    form.setFieldsValue({
                      permanent_region_id: undefined,
                      permanent_area_id: undefined,
                      permanent_address: undefined,
                    });
                  }}
                  // placeholder={"Select a permanent country"}
                  optionFilterProp="children"
                  loading={countries.loading}
                  allowClear
                >
                  {countries.items.length
                    ? countries.items.map((e: any, i: number) => (
                        <Option key={i} value={e.id}>
                          {e.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Permanent region</FormLabelCrud>}
                name="permanent_region_id"
                rules={[{ required: true, message: t("Fill in the field!") }]}
              >
                <Select
                  showSearch
                  // placeholder={"Select a permanent region"}
                  optionFilterProp="children"
                  onChange={(e: number) => {
                    form.setFieldsValue({
                      permanent_area_id: undefined,
                      permanent_address: undefined,
                    });
                  }}
                  onFocus={() => regions.fetch()}
                  loading={regions.loading}
                  allowClear
                >
                  {regions.items.length
                    ? regions.items.map((e: any, i: number) => (
                        <Option key={i} value={e.id}>
                          {e.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Permanent area</FormLabelCrud>}
                name="permanent_area_id"
                rules={[{ required: true, message: t("Fill in the field!") }]}
              >
                <Select
                  showSearch
                  onChange={() => {
                    form.setFieldsValue({
                      permanent_address: undefined,
                    });
                  }}
                  // placeholder={t(`Select a permanent area`)}
                  optionFilterProp="children"
                  disabled={!regions.items.length}
                  onFocus={() => areas.fetch()}
                  loading={areas.loading}
                  allowClear
                >
                  {areas.items.length
                    ? areas.items.map((e: any, i: number) => (
                        <Option key={i} value={e.id}>
                          {e.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label={<FormLabelCrud>Permanent address</FormLabelCrud>}
                name="permanent_address"
                rules={[{ required: true, message: "" }]}
              >
                <Input.TextArea rows={1} />
              </Form.Item>
            </Col>
          </Row>
        </CardContainer>
      </Col>
      {isStudent ? (
        <Col span={24}>
          <CardContainer>
            <Row gutter={[12, 12]}>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="status"
                  label={<FormLabelCrud>Holati</FormLabelCrud>}
                  rules={[{ required: true, message: t("Fill in the field!") }]}
                >
                  <Select
                    className="w-100"
                    // placeholder={t("Select status")}
                    onFocus={() => statuses.fetch()}
                  >
                    {statuses.data
                      ? Object.entries(statuses.data).map((e) => {
                          const [key, value]: any = e;
                          return (
                            <Option key={key} value={key}>
                              {value}
                            </Option>
                          );
                        })
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Button type="primary" htmlType="submit" className="w-100 mt-4">
                  Saqlash
                </Button>
              </Col>
            </Row>
          </CardContainer>
        </Col>
      ) : null}
    </Row>
  );
};

export default CreateAndUpdateForm;
