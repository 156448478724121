import { Button, Modal, Popconfirm, Tooltip } from "antd";
import instance from "config/_axios";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { delete_data } from "services";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import { userAccessType1, userType, vocationType } from "../type";
import VocationsCreateOrEditUi from "./crud/ui";
type propType = {
    user: userType
    visible: boolean
    setVisible: (visible: boolean) => void
    userAccesses: Array<userAccessType1>
    month: number,
    year: number
    refresh: boolean
    setRefresh: (refresh: boolean) => void
}
const VocationsIndex: FC<propType> = ({
    user, visible, setVisible, month, year, userAccesses, refresh, setRefresh
}): JSX.Element => {
    const { t } = useTranslation()
    const [vocations, setVocations] = useState<Array<any>>([]);
    const [editOrCreate, setEditOrCreate] = useState<{ type: "edit" | "create" | "index", item: vocationType | null }>({
        type: "index", item: null
    })

    useEffect(() => {
        (
            async () => {
                try {
                    const resp = await instance({
                        url: `/vocations?expand=description&filter={"user_id":${user?.id},"month":${month + 1},"year":${year}}&per-page=0`,
                        method: 'get'
                    })
                    if (resp?.data?.status === 1) {
                        setVocations(resp?.data?.data?.items)
                    } else {
                        asyncN("error", "read", resp?.data?.message);
                    }
                } catch (error: any) {
                    asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message);
                }
            }
        )()
    }, [user?.id, visible, refresh])

    return (
        <Modal width={1000} title={
            editOrCreate?.type === "index" ? `${user?.profile?.first_name
                ?? ''} ${user?.profile?.last_name
                ?? ''} ${user?.profile?.middle_name
                ?? ''} ning ${year}-yil ${month + 1}-oydagi ta'tillari`
                :
                editOrCreate?.type === "create" ?
                    `${user?.profile?.first_name
                    ?? ''} ${user?.profile?.last_name
                    ?? ''} ${user?.profile?.middle_name
                    ?? ''} uchun ta'tilni belgilash!`
                    :
                    `Ta'tilni tahrirlash!`
        } visible={visible} onCancel={() => setVisible(false)} footer={false}>
            {
                editOrCreate?.type === "index" ?
                    <>
                        <div className="d-flex justify-content-end mb-1">
                            <Button type="primary" onClick={() => {
                                setEditOrCreate({
                                    item: null,
                                    type: "create"
                                })
                            }}>Qo'shish</Button>
                        </div>
                <div className="tableWrap">
                        <Table className="table">
                            <Thead>
                                <Tr>
                                    <Th>
                                        #
                                    </Th>
                                    <Th>
                                        Nomi
                                    </Th>
                                    <Th>Boshlanishi</Th>
                                    <Th>Tugashi</Th>
                                    <Th style={{ width: "100px" }}>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    vocations?.map((item: vocationType, i: number) =>
                                        <Tr>
                                            <Td>{i + 1}</Td>
                                            <Td>{item?.name}</Td>
                                            <Td>{item?.start_date}</Td>
                                            <Td>{item?.finish_date}</Td>
                                            <Td>
                                                <div className="crud_actions_table">
                                                    {isHasAuthRoute("question_update") && (
                                                        <Tooltip placement="top" title={t("Edit")}>
                                                            <TbFilePencil 
                                                                onClick={() => {
                                                                    setEditOrCreate({
                                                                        type: "edit",
                                                                        item: item
                                                                    })
                                                                }}
                                                                className="edit_btn"
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    {isHasAuthRoute("question_delete") && (
                                                        <Tooltip placement="top" title={t("Delete")}>
                                                            <Popconfirm
                                                                placement="topLeft"
                                                                title={`${t("Deleted it")} ?`}
                                                                okText={t("Yes")}
                                                                cancelText={t("No")}
                                                                onConfirm={() => delete_data("/vocations", item?.id).then((e: any) => {
                                                                    setRefresh(!refresh)
                                                                })}
                                                            >
                                                                <TbFileOff className="delete_btn" />
                                                            </Popconfirm>
                                                        </Tooltip>
                                                    )}
                                                </div></Td>
                                        </Tr>
                                    )
                                }
                            </Tbody>
                        </Table>
                </div>
                    </>
                    :
                    editOrCreate?.type === "create" ?
                        <>
                            <VocationsCreateOrEditUi refresh={refresh} setRefresh={setRefresh} user={user} setAttr={setEditOrCreate} attr={editOrCreate} userAccesses={userAccesses} />
                        </>
                        :
                        <>
                            <VocationsCreateOrEditUi refresh={refresh} setRefresh={setRefresh} user={user} setAttr={setEditOrCreate} attr={editOrCreate} userAccesses={userAccesses} />
                        </>
            }
        </Modal>
    )
}

export default VocationsIndex