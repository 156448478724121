import { FC, useEffect, useState } from "react";
import { Row, Col, Typography, Button, Pagination, Tooltip, Modal, Tag } from "antd";
import moment from "moment";
import { BadgeStatusText } from "components/BadgeStatus";
import { getAllSubject, getOneEduSemester } from "../service";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import EmptyTable from "components/EmptyTable";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { ArrayChecker } from "utils/ArrayChecker";
import CustomBreadcrumb from "components/Breadcrumb";
import { FaEdit } from "react-icons/fa";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import isHasAuthRoute from "utils/isHasRoute";
import UpdateEduSemestrSubjects from "pages/edu_plan/attachSubjects/update";
import ViewSemestrSubjects from "pages/edu_plan/attachSubjects/view";
import { delete_data } from "services";

const { Text } = Typography;

const EduSemesterView: FC = (): JSX.Element => {

    const [num, setNum] = useState(-1)

    const { i18n, t } = useTranslation();
    const location: any = useLocation();
    const history = useHistory();
    const [data, setData] = useState<any>(null);
    const [subjects, setSubjects] = useState<Array<any>>([])
    const [showCreated, setShowCreated] = useState<boolean>(false);
    const [tableOption, setTableOption] = useState<any>([]);
    const [eduSemestrSubject, setEduSemestrSubject] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [modalElement, setMOdalElement] = useState<{
        visible: boolean, edit: boolean, item: any
    }>({ visible: false, edit: false, item: {} });
    const [refresh, setRefresh] = useState<boolean>(false);
    const parentPath: Array<{ name: string, path: string }> = location.state?.parent_path?.length ? location.state?.parent_path : [];
    const a: Array<string> = location?.pathname.split('/');
    const _id = a[a?.length - 1];

    const _breadcrump = [
        {
            name: "Home",
            path: '/',
        },
        ...parentPath,
        {
            name: "Edu semester view",
            path: 'edu-semestr_view'
        },
    ]

    useEffect(() => {
        if (_id) {
            (
                async () => {
                    try {
                        const response = await getOneEduSemester(Number(_id), 'expand=createdBy,updatedBy,description,eduYear,eduType,eduPlan,semestr,course,eduSemestrSubjects');
                        setData(response);
                        setEduSemestrSubject(response?.eduSemestrSubjects);
                        setTableOption(response?.eduSemestrSubjects?.length)
                    } catch (error: any) {
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )();
            (
                async () => {
                    try {
                        setLoading(true);
                        const subjects = await getAllSubject();
                        setSubjects(subjects?.items);
                        setLoading(false);

                    } catch (error: any) {
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [i18n.language, perPage, currentPage, refresh]);

    if (!_id) {
        history.goBack();
    }

    const remoteSubject = async (id: any) => {
        let subjectId: any;
        data?.eduSemestrSubjects?.forEach((item: any) => {
            if (item?.subject_id == id) {
                subjectId = item?.id;
            }
        });
        setLoading(true);
        const res = await delete_data("edu-semestr-subjects", subjectId).then(() => {
            setRefresh(!refresh);
            setLoading(false);
        })
    }

    const getSubjectName = (id: number): string => {
        return subjects?.filter((item: any) => {
            return item?.id === id && item
        })[0]?.name
    };

    const mapper = (element: any) => {
        return [
            <>{getSubjectName(element?.subject_id)} &nbsp;({element.semestr_id}-sm)</>,
            element?.max_ball,
            element?.all_ball_yuklama,
            <div className="crud_actions_table">
                {isHasAuthRoute("edu-semestr-subject_view") && (
                    <Tooltip placement="top" title={t("View")}>
                        <TbFileInvoice 
                            onClick={() => setMOdalElement({
                                visible: true,
                                edit: false,
                                item: element
                            })}
                            className="view_btn"
                        />
                    </Tooltip>
                )}
                {isHasAuthRoute("edu-semestr-subject_update") && (
                    <Tooltip placement="top" title={t("Edit")}>
                        <FaEdit
                            onClick={() => setMOdalElement({
                                visible: true,
                                edit: true,
                                item: element
                            })}
                            className="edit_btn"
                        />
                    </Tooltip>
                )}
                {isHasAuthRoute("edu-semestr-subject_delete") && (
                    <Tooltip placement="top" title={t("Delete")}>
                        <TbFileOff 
                            onClick={() => remoteSubject(element?.subject_id)}
                            className="delete_btn"
                        />
                    </Tooltip>
                )}
            </div>,
        ]
    }

    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <CustomBreadcrumb arr={_breadcrump} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                    {isHasAuthRoute("edu-semestr_update") && <NavLink to={{ pathname: "../edu-semestr_update", state: { id: _id } }} ><Button type="primary" className="float-end d-f" icon={<TbFilePencil />}> &nbsp; {t("Edit")}</Button></NavLink>}
                </Col>
            </Row>
            <hr />
            <Row gutter={[12, 12]} className="mt-2">
                <Col lg={{ span: 12 }} xs={{ span: 24 }} ><Text>{t("Name of edu semestr")}: </Text>&nbsp;{data?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Course")}: </Text>{data?.course?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Semester")}: </Text>{data?.semestr?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Edu plan")}: </Text>{data?.eduPlan?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Edu year")}: </Text>{data?.eduYear?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Start date")}: </Text>{data?.start_date}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("End date")}: </Text>{data?.end_date}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Status")}: </Text><BadgeStatusText status={data?.status} /></Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                    <span>
                        <Text>{t("Confirmation")}: </Text>
                        <Text type={Number(data?.is_checked) === 1 ? "success" : "danger"}>
                            {Number(data?.is_checked) === 1 ? t("Confirmed") : t("Unconfirmed")}
                        </Text>
                    </span>
                </Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Button onClick={() => setShowCreated(!showCreated)} >{t("Show created and updated by")}</Button></Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col lg={{ span: 12 }} xs={{ span: 24 }} >{data?.createdBy && showCreated ? <Row gutter={[12, 12]} className="border p-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Created By")}</Text></Col>
                    <Col span={24} ><Text>{t("First Name")}: </Text>{data?.createdBy?.first_name}</Col>
                    <Col span={24} ><Text>{t("Last Name")}: </Text>{data?.createdBy?.last_name}</Col>
                    <Col span={24} ><Text>{t("Login")}: </Text>{data?.createdBy?.username}</Col>
                    <Col span={24} ><Text>{t("Role")}: </Text>{data?.createdBy?.role}</Col>
                    <Col span={24} ><Text>{t("Created at")}: </Text>{moment(data?.created_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }} >{data?.updatedBy && showCreated ? <Row gutter={[12, 12]} className="border p-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Updated By")}</Text></Col>
                    <Col span={24} ><Text>{t("First Name")}: </Text>{data?.updatedBy?.first_name}</Col>
                    <Col span={24} ><Text>{t("Last Name")}: </Text>{data?.updatedBy?.last_name}</Col>
                    <Col span={24} ><Text>{t("Login")}: </Text>{data?.updatedBy?.username}</Col>
                    <Col span={24} ><Text>{t("Role")}: </Text>{data?.updatedBy?.role}</Col>
                    <Col span={24} ><Text>{t("Updated at")}: </Text>{moment(data?.updated_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}</Col>
            </Row>
            <hr />


            <Row>
                <Row gutter={24} className="w-100" >
                    <Col span={12} className="my-3 "> <h5>{t(data?.name)}</h5> </Col>
                    <Col span={12} className="my-3 d-f justify-content-end"> {isHasAuthRoute("edu-semestr-subject_index") && (
                        <Button type="primary" >
                            <NavLink to={{
                                pathname: `/edu-semestr-subject_create/${data?.id}`,
                                state: {
                                    id: data?.id,
                                    parent_path: [
                                        ...parentPath,
                                    ]
                                }
                            }} className="d-block">{t("Attach subject")}
                            </NavLink>
                        </Button>)}
                    </Col>
                </Row>
                {loading ? <div className="loader-line"></div> : <div style={{ height: '3px' }}></div>}
                <div className="tableWrap">
                <Table id="tech-companies-1" className="table">
                    <Thead>
                        <Tr>
                            <Th>{t("Name")}</Th>
                            <Th>{t("Total score")}</Th>
                            <Th>{t("Total hours")}</Th>
                            <Th className="text-center">{t("Actions")} </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            ArrayChecker(eduSemestrSubject)._ && eduSemestrSubject.map((element: any, i: number) => (
                                <Tr key={i} >
                                    {
                                        mapper(element).map((item: any, i:number) => (
                                            <Td key={element.id} className={i === num ? 'bgcbl' : null} onMouseEnter={() => setNum(i)} onMouseLeave={() => setNum(-1)}>
                                                {
                                                    item
                                                }
                                            </Td>
                                        ))
                                    }
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
                </div>
                <div className="d-f justify-content-center w-100">
                    <EmptyTable data={subjects} loading={loading} />
                </div>
                {tableOption && tableOption?.totalCount > 10 && eduSemestrSubject?.length ? <div className="w-100 table_bottom_footer">
                    <div><Tag color="#F2F2F2" className="text-dark"> Jami {eduSemestrSubject?.length} ta </Tag></div>
                    <Pagination
                        defaultCurrent={currentPage}
                        total={tableOption?.totalCount}
                        defaultPageSize={perPage}
                        onChange={(e) => { setCurrentPage(e) }}
                        onShowSizeChange={(e, pageSize) => { setPerPage(pageSize) }}
                        showSizeChanger
                        pageSizeOptions={['10', '15', '20', '30', '50', '100']}
                    />
                </div> : null}
            </Row>
            <Modal width={modalElement?.edit ? 1300 : 600} title={modalElement?.edit ? "Update semestr subject" : "View semestr subject"}
                footer={false} visible={modalElement?.visible}
                onCancel={() => setMOdalElement({ visible: false, edit: false, item: {} })}>
                {modalElement?.item && modalElement?.edit ? <UpdateEduSemestrSubjects
                    visible={modalElement?.visible}
                    element={modalElement}
                    edit={modalElement?.edit}
                    subjectName={getSubjectName(modalElement?.item?.subject_id) && getSubjectName(modalElement?.item?.subject_id)}
                    item={modalElement?.item}
                    setElement={setMOdalElement}
                    refresh={refresh}
                    setRefresh={setRefresh}
                /> : <ViewSemestrSubjects
                    visible={modalElement?.visible}
                    element={modalElement}
                    edit={modalElement?.edit}
                    subjectName={getSubjectName(modalElement?.item?.subject_id) && getSubjectName(modalElement?.item?.subject_id)}
                    item={modalElement?.item}
                    setElement={setMOdalElement}
                    refresh={refresh}
                    setRefresh={setRefresh} />}
            </Modal>
        </>
    )
}


export default EduSemesterView;