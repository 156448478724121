import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IKPIData } from "models/kpi"


type TYPE_KPIInitialState = {
    visible: 'update' | 'view' | 'create' | null,
    element: IKPIData | null,
    category_id: number | null,
    refetch: boolean,
    category_name: string | null,
    file: string | null
}

const initialState: TYPE_KPIInitialState = {
    visible: null,
    element: null,
    category_id: null,
    refetch: false,
    category_name: null,
    file: null
}



const KPISlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        createModal(state: TYPE_KPIInitialState, action: PayloadAction<{ category_id: number, category_name: string }>) {
            const { category_id, category_name } = action.payload;
            state.category_id = category_id;
            state.category_name = category_name;
            state.visible = 'create'
        },
        updateModal(state: TYPE_KPIInitialState, action: PayloadAction<{ category_id: number, element: IKPIData, category_name: string }>) {
            const { category_id, element, category_name } = action.payload;
            state.category_id = category_id;
            state.category_name = category_name
            state.element = element;
            state.visible = 'update'
        },
        viewModal(state: TYPE_KPIInitialState, action: PayloadAction<{ file: string | null }>) {
            const { file } = action.payload;
            state.file = file;
            state.visible = 'view'
        },
        closeModal(state: TYPE_KPIInitialState) {
            state.visible = null;
            // state.category_id = null;
            state.element = null;
            state.refetch = false;
            state.category_name = null;
            state.file = null;
        },
        reFetch(state: TYPE_KPIInitialState) {
            state.refetch = !state.refetch;
        }
    }
})



export const KPI_ACTIONS = KPISlice.actions;

export default KPISlice;