import { Form, Modal, message, Input, Col, Button, Switch, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getOneDirection } from '../service';
import { useTranslation } from 'react-i18next';
import { getAllFaculties } from '../../faculty/service';


type EditDirectionType = {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setReRequest: (reRequest: boolean) => void,
    id: number | null,
    reRequest: boolean
}

const { Option } = Select;

const EditDirection: FC<EditDirectionType> = ({ visible, setVisible, id, setReRequest, reRequest }) => {

    const { i18n, t } = useTranslation();
    const [form] = Form.useForm();
    const [faculties, setFaculties] = useState<Array<any>>([])
    const [status, setStatus] = useState<boolean>(true);
    const [clicked, setClicked] = useState<boolean>(false);

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOneDirection(id, 'expand=description&self=1');
                        form.setFieldsValue({
                            name: response.name,
                            description: response?.description,
                            faculty_id: response?.faculty_id,
                            status: Number(response.status) === 1 ? setStatus(true) : setStatus(false)
                        })
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        await getAllFaculties()
                            .then((response: any) => {
                                setFaculties(response?.items)
                            }).catch((error: any) => {
                                asyncN('error', 'read', error)
                            })
                    } catch (error: any) {
                        message.error(error?.response?.message)
                    }
                }
            )()
        } else {
            setFaculties([])
        }
    }, [visible])


    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            formdata.append(`name[${i18n.language}]`, values.name)
            formdata.append(`description[${i18n.language}]`, values?.description)
            formdata.append('faculty_id', values?.faculty_id)
            formdata.append('status', status ? '1' : '0');

            const response = await instance({ url: `/directions/${id}`, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)

                    })
            } else {
                asyncN("error", 'update', response.data?.message).then(() => {
                    setVisible(false);
                    resetFields()
                    setReRequest(!reRequest)
                })
            }
            setClicked(false)
        } catch (error) {
            setClicked(false)
            setReRequest(!reRequest)
        }
    }

    const resetFields = () => form.resetFields();


    return (
        <>
            <Modal
                title={t("Direction edit")}
                visible={visible}
                onCancel={() => { setVisible(false); resetFields() }}
                okText="Submit"
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Form.Item
                        label={t("Name")}
                        name="name"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Input placeholder={t("Enter direction name")+" ... "} />
                    </Form.Item>
                    <Form.Item
                        label={t("Description")}
                        name="description"
                        shouldUpdate
                        rules={[{ required: false }]}
                    >
                        <Input.TextArea placeholder={t("Enter description for direction")+" ... "} />
                    </Form.Item>
                    <Form.Item
                        label={t("Texnikum")}
                        name="faculty_id"
                        rules={[{ required: true, message: t("Select a faculty") }]}
                    >
                        <Select
                            showSearch
                            placeholder={t("Select a faculty")+" ... "}
                            optionFilterProp="children"
                            filterOption={(input, option:any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                faculties.length ? faculties.map((e: any, i: number) => {
                                    return <Option key={i} value={e.id}>{e.name}</Option>
                                }) : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t("Status")}
                        name="status"
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                    <hr />
                    <Col span={24} className="text-end mt-2">
                        <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </Col>
                </Form>
            </Modal>
        </>
    )
}


export default EditDirection;