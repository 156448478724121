import { Button, Empty, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { FaKey, FaList } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import CustomBreadcrumb from "components/Breadcrumb";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";
import { secretKeyStudent, secretKeysType } from "./type";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import isHasRoute from "utils/isHasRoute";


const GetSecretKeys: FC = (): JSX.Element => {
    const url: string = useLocation()?.pathname;
    let a: Array<string> = url.split("/");
    const history = useHistory();
    const { t } = useTranslation();
    const [loadingKeys, setLoadingKeys] = useState<boolean>(false)
    const [loadingGetKeys, setLoadingGetKeys] = useState<boolean>(false)
    const [secretKeys, setSecretKeys] = useState<secretKeysType | null>(null)
    const [isGenereteKeys, setIsGenerateKeys] = useState<boolean>(true)
    const _breadcrump = [
        {
            name: "Home",
            path: '/',
        },
        {
            name: "Exams",
            path: 'exam_index'
        },
        {
            name: "Generate and get secret keys",
            path: 'generate-and-get-secret-keys'
        },
    ]

    const generateKeys = async () => {

        try {
            setLoadingKeys(true)
            let data = new FormData()
            data.append("exam_id", a[a?.length - 1])
            const response = await instance({
                method: "post",
                url: "/exams/generate-passwords",
                data: data
            })
            if (response?.data?.status === 1) {
                asyncN("success", "create", response?.data?.message)
                setIsGenerateKeys(false)
                setLoadingKeys(false)
            } else {
                asyncN("error", "create", response?.data?.message)
                setLoadingKeys(false)
            }

        } catch (e) {
            asyncN("error", "create", "Error generate keys!!!")
            setLoadingKeys(false)
        }
    }

    useEffect(() => {
        getSecretKeys()
    }, [])

    const getSecretKeys = async () => {

        try {
            setLoadingGetKeys(true)
            let data = new FormData()
            data.append("exam_id", a[a?.length - 1])
            const response = await instance({
                method: "post",
                url: "/exams/get-passwords",
                data: data
            })
            if (response?.data?.status === 1) {
                asyncN("success", "read", response?.data?.message)
                setSecretKeys(response?.data?.data)
                setLoadingGetKeys(false)
            } else {
                asyncN("error", "read", response?.data?.message)
                setLoadingGetKeys(false)
            }

        } catch (e) {
            asyncN("error", "read", "Error get secret keys!!!")
            setLoadingGetKeys(false)
        }
    }
    const saveBuffer = (item: secretKeyStudent) => {
        navigator.clipboard.writeText(`Full name: ${item?.full_name},\n Desiction: ${item?.direction}.\n Secret key: ${item?.password} `)
        message.success(`Copied! Full name: ${item?.full_name},\n Desiction: ${item?.direction}.\n Secret key: ${item?.password} `)
    }

    return (
        <div>
            <CustomBreadcrumb arr={_breadcrump} />
            <div className="d-flex justify-content-between mt-2">
                {isHasRoute("exam_generate-passwords") ? <Button icon={<FaKey className="me-1" />} loading={loadingKeys} onClick={generateKeys} className="text-light bg-success">{t("Generate keys")}</Button> : null}
                {isHasRoute("exam_get-passwords") ? <Button icon={<FaList className="me-1" />} loading={loadingGetKeys} onClick={getSecretKeys} type="primary">{t("Get keys")}</Button> : null}
            </div>
            <hr />
            {
                secretKeys !== null ? <Table>
                    {
                        secretKeys?.is_ok ? secretKeys?.students?.length > 0 ?
                            <>
                                <Thead>
                                    <Tr>
                                        <Th>#</Th>
                                        <Th>Full name</Th>
                                        <Th>Direction</Th>
                                        <Th>Secret key</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        secretKeys?.students?.map((item: secretKeyStudent, i: number) =>
                                            <Tr key={i}>
                                                <Td>{i + 1}</Td>
                                                <Td onCopy={() => saveBuffer(item)}>{item?.full_name}</Td>
                                                <Td onCopy={() => saveBuffer(item)}>{item?.direction}</Td>
                                                <Td onCopy={() => saveBuffer(item)}>{item?.password}</Td>
                                            </Tr>
                                        )
                                    }
                                </Tbody>
                            </> : <Empty description={"Students not found!"} /> : ""
                    }
                </Table> :
                    <Empty />
            }
        </div>
    )
}

export default GetSecretKeys