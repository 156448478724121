import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const Citizenship: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="citizenships"
                indexTitle="Citizenship"
                editTitle="Citizenship edit"
                viewTitle="Citizenship view"
                createTitle="Citizenship create"
                permissions={{
                    view_: "citizenship_view",
                    delete_: "citizenship_delete",
                    update_: "citizenship_update",
                    create_: "citizenship_create"
                }}
            />
        </div>
    )
}

export default Citizenship;