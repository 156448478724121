import { FC } from 'react';
import { useHistory } from 'react-router';
import { Breadcrumb } from 'antd';
import { FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export type BreadcrumbArgumentsType = {
    arr: Array<{
        name: string,
        path: string
    }>
}

const CustomBreadcrumb: FC<BreadcrumbArgumentsType> = ({ arr }): JSX.Element => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const path: any = (element: { name: string, path: string }) => element.path.startsWith('/') ? element.path : `/${element.path}`


    return (
        <>
            <div className="d-flex align-items-center">
                <FaArrowLeft className="goBackBtn" onClick={() => history.goBack()} />
                <Breadcrumb>
                    {
                        arr.length && arr.map((element: { name: string, path: string }, index: number) => {
                            if (arr.length - 1 !== index) {
                                return (
                                    <Breadcrumb.Item key={index} className="cursor-pointer" onClick={() => history.push(path(element))}>{t(element.name)}</Breadcrumb.Item>
                                )
                            } else {
                                return <Breadcrumb.Item key={index}>{t(element.name)}</Breadcrumb.Item>
                            }
                        })
                    }
                </Breadcrumb>
            </div>
        </>
    )
}


export default CustomBreadcrumb;