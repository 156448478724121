import React from "react";
import styles from '../styles/styles.module.scss';




type PropsInformer = {
    id: number,
    lecture_id: null | number,
    parent_id: number | null,
    subject_category_id: number
}


const TimeTableUniqueCode: React.FC<PropsInformer> = ({ id, lecture_id, parent_id, subject_category_id }): JSX.Element => {


    if (!parent_id && !lecture_id) {
        return (
            <span className={styles.dot_simvol} >
                {
                    subject_category_id === 1 ? `${id}-M` : `${id}-S`
                }
            </span>
        )
    } else if (!parent_id && lecture_id) {
        return (
            <span className={styles.dot_simvol} >{`${lecture_id}-${id}-S`} </span>
        )
    } else {
        return (
            <span className={styles.dot_simvol} >
                {subject_category_id === 1 ? `${parent_id}` : lecture_id ? `${lecture_id}-${parent_id}` : `${parent_id}`}
            </span>
        )
    }


}


export default React.memo(TimeTableUniqueCode);