import { Pie, G2 } from '@ant-design/plots';
import type { PieConfig } from '@ant-design/plots/es/interface';
import useGetAllData from 'hooks/useGetAllData';
import { useEffect, useState } from 'react';

function StudentStatisticByRegion({students} : any): JSX.Element{
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
      if (students.data.items.length){
          setData(students.data.items.map((item: any, index: any) => ({
            region: item.name,
            count: item.studentsCount
          })))
      }
  }, [students.data.items.length])
    const G = G2.getEngine('canvas')
    const config: PieConfig = {
        appendPadding: 10,
        data,
        angleField: 'count',
        colorField: 'region',
        radius: 0.75,
        legend: false,
        label: {
          type: 'spider',
          labelHeight: 40,
          formatter: (data:any, mappingData:any) => {
            const group = new G.Group({});
            group.addShape({
              type: 'circle',
              attrs: {
                x: 0,
                y: 0,
                width: 40,
                height: 50,
                r: 5,
                fill: mappingData.color,
              },
            });
            group.addShape({
              type: 'text',
              attrs: {
                x: 10,
                y: 8,
                text: `${data.region}`,
                fill: mappingData.color,
              },
            });
            group.addShape({
              type: 'text',
              attrs: {
                x: 0,
                y: 25,
                text: `${data.count}`,
                fill: 'rgba(0, 0, 0, 0.65)',
                fontWeight: 700,
              },
            });
            return group;
          },
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ],
      };

    return <Pie {...config} />;
}

export default StudentStatisticByRegion;