import { Form, Modal, message, Input, Col, Button, Switch, Select, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getAllSubjects, getOneSubject } from '../service';
import { useTranslation } from 'react-i18next';
import { getAllLecterns } from '../../lectern/service';
import { getAllSemestrs } from 'pages/semestr/service';


type EditSubjectType = {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setReRequest: (reRequest: boolean) => void,
    id: number | null,
    reRequest: boolean
}

const { Option } = Select;

const EditSubject: FC<EditSubjectType> = ({ visible, setVisible, id, setReRequest, reRequest }) => {

    const { i18n, t } = useTranslation();
    const [form] = Form.useForm();
    const [lecterns, setLecterns] = useState<Array<any>>([])
    const [status, setStatus] = useState<boolean>(true);
    const [clicked, setClicked] = useState<boolean>(false);
    const [subjects, setSubjects] = useState<Array<{ id: number, name: string }>>([])
    const [semestrs, setSemestrs] = useState<Array<{ id: number, name: string }>>([])

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOneSubject(id, 'expand=description,parent,smemstr');
                        form.setFieldsValue({
                            name: response.name,
                            description: response?.description,
                            kafedra_id: response?.kafedra_id,
                            status: Number(response.status) === 1,
                            parent_id: response?.parent_id,
                            semestr_id: response?.semestr_id
                        })
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        await getAllLecterns()
                            .then((response: any) => {
                                setLecterns(response?.items)
                            }).catch((error: any) => {
                                asyncN('error', 'read', error)
                            })
                    } catch (error: any) {
                        message.error(error?.response?.message)
                    }
                }
            )()
        } else {
            setLecterns([])
        }
    }, [visible])


    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            formdata.append(`name[${i18n.language}]`, values.name)
            formdata.append(`description[${i18n.language}]`, values?.description)
            formdata.append('kafedra_id', values?.kafedra_id)
            if (values?.parent_id) formdata.append('parent_id', values?.parent_id)
            formdata.append('semestr_id', values?.semestr_id)
            formdata.append('status', values?.status ? '1' : '0');

            const response = await instance({ url: `/subjects/${id}`, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)

                    })
            } else {
                asyncN("error", 'update', response.data?.message).then(() => {
                    setVisible(false);
                    resetFields()
                    setReRequest(!reRequest)
                })
            }
            setClicked(false)
        } catch (error) {
            setClicked(false)
            setReRequest(!reRequest)
        }
    }

    const resetFields = () => form.resetFields();

    useEffect(() => {
        (
            async () => {
                const response = await getAllSubjects(0,1);
                setSubjects(response?.items)
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                const response = await getAllSemestrs();
                setSemestrs(response?.items)
            }
        )()
    }, [])


    return (
        <>
            <Modal
                title={t("Subject edit")}
                visible={visible}
                onCancel={() => { setVisible(false); resetFields() }}
                okText="Submit"
                footer={null}
                width={850}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                label={t("Name")}
                                name="name"
                                shouldUpdate
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <Input placeholder={t("Enter subject name") + " ... "} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                label={t("Description")}
                                name="description"
                                shouldUpdate
                                rules={[{ required: false }]}
                            >
                                <Input placeholder={t("Enter description for subject") + " ... "} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                label={t("Lectern")}
                                name="kafedra_id"
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <Select
                                    showSearch
                                    placeholder={t("Select a lectern") + " ... "}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        lecterns.length ? lecterns.map((e: any, i: number) => {
                                            return <Option key={i} value={e.id}>{e.name}</Option>
                                        }) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                label={t("Semestr")}
                                name="semestr_id"
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <Select
                                    showSearch
                                    placeholder={t("Select a semestr") + " ... "}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        semestrs?.length ? semestrs.map((e: any, i: number) => {
                                            return <Option key={i} value={e.id}>{e.name}</Option>
                                        }) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                label={t("Parent")}
                                name="parent_id"
                                rules={[{ required: false, message: t("Fill in the field!") }]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder={t("Select a subject") + " ... "}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        subjects.length ? subjects.map((e: any, i: number) => {
                                            return <Option key={i} value={e.id}>{e.name}</Option>
                                        }) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label={t("Status")}
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch checkedChildren="On" unCheckedChildren="Off" onChange={() => setStatus(!status)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr />
                    <Col span={24} className="text-end mt-2">
                        <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </Col>
                </Form>
            </Modal>
        </>
    )
}


export default EditSubject;