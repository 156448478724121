import { Col, Pagination, Popconfirm, Row, Tag } from "antd";
import EmptyTable from "components/EmptyTable";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import useGetAllData from "hooks/useGetAllData";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { BiRightArrowAlt } from "react-icons/bi";
import { TbFileOff } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { delete_data } from "services";
import { useAppSelector } from "store/services";
import isHasAuthRoute from "utils/isHasRoute";

const CheckingContentIndex: FC = (): JSX.Element => {

    const auth: any = useAppSelector(state => state.auth);
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)
    const [refresh, setRefresh] = useState<boolean>(false)
    const _teacherContents = useGetAllData({
        url: `/teacher-contents?expand=subject,user&per-page=${perPage}&page=${currentPage}`, isCall: 'auto', refetch: [refresh]
    })
    
    useEffect(() => {
        setRefresh(!refresh)
    }, [currentPage, perPage])

    return (
        <div>
            <ForTitle title='Contenter subjects' buttons={[null]}/>
            <ForTable perPage={perPage} setPerPage={setPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} totalCount={_teacherContents.data._meta.totalCount} datas={_teacherContents.items} theads={
                    [
                        <>Subject name</>,
                        <>Amallar</>
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            <Link to={`/subjects/${this?.subject?.id}/teachers?set=0`}>
                                {this?.subject?.name}
                            </Link>,
                            <>
                            {
                                isHasAuthRoute("teacher-content_delete") ?
                                <>
                                    <Popconfirm title="Delete subject from contenter!"
                                        okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                                        onConfirm={() => delete_data("/teacher-contents", this?.id).then(() => {
                                            setRefresh(!refresh)
                                        })}>
                                        <TbFileOff style={{ cursor: "pointer" }} color="red" />
                                    </Popconfirm>
                                </>
                                : ''
                            }
                            </>
                        ]
                    }
                } loading={_teacherContents.loading}/>
        </div>
    )
}

export default CheckingContentIndex;