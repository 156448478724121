import { useHistory, useRouteMatch, match } from 'react-router';
import { Col, Divider, Input, Modal, Pagination, Row, Spin, Tag } from "antd";
import { BiCheck, BiDetail, BiQuestionMark, BiRightArrowAlt, BiX } from "react-icons/bi";
import { Card, CardBody } from "reactstrap";
import useGetAllData from "hooks/useGetAllData";
import useGetOneData from "hooks/useGetOneData";
import { useTranslation } from 'react-i18next';
import PageContentTitle from 'components/Title';
import { ChangeEvent, useState } from 'react';
import isHasAuthRoute from 'utils/isHasRoute';
import { exam_student_status_list } from '../exam_status_list';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { useAppSelector } from 'store/services';
import isRole from 'utils/isRole';
import AppelationPopver from '../exam_students/components/AppelationPopover';
import ModalTitle from 'components/ModalTitle';
import { FaCheckSquare, FaLaptopCode, FaMinusSquare } from 'react-icons/fa';
import ForTable from 'components/ForTable';


export const encodeKey = (key: string): string => {
    if (Boolean(Number(key))) {
        let a: string = ""
        for (let i = 0; i < key?.length; i++) {
            a += String.fromCharCode(Number(key[i]) + 97)
        }
        return a
    } else {
        return "no encode";
    }
}

export const decodeKey = (key: string): string => {
    let a: string = ""
    for (let i = 0; i < key?.length; i++) {
        a += String(Number(key.charCodeAt(i) - 97));
    }

    return a;
}


const typeAppeal = (appealType: number | null) => {
    switch (appealType) {
        case 1:
            return <div>Asosli</div>
        case 2:
            return <div>Asosiz</div>
        case 3:
            return <div>Texnik</div>
        case 4:
            return <div>Asosli va texnik</div>
        default:
            return <div>Korib chiqilmoqda</div>
    }
}


const AppelationCards: React.FC = (): JSX.Element => {

    const { t } = useTranslation();
    const match: match<any> | null = useRouteMatch('/appelation_cards/:exam_id');
    const exam_id = match?.params?.exam_id;
    const userData: any = useAppSelector(state => state.auth);
    const history = useHistory();

    const [visible, setVisible] = useState<number | null>(null);
    const [selectedElement, setSelectedElement] = useState<{ [key: string | number]: any } | null>(null);
    const { value, writeToUrl } = useUrlQueryParams({ perPage: 20, currentPage: 1 });


    const _exam = useGetOneData({ url: `/exams/${exam_id}?expand=subject&fields=id,name,subject.name`, isCall: 'auto' })
    const exam_appeals = useGetAllData({ url: `/exam-appeals?expand=description&filter=${JSON.stringify({ exam_id })}&filter-like=${JSON.stringify({ student_id: value?.filter_like?.id_student })}`, isCall: 'auto', perPage: value?.perPage, currentPage: value.currentPage, refetch: [value.filter?.exam_id, value?.filter_like?.id_student] });


    return (
        <div className='not_user_select'>
            <Modal
                title={<ModalTitle>{isRole('admin') ? selectedElement?.id : encodeKey(String(selectedElement?.id))}-{isRole('admin') ? selectedElement?.exam_student_id : encodeKey(String(selectedElement?.exam_student_id))} - apelatsiya</ModalTitle>}
                visible={visible !== null}
                onCancel={() => setVisible(null)}
                closeIcon={<BiX size={20} />}
                footer={null}
            >
                <AppelationPopver text={selectedElement?.appeal_text} conclusion={selectedElement?.teacher_conclusion} />
            </Modal>
            <Spin tip="Loading..." spinning={exam_appeals.loading}>
                <Row gutter={[12, 12]} className="mt-2">
                    <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                        <PageContentTitle title={`${_exam.data?.subject?.name} | ${_exam.data?.name}`} />
                    </Col>
                    <Col xl={6} lg={6} md={6} xs={24}>
                        {(userData.user.role as string[]).includes('admin') ?
                            <>
                                <Input
                                    placeholder={`${t("Talaba id ma'lumoti bo'yicha qidirish.")} ...`}
                                    value={value.filter_like?.id_student}
                                    onChange={(e: ChangeEvent<HTMLInputElement>): void => { writeToUrl({ name: 'id_student', value: e?.target?.value }) }}
                                    className="w-100 ant_input_custom"
                                />
                            </> : null}
                    </Col>
                </Row>
                <Row gutter={[12, 12]} style={{ backgroundColor: "#F5F7F9" }} className="px-2 py-3 mt-2">
                    {
                        exam_appeals.items?.length ? exam_appeals.items.sort((a, b) => { return a.type - b.type }).map((element, index) => {
                            return (
                                <Col key={index} xl={6} lg={8} md={12} xs={24}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-between">
                                                <span className='fs-16' style={{ color: "#096dd9" }}>
                                                    {isRole('admin') ? element?.student_id : encodeKey(String(element?.student_id))}-{isRole('admin') ? element?.id : encodeKey(String(element?.id))} - apelatsiya</span>
                                                <span className='cursor-pointer' onClick={() => { setVisible(element.id); setSelectedElement(element) }} >
                                                    {typeAppeal(element?.type)}
                                                </span>
                                            </div>
                                            <Divider className='mb-3'></Divider>
                                            <div className={Number(element?.isChecked) && element?.plagiat_percent && Number(element?.plagiat_percent) !== 0 ? "d-f justify-content-between w-100" : 'w-100 text-end'}>

                                                {/* {Number(element?.isChecked) && element?.plagiat_percent && Number(element?.plagiat_percent) !== 0 ?
                                                    <Tag color={Number(element?.isPlagiat) ? "#FCE8E6" : "#E8F0FE"} className="rounded text-center" style={Number(element?.isPlagiat) ? { color: '#A50E0E' } : { color: '#1967D2' }} >
                                                        Plagiat: {element?.plagiat_percent}%
                                                    </Tag> : null} */}
                                                {
                                                    isHasAuthRoute('exam-checking_appeal') ?
                                                        (
                                                            <Tag color={element?.type ? "#E6F4EA" : "#E8F0FE"} className="cursor-pointer rounded min_w_150 text-center" style={{ color: element?.type ? '#137333' : '#1967D2' }} onClick={() => history.push(`/appelation_checking/${element?.id}`)} >{element?.type ? "Tekshirilgan" : "Tekshirish"}<BiRightArrowAlt color={element?.type ? '#137333' : '#1967D2'} /></Tag>
                                                        ) : exam_student_status_list(element?.status)
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>)
                        }) : null}
                </Row>
                <ForTable toUrl={true} setCurrentPage={() => {}} setPerPage={() => {}} currentPage={value.currentPage} perPage={value.perPage} 
                totalCount={exam_appeals.data?._meta.totalCount} datas={exam_appeals.items} loading={exam_appeals.loading} theads={[
                    <>{t("Javob id")}</>,
                    <>{t("Javob holati")}</>,
                    <>{t("Javob natijasi")}</>,
                ]} tbodys={function(this: any){
                    return [
                        <>
                            {
                                isRole('admin') ? 
                                this?.student_id : encodeKey(String(this.student_id))}-{isRole('admin') ? this?.id 
                                : 
                                encodeKey(String(this?.id))
                            } - javob
                        </>,
                        <>
                        {
                            typeAppeal(this?.type)
                        }
                        </>,
                        <>
                        {
                            isHasAuthRoute('exam-checking_appeal') ?
                                <span onClick={() => { history.push(`/appelation_checking/${this?.id}`)}} className="text-decoration-none" >
                                    {
                                        <Tag color="#E6F4EA" className="cursor-pointer rounded min_w_150 text-center" style={{ color: '#137333' }} >
                                        {
                                            Number(this?.type) ?
                                            <>Tekshirilgan<BiRightArrowAlt color='#137333' /></>
                                            :
                                            <>Tekshirish<BiRightArrowAlt color='rgb(19, 115, 51)' /></>
                                        }
                                        </Tag>
                                    }
                                </span>
                                : exam_student_status_list(this?.status)
                        }
                        </>
                    ]
                }}/>
            </Spin>
        </div >
    )

}




export default AppelationCards;


/** 
@permissons
exam-appeal_create
exam-appeal_delete
exam-appeal_index
exam-appeal_update
exam-appeal_view
*/
