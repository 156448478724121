import { Form, Input, Modal, Switch, Button } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import SimpleIndex from "pages/common/simple_index";

const NationalitiesModal = (props:any) => {


  return(<>
  <SimpleIndex indexTitle="Tillar" url="languages" createTitle="Yaratish" editTitle="Tahrirlash" viewTitle="Korish" permissions={{
    view_: 'languages_view',
    delete_: 'languages_delete',
    update_: 'languages_update',
    create_: 'languages_create'
  }} expand={false} customInputs={[{
    title: 'Til kodi',
    name: 'lang_code'
  }]}/>
  {/* <Modal bodyStyle={{paddingBottom:0}} title={!props.isModalEdit?"Ma'lumot yaratish":"Ma'lumotni o'zgartirish"} width={800} visible={props.isModalVisible} onCancel={()=>{props.setIsModalVisible(false);props.setIsModalEdit(false)}}
  footer={false}
  >
    <Form
      form={props.form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={props.onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <div className="row">
        <div className="col-md-6">
          <Form.Item
            label="Name(uz)"
            name="name_uz"
            rules={[{ required: true, message: 'Name(uz) ni kiriting!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Name(ru)"
            name="name_ru"
            rules={[{ required: false, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
        </div>

        <div className="col-md-6">
          <Form.Item
            label="Name(en)"
            name="name_en"
            rules={[{ required: false, message: 'Name(en) ni kiriting!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Sort"
            name="sort"
            rules={[{ required: true, message: 'Sortni kiriting!' }]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
      </div>
        <div className="modal-footer">
              <Form.Item
                name="status"
                style={{position:'absolute', left:'22px'}}
                rules={[{ required: false, message: 'Dars soatlarini kiriting!' }]}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={props.status}
                  onChange={(e)=>props.setstatus(e)}
                />
                <span className="ms-3">{props.status?"Faol":"Faol emas"}</span>
              </Form.Item>
                <Form.Item>
                    <Button onClick={()=>props.setIsModalVisible(false)} type="ghost">
                      Yopish
                    </Button>
                </Form.Item>

                {
                props.isModalEdit?
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    O'zgarishni saqlash
                  </Button>
                </Form.Item>:
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Saqlash
                  </Button>
                </Form.Item>
                }
        </div>
    </Form>
  </Modal> */}
  </>)
}
export default NationalitiesModal;