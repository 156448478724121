import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Row, Col, Select, Button, Switch, Input, DatePicker, InputNumber } from 'antd';
import moment from 'moment';
import { FaTimes } from 'react-icons/fa';
import { getAllEduYear } from '../../edu_years/service';
import { getAllFaculties } from '../../faculty/service';
import { getAllDirections } from '../../directions/service';
import { getAllEduTypes } from '../../edu_types/service';
import { getAllLanguages } from 'services'
import { useTranslation } from 'react-i18next';
import { getAllEduForms } from 'pages/edu_form/service';

type FormsType = {
    title: string,
    name: string,
    required: boolean,
    key: string,
    items: Array<any>,
    onFocus: any | null
}

type PropsType = {
    resetFields: () => void,
    status: boolean,
    setStatus: (status: boolean) => void,
    clicked: boolean,
    type: 'create' | 'update',
    dates: any,
    form: any,
    ref?: any,
    data?: any
}

type LoadingState = 'edu_year_id' | 'edu_form_id' | 'edu_type_id' | 'faculty_id' | 'direction_id';

const { Option } = Select;
const EduPlanUi: FC<PropsType> = ({ resetFields, status, setStatus, clicked, type, dates, form, ref, data }): JSX.Element => {

    const history = useHistory();
    const { i18n, t } = useTranslation();
    const [languages, setLanguages] = useState<Array<any>>([]);
    const [names, setNames] = useState<Array<string>>(['uz']);
    const [edu_years, setEduYears] = useState<Array<any>>([]);
    const [faculties, setFaculties] = useState<Array<any>>([]);
    const [directions, setDirections] = useState<Array<any>>([]);
    const [edu_types, setEduTypes] = useState<Array<any>>([]);
    const [edu_forms, setEduForms] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<LoadingState | null>(null)

    useEffect(() => {
        _getDirection();
        _getEduForm();
        _getEduType();
        _getEduYear();
        _getFaculty();
        _getLanguages();
    }, [])

    const _loading = (name: LoadingState) => {
        if (name) {
            return name === loading;
        }
        return false
    }

    const _getLanguages = async () => {
        if (!languages.length) {
            const response: any = await getAllLanguages();
            setLanguages(response?.items)
        }
    }

    useEffect(() => {
        _getLanguages()
    }, [])

    const _getEduYear = async () => {
        if (!edu_years.length) {
            setLoading('edu_year_id');
            const getEduYears = await getAllEduYear();
            setEduYears(getEduYears?.items)
            setLoading(null);
        }
    }

    const _getFaculty = async () => {
        if (!faculties.length) {
            setLoading('faculty_id');
            const getFaculties = await getAllFaculties();
            setFaculties(getFaculties?.items)
            setLoading(null);
        }
    }

    const _getEduForm = async () => {
        if (!edu_forms.length) {
            setLoading('edu_form_id');
            const getEduForms = await getAllEduForms();
            setEduForms(getEduForms?.items)
            setLoading(null);
        }
    }

    const _getDirection = async () => {
        setLoading('direction_id');
        const getDirections = await getAllDirections(0, undefined, `filter={"faculty_id":${form.getFieldValue('faculty_id')}}`);
        setDirections(getDirections?.items);
        setLoading(null);
    }

    const _getEduType = async () => {
        if (!edu_types.length) {
            setLoading('edu_type_id');
            const getEduTypes = await getAllEduTypes();
            setEduTypes(getEduTypes?.items);
            setLoading(null);
        }
    }



    const forms: Array<FormsType> = [
        {
            title: 'Edu Years',
            name: 'edu_year_id',
            required: true,
            key: 'edu year',
            items: edu_years,
            onFocus: _getEduYear
        },
        {
            title: 'Technikumes',
            name: 'faculty_id',
            required: true,
            key: 'faculty',
            items: faculties,
            onFocus: _getFaculty
        },
        {
            title: 'Directions',
            name: 'direction_id',
            required: true,
            key: 'direction',
            items: directions,
            onFocus: _getDirection
        },
        {
            title: 'Education Types',
            name: 'edu_type_id',
            required: true,
            key: 'edu type',
            items: edu_types,
            onFocus: _getEduType
        },
        {
            title: 'Education Forms',
            name: 'edu_form_id',
            required: true,
            key: 'edu form',
            items: edu_forms,
            onFocus: _getEduForm
        },
    ]

    function disabledDate(current: any, name: string) {
        const starting_date = form.getFieldValue(name);
        return current && current < moment(starting_date);
    }

    const onAddNewForm = () => {
        const langId = languages.filter(e => !names.includes(e.lang_code))
        if (langId.length) {
            setNames(prevState => ([...prevState, langId[0].lang_code]))
        }
    }


    const removeInput = (id: string) => {
        const newNames = names.filter(e => e !== id);
        setNames(newNames)
    }

    const selectLanguage = (newValue: string, oldValue: string) => {
        const index = names.indexOf(oldValue);

        if (!names.includes(newValue)) {
            names.splice(index, 1, newValue)
            setNames([...names]);
        } else {
            return
        }
    }

    return (
        <>
            <Row gutter={[12, 0]}>
                {
                    type === "create" ? names.map((e: any) => {
                        return (
                            <React.Fragment key={e} >
                                <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                    <Form.Item
                                        label={`${t("Name")} (${e})`}
                                        name={`name[${e}]`}
                                        shouldUpdate
                                        rules={[{ required: true, message: `${t("Fill in the field")}(${e})!` }]}
                                    >
                                        <Input placeholder={`${t("Enter edu plan name")} (${e}) ...`} />
                                    </Form.Item>

                                </Col>
                                <Col key={e} xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} className="form_ui_flex">
                                    <Form.Item
                                        label={`${t("Description")} (${e})`}
                                        name={`description[${e}]`}
                                        shouldUpdate
                                        rules={[{ required: false, message: `${t("Fill in the field")}(${e})!` }]}
                                        className="form_ui_flex_form_item"
                                    >
                                        <Row wrap={false}>
                                            <Input placeholder={`${t("Enter description for edu plan")} (${e}) ...`} />
                                            <Select onFocus={_getLanguages} defaultValue={e} style={{ width: '100px' }} className="mx-2" onChange={(newElement) => selectLanguage(newElement, e)}>
                                                {
                                                    languages.length && languages.map((l: any) => {
                                                        if (!names.includes(l.lang_code) || l.lang_code === e) {
                                                            return <Option key={l.id} value={l.lang_code}>{l.name}</Option>
                                                        }
                                                    })
                                                }
                                            </Select>
                                            <Button shape="circle" className='d-flex justify-content-center align-items-center' onClick={() => removeInput(e)} disabled={names.length === 1} >
                                                <FaTimes />
                                            </Button>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </React.Fragment>
                        )
                    }) : null
                }
                {
                    type === "create" ?
                        <Col lg={{ span: 24 }} className="mb-3">
                            <Button type="link" className="ps-0" onClick={onAddNewForm} >+ {t("Add translation")}</Button>
                        </Col> : null}
                {
                    type === "update" ? <>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={`${t("Name")} (${i18n.language})`}
                                name={`name[${i18n.language}]`}
                                shouldUpdate
                                rules={[{ required: true, message: `${t("Fill in the field")} (${i18n.language})!` }]}
                            >
                                <Input placeholder={t("Enter course name") + " ..."} />
                            </Form.Item>
                        </Col>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={`${t("Description")} (${i18n.language})`}
                                name={`description[${i18n.language}]`}
                                shouldUpdate
                                rules={[{ required: false }]}
                            >
                                <Input placeholder={t("Enter description for course") + " ..."} />
                            </Form.Item>
                        </Col>
                    </> : null
                }
                <Col span={24}>
                    <Row className='border mt-1 mb-1 pt-1 pb-1' gutter={24}>
                        {type === "create" ? <Col span={24}>
                            <div className="text-warning">
                                Ushbu maydonlarni kiritishdagi urunish bir marotaba beriladi. Ularni tahrirlashning imkoni yo'q. Maydonlardagi qiymatlar to'g'riligiga ishon hosil qiling!
                            </div>
                        </Col> : ""}
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={"Course ( kurs davomiyligi )"}
                                name="course"
                                shouldUpdate
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <InputNumber disabled={type === "update"} min={1} placeholder={t("Enter course name") + " ..."} className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={"Semester ( semester davomiyligi )"}
                                name="semestr"
                                shouldUpdate
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <InputNumber disabled={type === "update"} min={1} placeholder={t("Enter semester name") + " ..."} className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col xl={{ span: 6 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={t("The start of the fall semester")}
                                name="fall_start"
                                shouldUpdate
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <DatePicker disabled={type === "update"} placeholder={t("Select date") + " ..."} format="YYYY-MM-DD" className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col xl={{ span: 6 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={t("The finish of the fall semester")}
                                name="fall_end"
                                shouldUpdate
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <DatePicker disabled={type === "update"} placeholder={t("Select date") + " ..."} format="YYYY-MM-DD" className="w-100" disabledDate={e => disabledDate(e, 'fall_start')} />
                            </Form.Item>
                        </Col>
                        <Col xl={{ span: 6 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={t("The start of the spring semester")}
                                name="spring_start"
                                shouldUpdate
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <DatePicker disabled={type === "update"} placeholder={t("Select date") + " ..."} format="YYYY-MM-DD" className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col xl={{ span: 6 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <Form.Item
                                label={t("The finish of the spring semester")}
                                name="spring_end"
                                shouldUpdate
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                            >
                                <DatePicker disabled={type === "update"} placeholder={t("Select date") + " ..."} format="YYYY-MM-DD" className="w-100" disabledDate={e => disabledDate(e, 'spring_start')} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                {
                    forms.map((element: FormsType) => {

                        return (
                            <Col key={element.key} xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                <Form.Item
                                    label={t(element.title)}
                                    name={element.name}
                                    rules={[{ required: true, message: t("Select " + element.key) + "!" }]}
                                >
                                    <Select
                                        showSearch
                                        onFocus={element?.onFocus}
                                        placeholder={`${t("Select " + element.key)} ...`}
                                        optionFilterProp="children"
                                        disabled={element.name === "direction_id" && !faculties.length ? true : false}
                                        filterOption={(input, option: any) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        loading={_loading(element.name as LoadingState)}
                                    >
                                        {
                                            Array.isArray(element.items) && element.items.length ? element.items.map((e: any, i: number) => {
                                                return <Option key={i} value={e.id}>{e.name}</Option>
                                            }) : null
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    })
                }
                <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Status")}
                        name="status"
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                </Col>
            </Row>
            <hr />
            <Col span={24} className="mt-2 text-end">
                <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { history.goBack() }}>{t("Cancel")}</Button>
                <Button type="primary" htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
            </Col>
        </>
    )
}


export default EduPlanUi;