import instance from "."
import { asyncN } from "utils/notifications"
import store from "store"
import { logout } from "store/auth"
import { message } from "antd"
import { createBrowserHistory } from 'history'; 


const _logout = async () => {
    try {
        const history = createBrowserHistory();
        const response = await instance({ url: '/auth/logout', method: 'POST' })
        if (response.data?.status === 1) {
            localStorage.removeItem('access_token')
            asyncN('success', 'read', response.data?.message).then(() => {
                localStorage.removeItem('url');
                history.push('/')
                store.dispatch(logout({}));
            })
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}



export default _logout