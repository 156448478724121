import { useEffect, useState } from 'react';
import { Form, Modal, Input, Switch, Button, Col } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { useTranslation } from 'react-i18next';
import { ServerErrors } from 'utils/validationFunctions';
import ModalTitle from 'components/ModalTitle';
import { BiX } from 'react-icons/bi';

export type TypeSimpleUpdateProps = {
    url: string,
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setReFetch: React.Dispatch<React.SetStateAction<boolean>>,
    title: string
}

const SimpleEdit: React.FC<TypeSimpleUpdateProps> = ({ url, visible, setVisible, setReFetch, title }) => {

    const { i18n, t } = useTranslation();
    const [form] = Form.useForm();
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true);

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const res = await instance({method: 'GET', url})
                        if(res.data.status === 1){
                            form.setFieldsValue({
                                name: res.data?.data?.name,
                                description: res.data?.data?.description,
                                status: Number(res.data?.data?.status) === 1 ? setStatus(true) : setStatus(false)
                            })
                        } else{

                        }
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])

    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            // formdata.append(`name[${i18n.language}]`, values?.name)
            // formdata.append(`description[${i18n.language}]`, values?.description)
            formdata.append(`name`, values?.name)
            formdata.append(`description`, values?.description)
            formdata.append('status', status ? '1' : '0');

            const response = await instance({ url, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReFetch(prevState => !prevState);
                    })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReFetch(prevState => !prevState)
                    })
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            setClicked(false)
            setReFetch(prevState => !prevState)
            asyncN("error", 'update', error?.response?.data?.errors[0])
                .then(() => {
                    setVisible(false);
                    resetFields()
                })
        }
    }

    const resetFields = () => form.resetFields();

    return (
        <>
            <Modal
                title={title}
                visible={visible}
                onCancel={() => { setVisible(false); resetFields() }}
                okText="Submit"
                footer={null}
                closeIcon={<BiX size={20} />}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Form.Item
                        label={t("Name")}
                        name="name"
                        shouldUpdate
                        rules={[{ required: true, message: 'Please input edu type name !' }]}
                    >
                        <Input placeholder={t("Enter edu type name") + " ..."} />
                    </Form.Item>
                    <Form.Item
                        label={t("Description")}
                        name="description"
                        shouldUpdate
                        rules={[{ required: false, message: 'Please input edu type name !' }]}
                    >
                        <Input.TextArea placeholder={t("Enter description for edu type") + " ..."} />
                    </Form.Item>
                    <Form.Item
                        label={t("Status")}
                        name="status"
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                    <hr />
                    <Col span={24} className="text-end mt-2">
                        <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </Col>
                </Form>
            </Modal>
        </>
    )
}

export default SimpleEdit;