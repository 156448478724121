import React, { FC, useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import CustomBreadcrumb from "components/Breadcrumb";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { getAllQuestionTypes } from "../../question_types/service";
import { getOneExam } from "../service";
import { examType } from "./type";

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Exams",
        path: 'exam_index'
    },
    {
        name: "Attach question",
        path: 'question_create'
    },
];


const AttachQuestionsForExam: FC = (): JSX.Element => {

    
    const [form] = Form.useForm();
    const history = useHistory()
    const { t } = useTranslation()
    const url: string = useLocation()?.pathname;
    let a: Array<string> = url?.split("/");
    const [types, setTypes] = useState<Array<{ id: number, name: string }>>([]);
    const [selectedTypes, setSelectedTypes] = useState<Array<{ id: number, name: string }>>([]);
    const [exam, setExam] = useState<examType>()

    useEffect(() => {
        (
            async () => {
                const resp = await getOneExam(Number(a[a?.length - 1]), 'expand=description,eduSemestr,eduSemestrSubject,subject');
                setExam(resp)
                setQuestionCounts(resp?.question_count_by_type_with_ball)

            }
        )()
    }, [])

    const setQuestionCounts = async (question_count_by_type: string) => {
        try {
            let a: any = [];
            const typesRes = await getAllQuestionTypes()
            setTypes(typesRes?.items);
            if (question_count_by_type) {
                let b = JSON.parse(question_count_by_type);
                let d: any = {}
                Object.entries(b).forEach(([key, value]: any) => {

                    typesRes?.items?.map((item: { id: number, name: string }) => {
                        if (Number(key) === item?.id) {
                            a.push(item)
                            d = {
                                ...d,
                                [`${item?.id}-count`]: value?.count,
                                [`${item?.id}-ball`]: value?.ball
                            }
                        }
                    })
                })

                setSelectedTypes(a)
                form.setFieldsValue(d)

            } else {
                a.push(typesRes?.items[0])
                setSelectedTypes(a)
            }

        } catch (e) {
            asyncN("error", "read", "Error for read question types!!!");
        }
    }

    const getTypes = (id: number) => {
        let a = [];

        a = types.filter((item: { id: number, name: string }) => {
            return item?.id === id || !selectedTypes.includes(item) && item
        })
        return a;
    }

    const changeType = (newItemId: number, oldItemId: number) => {

        let newItem: { id: number, name: string };

        types?.map((item: { id: number, name: string }) => {
            if (newItemId == item?.id) {
                newItem = item;
            }
        })
        let oldItem: { id: number, name: string }
        let a = selectedTypes?.map((item: { id: number, name: string }) => {
            if (item?.id === oldItemId) {
                oldItem = item;
                return newItem;
            } else {
                return item
            }
        })
        setSelectedTypes(a)
    }

    const addType = () => {
        let id = selectedTypes[selectedTypes.length - 1]?.id;
        types.map((item: { id: number, name: string }, i: number) => {
            if (!selectedTypes.includes(item)) {
                setSelectedTypes([...selectedTypes, item]);
                return
            }
        })
    }

    const removeType = (id: number) => {
        let a = selectedTypes?.filter((item: { id: number, name: string }) => {
            return item?.id !== id && item
        })

        setSelectedTypes(a);
    }


    const onFinish = async (values: any) => {
        let data = new FormData();

        let ac: any = {};
        let maxScore: number = 0
        Object.entries(values)?.forEach(([key, value]: any) => {
            let b: Array<string> = key?.split("-");
            if (ac[`${b[0]}`]) {
                ac = {
                    ...ac,
                    [`${b[0]}`]: { ...ac[`${b[0]}`], [`${b[1]}`]: value }
                }
            } else {
                ac = {
                    ...ac,
                    [`${b[0]}`]: { [`${b[1]}`]: value }
                }
            }
            if (b[1] === "ball") {
                maxScore += (Number(value)*Number(values[`${b[0]}-count`]))
            }
        })
        data.append("question_count_by_type_with_ball", JSON.stringify(ac));

        if(exam){
            if (maxScore<=exam?.max_ball) {
                try {
                    const resp = await instance({
                        method: 'put',
                        url: '/exams/' + a[a.length - 1],
                        data: data
                    })
    
                    if (resp?.data?.status === 1) {
                        asyncN("success", "update", resp?.data?.message);
                        history.goBack()
                    } else {
                        asyncN("error", "update", resp?.data?.message)
                    }
                } catch (e) {
                    asyncN("error", "update", "Error for attach question types counts!!!")
                }
            } else {
                message.error(`${t("The total score should not exceed ")}${exam?.max_ball} but your score ${maxScore}!`)
            }
        }else{
            message.error("Exam not found please try again!")
        }

    }

    return (
        <>
            <CustomBreadcrumb arr={_breadcrump} />
            <Form onFinish={onFinish} form={form} layout="vertical">
                <div className="text-danger mt-2"><strong>{t("Ushbu imtihon uchun ball")}: </strong><span>{exam?.max_ball} ball</span></div>
                <Row gutter={24}>
                    {selectedTypes?.map((ids: { id: number, name: string }) =>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Row className="border-bottom" gutter={24}>
                                <Col className="p-1" xs={24} sm={24} md={6} lg={4}>
                                    <Form.Item
                                        name={`${ids?.id}-count`}
                                        label={t("Count")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Fill in the field!")
                                            }
                                        ]}
                                    >
                                        <Input type="number" placeholder={t("Enter question count")} />
                                    </Form.Item>
                                </Col>
                                <Col className="p-1" xs={24} sm={24} md={2} lg={2}>
                                    <Form.Item
                                        name={`${ids?.id}-ball`}
                                        label={t("Ball")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Fill in the field!")
                                            }
                                        ]}
                                    >
                                        <Input type="number" placeholder={t("Enter question ball")} />
                                    </Form.Item>
                                </Col>
                                <Col className="p-1" xs={21} sm={21} md={4} lg={3}>
                                    <Form.Item
                                        label={t("Type")}
                                        rules={[
                                            {
                                                required: false,
                                                message: t("Fill in the field!")
                                            }
                                        ]}
                                        initialValue={ids?.id}
                                    >
                                        <Select value={ids?.id} onChange={(e: number) => {
                                            changeType(e, ids?.id)
                                        }} placeholder={t("Enter question type")}>
                                            {
                                                types && getTypes(ids?.id)?.map((item: { id: number, name: string }) =>
                                                    <Select.Option value={item?.id}>{item?.name}</Select.Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="p-1" xs={2} sm={2} md={1} lg={1}>
                                    <Form.Item
                                        label=" "
                                    >
                                        <Button onClick={() => removeType(ids?.id)} disabled={selectedTypes?.length === 1} type="primary" shape="circle" >
                                            <FaTimes />
                                        </Button>
                                    </Form.Item>
                                </Col>
                                {form.getFieldValue(`${ids?.id}-ball`)&&form.getFieldValue(`${ids?.id}-count`)&&<Col span={24}>
                                    <div className="text-warning">
                                        Bu tur uchun umumiy ball {form.getFieldValue(`${ids?.id}-ball`)*form.getFieldValue(`${ids?.id}-count`)} ge teng
                                    </div>
                                </Col>}
                            </Row>
                        </Col>
                    )}
                </Row>
                <hr />
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <Button danger={types?.length === selectedTypes?.length} disabled={types?.length === selectedTypes?.length} onClick={() => addType()} type="link">+{" " + t("Add type")}</Button>
                    </Col>
                    <Col lg={{ span: 12 }} className="text-end">
                        <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { history.goBack() }}>{t("Cancel")}</Button>
                        <Button type="default" htmlType="reset" className="me-2" onClick={() => { form.resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit">{t("Submit")}</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AttachQuestionsForExam;