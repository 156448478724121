import { message } from "antd";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";



export const getOneExam = async (id: number | null, path_prefix?: string) => {
    try {
        if (!id) {
            return new Error("Id bo'yicha ma'lumot olinmadi !");
        }

        const response = await instance({ url: `/exams/${id}${path_prefix ? '?' + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}





export const getAllExams = async (perPage?: number, currentPage?: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/exams${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}



export const getPassedExamsDistributedToTeacher = async ({ subject_id, exam_id, languages = [] }: { subject_id: number, exam_id: number, languages: any[] }) => {

    let _examStudentByLang: any[] = [];
    let _examSmeta: any[] = [];
    let _subjectName: string = "";
    let _teacherAccess: any[] = [];

    await getOneExam(exam_id, `sort=-id&expand=subjectName,examStudentByLang,teacherAccess,examSmeta&fields=teacherAccess&filter=${JSON.stringify({ subject_id })}`)
        .then((response) => {

            const { teacherAccess, examSmeta, examStudentByLang, subjectName } = response;

            if (teacherAccess && teacherAccess.length) {
                let obj: any = {};
                for (let i = 0; i < teacherAccess.length; i++) {
                    const element: { id: number, user_id: number, language_id: number, subject: any, teacher: any } = teacherAccess[i];
                    const { teacher, language_id, id, user_id } = element;
                    if (obj.hasOwnProperty(user_id)) {
                        obj[user_id]?.langs.push(language_id);
                        obj[user_id]._[language_id] = `${language_id}_${id}`;
                    } else {
                        obj[user_id] = {
                            teacher,
                            langs: [language_id],
                            _: { [language_id]: `${language_id}_${id}` }
                        };
                    }
                }
                const new_data: any = [];
                for (let key in obj) {
                    new_data.push(obj[key]);
                }
                _teacherAccess = new_data || [];
            }
            _examStudentByLang = examStudentByLang || [];
            _examSmeta = examSmeta || [];
            _subjectName = subjectName || [];

            if (examStudentByLang?.length) {
                const new_obj: any = {};
                if (languages?.length) {
                    for (let i = 0; i < languages.length; i++) {
                        const { lang_code, id } = languages[i];
                        const findLang: { count: string, lang_id: string } | undefined = examStudentByLang.find((e: { lang_id: string, count: string }) => Number(e.lang_id) === id);
                        if (findLang && findLang.lang_id && findLang.count) {
                            new_obj[lang_code] = {
                                count: findLang.count,
                                id: findLang.lang_id
                            }
                        }
                    }
                } else {
                    message.error("Til bilan bog'liq xatolik!");
                }
                _examStudentByLang = new_obj;
            }
        })

    const check_language = (_lang_code: string) => {
        if (languages.length) {
            const _language = languages.find(e => e.lang_code === _lang_code);
            return _language?.id;
        }
    }

    return { _examSmeta, _teacherAccess, _examStudentByLang, _subjectName, check_language };
}