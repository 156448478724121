import { FC, useEffect, useState } from 'react';
import { Form, Row, Col, Select, Button, Switch, InputNumber, Modal } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import { getAllBuildings } from '../../buildings/service';
import { useTranslation } from 'react-i18next';
import MultipleInput from 'components/MultipleInput';

const { Option } = Select;

const CreateRoom: FC<{ visible: boolean, reFetch: boolean, setVisible: (visible: boolean) => void, setReFetch: (reFetch: boolean) => void }> = ({ visible, reFetch, setVisible, setReFetch }): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [buildings, setBuildings] = useState<Array<any>>([])
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true)

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    const response = await getAllBuildings();
                    setBuildings(response?.items)
                }
            )()
        }
    }, [visible])

    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            for (const [key, value] of Object.entries(values)) {
                if (key === 'status') {
                    formdata.append(key, status ? '1' : '0');
                } else {
                    formdata.append(key, String(value));
                }
            }

            const response = await instance({ url: '/rooms', method: 'POST', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { setVisible(false); resetFields(); setReFetch(!reFetch) })
            } else {
                asyncN("error", 'update', response.data?.message)
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();

    return (
        <Modal
            title={t("Room create")}
            visible={visible}
            onCancel={() => { setVisible(false); resetFields() }}
            okText="Submit"
            footer={null}
            width={800}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{ status: true }}
                className="mt-4"
            >
                <Row gutter={[12, 0]}>
                    <Col span={24} className="mb-2" >
                        <MultipleInput layout='vertical' />
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Building")}
                            name="building_id"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Select
                                showSearch
                                placeholder={t("Select building ...")}
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    buildings?.length ? buildings?.map((e: any, i: number) => {
                                        return <Option key={i} value={e.id}>{e.name}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Capacity")}
                            name="capacity"
                        >
                            <InputNumber className='w-100' placeholder={t("Enter capacity of room")} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Col span={24} className="mt-2 d-flex justify-content-between align-items-center">
                    <Form.Item name="status" className='mb-0'>
                        <Switch checkedChildren={t("Active")} unCheckedChildren={t("InActive")} checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                    <div>
                        <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { resetFields(); setVisible(false) }}>{t("Cancel")}</Button>
                        <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </div>
                </Col>
            </Form>
        </Modal>
    )
}


export default CreateRoom;