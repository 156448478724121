import { FC, useEffect, useState } from "react";
import { Modal, Row, Col, Typography, Button } from "antd";
import moment from "moment";
import { BadgeStatusText } from "components/BadgeStatus";
import { getOneEduPlan } from "../service";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const EduPlanView: FC<{ visible: boolean, setVisible: (visible: boolean) => void, id: number | null }> = ({visible, setVisible, id}): JSX.Element => {

    const { t } = useTranslation();
    const [data, setData] = useState<any>(null);
    const [showCreated, setShowCreated] = useState<boolean>(false);

    useEffect(() => {
        if(visible){
            (
                async () => {
                    try {
                        const response = await getOneEduPlan(id,'expand=createdBy,updatedBy,description,faculty,direction,eduYear,eduType')
                        setData(response)
                    } catch (error: any) {
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])
    
    return (
        <>
        {data &&
        <Modal
            title={t("Education plan view")}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            width={1200}
        >
            <Row gutter={[12, 12]}>
                <Col span={24} ><Text>{t("Name of edu plan")}: </Text>&nbsp;{data?.name}</Col>
                <Col span={24} ><Text>{t("Description of edu plan")}: </Text>&nbsp;{data?.description}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Texnikum")}: </Text>{data?.faculty?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Direction")}: </Text>{data?.direction?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Edu Type")}: </Text>{data?.eduType?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Edu Year")}: </Text>{data?.eduYear?.name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Semestr")}: </Text>{data?.semestr}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Course")}: </Text>{data?.course}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("The beginning of the fall semester")}: </Text>{data?.fall_start}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("The end of the fall semester")}: </Text>{data?.fall_end}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("The beginning of the spring semester")}: </Text>{data?.spring_start}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("The end of the spring semester")}: </Text>{data?.spring_end}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Created at")}: </Text>{moment(data?.created_at*1000).format('MM-DD-YYYY')}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Updated at")}: </Text>{moment(data?.updated_at*1000).format('MM-DD-YYYY')}</Col>
                <Col className="d-f justify-content-between" span={24}><span><Text>{t("Status")}: </Text><BadgeStatusText status={data?.status} /></span> <Button type="link" onClick={() => setShowCreated(!showCreated)} >details</Button> </Col>
            </Row>
            {data.createdBy && showCreated ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Created By")}</Text></Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>First Name: </Text>{data.createdBy.first_name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data.createdBy.last_name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Login")}: </Text>{data.createdBy.username}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data.createdBy.role}</Col>
            </Row> : null}
            {data.updatedBy && showCreated ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Updated By")}</Text></Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("First Name")}: </Text>{data.updatedBy.first_name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data.updatedBy.last_name}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Login")}: </Text>{data.updatedBy.username}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data.updatedBy.role}</Col>
            </Row> : null}
            </Modal>}
        </>
    )
}


export default EduPlanView;