import { FC, useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch, match } from 'react-router';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import moment from 'moment';
import CustomBreadcrumb from "components/Breadcrumb";
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import StudentUi from './ui';
import { getOneStudent } from '../service';
import { FILE_URL } from 'config/utils';
import { comparingStudentData, page_errors } from './utils';
import { useAppDispatch, useAppSelector } from 'store/services';
import { STUDENT_ACTIONS } from 'store/student';
import { FaCheck } from 'react-icons/fa';
import FormLabel from 'components/FormLabel';
import { BiX } from 'react-icons/bi';

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Students",
        path: 'student_index'
    },
    {
        name: "Student edit",
        path: 'student_update'
    },
]



const CompareStudentData: FC = (): JSX.Element => {

    const [form] = Form.useForm();
    const history = useHistory();
    const match: match<any> | null = useRouteMatch("/student_compare/:student_id/:pinfl");
    const dispatch: any = useAppDispatch();
    const { visible } = useAppSelector(state => state.student);

    const ref: any = useRef();
    const [clicked, setClicked] = useState<boolean>(false);
    const [fileList, setFileList] = useState<Array<any>>([]);
    const [filePassport, setFilePassport] = useState<Array<any>>([]);
    const [data, setData] = useState<any>(null);
    const [hemisData, setHemisData] = useState<Record<string, any>>();

    // if (!match) {
    //     history.goBack();
    // }


    useEffect(() => {
        (
            async () => {
                try {
                    if (match) {
                        if (!match.params?.student_id) {
                            asyncN('error', 'read', "Id ma'lumotini olishda xatolik !").then(() => history.goBack())
                        }

                        const response = await getOneStudent(match.params?.student_id, "expand=course,direction,eduType,eduYear,eduForm,faculty,user,tutor,profile,eduPlan,citizenship,country,region,area,permanentCountry,permanentRegion,permanentArea,nationality,socialCategory,residenceStatus,categoryOfCohabitant,studentCategory");

                        setData(response);
                        form.setFieldsValue({
                            first_name: response?.profile?.first_name || undefined,
                            last_name: response?.profile?.last_name || undefined,
                            middle_name: response?.profile?.middle_name || undefined,
                            phone: response?.profile?.phone || undefined,
                            phone_secondary: response?.profile?.phone_secondary || undefined,
                            gender: String(response?.profile?.gender) || undefined,
                            status: String(response?.user?.status) || undefined,
                            description: response?.description || undefined,
                            birthday: response?.profile?.birthday ? moment(response?.profile?.birthday) : undefined,
                            passport_date: [response?.profile?.passport_given_date ? moment(response?.profile?.passport_given_date) : undefined, response?.profile?.passport_issued_date ? moment(response?.profile?.passport_issued_date) : undefined],
                            passport_pin: String(response?.profile?.passport_pin).trim() || undefined,
                            passport_seria_and_number: response?.profile?.passport_seria || response?.profile?.passport_number ? response?.profile?.passport_seria + " " + response?.profile?.passport_number : undefined,
                            passport_given_by: response?.profile?.passport_given_by || undefined,
                            diplom_seria: response?.diplom_seria || undefined,
                            diplom_number: response?.diplom_number || undefined,
                            is_contract: String(response?.is_contract) || undefined,
                            course_id: response?.course_id || undefined,
                            faculty_id: response?.faculty_id || undefined,
                            direction_id: response?.direction_id || undefined,
                            edu_year_id: response?.edu_year_id || undefined,
                            edu_type_id: response?.edu_type_id || undefined,
                            edu_plan_id: response?.edu_plan_id || undefined,
                            edu_lang_id: response?.edu_lang_id || undefined,
                            country_id: response?.profile?.country_id ? response?.profile?.country_id : 229,
                            region_id: response?.profile?.region_id || undefined,
                            area_id: response?.profile?.area_id || undefined,
                            address: response?.profile?.address || undefined,
                            permanent_country_id: response?.profile?.permanent_country_id ? response?.profile?.permanent_country_id : 229,
                            permanent_region_id: response?.profile?.permanent_region_id || undefined,
                            permanent_area_id: response?.profile?.permanent_area_id || undefined,
                            permanent_address: response?.profile?.permanent_address || undefined,
                            citizenship_id: response?.profile?.citizenship_id || undefined,
                            nationality_id: response?.profile?.nationality_id || undefined,
                            edu_form_id: response?.edu_form_id || undefined,
                            student_category_id: response?.student_category_id || undefined,
                            category_of_cohabitant_id: response?.category_of_cohabitant_id || undefined,
                            residence_status_id: response?.residence_status_id || undefined,
                            social_category_id: response?.social_category_id || undefined,
                            partners_count: response?.partners_count || undefined,
                            live_location: response?.live_location || undefined,
                            parent_phone: response?.parent_phone || undefined,
                            res_person_phone: response?.res_person_phone || undefined,
                            last_education: response?.last_education || undefined,
                            email: response?.user?.email || undefined,
                        })
                        setFileList([
                            {
                                uid: '-1',
                                name: 'image.png',
                                status: 'done',
                                url: FILE_URL + response?.profile?.image,
                            }
                        ]);
                        if (response?.profile?.passport_file && response?.profile?.passport_file !== "undefined") {
                            setFilePassport([
                                {
                                    uid: '-1',
                                    name: 'Passport file.' + response?.profile?.passport_file.substring(response?.profile?.passport_file.lastIndexOf(".") + 1, response?.profile?.passport_file.length),
                                    status: 'done',
                                    url: FILE_URL + response?.profile?.passport_file,
                                }
                            ])
                        }
                    }
                } catch (error: any) {
                    asyncN("error", "read", error?.response?.message)
                }
            }
        )()
    }, [])


    const onSubmit = async (values: any) => {

        try {
            setClicked(true)


            const { phone, passport_seria_and_number, passport_pin, phone_secondary, parent_phone, res_person_phone } = values;

            const isPhoneSecondary = phone_secondary && String(phone_secondary).includes('_');
            const isParentPhone = parent_phone && String(parent_phone).includes('_');
            const isResPersonPhone = res_person_phone && String(res_person_phone).includes('_');
            const isPhoneNumber = String(phone).includes('_');
            const isPassport = String(passport_seria_and_number).includes('_');
            const isPassportPin = String(passport_pin).includes('_')

            if (!isPhoneNumber && !isPassport && !isPassportPin && !isPhoneSecondary && !isParentPhone && !isResPersonPhone) {
                const formdata = new FormData();
                Object.entries(values).forEach(([key, value]: any) => {
                    if (value) {
                        if (key === "birthday") {
                            formdata.append(key, moment(value).format("YYYY-MM-DD"));
                        } else if (['avatar', 'passport_file'].includes(key)) {
                            if (value) {
                                formdata.append(key, value.file.originFileObj)
                            }
                        } else if (key === 'passport_date') {
                            formdata.append("passport_given_date", moment(value[0]).format("YYYY-MM-DD"))
                            formdata.append("passport_issued_date", moment(value[1]).format("YYYY-MM-DD"))
                        } else if (key === 'passport_seria_and_number') {
                            formdata.append("passport_seria", value.split(" ")[0])
                            formdata.append("passport_number", value.split(" ")[1])
                        } else {
                            formdata.append(key, String(value));
                        }
                    }
                });

                const response = await instance({ url: `/students/${match && match.params?.student_id}`, method: 'PUT', data: formdata })

                if (response.data.status === 1) {
                    asyncN("success", 'create', response.data?.message)
                        .then(() => { resetFields(); history.goBack() })
                } else {
                    asyncN("error", 'update', response.data?.message)
                        .then(() => resetFields())
                }
            } else {
                message.error(`${isPhoneNumber ? "Telefon raqam " : ''}${isPassport ? "Passport seriya va raqam " : ''}${isPassportPin ? "Passport JSHSHIR, " : ''}${isPhoneSecondary ? "Qo'shimcha telefon raqam, " : ''}${isParentPhone ? "Ota onasi telefon raqam, " : ''}${isResPersonPhone ? "Ma'sul shaxs telefon raqam " : ''} ma'lumotida format xatoligi !`, 5);
            }

            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    const _errors = error?.response?.data?.errors[0];
                    let active_field = undefined;
                    for (let key in _errors) {
                        active_field = key;
                        break
                    }
                    for (let i = 0; i < page_errors.length; i++) {
                        const element = page_errors[i];
                        if (active_field && element.fields.includes(active_field)) {
                            ref.current?.catchErrorFromBackend(element.tab);
                        }
                    }
                    form.setFields(ServerErrors(_errors));
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();

    const getStudentByPinfl = async () => {


        const pinfl = match?.params?.pinfl;

        if (!pinfl || pinfl.includes('_')) {
            message.warning('Invalid pinfl');
            return;
        }

        try {
            const response = await instance({ url: `/students/get?pinfl=${pinfl}`, method: 'GET' });


            if (response.data?.status === 1) {
                dispatch(STUDENT_ACTIONS.openModal({ modal_name: 'compare' }))
                setHemisData(response.data?.data)
            }
            console.log("response -> ", response.data)

        } catch (error) {

        }

    }
    useEffect(() => {
        const pinfl = match?.params?.pinfl;
        if (pinfl === 'undefined') history.goBack();
        getStudentByPinfl()
    }, [])

    return (
        <>
            <CustomBreadcrumb arr={_breadcrump} />
            <Form form={form} layout="vertical" >
                <Row gutter={[24, 12]}>
                    {
                        comparingStudentData.map((element) => {
                            return (
                                <Col key={element.field1} xl={12}>
                                    <Row gutter={[12, 12]} style={{ backgroundColor: '#F6F8F9', borderRadius: '4px', padding: '10px 0 0 0' }}>
                                        <Col xl={10}>
                                            <FormLabel><span style={{ color: '#C26C03' }} >{element.title} </span></FormLabel>
                                            {hemisData ? <Input value={hemisData[element.field1]} className='mt-2' disabled={true} style={{ color: '#404346' }} /> : <Input disabled={true} className="mt-2" />}
                                        </Col>
                                        <Col xl={14}>
                                            <div className='d-flex' >
                                                <Form.Item
                                                    label={<FormLabel>{element.title}</FormLabel>}
                                                    name={element.field2}
                                                    className='w-100'
                                                >
                                                    <Input />
                                                </Form.Item>&nbsp;
                                                <Button type='primary' style={{ marginTop: '30px' }} danger ghost>
                                                    <BiX size={18} />
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Form>
            <div>
                <Button type='primary'>Saqlash</Button>
            </div>
        </>
    )
}


export default CompareStudentData;