import React from "react";
import { Button, Col, Modal, Row, Image } from "antd";
import ModalTitle from "components/ModalTitle";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import useGetOneData, { ReturnTypeUseGetOneData } from "hooks/useGetOneData";
import { useAppDispatch, useAppSelector } from "store/services";
import { STUDENT_ACTIONS } from "store/student";
import { IPetition, IPetitionCategory } from 'models/dormitory';
import { FILE_URL } from "config/utils";
import { BiCheck, BiX } from "react-icons/bi";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";



const DormitoryDocumentsStudent = () => {

    const dispatch: any = useAppDispatch();
    const { visible, petition_id } = useAppSelector(state => state.student);
    const [reFetch, setRefetch] = React.useState<boolean>(false);

    const [file, setFile] = React.useState<string>('');

    const petition = useGetOneData({}) as ReturnTypeUseGetOneData<IPetition>;
    const petition_categories = useGetAllData({ url: '/hostel-categories?expand=types' }) as TypeReturnUseGetAllData<IPetitionCategory>;


    React.useEffect(() => {
        if (visible === 'dormitory') {
            petition.fetch(`hostel-apps/${petition_id}?expand=hostelDoc,profile`);
            petition_categories.fetch()
        }
    }, [visible, reFetch]);


    const filterPetitionDocsByCategory = React.useCallback((category_id:any) => {

        if (petition.data.hostelDoc.length) {
            const filteredDocs = petition.data.hostelDoc.filter(e => e.hostel_category_id === category_id);

            return filteredDocs;
        }

    }, [petition.data]);


    const checkDocument = async (doc_id: number, type?: '0' | undefined) => {

        try {
            const response = await instance({ url: `hostel-docs/${type ? 'not' : 'check'}/${doc_id}`, method: 'GET' });
            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message);
                setRefetch(!reFetch)
            } else {
                asyncN("error", 'update', response.data?.message);
            }
        } catch (error) {

        }

    }


    const checkFileFormat = React.useCallback((file: string | null) => {
        if (file) {
            if (file.endsWith(".pdf")) {
                return "pdf";
            } else if ((file.endsWith(".png") || file.endsWith("jpg"))) {
                return "img";
            } else if ((file.endsWith(".mp3") || file.endsWith(".ogg") || file.endsWith(".dsd") || file.endsWith(".aac") || file.endsWith(".alac") || file.endsWith(".wma") || file.endsWith(".flac"))) {
                return "audio";
            } else if ((file.endsWith(".mp4") || file.endsWith(".mov") || file.endsWith(".wmv") || file.endsWith(".flv") || file.endsWith(".avi") || file.endsWith(".mkv"))) {
                return "video";
            }
        }
    }, [])

    return (
        <div>
            <Modal
                title={<ModalTitle>{String(file).split('/kpi_store/')[1]}</ModalTitle>}
                visible={!!file}
                onCancel={() => setFile('')}
                closeIcon={<BiX size={20} />}
                width={1000}
                footer={null}
            >
                <div className="text-center" >
                    {
                        file && file.endsWith('.pdf') ? <iframe className="file-modal" src={FILE_URL + file} width="100%" height={700} ></iframe> :
                            checkFileFormat(file) === "video" ? (<video className="file-modal" src={FILE_URL + file} controls></video>) :
                                checkFileFormat(file) === "audio" ? (<audio src={FILE_URL + file} controls></audio>) :
                                    checkFileFormat(file) === "img" ? <Image className="file-modal" width="100%" src={FILE_URL + file} /> :
                                        <iframe className="file-modal" width="100%" height={700} src={`https://view.officeapps.live.com/op/embed.aspx?src=${FILE_URL + file}`}></iframe>
                    }
                </div>
            </Modal>
            <Modal
                title={<span>{petition.data?.profile?.last_name}&nbsp;{petition.data?.profile?.first_name}&nbsp;{petition.data?.profile?.middle_name}</span>}
                wrapClassName="dd_student"
                visible={visible === 'dormitory'}
                onCancel={() => dispatch(STUDENT_ACTIONS.closeModal())}
                footer={null}
            >
                <div>
                    <span className="dormitroy_petition">
                        {petition.data.description ? petition.data.description : "Ariza matni mavjud emas!"}
                    </span>
                    <div className="content_box">
                        {
                            petition.data.hostelDoc?.length ?
                                petition_categories.items?.length && petition_categories.items.map((categoryItem, categoryIndex) => {

                                    const doc_element = filterPetitionDocsByCategory(categoryItem.id);

                                    if (doc_element?.length) {
                                        return (
                                            <div key={categoryItem.id} className="dormitory_file_list">
                                                <span key={categoryItem.id} className="title" >
                                                    <strong> {categoryItem.key}) </strong>{categoryItem.name}
                                                </span>
                                                <Row gutter={[12, 12]} className="my-3" >
                                                    {
                                                        doc_element?.map((docItem) => {
                                                            return (
                                                                <>
                                                                    <Col xl={8} >
                                                                        <div className="d-flex align-items-center" ><span className="info_text_key" >Fayl:</span><Button type="link" ghost className="w-100 text-start" onClick={() => setFile(docItem.file)} >Hujjatni ko'rish</Button></div>
                                                                    </Col>
                                                                    <Col xl={8} >
                                                                        <span>{categoryItem.types.length ? <>{categoryItem.types.filter(e => e.id === docItem.hostel_category_type_id)[0]?.name}</> : null}</span>
                                                                    </Col>
                                                                    <Col xl={4} >
                                                                        <Button type="primary" ghost className="w-100" icon={<BiCheck size={22} className="me-2" />} onClick={() => checkDocument(docItem.id)} disabled={docItem.is_checked === 1} >{docItem.is_checked === 1 ? "Tasdiqlandi" : "Hujjatni tasdiqlash"}</Button>
                                                                    </Col>
                                                                    <Col xl={4} >
                                                                        <Button type="primary" ghost className="w-100" danger icon={<BiX size={22} className="me-2" />} onClick={() => checkDocument(docItem.id, '0')} disabled={docItem.is_checked === 0} >Hujjatni bekor qilish</Button>
                                                                    </Col>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        )
                                    }


                                })
                                : null
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )

}

export default DormitoryDocumentsStudent;




{/* <Card key={categoryItem.id} className="my-3">
<CardBody>
    <span key={categoryItem.id} className="d-block" >
        {categoryItem.name}
    </span>
    <Divider></Divider>
    <div className="dormitory_file_list">

    </div>
    <Row gutter={[12, 12]} >
        {
            doc_element?.map((docItem) => {
                return (
                    <>
                        <Col xl={6} >
                            <div className="d-flex align-items-center" ><span className="info_text_key" >Fayl:</span><Button type="link" ghost className="w-100 text-start " >Hujjatni ko'rish</Button></div>
                        </Col>
                        <Col xl={6} >
                            <span>{categoryItem.types.length ? <>{categoryItem.types.filter(e => e.id === docItem.hostel_category_type_id)[0]?.name}</> : null}</span>
                        </Col>
                        <Col xl={6} >
                            <Button type="primary" ghost className="w-100" icon={<BiCheck size={22} className="me-2" />} onClick={() => checkDocument(docItem.id, '1')} >Hujjatni tasdiqlash</Button>
                        </Col>
                        <Col xl={6} >
                            <Button type="primary" ghost className="w-100" danger icon={<BiX size={22} className="me-2" />} onClick={() => checkDocument(docItem.id, '1')}  >Hujjatni bekor qilish</Button>
                        </Col>
                    </>
                )
            })
        }
    </Row>
</CardBody>
</Card> */}