import React, { useState } from "react";
import { Row, Col, Form, Button, message, Spin } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import CustomBreadcrumb from "components/Breadcrumb";
import { FILE_URL } from "config/utils";
import instance from "config/_axios";
import { IUserAccess } from "models/others";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { match, useHistory, useRouteMatch } from "react-router-dom";
import { asyncN } from "utils/notifications";
import { Card, CardBody } from "reactstrap";
import { getOneUsers } from "pages/user/servise";
import CreateAndUpdateForm from "pages/user/components/create_and_update_form";
import UserAdditionalInfo from "pages/user/components/additional_info";
import NewTeacherAccess from "./teacher_access";
import CardContainer from "components/Widgets/CardContainer";
import { getTeacherAccessByUserId } from "../servise";
import { ITeacherAccess } from "models/exam";

const _breadcrump = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "O'qituvchilar bazasi",
    path: "teachers_index",
  },
  {
    name: "Update teacher",
    path: "update_teacher",
  },
];

const NewUpdateTeacher = () => {

  const { t } = useTranslation();
  const history = useHistory()
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const match: match<any> | null = useRouteMatch("/update_teacher/:user_id");
  const [userAccess, setUserAccess] = React.useState<Record<string, string[]>>({});
  const [teacherAccess, setTeacherAccess] = React.useState<Record<string, number[]>>({});
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [filePassport, setFilePassport] = React.useState<string>();


  React.useEffect(() => {
    (
      async () => {
        try {
          if (match) {
            setLoading(true);
            const response = await getOneUsers(match.params?.user_id, "expand=userAccess,citizenship,country,region,area,permanentCountry,permanentRegion,permanentArea,nationality,profile,degree,academicDegree");

            const data = response.data;
            const userAccess = data.userAccess as Array<IUserAccess>;

            const { last_name, first_name, middle_name, phone, phone_secondary, gender, birthday, address, description, status, country_id, region_id, area_id, passport_given_date, passport_pin, passport_seria, passport_given_by, passport_file, permanent_address, permanent_area_id, permanent_country_id, permanent_region_id, citizenship_id, nationality_id, passport_issued_date, passport_number, academic_degree_id, degree_id, degree_info_id, diploma_type_id, partiya_id } = data.profile;
            form.setFieldsValue({
              first_name: first_name || undefined,
              last_name: last_name || undefined,
              middle_name: middle_name || undefined,
              phone: phone || undefined,
              gender: String(gender) || undefined,
              status: String(data.status) || undefined,
              birthday: birthday ? moment(birthday) : undefined,
              passport_date: [passport_given_date ? moment(passport_given_date) : undefined, passport_issued_date ? moment(passport_issued_date) : undefined],
              passport_pin: String(passport_pin).trim() || undefined,
              passport_seria_and_number: passport_seria || passport_number ? passport_seria + " " + passport_number : undefined,
              passport_given_by: passport_given_by || undefined,
              permanent_country_id: permanent_country_id ? permanent_country_id : 229,
              permanent_region_id: permanent_region_id || undefined,
              permanent_area_id: permanent_area_id || undefined,
              country_id: country_id || undefined,
              region_id: region_id || undefined,
              area_id: area_id || undefined,
              address: address || undefined,
              permanent_address: permanent_address?.replace(/(\r\n|\n|\r)/gm, "") || undefined,
              citizenship_id: citizenship_id || undefined,
              nationality_id: nationality_id || undefined,
              email: data.email || undefined,
              phone_secondary: phone_secondary || undefined,
              academic_degree_id,
              degree_id,
              degree_info_id,
              diploma_type_id,
              partiya_id,
              role: data.role,
              username: data.username,
              description
            })

            if (data.avatar) {
              setFileList([
                {
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: FILE_URL + data.avatar,
                }
              ]);
            }

            if (passport_file && passport_file !== "undefined") {
              setFilePassport(passport_file);
            }

            if (userAccess.length) {

              let newUserPositions: Record<string, string[]> = {};
              userAccess.forEach((element) => {

                if (newUserPositions.hasOwnProperty(element.user_access_type_id)) {
                  newUserPositions[String(element.user_access_type_id)].push(`${element.table_id}-${element.is_leader}`);
                } else {
                  newUserPositions[String(element.user_access_type_id)] = [(`${element.table_id}-${element.is_leader}`)];
                }

              })
              setUserAccess(newUserPositions);
            }
            setLoading(false);

          }
        } catch (error: any) {
          asyncN("error", "read", error?.response?.message)
          setLoading(false);
        }
      }
    )()

  }, [])

  React.useEffect(() => {
    (
      async () => {

        const responseAc = await getTeacherAccessByUserId(Number(match?.params?.user_id))
        const items: any = responseAc?.data?.items;
        let access: any = {};
        items.map((item: ITeacherAccess) => {
          if (access[`${item?.subject_id}`])
            access = { ...access, [`${item?.subject_id}`]: [...access[`${item?.subject_id}`], item?.language_id] };
          else
            access = { ...access, [`${item?.subject_id}`]: [item?.language_id] }
        });
        setTeacherAccess(access);

      }
    )()
  }, [])


  const onSubmit = async (values: Record<string | number, any>) => {
    try {

      const { passport_seria_and_number, phone, passport_pin } = values;

      const isValidateField = passport_seria_and_number?.includes('_') || phone?.includes('_') || passport_pin?.includes('_');

      if (isValidateField) {
        message.warning("Kiritilgan ma'lumotlar to'g'ri formatda ekanligini tekshiring!")
        return;
      }

      if (!Object.keys(userAccess).length) {
        message.warning("Ushbu xodimni Bo'lim, Kafedra yoki Taxnikumga biriktirish zarur !!!")
        return;
      }

      if (!Object.keys(teacherAccess).length) {
        message.warning("O'qituvchiga fan biriktirish zarur !!!")
        return;
      }

      const formdata = new FormData();
      Object.entries(values).forEach(([key, value]: any) => {
        if (value) {
          if (key === "birthday") {
            formdata.append(key, moment(value).format("YYYY-MM-DD"));
          } else if (['avatar', 'passport_file'].includes(key)) {
            formdata.append(key, value.file.originFileObj);
          } else if (key === 'role') {
            formdata.append(key, JSON.stringify(value));
          } else if (key === 'passport_date') {
            formdata.append("passport_given_date", moment(value[0]).format("YYYY-MM-DD"))
            formdata.append("passport_issued_date", moment(value[1]).format("YYYY-MM-DD"))
          } else if (key === 'passport_seria_and_number') {
            formdata.append("passport_seria", value.split(" ")[0])
            formdata.append("passport_number", value.split(" ")[1])
          } else {
            formdata.append(key, String(value));
          }
        }
      });

      formdata.append("user_access", JSON.stringify(userAccess))
      formdata.append("teacherAccess", JSON.stringify(teacherAccess));

      const response = await instance({ url: `/users/${match?.params?.user_id}`, method: 'PUT', data: formdata });

      if (response.data.status === 1) {
        asyncN("success", 'create', response.data?.message)
          .then(() => {
            history.goBack()
          })
      } else {
        asyncN("error", 'update', response.data?.message)
      }
    } catch (error: any) {

    }
  }

  return (
    <div className="bg-light p_12 rounded rounded-3">
      <Col span={24} className="mb_12" >
        <Card style={{ position: "relative", zIndex: 100 }}>
          <CardBody className='p-2'>
            <CustomBreadcrumb arr={_breadcrump} />
          </CardBody>
        </Card>
      </Col>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          className="custom_form_style"
        >
          <Row gutter={[12, 12]}>
            <Col xl={10} lg={24} md={24} sm={24} xs={24}>
              <CreateAndUpdateForm form={form} fileList={fileList} setFileList={setFileList} passportFile={filePassport} />
            </Col>
            <Col xl={14} lg={24} md={24} sm={24} xs={24}>
              <UserAdditionalInfo form={form} userAccess={userAccess} setUserAccess={setUserAccess} />
              <CardContainer className="mt_12" >
                <NewTeacherAccess teacherAccess={teacherAccess} setTeacherAccess={setTeacherAccess} />
              </CardContainer>
              <div className="text-end mt-3">
                <Button type="primary" danger className="px-5" onClick={() => { form.resetFields(); history.goBack(); }}>{t("Cancle")}</Button>
                <Button type="primary" className="ms-3 px-5" htmlType="submit" >{t("Save")}</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div >
  )
};

export default NewUpdateTeacher
