
import { FC, useEffect, useState } from "react"
import SimpleIndex from "pages/common/simple_index";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import BadgeStatus from "components/BadgeStatus";
import Actions from "components/Actions";
import useGetAllData from "hooks/useGetAllData";
import DiscriptionStyle from "components/DescriptionStyle";
import { useTranslation } from "react-i18next";
import isHasAuthRoute from "utils/isHasRoute";
import { Button, Popconfirm, Tooltip } from "antd";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import useDeleteOne from "hooks/useDeleteOne";
import { BiPlus } from "react-icons/bi";
import SimpleCreate from "pages/common/simple_create";
import SimpleView from "pages/common/simple_view";
import SimpleEdit from "pages/common/simple_edit";
import { useAppDispatch, useAppSelector } from "store/services";
import DormitoryCreate from "./crud/create";
import CreateButton from "components/CreateButton";
import { DORMITORY_ACTIONS } from "store/dormitory/indext";
import IndexPageTitle from "components/IndexPageTitle";
import SingleInputLanguage from "components/MultipleInput/SingleInputLanguage";
import DormitoryCreateType from "./crud/create";
import { ButtonCreate } from "components/Buttons";
import ForTable from "components/ForTable";
import EmptyTable from "components/EmptyTable";
import { AiOutlineCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import ForTitle from "components/ForTitles";

const DormitoryCategory: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const dispatch: any = useAppDispatch();
    const { visible, category_id, reFetch } = useAppSelector(state => state.dormitory);
    const [typeVisible, setTypeVisible] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)

    const _delete = useDeleteOne();
    const { items, loading, data } = useGetAllData({ url: '/hostel-categories', isCall: 'auto', refetch: [_delete.refetch, reFetch], currentPage, perPage });
    
    useEffect(() => {
        dispatch(DORMITORY_ACTIONS.reFetch())
    }, [currentPage, perPage])

    return (
        <div>
            <ForTitle title="Talabalar turar joy hujjat toifalari" buttons={[<ButtonCreate onClick={() => dispatch(DORMITORY_ACTIONS.createModal())}>{t("Create")}</ButtonCreate>]}/>
            <DormitoryCreateType visible={typeVisible} setVisible={setTypeVisible} />
            <SimpleCreate url={`/hostel-categories`} visible={visible === 'create'} setVisible={() => dispatch(DORMITORY_ACTIONS.closeModal())} setReFetch={() => dispatch(DORMITORY_ACTIONS.reFetch())} title="Talabalar turar joyi hujjat toifasini qo'shish" />
            <SimpleEdit url={`/hostel-categories/${category_id}`} visible={visible === 'update'} setVisible={() => dispatch(DORMITORY_ACTIONS.closeModal())} setReFetch={() => dispatch(DORMITORY_ACTIONS.reFetch())} title="Talabalar turar joyi hujjat toifasini o'zgartirish" />
            <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={data._meta.totalCount} datas={items} theads={
                    [
                        <>{t("Name")}</>,
                        <>{t("Description")}</>,
                        <>Turlari</>,
                        <>{t("Status")}</>,
                        <>{t("Actions")}</>,
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            <>{this.name}</>,
                            <><DiscriptionStyle>{this?.description}</DiscriptionStyle></>,
                            <><div className="ant-col"><Button type="primary" size="small" className="w-100" onClick={() => setTypeVisible(true)} ><BiPlus /></Button></div></>,
                            <><BadgeStatus status={`${t(this.status)}`} /></>,
                            <>
                                <div className="crud_actions_table">
                                    {isHasAuthRoute("hostel-category_view") && (
                                        <Tooltip placement="top" title={t("View")}>
                                            <TbFileInvoice  className="view_btn" />
                                        </Tooltip>
                                    )}
                                    {isHasAuthRoute("hostel-category_update") && (
                                        <Tooltip placement="top" title={t("Edit")}>
                                            <TbFilePencil className="edit_btn" onClick={() => { dispatch(DORMITORY_ACTIONS.updateModal({ category_id: this?.id })) }} />
                                        </Tooltip>
                                    )}
                                    {isHasAuthRoute("hostel-category_delete") && (
                                        <Tooltip placement="top" title={t("Delete")}>
                                            <Popconfirm
                                                placement="topLeft"
                                                title={`${t("Deleted it")} ?`}
                                                okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                                                onConfirm={() => _delete.fetch(`/hostel-categories/${this?.id}`)}
                                            >
                                                <TbFileOff className="delete_btn" />
                                            </Popconfirm>
                                        </Tooltip>
                                    )}
                                </div>
                            </>
                        ]
                    }
                } loading={loading}/>
        </div>
    )
}

export default DormitoryCategory;



/**
hostel-category-type_create
hostel-category-type_delete
hostel-category-type_update
hostel-category-type_view
hostel-category-type_index
hostel-category_index
hostel-category_create
hostel-category_delete
hostel-category_update
hostel-category_view
 */



