import { FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Calendar, Spin } from 'antd';
import { BiDotsVerticalRounded, BiEditAlt, BiTrash } from 'react-icons/bi';
import { EverySubjectOnCard, TimeTableCardMain, WrapperTeacherPanel } from './style';
import MessageInDashboard from '../common/message';
import "../style.scss"
import ForTitle from 'components/ForTitles';
import LessonCard from './lessonCard';
import useGetAllData from 'hooks/useGetAllData';
import { useAppSelector } from 'store/services';
import isRole from 'utils/isRole';

const lessons = [
    {
        day: 'Dushanba',
        lessons: [
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '2 bino',
                room: '16 A'
            },
            {
                name: 'Xalqaro',
                type: 'Yozma',
                build: '3 bino',
                room: '20 B'
            },
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '1 bino',
                room: '11 A'
            },
        ]
    },
    {
        day: 'Seshanba',
        lessons: null
    },
    {
        day: 'Chorshanba',
        lessons: [
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '2 bino',
                room: '16 A'
            },
            {
                name: 'Xalqaro',
                type: 'Yozma',
                build: '3 bino',
                room: '20 B'
            },
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '1 bino',
                room: '11 A'
            },
        ]
    },
    {
        day: 'Payshanba',
        lessons: [
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '2 bino',
                room: '16 A'
            },
            {
                name: 'Xalqaro',
                type: 'Yozma',
                build: '3 bino',
                room: '20 B'
            },
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '1 bino',
                room: '11 A'
            },
        ]
    },
    {
        day: 'Juma',
        lessons: [
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '2 bino',
                room: '16 A'
            },
            {
                name: 'Xalqaro',
                type: 'Yozma',
                build: '3 bino',
                room: '20 B'
            },
            {
                name: 'Xalqaro huquq',
                type: 'Maruza',
                build: '1 bino',
                room: '11 A'
            },
        ]
    },
]

const TeacherPanel: FC = (): JSX.Element => {
    const auth: any = useAppSelector(state => state.auth);
    const timetable: any = useGetAllData({
        url: 'time-tables?expand=para,subject,subjectCategory,week,teacher,teacher.user_id,building,room',
        isCall: 'auto',
        perPage: 0
    })
    const [lessons, setLessons] = useState([])
    
    useEffect(() => {
        let arr: any = []
        arr = timetable?.items.map((item: any, index: number) => item?.week?.name)
        arr = arr.filter((item: any, index: number) => arr.indexOf(item) === index)
        arr = arr.map((item: any) => ({day: item, lessons: []}))
        arr.forEach((item: any, index: number) => {
            timetable?.items.forEach((item1: any, index1: any) => {
                if(item.day === item1?.week?.name){
                    arr[index].lessons.push(
                        {
                            name: item1?.subject?.name,
                            type: item1?.subjectCategory?.name,
                            build: item1?.building?.name,
                            room: item1?.room?.name,
                            start: item1?.para?.start_time,
                            end: item1?.para?.end_time
                        }
                    )
                }
            })
        })
        setLessons(arr)
    }, [timetable?.items])
    

    const { t } = useTranslation();
    const [isSetting, setIsSetting] = useState<number | null>(null);
    const [canOpen, setCanOpen] = useState<number>(-1)

    const isOpenSetting = (id: number) => {
        if (id === isSetting) {
            setIsSetting(null)
        } else {
            setIsSetting(id)
        }
    }

    return (
        <>
            <ForTitle title='Dashboard' buttons={[null]}/>
            <Row>
                <Col sm={24} md={24} lg={24} xl={16} xs={24}>
                    <Spin spinning={timetable.loading}>
                        <Card className='forLessons mx-1 mb-2' title={<h5>Dars jadvali</h5>}>
                            <Row>
                                {
                                    lessons.map((item: {
                                        day: string,
                                        lessons: [
                                            {
                                                type: string,
                                                build: string,
                                                room: string,
                                                start: string,
                                                end: string
                                            }
                                        ]
                                    }, index: number) => (
                                        <Col sm={24} md={24} lg={12} xl={12} key={index}>
                                            <LessonCard canEdit={isRole("time-table_edit")} title={item.day} lessons={item.lessons} index={index} canOpen={canOpen} setCanOpen={setCanOpen}/>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Card>
                        <Card className='mx-1' title={<h5>Ish stavkasi</h5>}>
                            
                        </Card>
                    </Spin>
                </Col>
                <Col sm={24} md={24} lg={24} xl={8} xs={24}>
                    <Row>
                        <Col sm={24} md={24} lg={24} xl={24} xs={24}>
                            <Card title={<h5>Kalendar</h5>} className='mx-1 mb-2'>
                                <Calendar fullscreen={false} />
                            </Card>
                        </Col>
                        <Col sm={24} md={24} lg={24} xl={24} xs={24}>
                            <Card title={<h5>Xabarlar</h5>} className='mx-1'>
                                <MessageInDashboard/>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}


export default TeacherPanel;