import styled from "styled-components";

export const RipplebuttosStyle = styled.div<{ color: string }>`
  border: none;
  background: transparent;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 0px 0;
  transition: 0.4s;

`

export const HeaderUserBox = styled.div<{ theme: any }>`
    border:1px solid transparent;
`