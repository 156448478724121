import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const StudentCategory: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="student-categories"
                indexTitle="Student Category"
                viewTitle="Talaba toifa"
                editTitle="Talaba toifasini tahrirlash"
                createTitle="Talaba toifasini yaratish"
                permissions={{
                    view_: "social-category_view",
                    delete_: "social-category_delete",
                    update_: "social-category_update",
                    create_: "social-category_create",
                }}
            />
        </div>
    )
}

export default StudentCategory;