import { Button, Col, message, Row, Timeline, Card, Typography, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useHistory, useRouteMatch, match } from 'react-router';
import { asyncN } from 'utils/notifications';
import { getAllLanguages } from 'services';
import { useTranslation } from 'react-i18next';
import useGetOneData from 'hooks/useGetOneData';
import CustomBreadcrumb from 'components/Breadcrumb';
import { questionLevel } from 'utils/variables';
import { getLanguageAsObject } from 'utils/auxiliary_functions';
import { question_confirm, question_confirm_status } from '../confirm_status';
import instance from 'config/_axios';
import isRole from 'utils/isRole';
import { BiFont } from 'react-icons/bi';
import { FILE_URL } from 'config/utils';
import pdf_file from 'assets/images/files_images/pdf-file.png';
import './style.scss';
import { FaFileSignature, FaRegFilePdf } from 'react-icons/fa';
import isHasAuthRoute from 'utils/isHasRoute';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { TbFilePencil } from 'react-icons/tb';

const _breadcrump = [
    {
        name: "Home",
        path: "/",
    },
    {
        name: "Questios",
        path: "questions",
    },
    {
        name: "Question view",
        path: "question_view",
    },
];

const { Text } = Typography;

const ViewQuestionPage: FC = (): JSX.Element => {


    const history = useHistory();
    const { t } = useTranslation();
    const match: match<any> | null = useRouteMatch("/question-view/:id");


    if (!match || !match?.params?.id) {
        message.error("Id ma'lumoti topilmadi !").then(() => history.goBack())
    }

    const [refetch, setRefetch] = useState<boolean>(false);
    const [languages, setLanguages] = useState<any[]>([]);
    const [textSize, setTextSize] = useState<number>(15);


    const _question = useGetOneData({ url: `/questions/${match?.params?.id}?expand=subject,course,semestr,questionType,language,createdBy,updatedBy`, isCall: 'auto', refetch: [refetch] });



    useEffect(() => {
        (
            async () => {
                try {
                    const resp = await getAllLanguages();
                    setLanguages(resp?.items || []);
                } catch (e: any) {
                    asyncN("error", "read", "Error for get languages!!!")
                }
            }
        )()
    }, [])

    const _updateQuestion = async (id: number, status: string,) => {
        try {
            const formdata = new FormData();
            formdata.append('status', status);
            const response = await instance({ url: `/questions/status-update/${id}`, method: "PUT", data: formdata });

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message).then(() => setRefetch(!refetch))
            } else {
                asyncN("error", 'update', response.data?.message)
            }
        } catch (error) {
            asyncN("error", 'update', "Ma'lumot yangilashda xatolik!")
        }
    }


    const isImage = (_url: string) => {
        if (_url) {
            const _url_arr = _url.split('.');
            // ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']
            return ['JPEG', 'JPG', 'PNG', 'GIF', 'TIFF', 'PSD', , 'EPS', 'AI', 'INDD', 'RAW', 'jpeg', 'jpg', 'png', 'gif', 'tiff', 'psd', 'eps', 'ai', 'indd', 'raw'].includes(_url_arr[_url_arr.length - 1]);
        }
    }

    return (
        <div className='mb-3'>
            <div className='d-f justify-content-between'>
                <CustomBreadcrumb arr={_breadcrump} />
                {isHasAuthRoute("question_update") && <NavLink to={"/edit-questions/" + _question?.data?.id}><Button type='primary'><TbFilePencil className='me-2' />{t("Edit")}</Button></NavLink>}
            </div>
            <Card className='mt-4'>
                <Row gutter={[12, 0]} className="bg-white">
                    <Col xl={6} lg={8} md={8} sm={8} xs={12}><span className='info_text_key'>Fan:</span> <span className='info_text_value'>{_question?.data?.subject?.name}</span></Col>
                    <Col xl={6} lg={8} md={8} sm={8} xs={12}><span className='info_text_key'>Semester:</span> <span className='info_text_value'>{_question?.data?.semestr?.name} - semester</span></Col>
                    <Col xl={6} lg={8} md={8} sm={8} xs={12}><span className='info_text_key'>{t("Status")}:</span><span>{question_confirm_status(_question?.data?.status)}</span></Col>
                    <Col xl={6} lg={8} md={8} sm={8} xs={12}><span className='info_text_key'>Kiritilgan:</span><span>{_question.data?.createdBy?.last_name}&nbsp;{_question.data?.createdBy?.first_name}</span></Col>
                </Row>
            </Card>
            <Card
                style={{ marginTop: 16 }}
                type="inner"
                className='px-0'
                title={
                    <span>
                        {_question?.data?.questionType?.name}
                        <span className='info_text_key ms-2'>Savol darajasi:</span> <span className='info_text_value'>{questionLevel[_question?.data?.level]}</span>
                        <span className='info_text_key ms-2'>Tili:</span> <span className='info_text_value'>{getLanguageAsObject(languages)[_question?.data?.lang_id]}</span>
                    </span>}
                extra={
                    <div>
                        <Button className="me-2" size="small" onClick={() => setTextSize(textSize > 13 ? textSize - 1 : textSize)} ><BiFont /> <sup>-</sup> </Button>
                        <Button className="btn-filter" size="small" onClick={() => setTextSize(textSize < 30 ? textSize + 1 : textSize)} ><BiFont /><sup>+</sup></Button>
                        {
                            isRole(['mudir', 'dean', 'edu_admin']) ?
                                <>
                                    <Button type="primary" size="small" style={{ fontSize: '12px', margin: "0 10px" }} disabled={question_confirm(_question?.data?.status).disabled_confirm} onClick={() => _updateQuestion(_question?.data?.id, '1')}>Tasdiqlash</Button>
                                    <Button type="primary" size="small" danger style={{ fontSize: '12px' }} disabled={question_confirm(_question?.data?.status).disabled_cancel} onClick={() => _updateQuestion(_question?.data?.id, '0')} >Bekor qilish</Button>
                                </> : null
                        }
                    </div>
                }
            >
                <div className="mt-3" style={{ backgroundColor: '#FDFEFE' }}>
                    <span className={`question_main_view text_size_question_${textSize}`} dangerouslySetInnerHTML={{ __html: _question.data?.question }}></span>
                    {_question?.data?.file && <div>
                        {isImage(String(_question?.data?.file)) ?
                            <div className='question_img_box'>
                                <img src={FILE_URL + _question?.data?.file} alt="" />
                            </div> : <iframe src={FILE_URL + _question?.data?.file} width="100%" height={'800px'}></iframe>
                        }
                    </div>}
                    <div className='mt-5'>
                        <Timeline mode='left'>
                            {
                                _question?.data?.subQuestion?.length ? _question?.data?.subQuestion.map((element: any, index: number) => {
                                    return <Timeline.Item key={index}> <span style={{ color: '#1890FF', fontSize: `${textSize - 2}px` }}>{index + 1}-savol ({element?.percent}%)</span><br /><span className={`question_main_view text_size_question_${textSize}`} dangerouslySetInnerHTML={{ __html: element?.question }}></span></Timeline.Item>
                                }) : null
                            }
                        </Timeline>
                    </div>
                </div>
            </Card>

            <Row gutter={[24, 24]} className="mt-3 px-2" >
                <Col lg={{ span: 12 }} xs={{ span: 24 }} >{_question.data?.createdBy ? <Row gutter={[12, 12]} className="border p-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Created By")}</Text></Col>
                    <Col span={24} ><Text>{t("First Name")}: </Text>{_question.data?.createdBy?.first_name}</Col>
                    <Col span={24} ><Text>{t("Last Name")}: </Text>{_question.data?.createdBy?.last_name}</Col>
                    <Col span={24} ><Text>{t("Login")}: </Text>{_question.data?.createdBy?.username}</Col>
                    <Col span={24} ><Text>{t("Role")}: </Text>{_question.data?.createdBy?.role ? _question.data?.createdBy?.role?.map((e:string, i: number) => (<Tag key={i} >{e}</Tag>)) : null}</Col>
                    <Col span={24} ><Text>{t("Created at")}: </Text>{moment(_question.data?.created_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}</Col>
                <Col lg={{ span: 12 }} xs={{ span: 24 }} >{_question.data?.updatedBy ? <Row gutter={[12, 12]} className="border p-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Updated By")}</Text></Col>
                    <Col span={24} ><Text>{t("First Name")}: </Text>{_question.data?.updatedBy?.first_name}</Col>
                    <Col span={24} ><Text>{t("Last Name")}: </Text>{_question.data?.updatedBy?.last_name}</Col>
                    <Col span={24} ><Text>{t("Login")}: </Text>{_question.data?.updatedBy?.username}</Col>
                    <Col span={24} ><Text>{t("Role")}: </Text>{_question.data?.updatedBy?.role ? _question.data?.updatedBy?.role?.map((e:string, i: number) => (<Tag key={i} >{e}</Tag>)) : null}</Col>
                    <Col span={24} ><Text>{t("Updated at")}: </Text>{moment(_question.data?.updated_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}</Col>
            </Row>


        </div>
    )
}

export default ViewQuestionPage
