import store from "store";
import { logout, TypeInitialStateAuth } from "store/auth";



const isHasAuthRoute = (name: string) => {

    const auth: TypeInitialStateAuth = store.getState().auth as TypeInitialStateAuth;

    if (Array.isArray(auth?.permissions) && auth.permissions.length > 0) {
        return auth.permissions?.includes(name);
    } else {
        store.dispatch(logout())
        sessionStorage.removeItem("access_token");
        return false
    }
}

export default isHasAuthRoute;