import React, { FC, useState } from 'react';
import { Form, Row, Col, Button, Switch, TimePicker, Modal } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import ModalTitle from 'components/ModalTitle';
import MultipleInput from 'components/MultipleInput';

type CreateParaType = {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setReFetch: React.Dispatch<React.SetStateAction<boolean>>,
}

const CreatePara: FC<CreateParaType> = ({ visible, setVisible, setReFetch }) => {

    const [form] = Form.useForm();
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true)
    const { t } = useTranslation();


    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            for (const [key, value] of Object.entries(values)) {
                if (key === 'status') {
                    formdata.append(key, status ? '1' : '0');
                } else {
                    formdata.append(key, String(value));
                }
            }
            formdata.append('start_time', moment(values?.start_time).format("HH:mm"));
            formdata.append('end_time', moment(values?.end_time).format("HH:mm"));

            const response = await instance({ url: '/paras', method: 'POST', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); setVisible(false); setReFetch(propState => !propState) })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();

    return (
        <Modal
            title={'Para create'}
            visible={visible}
            onCancel={() => { setVisible(false); resetFields() }}
            width={800}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
            >
                <Row gutter={[12, 0]}>
                    <Col span={24} className="mb-2">
                        <MultipleInput layout='vertical' />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t("Start time")}`}
                            name="start_time"
                        >
                            <TimePicker
                            showNow={false}
                            className="w-100"
                            format="HH:mm" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t("End time")}`}
                            name="end_time"
                        >
                            <TimePicker
                            showNow={false}
                            className="w-100"
                            format="HH:mm" />

                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Col span={24} className="mt-2">
                    <Row gutter={[12, 12]}>
                        <Col lg={{ span: 12 }}>
                            <Form.Item name="status" className='mb-0' >
                                    <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
                            </Form.Item>
                        </Col>
                        <Col lg={{ span: 12 }} className="text-end">
                            <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { setVisible(false) }}>{t("Cancel")}</Button>
                            <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                            <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </Modal>
    )
}


export default CreatePara;