import { Button, Col, DatePicker, message, Popconfirm, Row, Select, Tag, Tooltip } from "antd";
import { ButtonCreate } from "components/Buttons";
import EmptyTable from "components/EmptyTable";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import instance from "config/_axios";
import useGetAllData from "hooks/useGetAllData";
import moment from "moment";
import { userAccessType } from "pages/user/components/types";
import { getUserAccessTypes } from "pages/user/servise";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Link, useHistory } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { delete_data } from "services";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import CreateTableModal from "./options/create_table";
import { tableStoreType } from "./type";

const { Option } = Select
const Tables: FC = (): JSX.Element => {

    const history = useHistory();
    const { t } = useTranslation()
    const [visible, setVisible] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false)
    const [filter, setFilter] = useState<{ user_access_type_id?: number, table_id?: number, type?: number, month?: number, year?: number }>()
    const [userAccessTypes, setUserAccessTypes] = useState<Array<userAccessType>>([]);
    const [tables, setTables] = useState<Array<{ id: number, name: string, userAccess: Array<{ id: number, name: string }> }>>([]);
    const [tableStore, setTableStore] = useState<tableStoreType | null>(null)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)

    useEffect(() => {
        (
            async () => {
                const response: any = await getUserAccessTypes();
                setUserAccessTypes(response?.items)
            }
        )()
    }, [])

    const getAccess = async (id: number) => {
        if (id) {
            try {
                let url = userAccessTypes?.filter((item: { id: number, url: string }) => item?.id === id)[0]?.url;
                const resp = await instance({
                    method: 'get',
                    url: `/${url}?per-page=0`
                })
                if (resp?.data?.status === 1) {
                    setTables(resp?.data?.data?.items)
                } else {
                    asyncN("error", "read", resp?.data?.message);
                }
            } catch (error: any) {
                asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message);
            }
        }
    }

    const _tabels: any = useGetAllData({
        url: `/table-stores?sort=-id&expand=userAccess,department${filter ? '&filter=' + JSON.stringify(filter) : ''}`,
        isCall: "auto",
        perPage: perPage,
        currentPage: currentPage,
        refetch: [filter, refresh]
    })

    useEffect(() => {
        setRefresh(!refresh)
    }, [currentPage, perPage])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(_tabels.data.data !== undefined)
    }, [_tabels.data.data])
    

    return (
        <div>
            <ForTitle title={'Tabellar'} buttons={[<ButtonCreate onClick={() => { setVisible(true); setTableStore(null); }}/>]}/>
            <Row gutter={24}>
                <Col span={24}>
                    <Row className="d-flex justify-content-end mb-1" gutter={24}>
                        <Col span={4}>
                            <Select style={{ width: "100%" }} value={filter?.user_access_type_id} allowClear placeholder="User access type!" onClear={() => {
                                setFilter({})
                                setTables([])
                            }} onChange={(e) => {
                                getAccess(e)
                                if (filter) {
                                    setFilter({
                                        ...filter,
                                        user_access_type_id: e,
                                        table_id: undefined
                                    })
                                } else {
                                    setFilter({
                                        user_access_type_id: e,
                                        table_id: undefined
                                    })
                                }
                            }}>
                                {
                                    userAccessTypes?.map((item: userAccessType, i: number) => {
                                        return (
                                            <Option key={i} value={item?.id}>{item?.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Select disabled={Boolean(!filter?.user_access_type_id)} style={{ width: "100%" }} value={filter?.table_id} allowClear placeholder="Bo'lim (Texnikum, kafedra)!" onClear={() => setFilter({
                                user_access_type_id: filter?.user_access_type_id
                            })} onChange={(e) => {
                                if (filter) {
                                    setFilter({
                                        ...filter,
                                        table_id: e
                                    })
                                }
                            }}>
                                {
                                    tables?.map((item: { id: number, name: string }, i: number) => {
                                        return (
                                            <Option key={i} value={item?.id}>{item?.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                        <Col className="d-flex justify-content-between" span={6}>
                            <DatePicker disabled={Boolean(!filter?.table_id)} picker="month" onChange={(e, s) => {
                                if (s) {
                                    let a: Array<string> = s.split("/")
                                    if (filter) {
                                        setFilter({
                                            ...filter,
                                            month: Number(a[0]),
                                            year: Number(a[1])
                                        })
                                    } else {
                                        setFilter({
                                            month: Number(a[0]),
                                            year: Number(a[1])
                                        })
                                    }
                                } else {
                                    setFilter({
                                        user_access_type_id: filter?.user_access_type_id,
                                        table_id: filter?.table_id
                                    })
                                }
                            }} format="MM/YYYY" />
                            <Select value={filter?.type} disabled={Boolean(!filter?.year)} onChange={(e) => {
                                if (filter) {
                                    setFilter({
                                        ...filter,
                                        type: e
                                    })
                                }
                            }} placeholder="Select type!">
                                <Option value={1}>1-15</Option>
                                <Option value={2}>16-{new Date(filter?.year ?? new Date().getFullYear(), filter?.month ?? new Date().getMonth(), 0).getDate()}</Option>
                            </Select>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={_tabels?.data?.data?._meta?.totalCount} loading={_tabels.loading} datas={_tabels.data.data?.items} theads={
                            [
                                <>Bo'limlar ( Kafedra,Texnikum )</>,
                                <>Davr(yil/oy/1-15,16-30|31)</>,
                                <>Izoh</>,
                                <>Tasdiq</>,
                                <>Harakatlar</>,
                            ]
                        } tbodys={
                            function(this: any){
                                return [
                                    <Link to={`/employee-tabel/${this?.user_access_type_id}/${this?.table_id}/${this?.id}/${this?.year}/${this?.month}/${this?.type}`}>{this?.department?.name}</Link>,
                                    <Link to={`/employee-tabel/${this?.user_access_type_id}/${this?.table_id}/${this?.id}/${this?.year}/${this?.month}/${this?.type}`}>{`${this?.year}/${this?.month}/${this?.type === 1 ? '1-15' : '16-' + new Date(this?.year, this?.month, 0)?.getDate()}`}</Link>,
                                    <Link to={`/employee-tabel/${this?.user_access_type_id}/${this?.table_id}/${this?.id}/${this?.year}/${this?.month}/${this?.type}`}>{this?.description}</Link>,
                                    <Link to={`/employee-tabel/${this?.user_access_type_id}/${this?.table_id}/${this?.id}/${this?.year}/${this?.month}/${this?.type}`}><Tag color={this?.is_checked === 1 ? "blue" : "red"}>{this?.is_checked === 1 ? "Tasdiqlangan" : "Tasdiqlanmagan"}</Tag></Link>,
                                    <div style={{ zIndex: 100 }} className="crud_actions_table" >
                                        <Tooltip placement="top" title={t("Edit")}>{isHasAuthRoute("user_update") &&
                                            <TbFilePencil className="edit_btn" onClick={() => {
                                                setVisible(true)
                                                setTableStore(this);
                                            }} />
                                        }
                                        </Tooltip>
                                        <Tooltip placement="top" title={t("Delete")}>{isHasAuthRoute("user_delete") &&
                                            <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`} okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>} onConfirm={() => {
                                                if (this?.is_checked === 0) {
                                                    delete_data(`/table-stores`, this?.id).then(() => setRefresh(!refresh))
                                                } else {
                                                    message.info("Ushbu tabel tasdiqlangan, o'chirishning imkoni yo'q!")
                                                }
                                            }}>
                                                <TbFileOff className="delete_btn" />
                                            </Popconfirm>}
                                        </Tooltip>
                                    </div>,
                                ]
                            }
                        }/>
                </Col>
            </Row>
            <CreateTableModal refresh={refresh} setRefresh={setRefresh} item={tableStore} visible={visible} setVisible={setVisible} />
        </div>

    )
}

export default Tables;