import { createBrowserHistory } from 'history';
import store from 'store';
import { logout } from 'store/auth';
import _logout from './logout';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'config/i18n';



export const error_401 = (err_message: Error) => {
    localStorage.removeItem('access_token');
    store.dispatch(logout());
    // let Backlen = window.history.length;
    // window.history.go(-Backlen);
    // // window.location.href = '/'
    // if(window.history.forward() !== null){
    //     window.history.forward();
    // }
    createBrowserHistory().push('');
    
    // createBrowserHistory().deleteAll()
    message.error(err_message?.message);
}


export const error_500 = (err_message: Error) => {
    message.error(err_message?.message); 
}

export const error_403 = () => message.error('Ruxsat yetarli emas')

export const error_422 = (err_message: Error[]) => {
    // const t = i18n.t
    
    err_message?.forEach((element: any) => {
        message.error(i18n.t(`${Object.values(element)[0]}`));
    });
}