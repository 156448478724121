import { Button, Col, Form, Input, Row, Select } from "antd";
import instance from "config/_axios";
import useGetAllData from "hooks/useGetAllData";
import { FC, useEffect } from "react";
import { asyncN } from "utils/notifications";
import { jobTitleType } from "../job_title/types";
import { editUserAccessStateType } from "../type";
import { workRateType } from "../work_rate/types";
type propType = {
    editUserAccessState: editUserAccessStateType,
    setEditUserAccessState: (editUserAccessState: editUserAccessStateType) => void
    refresh: boolean,
    setRefresh: (refresh: boolean) => void
}

const { Option } = Select;

const UserAccessUpdate: FC<propType> = ({
    editUserAccessState,
    setEditUserAccessState,
    refresh,
    setRefresh
}): JSX.Element => {

    const [form] = Form.useForm()
    const workRate = useGetAllData({
        url: `/work-rates`, isCall: "auto"
    })

    const jobTitle = useGetAllData({
        url: `/job-titles?filter={"user_access_type_id":${editUserAccessState?.item?.user_access_type_id},"table_id":${editUserAccessState?.item?.table_id}}`, isCall: "auto"
    })


    const onFinish = async (values: any) => {
        let data = new FormData()
        Object.entries(values)?.forEach(([key, value]: any) => {
            data.append(key, value)
        })
        data.append("user_access_type_id", editUserAccessState.item?.user_access_type_id)
        data.append("table_id", editUserAccessState.item?.table_id)
        data.append("user_id", editUserAccessState.item?.user_id)
        try {
            const resp = await instance({
                url: `/user-accesses/${editUserAccessState?.item?.id}`,
                method: "put",
                data: data
            })
            if (resp?.data?.status === 1) {
                setRefresh(!refresh)
                setEditUserAccessState({
                    item: null,
                    visibleModal: false,
                    edit: editUserAccessState?.edit
                })
            } else {
                asyncN("error", "create", resp?.data?.message);
            }
        } catch (error: any) {
            if (error?.response?.status === 422) {
                asyncN("error", "create", error?.response?.data?.errors[0]?.type);
            } else {
                asyncN("error", "create", error?.response ? error?.response?.data?.message : error?.message);
            }
        }
    }

    useEffect(() => {
        if (editUserAccessState?.item) {
            form.setFieldsValue({
                ...editUserAccessState?.item
            })
        }
    })

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="tabel_number" label="Tabel number" rules={[{ required: true, message: "Please fill the field!" }]}>
                        <Input type="number" placeholder="Tabel number!" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="work_rate_id" label="Work rate" rules={[{ required: true, message: "Please fill the field!" }]}>
                        <Select allowClear placeholder="Work rate!">
                            {
                                workRate?.data?.items?.map((item: workRateType, i: number) =>
                                    <Option key={i} value={item?.id}>
                                        {item?.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="job_title_id" label="Job title" rules={[{ required: true, message: "Please fill the field!" }]}>
                        <Select allowClear placeholder="Job title!">
                            {
                                jobTitle?.data?.items?.map((item: jobTitleType, i: number) =>
                                    <Option key={i} value={item?.id}>
                                        {item?.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col className="d-flex justify-content-end" span={24}>
                    <Button onClick={() => setEditUserAccessState({ item: null, visibleModal: false, edit: editUserAccessState?.edit })} danger type="primary">Cancel</Button>
                    <Button type="default" className="mx-2" htmlType="reset">Reset</Button>
                    <Button type="primary" htmlType="submit">Save</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default UserAccessUpdate;