import { FC } from "react";
import { Row, Col } from "antd";
import EmptyTable from "components/EmptyTable";
import MessageItem from "pages/message/components/MessageItem";
import { useAppSelector } from "store/services";
import { ArrayChecker } from "utils/ArrayChecker";


const MessageInDashboard: FC = (): JSX.Element => {

    const messages = useAppSelector(state => state.data.messages);

    const _dataEdit = () => {
    }

    const _deleteMessage = () => {
    }

  return (
    <Row>
      <Col sm={24} lg={24} md={24} xl={24}>
        {
          ArrayChecker(messages.messages)._ ? messages.messages.map((element: any, index) => <MessageItem key={index} isType="0" _state="unread" element={element} update={_dataEdit} _deleteMessage={_deleteMessage} isDelete={true} />) : null
        }
        <EmptyTable data={messages?.messages} loading={false} searchText={"Xabarlar yuq"} />
      </Col>
    </Row>
  )
}

export default MessageInDashboard;