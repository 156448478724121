import { Button, Col, DatePicker, Form, Input, Row, Switch, Tooltip } from 'antd';
import CustomBreadcrumb from 'components/Breadcrumb';
import instance from 'config/_axios';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { asyncN } from 'utils/notifications';
import { getOneEduSemester } from '../service';

const _breadcrump = [
  {
    name: "Home",
    path: "/"
  },
  {
    name: "Education Semester",
    path: 'edu_semestr_index'
  },
  {
    name: "Edu semester edit",
    path: 'edu-semestr_update'
  },
]

const EduSemestrEdit: FC = (): JSX.Element => {

  const [form] = Form.useForm();
  const [t] = useTranslation();
  const location: any = useLocation();
  const history = useHistory()
  const [status, setStatus] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<boolean>(false);

  useEffect(() => {
    if (location.state?.id) {
      (
        async () => {
          try {
            const res = await getOneEduSemester(location.state?.id, 'expand=course,eduPlan,eduYear&self=1')
            form.setFieldsValue({
              name: res?.name,
              course_id: res?.course?.name,
              edu_plan_id: res?.eduPlan?.name,
              edu_year_id: res?.eduYear?.name,
              start_date: moment(res?.start_date),
              end_date: moment(res?.end_date),
              credit: res?.credit,
              status: Number(res?.status) === 1 ? setStatus(true) : setStatus(false),
              is_checked: Number(res?.isChecked) === 1 ? setIsCheck(true) : setIsCheck(false),
            })

          } catch (error: any) {
            asyncN("error", "read", error?.response?.message);
          }
        }
      )()
    }
  }, [])

  if (!location.state?.id) {
    history.goBack();
  }

  const onSubmit = async (values: any) => {
    try {
      const formdata = new FormData();
      formdata.append("start_date", moment(values?.start_date).format("YYYY-MM-DD"));
      formdata.append("end_date", moment(values?.end_date).format("YYYY-MM-DD"));
      formdata.append("credit", values?.credit);
      formdata.append("status", status ? '1' : '0');
      formdata.append("is_checked", isCheck ? '1' : '0');

      const res = await instance({
        url: `/edu-semestrs/${location.state?.id}`,
        method: 'PUT',
        data: formdata,
      })

      if (res.data.status === 1) {
        asyncN("success", 'update', res.data?.message)
        history.goBack();
      } else {
        asyncN("error", 'update', res.data?.message)
      }

    } catch (error: any) {
      asyncN("error", "read", error?.response?.message)
    }
  }

  return (
    <>
      <Row>
        <Col span={24} > <CustomBreadcrumb arr={_breadcrump} /> </Col>
      </Row>
      <hr />
      <Form form={form} layout='vertical' onFinish={onSubmit} >
        <Row gutter={24} className='mt-4' >
          <Col lg={{ span: 8 }} xs={{ span: 24 }} >
            <Tooltip placement='topLeft' title={t("O'zgartirib bo'lmaydi")}>
              <Form.Item
                label={t("Name")}
                name="name"
                shouldUpdate
                rules={[{ required: false, message: t("Fill in the field!") }]}
              >
                <Input placeholder={t('Enter semestr name')+" ..."} readOnly={true} />
              </Form.Item>
            </Tooltip>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }} >
            <Tooltip placement='topLeft' title={t("O'zgartirib bo'lmaydi")}>
              <Form.Item
                label={t("Course")}
                name="course_id"
                shouldUpdate
                rules={[{ required: false, message: t("Fill in the field!") }]}
              >
                <Input placeholder={t('Enter course name')+" ..."} readOnly={true} />
              </Form.Item>
            </Tooltip>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }} >
            <Tooltip placement='topLeft' title={t("O'zgartirib bo'lmaydi")}>
              <Form.Item
                label={t("Curriculum")}
                name="edu_plan_id"
                shouldUpdate
                rules={[{ required: false, message: t("Fill in the field!") }]}
              >
                <Input placeholder={t("Enter edu plan name")+" ..."} readOnly={true} />
              </Form.Item>
            </Tooltip>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }} >
            <Tooltip placement='topLeft' title={t("O'zgartirib bo'lmaydi")}>
              <Form.Item
                label={t("Edu year")}
                name="edu_year_id"
                shouldUpdate
                rules={[{ required: false, message: t("Fill in the field!") }]}
              >
                <Input placeholder={t("Enter edu year name")+" ..."} readOnly={true} />
              </Form.Item>
            </Tooltip>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }} >
            <Form.Item
              label={t("Start date")}
              name="start_date"
              shouldUpdate
              rules={[{ required: false, message: t("Fill in the field!") }]}
            >
              <DatePicker placeholder={t("Select date")} className="w-100" />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }} >
            <Form.Item
              label={t("End date")}
              name="end_date"
              shouldUpdate
              rules={[{ required: false, message: t("Fill in the field!") }]}
            >
              <DatePicker placeholder={t("Select date")} className="w-100" />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }} >
              <Form.Item
                label={t("Credit")}
                name="credit"
                shouldUpdate
                rules={[{ required: false, message: t("Fill in the field!") }]}
              >
                <Input placeholder={t("Enter credit")+" ..."} />
              </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }} >
            <Row>
              <Col span={12} >
                <Form.Item
                  label={t("Confirmation")}
                  name="is_checked"
                >
                  <Switch checkedChildren="On" unCheckedChildren="Off" checked={isCheck} onChange={() => setIsCheck(!isCheck)} />
                </Form.Item>
              </Col>
              <Col span={12} >
                <Form.Item
                  label={t("Status")}
                  name="status"
                >
                  <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className='d-f justify-content-end w-100'>
          <div>
            <Button onClick={() => form.resetFields()} >{t("Clear")}</Button>
            <Button className='mx-3' type='primary' danger onClick={() => history.goBack()} >{t("Cencle")}</Button>
            <Button htmlType='submit' type='primary'>{t("Submit")}</Button>
          </div>
        </Row>
      </Form>
    </>
  );
}

export default EduSemestrEdit;