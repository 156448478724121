import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { permissions } from "./types";
import { Col, Form, Input, Row, Card, Button, Select } from "antd";
import instance from "config/_axios";
import '../style.scss'
import { asyncN } from "utils/notifications";
import CustomBreadcrumb from "components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { getAllRoles } from "../service";

const layout = {
    labelCol: { offset: 0, span: 24 },
    wrapperCol: { offset: 0, span: 24 },
};

const AddRole = () => {
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const [permissions, setPermissions] = useState<permissions[]>([]);
    const [selectedP, setSelectedP] = useState<string[]>([]);
    const [error, setError] = useState<string>("");
    const [form] = Form.useForm();
    const [roles, setRoles] = useState<Array<{ id: number, name: string }>>([]);
    const [children, setChildren] = useState<Array<string>>([])
    const [parents, setParents] = useState<Array<string>>([])
    const _breadcrump = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Roles",
            path: "roles",
        },
        {
            name: "Create role",
            path: "add-role",
        },
    ];

    useEffect(() => {
        (
            async () => {
                const response = await instance({
                    method: 'get',
                    url: 'permissions'
                })

                if (response.data.status === 1) {
                    setPermissions(response.data.data);
                }
            }
        )()
    }, []);

    const checkedCategory = (items: { name: string, title: string }[] | null, allItems: permissions[] | null): boolean => {
        if (items !== null) {
            let a = 0;
            selectedP.map((item: string) => {
                items.map((item1: { name: string, title: string }) => {
                    if (item1.name === item) a++;
                })
            });
            return a === items.length
        } else {
            let a = 0, b = 0;
            if (allItems !== null) {

                allItems.map((item1: permissions) => {
                    item1.permissions.map((item2: { name: string, title: string }) => {
                        selectedP.map((item: string) => {
                            if (item === item2.name) {
                                a++;
                            }
                        });
                        b++
                    })
                });
            }
            return b !== 0 && a === b;
        }
    };

    useEffect(() => {
        (
            async () => {
                const resp = await getAllRoles()
                setRoles(resp?.items)
            }
        )()
    }, [])

    const checkAll = (checked: boolean): void => {
        let a: string[] = [];
        if (checked) {
            permissions.map((item: permissions) => {
                item.permissions.map((per: { name: string, title: string }) => {
                    a.push(per.name);
                })
            });
            setSelectedP(a)
        } else {
            setSelectedP(a);
        }
    };


    const onFinish = async (values: any) => {

        if (selectedP.length > 0) {
            let a: {
                role: string,
                parents: Array<string>,
                childs: Array<string>,
                description: string
                permissions: Array<string>
            } = {
                role: values?.name,
                description: values?.description,
                parents: Array.isArray(values?.parents) ? values?.parents : [values?.parents],
                childs: Array.isArray(values?.childs) ? values?.childs : [values?.childs],
                permissions: selectedP
            };
            try {
                const response = await instance({
                    method: "post",
                    url: "/roles",
                    data: [a]
                });
                if (response.status === 200) {
                    if (response.data.status === 1) {
                        asyncN("success", "create", a.role + " roli qo'shildi!!!");
                        history.push("/roles");
                    } else {
                        asyncN("error", "create", response?.data?.data?.message);
                    }
                }
            } catch (error: any) {
                asyncN("error", "create", error?.response?.message);
                setError(error?.response?.data?.errors[0])
            }
        } else {
            asyncN("error", "create", "Kamida bir dona permission biriktirilishi shart")
        }
    };

    return (
        <>
            <div className="container-fluid">
                <CustomBreadcrumb arr={_breadcrump} />
                <Form className="mt-4"
                    {...layout}
                    form={form}
                    name="add-role"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>

                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item
                                name={`name`}
                                label={t(`Name`)}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field"),
                                    },
                                ]}
                            >
                                <Input placeholder={t`Name`} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item
                                name={`description`}
                                label={t(`Description`)}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field"),
                                    },
                                ]}
                            >
                                <Input placeholder={t`Name`} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={9}>
                            <Form.Item
                                name={`parents`}
                                label={t("Parents")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field"),
                                    },
                                ]}
                            >
                                <Select onChange={(e: Array<string>) => setParents(e)} mode="multiple" allowClear placeholder={t`Parents`}>
                                    {
                                        roles && roles?.map((item: { id: number, name: string }) => {
                                            return <Select.Option disabled={children.includes(item?.name)} value={item?.name} key={item?.id}>{item?.name}</Select.Option>
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={9}>
                            <Form.Item
                                name={`childs`}
                                label={t("Children")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field!"),
                                    },
                                ]}
                            >
                                <Select onChange={(e: Array<string>) => setChildren(e)} mode="multiple" allowClear placeholder={t`Children`}>
                                    {
                                        roles && roles?.map((item: { id: number, name: string }) =>
                                            <Select.Option disabled={parents?.includes(item?.name)} value={item?.name} key={item?.id}>{item?.name}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={2}>
                            {t("Permissions")} <input checked={checkedCategory(null, permissions)} onChange={(e) => {
                                checkAll(e.target.checked);
                                e.target.checked = checkedCategory(null, permissions)
                            }} type="checkbox" />
                        </Col>
                    </Row>
                    <Card>
                        {
                            permissions && permissions.map((item: permissions, i: number) =>
                                <div className="row d-flex permissions" key={"" + i} >
                                    <div className="col-3 row fw-bold">
                                        <div className="col-1 m-0">
                                            <input type="checkbox"
                                                checked={checkedCategory(item.permissions, null)}
                                                onChange={(e) => {
                                                    let a: string[] = [];
                                                    if (e.target.checked) {
                                                        item.permissions.map((item) => {
                                                            if (!selectedP.includes(item.name)) {
                                                                a.push(item.name);
                                                            }
                                                        });
                                                        setSelectedP([...selectedP, ...a])
                                                    } else {
                                                        item.permissions.map((item) => {
                                                            a.push(item.name);
                                                        });
                                                        setSelectedP(selectedP.filter(item1 => {
                                                            return !a.includes(item1)
                                                        }))
                                                    }
                                                }
                                                } name="" />
                                        </div>
                                        <div className="col-11 m-0">
                                            {item.category}
                                        </div>
                                    </div>
                                    <div className="col-9 d-flex flex-wrap">
                                        {
                                            item.permissions.map((per: { name: string, title: string }, j: number) =>
                                                <div key={j} className="actions" style={
                                                    {
                                                        width: "20%",
                                                        borderTop: j > 4 ? ".01em solid rgba(0,0,0,0.1)" : ""
                                                    }
                                                }>
                                                    <div>
                                                        <div><input checked={selectedP.includes(per.name)}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setSelectedP([...selectedP, e.target.name])
                                                                } else {
                                                                    if (selectedP.length > 0) {
                                                                        setSelectedP(selectedP.filter((item: string) => {
                                                                            return item !== e.target.name
                                                                        }))
                                                                    }
                                                                }
                                                            }
                                                            } type="checkbox" name={per.name} /></div>
                                                        <div>{per.title}</div>
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )

                        }
                    </Card>
                    <Col span={24}>
                        <Button type="default" style={{ float: "right" }} className="m-1">{t("Clear")}</Button>
                        <Button type="primary" htmlType="submit" disabled={error === "validator"} className="m-1"
                            style={{ float: "right" }}>{t("Save")}</Button>
                    </Col>
                </Form>

            </div>
        </>
    )
};

// @ts-ignore
export default AddRole
