
import React, { FC } from "react"
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table"
import { Input, Form, Button, message, Tag, Alert } from "antd";
import PageContentTitle from 'components/Title';
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { BiCheckCircle } from "react-icons/bi";
import { TableLineLoader } from "components/SimpleComponents";
import useDefineCheckers from "../hooks/useDefineCheckers";
import ForTable from "components/ForTable";

const ExamCheckerTeachers: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const history = useHistory()
    let [form] = Form.useForm();
    const [refetch, setReFetch] = useState<boolean>(false);
    const [submit_loading, setSubmitLoading] = useState<boolean>(false);

    const match: any = useRouteMatch('/exams_for_checking/:subject_id/exam_distribution/:exam_id/:type_exam');

    const { semeta, newExamStudentByLang, newTeacherAcces, isConfirmedEduAdmin, isConfirmedMudir, loading, subjectName } = useDefineCheckers();

    React.useEffect(() => {

        if (semeta.length) {

            let new_obj: any = {};

            for (let i = 0; i < semeta.length; i++) {

                const { lang_id, teacher_access_id, count } = semeta[i];

                new_obj[`${lang_id}_${teacher_access_id}`] = count;

            }

            form.setFieldsValue(new_obj);

        }

    }, [semeta.length]);


    const _calculation = (): string[] => {

        const values = form.getFieldsValue();

        let sum: any = {};

        for (let key in values) {
            if (values[key]) {
                const _key = key.split('_')[0];
                if (sum.hasOwnProperty(_key) && values[key]) {
                    sum[_key] = sum[_key] + Number(values[key]);
                } else {
                    sum[_key] = Number(values[key]);
                }
            }
        }

        let _status: string[] = [];

        for (let key in newExamStudentByLang) {

            if (sum[key] && sum[key] !== Number(newExamStudentByLang[key])) {
                _status.push(key)
                switch (key) {
                    case '1':
                        message.warning("O'zbek tilidagi imtihonlar taqsimotida xatolik !")
                        break;
                    case '3':
                        message.warning("Rus tilidagi imtihonlar taqsimotida xatolik !")
                        break;
                    case '2':
                        message.warning("Ingliz tilidagi imtihonlar taqsimotida xatolik !")
                        break;
                    default:
                        break;
                }
            }
        }

        return _status;
    }



    const onSubmit = async (values: any, type?: 'confirm' | undefined) => {
        try {
            let new_obj: any = {};
            for (let key in values) {
                let _key: string = key.split('_')[1];
                if (values[key]) {
                    new_obj[_key] = {
                        lang_id: key.split('_')[0],
                        count: values[key]
                    }
                }
            }
            if (Object.getOwnPropertyNames(new_obj).length && !_calculation().length) {
                setSubmitLoading(true);
                const formdata = new FormData();
                formdata.append('exam_id', match.params?.exam_id);
                formdata.append('smetas', JSON.stringify(new_obj));
                if (type) {
                    formdata.append("status", '1')
                }

                const response = await instance({ url: match.params?.type_exam === 'exam' ? '/exam-semetas' : '/exam-appeal-semetas', method: 'POST', data: formdata });
                if (response.data.status === 1) {
                    asyncN("success", 'create', response.data?.message);
                    setReFetch(!refetch)
                    history.goBack()
                }
                setSubmitLoading(false);
            }
        } catch (error: any) {
            asyncN("error", 'update', error?.response?.data?.errors[0]);
            setSubmitLoading(false);
        }


    }




    return (
        <div className="not_user_select">
            <div className="d-flex justify-content-between align-items-center">
                <PageContentTitle title={t("Imtihonlar taqsimoti") + ' | ' + subjectName} />
                {Array.isArray(semeta) && semeta.length ?
                    <div>{isConfirmedMudir ?
                        <Tag icon={<BiCheckCircle className="mt-2 me-1" size={15} />} color="success" className="py-1 px-2" >Tasdiqlangan</Tag> :
                        <div className="ant-col">
                            <Button type="primary" className="mt-2" loading={submit_loading} onClick={() => onSubmit(form.getFieldsValue(), 'confirm')} >Tasdiqlash</Button>
                        </div>}
                    </div> : null}
            </div>
            <hr />
            {
                isConfirmedEduAdmin ?
                    <Alert message="Taqsimlangan ishlarni qayta taqsimlash imkoni yo'q. O'quv boshqarma tomonidan tasdiqlangan!" banner className="mb-2" /> : null
            }
            <TableLineLoader loading={loading} />
            <Form
                form={form}
                onFinish={onSubmit}
            >
            <ForTable showAllData={true} loading={loading} totalCount={Object.values(newTeacherAcces || {}).length} datas={Object.values(newTeacherAcces || {})} theads={[
                <>{t("F.I.O")}</>,
                <>{t("Uzbek")}&nbsp;({newExamStudentByLang?.uz ?? 0})</>,
                <>{t("Russian")}&nbsp;({newExamStudentByLang?.ru ?? 0})</>,
                <>{t("English")}&nbsp;({newExamStudentByLang?.en ?? 0})</>
            ]} tbodys={function(this: any){
                return [
                    <>{this?.full_name}</>,
                    <>
                        {
                            this?.langs?.includes(1) ?
                                <Form.Item
                                    name={this.teacherAccessByLang && this.teacherAccessByLang[1]}
                                    className="p-0 m-0"
                                >
                                    <Input min={0} max={newExamStudentByLang?.uz} className="w-100" type="number" disabled={!newExamStudentByLang?.uz} onWheel={(e) => e.currentTarget.blur()} />
                                </Form.Item> : null
                        }
                    </>,
                    <>
                        {this?.langs?.includes(3) ?
                            <Form.Item
                                name={this.teacherAccessByLang && this.teacherAccessByLang[3]}
                                className="p-0 m-0"
                            >
                                <Input min={0} max={newExamStudentByLang?.ru} className="w-100" type="number" disabled={!newExamStudentByLang?.ru} onWheel={(e) => e.currentTarget.blur()} />
                            </Form.Item> : null}
                    </>,
                    <>
                        {this?.langs?.includes(2) ?
                            <Form.Item
                                name={this.teacherAccessByLang && this.teacherAccessByLang[2]}
                                className="p-0 m-0"
                            >
                                <Input min={0} max={newExamStudentByLang?.en} className="w-100" type="number" disabled={!newExamStudentByLang?.en} onWheel={(e) => e.currentTarget.blur()} />
                            </Form.Item> : null}
                    </>
                ]
            }} />
            <div className="d-flex justify-content-end">
                <div className="ant-col"><Button type="primary" htmlType="reset" className="px-5" disabled={isConfirmedEduAdmin}>Reset</Button></div>
                <div className="ant-col"><Button type="primary" className="ms-2 px-5" htmlType="submit" disabled={isConfirmedEduAdmin} >Save</Button></div>
            </div>
            </Form>
            {/* <EmptyTable data={newTeacherAcces} loading={loading} /> */}
        </div>
    )
}

export default ExamCheckerTeachers;