import { FC, useState } from "react";
import { Row, Col, Table, Card, Divider } from 'antd';
import LabellineChart from "pages/dashboards/common/charts/LabellineChart";
import CustomBreadcrumb from 'components/Breadcrumb';
import { data, vacancy_data, gender_data, language_data, edu_level_data, dataSourceLanguage1, columnsLanguage, dataSourceLanguage2, dataSourceEduLevel1, columnsEduLevel, dataSourceEduLevel2, age_data } from './utils'
import ForTitle from "components/ForTitles";
import { useTranslation } from "react-i18next";
import ForTable from "components/ForTable";


const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Employee detail statistics",
        path: 'employee_statistic'
    },
]


const EmployeeStatisticPage: FC = (): JSX.Element => {
    const {t} = useTranslation()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)

    return (
        <div>
            <CustomBreadcrumb arr={_breadcrump} />
            <ForTitle title='Xodimlarning batafsil statistikasi' buttons={[null]}/>
            <Row>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <Card className="mx-1" title={<h6>{t("Shtatlar va vakantlar, asosiy va o'rindosh xodimlar soni")}</h6>}>
                        <LabellineChart data={vacancy_data} height={350} />
                        <hr/>
                        <Row gutter={24}>
                            <Col lg={8} md={8} sm={12} xs={24} className="text-center">
                                <b className="info_text_value">230</b><br />
                                <span className="info_text_key">Asosiy va o'rindosh xodimlar</span>
                            </Col>
                            <Col lg={8} md={8} sm={12} xs={24} className="text-center">
                                <b className="info_text_value">50</b><br />
                                <span className="info_text_key">Asosiy shtatdagi xodimlar</span>
                            </Col>
                            <Col lg={8} md={8} sm={12} xs={24} className="text-center">
                                <b className="info_text_value">10</b><br />
                                <span className="info_text_key">O'rindosh xodimlar</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <Card className="mx-1 mb-3" title={<h6>{t('Erkaklar va ayollar soni')}</h6>}>
                        <LabellineChart data={gender_data} height={350} />
                        <hr />
                        <Row gutter={24} className="d-flex">
                            <Col lg={8} md={8} sm={12} xs={24} className="text-center">
                                <b className="info_text_value">290 - 155</b><br />
                                <span className="info_text_key">Asosiy shtatdagi xodimlar (Erkaklar - Ayollar)</span>
                            </Col>
                            <Col lg={8} md={8} sm={12} xs={24} className="text-center">
                                <b className="info_text_value">41 - 13</b><br />
                                <span className="info_text_key">O'rindosh xodimlar (Erkaklar - Ayollar)</span>
                            </Col>
                            <Col lg={8} md={8} sm={12} xs={24} className="text-center">
                                <b className="info_text_value">50 - 95</b><br />
                                <span className="info_text_key">Ta'tildagi xodimlar (Erkaklar - Ayollar)</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <Card className="mx-1" title={<h6>{t('Chet tillarini biladigan xodimlar soni')}</h6>}>
                        <LabellineChart data={language_data} height={350} />
                        <hr />
                        <Row>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <div className="mx-1">
                                    <h6>{t('Asosiy shtatdagi xodimlar')}</h6>
                                    <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={dataSourceEduLevel1.length} loading={false} datas={dataSourceEduLevel1} theads={columnsLanguage.map((item: any) => <>{item.title}</>)} 
                                    tbodys={function(this: any){
                                        return [
                                            this.name,
                                            this.count
                                        ]
                                    }}/>
                                </div>
                            </Col>
                            <Col lg={12} md={24} sm={12} xs={24}>
                                <div className="mx-1">
                                    <h6>{t("O'rindosh xodimlar")}</h6>
                                    <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={dataSourceLanguage2.length} loading={false} datas={dataSourceLanguage2} theads={columnsLanguage.map((item: any) => <>{item.title}</>)}
                                    tbodys={function(this: any){
                                        return [
                                            this.name,
                                            this.count
                                        ]
                                    }}/>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <Card className="mx-1 mb-3" title={<h6>{t("Ma'lumoti bo'yicha xodimlar soni")}</h6>}>
                        <LabellineChart data={edu_level_data} height={350} />
                        <hr />
                        <Row>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <div className="mx-1">
                                    <h6>{t('Asosiy shtatdagi xodimlar')}</h6>
                                    <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={dataSourceEduLevel1.length} loading={false} datas={dataSourceEduLevel1} theads={columnsEduLevel.map((item: any) => <>{item.title}</>)} tbodys={
                                        function(this: any){
                                            return [
                                                this.name,
                                                this.count
                                            ]
                                        }
                                    }/>
                                </div>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <div className="mx-1">
                                    <h6>{t("O'rindosh xodimlar")}</h6>
                                    <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={dataSourceEduLevel2.length} loading={false} datas={dataSourceEduLevel2} theads={columnsEduLevel.map((item: any) => <>{item.title}</>)} tbodys={
                                        function(this: any){
                                            return [
                                                this.name,
                                                this.count
                                            ]
                                        }
                                    }/>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Card className="mx-1" title={<h6>{t("Xodimlarning yoshi bo'yicha soni")}</h6>}>
                        <Row>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Card className="mx-1">
                                    <LabellineChart data={age_data} height={350} />
                                </Card>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                                <Card className="mx-1">
                                    <Row>
                                        <Col lg={12} md={24} sm={24} xs={24}>
                                            <div className="mx-1">
                                                <h6>{t('Asosiy shtatdagi xodimlar')}</h6>
                                                <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={dataSourceEduLevel1.length} loading={false} datas={dataSourceEduLevel1} theads={columnsEduLevel.map((item: any) => <>{item.title}</>)} tbodys={
                                                    function(this: any){
                                                        return [
                                                            this.name,
                                                            this.count
                                                        ]
                                                    }
                                                }/>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={24} sm={24} xs={24}>
                                            <div className="mx-1">
                                                <h6>{t("O'rindosh xodimlar")}</h6>
                                                <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={dataSourceEduLevel2.length} loading={false} datas={dataSourceEduLevel2} theads={columnsEduLevel.map((item: any) => <>{item.title}</>)} tbodys={
                                                    function(this: any){
                                                        return [
                                                            this.name,
                                                            this.count
                                                        ]
                                                    }
                                                }/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );

}

export default EmployeeStatisticPage;
