import cx from 'classnames';
import styles from './displaying.module.scss';
import file_upload from '../../../assets/images/files_images/file-upload.gif'
import { Button, Col, Form, Input, message, Row, Switch, Upload } from 'antd';
import SunEditor from 'suneditor-react';
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import wordImg from '../../../assets/images/files_images/word.png'
import pptImg from '../../../assets/images/files_images/ppt.png'
import xlsImg from '../../../assets/images/files_images/xls.jpg'
import zipImg from '../../../assets/images/files_images/zip11.png'
import { useEffect, useState } from 'react';

type DisplayProps = {
    typeFile: string,
    urlFile: any,
    id: number,
    setFileUrl: (fileUrl: string) => void,
    accept: string,
    maxSize: number,
    fileFile: any,
    setFileFile: (fileFile: any) => void,
    item?: any
}

export const Displaying: React.FC<DisplayProps> = ({ typeFile, urlFile, id, setFileUrl, accept, maxSize, fileFile, setFileFile, item }): React.ReactElement => {
    const getImgThumb = (name: string): any => {

        switch (name) {
            case "doc": return wordImg;
            case "docx": return wordImg;
            case "xls": return xlsImg;
            case "xlsx": return xlsImg;
            case "zip": return zipImg;
            case "ppt": return pptImg;
            case "pptx": return pptImg;
            default: return null
        }
    }

    const removeEl = async (keyProp: string) => {
        let a: any = {};
        Object.entries(urlFile)?.forEach(([key, value]: any) => {
            if (key !== keyProp) {
                a = { ...a, [`${key}`]: value }
            }
        })
        setFileUrl(a);
    }

    console.log('type => ', typeFile);
    

    if (typeFile === "TEXT") {
        return (
            <div className={styles.empty_box}>
                <Row className='p-2' gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            rules={[{
                                required: true,
                                message: "Please input feild!"
                            }]}
                            name={`${id}-text`} label="Text:">
                            <SunEditor setContents={item ? item?.content : ''} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item valuePropName='checked' initialValue={item ? item?.status === 1 : false} messageVariables={{ another: 'good' }} label="Status:" name={`${id}-status`}>
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        )
    } else if (typeFile === "IMAGE") {
        return (
            <div className={urlFile ? styles.image_box : cx(styles.image_box, styles.empty_box)}>
                <Row className='p-2' gutter={24}>
                    <Col xs={24} sm={24} md={14} lg={12}>
                        <Form.Item
                            initialValue={item ? item?.description : ''}
                            rules={[{
                                required: !urlFile[`${id}-image`],
                                message: "Please input feild!"
                            }]}
                            label={`Description`}
                            name={`${id}-description`}
                        >
                            <Input placeholder='Description' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4}>
                        <Form.Item
                            initialValue={item ? item?.status === 1 : false}
                            label={`Status`}
                            name={`${id}-status`}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col className='p-2 ms-3' md={4} lg={4} span={24}>
                        {
                            urlFile[`${id}-image`] &&
                            <div className={styles?.view_box}>
                                <div>
                                    <img
                                        src={urlFile[`${id}-image`]}
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                                <div>
                                    <div className={styles.file_actions}>
                                        <Button onClick={() => removeEl(`${id}-image`)} type='link' className='p-1 m-1' danger>
                                            <TbFileOff />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        <div style={{ position: "relative" }}>
                            <Form.Item
                                rules={[{
                                    required: !urlFile[`${id}-image`],
                                    message: "Please input feild!"
                                }]}
                                name={`${id}-file_image`}>
                                <Upload fileList={[]} customRequest={({ onSuccess: onSuccess }: any) => {
                                    onSuccess("ok")
                                }} accept={accept} onChange={(e: any) => {
                                    if (e?.file?.size < maxSize)
                                        setFileUrl({
                                            ...urlFile,
                                            [`${id}-image`]: window.URL.createObjectURL(e?.file?.originFileObj)
                                        })
                                    else message.error(`${typeFile} size not more then ${maxSize / 1024 / 1024}MB!`)
                                }} style={{ height: "50px", width: "100%" }}>
                                    <p className={urlFile[`${id}-image`] ? "d-none" : ""}>
                                        <img src={file_upload} alt="Ma'lumot topilmadi" />
                                    </p>
                                </Upload>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    } else if (typeFile === "VIDEO") {
        return (
            <div className={urlFile[`${id}-video`] ? undefined : cx(styles.video_box, styles.empty_box)}>
                <Row className='p-2' gutter={24}>
                    <Col xs={24} sm={24} md={18} lg={12}>
                        <Form.Item
                            initialValue={item ? item?.description : ''}
                            rules={[{
                                required: true,
                                message: "Please input feild!"
                            }]}
                            label={`Description`}
                            name={`${id}-description`}
                        >
                            <Input placeholder='Description' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4}>
                        <Form.Item
                            initialValue={item ? item?.status === 1 : false}
                            label={`Status`}
                            name={`${id}-status`}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col className='p-2 ms-3' md={4} lg={4} span={24}>
                        {
                            urlFile[`${id}-video`] &&
                            <div>
                                <div>
                                    <video
                                        src={urlFile[`${id}-video`]}
                                        controls={true}
                                        autoPlay={false}
                                        width="250px"
                                        height="250px"
                                    />
                                </div>
                                <div>
                                    <div className={styles.file_actions}>
                                        <Button onClick={() => removeEl(`${id}-video`)} type='link' className='p-1 m-1' danger>
                                            <TbFileOff />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        <div style={{ position: "relative" }}>
                            <Form.Item
                                rules={[{
                                    required: !urlFile[`${id}-video`],
                                    message: "Please select file!"
                                }]}
                                name={`${id}-file_video`}>
                                <Upload fileList={[]} customRequest={({ onSuccess: onSuccess }: any) => {
                                    onSuccess("ok")
                                }} accept={accept} onChange={(e: any) => {
                                    if (e?.file?.size < maxSize)
                                        setFileUrl({
                                            ...urlFile,
                                            [`${id}-video`]: window.URL.createObjectURL(e?.file?.originFileObj)
                                        })
                                    else message.error(`${typeFile} size not more then ${maxSize / 1024 / 1024}MB!`)
                                }} style={{ height: "50px", width: "100%" }}>
                                    <p className={urlFile[`${id}-video`] ? "d-none" : ""}>
                                        <img src={file_upload} alt="Ma'lumot topilmadi" />
                                    </p>
                                </Upload>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    } else if (typeFile === 'AUDIO') {
        return (
            <div className={styles.audio_box}>
                <Row className='p-2' gutter={24}>
                    <Col xs={24} sm={24} md={18} lg={12}>
                        <Form.Item
                            initialValue={item ? item?.description : ''}
                            rules={[{
                                required: true,
                                message: "Please input feild!"
                            }]}
                            label={`Description`}
                            name={`${id}-description`}
                        >
                            <Input placeholder='Description' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4}>
                        <Form.Item
                            initialValue={item ? item?.status === 1 : false}
                            label={`Status`}
                            name={`${id}-status`}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col className='p-2 ms-3' md={4} lg={4} span={24}>
                        {
                            urlFile[`${id}-audio`] &&
                            <div className={styles?.view_box}>
                                <div>
                                    <audio
                                        src={urlFile[`${id}-audio`]}
                                        controls={true}
                                        autoPlay={false}
                                    />
                                </div>
                                <div>
                                    <div className={styles.file_actions}>
                                        <Button onClick={() => removeEl(`${id}-audio`)} type='link' className='p-1 m-1' danger>
                                            <TbFileOff />
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        }
                        <div style={{ position: "relative" }}>
                            <Form.Item
                                rules={[{
                                    required: !urlFile[`${id}-audio`],
                                    message: "Please select file!"
                                }]}
                                name={`${id}-file_audio`}>
                                <Upload fileList={[]} customRequest={({ onSuccess: onSuccess }: any) => {
                                    onSuccess("ok")
                                }} accept={accept} onChange={(e: any) => {
                                    if (e?.file?.size < maxSize)
                                        setFileUrl({
                                            ...urlFile,
                                            [`${id}-audio`]: window.URL.createObjectURL(e?.file?.originFileObj)
                                        })
                                    else message.error(`${typeFile} size not more then ${maxSize / 1024 / 1024}MB!`)
                                }} style={{ height: "50px", width: "100%" }}>
                                    <p className={urlFile[`${id}-audio`] ? "d-none" : ""}>
                                        <img src={file_upload} alt="Ma'lumot topilmadi" />
                                    </p>
                                </Upload>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    } else if (typeFile === 'FILE') {
        return (
            <div>
                <div className={urlFile ? styles.file_box : cx(styles.file_box, styles.empty_box)}>
                    <Row className='p-2' gutter={24}>
                        <Col xs={24} sm={24} md={18} lg={12}>
                            <Form.Item
                                initialValue={item ? item?.description : ''}
                                rules={[{
                                    required: true,
                                    message: "Please input feild!"
                                }]}
                                label={`Description`}
                                name={`${id}-description`}
                            >
                                <Input placeholder='Description' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={4}>
                            <Form.Item
                                initialValue={item ? item?.status === 1 : false}
                                label={`Status`}
                                name={`${id}-status`}
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col className='p-2 ms-3' md={4} lg={4} span={24}>
                            {
                                urlFile[`${id}-file`] &&
                                <div className={styles?.view_box}>
                                    <div>
                                        {fileFile[`${id}-file`] === "pdf"
                                            ?
                                            <iframe src={urlFile[`${id}-file`]} />
                                            :
                                            <img style={{ cursor: "pointer" }} width="250px" onClick={() => {
                                                let a = document.createElement('a');
                                                a.href = urlFile[`${id}-file`];
                                                a.download = 'File.' + fileFile[`${id}-file`]
                                                a.click();
                                                a.remove()
                                            }} src={getImgThumb(fileFile[`${id}-file`])} />
                                        }
                                    </div>
                                    <div>
                                        <div className={styles.file_actions}>
                                            <Button onClick={() => removeEl(`${id}-file`)} type='link' className='p-1 m-1' danger>
                                                <TbFileOff />
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            }
                            <div className={styles?.upload_box} style={{ position: "relative" }}>
                                <Form.Item
                                    rules={[{
                                        required: !urlFile[`${id}-file`],
                                        message: "Please select file!"
                                    }]}
                                    name={`${id}-file_file`}>
                                    <Upload fileList={[]} customRequest={({ onSuccess: onSuccess }: any) => {
                                        onSuccess("ok")
                                    }} accept={accept} onChange={(e: any) => {
                                        let name: Array<string> = e?.file?.name?.split(".")
                                        if (e?.file?.size < maxSize) {
                                            setFileUrl({
                                                ...urlFile,
                                                [`${id}-file`]: window.URL.createObjectURL(e?.file?.originFileObj)
                                            })
                                            setFileFile({
                                                ...fileFile,
                                                [`${id}-file`]: name[name?.length - 1]
                                            })
                                        } else message.error(`${typeFile} size not more then ${maxSize / 1024 / 1024}MB!`)
                                    }} style={{ height: "50px", width: "100%" }}>
                                        <p className={urlFile[`${id}-file`] ? "d-none" : ""}>
                                            <img style={{ height: "250px", width: "250px" }} src={file_upload} alt="Ma'lumot topilmadi" />
                                        </p>
                                    </Upload>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
        )
    }

    return (
        <div>here nothing</div>
    )


}
