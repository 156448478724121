import * as React from 'react';
import Content from '../dashboard/common/Content';
import Header from '../dashboard/common/Header';
import RightBar from '../dashboard/common/Rightbar';
import DefaultSidebar from '../dashboard/vertical/default';
import LayoutNew from '../dashboardNew/vertical';


const Layout: React.FC<{children:any}> = ({ children }): JSX.Element => {

    return (
        <>
            <LayoutNew children={children} />
            {/* <DefaultSidebar />
            <Header />
            <RightBar />
            <Content>
                {children}
            </Content> */}
        </>
    )
}

export default Layout;