import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Input, InputNumber, Tag, Divider, Upload, message, Form, Collapse, Empty } from 'antd';
import { useHistory, useRouteMatch, match } from 'react-router';
import { useTranslation } from 'react-i18next';
import { BiQuestionMark, BiCheck, BiChevronDown, BiArrowToBottom, BiArrowToTop } from 'react-icons/bi';
import useGetAllData from 'hooks/useGetAllData';
import { FaArrowLeft } from 'react-icons/fa';
import { CaretRightOutlined } from '@ant-design/icons';
import "../styles.scss";
import useGetOneData from 'hooks/useGetOneData';
import instance from 'config/_axios';
import { findElementById, getTextWithoutAnyStyles } from 'utils/auxiliary_functions';
import { asyncN } from 'utils/notifications';
import { FILE_URL } from 'config/utils';


const { TextArea } = Input;
const { Panel } = Collapse;

const ExamAnswerPage: FC = (): JSX.Element => {

    const match: match<any> | null = useRouteMatch("/student-exam-questions/:exam_id/answer-page/:answer_id");
    const history = useHistory();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    if (!match) {
        history.goBack();
    }

    const [refetch, setRefetch] = useState<boolean>(false);
    const [answerId, setAnswerId] = useState<number | undefined>();
    const [fullViewQuestion, setFullViewQuestion] = useState<boolean>(false);
    const [turnitinFile, setTurnitinFile] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<{ download: boolean, upload: boolean, submit: boolean }>({ download: false, upload: false, submit: false });
    const [plagiat_percent, setPlagiatPercent] = useState<string>('');

    const _exam = useGetAllData({ url: `/exam-checkings`, params: { exam_student_id: match?.params?.answer_id }, isCall: 'auto' });
    const _question = useGetOneData({ url: `/exam-checkings/${answerId}?expand=questionOnly,subQuestions,examStudentAnswerSubQuestion`, params: { exam_student_id: match?.params?.answer_id } });
    const files_s = useGetOneData({ url: `/exam-students/${match?.params?.answer_id}`, isCall: 'auto', refetch: [refetch] });


    useEffect(() => {
        if (Object.getOwnPropertyNames(files_s.data).length) {
            setPlagiatPercent(files_s.data?.plagiat_percent);
            if (files_s.data?.plagiat_file) {
                setTurnitinFile([{
                    file: null,
                    name: "link",
                    status: "done",
                    uid: "-1",
                    url: FILE_URL + files_s.data?.plagiat_file
                }])
            }
        }
    }, [files_s.loading])

    useEffect(() => {
        if (_exam.items.length) {
            setAnswerId(_exam.items[0]?.id)
        }
    }, [_exam.items.length])

    useEffect(() => {
        if (answerId) {
            _question.fetch(`/exam-checkings/${answerId}?expand=isChecked,isCheckedFull,questionOnly,subQuestions,examStudentAnswerSubQuestion,exam,exam.examType,exam.subjectName`);
        }
    }, [answerId])

    useEffect(() => {
        if (_question.data?.subQuestions?.length) {
            _question.data?.subQuestions.forEach((element: any) => {
                form.setFieldsValue({
                    [`${element?.id}_ball`]: _getElementById(element.id)?.ball,
                    [`${element?.id}_teacher_answer`]: _getElementById(element.id)?.teacher_conclusion
                });
            })
        }
        form.setFieldsValue({ teacher_conclusion: _question.data?.teacher_conclusion })
    }, [_question.data])


    const iframe_ref = useRef<HTMLIFrameElement | null>(null);

    const formError = (name: string) => {
        form.setFields([
            {
                name,
                errors: [name.endsWith('ball') ? "Noto'g'ri qiymat !" : "Izoh kiriting!"]
            }
        ])
    }

    const getAnswersAndGeneratePdf = async () => {
        try {
            setLoading(prevState => ({ ...prevState, download: true }))
            const response = await instance({ url: '/exam-checkings?expand=examStudentAnswerSubQuestion,questionOnly', params: { exam_student_id: match?.params?.answer_id } });
            const { data, status } = response.data;
            if (status === 1) {
                if (data.items?.length) {
                    if (iframe_ref?.current) {
                        const _iframe = iframe_ref.current;
                        _iframe.contentDocument?.open()
                        const text = `<div>${data.items.map((element: any, index: number) => {
                            return (
                                `<div key={${index}} class="jjj">
                                    ${element?.examStudentAnswerSubQuestion?.map((e: any, i: number) => `<p key={${i}} style='text-align:justify; font-size: 18px;'>${e?.answer}</p>`).join('')}
                                </div>`
                            )
                        }).join('')
                            }
                            </div>`
                        _iframe.contentDocument?.write(text);
                        _iframe.contentDocument?.close();
                        window.document.title = `${_question.data?.student_id}-${files_s?.data?.id}-javob. ${_question.data?.exam?.subjectName} | ${_question?.data?.exam?.name} (${_question?.data?.exam?.examType?.name}`
                        _iframe.focus();
                        _iframe.contentWindow?.print()
                        window.document.title = "TSUL"


                    }
                }
            }
            setLoading(prevState => ({ ...prevState, download: false }))
        } catch (error) {
            setLoading(prevState => ({ ...prevState, download: false }))
        }
    }

    const _getElementById = (id: number) => findElementById({ arr: _question?.data?.examStudentAnswerSubQuestion, id, by_field: 'sub_question_id' });



    const submitSingle = async (id: number) => {
        try {
            const elementFilteredByID = findElementById({ arr: _question?.data?.examStudentAnswerSubQuestion, id, by_field: 'sub_question_id' });

            if (Object.getOwnPropertyNames(elementFilteredByID).length) {
                const { percent } = findElementById({ arr: _question?.data?.subQuestions, id, by_field: 'id' });
                if (percent && Number(form.getFieldValue(`${id}_ball`)) <= (_question?.data?.max_ball * (percent / 100))) {
                    const formdata = new FormData();

                    const teacher_conclusion = form.getFieldValue(`${id}_teacher_answer`);
                    const ball = form.getFieldValue(`${id}_ball`);

                    if (teacher_conclusion && (ball || Number(ball) === 0)) {

                        const answer = {
                            exam_student_answer_sub_question_id: elementFilteredByID?.id,
                            teacher_conclusion,
                            ball
                        };
                        formdata.append('subQuestionAnswersChecking', JSON.stringify([answer]));

                        const response = await instance({ url: `/exam-checkings/${_question.data.id}`, method: 'PUT', data: formdata });
                        if (response.data.status === 1) {
                            asyncN("success", 'create', response.data?.message)
                        }
                    } else {
                        if (!ball && Number(ball) !== 0) {
                            formError(`${id}_ball`)
                        } else {
                            formError(`${id}_teacher_answer`)
                        }
                    }

                } else {
                    formError(`${id}_ball`)
                }
            } else {
                message.warning("Ushbu savolga javob yozilmagan !");
            }
        } catch (error) {

        }
    }

    const submitMultiple = async () => {
        try {

            const values = form.getFieldsValue();

            const _fetch = async () => {
                const formdata = new FormData();
                let _answers: any = [];

                _question.data?.subQuestions.forEach((element: any) => {
                    if (values[`${element.id}_teacher_answer`] && values[`${element.id}_ball`]) {
                        _answers.push({
                            exam_student_answer_sub_question_id: _getElementById(element?.id)?.id,
                            teacher_conclusion: values[`${element.id}_teacher_answer`],
                            ball: values[`${element.id}_ball`]
                        })
                    }
                });

                const teacher_conculusion = form.getFieldValue('teacher_conclusion');

                if (teacher_conculusion || _answers.length) {
                    if (_answers.length) {
                        formdata.append('subQuestionAnswersChecking', JSON.stringify(_answers));
                    }
                    if (teacher_conculusion) {
                        formdata.append("teacher_conclusion", teacher_conculusion)
                    }

                    // const response = await instance({ url: `/exam-checkings/${_question.data.id}`, method: 'PUT', data: formdata });
                    // if (response.data.status === 1) {
                    //     asyncN("success", 'create', response.data?.message)
                    // }
                } else {
                    message.warning("Yetarlicha ma'lumot kiritilmadi !")
                }
            }

            form.validateFields().then((response) => {

                _fetch();

            }).catch((error) => { message.error("Something went wrong!") })


        } catch (error) {

        }
    }

    const submitTeacherConclusion = async () => {
        try {

            const formdata = new FormData();

            const teacher_conculusion = form.getFieldValue('teacher_conclusion');

            if (teacher_conculusion) {

                formdata.append("teacher_conclusion", teacher_conculusion);

                const response = await instance({ url: `/exam-checkings/${_question.data.id}`, method: 'PUT', data: formdata });
                if (response.data.status === 1) {
                    asyncN("success", 'create', response.data?.message)
                }
            } else {
                message.warning("Savollar bo'yicha teksiruv xulosasi kiritilmagan !")
            }

        } catch (error) {

        }
    }

    // const submit

    const handleChangeFile = (name: string, { fileList: file }: any) => {

        if (Array.isArray(file) && file.length) {

            if (file[0]?.size && file[0]?.size < 5242880) {
                setTurnitinFile([{
                    uid: "-1",
                    name: file[0]?.originFileObj?.name,
                    status: "done",
                    url: window.URL.createObjectURL(file[0]?.originFileObj),
                    file: file[0]?.originFileObj,
                }]);
            } else {
                message.error("Fayl hajmi 5Mb dan oshmasligi kerak !");
            }
        }
    }


    const submitTurnitinFile = async () => {
        try {
            if (plagiat_percent && Number(plagiat_percent) <= 100 && Number(plagiat_percent) >= 0) {
                const formdata = new FormData();
                formdata.append("plagiatFile", turnitinFile[0]?.file)
                formdata.append("plagiat_percent", plagiat_percent);
                const response = await instance({ url: `/exam-students/${match?.params?.answer_id}`, method: 'PUT', data: formdata })
                if (response.data.status === 1) {
                    asyncN("success", 'create', response.data?.message);
                    setRefetch(!refetch);
                }
            } else {
                message.warning("Plagiat foizida xatolik !");
            }
        } catch (error) {

        }
    }

    return (
        <div className='mb-3'>
            <iframe ref={iframe_ref} style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                    <h6> <FaArrowLeft className='me-2' onClick={() => history.goBack()} /> {_question.data?.exam?.subjectName} | {_question?.data?.exam?.name} ({_question?.data?.exam?.examType?.name}) | {files_s.data?.student_id}-{files_s.data?.id} - javob</h6>
                </div>
            </div>
            <Row gutter={[12, 12]}>
                <Col xl={8}>
                    <Button
                        icon={<BiArrowToBottom size={18} className="me-2" />}
                        style={{ width: "auto", color: "#1890FF" }}
                        onClick={getAnswersAndGeneratePdf}
                        className="w-100"
                        loading={loading.download}
                    >
                        Javoblarni yuklab olish
                    </Button>
                </Col>
                <Col xl={4}>
                    <Input.Group>
                        <Input style={{ width: 'calc(100% - 40px)' }} type="number" value={plagiat_percent} onChange={(e: ChangeEvent<HTMLInputElement>) => { if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) { setPlagiatPercent(e.target.value) } else { message.warning("Noto'g'ri qiymat!") } }} />
                        <Button style={{ width: '40px' }}>%</Button>
                    </Input.Group>
                </Col>
                <Col xl={8}>
                    <Upload
                        customRequest={({ file, onSuccess }: any) => { onSuccess('ok') }}
                        fileList={turnitinFile}
                        accept={"application/pdf,.png,.doc"}
                        onChange={(e) => { handleChangeFile("doc", e) }}
                        onRemove={() => setTurnitinFile([])}
                        className='w-100'
                    >
                        <Button icon={<BiArrowToTop size={18} className="me-2" />} style={{ width: "auto", color: "#1890FF" }} className="w-100" loading={loading.upload}  >Turnitin faylini yuklash</Button>
                    </Upload>
                </Col>
                <Col xl={4} className="text-end">
                    <Button type="primary" htmlType='submit' disabled={!turnitinFile?.length} block onClick={submitTurnitinFile}>Saqlash</Button>
                </Col>
            </Row>
            <Divider className='my-2' />
            <div className='d-flex justify-content-center my-2'>
                {
                    _exam.items.length && _exam.items.map((e, i) => {
                        return <div className="ant-col"><Button key={e.id} type={answerId === e.id ? 'primary' : 'dashed'} size="small" onClick={() => setAnswerId(e.id)} style={{ textTransform: 'lowercase', minWidth: "150px" }} className="mx-2" >{i + 1} - {e?.question_type}</Button></div>
                    })
                }
            </div>
            {_question.loading ? <div className="loader-line"></div> : <div style={{ height: '3px' }}></div>}
            <Row gutter={[12, 12]} className="card pb-4" style={{ backgroundColor: 'white' }}>
                <Col xl={24} className="pt-3 px-4">
                    <div className='d-flex justify-content-between'>
                        <span onClick={() => setFullViewQuestion(!fullViewQuestion)} style={{ color: '#096DE0', fontSize: '14px' }} className="cursor-pointer">{_question?.data?.question_type}</span>
                        <span style={{ color: '#096DE0', fontSize: '14px' }}>Max ball: <strong>{_question?.data?.max_ball}</strong></span>
                    </div>
                    <Divider className='my-2' />
                    <div onClick={() => setFullViewQuestion(!fullViewQuestion)} className={fullViewQuestion ? 'question_text_area_full' : 'question_text_area'}>
                        {getTextWithoutAnyStyles(_question.data?.questionOnly?.question)}
                    </div>
                </Col>
                <Col xl={24} className="py-3">
                    <Form
                        form={form}
                        layout="vertical"
                    // className={!files_s.data?.plagiat_percent && files_s.data?.plagiat_percent !== 0 ? 'disable_exam' : ''}
                    >
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['0']}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            className="site-collapse-custom-collapse"
                        >
                            {
                                _question.data?.subQuestions?.length ? _question.data?.subQuestions?.map((element: any, index: number) => {
                                    return (
                                        <Panel
                                            key={index}
                                            header={
                                                <div  className='d-flex align-items-center justify-content-center'>
                                                    <span className='me-2'><strong>{index + 1}-savol. </strong>{getTextWithoutAnyStyles(element?.question)}</span>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                    {
                                                        _getElementById(element.id)?.teacher_conclusion && _getElementById(element.id)?.ball !== null ?
                                                            <Tag color="#E6F4EA" className="cursor-pointer rounded pb-1" ><span style={{ color: "#137333" }}> {_getElementById(element.id)?.ball} </span> <BiCheck size={15} color="#137333" /> Baholangan</Tag> :
                                                            <Tag color="#FFFBE6" className="cursor-pointer rounded py-1" ><BiQuestionMark size={18} color="#FAAD14" /> Baholash zarur</Tag>
                                                    }
                                                    </div>
                                                </div>
                                            }
                                            className="site-collapse-custom-panel"
                                        >
                                            {
                                                _getElementById(element.id)?.answer ?
                                                    <Row gutter={[12, 12]}>
                                                        <Col xl={16} sm={16} md={24} xs={24} className='border py-3 px-2' >
                                                            <span className='question_part_check' dangerouslySetInnerHTML={{ __html: _getElementById(element.id)?.answer }} ></span>
                                                        </Col>
                                                        <Col xl={8} lg={24} md={24} sm={24} xs={24} style={{ display: 'flex' }} className="">
                                                            <Row gutter={[12, 12]} style={{ alignSelf: 'flex-end' }} className="w-100">
                                                                <Col xl={24} md={24} lg={24} sm={24} xs={24}>
                                                                    <Form.Item
                                                                        name={`${element?.id}_teacher_answer`}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t("Fill in the field!")
                                                                            }
                                                                        ]}
                                                                        className="w-100"
                                                                    >
                                                                        <TextArea rows={6} placeholder="Xulosa matnini kiriting ..." />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                                    <Tag color="#E6F7FF" className='exam_max_score_checking d-flex align-items-center justify-content-center'>{t('Max ball')}: <strong>{_question?.data?.max_ball * (element?.percent / 100)}</strong></Tag>
                                                                </Col>
                                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                                    <Form.Item
                                                                        name={`${element?.id}_ball`}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Ball kiritilmagan',
                                                                            }
                                                                        ]}
                                                                        
                                                                        className="p-0 m-0 w-100"
                                                                    >
                                                                        <InputNumber placeholder='Ballni kiriting' min={0} type={'number'} className="d-block w-100" onWheel={(e) => e.currentTarget.blur()} onBlur={(e) => {
                                                                            if (Number(e.target.value) > (_question?.data?.max_ball * (element?.percent / 100))) {
                                                                                formError(`${element?.id}_ball`)
                                                                            }
                                                                        }} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                                    <Button type="primary" block onClick={() => { submitSingle(element?.id) }}>Saqlash</Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row> :
                                                    <>
                                                        <Divider className='mt-0' />
                                                        <Empty description="No answer" />
                                                    </>
                                            }
                                        </Panel>
                                    )
                                }) : null
                            }
                            {
                                _question.data?.answer && <div className='card_shadow rounded my-3 py-3 px-3'>
                                    <span className='question_part_check' dangerouslySetInnerHTML={{ __html: _question.data?.answer }} ></span>
                                </div>
                            }
                            <div className='card_shadow rounded mb-3 p-3'>
                                <Row gutter={[12, 12]}>
                                    <Col xl={24} >
                                        <span>{_question.data?.question_type} bo'yicha umumiy xulosa</span>
                                    </Col>
                                    <Col xl={16}>
                                        <Form.Item
                                            name="teacher_conclusion"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t("Fill in the field!")
                                                }
                                            ]}
                                        >
                                            <TextArea rows={3} placeholder="Xulosa matni ..." />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={8}>
                                        <Button type="primary" block onClick={submitTeacherConclusion} >Xulosa matnini saqlash</Button>
                                        <Button type="primary" block className="mt-2" onClick={submitMultiple}>Barchasini saqlash</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default ExamAnswerPage;
