import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom"

export const useQueryParams = () => {
    const locationSearch = useLocation()?.search;
    const [params, setParams] = useState<any>({});

    useEffect(() => {
        if (locationSearch && locationSearch?.startsWith("?")) {
            let res: any = {}
            let paramsSplit: Array<string> = locationSearch.substring(1, locationSearch?.length)?.split("&");
            paramsSplit?.forEach((item: string) => {
                if (item?.includes("=")) {
                    let a = item?.split("=");
                    if (a.length > 1) {
                        res = { ...res, [`${a[0]}`]: a[1] }
                    }
                }
            })
            setParams(res)
        } else {
            setParams({})
        }
    }, [locationSearch])
    return params;
}