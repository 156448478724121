import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { BiCopyAlt, BiText, BiTrash, BiEditAlt } from 'react-icons/bi';
import { getColor } from "pages/message/utils";
import { MessageItemUi } from "./styled";
import moment from "moment";
import { message, Button } from "antd";
import { getOneMessage } from "pages/message/service";
import instance from "config/_axios";
import { DataInitialState } from "store/data";
import { getNotifications } from "services";


type PropsTypeMessageItem = {
    element: any,
    isType: "1" | "0",
    _deleteMessage: (id: number) => void,
    update: ({ id, value, roles }: { id: number, value: string, roles: Array<{ role: string }> }) => void,
    _state?: 'read' | 'unread' | undefined,
    setText?: (text: string) => void,
    isDelete?: boolean
}


const MessageItem: FC<PropsTypeMessageItem> = ({ element, _deleteMessage, update, isType, _state, setText, isDelete }): JSX.Element => {
    const auth: any = useSelector<RootState>(state => state.auth);

    const _getOneMessage = async (id: number) => {
        if (!id) {
            message.warning("Id ma'lumoti mavjud emas !");
            return;
        }

        const response = await getOneMessage(id, "expand=roles");
        if (response) {
            update({ id: response?.id, value: response?.name, roles: response?.roles })
        }
    }


    const _confirmMessage = async (id: number, status: number | string) => {
        try {
            if (!id) {
                message.warning("Ma'lumotni tasdiqlash uchun id topilmadi !");
                return;
            }
            const formdata = new FormData();
            formdata.append("status", String(status));
            const response = await instance({ url: `/notifications/approved/${id}`, method: "PUT", data: formdata });
            if (response.data?.status === 1) {
                message.success(response.data?.message);
                getNotifications();
            }
        } catch (error) {

        }
    }


    return (
        <MessageItemUi me={auth?.user?.role === element.role} color={getColor(element.status).color}>
            <div>
                <span><div></div>{auth?.user?.role.includes(element.name) ? element.name : 'Men'}<span>( {moment.unix(element?.created_at).format("DD-MM-YYYY")} ) </span></span>
                {isType === "0" ?
                    _state && _state === "unread" ? <span>
                        <Button type="primary" size="small" className="me-2" style={{ fontSize: "10px" }} onClick={() => _confirmMessage(element.id, 2)}>Accept</Button>
                        { !isDelete && <Button type="primary" danger size="small" style={{ fontSize: "8px" }}>Cancel</Button>}
                    </span> : null :
                    <span>
                        <BiEditAlt onClick={() => { _getOneMessage(element.id) }} />&nbsp;&nbsp;<BiCopyAlt />
                        &nbsp;&nbsp;<BiTrash color="#fa541c" onClick={() => _deleteMessage(element.id)} />
                    </span>
                }
            </div>
            <span onDoubleClick={() => { setText && setText(element.name)  }}>{element.name || element.notification}</span>
        </MessageItemUi>
    )

}

export default MessageItem;
