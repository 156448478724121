import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Form } from 'antd';
import CustomBreadcrumb from "components/Breadcrumb";
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import EduPlanUi from './ui';
import moment from 'moment';
import i18n from 'config/i18n';
import { useTranslation } from 'react-i18next';

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Education Plans",
        path: 'edu_plan_index'
    },
    {
        name: "Education plan create",
        path: 'edu-plan_create'
    },
]


const CreateEduPlan: FC = (): JSX.Element => {

    const {t} = useTranslation()

    const [form] = Form.useForm();
    const history = useHistory();
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true)
    const [dates, setDates] = useState<{ fall_start: any, fall_end: any, spring_start: null, spring_end: null }>({
        fall_start: null,
        fall_end: null,
        spring_start: null,
        spring_end: null
    })





    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            Object.entries(values).forEach(([key, value]: any) => {
                if (key === 'status') {
                    formdata.append(key, status ? '1' : '0');
                } else if (['fall_start', 'fall_end', 'spring_start', 'spring_end'].includes(key)) {
                    formdata.append(key, moment(value).format("YYYY-MM-DD"));
                } else {
                    formdata.append(key, String(value));
                }
            });

            const response = await instance({ url: '/edu-plans', method: 'POST', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); history.goBack() })
            } else {
                asyncN("error", 'create', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    if(error?.response?.data?.errors[0].includes('This Edu Plan')){
                        form.setFields([{name: `name[${i18n.language}]`, errors: [t('Avvalroq band qilingan')]}])
                    }
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();


    return (
        <>
            <CustomBreadcrumb arr={_breadcrump} />
            <Form
                onFieldsChange={() => setClicked(false)}
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
                className="mt-4"
            >
                <EduPlanUi resetFields={resetFields} status={status} setStatus={setStatus} clicked={clicked} type="create" dates={dates} form={form} />
            </Form>
        </>
    )
}


export default CreateEduPlan;