import React from 'react';
import { Pagination, Tag } from 'antd';
import useUrlQueryParams from 'hooks/useUrlQueryParams';


type TypeCustomPaginationProps = {
    totalCount?: number | undefined,
    currentPage?: number | undefined,
    perPage?: number | undefined
}


const CustomPagination: React.FC<TypeCustomPaginationProps> = React.memo(({ totalCount = 0, currentPage = 1, perPage = 15 }): JSX.Element => {

    const { value, writeToUrl } = useUrlQueryParams({});


    if(!totalCount) return <></>

    return (
        <div className="table_bottom_footer">
            <div>
                <Tag color="#F2F2F2" className="text-dark m-0">Jami {totalCount} ta</Tag>
            </div>
            <Pagination
                total={totalCount}
                defaultPageSize={perPage}
                current={currentPage}
                onChange={(e) => { writeToUrl({ name: 'currentPage', value: e }) }}
                onShowSizeChange={(e, pageSize) => { writeToUrl({ name: 'perPage', value: pageSize }) }}
                showSizeChanger
                pageSizeOptions={['10', '15', '20', '30', '50', '100']}
            />
        </div>
    )
})



export default CustomPagination;