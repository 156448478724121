import { FC, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Form } from 'antd';
import CustomBreadcrumb from "components/Breadcrumb";
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import EduPlanUi from './ui';
import { getOneExam } from '../service';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Exams",
        path: 'exam_update'
    },
    {
        name: "Exam edit",
        path: 'exam_update'
    },
]



const EditExam: FC = (): JSX.Element => {

    const [form] = Form.useForm();
    const history = useHistory();
    const location: string = useLocation().pathname;
    let a: Array<string> = location.split("/")
    const { i18n, t } = useTranslation();
    const id = Number(a[a.length - 1])
    const [clicked, setClicked] = useState<boolean>(false)
    const [subjectId, setSubjectId] = useState<number>()
    const [loader, setLoader] = useState<boolean>(false)




    useEffect(() => {
        (
            async () => {
                try {
                    if (!id) {
                        asyncN('error', 'read', "Id ma'lumotini olishda xatolik !").then(() => history.goBack())
                    }
                    setLoader(true)
                    const response = await getOneExam(id, "expand=description")
                    let a = new Date();
                    a.setHours(0);
                    a.setMinutes(0);
                    a.setSeconds(0)
                    a.setTime(a.getTime() + response?.duration * 1000)
                    form.setFieldsValue({
                        [`name[${i18n.language}]`]: response?.name || undefined,
                        [`description[${i18n.language}]`]: response?.description || undefined,
                        max_ball: response?.max_ball || undefined,
                        min_ball: response?.min_ball || undefined,
                        exam_type_id: response?.exam_type_id || undefined,
                        edu_semestr_subject_id: response?.edu_semestr_subject_id || undefined,
                        start_exam: response?.start ? moment(response?.start) : undefined,
                        finish_exam: response?.finish ? moment(response?.finish) : undefined,
                        duration: response?.duration ? moment(a) : undefined,
                        status: response?.status,
                        status_appeal: response?.status_appeal,
                        is_protected: Boolean(Number(response?.is_protected))
                    })
                    setSubjectId(response?.edu_semestr_subject_id)
                    setLoader(false)
                } catch (error: any) {
                    setLoader(false)
                    asyncN("error", "read", error?.response?.message)
                }
            }
        )()
    }, [i18n.language])




    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            Object.entries(values).forEach(([key, value]: any) => {
                if (key === 'is_protected') {
                    formdata.append(key, value ? '1' : '0');
                } else if (key === "start_exam") {
                    formdata.append("start", moment(value).format("YYYY-MM-DD HH:mm:ss"))
                } else if (key === "finish_exam") {
                    formdata.append("finish", moment(value).format("YYYY-MM-DD HH:mm:ss"))
                } else if (["edu_semestr_id", "edu_plan_id"].includes(key)) {

                } else if (key === "duration") {
                    formdata.append("duration", moment(value).format("HH:mm"))
                } else if(key === "status_appeal"){
                    formdata.append('isConfirmedAppeal', String(value))
                    formdata.append(key, String(value))
                } else {
                    formdata.append(key, String(value));
                }
            });

            const response = await instance({ url: `/exams/${id}`, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); history.goBack() })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();


    return (
        <>
            <CustomBreadcrumb arr={_breadcrump} />
            {loader ? (
                <div className="loader-line"></div>
            ) : (
                <div style={{ height: "3px" }}></div>
            )}
            <Form 
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
                className="mt-4"
            >
                <EduPlanUi subject_id={subjectId} resetFields={resetFields} clicked={clicked} type="update" form={form} />
            </Form>
        </>
    )
}


export default EditExam;