import { FC } from "react";
import { BiSend } from 'react-icons/bi';
import { ItemUi } from "./styled";
import { getColor } from "pages/message/utils";




const UserItemOfMessage: FC<{ element:any, selectItem: (id: string) => void, active: boolean }> = ({ element, selectItem, active }): JSX.Element => {



    return (
        <ItemUi color={getColor(element.status).color} onClick={() => selectItem(element.name)} active={active}>
            {active ? <BiSend /> : null}
            <p>{element?.pretty_name}</p>
            <span><span className="message_status_icon"></span> &nbsp; {getColor(element.status).title}</span>
        </ItemUi>
    )
}


export default UserItemOfMessage;