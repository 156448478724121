import { FC, useEffect, useState } from "react";
import { Modal, Row, Col, Typography, Button } from "antd";
import moment from "moment";
import { BadgeStatusText } from "components/BadgeStatus";
import { useTranslation } from "react-i18next";
import { SimpleViewType } from "./types";
import useGetOneData from "hooks/useGetOneData";
import ModalTitle from "components/ModalTitle";

const { Text } = Typography;

const SimpleView: FC<SimpleViewType> = ({ visible, setVisible, url, title }): JSX.Element => {

    const { t } = useTranslation();
    const [showCreated, setShowCreated] = useState<boolean>(false);
    const {data, fetch} = useGetOneData({url});

    useEffect(() => {
        if(visible){
            fetch(url);
        }
    },[visible])

    return (
        <>
            {data && <Modal
                title={title}
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Row gutter={[12, 12]}>
                    <Col span={24} ><Text>Name: </Text>&nbsp;{data?.name}</Col>
                    <Col span={24} ><Text>Description: </Text>&nbsp;{data?.description}</Col>
                    <Col className="d-f justify-content-between" span={24}><span><Text>{t("Status")}: </Text><BadgeStatusText status={data?.status} /></span> <Button type="link" onClick={() => setShowCreated(!showCreated)} >details</Button> </Col>
                </Row>
                {data.createdBy && showCreated ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Created By")}</Text></Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("First Name")}: </Text>{data.createdBy.first_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data.createdBy.last_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Login")}: </Text>{data.createdBy.username}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data.createdBy.role}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Created at")}: </Text>{moment(data?.created_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}
                {data.updatedBy && showCreated ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Updated By")}</Text></Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("First Name")}: </Text>{data.updatedBy.first_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data.updatedBy.last_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Login")}: </Text>{data.updatedBy.username}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data.updatedBy.role}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Updated at")}: </Text>{moment(data?.updated_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}
            </Modal>}
        </>
    )
}


export default SimpleView;