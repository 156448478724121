import { message } from "antd";
import instance from "config/_axios";



export const getOneEduPlan = async (id: number | null, path_prefix?: string) => {
    try {
        if (!id) {
            return new Error("Id bo'yicha ma'lumot olinmadi !");
        }

        const response = await instance({ url: `/edu-plans/${id}${path_prefix ? '?' + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}





export const getAllEduPlans = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/edu-plans${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            if (Array.isArray(response.data?.data?.items) && response.data?.data?.items?.length === 0) {
                message.warning("Ma'lumot topilmadi !");
            }
            return response.data?.data;
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export const getAllEduSemestrs = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/edu-semestrs${path_prefix ? '?' + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response?.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}