import { useEffect, useState } from "react";
import { Col, message, Modal, Row, Tag, Tooltip, Typography } from "antd";
import { Table, Tbody, Td, Tr, Thead, Th } from 'react-super-responsive-table'
import isHasAuthRoute from "utils/isHasRoute";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import instance from "config/_axios";
import { useTranslation } from "react-i18next";

const ViewRole = (props: any) => {
    const { i18n, t } = useTranslation();
    const [role, setRole] = useState<any>();

    useEffect(() => {
        if (props.roleName) {
            (
                async () => {
                    try {
                        const response = await instance({
                            method: 'get',
                            url: '/roles/' + props.roleName + '/permissions?expand=permissions,parent,child'
                        });
                        if (response?.data?.status === 1) {
                            setRole(response.data.data);
                        }
                    } catch (e) {
                        message.error("Xatolik yuz berdi")
                    }
                }
            )();
        }
    }, [props]);

    return (
        <Modal
            footer={isHasAuthRoute('access-control_update-role') ? <button className="btn btn-none border-primary">
                <Tooltip placement="topLeft" title={t("Edit")}><Link className="text-decoration-none"
                    to={{ pathname: "/edit-role/" + role?.name }}>
                    <FaEdit className="text-primary" style={{
                        cursor: 'pointer',
                        display: isHasAuthRoute('access-control_update-role') ? '' : 'none'
                    }} />
                </Link></Tooltip>
            </button> : false}
            cancelText="Yopish" okText={
                <Tooltip placement="topLeft" title={t("Edit")}><Link
                    to={{ pathname: "/edit-role/" + role?.name }}>
                    <FaEdit className="text-primary" style={{
                        cursor: 'pointer',
                        display: isHasAuthRoute('access-control_update-role') ? '' : 'none'
                    }} />
                </Link></Tooltip>
            } okButtonProps={{
                style: {
                    backgroundColor: "white"
                }
            }} title={t("Info")} width={1200} visible={props?.visible} onCancel={() => props?.setVisible(false)}>
            <Row gutter={24}>
                <Col span={24}>
                    <Row gutter={24}>
                        <Col sm={24} xs={24} lg={12} md={12}><strong>{t("Role name")}: </strong><span>{role?.name}</span></Col>
                        <Col sm={24} xs={24} lg={12} md={12}><strong>{t("Description")}: </strong><span>{role?.pretty_name}</span></Col>
                        <Col className="mt-2 mb-2" sm={24} xs={24} lg={24} md={24}>
                            <strong className="me-2">
                                {t("Parents")}:
                            </strong>
                            <span>
                                {
                                    Array.isArray(role?.parent) && role?.parent?.map((item: { parent: string, child: string }) => <Tag>{item?.parent}</Tag>)
                                }
                            </span>
                        </Col>
                        <Col sm={24} xs={24} lg={24} md={24}>
                            <strong className="me-2">
                                {t("Children")}:
                            </strong>
                            <span>
                                {
                                    Array.isArray(role?.child) && role?.parent?.map((item: { parent: string, child: string }) => <Tag>{item?.child}</Tag>)
                                }
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col sm={24} xs={24} lg={24} md={24}><strong>{t("Permissions")}</strong></Col>
                <Col sm={24} xs={24} lg={24} md={24} style={{ maxHeight: "60vh", overflow: "auto" }}>
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th>№</Th>
                                <Th>{t("Name")}</Th>
                                <Th>{t("Description")}</Th>
                                <Th>{t("Category")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                role?.permissions && role.permissions.map((item: any, i: number) =>
                                    <Tr key={"" + i}>
                                        <Td>{i + 1}</Td>
                                        <Td>{item.name}</Td>
                                        <Td>{t(item.description)}</Td>
                                        <Td>{t(item.category)}</Td>
                                    </Tr>
                                )
                            }
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </Modal>
    )
};


export default ViewRole
