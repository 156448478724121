import { Row, Col, Divider } from 'antd'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function ForTitle({title, buttons}: any){
    const { i18n, t } = useTranslation();
    const [windowSize, setWindowSize] = useState<number>(0)

    useEffect(() => {
        setWindowSize(window.innerWidth)
    }, [window.innerWidth])

    return (
        <div className={`d-flex justify-content-between align-items-center  ${windowSize > 1180 ? '' : 'flex-wrap'}`}>
            <div className='w-100'>
                <Divider orientation="left">
                    <h5>{t(title)}</h5>
                </Divider>
            </div>
            <div className={`d-flex justify-content-end align-items-center ms-2 ant-col ${windowSize > 1180 ? '' : 'flex-wrap'}`}>
                {
                    buttons.length && buttons.map((item: any, i: number) => (
                        <div key={i}>
                            {item}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ForTitle