import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const Nationality: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="nationalities"
                indexTitle="Nationalities"
                editTitle="Edit nationality"
                viewTitle="View nationality"
                createTitle="Create nationality"
                isPagination={true}
                permissions={{
                    view_: "nationality_view",
                    delete_: "nationality_delete",
                    update_: "nationality_update",
                    create_: "nationality_create"
                }}
            />
        </div>
    )
}

export default Nationality;