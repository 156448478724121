import React from 'react';
import { useRouteMatch, match, useHistory } from 'react-router';
import useGetOneData from 'hooks/useGetOneData';
import { IExamSmeta } from 'models/exam';

type TypeExamStudentByLang = {
    count: string,
    lang_id: string
}

type TypeTeacherAccess = {
    id: number
    user_id: number
    subject_id: number | null
    language_id: number
    status: number
    teacher: { first_name: string, last_name: string, middle_name: string }
    created_at: number
    created_by: number
    updated_at: number | null
    updated_by: number | null
}

const useDefineCheckers = () => {

    const match: match<any> | null = useRouteMatch('/exams_for_checking/:subject_id/exam_distribution/:exam_id/:type_exam');
    const history = useHistory();

    const { subject_id, exam_id, type_exam } = match?.params;

    const exams = useGetOneData({ url: `/exams/${exam_id}?sort=-id&expand=subjectName,${type_exam === 'exam' ? 'examStudentByLang,examSmeta' : 'examAppealByLang,examAppealSemeta'},teacherAccess,&fields=teacherAccess&filter=${JSON.stringify({ subject_id })}`, isCall: 'auto' });

    React.useEffect(() => {
        if (!subject_id && !exam_id) { history.goBack(); }
    }, []);


    const returnValues = React.useMemo(() => {

        const newTeacherAcces: Record<number, { langs: number[], teacherAccessByLang: Record<number, string>, full_name: string }> = {};
        let newExamStudentByLang: Record<string, string> = {};

        const { examSmeta, examAppealSemeta, examStudentByLang, examAppealByLang, subjectName, teacherAccess, loading } = exams.data as { examSmeta: Array<IExamSmeta>, examAppealSemeta: Array<IExamSmeta>, examStudentByLang: TypeExamStudentByLang[], examAppealByLang: TypeExamStudentByLang[], subjectName: string, teacherAccess: TypeTeacherAccess[], loading: boolean }

        if (teacherAccess && teacherAccess.length) {

            teacherAccess.forEach(element => {

                const { id, language_id, user_id, teacher } = element;

                if (newTeacherAcces.hasOwnProperty(user_id)) {

                    newTeacherAcces[user_id].langs.push(language_id);
                    newTeacherAcces[user_id].teacherAccessByLang[language_id] = `${language_id}_${id}`

                } else {

                    newTeacherAcces[user_id] = {
                        full_name: `${teacher.last_name}  ${teacher.first_name}  ${teacher.middle_name}`,
                        langs: [language_id],
                        teacherAccessByLang: { [language_id]: `${language_id}_${id}` }
                    };

                }
            })

        };

        const examStudentByLangs = type_exam === 'exam' ? examStudentByLang : examAppealByLang;

        if (examStudentByLangs && examStudentByLangs.length) {

            examStudentByLangs.forEach((element) => {

                switch (element.lang_id) {
                    case '1':
                        newExamStudentByLang['uz'] = element.count;
                        newExamStudentByLang['1'] = element.count;
                        break;
                    case '2':
                        newExamStudentByLang['en'] = element.count;
                        newExamStudentByLang['2'] = element.count;
                        break;
                    case '3':
                        newExamStudentByLang['ru'] = element.count;
                        newExamStudentByLang['3'] = element.count;

                }

            })

        }

        let isConfirmedEduAdmin: boolean = false;
        let isConfirmedMudir: boolean = false;

        const semeta = type_exam === 'exam' ? examSmeta : examAppealSemeta;

        if (Array.isArray(semeta) && semeta.length) {

            isConfirmedEduAdmin = semeta.every(e => e.status === 2);

            isConfirmedMudir = semeta.every(e => e.status === 1);

        }

        return { newTeacherAcces, newExamStudentByLang, semeta: semeta ?? [], subjectName, isConfirmedEduAdmin, isConfirmedMudir, loading };

    }, [exams]);

    return returnValues;

}


export default useDefineCheckers;