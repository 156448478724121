import store from "store";
import { ThemeType } from "./type"


export let DefaultTheme: ThemeType = {
    name: 'default',
    sidebar: {
        sidebarBackgroundColor: '#1A233A',
        title: '#69c0ff',
        link: {
            bg: '#233354',
            line: '#29385C',
            icon: '#6682B8',
            text: '#8494A7',
            hover: {
                bg: '#233354',
                text: '#5A8DEE',
                icon: '#5A8DEE'
            },
            active: {
                bg: '#233354',
                text: '#5A8DEE',
                icon: '#A9C7FF'
            }
        },
        submenu: {
            bg: '#233354',
            link: {
                bg: '#1A233A',
                text: '#8494A7',
                icon: '#6682B8'
            },
            hover: {
                bg: '#233354',
                text: '#5A8DEE',
                icon: '#5A8DEE'
            },
            active: {
                bg: '#233354',
                text: '#5A8DEE',
                icon: '#A9C7FF'
            }
        },
    },
    header: {
        bg: '#1A233A',
        element_bg: '#233354',
        text: '#7A8A9D',
        icon: '#A9C7FF',
        ripple_hover: "linear-gradient(90deg, rgba(47,84,235,0.4) 0%, rgba(73,105,235,0.4) 47%)",
        input_bg: '#233354',
        hover_bg: '#233354',
    },
    content: {

    }
}
export let LightTheme: ThemeType = {
    name: 'light',
    sidebar: {
        sidebarBackgroundColor: '#FFFFFF',
        title: '',
        link: {
            bg: '#DBE5F3',
            line: '#bfbfbf',
            icon: '#5A8DEE',
            text: '#8494A7',
            hover: {
                bg: '#DBE5F3',
                text: '#5A8DEE',
                icon: ''
            },
            active: {
                bg: '#C3D7F3',
                text: '#5A8DEE',
                icon: '#0050b3'
            }
        },
        submenu: {
            bg: '#DBE5F3',
            link: {
                bg: '',
                icon: '#5A8DEE',
                text: '#8494A7',
            },
            hover: {
                bg: '#DBE5F3',
                text: '#5A8DEE',
                icon: ''
            },
            active: {
                bg: '#DBE5F3',
                text: '#5A8DEE',
                icon: '#0050b3'
            }
        },
    },
    header: {
        bg: '#FFFFFF',
        element_bg: '#C3D7F3',
        text: '#8999AB',
        icon: '#5A8DEE',
        ripple_hover: '#DBE5F3',
        input_bg: '#f5f5f5',
        hover_bg: '#f5f5f5',
    },
    content: {

    }
}
export let BlueTheme: ThemeType = {
    name: 'blue',
    sidebar: {
        sidebarBackgroundColor: '#10239E',
        title: '#69c0ff',
        link: {
            bg: '#1D39C4',
            line: '#3D54C4',
            icon: '#69c0ff',
            text: '#91d5ff',
            hover: {
                bg: '#1D39C4',
                text: '#91d5ff',
                icon: '#BBE3FF'
            },
            active: {
                bg: '#1D39C4',
                text: '#bae7ff',
                icon: '#bae7ff'
            }
        },
        submenu: {
            bg: '#1D39C4',
            link: {
                bg: '#1D39C4',
                icon: '#69c0ff',
                text: '#91d5ff',
            },
            hover: {
                bg: '#1D39C4',
                text: '#91d5ff',
                icon: '#bae7ff'
            },
            active: {
                bg: '#1D39C4',
                text: '#85A5FF',
                icon: '#e6f7ff'
            }
        },
    },
    header: {
        bg: '#10239E',
        element_bg: '#1D39C4',
        text: '#85A5FF',
        icon: '#BAE7FF',
        ripple_hover: '',
        input_bg: '#1D39C4',
        hover_bg: '#1D39C4',
    },
    content: {

    }
}


export const ActiveHeader = {
    bg: '#FFFFFF',
    element_bg: '#C3D7F3',
    text: '#8999AB',
    icon: '#5A8DEE',
    ripple_hover: '#DBE5F3',
    input_bg: '#f5f5f5',
    hover_bg: '#f5f5f5',
}





export const activeColor = () => {
    const ui = store.getState().ui;
    const header_active = ui.theme.header_status === 'active';
    switch (ui.theme.color) {
        case 'blue':
            if (header_active) {
                return BlueTheme = {
                    ...BlueTheme,
                    header: ActiveHeader
                }
            } else {
                return BlueTheme;
            }
        case 'light':
            if (header_active) {
                return LightTheme = {
                    ...LightTheme,
                    header: ActiveHeader
                }
            } else {
                return LightTheme;
            }
        default:
            if (header_active) {
                return DefaultTheme = {
                    ...DefaultTheme,
                    header: ActiveHeader
                }
            } else {
                return DefaultTheme;
            }
    }
};

