import { useHistory, useRouteMatch, match } from 'react-router';
import { Col, Divider, Input, Pagination, Row, Spin, Tag } from "antd";
import { BiCheck, BiQuestionMark, BiRightArrowAlt } from "react-icons/bi";
import { Card, CardBody } from "reactstrap";
import useGetAllData from "hooks/useGetAllData";
import useGetOneData from "hooks/useGetOneData";
import { useTranslation } from 'react-i18next';
import PageContentTitle from 'components/Title';
import { ChangeEvent } from 'react';
import isHasAuthRoute from 'utils/isHasRoute';
import { exam_student_status_list } from '../exam_status_list';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import FormLabel from 'components/FormLabel';
import { useAppSelector } from 'store/services';
import useDebounce from 'hooks/useDebounce';
import isRole from 'utils/isRole';
import ForTitle from 'components/ForTitles';
import CustomBreadcrumb from 'components/Breadcrumb';
import EmptyTable from 'components/EmptyTable';
import ForTable from 'components/ForTable';


/**
 Exam Student
Status List
    STATUS_INACTIVE = 0
    STATUS_TAKED = 1
    STATUS_COMPLETE = 2
    STATUS_IN_CHECKING = 3
    STATUS_CHECKED = 4
    STATUS_SHARED = 5
 */


/**
 exam-checking_create
 exam-checking_index
 exam-checking_update
 exam-checking_view
 */
const { Search } = Input;


export const encodeKey = (key: string): string => {
    if (Boolean(Number(key))) {
        let a: string = ""
        for (let i = 0; i < key?.length; i++) {
            a += String.fromCharCode(Number(key[i]) + 97)
        }
        return a
    } else {
        return "no encode";
    }
}

export const decodeKey = (key: string): string => {
    let a: string = ""
    for (let i = 0; i < key?.length; i++) {
        a += String(Number(key.charCodeAt(i) - 97));
    }

    return a;
}

const StudentPassedExams = () => {

    const match: match<any> | null = useRouteMatch("/student-exam-questions/:exam_id");
    const history = useHistory();
    const { t } = useTranslation();
    const userData: any = useAppSelector(state => state.auth);

    if (!match) {
        history.goBack();
    }

    const { value, writeToUrl } = useUrlQueryParams({ perPage: 100, currentPage: 1 });
    const student_id = useDebounce(value.filter_like?.id_student);

    const _exam = useGetOneData({ url: `/exams/${match?.params?.exam_id}?expand=subject&fields=id,name,subject.name`, isCall: 'auto' })
    const _questions = useGetAllData({ url: `/exam-students?expand=examStudentAnswers,isChecked,isCheckedFull,allBall,hasAnswer,examStudentAnswers.subQuestions${match?.params?.exam_id ? `&filter={"exam_id":${match?.params?.exam_id}}&filter-like=${JSON.stringify({ student_id: value?.filter_like?.id_student })}` : null}`, isCall: 'auto', perPage: value?.perPage, currentPage: value.currentPage, refetch: [student_id] });
    

    return (
        <div>
            <ForTitle title={_exam.data?.name} buttons={[null]}/>
            <CustomBreadcrumb arr={[
                {
                    name: _exam.data?.subject?.name,
                    path: `/exam_by_subject?subject_id=${_exam.data?.subject?.id}`
                }
            ]}/>
                {(userData.user.role as string[]).includes('admin') ? <Row gutter={[12, 12]} className="mb-3" >
                    <Col xl={6}>
                        <FormLabel>Talaba id ma'lumoti bo'yicha qidirish.</FormLabel>
                        <Input
                            placeholder={`${t("Input search text")} ...`}
                            value={value.filter_like?.id_student}
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => { writeToUrl({ name: 'id_student', value: e?.target?.value }) }}
                            className="w-100 ant_input_custom"
                        />
                    </Col>
                </Row> : null}
                <ForTable toUrl={true} setCurrentPage={() => {}} setPerPage={() => {}} currentPage={value.currentPage} perPage={value.perPage} 
                totalCount={_questions.data?._meta.totalCount} datas={_questions.items} loading={_questions.loading} theads={[
                    <>{t("Javob id")}</>,
                    <>{t("Javob holati")}</>,
                    <>{t("Plagiat foizi")}</>,
                    <>{t("Javob natijasi")}</>,
                ]} tbodys={function(this: any){
                    return [
                        <>
                            {
                                isRole('admin') ? 
                                this?.student_id : encodeKey(String(this.student_id))}-{isRole('admin') ? this?.id 
                                : 
                                encodeKey(String(this?.id))
                            } - javob
                        </>,
                        <>
                        {
                            Number(this?.isChecked) && this.examStudentAnswers.length ?
                                <Tag color={Number(this?.isCheckedFull) ? "#E6F4EA" : "#FFFBE6"} className="cursor-pointer rounded py-1" >
                                    <span style={{ color: "#137333" }}>
                                        {this.allBall}
                                        {
                                        Number(this?.isCheckedFull) ? 
                                        <><BiCheck size={18} color="#137333" /> Baholandi</>
                                        : 
                                        <><BiQuestionMark size={18} color="#FAAD14" /> Ko`rib chiqilmoqda</>
                                        }
                                    </span>
                                </Tag> 
                                    :
                                <Tag color="#FFFBE6" className="cursor-pointer rounded py-1" >
                                    <><BiQuestionMark size={18} color="#FAAD14" /> Ko`rib chiqilmoqda</>
                                </Tag>
                        }
                        </>,
                        <>
                        {
                            Number(this?.isChecked) && this?.plagiat_percent && Number(this?.plagiat_percent) !== 0 ?
                                <Tag color={Number(this?.isPlagiat) ? "#FCE8E6" : "#E8F0FE"} className="rounded text-center" style={Number(this?.isPlagiat) ? { color: '#A50E0E' } : { color: '#1967D2' }} >
                                    Plagiat: {this?.plagiat_percent}% 
                                </Tag> 
                                : 
                                <Tag color="#FFFBE6" className="cursor-pointer rounded py-1" >
                                    <><BiQuestionMark size={18} color="#FAAD14" /> Ko`rib chiqilmoqda</>
                                </Tag>
                        }
                        </>,
                        <>
                        {
                            isHasAuthRoute('exam-checking_create') || isHasAuthRoute("exam-checking_update") || isHasAuthRoute("exam-checking_index") || isHasAuthRoute("exam-checking_view") ?
                                this?.hasAnswer === 1 ? 
                                    <span onClick={() => { if (Number(this?.act) === 0) { history.push(`/student-exam-questions/${match?.params?.exam_id}/answer-page/${this?.id}`) } }} className="text-decoration-none" >
                                        {
                                            Number(this?.act) ? <Tag color="#f7edeb" className="cursor-pointer rounded min_w_150 text-center" style={{ color: '#f04022' }} >Chetlatilgan</Tag> :
                                            <Tag color="#E6F4EA" className="cursor-pointer rounded min_w_150 text-center" style={{ color: '#137333' }} >
                                            {
                                                Number(this?.isCheckedFull) ?
                                                <>Tekshirilgan<BiRightArrowAlt color='#137333' /></>
                                                :
                                                <>Tekshirish<BiRightArrowAlt color='rgb(19, 115, 51)' /></>
                                            }
                                            </Tag>
                                        }
                                    </span>
                                    :
                                    <Tag color="#FFFBE6" className="rounded min_w_150 text-center" style={{ color: '#FAAD14' }} >Javob yozilmagan</Tag>
                                    : exam_student_status_list(this?.status)
                        }
                        </>
                    ]
                }}/>
        </div >
    )

}



export default StudentPassedExams;
