import { Alert, Button, Divider } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

type TypeAppelationPopoverProps = {
    text: string,
    conclusion: string | null
}

const AppelationPopver: React.FC<TypeAppelationPopoverProps> = ({ text, conclusion }): JSX.Element => {


    const { t } = useTranslation();


    return (
        <div>
            <span className="d-block fs-14" style={{ textAlign: 'justify', color: '#373D3F', fontWeight: 400, lineHeight: '20px' }}>
                {text}
                {conclusion ? <Divider style={{ fontSize: '13px', color: '#737373' }}>Apelatsiya xulosasi</Divider> : null}
                <span>{conclusion ?? ''}</span>
            </span>
        </div>
    )

}




export default AppelationPopver;