import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spin, Tabs, Tag } from "antd";
import UserAvatar from "components/userAvatar/avatar";
import { useLocation, useHistory } from 'react-router-dom'
import CustomBreadcrumb from 'components/Breadcrumb';
import isHasAuthRoute from "utils/isHasRoute";
import { useTranslation } from 'react-i18next';
import { getUserPasswordById } from '../servise';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FILE_URL } from 'config/utils';
import styled from 'styled-components';
import useGetAllData from 'hooks/useGetAllData';
import useGetOneData from 'hooks/useGetOneData';
import { data } from 'pages/teacher_KPI/crud/json';

const { TabPane } = Tabs
const _breadcrump = [
    {
        name: "Home",
        path: "/",
    },
    {
        name: "Users",
        path: "users",
    },
    {
        name: "View user",
        path: "edit-user",
    },
];

const ViewUser = () => {

    const { t } = useTranslation();
    const [password, setPassword] = useState<string>();
    const [isPass, setIsPass] = useState<boolean>(false);
    const [userAccess, setUserAccess] = useState<any>();
    const history = useHistory();
    const state = useLocation<any>().pathname;
    let a: Array<string> = state?.split("/");
    let id = a[a?.length - 1];

    const _data = useGetOneData({ url: `users/${id}?expand=profile,user,department,createdBy,updatedBy,region,area,country,permanentArea,permanentCountry,permanentRegion`, isCall: 'auto' });
    const _userAccessTypes = useGetAllData({ url: '/user-access-types', isCall: 'auto' });


    useEffect(() => {
        if (_userAccessTypes.items?.length && Object.getOwnPropertyNames(_data.data)?.length) {
            const { department } = _data.data;
            let new_arr: any[] = [];
            if (department && Object.getOwnPropertyNames(department)?.length) {
                for (let i = 0; i < _userAccessTypes.items?.length; i++) {
                    const element = _userAccessTypes.items[i];
                    if (department.hasOwnProperty(element?.id) && Array.isArray(department[element?.id]) && department[element?.id]?.length) {
                        let arr: Array<string> = [];
                        department[element?.id]?.forEach((e: any) => arr.push(e?.name));
                        new_arr.push({
                            name: element?.name,
                            value: arr,
                        });
                    }
                }
                setUserAccess(new_arr);
            }
        }
    }, [_userAccessTypes.items.length, _data.data])

    const showPassword = async () => {
        if (!password) {
            const res: any = await getUserPasswordById(Number(id));
            setPassword(res?.data?.password);
            setIsPass(true);
        }
    };

    return (
        <Wrapper style={{ height: "100%" }}>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <CustomBreadcrumb arr={_breadcrump} />
                </Col>
            </Row>
            <Spin spinning={_data.loading}>
                <Row style={{ height: "100%" }} className="mt-2 p-3 d-flex" gutter={[24, 24]}>
                    <Col className='rounded box-shadow' style={{ flex: 1 }} xs={24} sm={24} md={8} lg={8}>
                        <Row className={"pt-3 pb-3 d-flex justify-content-center align-items-center"} gutter={24}>
                            <UserAvatar size={150} text={_data.data?.first_name} avatar={_data.data?.avatar ? FILE_URL + _data.data?.avatar : ""} />
                        </Row>
                        <Row gutter={24}>
                            <Col className={"p-2"} xs={24} sm={24} md={24} lg={24}>
                                <Row gutter={24}>
                                    <Col span={12} className="d-flex justify-content-end">
                                        <strong className="mb-1">{t("Last name")}:</strong>
                                    </Col>
                                    <Col span={12}>
                                        {_data.data?.last_name}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className={"p-2"} xs={24} sm={24} md={24} lg={24}>
                                <Row gutter={24}>
                                    <Col span={12} className="d-flex justify-content-end">
                                        <strong>{t("First name")}:</strong>
                                    </Col>
                                    <Col span={12}>{_data.data?.first_name}</Col>
                                </Row>
                            </Col>
                            <Col className={"p-2"} xs={24} sm={24} md={24} lg={24}>
                                <Row gutter={24}>
                                    <Col span={12} className="d-flex justify-content-end"><strong>{t("Middle name")}:</strong></Col>
                                    <Col span={12}>{_data.data?.profile?.middle_name}</Col>
                                </Row>
                            </Col>
                            <Col className={"p-2"} xs={24} sm={24} md={24} lg={24}>
                                <Row gutter={24}>
                                    <Col span={12} className="d-flex justify-content-end">
                                        <strong className="mb-1">{t("Username")}: </strong>
                                    </Col>
                                    <Col span={12}>
                                        <span >{_data.data?.username}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className={"p-2"} xs={24} sm={24} md={24} lg={24}>
                                <Row gutter={24}>
                                    <Col span={12} className="d-flex justify-content-end">
                                        <strong className="mb-1">{t("Password")}:</strong>
                                    </Col>
                                    <Col span={12} className="d-flex align-items-top">
                                        <span className="p-0 m-0">{isPass ? (password) : ('********')}</span>
                                        <span className="ps-2 m-0" style={{ cursor: "pointer" }} onClick={() => { showPassword(); password && setIsPass(!isPass) }}>{isPass ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</span>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ flex: 1 }} xs={24} sm={24} md={{ span: 16 }} lg={{ span: 16 }}>
                        <Row style={{ height: "100%", position: "relative" }} className="ms-1 rounded box-shadow" gutter={24}>
                            <Col span={24}>
                                <Tabs defaultActiveKey='1'>
                                    <TabPane tab={t("Basic details")} key="1">
                                        <Row className="ms-2" gutter={24}>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Email")}: </strong>{_data.data?.email}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span><strong className="mb-1">{t("Role")}: </strong>{_data.data?.role?.map((item: string, i: number) => (
                                                    <Tag key={i}>{item}</Tag>
                                                ))}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Phone number")}: </strong>{_data.data?.profile?.phone}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Gender")}: </strong>{_data.data?.profile?.gender === 1 ? "Male" : "Female"}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Second number")}: </strong>{_data.data?.profile?.phone_secondary}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Birthday")}: </strong>{_data.data?.profile?.birthday}</span>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={t("Passport details")} key="2">
                                        <Row>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Passport seria and number")}: </strong>{_data.data?.profile?.passport_seria ? `${_data.data?.profile?.passport_seria} ${_data.data?.profile?.passport_number}` : ''}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Passport JSHIR")}: </strong>{_data.data?.profile?.passport_pin}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Passport given by")}: </strong>{_data.data?.profile?.passport_given_by}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Passport date")}: </strong>{_data.data?.profile?.passport_given_date && `${_data.data?.profile?.passport_given_date} - ${_data.data?.profile?.passport_issued_date}`}</span>
                                            </Col>
                                            {_data.data?.profile?.passport_file && _data.data?.profile?.passport_file !== "undefined"
                                                && <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                    <a href={FILE_URL + _data.data?.profile?.passport_file} target="_blank" className="">
                                                        <Tag className="p-2 d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}>
                                                            <span>
                                                                <img width="30px" src="https://www.seaicons.com/wp-content/uploads/2015/11/document-file-icon.png" alt="file img" />
                                                            </span>
                                                            <span className="ms-1">
                                                                {
                                                                    "Passport file" + String(_data.data?.profile?.passport_file)?.substring(String(_data.data?.profile?.passport_file)?.lastIndexOf(".") + 1, String(_data.data?.profile?.passport_file)?.length)
                                                                }
                                                            </span>
                                                        </Tag>
                                                    </a>
                                                </Col>}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={t("Address details")} key="3">
                                        <Row>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Country")}: </strong>{_data.data?.country?.name}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Region")}: </strong>{_data.data?.region?.name}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Area")}: </strong>{_data.data?.area?.name}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Address")}: </strong>{_data.data?.profile?.address}</span>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={t("Permanent address details")} key="4">
                                        <Row>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Country")}: </strong>{_data.data?.permanentCountry?.name}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Permanent region")}: </strong>{_data.data?.permanentRegion?.name}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Permanent area")}: </strong>{_data.data?.permanentArea?.name}</span>
                                            </Col>
                                            <Col className={"p-2"} xs={24} sm={24} md={12} lg={12}>
                                                <span className="border-bottom"><strong className="mb-1">{t("Permanent address")}: </strong>{_data.data?.profile?.permanent_address}</span>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={t("User access")} key="5">
                                        <Row className="m-2" gutter={24}>
                                            {userAccess?.length ? userAccess?.map((element: any, index: number) => (
                                                <Col className={"p-2"} xs={24} sm={24} md={12} lg={12} key={index} >
                                                    <span className="border-bottom"><strong className="mb-1">{t(element?.name)}</strong>:&nbsp;
                                                        {element?.value?.map((e: string, j: number) => (
                                                            <React.Fragment key={j}>{e}{element?.value?.length !== 1 && element?.value?.length - 1 !== j ? "," : ""} &nbsp;&nbsp;</React.Fragment>
                                                        ))}</span>
                                                </Col>
                                            )) : null}
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </Col>
                            <div style={{ position: "absolute", bottom: 15, left: 0, right: 15 }}>
                                {isHasAuthRoute("user_update") && <Col xs={24} sm={24} md={24} lg={24}>
                                    <Button className="float-end" onClick={() => { history.push({ pathname: `/update_user/${id}` }); }} type="primary">{t("Edit")}</Button>
                                </Col>}
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </Wrapper>
    )
};

export default ViewUser

const Wrapper = styled.div`
    .rounded{
        border-radius: 8px !important;
        background-color: white;
    }
    .h5{
        font-size: 19px;
        color: #223C50;
    }

`;