import { FC, useEffect, useState } from 'react';
import { Form, Modal, Input, Switch, Button, Col, DatePicker } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getOnePara } from '../service';
import { useTranslation } from 'react-i18next';
import { ServerErrors } from 'utils/validationFunctions';
import moment from 'moment';

type EditParaType = {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setReRequest: (reRequest: boolean) => void,
    id: number | null,
    reRequest: boolean
}


const EditPara: FC<EditParaType> = ({ visible, setVisible, id, setReRequest, reRequest }) => {

    const { i18n, t } = useTranslation();
    const [form] = Form.useForm();
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true);
    const [startEnd, setStartEnd] = useState<{ start: string,startList:{hours:Array<number>,minutes:Array<number>}, end: string,endList:{hours:Array<number>,minutes:Array<number>} }>({ start: "",startList:{hours:[],minutes:[]}, end: "", endList:{hours:[],minutes:[]}})



    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOnePara(id, 'expand=description&self=1')
                        let sd = new Date();
                        if (response?.start_time) {
                            let a = response?.start_time?.split(":")
                            sd.setSeconds(0)
                            sd.setMinutes(a[1])
                            sd.setHours(a[0])
                        }
                        let ed = new Date();
                        if (response?.end_time) {
                            let b = response?.end_time?.split(":")
                            ed.setSeconds(0)
                            ed.setMinutes(b[1])
                            ed.setHours(b[0])
                        }
                        form.setFieldsValue({
                            name: response.name,
                            description: response?.description,
                            status: Number(response.status) === 1 ? setStatus(true) : setStatus(false),
                            start_time: response?.start_time ? moment(sd) : null,
                            end_time: response?.end_time ? moment(ed) : null
                        })
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])


    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            formdata.append(`name[${i18n.language}]`, values?.name)
            formdata.append(`description[${i18n.language}]`, values?.description)
            formdata.append('status', status ? '1' : '0');
            formdata.append('start_time', moment(values?.start_time).format("HH:mm"));
            formdata.append('end_time', moment(values?.end_time).format("HH:mm"));

            const response = await instance({ url: `/paras/${id}`, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)

                    })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)
                    })
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            setClicked(false)
            setReRequest(!reRequest)
            asyncN("error", 'update', error?.response?.data?.errors[0])
                .then(() => {
                    setVisible(false);
                    resetFields()
                })
        }
    }

    const resetFields = () => form.resetFields();

    const getDisabledHours = () => {
        var hours = [];
        if (startEnd?.end) {
            let a = startEnd.end.split(":");
            for (var i = 0; i < parseInt(a[0]); i++) {
                hours.push(i);
            }
        }
        if (startEnd?.start) {
            let a = startEnd.start.split(":");
            for (var i = 0; i < parseInt(a[0]); i++) {
                hours.push(i);
            }
        }
        return hours;
    }

    const getDisabledMinutes = () => {
        var minutes = [];
        if (startEnd?.end) {
            let a = startEnd.end.split(":");
            for (var i = 0; i < parseInt(a[1]); i++) {
                minutes.push(i);
            }
        }
        if (startEnd?.start) {
            let a = startEnd.start.split(":");
            for (var i = 0; i < parseInt(a[1]); i++) {
                minutes.push(i);
            }
        }
        return minutes;
    }

    useEffect(()=>{
        var sminutes = [];
        var eminutes = [];
        if (startEnd?.end) {
            let a = startEnd.end.split(":");
            for (var i = 0; i < parseInt(a[1]); i++) {
                eminutes.push(i);
            }
        }
        if (startEnd?.start) {
            let a = startEnd.start.split(":");
            for (var i = 0; i < parseInt(a[1]); i++) {
                sminutes.push(i);
            }
        }
        var shours = [];
        var ehours = [];
        if (startEnd?.end) {
            let a = startEnd.end.split(":");
            for (var i = 0; i < parseInt(a[0]); i++) {
                ehours.push(i);
            }
        }
        if (startEnd?.start) {
            let a = startEnd.start.split(":");
            for (var i = 0; i < parseInt(a[0]); i++) {
                shours.push(i);
            }
        }
        setStartEnd({
            ...startEnd,
            startList:{hours:shours,minutes:sminutes},
            endList:{hours:ehours,minutes:eminutes}
        })
    },[startEnd.start,startEnd.end])

    return (
        <>
            <Modal
                title={t("Para edit")}
                visible={visible}
                onCancel={() => { setVisible(false); resetFields() }}
                okText="Submit"
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Form.Item
                        label={t("Name")}
                        name="name"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Input placeholder={t("Enter para name") + " ..."} />
                    </Form.Item>
                    <Form.Item
                        label={t("Description")}
                        name="description"
                        shouldUpdate
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Input placeholder={t("Enter description for para") + " ..."} />
                    </Form.Item>
                    <Form.Item
                        label={`${t("Start time")}`}
                        name="start_time"
                    >
                        <DatePicker.TimePicker
                            onChange={(e) => {
                                setStartEnd({
                                    ...startEnd,
                                    start: moment(e).format("HH:mm")
                                })
                            }}
                            showNow={false}
                            showSecond={false}
                            className="w-100"
                            format="HH:mm"
                            disabledHours={()=>startEnd.startList.hours}
                            disabledMinutes={()=>startEnd.startList.minutes}

                        />
                    </Form.Item>
                    <Form.Item
                        label={`${t("End time")}`}
                        name="end_time"
                    >
                        <DatePicker.TimePicker
                            onChange={(e) => {
                                setStartEnd({
                                    ...startEnd,
                                    end: moment(e).format("HH:mm")
                                })
                            }}
                            showSecond={false}
                            showNow={false}
                            className="w-100"
                            format="HH:mm"
                            disabledHours={()=>startEnd.endList.hours}
                            disabledMinutes={()=>startEnd.endList.minutes}
                        />

                    </Form.Item>
                    <Form.Item
                        label={t("Status")}
                        name="status"
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                    <hr />
                    <Col span={24} className="text-end mt-2">
                        <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </Col>
                </Form>
            </Modal>
        </>
    )
}


export default EditPara;