import { Button, Col, Form, Input, message, Row, Select, Tooltip, Upload } from "antd";
import instance from "config/_axios";
import { getAllCoueses } from "pages/courses/service";
import { getAllQuestionTypes } from "pages/question_types/service";
import { getAllSemestrs } from "pages/semestr/service";
import { getAllSubjects } from "pages/subjects/service";
import React, { FC, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMinus, FaRedo, FaUpload } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { getAllLanguages } from "services";
import SunEditor from "suneditor-react";
import { asyncN } from "utils/notifications";
import { FeildsUiPropsType, lang, subQuestionType } from "./types";

const { Option } = Select

const FeildsUi: FC<FeildsUiPropsType> = forwardRef(({ onFinish, setEditorVal, editorVal, typeQuestion, setTypeQuestion, subQuestions, setSubQuestions }, ref): JSX.Element => {

    const history = useHistory()
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [languages, setLanguages] = useState<Array<lang>>();
    const [questionTypes, setQuestionTypes] = useState<Array<{ id: number, name: string }>>([]);
    const [semesteres, setSemesteres] = useState<Array<any>>([])
    const [courses, setCourses] = useState<Array<any>>([])
    const [subjects, setSubjects] = useState<Array<any>>([]);
    const [refreshSelectF, setRefreshSelectF] = useState<{ lang: boolean, types: boolean, subj: boolean, course: boolean }>({ lang: false, types: false, subj: false, course: false })
    const [fileList, setFileList] = useState<any>([]);
    const [percents, setPercents] = useState<Array<{ name: string, value: number }>>(
        [
            {
                name: "10%",
                value: 10,
            },
            {
                name: "20%",
                value: 20,
            },
            {
                name: "30%",
                value: 30,
            },
            {
                name: "40%",
                value: 40,
            },
            {
                name: "50%",
                value: 50,
            },
            {
                name: "60%",
                value: 60,
            },
            {
                name: "70%",
                value: 70,
            },
            {
                name: "80%",
                value: 80,
            },
            {
                name: "90%",
                value: 90,
            },
            {
                name: "100%",
                value: 100,
            }
        ])

    // console.log(typeQuestion);
        

    useEffect(() => {
        (
            async () => {
                try {
                    const response = await getAllLanguages();
                    setLanguages(response?.items);
                } catch (e: any) {
                    asyncN("error", "read", "Error read languages for questions!!!")
                }
            }
        )()
    }, [refreshSelectF?.lang]);

    useEffect(() => {
        (
            async () => {
                try {
                    const examQuestionTypes = await getAllQuestionTypes();
                    setQuestionTypes(examQuestionTypes?.items);
                } catch (e: any) {
                    asyncN("error", "read", e?.response ? e?.response?.data?.message : "Error read types for questions!!!")
                }
            }
        )()
    }, [refreshSelectF?.types]);

    useEffect(() => {
        questionTypes?.map((item: { id: number, name: string }) => {
            if (item?.name === "Kazus") {
                setTypeQuestion(getType(item?.id))
            }
        })
    }, [questionTypes])

    useEffect(() => {
        getSubjects();
    }, [refreshSelectF?.subj])

    useEffect(() => {
        getCourses()
    }, [refreshSelectF?.course])

    const getSubjects = async () => {
        const subjects = await getAllSubjects(0, 1, ``)
        setSubjects(subjects?.items);
    }

    const getCourses = async () => {
        const courses = await getAllCoueses(0, 1)
        setCourses(courses.items)
    }

    const getSemestrsByCourseId = async (id: number) => {
        const semesters = await getAllSemestrs(0, 1, `expand=course&filter={"course_id":"${id}"}`);
        setSemesteres(semesters?.items)
    }

    useImperativeHandle(ref, () => ({
        getSemestrs(id: number) {
            getSemestrsByCourseId(id);
        }
    }))

    const onChange = ({ fileList: newFileList }: any) => {

        if (Array.isArray(newFileList) && newFileList.length) {
            if (newFileList[0]?.size < 2048000) {
                setFileList(newFileList);
            } else {
                message.error("Rasm hajmi 2Mb dan oshmasligi kerak !");
            }
        }
    };

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    const getType = (id: number): string => {
        return questionTypes.filter((item: { id: number, name: string }) => {
            return item?.id === Number(id)
        })[0]?.name
    }

    const setSubQuestionsValues = async (id: number, value: string, type: "per" | "content") => {
        setSubQuestions([...subQuestions?.map((item: subQuestionType) => {
            if (id === item?.id) {
                return type === "per" ? {
                    ...item,
                    percent: Number(value)
                } : {
                    ...item,
                    percent: Number(value)
                }
            } else {
                return item
            }
        })])
    }


    const onPreview = async (file: any) => {

        let src = file.url;
        let name: string = file?.name;

        if (name) {
            switch (name.substring(name.lastIndexOf(".") + 1, name.length)) {
                case "pdf": {
                    let a = document.createElement('a');
                    a.target = '_blank';
                    a.href = src;
                    a.click();
                    a.remove();
                }; break

                case "doc": {
                    let a = document.createElement('a');
                    a.target = '_blank';
                    a.href = URL.createObjectURL(file?.originFileObj);
                    a.click();
                    a.remove();
                }; break

                case "docx": {
                    let a = document.createElement('a');
                    a.target = '_blank';
                    a.href = URL.createObjectURL(file?.originFileObj);
                    a.click();
                    a.remove();
                }; break

                case "img": {
                    if (!src) {
                        src = await new Promise(resolve => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file.originFileObj);
                            reader.onload = () => resolve(reader.result);
                        });
                    }
                    const image = new Image();
                    image.src = src;
                    const imgWindow = window.open(src);
                    imgWindow?.document.write(image.outerHTML);
                }; break

                case "png": {
                    if (!src) {
                        src = await new Promise(resolve => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file.originFileObj);
                            reader.onload = () => resolve(reader.result);
                        });
                    }
                    const image = new Image();
                    image.src = src;
                    const imgWindow = window.open(src);
                    imgWindow?.document.write(image.outerHTML);
                }; break

                case "image": {
                    if (!src) {
                        src = await new Promise(resolve => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file.originFileObj);
                            reader.onload = () => resolve(reader.result);
                        });
                    }
                    const image = new Image();
                    image.src = src;
                    const imgWindow = window.open(src);
                    imgWindow?.document.write(image.outerHTML);
                }; break

                default: break
            }
        }
    };

    return (
            <Row gutter={24}>
                <Col className="text-danger d-flex justify-content-start" span={24}>
                    ( * ) majburiy to'ldirishi kerak bo'lgan maydonlar
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label={t('Course')}
                        name="course_id"
                        rules={[{ required: true, message: `Please select course` }]}
                    >
                        <Select onFocus={() => {
                            if (subjects?.length === 0) {
                                setRefreshSelectF({
                                    ...refreshSelectF,
                                    course: !refreshSelectF?.course
                                })
                            }
                        }} onChange={(e: number) => { getSemestrsByCourseId(e) }} placeholder={t("Select a course")}>
                            {
                                courses.length && courses.map((item: { id: number, name: string }, i: number) =>
                                    <Select.Option key={i} value={item?.id}>{item?.name + "-" + t("course")}</Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label={t('Semestr')}
                        name="semestr_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select placeholder={t("Select a semestr")}>
                            {
                                semesteres.length && semesteres.map((item: { id: number, name: string, course: { id: number, name: string } }, i) =>
                                    <Select.Option key={i} value={item?.id}>{item?.name + "-semestr"}</Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={16} lg={12}>
                    <Form.Item
                        label={t('Subject')}
                        name="subject_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select onFocus={() => {
                            if (subjects?.length === 0) {
                                setRefreshSelectF({
                                    ...refreshSelectF,
                                    subj: !refreshSelectF?.subj
                                })
                            }
                        }} showSearch filterOption={(input: any, option: any) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        } placeholder={t("Select subject")}>
                            {
                                subjects && subjects.map((item: { id: number, name: string, semestr_id: number }, i) =>
                                    <Select.Option key={i} value={String(item?.id + "-" + item?.semestr_id)}>{item?.name}</Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        initialValue={1}
                        label={t('Type')}
                        name="question_type_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select onFocus={() => {
                            if (questionTypes?.length === 0) {
                                setRefreshSelectF({
                                    ...refreshSelectF,
                                    types: !refreshSelectF?.types
                                })
                            }
                        }} onChange={(e: number) => setTypeQuestion(getType(e))} placeholder={t("Select question type")}>
                            {
                                questionTypes && questionTypes.map((item: { id: number, name: string }, i) =>
                                    <Select.Option key={i} value={item?.id}>{item?.name}</Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label={t('Language')}
                        name="lang_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select onFocus={() => {
                            if (languages?.length === 0) {
                                setRefreshSelectF({
                                    ...refreshSelectF,
                                    lang: !refreshSelectF?.lang
                                })
                            }
                        }} placeholder={t("Select language")}>
                            {languages && languages.map((item: lang, i: number) =>
                                <Select.Option key={i} value={item?.id}>{item?.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label={t('Level')}
                        name="level"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select placeholder={t("Enter level")} >
                            <Option value={1}>Oson</Option>
                            <Option value={2}>O'rta</Option>
                            <Option value={3}>Qiyin</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item
                        label={t('Question file')}
                        name="question_file"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Upload
                            style={{ width: "100%" }}
                            customRequest={dummyRequest}
                            listType="text"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            accept=".pdf,.png,.img"
                            onRemove={() => setFileList([])}
                        >
                            {fileList.length < 1 && <Button type="default"><FaUpload className="me-1" />{t("Upload")}</Button>}
                        </Upload>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                rules={[{ required: true, message: t("Fill in the field!") }]}
                                label={
                                    t(typeQuestion)
                                }
                                name="general_question">
                                <SunEditor setContents={editorVal} onChange={(e) => setEditorVal(e)} height={300 + "px"} placeholder={t("Enter a cazus text")} setAllPlugins={true} />
                            </Form.Item>
                        </Col>
                        {
                            subQuestions?.map((item: subQuestionType, i: number) => {
                                return (
                                    <Col key={i} className="mt-3" span={24}>
                                        <Row gutter={24}>
                                            <Col span={24} style={{ position: "relative" }}>
                                                {subQuestions?.length > 1 && i === subQuestions?.length - 1 && <span className="kazus-question-remove-button">
                                                    <Button
                                                        onClick={() => {
                                                            setSubQuestions(subQuestions?.filter((item1: subQuestionType) => item1?.id !== item?.id))
                                                        }}
                                                        type="primary"
                                                        danger
                                                        shape="circle"
                                                        className="btn btn-danger pt-0 pb-0 ps-1 pe-1 mt-1"
                                                    >
                                                        <FaMinus />
                                                    </Button>
                                                </span>}
                                                <div className="question-persent-input d-flex">
                                                    <span className="pt-1 pe-2 question-persent-input-label">{t("Persent")}</span>
                                                    <Form.Item rules={[{ required: true, message: '' }]} initialValue={item?.percent} name={`persent-` + i}>
                                                        <Select
                                                            onChange={(e: string) => {
                                                                setSubQuestionsValues(item?.id, e, "per")
                                                            }}
                                                            style={{ width: "85px" }}
                                                            placeholder={t("Enter a question persent")} >
                                                            {
                                                                percents?.map((item: { name: string, value: number }, j: number) =>
                                                                    <Select.Option key={j} value={item?.value}>{item?.name}</Select.Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <Form.Item
                                                    rules={[{ required: true, message: t("Fill in the field!") }]}
                                                    labelCol={{ span: 24 }}
                                                    label={(i + 1) + t("-question")}
                                                    name={"question-" + i}>
                                                    <SunEditor height={66 + "px"} setContents={item?.question} placeholder={t("Enter a question")} setAllPlugins={true} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            }
                            )

                        }
                        <Col span={24}>
                            <Button disabled={subQuestions?.length >= 10} type="link" onClick={() => {
                                setSubQuestions([
                                    ...subQuestions,
                                    {
                                        id: subQuestions?.length > 0 ? subQuestions[subQuestions.length - 1].id + 1 : 1,
                                        question: "",
                                    }
                                ])
                            }}>{t("+ Add question")}</Button>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} xs={24} md={24} lg={24} className="mt-2">
                    <Button style={{ float: "right" }} className="m-1" type="primary" htmlType="submit">
                        {t("Save")}
                    </Button>
                    <Button style={{ float: "right" }} className="m-1" htmlType="button">
                        <FaRedo className="me-1" />
                        {t("Reset")}
                    </Button>
                    <Button style={{ float: "right" }} className="m-1" htmlType="button">
                        {t("Cancel")}
                    </Button>
                </Col>
            </Row>
    )
})

export default FeildsUi