import { Button, Col, Form, Row, message } from "antd"
import CustomBreadcrumb from "components/Breadcrumb"
import UserMainInfo from "pages/student/crud/froms/main_info"
import { FC, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import { UploadFile } from "antd/lib/upload/interface"
import React from "react"
import moment from "moment"
import useUrlQueryParams from "hooks/useUrlQueryParams"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { asyncN } from "utils/notifications"
import instance from "config/_axios"
import CreateAndUpdateForm from "pages/user/components/create_and_update_form"
import UserAdditionalInfo from "pages/user/components/additional_info"
import CardContainer from "components/Widgets/CardContainer"
import NewTeacherAccess from "./teacher_access"

const _breadcrump = [
  {
    name: "Home",
    path: '/',
  },
  {
    name: "O'qituvchilar bazasi",
    path: "teachers_index",
  },
  {
    name: "Create teacher",
    path: "create   _teacher",
  },
]


const NewCreateTeacher: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();

  const [userAccess, setUserAccess] = React.useState<Record<string, string[]>>({});
  const [teacherAccess, setTeacherAccess] = React.useState<Record<string, number[]>>({});
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [filePassport, setFilePassport] = React.useState<UploadFile[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      "role": ["teacher"]
    })
  }, [])

  const onSubmit = async (values: Record<string | number, any>) => {
    try {

      const { passport_seria_and_number, phone, passport_pin } = values;

      const isValidateField = passport_seria_and_number?.includes('_') || phone?.includes('_') || passport_pin?.includes('_');

      if (isValidateField) {
        message.warning("Kiritilgan ma'lumotlar to'g'ri formatda ekanligini tekshiring!")
        return;
      }

      if (!Object.keys(userAccess).length) {
        message.warning("Ushbu xodimni Bo'lim, Kafedra yoki Taxnikumga biriktirish zarur !!!")
        return;
      }

      if (!Object.keys(teacherAccess).length) {
        message.warning("O'qituvchiga fan biriktirish zarur !!!")
        return;
      }

      const formdata = new FormData();
      Object.entries(values).forEach(([key, value]: any) => {
        if (value) {
          if (key === "birthday") {
            formdata.append(key, moment(value).format("YYYY-MM-DD"));
          } else if (['avatar', 'passport_file'].includes(key)) {
            formdata.append(key, value.file.originFileObj);
          } else if (key === 'role') {
            formdata.append(key, JSON.stringify(value));
          } else if (key === 'passport_date') {
            formdata.append("passport_given_date", moment(value[0]).format("YYYY-MM-DD"))
            formdata.append("passport_issued_date", moment(value[1]).format("YYYY-MM-DD"))
          } else if (key === 'passport_seria_and_number') {
            formdata.append("passport_seria", value.split(" ")[0])
            formdata.append("passport_number", value.split(" ")[1])
          } else {
            formdata.append(key, String(value));
          }
        }
      });

      formdata.append("user_access", JSON.stringify(userAccess))
      formdata.append("teacherAccess", JSON.stringify(teacherAccess));

      const response = await instance({ url: '/users', method: 'POST', data: formdata });

      console.log(response);


      if (response.data.status === 1) {
        asyncN("success", 'create', response.data?.message)
          .then(() => {
            history.goBack()
          })
      } else {
        asyncN("error", 'update', response.data?.message)
      }

    } catch (error: any) {
      form.setFields(
        Object.entries(error.response.data.errors[0]).map(([key, value]: any) => {
          return { name: key, errors: value }
        })
      )
    }
  }

  return (
    <div className="bg-light p_12 rounded rounded-3">
      <Col span={24} className="mb_12" >
        <Card style={{ position: "relative", zIndex: 100 }}>
          <CardBody className='p-2'>
            <CustomBreadcrumb arr={_breadcrump} />
          </CardBody>
        </Card>
      </Col>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        className="custom_form_style"
      >
        <Row gutter={[12, 12]}>
          <Col xl={10} lg={24} md={24} sm={24} xs={24}>
            <CreateAndUpdateForm form={form} fileList={fileList} setFileList={setFileList} />
          </Col>
          <Col xl={14} lg={24} md={24} sm={24} xs={24}>
            <UserAdditionalInfo form={form} userAccess={userAccess} setUserAccess={setUserAccess} />
            <CardContainer className="mt_12">
              <NewTeacherAccess teacherAccess={teacherAccess} setTeacherAccess={setTeacherAccess} />
            </CardContainer>
            <div className="text-end mt-3">
              <Button type="primary" danger className="px-5" onClick={() => { form.resetFields(); history.goBack(); }}>{t("Cancle")}</Button>
              <Button type="primary" className="ms-3 px-5" htmlType="submit" >{t("Save")}</Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div >
  )
};

export default NewCreateTeacher;