import { Form, Select, Button, Modal, Row, Col, Switch } from "antd";
import FormLabel from "components/FormLabel";
import instance from "config/_axios";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { IPara } from "models/education";
import { IBuilding, IRoom, IWeek } from "models/others";
import { ISubjectCategory } from "models/subject";
import React, { useState } from "react";
import { BiArrowFromLeft, BiX } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "store/services";
import { TIMETABLE_ACTIONS } from "store/time_table";
import { asyncN } from "utils/notifications";
import styles from '../styles/styles.module.scss';
const { Option } = Select;

type TypeCreateValues = {
    week_id: undefined | number,
    para_id: undefined | number,
    building_id: undefined | number,
    room_id: undefined | number,
    subject_category_id: undefined | number,
    teacher_access_id: undefined | number
}

const initialValues: TypeCreateValues = {
    week_id: undefined,
    para_id: undefined,
    building_id: undefined,
    room_id: undefined,
    subject_category_id: undefined,
    teacher_access_id: undefined
}

const CreateChildTimeTable: React.FC<{ setRefetch: React.Dispatch<React.SetStateAction<boolean>>, para: IPara[], weeks: IWeek[] }> = ({ setRefetch, weeks, para }): JSX.Element => {

    const [form] = Form.useForm();
    const dispatch: any = useAppDispatch();
    const { visible, parent } = useAppSelector(state => state.time_table);
    const [status, setStatus] = useState<boolean>(true);

    const [rooms, setRooms] = useState<IRoom[]>([]);
    const [values, setValues] = React.useState<TypeCreateValues>(initialValues);

    const buildings = useGetAllData({ url: `/buildings`, perPage: 0 }) as TypeReturnUseGetAllData<IBuilding>;
    const subject_categories = useGetAllData({ url: `/subject-categories`, perPage: 0 }) as TypeReturnUseGetAllData<ISubjectCategory>;
    const teacher_access = useGetAllData({
        url: `/teacher-accesses/free?filter=${JSON.stringify(Number(values.subject_category_id) === 1 ? {language_id: parent?.language_id, subject_id: parent?.subject_id, is_lecture: 1} : {language_id: parent?.language_id, subject_id: parent?.subject_id})}`,
        params: {
            para_id: form.getFieldValue('para_id'),
            week_id: form.getFieldValue('week_id'),
            semester_id: parent?.semester_id,
            edu_year_id: parent?.edu_year_id,
            subject_category_id: values.subject_category_id
        },
        perPage: 0
    });

    console.log("parent", parent)

    const getRooms = async () => {

        const formdata = new FormData();
        formdata.append('para_id', form.getFieldValue('para_id'));
        formdata.append('week_id', form.getFieldValue('week_id'));
        formdata.append('edu_year_id', String(parent?.edu_year_id));
        formdata.append('semester_id', String(parent?.semester_id));
        const response = await instance({ url: `/rooms/free?sort=-id&filter=${JSON.stringify({building_id: values.building_id})}`, method: 'POST', data: formdata, params: { "per-page": 0 } });

        if (response.data?.status === 1) {
            setRooms(response.data?.data?.items);
        }

    }

    const handleChange = React.useCallback(({ name, value }: { name: string, value: number }) => {

        setValues((prevState) => ({ ...prevState, [name]: value }))

    }, [values])

    const onSubmit = async () => {
        try {

            const values = form.getFieldsValue();
            const formdata = new FormData();
            formdata.append('edu_semester_id', String(parent?.edu_semester_id));
            formdata.append('subject_id', String(parent?.subject_id));
            formdata.append('language_id', String(parent?.language_id));
            formdata.append('status', status ? '1' : '0');

            if (parent?.subject_category_id === 1) {
                if (form.getFieldValue('subject_category_id') === 1) {
                    formdata.append('parent_id', String(parent?.id));
                } else {
                    formdata.append("lecture_id", String(parent?.id));
                }
            } else {
                if (!parent?.parent_id && !parent?.lecture_id) {
                    formdata.append('parent_id', String(parent?.id));
                } else {
                    formdata.append('parent_id', String(parent?.id));
                    formdata.append("lecture_id", String(parent?.lecture_id));
                }
            }

            for (const [key, value] of Object.entries(values)) {
                if (key !== 'edu_plan_id') {
                    formdata.append(key, String(value));
                }
            }

            const response = await instance({ url: "/time-tables", method: "POST", data: formdata });

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message).then(() => {
                    form.resetFields();
                    setValues(initialValues);
                    setRefetch(prevState => !prevState);
                    dispatch(TIMETABLE_ACTIONS.closeModal());
                })
            } else {
                asyncN("error", 'update', response.data?.message).then(() => form.resetFields())
            }
        } catch (error) { }
    };


    return (
        <Modal
            title={<span className={styles.modal_title}><span>YARATISH. </span></span>}
            centered
            visible={visible === 'child_create'}
            onCancel={() => { dispatch(TIMETABLE_ACTIONS.closeModal()); form.resetFields() }}
            footer={null}
            closeIcon={<BiX size={20} />}
            width={1000}
        >
            <Row gutter={[12, 12]}>
                <Col xl={8} xs={8}>
                    {
                        parent?.id ?
                            <div className={styles.parent_time_table_box} style={{ height: "100%" }} >
                                <span className={styles.info_text_subject}> Siz keltirilgan dars jadval uchun seminar yoki ma'ruza kiritishingiz mumkin.</span>
                            </div> : null
                    }
                </Col>
                <Col xl={4} xs={4}>
                    <div className="text-center" style={{ verticalAlign: "middle", height: "100%" }} >
                        <BiArrowFromLeft size={40} style={{ verticalAlign: "middle", height: "100%" }} />
                    </div>
                </Col>
                <Col xl={12} xs={12}>
                    {
                        parent?.id ?
                            <div className={styles.parent_time_table_box}>
                                <span className={styles.info_text_subject} >{parent.week_name} / {parent.para_name} / {parent.building?.name} / {parent?.room?.name?.replace(/\D/g, '')}-xona ({parent?.room?.capacity}) / <i> {parent.subjectCategory?.name}</i> / <i>{parent.language?.name}</i> </span><br />
                                <span className={styles.info_text_subject}>{parent.subject?.name} / </span>
                                <span className={styles.info_text_teacher}>{parent.teacher?.last_name} {parent.teacher?.first_name} {parent.teacher?.middle_name}</span>
                            </div> : null
                    }
                </Col>
            </Row>

            <Form
                form={form}
                layout="vertical"
                className="mt-3"
            >
                <Row gutter={[12, 0]} >
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabel>Hafta kuni</FormLabel>}
                            name="week_id"
                            rules={[{ required: true, message: 'Select item' }]}
                            className="mb-1"
                        >
                            <Select
                                className="w-100"
                                allowClear
                                onChange={(eventValue) => { handleChange({ name: 'building_id', value: eventValue }); form.setFieldsValue({ room_id: undefined }) }}
                            >
                                {
                                    weeks.length ? weeks.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabel>Akademik soat</FormLabel>}
                            name="para_id"
                            rules={[{ required: true, message: 'Select item' }]}
                            className="mb-1"
                        >
                            <Select
                                className="w-100"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                                }
                                onChange={(eventValue) => { handleChange({ name: 'building_id', value: eventValue }); form.setFieldsValue({ room_id: undefined }) }}
                            >
                                {
                                    para.length ? para.map((element) => <Option key={element.id} value={element?.id} >{element?.start_time} - {element.end_time}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabel>O'quv binosi</FormLabel>}
                            name="building_id"
                            rules={[{ required: true, message: 'Select item' }]}
                            className="mb-1"
                        >
                            <Select
                                className="w-100"
                                allowClear
                                onFocus={() => buildings.fetch()}
                                onChange={(eventValue) => { handleChange({ name: 'building_id', value: eventValue }); form.setFieldsValue({ room_id: undefined }) }}
                                loading={buildings.loading}
                                showSearch
                                filterOption={(input, option) =>
                                    (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                                }
                            >
                                {
                                    buildings.items.length ? buildings.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabel>O'quv xonasi</FormLabel>}
                            name="room_id"
                            rules={[{ required: true, message: 'Select item' }]}
                            className="mb-1"
                        >
                            <Select
                                className="w-100"
                                allowClear
                                onFocus={() => { getRooms() }}
                                onChange={(eventValue) => { handleChange({ name: 'room_id', value: eventValue }) }}
                                disabled={!Boolean(values.building_id)}
                                showSearch
                                filterOption={(input, option) =>
                                    (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                                }
                            >
                                {
                                    rooms.length ? rooms.map((element) => <Option key={element.id} value={element?.id} >{element?.name}-xona. &nbsp;&nbsp;&nbsp;<span style={{ color: 'red' }}>Sig'imi: {element.capacity}</span></Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabel>Mashg'ulot toifasi</FormLabel>}
                            name="subject_category_id"
                            rules={[{ required: true, message: 'Select item' }]}
                            className="mb-1"
                            initialValue={parent?.subject_category_id !== 1 ? parent?.subject_category_id : undefined}
                        >
                            <Select
                                className="w-100"
                                allowClear
                                onFocus={() => subject_categories.fetch()}
                                onChange={(eventValue) => { handleChange({ name: 'subject_category_id', value: eventValue }); form.setFieldsValue({ language_id: undefined, teacher_access_id: undefined }) }}
                                loading={subject_categories.loading}
                                showSearch
                                filterOption={(input, option) =>
                                    (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                                }
                            >
                                {
                                    parent?.subject_category_id !== 1 ?
                                        <Option value={parent?.subject_category_id}>Seminar</Option> :
                                        subject_categories.items.length ? subject_categories.items.map((element) => <Option key={element.id} value={element?.id}>{element?.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                            name='teacher_access_id'
                            label={<FormLabel>O'qituvchi</FormLabel>}
                            rules={[{ required: true, message: 'Select item' }]}
                            className="mb-1"
                        >
                            <Select
                                className="w-100"
                                allowClear
                                onFocus={() => teacher_access.fetch()}
                                onChange={(eventValue) => handleChange({ name: 'teacher_access_id', value: eventValue })}
                                // disabled={!Boolean(values.week_id) || !Boolean(values.para_id)}
                                loading={teacher_access.loading}
                                showSearch
                                filterOption={(input, option) =>
                                    (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                                }
                            >
                                {
                                    teacher_access.items.length ? teacher_access.items.map((element) => <Option key={element.id} value={element?.id}>{element.teacher?.last_name}&nbsp;{element.teacher?.first_name}&nbsp;{element.teacher?.middle_name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mt-2">
                        <Row gutter={[12, 12]}>
                            <Col lg={{ span: 8 }}>
                                <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
                            </Col>
                            <Col lg={{ span: 16 }} className="text-end">
                                <Button type="primary" className="mx-2 min_w_100" onClick={() => form.resetFields()} >Reset</Button>
                                <Button type="primary" className="min_w_100" onClick={onSubmit} >Saqlash</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )

};

export default React.memo(CreateChildTimeTable);