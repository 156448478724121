import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const EduForm: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="edu-forms"
                indexTitle="Edu form"
                editTitle="Edu form edit"
                viewTitle="Edu form view"
                createTitle="Create edu form"
                permissions={{
                    view_: "edu-form_view",
                    delete_: "edu-form_delete",
                    update_: "edu-form_update",
                    create_: "edu-form_create"
                }}
            />
        </div>
    )
}

export default EduForm;