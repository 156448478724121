/** @format */

import { RoutesTypeChildren } from "./types";
import {
  FaHome,
  FaUserGraduate,
  FaUsersCog,
  FaUserLock,
  FaCogs,
  FaLanguage,
  FaBriefcase,
  FaLayerGroup,
  FaListAlt,
  FaBuilding,
  FaColumns,
  FaFileContract,
  FaBook,
  FaRegCalendarAlt,
  FaAlignCenter,
  FaFileSignature,
  FaHotjar,
  FaListOl,
  FaMapMarkerAlt,
  FaUserTag,
  FaCalendarWeek,
  FaPollH,
  FaDeezer,
  FaUsers,
  FaClipboardCheck,
  FaChalkboardTeacher,
  FaPlus,
  FaInfoCircle,
  FaIdCard,
  FaCalendarAlt,
  FaGlobe,
  FaHouseUser,
  FaCheckDouble,
} from "react-icons/fa";
import { GiBookshelf, GiCheckboxTree, GiTeacher } from "react-icons/gi";
import {
  BiIdCard,
  BiAddToQueue,
  BiMessageAltDetail,
  BiBookReader,
} from "react-icons/bi";
import { ImBooks } from "react-icons/im";
import { IoBriefcaseOutline, IoHomeOutline, IoLanguage } from "react-icons/io5";
import { BsCardChecklist, BsQuestionLg, BsTable } from "react-icons/bs";
import Department from "pages/department";
import Nationalities from "pages/directories/Nationalities/NationalitiesModal";
import InProgressPage from "pages/additional_pages/InProgressPage";
import Subjects from "pages/subjects";
import Users from "pages/user";
import Login from "pages/login";
import Directions from "pages/directions";
import AddUser from "pages/user/components/add";
import EditUser from "pages/user/components/edit";
import Roles from "pages/role_and_permission";
import AddRole from "pages/role_and_permission/components/add";
import EditRole from "pages/role_and_permission/components/edit";
import Students from "pages/student";
import Documentation from "pages/documentation";
import Texnikum from "pages/faculty";
import Lectern from "pages/lectern";
import Room from "pages/rooms";
import Building from "pages/buildings";
import Course from "pages/courses";
import Para from "pages/paras";
import Semestr from "pages/semestr";
import ExamTypes from "pages/exam_types";
import EduTypes from "pages/edu_types";
import EduYear from "pages/edu_years";
import SubjectType from "pages/subject_type";
import SubjectCategory from "pages/subject_category";
import EduPlan from "pages/edu_plan";
import CreateEduPlan from "pages/edu_plan/crud/create";
import EditEduPlan from "pages/edu_plan/crud/edit";
import EduSemester from "pages/edu_semester";
import CreateStudent from "pages/student/crud/create";
import EditStudent from "pages/student/crud/edit";
import StudentView from "pages/student/crud/view";
import EduPlanViewSemestr from "pages/edu_plan/crud/viewSemestr";
import TimeTable from "pages/time_table";
import AttachSubject from "pages/edu_plan/attachSubjects/attachSubject";
import Exam from "pages/exam";
import CreateExam from "pages/exam/crud/create";
import EditExam from "pages/exam/crud/edit";
import EditTimeTable from "pages/time_table/crud/edit";
import ProfilePage from "pages/additional_pages/profilePage";
// import ProfilePage from "pages/profile";
import AttachSillabus from "pages/subjects/sillabus";
import ViewUser from "pages/user/components/view";
import Questions from "pages/questions";
import AttachQuestions from "pages/questions/crud/attach";
import QuestionOption from "pages/questions/crud/option";
import EditQuestions from "pages/questions/crud/edit";
import Teachers from "pages/teachers";
import TeachersCreate from "pages/teachers/crud/create";
import TeachersEdit from "pages/teachers/crud/edit";
import Teachersview from "pages/teachers/crud/view";
import AttachQuestionsForExam from "pages/exam/crud/questions";
import GetSecretKeys from "pages/exam/crud/secretKeys";
import DepartmentEmployees from "pages/department/crud/employees";
import FacultyEmployees from "pages/faculty/crud/employees";
import LecternEmployees from "pages/lectern/crud/employees";
import EduSemesterView from "pages/edu_semester/crud/view";
import EduSemestrEdit from "pages/edu_semester/crud/edit";
import EduSemestrSubjectCreate from "pages/edu_semester/crud/attach_subject_to_semestr";
import Note from "pages/note";
import Message from "pages/message";
import QuestionTypes from "pages/question_types";
import EduForm from "pages/edu_form";
import Citizenship from "pages/citizenship";
import Nationality from "pages/nationality";
import Week from "pages/week";
import Panel from "pages/dashboards/index";
import SubjectTopics from "pages/subject_topic";
import SubjectTopicCreate from "pages/subject_topic/components/create";
import SubjectTopicEdit from "pages/subject_topic/components/edit";
import SubjectContent from "pages/subject_content";
import SubjectContentCreate from "pages/subject_content/components/create";
import SubjectContentEdit from "pages/subject_content/components/edit";
import StudentStatisticPage from "pages/dashboards/RectorDashboard/statistic_pages/student_statistic";
import EmployeeStatisticPage from "pages/dashboards/RectorDashboard/statistic_pages/employee_statistic";
import ViewQuestionPage from "pages/questions/crud/view_page";
import TeacherStatistics from "pages/teacher_statistics";
import ExamCheckerTeachers from "pages/exam/pages/DistributionExams";
import StudentPartners from "pages/student_partners";
import LivingSpaceStatus from "pages/living_space_status";
import SocialCategory from "pages/social_category";
import StudentCategory from "pages/student_caregories";
import Employees from "pages/employees";
import StudentPassedExams from "pages/exam/pages/StudentPassedExams";
import ExamAnswerPage from "pages/exam/pages/PageForCheckingQuestionAnswers";
import TeacherRoleExams from "pages/exam/pages/TeacherCheckExams";
import ExamView from "pages/exam/crud/view";
import LoginAndPasswordPage from "pages/student/default/loginAndPassword";
import QuestionsExport from "pages/questions/export";
import ExamStudents from "pages/exam/exam_students";
import ExamStudentAnswer from "pages/exam/exam_students/exam_student_answers";
import EmployeeTabel from "pages/employee_tabel";
import DiplomaType from "pages/diploma_type";
import Degrees from "pages/degree";
import DegreeInfos from "pages/degree_info";
import AcademicDegrees from "pages/academic_degree";
import JobTitles from "pages/employee_tabel/job_title";
import WorkRate from "pages/employee_tabel/work_rate";
import Tables from "pages/employee_tabel/tables";
// import DiplomaType from "diploma_type";
import Holiday from "pages/holiday";
import Appelation from "pages/exam/appelation";
import AppealStudents from "pages/exam/exam_students/appeal_students";
import AppelationChecking from "pages/exam/appelation/appeal_checking";
import AppelationCards from "pages/exam/appelation/filtered_appelations";
import ExamStudentsIndex from "pages/exam_student";
import SubjectTeachers from "pages/subjects/teachers";
import LanguageCertificateType from "pages/language_certificate_type";
import IntensivCreate from "pages/exam/crud/intensiv";
import DormitoryCategory from "pages/dormitory";
import DormitoryStudents from "pages/student/dormitory";
import CompareStudentData from "pages/student/crud/compare_data";
import CheckingContentIndex from "pages/checking_content";
import {
  MdCastForEducation,
  MdOutlineAdminPanelSettings,
} from "react-icons/md";
import { RiBuilding2Line } from "react-icons/ri";
import { HiOutlineUserGroup, HiOutlineUsers } from "react-icons/hi";
import { FiDatabase } from "react-icons/fi";
import { IoIosGitBranch } from "react-icons/io";
import Faculty from "pages/faculty";
import TimeOptions from "pages/time_option";
import TimeTableByChild from "pages/time_table/pages/timeTableGroup";
import NewEditStudent from "pages/student/crud/new_edit";
import NewCreateUser from "pages/user/components/new_create";
import NewUpdateUser from "pages/user/components/new_update";
import NewStudentView from "pages/student/crud/new_view";
import TimeOptionView from "pages/time_option/crud/view";
import NewCreateTeacher from "pages/teachers/crud/new_create";
import NewUpdateTeacher from "pages/teachers/crud/new_update";
import ContractUpload from "pages/contractUpload";

export const public_routes: Array<RoutesTypeChildren> = [
  {
    name: "Login",
    path: "/",
    component: Login,
    config: {
      key: "unlock",
      icon: FaBriefcase,
      structure: "nonlayout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
];

export const private_routes: Array<RoutesTypeChildren> = [
  {
    name: "Main page",
    path: "/",
    component: Panel,
    config: {
      key: "unlock",
      icon: IoHomeOutline,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "",
    path: "/student_statistic",
    component: StudentStatisticPage,
    config: {
      key: "unlock",
      icon: FaHome,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "",
    path: "/employee_statistic",
    component: EmployeeStatisticPage,
    config: {
      key: "unlock",
      icon: FaHome,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Profile",
    path: "/user-profile",
    component: ProfilePage,
    config: {
      key: "unlock",
      icon: FaHome,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Teacher statistics",
    path: "/teacher_statistics",
    component: TeacherStatistics,
    config: {
      key: "unlock",
      icon: FaHome,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "User types",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: FaUsers,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "Users",
        path: `/users`,
        component: Users,
        config: {
          key: "user_index",
          icon: FaUsersCog,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Create user",
        path: `/create_user`,
        component: NewCreateUser,
        config: {
          key: "user_create",
          icon: FaUsersCog,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Update user",
        path: `/update_user/:user_id`,
        component: NewUpdateUser,
        config: {
          key: "user_create",
          icon: FaUsersCog,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Update user",
        path: `/edit-user/:id`,
        component: EditUser,
        config: {
          key: "user_update",
          icon: FaUsersCog,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "View user",
        path: `/view-user/:id`,
        component: ViewUser,
        config: {
          key: "user_view",
          icon: FaUsersCog,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Roles & permissions",
        path: `/roles`,
        component: Roles,
        config: {
          key: "access-control_roles",
          icon: FaUserLock,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      // {
      //   name: "Diploma types",
      //   path: `/diploma-types`,
      //   component: DiplomaType,
      //   config: {
      //     key: "diploma-type_index",
      //     icon: FaUserLock,
      //     structure: "layout",
      //     exact: true,
      //     isShowLink: true,
      //   },
      // },
      {
        name: "",
        path: `/add-role`,
        component: AddRole,
        config: {
          key: "access-control_create-role",
          icon: FaLanguage,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "",
        path: `/edit-role/:id`,
        component: EditRole,
        config: {
          key: "access-control_update-role",
          icon: FaLanguage,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
    ],
  },

  {
    name: "Structural division",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: IoIosGitBranch,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "Technikumes",
        path: "/faculties",
        component: Faculty,
        config: {
          key: "faculty_index",
          icon: FaHome,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Texnikum employees",
        path: "/faculty-employees",
        component: FacultyEmployees,
        config: {
          key: "faculty_index",
          icon: FaHome,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Lecterns",
        path: "/lecterns",
        component: Lectern,
        config: {
          key: "kafedra_index",
          icon: FaHome,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Lectern employees",
        path: "/lectern-employees/:id",
        component: LecternEmployees,
        config: {
          key: "kafedra_index",
          icon: FaHome,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Departments",
        path: "/departments",
        component: Department,
        config: {
          key: "department_index",
          icon: FaUserLock,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Department employees",
        path: "/department-employees",
        component: DepartmentEmployees,
        config: {
          key: "department_index",
          icon: FaUserLock,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
    ],
  },
  {
    name: "HR",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: IoBriefcaseOutline,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "Tabel",
        path: "/tabel",
        component: Tables,
        config: {
          key: "table-store_index",
          icon: BsTable,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Tabel",
        path: "/employee-tabel/:user_access_id/:table_id/:tabel_id/:year/:month/:type",
        component: EmployeeTabel,
        config: {
          key: "user_index",
          icon: BsTable,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Job titles",
        path: "/job-titles",
        component: JobTitles,
        config: {
          key: "job-title_index",
          icon: FaUsersCog,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Work rate",
        path: "/work-rate-index",
        component: WorkRate,
        config: {
          key: "work-rate_index",
          icon: FaUsersCog,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Holiday",
        path: `/holidayindex`,
        component: Holiday,
        config: {
          key: "holiday_index",
          icon: FaCalendarAlt,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Tabel",
        path: "/employee-tabel",
        component: EmployeeTabel,
        config: {
          key: "user_index",
          icon: BsTable,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      // {
      //   name: "Teacher create",
      //   path: "/teacher_create",
      //   component: TeachersCreate,
      //   config: {
      //     key: "user_create",
      //     icon: GiTeacher,
      //     structure: "layout",
      //     exact: true,
      //     isShowLink: false,
      //   },
      // },
    ],
  },
  {
    name: "Employees",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: HiOutlineUsers,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "Teacher view",
        path: "/teacher-view/:id",
        component: Teachersview,
        config: {
          key: "user_view",
          icon: GiTeacher,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Teacher edit",
        path: "/teacher-edit/:id",
        component: TeachersEdit,
        config: {
          key: "user_update",
          icon: GiTeacher,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Update teacher",
        path: "/update_teacher/:user_id",
        component: NewUpdateTeacher,
        config: {
          key: "user_update",
          icon: GiTeacher,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Teacher create",
        path: "/teacher_create",
        component: NewCreateTeacher,
        config: {
          key: "user_create",
          icon: GiTeacher,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Employees base",
        path: "/employees_index",
        component: Employees,
        config: {
          key: "user_index",
          icon: FaUsers,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Teacher base",
        path: "/teachers_index",
        component: Teachers,
        config: {
          key: "user_index",
          icon: FaChalkboardTeacher,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      }
    ],
  },
  {
    name: "Educational process",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: MdCastForEducation,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "Education plan",
        path: `/edu_plan_index`,
        component: EduPlan,
        config: {
          key: "edu-plan_index",
          icon: FaPollH,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Edu Plan Create",
        path: `/edu-plan_create`,
        component: CreateEduPlan,
        config: {
          key: "edu-plan_create",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Edu Plan Edit",
        path: `/edu-plan_update/:id`,
        component: EditEduPlan,
        config: {
          key: "edu-plan_update",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Edu Plan View",
        path: `/edu-plan_view/:id`,
        component: EduPlanViewSemestr,
        config: {
          key: "edu-plan_view",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Attach subjects",
        path: `/edu_semestr_attach_subject`,
        component: AttachSubject,
        config: {
          key: "edu-plan_index",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Education Semester",
        path: `/edu_semestr_index`,
        component: EduSemester,
        config: {
          key: "edu-semestr_index",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Edu Semester Edit",
        path: `/edu-semestr_update`,
        component: EduSemestrEdit,
        config: {
          key: "edu-semestr_update",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Edu semester view",
        path: `/edu-semestr_view/:id`,
        component: EduSemesterView,
        config: {
          key: "edu-semestr_view",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Attach subject to edu semester",
        path: `/edu-semestr-subject_create/:id`,
        component: EduSemestrSubjectCreate,
        config: {
          key: "edu-semestr-subject_index",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Time table",
        path: `/time-table_index`,
        component: TimeTable,
        config: {
          key: "time-table_index",
          icon: ImBooks,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Edit Time table",
        path: `/time-table_update/:id`,
        component: EditTimeTable,
        config: {
          key: "time-table_update",
          icon: ImBooks,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Time table by subject",
        path: `/time-table_child`,
        component: TimeTableByChild,
        config: {
          key: "time-table_index",
          icon: ImBooks,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Guruhlar",
        path: `/time_option_index`,
        component: TimeOptions,
        config: {
          key: "time-table_index",
          icon: ImBooks,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Guruhni ko'rish",
        path: `/time_option_view/:id`,
        component: TimeOptionView,
        config: {
          key: "time-table_view",
          icon: ImBooks,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Edu type",
        path: `/edu-type_index`,
        component: EduTypes,
        config: {
          key: "edu-type_index",
          icon: GiBookshelf,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Education form",
        path: `/edu-form_index`,
        component: EduForm,
        config: {
          key: "edu-type_index",
          icon: GiBookshelf,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Education years",
        path: `/edu-year_index`,
        component: EduYear,
        config: {
          key: "edu-year_index",
          icon: FaRegCalendarAlt,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Attach question",
        path: `/attach-questions/:id`,
        component: AttachQuestionsForExam,
        config: {
          key: "exam_update",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Para",
        path: `/para`,
        component: Para,
        config: {
          key: "para_index",
          icon: FaListOl,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Course",
        path: `/courses`,
        component: Course,
        config: {
          key: "course_index",
          icon: FaBook,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Semestr",
        path: `/semestr_index`,
        component: Semestr,
        config: {
          key: "semestr_index",
          icon: FaBook,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Directions",
        path: `/directions`,
        component: Directions,
        config: {
          key: "direction_index",
          icon: FaUserLock,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
    ],
  },
  {
    name: "Subjects info",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: BiBookReader,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "All subjects",
        path: `/subjects`,
        component: Subjects,
        config: {
          key: "subject_index",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Content checking",
        path: `/subjects-content-checking`,
        component: CheckingContentIndex,
        config: {
          key: "teacher-content_index",
          icon: FaCheckDouble,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Subjects",
        path: `/subjects/:subjectId/teachers`,
        component: SubjectTeachers,
        config: {
          key: "teacher-access_index",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Subjects sillabus",
        path: `/subject-sillabus`,
        component: AttachSillabus,
        config: {
          key: "subject_index",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Subject type",
        path: `/subject_types`,
        component: SubjectType,
        config: {
          key: "subject-type_index",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Subject topics",
        path: `/subjects/:subjectId/:teacherId/topics`,
        component: SubjectTopics,
        config: {
          key: "subject-topic_index",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Subject topics create",
        path: `/subjects/:subjectId/:teacherId/topic-create`,
        component: SubjectTopicCreate,
        config: {
          key: "subject-topic_create",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Subject topics",
        path: `/subjects/:subjectId/:teacherId/topic-edit/:id`,
        component: SubjectTopicEdit,
        config: {
          key: "subject-topic_update",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Subject content",
        path: `/subjects/:subjectId/:teacherId/topics/:topicId/contents`,
        component: SubjectContent,
        config: {
          key: "subject-content_index",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Subject content create",
        path: `/subjects/:subjectId/:teacherId/topics/:topicId/content-create`,
        component: SubjectContentCreate,
        config: {
          key: "subject-content_create",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Subject content edit",
        path: `/subjects/:subjectId/:teacherId/topics/:topicId/content-edit/:id`,
        component: SubjectContentEdit,
        config: {
          key: "subject-content_update",
          icon: FaLayerGroup,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Attach subjects to semester",
        path: `/edu_semestr_attach_subject`,
        component: AttachSubject,
        config: {
          key: "edu-plan_index",
          icon: FaListAlt,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Subject category",
        path: `/subject-category_index`,
        component: SubjectCategory,
        config: {
          key: "subject-category_index",
          icon: FaAlignCenter,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
    ],
  },
  {
    name: "Exams system",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: BsCardChecklist,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "All exams",
        path: `/exam_index`,
        component: Exam,
        config: {
          key: "exam_index",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Exam types",
        path: `/exams-type_index`,
        component: ExamTypes,
        config: {
          key: "exams-type_index",
          icon: GiBookshelf,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Exam students",
        path: `/exam-students-index`,
        component: ExamStudentsIndex,
        config: {
          key: "exams-type_index",
          icon: GiBookshelf,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Generate and get secret keys",
        path: `/generate-and-get-secret-keys/:id`,
        component: GetSecretKeys,
        config: {
          key: "exam_generate-passwords",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Exam Create",
        path: `/exam_create`,
        component: CreateExam,
        config: {
          key: "exam_create",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Exam Edit",
        path: `/exam_update/:id`,
        component: EditExam,
        config: {
          key: "exam_update",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Exam Edit",
        path: `/exam_intensiv/:id`,
        component: IntensivCreate,
        config: {
          key: "exam_update",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Exam View",
        path: `/exam_view/:id`,
        component: ExamView,
        config: {
          key: "exam_view",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Student exam",
        path: `/student-exam-index/:exam_id`,
        component: ExamStudents,
        config: {
          key: "exam-student_index",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Student exam",
        path: `/exam-appeal_index/:exam_id`,
        component: AppealStudents,
        config: {
          key: "exam-appeal_index",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Student Exam Answer",
        path: `/student_exam_detail/:exam_student_id`,
        component: ExamStudentAnswer,
        config: {
          key: "exam-student_index",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Check exam window",
        path: `/student-exam-questions/:exam_id`,
        component: StudentPassedExams,
        config: {
          key: "unlock",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Exam Answer Page",
        path: `/student-exam-questions/:exam_id/answer-page/:answer_id`,
        component: ExamAnswerPage,
        config: {
          key: "unlock",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Distribution teacher",
        path: `/exams_for_checking/:subject_id/exam_distribution/:exam_id/:type_exam`,
        component: ExamCheckerTeachers,
        config: {
          key: "unlock",
          icon: FaBook,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Imtihon tekshirish",
        path: `/exam_by_subject`,
        component: TeacherRoleExams,
        config: {
          key: "exam-checking_index",
          icon: FaClipboardCheck,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Appelation",
        path: `/appelation`,
        component: Appelation,
        config: {
          key: "ggg",
          icon: BiMessageAltDetail,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Appelation Checking",
        path: `/appelation_checking/:appeal_id`,
        component: AppelationChecking,
        config: {
          key: "exam-checking_appeal",
          icon: BiMessageAltDetail,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Appelation Checking",
        path: `/appelation_cards/:exam_id`,
        component: AppelationCards,
        config: {
          key: "exam-appeal_index",
          icon: BiMessageAltDetail,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      // Question copy body
      {
        name: "Attach question",
        path: `/create-questions`,
        component: AttachQuestions,
        config: {
          key: "question_create",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Generate and get secret keys",
        path: `/generate-and-get-secret-keys/:id`,
        component: GetSecretKeys,
        config: {
          key: "exam_generate-passwords",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Edit question",
        path: `/edit-questions/:id`,
        component: EditQuestions,
        config: {
          key: "question_update",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Questions",
        path: `/questions`,
        component: Questions,
        config: {
          key: "question_index",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Export questions",
        path: `/questions-to-pdf`,
        component: QuestionsExport,
        config: {
          key: "question_index",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Question view",
        path: `/question-view/:id`,
        component: ViewQuestionPage,
        config: {
          key: "question_view",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Question options",
        path: `/question-options`,
        component: QuestionOption,
        config: {
          key: "question-option_create",
          icon: FaFileSignature,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Question types",
        path: `/question-type_index`,
        component: QuestionTypes,
        config: {
          key: "question-type_index",
          icon: GiBookshelf,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
    ],
  },
  // {
  //   name: "Questions",
  //   path: "/",
  //   component: "",
  //   config: {
  //     key: "",
  //     icon: BsQuestionLg,
  //     structure: "layout",
  //     exact: true,
  //     isShowLink: true,
  //   },
  //   submenu: [
  //     {
  //       name: "Attach question",
  //       path: `/create-questions`,
  //       component: AttachQuestions,
  //       config: {
  //         key: "question_create",
  //         icon: FaFileSignature,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       },
  //     },
  //     {
  //       name: "Generate and get secret keys",
  //       path: `/generate-and-get-secret-keys/:id`,
  //       component: GetSecretKeys,
  //       config: {
  //         key: "exam_generate-passwords",
  //         icon: FaFileSignature,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       },
  //     },
  //     {
  //       name: "Edit question",
  //       path: `/edit-questions/:id`,
  //       component: EditQuestions,
  //       config: {
  //         key: "question_update",
  //         icon: FaFileSignature,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       },
  //     },
  //     {
  //       name: "Questions",
  //       path: `/questions`,
  //       component: Questions,
  //       config: {
  //         key: "question_index",
  //         icon: FaFileSignature,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Export questions",
  //       path: `/questions-to-pdf`,
  //       component: QuestionsExport,
  //       config: {
  //         key: "question_index",
  //         icon: FaFileSignature,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Question view",
  //       path: `/question-view/:id`,
  //       component: ViewQuestionPage,
  //       config: {
  //         key: "question_view",
  //         icon: FaFileSignature,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       },
  //     },
  //     {
  //       name: "Question options",
  //       path: `/question-options`,
  //       component: QuestionOption,
  //       config: {
  //         key: "question-option_create",
  //         icon: FaFileSignature,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       },
  //     },
  //     {
  //       name: "Question types",
  //       path: `/question-type_index`,
  //       component: QuestionTypes,
  //       config: {
  //         key: "question-type_index",
  //         icon: GiBookshelf,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //   ],
  // },
  {
    name: "Students",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: HiOutlineUserGroup,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "Student base",
        path: "/student_index",
        component: Students,
        config: {
          key: "student_index",
          icon: FaUserGraduate,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Yotoqxona uchun",
        path: "/dormitory_students",
        component: DormitoryStudents,
        config: {
          key: "hostel-app_index",
          icon: FaHouseUser,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Student base",
        path: "/student-login-password",
        component: LoginAndPasswordPage,
        config: {
          key: "student_index",
          icon: FaUserGraduate,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Student Create",
        path: "/student_create",
        component: CreateStudent,
        config: {
          key: "student_create",
          icon: FaUserGraduate,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Student edit",
        path: "/student_update/:student_id",
        component: NewEditStudent,
        config: {
          key: "student_update",
          icon: FaUserGraduate,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Student Compare Data",
        path: "/student_compare/:student_id/:pinfl",
        component: CompareStudentData,
        config: {
          key: "student_update",
          icon: FaUserGraduate,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "View student",
        path: "/view_student/:student_id",
        component: NewStudentView,
        config: {
          key: "student_view",
          icon: FaUserGraduate,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "Student view",
        path: "/student_view/:student_id",
        component: StudentView,
        config: {
          key: "student_view",
          icon: FaUserGraduate,
          structure: "layout",
          exact: true,
          isShowLink: false,
        },
      },
      {
        name: "O'quvchi toifasi",
        path: `/student_category`,
        component: StudentCategory,
        config: {
          key: "social-category_create",
          icon: FaDeezer,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
    ],
  },
  {
    name: "Infrastruktura",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: RiBuilding2Line,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "Buildings",
        path: `/buildings`,
        component: Building,
        config: {
          key: "building_index",
          icon: FaBuilding,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Rooms",
        path: `/rooms`,
        component: Room,
        config: {
          key: "room_index",
          icon: FaColumns,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
    ],
  },
  // {
  //   name: "Election",
  //   path: "/",
  //   component: "",
  //   config: {
  //     key: "",
  //     icon: FaVoteYea,
  //     structure: "layout",
  //     exact: true,
  //     isShowLink: true,
  //   },
  //   submenu: [
  //     {
  //       name: "Election",
  //       path: `/election`,
  //       component: ElectionComponent,
  //       config: {
  //         key: "citizenship_index",
  //         icon: FaVoteYea,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Election",
  //       path: `/election-create`,
  //       component: CreateElection,
  //       config: {
  //         key: "citizenship_index",
  //         icon: FaVoteYea,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       },
  //     },
  //     {
  //       name: "Condidates",
  //       path: `/condidates/:electionId`,
  //       component: Contidates,
  //       config: {
  //         key: "election-candidate_index",
  //         icon: FaVoteYea,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       },
  //     },
  //     {
  //       name: "Condidates create",
  //       path: `/condidate_create/:id`,
  //       component: ElectionCondidateCreate,
  //       config: {
  //         key: "election-candidate_index",
  //         icon: FaVoteYea,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       },
  //     }
  //   ]
  // },
  {
    name: "Qoshimcha ma'lumotlar",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: FiDatabase,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      {
        name: "Citizenship",
        path: `/citizenship_index`,
        component: Citizenship,
        config: {
          key: "citizenship_index",
          icon: BiIdCard,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Nationalities",
        path: `/nationality_index`,
        component: Nationality,
        config: {
          key: "nationality_index",
          icon: FaCogs,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Ijtimoiy toifa",
        path: `/social_category`,
        component: SocialCategory,
        config: {
          key: "social-category_index",
          icon: FaUserTag,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Languages",
        path: `/languages`,
        component: Nationalities,
        config: {
          key: "system_language",
          icon: FaGlobe,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Week",
        path: `/week_index`,
        component: Week,
        config: {
          key: "week_index",
          icon: FaCalendarWeek,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Language certificate type",
        path: `/lang_certificate_types`,
        component: LanguageCertificateType,
        config: {
          key: "lang-certificate-type_index",
          icon: IoLanguage, // GrCertificate
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Partners Type",
        path: `/partners_type`,
        component: StudentPartners,
        config: {
          key: "category-of-cohabitant_index",
          icon: BiAddToQueue,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Hudud holati",
        path: `/space_status`,
        component: LivingSpaceStatus,
        config: {
          key: "residence-status_index",
          icon: FaMapMarkerAlt,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Diploma types",
        path: `/diploma-types`,
        component: DiplomaType,
        config: {
          key: "diploma-type_index",
          icon: FaIdCard,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Degrees",
        path: `/degrees`,
        component: Degrees,
        config: {
          key: "degree_index",
          icon: FaUserGraduate,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      // {
      //   name: "Partyalar",
      //   path: `/partiyas-index`,
      //   component: FaUsers,
      //   config: {
      //     key: "partiya_index",
      //     icon: FaUserLock,
      //     structure: "layout",
      //     exact: true,
      //     isShowLink: true,
      //   },
      // },
      {
        name: "Degree infos",
        path: `/degree-info-index`,
        component: DegreeInfos,
        config: {
          key: "degree-info_index",
          icon: FaInfoCircle,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Academic degrees",
        path: `/academic-degree-index`,
        component: AcademicDegrees,
        config: {
          key: "academic-degree_index",
          icon: FaUserLock,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Dormitory Category",
        path: `/dormitory_category`,
        component: DormitoryCategory,
        config: {
          key: "hostel-category-type_index",
          icon: FaUserLock,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Requisite Photo",
        path: '/requisite_upload',
        component: ContractUpload,
        config: {
          key: "tex-contact-file_index",
          icon: FaUserLock,
          exact: true,
          isShowLink: true,
          structure: "layout"
        }
      }
    ],
  },
  // {
  //   name: "Statistics",
  //   path: "/",
  //   component: "",
  //   config: {
  //     key: "",
  //     icon: FaChartBar,
  //     structure: "layout",
  //     exact: true,
  //     isShowLink: true,
  //   },
  //   submenu: [
  //     {
  //       name: "So'rovnoma statistikasi",
  //       path: "/survey-statistic-by-subject",
  //       component: SurveyStatisticBySubject,
  //       config: {
  //         key: "survey-answer_index",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Texnikum",
  //       path: "/statistic",
  //       component: TeachersStatistic,
  //       config: {
  //         key: "statistic_kafedra",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Subject questions",
  //       path: "/statistic-question",
  //       component: QuestionStatistic,
  //       config: {
  //         key: "subject_index",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Savollar kafedra bo'yicha",
  //       path: "/statistic-question-by-lectern",
  //       component: QuestionStatisticByStatus,
  //       config: {
  //         key: "question_index",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Talabalar o'quv reja bo'yicha",
  //       path: "/student-statistic-by-edu-plan",
  //       component: StudentsByEduPlan,
  //       config: {
  //         key: "student_index",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Imtihon statistikasi",
  //       path: "/exam-cheking-statistic",
  //       component: ExamChekingStatistic,
  //       config: {
  //         key: "statistic_checking",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Imtihon taqsimlash",
  //       path: "/exam-smeta-statistic",
  //       component: ExamSmetaStatstic,
  //       config: {
  //         key: "statistic_checking",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Appeal Statist",
  //       path: "/exam-appeal-stat",
  //       component: ExamAppealCheckingStatistic,
  //       config: {
  //         key: "access-control_create-role",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Teacher Content",
  //       path: "/teacher-access-content",
  //       component: TeacherAccessContent,
  //       config: {
  //         key: "access-control_create-role",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     },
  //     {
  //       name: "Exam Student",
  //       path: "/student-exam-index-all",
  //       component: ExamStudentsAll,
  //       config: {
  //         key: "access-control_create-role",
  //         icon: FaChartLine,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     }
  //   ],
  // },
  {
    name: "Others",
    path: "/",
    component: "",
    config: {
      key: "",
      icon: FaPlus,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      //     {
      //       name: "Reserve Room",
      //       path: "/reserved_rooms",
      //       component: ReserveRoom,
      //       config: {
      //         key: "unlock",
      //         icon: FaColumns,
      //         structure: "layout",
      //         exact: true,
      //         isShowLink: true,
      //       },
      //     },
      //     {
      //       name: "Reserve Room Create",
      //       path: `/reserve_create`,
      //       component: CreateReserveRoom,
      //       config: {
      //         key: "unlock",
      //         icon: FaColumns,
      //         structure: "layout",
      //         exact: true,
      //         isShowLink: false,
      //       },
      //     },
      // {
      //   name: "So'rovnoma",
      //   path: `/quiz`,
      //   component: Quiz,
      //   config: {
      //     key: "survey-question_index",
      //     icon: FaClipboardList,
      //     structure: "layout",
      //     exact: true,
      //     isShowLink: true,
      //   },
      // },
    ],
  },
  {
    name: "Documentation",
    path: "/documentation",
    component: Documentation,
    config: {
      key: "document_index",
      icon: FaFileContract,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  // {
  //   name: "Video guide",
  //   path: "/",
  //   component: "",
  //   config: {
  //     key: "unlock",
  //     icon: FaPhotoVideo,
  //     structure: "layout",
  //     exact: true,
  //     isShowLink: true,
  //   },
  //   submenu: [
  //     {
  //       name: "Video guide",
  //       path: `/video-guide`,
  //       component: VideoGuide,
  //       config: {
  //         key: "unlock",
  //         icon: FaPhotoVideo,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       },
  //     }
  //   ],
  // },
  // {
  //   name: "Tester",
  //   path: "/tester",
  //   component: Tester,
  //   config: {
  //     key: "unlock",
  //     icon: FaHotjar,
  //     structure: "layout",
  //     exact: true,
  //     isShowLink: true,
  //   },
  //   submenu: [],
  // },
  // {
  //   name: "Chat",
  //   path: "/chat",
  //   component: ChatContainer,
  //   config: {
  //     key: "unlock",
  //     icon: FaHotjar,
  //     structure: "layout",
  //     exact: true,
  //     isShowLink: false,
  //   },
  //   submenu: [],
  // },
  {
    name: "Message",
    path: "/message",
    component: Message,
    config: {
      key: "unlock",
      icon: FaHotjar,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Note",
    path: "/note",
    component: Note,
    config: {
      key: "unlock",
      icon: FaHotjar,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Setting",
    path: "/setting",
    component: InProgressPage,
    config: {
      key: "unlock",
      icon: FaHotjar,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  // {
  //   name: "FAQ",
  //   path: "/faq",
  //   component: Documentation,
  //   config: {
  //     key: "unlock",
  //     icon: FaFileContract,
  //     structure: "layout",
  //     exact: true,
  //     isShowLink: true,
  //   },
  //   submenu: [],
  // },
  // {
  //   name: "Teacher KPI",
  //   path: "",
  //   component: "",
  //   config: {
  //     key: "",
  //     icon: FaChartBar,
  //     structure: "layout",
  //     exact: true,
  //     isShowLink: true,
  //   },
  //   submenu: [
  //     {
  //       name: "Teacher KPI",
  //       path: "/teacher_kpi/:user_id",
  //       component: TeacherKPI,
  //       config: {
  //         key: "kpi-data_index",
  //         icon: FaChartBar,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: true,
  //       }
  //     },
  //     {
  //       name: "Teacher KPI",
  //       path: "/teacher_kpi_create",
  //       component: TeacherKPICreateUpdate,
  //       config: {
  //         key: "kpi-data_create",
  //         icon: FaChartBar,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       }
  //     },
  //     {
  //       name: "KPI Category",
  //       path: "/kpi_category",
  //       component: KPICategoryies,
  //       config: {
  //         key: "unlock",
  //         icon: FaChartBar,
  //         structure: "layout",
  //         exact: true,
  //         isShowLink: false,
  //       }
  //     },
  //   ],
  // },
];
