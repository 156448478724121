import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, message, Row } from "antd";
import moment from 'moment';
import instance from "config/_axios";
import { useLocation, useHistory } from 'react-router-dom'
import CustomBreadcrumb from 'components/Breadcrumb';
import { asyncN } from "utils/notifications";
import { teacherAccessType } from "./types";
import isHasAuthRoute from "utils/isHasRoute";
import { getTeacherAccessByUserId } from "../../user/servise";
import { FILE_URL } from 'config/utils';
import { useTranslation } from 'react-i18next';
import FeildsUi from './ui';
import { ServerErrors } from 'utils/validationFunctions';

const layout = {
  labelCol: { offset: 0, span: 24 },
  wrapperCol: { offset: 0, span: 24 },
};

const _breadcrump = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "O'qituvchilar bazasi",
    path: "teachers_index",
  },
  {
    name: "Teacher edit",
    path: "teacher_edit",
  },
];

const TeachersEdit = () => {


  const { t } = useTranslation();
  const [form] = Form.useForm();
  const path = useLocation().pathname;
  let a: Array<string> = path?.split("/");
  const ref: any = useRef();
  const [avatar, setAvatar] = useState<any>(null);
  const dateFormat = 'YYYY-MM-DD';
  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const history = useHistory()
  const [fileList, setFileList] = useState<any>([]);
  const [teacher_access_list, setTeacherAccessList] = useState({});
  const [accesses, setAccesses] = useState<Array<string>>([]);
  const [addressCheck, setAddressCheck] = useState<{ region: boolean, area: boolean, regionP: boolean, areaP: boolean, address: boolean, addressP: boolean }>({
    region: false, area: false, regionP: false, areaP: false, address: false, addressP: false
  })
  const [responseError, setResponseError] = useState<string>("-1");
  const [roles, setRoles] = useState<Array<string>>([])
  const [disabled, setDissabled] = useState<boolean>(false)
  let _locationTabError: any = {
    address: "3",
    area_id: "3",
    country_id: "3",
    dob: "1",
    email: "1",
    first_name: "1",
    gender: "1",
    last_name: "1",
    middle_name: "1",
    passport_date: "2",
    passport_file: "2",
    passport_given_by: "2",
    passport_pin: "2",
    passport_seria_and_number: "2",
    permanent_address: "3",
    permanent_area_id: "3",
    permanent_country_id: "3",
    permanent_region_id: "3",
    phone: "1",
    phone_secondary: "1",
    region_id: "3",
    role: "1",
    status: "1",
    username: "1"
  }

  useEffect(() => {
    (
      async () => {

        try {
          setLoading(true)
          const res = await instance({
            method: 'get',
            url: '/users/' + a[a.length - 1] + "?expand=profile,teacherAccess,department,userAccess"
          })
          if (res?.data?.status) {
            let data = res.data.data;
            setFirstName(data?.profile?.first_name);
            setAvatar(data?.profile?.image && FILE_URL + data?.profile?.image);
            data?.profile?.passport_file && setFileList([{
              uid: '-1',
              name: 'passport file',
              status: 'done',
              url: FILE_URL + data?.profile?.passport_file,
              thumbUrl: "https://www.seaicons.com/wp-content/uploads/2015/11/document-file-icon.png"
            }]);
            if (data?.userAccess) {
              let acc: any = {};
              data?.userAccess?.map((item: any) => {
                if (acc[`${item?.user_access_type_id}`]?.length > 0) {
                  acc = { ...acc, [`${item?.user_access_type_id}`]: [...acc[`${item?.user_access_type_id}`], `${item?.table_id}-${item?.is_leader}`] }
                } else {
                  acc = { ...acc, [`${item?.user_access_type_id}`]: [`${item?.table_id}-${item?.is_leader}`] }
                }
              })
              setAccesses(acc)

            }
            setRoles(data?.role)
            form.setFieldsValue({
              address: data?.profile?.address,
              area_id: data?.profile?.area_id,
              birthday: data?.profile?.birthday ? moment(data?.profile?.birthday) : "",
              country_id: data?.profile?.country_id,
              passport_given_by: data?.profile?.passport_given_by,
              passport_number: data?.profile?.passport_number,
              passport_pin: data?.profile?.passport_pin,
              permanent_address: data?.profile?.permanent_address,
              permanent_area_id: data?.profile?.permanent_area_id,
              permanent_country_id: data?.profile?.permanent_country_id,
              permanent_region_id: data?.profile?.permanent_region_id,
              region_id: data?.profile?.region_id,
              phone_secondary: data?.profile?.phone_secondary,
              email: data?.email,
              first_name: data?.profile?.first_name,
              gender: data?.profile?.gender,
              last_name: data?.profile?.last_name,
              middle_name: data?.profile?.middle_name,
              phone: data?.profile?.phone,
              partiya_id: data?.profile?.partiya_id,
              degree_info_id: data?.profile?.degree_info_id,
              academic_degree_id: data?.profile?.academic_degree_id,
              degree_id: data?.profile?.degree_id,
              diploma_type_id: data?.profile?.diploma_type_id,
              sort: data?.sort,
              status: data?.status,
              username: data?.username,
              passport_given_date: data?.profile?.passport_given_date && moment(data?.profile?.passport_given_date),
              passport_issued_date: data?.profile?.passport_issued_date && moment(data?.profile?.passport_issued_date),
              passport_seria_and_number: data?.profile?.passport_seria && data?.profile?.passport_number && data?.profile?.passport_seria + ' ' + data?.profile?.passport_number,
              passport_file: data.profile?.passport_file,
            });

            if (ref?.current) {
              ref?.current?.getRegionsE(data?.profile?.country_id);
              ref?.current?.getRegionsPE(data?.profile?.permanent_country_id);
              ref?.current?.getAreasE(data?.profile?.region_id);
              ref?.current?.getAreasPE(data?.profile?.permanent_region_id);
            }
            setLoading(false);
          } else {
            asyncN("error", "read", res?.data?.message);
            setLoading(false)
          }
        } catch (e: any) {
          asyncN('error', "read", e?.response?.data?.message)
          setLoading(false)
        }
      }
    )();


  }, []);

  useEffect(() => {
    (
      async () => {

        const responseAc = await getTeacherAccessByUserId(Number(a[a?.length - 1]))
        const items: any = responseAc?.data?.items;
        let access: any = {};
        items.map((item: teacherAccessType) => {
          if (access[`${item?.subject_id}`])
            access = { ...access, [`${item?.subject_id}`]: [...access[`${item?.subject_id}`], item?.language_id] };
          else
            access = { ...access, [`${item?.subject_id}`]: [item?.language_id] }
        });
        setTeacherAccessList(access);

      }
    )()
  }, [])

  const onFinish = (values: any) => {
    let data = new FormData();
    setDissabled(true);
    setLoading(true)
    for (const [key, value] of Object.entries<any>(values)) {
      if (value) {
        if (key === "birthday") {
          data.append("birthday", moment(value._d).format(dateFormat));
        } else if (key === "subject") {

        } else if (key === "passport_file") {
          if (value?.file?.originFileObj) {
            data.append(key, value?.file?.originFileObj)
          }
        } else if (["passport_given_date", "passport_issued_date"].includes(key)) {
          data.append(key, moment(value).format(dateFormat));
        } else if (key === "passport_seria_and_number") {
          let strings = value.split(" ");
          data.append("passport_seria", strings[0]);
          data.append("passport_number", strings[1])
        } else if (key === "faculty_id") {

        } else if (key === "is_leader") {

        } else {
          data.append(key, value);
        }
      }
    }
    data.append("user_access", JSON.stringify(accesses));
    data.append("role", `${roles?.length > 1 ? JSON.stringify(roles) : '["' + roles[0] + '"]'}`);
    data.append("teacherAccess", JSON.stringify(teacher_access_list));
    if (avatar) data.append("avatar", avatar);
    if (Object.keys(teacher_access_list)?.length > 0 && Object.keys(accesses).length > 0) {
      instance({
        method: 'put',
        url: '/users/' + a[a?.length - 1],
        data: data
      }).then((r) => {
        if (r.data.status === 1) {
          setDissabled(false)
          setLoading(false)
          message.success(r?.data?.message);
          history.push("/teachers_index")
        } else {
          setDissabled(false)
          setLoading(false)
          asyncN("error", "update", r.data?.message)
        }
      }).catch((e) => {
        setDissabled(false)
        setLoading(false)
        if (e?.response?.status === 422) {
          if (e?.response?.data?.status === 0) {
            let activeKey: string = ""
            form.setFields(ServerErrors(e?.response?.data?.errors[0]));
            Object.entries(e?.response?.data?.errors[0]).map(([key, value]: any) => {
              if (activeKey === "") {
                activeKey = _locationTabError[`${key}`]
              }
            })
            setResponseError(activeKey)
          }
          message.error("Yuborishda xatolik iltimos maydonlarni tekshirib qayta yuboring!")
        }
        message.error(e?.response ? e?.response?.data?.message : e?.message)
      })
    } else {
      setDissabled(false)
      setLoading(false)
      if (Object.keys(accesses).length === 0) {
        message.error("O'qituchi uchun Texnikum va kafedra tanlang!")
      } else if (Object.keys(teacher_access_list).length === 0) {
        message.error("O'qituchi uchun fan va tillarni tanlang!")
      } else {
        message.error("O'qituvchi uchun facultet, kafedra fan, va fan uchun tillarni tanlang!")
      }
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <CustomBreadcrumb arr={_breadcrump} />
        </Col>
      </Row>
      {loading ? (
        <div className="loader-line"></div>
      ) : (
        <div style={{ height: "3px" }}></div>
      )}
      <Form {...layout}
        form={form}
        layout="vertical"
        name="advanced_search"
        className="mt-3 text-start"
        style={{ height: "100%" }}
        onFinish={onFinish}>
        <FeildsUi
          firstname={firstName}
          avatar={avatar}
          teacher_access_list={teacher_access_list}
          setTeacherAccessList={setTeacherAccessList}
          addressCheck={addressCheck}
          setAddressCheck={setAddressCheck}
          form={form}
          responseError={responseError}
          setResponseError={setResponseError}
          accesses={accesses}
          disabledButton={disabled}
          setAccesses={setAccesses}
          edit
          files={fileList}
          ref={ref} />
      </Form>
    </div>
  )
};

export default TeachersEdit
