import { ChangeEvent, useEffect, useState } from "react";
import instance from "../../config/_axios";
import { RoleAndPermissionType } from "./components/types";
import { Table, Tbody, Td, Tr, Thead, Th } from 'react-super-responsive-table'
import { Col, Input, Row, Button, Popconfirm } from "antd";
import { useHistory } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { Tooltip } from "antd"
import isHasAuthRoute from "utils/isHasRoute";
import ViewRole from "./components/view";
import { _meta } from './components/types'
import {EmptyTable} from "components/Empty";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import useDebounce from "hooks/useDebounce";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import CustomPagination from "components/Pagination";
import ForTitle from "components/ForTitles";
import ForTable from "components/ForTable";
import { ButtonCreate } from "components/Buttons";

const { Search } = Input;

const Roles = () => {
    const { t } = useTranslation();
    const [roleName, setRoleName] = useState<string>();
    const [visible, setVisible] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const history = useHistory();
    const {value, writeToUrl} = useUrlQueryParams({});

    const debounceSearch = useDebounce(value?.filter_like?.search, 500);

    const {items: roles, loading, data} = useGetAllData({url: `/roles?expand=permissions,description&per-page=${value.perPage || 0}&page=${value.currentPage || 15}&q=${debounceSearch}`, isCall: "auto", refetch: [value.currentPage, value.perPage, debounceSearch]}) as TypeReturnUseGetAllData<RoleAndPermissionType>

    useEffect(() => {
        if(value?.filter_like?.search){
            writeToUrl({name: "currentPage", value: 1})
        }
    },[debounceSearch])

    const deleteRole = async (name: string) => {
        try {
            const response = await instance({
                method: "delete",
                url: "/roles/" + name
            });
            if (response.status === 204) {
                asyncN("success", "delete", response?.data?.data?.message);
                setRefresh(!refresh)
            }
        } catch (e: any) {
            asyncN("error", "delete", e?.response?.data?.message)
        }
    };    

    return (
        <>
            <div>
                <ForTitle title="Roles" buttons={[
                    isHasAuthRoute("access-control_create-role") ?
                    <ButtonCreate
                                onClick={() => history.push("/add-role")}>{t("Add")}</ButtonCreate>
                                : null
                ]}/>
                <div className="mb-4">
                    <div className="text-end my-2">
                        <Search allowClear placeholder={t("Input search text ...")} value={value.filter_like.search}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {writeToUrl({name: "search", value: e.target.value}) }}

                            style={{ width: 320 }} />
                    </div>
                </div>
                <ForTable loading={loading} datas={roles}
                    toUrl={true} theads={[
                        <>{t("Name")}</>,
                        <>{t("Description")}</>,
                        <>{t("Actions")}</>,
                    ]} tbodys={function(this: any){
                        return [
                            <>{this?.name}</>,
                            <>{this?.pretty_name}</>,
                            <>
                                <div className="crud_actions_table w_160">
                                    {isHasAuthRoute("access-control_role-permissions") &&
                                        <Tooltip placement="topLeft" title={t("View")}><FaEye className="view_btn"
                                            onClick={() => {
                                                setRoleName(this.name);
                                                setVisible(true)
                                            }} /></Tooltip>}
                                    {isHasAuthRoute("access-control_update-role") &&
                                        <Tooltip placement="topLeft" title={t("Edit")}><FaRegEdit className="edit_btn"
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/edit-role/" + this?.name
                                                })
                                            }} /></Tooltip>}
                                    {isHasAuthRoute("access-control_delete-role") &&
                                        <Tooltip placement="topLeft" title={t("Delete")}>
                                            <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`} okText={t("Yes")}
                                                cancelText={t("No")} onConfirm={() => {
                                                    deleteRole(this.name)
                                                }}>
                                                <FaTrashAlt className="delete_btn" />
                                            </Popconfirm>
                                        </Tooltip>}
                                </div>
                            </>
                        ]
                    }} totalCount={data._meta.totalCount} currentPage={value.currentPage} perPage={value.perPage} setCurrentPage={() => {}} setPerPage={() => {}}
                />
                <ViewRole visible={visible} roleName={roleName} setVisible={setVisible} />
            </div>
        </>
    )
};

export default Roles
