import { Form, Select, Button, Modal, Row, Col, Popconfirm, Switch } from "antd";
import FormLabel from "components/FormLabel";
import instance from "config/_axios";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { IEducationPlan, IEducationSemester, IPara } from "models/education";
import { ISubject, ISubjectCategory } from "models/subject";
import React, { useEffect } from "react";
import { BiX } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "store/services";
import { TIMETABLE_ACTIONS } from "store/time_table";
import { asyncN } from "utils/notifications";
import styles from '../styles/styles.module.scss';
import useGetOneData, { ReturnTypeUseGetOneData } from "hooks/useGetOneData";
import { ILanguage } from "models/language";
import { IBuilding, IRoom, ITimeTable, IWeek } from "models/others";
import useDeleteOne from "hooks/useDeleteOne";
import isHasAuthRoute from "utils/isHasRoute";
import { cf_filterOption } from "utils/auxiliary_functions";

const { Option } = Select;



const UpdateTimeTable: React.FC<{ setRefetch: React.Dispatch<React.SetStateAction<boolean>>, deleteTimeTable: (id: number | null | undefined) => void }> = ({ setRefetch, deleteTimeTable }): JSX.Element => {

  const [form] = Form.useForm();
  const dispatch: any = useAppDispatch();
  const _delete = useDeleteOne();
  const { selected } = useAppSelector(state => state.time_table);
  const { value } = useUrlQueryParams({});
  const { visible } = useAppSelector(state => state.time_table);
  const [rooms, setRooms] = React.useState<IRoom[]>([])
  const [status, setStatus] = React.useState<boolean>(true);

  const timeTable = useGetOneData({}) as ReturnTypeUseGetOneData<ITimeTable>

  const buildings = useGetAllData({ url: `/buildings`, perPage: 0 }) as TypeReturnUseGetAllData<IBuilding>;
  const edu_plans = useGetAllData({ url: `/edu-plans`, perPage: 0, isCall: "auto" }) as TypeReturnUseGetAllData<IEducationPlan>;
  const weeks = useGetAllData({ url: `/weeks`, perPage: 0, isCall: "auto" }) as TypeReturnUseGetAllData<IWeek>;
  const paras = useGetAllData({ url: `/paras`, perPage: 0, isCall: "auto" }) as TypeReturnUseGetAllData<IPara>;
  const languages = useGetAllData({ url: `/languages`, perPage: 0 }) as TypeReturnUseGetAllData<ILanguage>;
  const edu_semesters = useGetAllData({ url: `/edu-semestrs?filter=${JSON.stringify({ edu_plan_id: form.getFieldValue('edu_plan_id') })}`, perPage: 0 }) as TypeReturnUseGetAllData<IEducationSemester>;
  const subjects = useGetAllData({ url: `/subjects?filter=${JSON.stringify({ edu_semestr_id: form.getFieldValue('edu_semester_id') })}` }) as TypeReturnUseGetAllData<ISubject>;
  const subject_categories = useGetAllData({ url: `/subject-categories`, perPage: 0 }) as TypeReturnUseGetAllData<ISubjectCategory>;
  const teacher_access = useGetAllData({
    url: `/teacher-accesses/free?filter=${JSON.stringify({ language_id: form.getFieldValue('language_id'), subject_id: form.getFieldValue('subject_id') })}`,
    params: {
      'per-page': 0,
      para_id: selected?.para_id,
      week_id: selected?.week_id,
      semester_id: edu_semesters.items.find(e => e.id === Number(form.getFieldValue('edu_semester_id')))?.semestr_id,
      edu_year_id: timeTable.data?.edu_year_id,
      subject_category_id: form.getFieldValue('subject_category_id')
    }
  });
  const getRooms = async () => {
    const formdata = new FormData();
    formdata.append('para_id', String(selected?.para_id));
    formdata.append('edu_year_id', String(edu_semesters.items.find(e => e.id === form.getFieldValue("edu_semester_id"))?.edu_year_id));
    formdata.append('week_id', String(selected?.week_id));
    formdata.append('semester_id', String(edu_semesters.items.find(e => e.id === form.getFieldValue("edu_semester_id"))?.semestr_id));
    const response = await instance({ url: `/rooms/free?sort=-id&filter=${JSON.stringify({ building_id: form.getFieldValue("building_id") })}`, method: 'POST', data: formdata, params: { 'per-page': 0 } });

    if (response.data?.status === 1) {
      setRooms(response.data?.data?.items);
    }
  }


  useEffect(() => {
    if (visible === 'update') {
      timeTable.fetch(`/time-tables/${selected?.id}?sort=-id&expand=subject,teacher,subjectCategory,eduSemestr, subject,language,building,room&filter=${JSON.stringify({ room_id: value.filter?.room_id })}`);
    }
  }, [visible])

  useEffect(() => {
    if (timeTable.data?.id) {
      const { subject, language, teacher, subjectCategory, eduSemestr, subject_id, language_id, week_id, para_id, teacher_access_id, subject_category_id, edu_semester_id, building, room_id } = timeTable.data;
      if (eduSemestr) {
        edu_semesters.setState(eduSemestr);
      }
      form.setFieldsValue({ edu_semester_id: edu_semester_id, edu_plan_id: timeTable.data.edu_plan_id })
      if (subject) {
        subjects.setState(subject);
      }
      form.setFieldsValue({ subject_id: subject_id })
      if (subjectCategory) {
        subject_categories.setState(subjectCategory);
      }
      form.setFieldsValue({ subject_category_id: subject_category_id })
      if (building) {
        buildings.setState(building);
      }
      form.setFieldsValue({ building_id: building?.id })
      form.setFieldsValue({ room_id })
      teacher_access.setState({ id: teacher_access_id, teacher: teacher });
      form.setFieldsValue({ teacher_access_id: timeTable.data?.teacher_access_id })
      languages.setState({ id: language_id, ...language });
      form.setFieldsValue({ language_id: language_id })
      form.setFieldsValue({ para_id: para_id })
      form.setFieldsValue({ week_id: week_id })
    }
  }, [timeTable.data])


  const onSubmit = async () => {
    try {

      const formdata = new FormData();
      const values = form.getFieldsValue();

      // formdata.append('para_id', String(selected?.para_id));
      // formdata.append('week_id', String(selected?.week_id));
      formdata.append('status', status ? '1' : '0');

      for (const [key, value] of Object.entries(values)) {
        if (key !== 'edu_plan_id') {
          formdata.append(key, String(value));
        }
      }

      const response = await instance({ url: `/time-tables/${selected?.id}`, method: "PUT", data: formdata });

      if (response.data.status === 1) {
        asyncN("success", 'update', response.data?.message).then(() => {
          form.resetFields();
          setRefetch(prevState => !prevState);
          dispatch(TIMETABLE_ACTIONS.closeModal());
        })
      } else {
        asyncN("error", 'update', response.data?.message).then(() => form.resetFields())
      }
    } catch (error) { }
  };


  return (
    <Modal
      title={<span className={styles.modal_title}> <span>TAHRIRLASH</span> {selected?.week?.name} / {selected?.para?.start_time}-{selected?.para?.end_time}</span>}
      centered
      visible={visible === 'update'}
      onCancel={() => { dispatch(TIMETABLE_ACTIONS.closeModal()); form.resetFields() }}
      footer={null}
      closeIcon={<BiX size={20} />}
      width={1000}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={[12, 0]} >
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Ta'lim rejasi</FormLabel>}
              name="edu_plan_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                onFocus={() => { if (!edu_plans.items.length) { edu_plans.fetch() } }}
                onChange={() => { form.setFieldsValue({ edu_semester_id: undefined, room_id: undefined, subject_id: undefined, subject_category_id: undefined, language_id: undefined, teacher_access_id: undefined }) }}
                loading={edu_plans.loading}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  edu_plans.items.length ? edu_plans.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Ta'lim semester</FormLabel>}
              name="edu_semester_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                onFocus={() => edu_semesters.fetch()}
                onChange={() => { form.setFieldsValue({ room_id: undefined, subject_id: undefined, subject_category_id: undefined, language_id: undefined, teacher_access_id: undefined }) }}
                loading={edu_semesters.loading}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  edu_semesters.items.length ?
                    edu_semesters.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Hafta kuni</FormLabel>}
              name="week_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                onFocus={() => { if (!weeks.items.length) { weeks.fetch() } }}
                loading={weeks.loading}
              >
                {
                  weeks.items.length ? weeks.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Akademik soat</FormLabel>}
              name="para_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                onFocus={() => { if (!paras.items.length) { paras.fetch() } }}
                loading={paras.loading}
              >
                {
                  paras.items.length ? paras.items.map((element) => <Option key={element.id} value={element?.id} >{element?.start_time}-{element.end_time}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>O'quv binosi</FormLabel>}
              name="building_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                onFocus={() => buildings.fetch()}
                onChange={() => { form.setFieldsValue({ room_id: undefined }) }}
                loading={buildings.loading}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  buildings.items.length ? buildings.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>O'quv xonasi</FormLabel>}
              name="room_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                onFocus={() => { getRooms() }}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  rooms.length ?
                    rooms.map((element) => <Option key={element.id} value={element?.id} >{element?.name}-xona. &nbsp;&nbsp;&nbsp;<span style={{ color: 'red' }}>Sig'imi: {element.capacity}</span></Option>) :
                    [timeTable.data?.room].map((element) => <Option key={element?.id} value={element?.id} >{element?.name}-xona. &nbsp;&nbsp;&nbsp;<span style={{ color: 'red' }}>Sig'imi: {element?.capacity}</span></Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Fanlar</FormLabel>}
              name="subject_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                onFocus={() => subjects.fetch()}
                onChange={() => { form.setFieldsValue({ subject_category_id: undefined, language_id: undefined, teacher_access_id: undefined }) }}
                loading={subjects.loading}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  subjects.items.length ? subjects.items.map((element) => <Option key={element?.id} value={element?.id}>{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Mashg'ulot toifasi</FormLabel>}
              name="subject_category_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                onFocus={() => subject_categories.fetch()}
                onChange={() => { form.setFieldsValue({ language_id: undefined, teacher_access_id: undefined }) }}
                loading={subject_categories.loading}
              >
                {
                  subject_categories.items.length ? subject_categories.items.map((element) => <Option key={element.id} value={element?.id}>{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Tillar</FormLabel>}
              name="language_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                loading={languages.loading}
                onFocus={() => languages.fetch()}
                onChange={() => { form.setFieldsValue({ teacher_access_id: undefined }) }}
              >
                {
                  languages.items.length ? languages.items.map((element) => <Option key={element.id} value={element?.id}>{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name='teacher_access_id'
              label={<FormLabel>O'qituvchi</FormLabel>}
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                onFocus={() => teacher_access.fetch()}
                loading={teacher_access.loading}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  teacher_access.items.length ? teacher_access.items.map((element) => <Option key={element.id} value={element?.id}>{element.teacher?.last_name}&nbsp;{element.teacher?.first_name}&nbsp;{element.teacher?.middle_name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} className="mt-2">
            <Row gutter={[12, 12]}>
              <Col lg={{ span: 8 }}>
                <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
              </Col>
              <Col lg={{ span: 16 }} className="text-end">
                <Popconfirm placement="top" title={"O'chirilsinmi ?"} onConfirm={() => deleteTimeTable(selected?.id)} okText="Ha" cancelText="Yo'q">
                  <Button type="primary" className="min_w_100" danger disabled={!isHasAuthRoute('time-table_delete')} >O'chirish</Button>
                </Popconfirm>
                <Button type="primary" className="mx-2 min_w_100" onClick={() => form.resetFields()} >Reset</Button>
                <Button type="primary" className="min_w_100" disabled={!isHasAuthRoute('time-table_update')} onClick={onSubmit} htmlType='submit' >Saqlash</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )

};

export default React.memo(UpdateTimeTable);