import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Input, Col, Button, Switch, Select } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getOneLectern } from '../service';
import { getAllFaculties } from '../../faculty/service';
import { getAllDirections } from '../../directions/service';
import { getAllUsers } from 'pages/user/servise';


type EditLecternType = {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setReRequest: (reRequest: boolean) => void,
    id: number | null,
    reRequest: boolean
}

const { Option } = Select;
const { TextArea } = Input;

const EditLectern: FC<EditLecternType> = ({ visible, setVisible, id, setReRequest, reRequest }) => {

    const { i18n, t } = useTranslation();
    const [form] = Form.useForm();
    const [faculties, setFaculties] = useState<Array<any>>([]);
    const [directions, setDirections] = useState<Array<any>>([]);
    const [status, setStatus] = useState<boolean>(true);
    const [clicked, setClicked] = useState<boolean>(false);
    const [users, setUsers] = useState<Array<{ id: number, first_name: string, last_name: string, role: Array<string> }>>([]);
    const [userAccesses, setUserAccesses] = useState<Array<{ id: number, is_leader: number, user_id: number }>>([]);

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOneLectern(id, 'expand=description,userAccess&self=1');
                        form.setFieldsValue({
                            name: response?.name,
                            description: response?.description,
                            faculty_id: response?.faculty_id,
                            direction_id: response?.direction_id,
                            status: Number(response?.status) === 1 ? setStatus(true) : setStatus(false)
                        })
                        setUserAccesses(response?.userAccess)
                        if(Array.isArray(response?.userAccess)){
                            response?.userAccess?.map((item:{ id: number, is_leader: number, user_id: number })=>{
                                if(item?.is_leader===1){
                                    form.setFieldsValue({
                                        user_id:item?.user_id
                                    })
                                }
                            })
                        }
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    const response = await getAllFaculties();
                    setFaculties(response?.items)
                }
            )()
        } else {
            setFaculties([])
        }
    }, [visible])

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    const response = await getAllDirections();
                    setDirections(response?.items)
                }
            )()
        } else {
            setDirections([])
        }
    }, [visible])

    const getUserName=(id:number):string=>{
        let a:{ id: number, first_name: string, last_name: string, role: Array<string> }=users?.filter((item:{ id: number, first_name: string, last_name: string, role: Array<string> })=>item?.id===id)[0]
        return a?.first_name+" "+a?.last_name
    }

    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            formdata.append(`name[${i18n.language}]`, values?.name)
            formdata.append(`description[${i18n.language}]`, values?.description)
            formdata.append('faculty_id', values?.faculty_id)
            formdata.append('direction_id', values?.direction_id)
            formdata.append('user_id',values?.user_id)
            formdata.append('status', status ? '1' : '0');

            const response = await instance({ url: `/kafedras/${id}?expand=userAccess`, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)
                    })

            } else {
                asyncN("error", 'update', response.data?.message).then(() => {
                    setVisible(false);
                    resetFields()
                    setReRequest(!reRequest)
                })
            }
            setClicked(false)
        } catch (error) {
            setClicked(false)
            setReRequest(!reRequest)
        }
    }

    useEffect(() => {
        (
            async () => {
                const response = await getAllUsers(`filter={"-role_name":["student","test"]}&per-page=0&page=1`);
                setUsers(response?.data?.items)
            }
        )()
    }, [])

    const resetFields = () => form.resetFields();

    return (
        <>
            <Modal
                title={t("Lectern edit")}
                visible={visible}
                onCancel={() => { setVisible(false); resetFields() }}
                okText="Submit"
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Form.Item
                        label={t("Name")}
                        name="name"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Input placeholder={t("Enter lectern name")} />
                    </Form.Item>
                    <Form.Item
                        label={t("Description")}
                        name="description"
                        shouldUpdate
                        rules={[{ required: false }]}
                    >
                        <TextArea rows={2} placeholder={t("Enter description for lectern")} />
                    </Form.Item>
                    <Form.Item
                        label={t("Texnikum")}
                        name="faculty_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select
                            showSearch
                            placeholder={t("Select a faculty")}
                            optionFilterProp="children"
                            filterOption={(input, option:any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                faculties.length ? faculties.map((e: any, i: number) => {
                                    return <Option key={i} value={e.id}>{e.name}</Option>
                                }) : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t("Direction")}
                        name="direction_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select
                            showSearch
                            placeholder={t("Select a direction")}
                            optionFilterProp="children"
                            filterOption={(input, option:any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                directions.length ? directions.map((e: any, i: number) => {
                                    return <Option key={i} value={e.id}>{e.name}</Option>
                                }) : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t("Lectern leader")}
                        name="user_id"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Select
                            showSearch
                            placeholder={t("Select a lectern")}
                            optionFilterProp="children"
                            filterOption={(input, option:any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                userAccesses.length ? userAccesses.map((e: { id: number, is_leader: number, user_id: number }, i: number) => {
                                    return <Option key={i} value={e.user_id}>{getUserName(e.user_id)}</Option>
                                }) : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t("Status")}
                        name="status"
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                    <hr />
                    <Col span={24} className="text-end mt-2">
                        <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </Col>
                </Form>
            </Modal>
        </>
    )
}


export default EditLectern;