import { FC } from "react";
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from "react-router-dom";




const PageContentTitle: FC<{ title: string }> = ({ title }): JSX.Element => {

    const history = useHistory();

    return (
        <>
            <div className="pageContentTitle">
                <FaArrowLeft onClick={() => history.goBack()} fontSize={16} />
                <span>{title}</span>
            </div>
        </>
    )
}

export default PageContentTitle;