import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Form, Input, Row, Col, Select, Button, Switch, Modal } from 'antd';
import { FaTimes } from 'react-icons/fa';
import CustomBreadcrumb from "components/Breadcrumb";
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getAllLanguages } from 'services';
import { ServerErrors } from 'utils/validationFunctions';
import { getAllFaculties } from '../../faculty/service';
import { getAllDirections } from '../../directions/service';
import { ArrayChecker } from 'utils/ArrayChecker';
import MultipleInput from 'components/MultipleInput';
import ModalTitle from 'components/ModalTitle';

const { Option } = Select;

type CreateLecternType = {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setReFetch: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateLectern: FC<CreateLecternType> = ({visible, setVisible, setReFetch}): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [faculties, setFaculties] = useState<Array<any>>([])
    const [directions, setDirections] = useState<Array<any>>([])
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true)

    useEffect(() => {
        if(visible){
            (
                async () => {
                    const response = await getAllFaculties();
                    setFaculties(response?.items)
                }
            )()
        }
    }, [visible])

    const _getDirections = async () => {
        if (!directions.length) {
            const response = await getAllDirections();
            setDirections(response?.items)
        }
    }

    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            for (const [key, value] of Object.entries(values)) {
                if (key === 'status') {
                    formdata.append(key, status ? '1' : '0');
                } else {
                    formdata.append(key, String(value));
                }
            }

            const response = await instance({ url: '/kafedras', method: 'POST', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); setVisible(false); setReFetch(prevState => !prevState) })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();

    return (
        <Modal
            title={t("Lectern create")}
            visible={visible}
            onCancel={() => {setVisible(false); resetFields()}}
            width={600}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
            >
                <Row gutter={[12, 0]}>
                    <Col span={24} className="mb-2">
                        <MultipleInput layout='vertical' />
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Texnikum")}
                            name="faculty_id"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Select
                                showSearch
                                placeholder={t("Select a faculty")}
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    faculties.length ? faculties.map((e: any, i: number) => {
                                        return <Option key={i} value={e.id}>{e.name}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Direction")}
                            name="direction_id"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Select
                                showSearch
                                placeholder={t("Select a direction")}
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onFocus={_getDirections}
                            >
                                {
                                    ArrayChecker(directions)._ ? directions.map((e: any, i: number) => {
                                        return <Option key={i} value={e.id}>{e.name}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <hr className='mt-1' />
                <Col span={24} className="mt-2 d-flex justify-content-between aligin-items-center">
                    <Form.Item name="status" className='mb-0'>
                        <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                    <div>
                    <Button type="primary" danger htmlType="button" className="me-2" onClick={() => {setVisible(false); resetFields()}}>{t("Cancel")}</Button>
                    <Button type="primary" htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                    <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </div>
                </Col>
            </Form>
        </Modal>
    )
}


export default CreateLectern;