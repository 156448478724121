import { useEffect, useState } from "react";
import { Col, Form, Input, Row, Card, Select, Button } from "antd";
import { permissions, rolePermission } from "./types";
import { useHistory, useLocation } from "react-router";
import instance from "config/_axios";
import CustomBreadcrumb from "components/Breadcrumb";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";
import { getAllRoles } from "../service";

const layout = {
    labelCol: { offset: 0, span: 24 },
    wrapperCol: { offset: 0, span: 24 },
};

const EditRole = () => {
    const { t } = useTranslation();
    const history = useHistory()
    const [role, setRole] = useState<rolePermission>();
    const state: string = useLocation()?.pathname;
    let a: Array<string> = state.split("/")
    const [permissions, setPermissions] = useState<permissions[]>([]);
    const [selectedP, setSelectedP] = useState<string[]>([]);
    const [form] = Form.useForm();
    const [roles, setRoles] = useState<Array<{ id: number, name: string }>>([]);
    const [children, setChildren] = useState<Array<string>>([])
    const [parents, setParents] = useState<Array<string>>([])

    const _breadcrump = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Roles",
            path: "roles",
        },
        {
            name: "Edit role",
            path: "edit-role",
        },
    ];

    useEffect(() => {
        (
            async () => {
                try {
                    const response = await instance({
                        method: 'get',
                        url: '/roles/' + a[a?.length - 1] + '/permissions?expand=permissions,parent,child'
                    });
                    if (response?.data?.status === 1) {
                        const data = response.data.data;
                        const p: string[] = [];
                        const child: string[] = [];
                        const parent: string[] = [];

                        data?.child?.forEach((item: any) => {
                            child.push(item?.child)
                        })
                        data?.parent?.forEach((item: any) => {
                            parent.push(item?.parent)
                        })

                        data?.permissions?.forEach((item: any) => {
                            p.push(item.name)
                        });
                        setSelectedP([...selectedP, ...p]);

                        form.setFieldsValue({
                            childs: child,
                            parents: parent,
                            name: data?.name,
                            description: data?.description
                        })
                    }
                } catch (e) {
                    console.log(e);
                    asyncN("error", "read", "Xatolik yuz berdi")
                }
            }
        )();
        (
            async () => {
                const response = await instance({
                    method: 'get',
                    url: 'permissions'
                });

                if (response.data.status === 1) {
                    setPermissions(response.data.data);
                }
            }
        )()
    }, [state]);

    const onFinish = async (values: any) => {
        try {
            const response = await instance({
                method: 'put',
                url: '/roles',
                data: [
                    {
                        role: values?.name,
                        description: values?.description,
                        parents: Array.isArray(values?.parents) ? values?.parents : [values?.parents],
                        childs: Array.isArray(values?.childs) ? values?.childs : [values?.childs],
                        permissions: selectedP
                    }
                ]
            });
            if (response.data.status === 1) {
                setSelectedP([]);
                asyncN("success", "update", response?.data?.data?.message);
                history.goBack()
            } else {
                asyncN("error", "update", response?.data?.data?.message)
            }
        } catch (e: any) {
            asyncN("error", "update", e?.response?.data?.message)
        }

    };

    const checkedCategory = (items: { name: string, title: string }[] | null, allItems: permissions[] | null): boolean => {
        if (items !== null) {
            let a = 0;
            selectedP.map((item: string) => {
                items.map((item1: { name: string, title: string }) => {
                    if (item1.name === item) a++;
                })
            });
            return a === items.length
        } else {
            let a = 0, b = 0;
            if (allItems !== null) {

                allItems.map((item1: permissions) => {
                    item1.permissions.map((item2: { name: string, title: string }) => {
                        selectedP.map((item: string) => {
                            if (item === item2.name) {
                                a++;
                            }
                        });
                        b++
                    })
                });
            }
            return b !== 0 && a === b;
        }
    };

    const checkAll = (checked: boolean): void => {
        let a: string[] = [];
        if (checked) {
            permissions.map((item: permissions) => {
                item.permissions.map((per: { name: string, title: string }) => {
                    a.push(per.name);
                })
            });
            setSelectedP(a)
        } else {
            setSelectedP(a);
        }
    };

    useEffect(() => {
        (
            async () => {
                const resp = await getAllRoles()
                setRoles(resp?.items)
            }
        )()
    }, []);

    const copyPerCategory = (data: Array<any>) => {
        let perCategory: string = '';
        data.map((per: { name: string }) => {
            perCategory += per?.name + ",";
        })
        navigator.clipboard.writeText(perCategory.slice(0, perCategory.length - 1));
    }

    return (
        <>
            <div className="container-fluid">
                <CustomBreadcrumb arr={_breadcrump} />
                <Form
                    className="mt-4"
                    {...layout}
                    form={form}
                    name="edit-role"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>

                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item
                                name={`name`}
                                label={t`Name`}
                                initialValue={role?.name}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field!"),
                                    },
                                ]}
                            >
                                <Input readOnly={true} placeholder={t("Name")} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <Form.Item
                                name={`description`}
                                label={t(`Description`)}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field"),
                                    },
                                ]}
                            >
                                <Input placeholder={t`Description`} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={9}>
                            <Form.Item
                                name={`parents`}
                                label={t`Parents`}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Fill in the field!"),
                                    },
                                ]}
                            >
                                <Select onChange={(e: Array<string>) => setParents(e)} mode="multiple" allowClear placeholder={t`Parents`}>
                                    {
                                        roles && roles?.map((item: { id: number, name: string }) => {
                                            return <Select.Option disabled={children.includes(item?.name)} value={item?.name} key={item?.id}>{item?.name}</Select.Option>
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={9}>
                            <Form.Item
                                name={`childs`}
                                label={t`Children`}
                                rules={[
                                    {
                                        required: false,
                                        message: t("Fill in the field!"),
                                    },
                                ]}
                            >
                                <Select onChange={(e: Array<string>) => setChildren(e)} mode="multiple" allowClear placeholder={t`Children`}>
                                    {
                                        roles && roles?.map((item: { id: number, name: string }) =>
                                            <Select.Option disabled={parents?.includes(item?.name)} value={item?.name} key={item?.id}>{item?.name}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            {t("Permissions")} <input checked={checkedCategory(null, permissions)} onChange={(e) => {
                                checkAll(e.target.checked);
                                e.target.checked = checkedCategory(null, permissions)
                            }} type="checkbox" />
                        </Col>
                    </Row>
                    <Card>
                        {
                            permissions && permissions.map((item: permissions, i: number) =>
                                <div className="row p-1 d-flex permissions" key={"" + i}>
                                    <div className="col-2 fw-bold p-0 row">
                                        <div className="col-2"><input checked={checkedCategory(item.permissions, null)}
                                            className={"category-" + i} onChange={(e) => {
                                                let a: string[] = [];
                                                if (e.target.checked) {
                                                    item.permissions.map((item) => {
                                                        if (!selectedP.includes(item.name)) {
                                                            a.push(item.name);
                                                        }
                                                    });
                                                    setSelectedP([...selectedP, ...a])
                                                } else {
                                                    item.permissions.map((item) => {
                                                        a.push(item.name);
                                                    });
                                                    setSelectedP(selectedP.filter(item1 => {
                                                        return !a.includes(item1)
                                                    }))
                                                }
                                            }
                                            } type="checkbox" name={item.category} /></div>
                                        <div className="col-10" onCopy={() => copyPerCategory(item?.permissions)} >{item.category}</div>
                                    </div>
                                    <div className="col-10 d-flex flex-wrap">
                                        {
                                            item.permissions.map((per: { name: string, title: string }, j: number) =>
                                                <div key={j} className="actions" style={
                                                    {
                                                        width: "20%",
                                                        borderTop: j > 4 ? ".01em solid rgba(0,0,0,0.1)" : ""
                                                    }
                                                }>
                                                    <div>
                                                        <div><input checked={selectedP.includes(per.name)}
                                                            className={"category"} onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setSelectedP([...selectedP, e.target.name])
                                                                } else {
                                                                    if (selectedP.length > 0) {
                                                                        setSelectedP(selectedP.filter((item: string) => {
                                                                            return item !== e.target.name
                                                                        }))
                                                                    }
                                                                }
                                                            }
                                                            } type="checkbox" name={per.name} /></div>
                                                        <div onCopy={(e) => { navigator.clipboard.writeText(per?.name) }} >{per.title}</div>
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )

                        }
                    </Card>
                    <Col span={24}>
                        <Button type="primary" htmlType="submit" className="m-1" style={{ float: "right" }}>{t("Save")}</Button>
                        <Button type="default" className="m-1" style={{ float: "right" }}>{t("Clear")}</Button>
                    </Col>
                </Form>

            </div>
        </>
    )
}
    ;


export default EditRole
