import { FC, useState } from "react"
import IndexPageTitle from "components/IndexPageTitle";
import { useTranslation } from "react-i18next";
import isHasAuthRoute from "utils/isHasRoute";
import { ButtonCreate } from "components/Buttons";
import SimpleTable from "components/simpleTable";
import { Td, Tr } from "react-super-responsive-table";
import BadgeStatus from "components/BadgeStatus";
import Actions from "components/Actions";
import useGetAllData from "hooks/useGetAllData";
import LanguageCertificateTypeCreate from "./crud/create";
import LanguageCertificateTypeView from "./crud/view";
import LanguageCertificateTypeEdit from "./crud/update";
import ForTable from "components/ForTable";
import EmptyTable from "components/EmptyTable";
import ForTitle from "components/ForTitles";

const LanguageCertificateType: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [reFetch, setReFetch] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)

    const data = useGetAllData({ url: `lang-certificate-types`, isCall: "auto", refetch: [reFetch], currentPage, perPage });
    

    return (
        <div>
            <LanguageCertificateTypeCreate visible={visibleCreate} setVisible={setVisibleCreate} setReFetch={setReFetch} url={`/lang-certificate-types`} />
            {id ? <LanguageCertificateTypeView visible={visibleView} setVisible={setVisibleView} url={`/lang-certificate-types/${id}?expand=createdBy,updatedBy`} /> : null}
            {id ? <LanguageCertificateTypeEdit visible={visibleEdit} setVisible={setVisibleEdit} setReFetch={setReFetch} url={`/lang-certificate-types/${id}`} /> : null}
            <ForTitle title="Language certificate type" buttons={[isHasAuthRoute("lang-certificate-type_create") && <ButtonCreate onClick={() => setVisibleCreate(true)} />]}/>
            <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={data?.data?._meta?.totalCount} loading={data.loading} datas={data.items} theads={
                    [
                        "Name",
                        "Language",
                        "Status",
                        "Actions"
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            <>{this.name}</>,
                            <>{this?.lang}</>,
                            <><BadgeStatus status={this.status} /></>,
                            <>
                                <Actions
                                    id={this?.id}
                                    url="lang-certificate-types"
                                    setId={setId}
                                    setReFetch={setReFetch}
                                    setVisibleView={setVisibleView}
                                    setVisibleEdit={setVisibleEdit}
                                    viewPermission="lang-certificate-type_view"
                                    editPermission="lang-certificate-type_update"
                                    deletePermission="lang-certificate-type_delete"
                                />
                            </>
                        ]
                    }
                }/>
        </div>
    )
}

export default LanguageCertificateType;