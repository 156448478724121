import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const StudentPartnersType: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="category-of-cohabitants"
                indexTitle="Partners Type"
                viewTitle="Birga yashovchilar toifasi"
                editTitle="Birga yashovchi toifasini tahrirlash"
                createTitle="Birga yashovchi toifasini yaratish"
                permissions={{
                    view_: "category-of-cohabitant_view",
                    delete_: "category-of-cohabitant_delete",
                    update_: "category-of-cohabitant_update",
                    create_: "category-of-cohabitant_create",
                }}
            />
        </div>
    )
}

export default StudentPartnersType;
