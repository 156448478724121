import { Col, Row } from "antd";
import BadgeStatus from "components/BadgeStatus";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { getDepartmentTypes, getOneDepartment } from "../service";

const ViewDepartment: FC<{
    setRefresh: (refresh: boolean) => void,
    refresh: boolean,
    item: { id: number, name: string },
    setVisible: (visible: boolean) => void
}> = ({
    setRefresh,
    refresh,
    item,
    setVisible
}): JSX.Element => {
        const { t } = useTranslation()
        const [department, setDepartment] = useState<any>({});
        const [types, setTypes] = useState<any>({});

        useEffect(() => {
            (
                async () => {
                    const response = await getOneDepartment(item?.id, 'expand=parent,children,createdBy,updatedBy')
                    setDepartment(response);
                    const typesRes = await getDepartmentTypes();
                    setTypes(typesRes)
                }
            )()
        }, [item?.id])
        return (
            <Row gutter={24}>
                <Col className="mt-2" xs={24} lg={12}>
                    <strong>{t("Name")}: </strong>{department?.name}
                </Col>
                <Col className="mt-2" xs={24} lg={12}>
                    <strong>{t("Type")}: </strong>{types[String(department?.type)]}
                </Col>
                <Col className="mt-2" xs={24} lg={12}>
                    <strong>{t("Status")}: </strong><BadgeStatus status={department?.status} />
                </Col>
                <Col className="mt-2" xs={24} lg={12}>
                </Col>
                {
                    department?.createdBy && <>
                        <Col span={24} className="mt-2"><strong >{t("Created by")}:</strong></Col>
                        <Col className="mt-1" xs={24} lg={12}>
                            <strong>{t("Full name")}: </strong>{department?.createdBy?.first_name + ' ' + department?.createdBy?.last_name}
                        </Col>
                        <Col className="mt-1" xs={24} lg={12}>
                            <strong>{t("Username")}: </strong>{department?.createdBy?.username}
                        </Col>
                    </>
                }
                {
                    department?.updatedBy && <>
                        <Col span={24} className="mt-2"><strong >{t("Created by")}:</strong></Col>
                        <Col className="mt-1" xs={24} lg={12}>
                            <strong>{t("Full name")}: </strong>{department?.updatedBy?.first_name + ' ' + department?.updatedBy?.last_name}
                        </Col>
                        <Col className="mt-1" xs={24} lg={12}>
                            <strong>{t("Username")}: </strong>{department?.updatedBy?.username}
                        </Col>
                    </>
                }
                <Col className="mt-2" span={24}>
                    {department?.parent && <>
                        <strong>Parent: </strong>
                        <Table className="table table-striped table-bordered">
                            <Thead>
                                <Tr>
                                    
                                    <Th>{t("Name")}</Th>
                                    <Th>{t("Status")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>1</Td>
                                    <Td>{department?.parent?.name}</Td>
                                    <Td><BadgeStatus status={department?.parent?.status} /></Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </>}
                </Col>
                <Col className="mt-2" xs={24} lg={24}>
                    {department?.children?.length > 0 && <>
                        <strong>Children: </strong>
                        <Table className="table table-striped table-bordered">
                            <Thead>
                                <Tr>
                                    
                                    <Th>{t("Name")}</Th>
                                    <Th>{t("Status")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    department?.children?.map((item: { id: number, name: string, status: number }, i: number) =>
                                        <Tr>
                                            <Td>{i + 1}</Td>
                                            <Td>{item?.name}</Td>
                                            <Td><BadgeStatus status={item?.status} /></Td>
                                        </Tr>
                                    )}
                            </Tbody>
                        </Table>
                    </>}
                </Col>
            </Row>
        )
    }

export default ViewDepartment;