import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Tabs, Avatar, Image, Modal, Input, Form, Divider } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { NavLink, useRouteMatch, match } from "react-router-dom";
import { useLocation } from "react-router";
import isHasAuthRoute from "utils/isHasRoute";
import { getOneStudent } from "../service";
import { asyncN } from "utils/notifications";
import CustomBreadcrumb from "components/Breadcrumb";
import { FILE_URL } from "config/utils";
import { useTranslation } from "react-i18next";
import { BiEditAlt, BiLink } from "react-icons/bi";
import { getPassword } from "services";
import moment from "moment";
import { checkStatusState } from "./utils";
import user_male from 'assets/images/user_male.png';
import user_female from 'assets/images/user_female.png';
import useGetAllData from "hooks/useGetAllData";
import { getLanguageAsObject } from "utils/auxiliary_functions";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table";

import "../styles.scss"
import { Card, CardBody } from "reactstrap";
import { FaFileExcel } from "react-icons/fa";
import { excelExport } from "utils/excelExport";


const { TabPane } = Tabs;

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Students",
        path: 'student_index'
    },
    {
        name: "Student view",
        path: 'student_view'
    },
]
const StudentView: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const location: any = useLocation();
    const history = useHistory();
    const match: match<any> | null = useRouteMatch("/student_view/:student_id");
    const [form] = Form.useForm();
    const id = location?.state?.id;
    const path: string = location?.pathname;
    const a: Array<string> = path?.split("/");
    const _id = a[a.length - 1];
    const [visible, setVisible] = useState<boolean>(false);

    const [data, setData] = useState<any>(null)
    const [auth_data, setAuth_data] = useState<any>(null);
    const [language, setLanguage] = useState<string>('');
    const [loading, setLoading] = useState(false);
    if (!match) {
        history.goBack();
    }

    const _languages = useGetAllData({ url: "/languages", isCall: 'auto' });
    const _languageAsObject = getLanguageAsObject(_languages.items)




    useEffect(() => {
        (
            async () => {
                try {
                    if (!match?.params?.student_id) {
                        asyncN('error', 'read', "Id ma'lumotini olishda xatolik !").then(() => history.goBack())
                    }
                    const response = await getOneStudent(match?.params?.student_id, "expand=profile,user,faculty,direction,eduYear,eduType,eduPlan,eduLangs,eduForm,course,createdBy,updatedBy,region,area,country,permanentArea,permanentCountry,permanentRegion,citizenship,nationality,socialCategory,residenceStatus,categoryOfCohabitant,studentCategory")
                    if (!Array.isArray(response) && !Object.getOwnPropertyNames(response).length) {
                        history.goBack();
                    } else {
                        setData(response)
                    }
                } catch (error: any) {
                    asyncN("error", "read", error?.response?.message)
                }
            }
        )()
    }, []);


    const getAuthData = async () => {
        if (!auth_data) {
            setLoading(true);
            const _data = await getPassword(data?.profile?.user_id)
            setAuth_data(_data)
            setLoading(false);
        }
    }




    return (
        <div className="rounded">
            <Modal
                title={t('Change password')}
                visible={visible}
                onCancel={() => setVisible(false)}
                okText={t('Save')}
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        label={t('New password')}
                        name="password"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                        className="mt-0"
                    >
                        <Input.Password placeholder="input password" />
                    </Form.Item>
                    <Form.Item
                        label={t('Re-password')}
                        name="password"
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                        className="mt-0"
                    >
                        <Input.Password
                            placeholder="input password"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <div className="bg-light" style={{padding: 12}} >
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <Card >
                            <CardBody className="p-2">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className="d-f" >
                                        <CustomBreadcrumb arr={_breadcrump} />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        {isHasAuthRoute("student_update") && <NavLink to={{ pathname: `/student_update/${_id}`, state: { id: _id } }} ><Button type="primary" className="float-end d-f" icon={<EditOutlined />}>{t("Edit")}</Button></NavLink>}
                                        
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10}>
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    <Avatar shape="square" size={100} src={<Image src={data?.profile?.passport_file ? data?.profile?.passport_file : Number(data?.profile?.gender) ? user_male : user_female} style={{ width: 90 }} />} />
                                    <span className="info_fio">
                                    <span className="info_status" style={{ backgroundColor: checkStatusState(Number(data?.status)).color }}>{checkStatusState(Number(data?.status)).text}</span>
                                        <p className="m-0">{data?.profile?.last_name}</p>
                                        <p className="m-0">{data?.profile?.first_name}</p>
                                        <p className="m-0">{data?.profile?.middle_name}</p>
                                    </span>
                                </div>
                                <hr className="my-2" />
                                <Row gutter={[0, 10]}>
                                    <Col xs={12}><p className="info_text_key m-0">{t("Birth date")}: </p><span className="info_text_value">{data?.profile?.birthday}</span></Col>
                                    <Col xs={12}><p className="info_text_key m-0">{t("Gender")}: </p><span className="info_text_value">{Number(data?.profile?.gender) ? "Erkak" : "Ayol"}</span></Col>
                                    <Col xs={12}><p className="info_text_key m-0">{t("Main phone number")}: </p><span className="info_text_value" style={{ whiteSpace: "nowrap" }}>{data?.profile?.phone}</span></Col>
                                    <Col xs={12}><p className="info_text_key m-0">{t("Second phone number")}: </p><span className="info_text_value" style={{ whiteSpace: "nowrap" }}>{data?.profile?.phone_secondary}</span></Col>
                                    <Col xs={24}>
                                        <p className="info_text_key m-0">{t("Description")}: </p>
                                        <div className="info_view_description">{data?.description}</div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="d-flex justify-content-between">
                                            <a className="info_text_key" style={{ color: '#1890ff' }} onClick={getAuthData}>{t("Login and password")}: </a>
                                            {auth_data && Object.getOwnPropertyNames(auth_data).length ? <Button type="link" size="small" onClick={() => setVisible(true)}><BiEditAlt /></Button> : null}
                                        </div>
                                    </Col>
                                    {loading ? <div className="loader-line"></div> : <div style={{ height: '3px' }}></div>}
                                    {
                                        auth_data && Object.getOwnPropertyNames(auth_data).length ?
                                            <>
                                                <Col xs={12}><span className="info_text_key">username: </span><span className="info_text_value" style={{ whiteSpace: "nowrap" }}>{auth_data?.username}</span></Col>
                                                <Col xs={12}><span className="info_text_key">password: </span><span className="info_text_value" style={{ whiteSpace: "nowrap", fontFamily: "Roboto Mono", fontWeight: 'bold' }}>{auth_data?.password}</span></Col>
                                            </> : null
                                    }

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14}>
                        <Card>
                            <CardBody>
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab={t("Personal details")} key="1">
                                        <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Citizenship")}: </span> <span className="info_text_value">{data?.citizenship?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Nationality")}: </span> <span className="info_text_value">{data?.nationality?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}>
                                                <div><span className="info_text_key">{t("Passport seria and number")}: </span> <span className="info_text_value">{data?.profile?.passport_seria}&nbsp;{data?.profile?.passport_number}</span></div>
                                            </Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Passport JSHIR")}: </span> <span className="info_text_value">{data?.profile?.passport_pin}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Passport date")}: </span> <span className="info_text_value">{data?.profile?.passport_given_date} &nbsp; {data?.profile?.passport_issued_date}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{("Passport given by")}: </span> <span className="info_text_value">{data?.profile?.passport_given_by}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Passport file")}: </span>
                                                <span className="info_text_value"> {
                                                    data?.profile?.passport_file && data?.profile?.passport_file !== 'null' ?
                                                        <a href={FILE_URL + data?.profile?.passport_file} target="_blank" rel="noopener noreferrer"><BiLink />&nbsp;link</a> : <span className="text-danger">Yuklanmagan</span>
                                                }</span>
                                            </div></Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={t("Education details")} key="2">
                                        <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Education type")}: </span> <span className="info_text_value">{data?.eduType?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Education form")}: </span> <span className="info_text_value">{data?.eduForm?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Texnikum")}: </span> <span className="info_text_value">{data?.faculty?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Direction")}: </span> <span className="info_text_value">{data?.direction?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Education year")}: </span> <span className="info_text_value">{String(data?.eduYear?.name).split('-')[0]}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Course")}: </span> <span className="info_text_value">{data?.course?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Education plan")}: </span> <span className="info_text_value">{data?.eduPlan?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Education language")}: </span> <span className="info_text_value">{_languageAsObject[data?.edu_lang_id]}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Form of payment")}: </span> <span className="info_text_value">{data?.is_contract ? t("Contrakt") : t("Grant")}</span></div></Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={t("Address details")} key="3">
                                        <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                            <Col lg={24} sm={24} md={24} xs={24}>
                                                {/* <div className="info_text_key">{t("Permanent address")}</div>
                                        <hr className="p-0 m-0 text-secondary" /> */}
                                                <Divider orientation="left">{t("Permanent address")}</Divider>
                                            </Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Country")}: </span> <span className="info_text_value">{data?.permanentCountry?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Region")}: </span> <span className="info_text_value">{data?.permanentRegion?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Area")}: </span> <span className="info_text_value">{data?.permanentArea?.name}</span></div></Col>
                                            <Col lg={24} sm={24} md={24} xs={24}><div><span className="info_text_key">{t("Address")}: </span> <span className="info_text_value">{data?.profile?.permanent_address}</span></div></Col>
                                            <Col lg={24} sm={24} md={24} xs={24}>
                                                {/* <div className="info_text_key">{t("Current address")}</div>
                                        <hr className="p-0 m-0 text-secondary" /> */}
                                                <Divider orientation="left">{t("Current address")}</Divider>
                                            </Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Country")}: </span> <span className="info_text_value">{data?.country?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Region")}: </span> <span className="info_text_value">{data?.region?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">{t("Area")}: </span> <span className="info_text_value">{data?.area?.name}</span></div></Col>
                                            <Col lg={24} sm={24} md={24} xs={24}><div><span className="info_text_key">{t("Address")}: </span> <span className="info_text_value">{data?.profile?.address}</span></div></Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="Qo'shimcha ma'lumotlar" key="4">
                                        <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Birga yashaydiganlar soni: </span> <span className="info_text_value">{data?.partners_count}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Birga yashaydiganlar toifasi: </span> <span className="info_text_value">{data?.categoryOfCohabitant?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Yashash hududi holati: </span> <span className="info_text_value">{data?.residenceStatus?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Yashash joyi geolokatsiyasi: </span> <span className="info_text_value">{data?.live_location}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Ota onasi telefon raqami: </span> <span className="info_text_value">{data?.parent_phone}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Ma'sul shaxs telefon raqami: </span> <span className="info_text_value">{data?.res_person_phone}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Ijtimoiy toifa: </span> <span className="info_text_value">{data?.socialCategory?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Talaba toifasi: </span> <span className="info_text_value">{data?.studentCategory?.name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Avvalgi ta'lim ma'lumoti: </span> <span className="info_text_value">{data?.last_education}</span></div></Col>
                                            <Col span={24}>
                                                <Divider orientation="left">{t("Sport certificates")}</Divider>
                                                <Table id="tech-companies-1" className="table for-table">
                                                    <Thead>
                                                        <Tr>
                                                            <Th style={{padding: "0.25rem"}}>{t("Name")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("Date")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("Place")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("File")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>

                                                    </Tbody>
                                                </Table>
                                            </Col>
                                            <Col span={24}>
                                                <Divider orientation="left">{t("Olimpic certificates")}</Divider>
                                                <Table id="tech-companies-1" className="table for-table">
                                                    <Thead>
                                                        <Tr>
                                                            <Th style={{padding: "0.25rem"}}>{t("Name")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("Date")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("Place")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("File")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>

                                                    </Tbody>
                                                </Table>
                                            </Col>
                                            <Col span={24}>
                                                <Divider orientation="left">{t("Other certificates")}</Divider>
                                                <Table id="tech-companies-1" className="table for-table">
                                                    <Thead>
                                                        <Tr>
                                                            <Th style={{padding: "0.25rem"}}>{t("Name")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("Date")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("Place")}</Th>
                                                            <Th style={{padding: "0.25rem"}}>{t("File")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>

                                                    </Tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={t("Parent information")} key="5">
                                        <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                            <Col lg={12} sm={12} md={12} xs={24}><div><span className="info_text_key">{t("Father F.I.O")}: </span> <span className="info_text_value">{ }</span></div></Col>
                                            <Col lg={12} sm={12} md={12} xs={24}><div><span className="info_text_key">{t("Father phone number")}: </span> <span className="info_text_value">{ }</span></div></Col>
                                            <Col lg={24} sm={24} md={24} xs={24}><div><span className="info_text_key">{t("Father info")}: </span> <span className="info_text_value">{ }</span></div></Col>
                                            <Col lg={12} sm={12} md={12} xs={24}><div><span className="info_text_key">{t("Mather F.I.O")}: </span> <span className="info_text_value">{ }</span></div></Col>
                                            <Col lg={12} sm={12} md={12} xs={24}><div><span className="info_text_key">{t("Mather phone number")}: </span> <span className="info_text_value">{ }</span></div></Col>
                                            <Col lg={24} sm={24} md={24} xs={24}><div><span className="info_text_key">{t("Mather info")}: </span> <span className="info_text_value">{ }</span></div></Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={t("Student documents")} key="6">
                                        <Table id="tech-companies-1" className="table for-table">
                                            <Thead>
                                                <Tr>
                                                    <Th style={{padding: "0.25rem"}}>{t("Documnet name")}</Th>
                                                    <Th style={{padding: "0.25rem"}}>{t("Documnet description")}</Th>
                                                    <Th style={{padding: "0.25rem"}}>{t("Documnet file")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>

                                            </Tbody>
                                        </Table>
                                    </TabPane>
                                    <TabPane tab={t("Talaba o'zlashtirishi")} key="7">
                                        <Table id="tech-companies-1" className="table for-table">
                                            <Thead>
                                                <Tr>
                                                    <Th style={{padding: "0.25rem"}} >{t("Subject name")}</Th>
                                                    <Th style={{padding: "0.25rem"}} >ON1</Th>
                                                    <Th style={{padding: "0.25rem"}} >ON2</Th>
                                                    <Th style={{padding: "0.25rem"}} >YN</Th>
                                                    <Th style={{padding: "0.25rem"}} >All ball</Th>
                                                    <Th style={{padding: "0.25rem"}} >Student score</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>

                                            </Tbody>
                                        </Table>
                                    </TabPane>
                                    <TabPane tab="Kiritilgan va o'zgartirilgan" key="8">
                                        <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                            <Col lg={24} sm={24} md={24} xs={24}><div className="info_text_key">Kiritilgan</div><hr className="p-0 m-0 text-secondary" /></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Foydalanuvchi: </span> <span className="info_text_value">{data?.createdBy?.last_name}&nbsp;{data?.createdBy?.first_name}&nbsp;{data?.createdBy?.middle_name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Role: </span> <span className="info_text_value">
                                                {
                                                    Array.isArray(data?.createdBy?.role) && data?.createdBy?.role.map((e: string) => <span className="mx-2" key={e}>{e}</span>)
                                                }
                                            </span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Kiritgan vaqti: </span> <span className="info_text_value">{moment.unix(data?.created_at).format("DD-MM-YYYY HH:mm:ss")}</span></div></Col>
                                            {data?.updatedBy && Object.getOwnPropertyNames(data?.updatedBy).length ?
                                                <>
                                                    <Col lg={24} sm={24} md={24} xs={24}><div className="info_text_key">O'zgartirilgan</div><hr className="p-0 m-0 text-secondary" /></Col>
                                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Foydalanuvchi: </span> <span className="info_text_value">{data?.createdBy?.last_name}&nbsp;{data?.createdBy?.first_name}&nbsp;{data?.createdBy?.middle_name}</span></div></Col>
                                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Role: </span> <span className="info_text_value">
                                                        {
                                                            Array.isArray(data?.updatedBy?.role) && data?.updatedBy?.role.map((e: string) => <span className="mx-2" key={e}>{e}</span>)
                                                        }
                                                    </span></div></Col>
                                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">O'zgartirgan vaqti: </span> <span className="info_text_value">{moment.unix(data?.updated_at).format("DD-MM-YYYY  HH:mm:ss")}</span></div></Col>
                                                </> : null
                                            }
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
        </div >
    )
}


export default StudentView;