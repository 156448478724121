import { Tag } from "antd"
import { BiCheck } from "react-icons/bi";
import { isRoleExact } from "utils/auxiliary_functions";
import isRole from "utils/isRole";

// question status list

//  STATUS_INACTIVE = 0;
//  STATUS_ACTIVE = 1;
//  STATUS_TEACHER_EDITED = 2;
//  STATUS_MUDIR_REFUSED = 3;
//  STATUS_MUDIR_ACTIVE = 4;
//  STATUS_DEAN_REFUSED = 5;
//  STATUS_DEAN_ACTIVE = 6;
//  STATUS_EDU_ADMIN_REFUSED = 7;
//  STATUS_EDU_ADMIN_ACTIVE = 1;


const confirmed_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }} ><BiCheck size={14} /> Tasdiqlandi</Tag>; // tasdiqlandi
const refused_status = <Tag color="#FCE8E6" style={{ color: '#C5221F', minWidth: '120px', textAlign: 'center' }} >Bekor qilindi</Tag>; // bekor qilindi
const pending_status = <Tag color="#E8EAED" style={{ color: '#3C4043', minWidth: '120px', textAlign: 'center' }} >Ko'rib chiqilmagan</Tag>; // kutilmoqda
const process_confirm_status = <Tag color="#E8F0FE" style={{ color: '#1A73E8', minWidth: '120px', textAlign: 'center' }} >Ko'rib chiqilmoqda</Tag>; // kutilmoqda
const self_refused_status = <Tag color="#FCE8E6" style={{ color: '#C5221F', minWidth: '120px', textAlign: 'center' }} >Qabul qilinmadi</Tag>; // o'zi tasdiqladi
const active_status = <Tag color="#E6F4EA" style={{ color: '#137333', minWidth: '120px', textAlign: 'center' }}  >Faol</Tag>; // o'zi tasdiqladi
const inactive_status = <Tag color="#FCE8E6" style={{ color: '#C5221F', minWidth: '120px', textAlign: 'center' }} >Faolmas</Tag>; // o'zi tasdiqladi

export const question_status_list = [
    {
        id: 1,
        status: [0],
        title: 'Faolmas',
        status_ui: inactive_status
    },
    {
        id: 2,
        status: [1],
        title: 'Faol',
        status_ui: inactive_status
    },
    {
        id: 3,
        status: [2],
        title: "O'zgartirilgan (O'qituvchi)",
        status_ui: inactive_status
    },
    {
        id: 4,
        status: [3],
        title: 'Bekor qilindi (Mudir)',
        status_ui: inactive_status
    },
    {
        id: 5,
        status: [4],
        title: 'Tasdiqlandi (Mudir)',
        status_ui: inactive_status
    },
    {
        id: 6,
        status: [5],
        title: 'Bekor qilindi (Dekan)',
        status_ui: inactive_status
    },
    {
        id: 7,
        status: [6],
        title: 'Tasdiqlandi (Dekan)',
        status_ui: inactive_status
    },
    {
        id: 8,
        status: [7],
        title: "Bekor qilindi (O'quv boshqarma)",
        status_ui: inactive_status
    },
    {
        id: 9,
        status: [1],
        title: "Tasdiqlandi (O'quv boshqarma)",
        status_ui: inactive_status
    },
]

export const question_confirm_status = (status: number) => {

    if (isRoleExact(['teacher'])) {
        // tasdiqlandi  STATUS_EDU_ADMIN_ACTIVE -> 1 || STATUS_ACTIVE -> 1
        if (status === 1) {

            return confirmed_status

        }
        // Bekor qilindi  STATUS_MUDIR_REFUSED -> 3 || STATUS_DEAN_REFUSED -> 5 || STATUS_EDU_ADMIN_REFUSED -> 7
        else if ([3, 5, 7].includes(status)) {

            return refused_status;

        } else {

            return process_confirm_status;

        }

    } else if (isRoleExact(['teacher', 'mudir'])) {
        // tasdiqlandi  STATUS_EDU_ADMIN_ACTIVE -> 1 || STATUS_ACTIVE -> 1
        if (status === 1) {

            return confirmed_status;

        }
        // Bekor qilindi STATUS_DEAN_REFUSED -> 5 || STATUS_EDU_ADMIN_REFUSED -> 7
        else if ([5, 7].includes(status)) {

            return refused_status;

        }
        // Mudir tomonidan tasdiqlandi STATUS_MUDIR_ACTIVE = 4 || STATUS_DEAN_ACTIVE = 6;
        else if ([4, 6].includes(status)) {

            return process_confirm_status;

        }
        // Mudir tomonidan bekor qilindi STATUS_MUDIR_REFUSED = 3
        else if (status === 3) {

            return self_refused_status;

        } else if (status === 0) {
            return inactive_status
        } else {

            return pending_status;

        }

    } else if (isRoleExact(['mudir'])) {
        // tasdiqlandi  STATUS_EDU_ADMIN_ACTIVE -> 1 || STATUS_ACTIVE -> 1
        if (status === 1) {

            return confirmed_status;

        }
        // Bekor qilindi STATUS_DEAN_REFUSED -> 5 || STATUS_EDU_ADMIN_REFUSED -> 7
        else if ([5, 7].includes(status)) {

            return refused_status;

        }
        // Mudir tomonidan tasdiqlandi STATUS_MUDIR_ACTIVE = 4 || STATUS_DEAN_ACTIVE = 6;
        else if ([4, 6].includes(status)) {

            return process_confirm_status;

        }
        // Mudir tomonidan bekor qilindi STATUS_MUDIR_REFUSED = 3
        else if (status === 3) {

            return self_refused_status;

        } else {

            return pending_status;

        }

    } else if (isRole(['dean'])) {
        // tasdiqlandi  STATUS_EDU_ADMIN_ACTIVE -> 1 || STATUS_ACTIVE -> 1
        if (status === 1) {

            return confirmed_status;

        }
        // Bekor qilindi  STATUS_EDU_ADMIN_REFUSED = 7;
        else if (status === 7) {

            return refused_status;

        }
        // dekan tomonidan tasdiqlandi STATUS_DEAN_ACTIVE = 6;
        else if (status === 6) {

            return process_confirm_status;

        }
        // Dekan tomonidan bekor qilindi STATUS_DEAN_REFUSED = 5
        else if (status === 5) {

            return self_refused_status;

        } else {

            return pending_status;

        }

    } else if (isRole(['edu_admin'])) {
        // tasdiqlandi  STATUS_EDU_ADMIN_ACTIVE -> 1 || STATUS_ACTIVE -> 1
        if (status === 1) {

            return confirmed_status;

        }
        // Bekor qilindi  STATUS_EDU_ADMIN_REFUSED = 7;
        else if (status === 7) {

            return self_refused_status;

        } else {

            return pending_status;

        }

    } else if (isRole(['admin'])) {
        if (status === 1) {

            return confirmed_status;

        }
        // Bekor qilindi  STATUS_EDU_ADMIN_REFUSED = 7;
        else if ([3, 5, 7].includes(status)) {

            return refused_status;

        } else if (status === 0) {

            return inactive_status;

        } else {

            return process_confirm_status;

        }
    } else {
        // tasdiqlandi  STATUS_EDU_ADMIN_ACTIVE -> 1 || STATUS_ACTIVE -> 1
        if (status === 1) {

            return active_status;

        } else {
            return inactive_status;
        }
    }

}


export const question_confirm = (status: number): { disabled_confirm: boolean, disabled_cancel: boolean } => {

    if (isRole(['mudir'])) {

        const disabled_confirm = [4, 6, 1].includes(status);
        const disabled_cancel = [1, 6, 3].includes(status);

        return { disabled_cancel, disabled_confirm };

    } else if (isRole(['dean'])) {

        const disabled_confirm = [1, 6].includes(status);
        const disabled_cancel = [1, 5].includes(status);

        return { disabled_cancel, disabled_confirm };

    } else if (isRole(['edu_admin'])) {

        const disabled_confirm = [1].includes(status);
        const disabled_cancel = [7].includes(status);

        return { disabled_cancel, disabled_confirm };

    }

    return { disabled_cancel: false, disabled_confirm: false }
}
