import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";


const EduTypes: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="edu-types"
                indexTitle="Edu types"
                createTitle="Create edu type"
                editTitle="Edu type edit"
                viewTitle="Edu type view"
                permissions={{
                    view_: "edu-type_view",
                    delete_: "edu-type_delete",
                    update_: "edu-type_update",
                    create_: "edu-type_create"
                }}
            />
        </div>
    )
}

export default EduTypes;