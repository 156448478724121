import styled, { css } from "styled-components";




export const MessageItemUi = styled.div<{ me: boolean, color: string }>`

   padding: 6px 14px;
   margin-top: 14px;
   background-color:white;
   border-radius: 4px;
   -webkit-box-shadow: -1px 0px 19px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -1px 0px 19px -7px rgba(34, 60, 80, 0.2);
    box-shadow: -1px 0px 19px -7px rgba(34, 60, 80, 0.2);

   & > div {
       border-bottom : 1px solid #f0f0f0;
       display:flex;
       justify-content:space-between;
       padding:2px 0 ;

       & > span:nth-child(1){
            font-weight: 450;
            font-size: 14px;
            color:#595959;
            & > div {
                display:inline-block;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: ${props => props.color};
                margin-right: 6px;
            }

            & > span {
                font-size: 10px;
                margin-left: 10px;
            }
       }
       & > span:nth-child(2){
           & > svg {
               color: #595959;
               cursor:pointer;

               &:hover {
                   color: #096dd9;
               }
           }
       }
   }

   & > span {
        margin-top: 6px;
        color:#595959;
        font-size: 14px;
        display:block;
        padding: 4px 4px;
        
        &:hover {
            background-color: #f0f5ff;
            cursor: pointer ;
        }
    }

   @media (max-width:786px){
    & > span {
        text-align:justify !important;
   }
    
}
`