import { createSlice } from '@reduxjs/toolkit';
import { ui_var } from 'config/utils/variables';

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        sidebar: {
            width: ui_var.sidebar_width_lg,
            type: 'lg',
            side: 'left',
            submenu: null,
            sidebar_state: 'lg_sm'
        },
        theme: {
            color: 'dark',
            header_status: 'inactive'
        },
        responsive_content: 'mobile',
        sidebarIs: true,
        submenu: null,
        status_sm: false,
        sidebarColor: 'blue',
        headerColor: 'blue',
        isOnlyLightHeader: 0,
    },
    reducers: {
        sidebarState(state, action) {
            state.sidebar.width = action.payload.width
            state.sidebar.type = action.payload.type
            state.sidebar.side = action.payload.side
        },
        submenuState(state, action) {
            state.sidebar.submenu = state.sidebar.submenu === null || state.sidebar.submenu !== action.payload.submenu_id ? action.payload.submenu_id : null
        },
        changeUiTheme(state, action) {
            state.theme.color = action.payload.color
        },
        restoreUiTheme(state, action) {
            state.sidebar = action.payload.sidebar
        },
        headerConstTheme(state, action) {
            state.theme.header_status = state.theme.header_status === 'active' ? 'inactive' : 'active'
        },
        customSidebarResponsive(state, action) {
            state.sidebar.sidebar_state = action.payload.sidebar_state
            state.sidebar.type = action.payload.type
            state.sidebar.width = action.payload.width
        },
        windowSizeChecker(state, action) {
            state.sidebar.sidebar_state = action.payload.sidebar_state
            state.sidebar.type = action.payload.type
            state.sidebar.width = action.payload.width
        },
        restoreUi(state, action) {
            if (action.payload.sidebar ) {
                state.sidebar = action.payload.sidebar
            }
            if (action.payload.theme  && action.payload.theme !== '{}') {
                state.theme = action.payload.theme
            }
        },
        customContentResponsive(state, action) {
            state.responsive_content = action.payload.size
        },
    }
})


export const { sidebarState, submenuState, changeUiTheme, restoreUiTheme, customSidebarResponsive, restoreUi, headerConstTheme, customContentResponsive, windowSizeChecker } = uiSlice.actions;

export default uiSlice;