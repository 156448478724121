import { FC, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Modal, Select, InputNumber } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { useTranslation } from 'react-i18next';
import ModalTitle from 'components/ModalTitle';
import { BiX } from 'react-icons/bi';
import useGetAllData from 'hooks/useGetAllData';
import { cf_filterOption } from 'utils/auxiliary_functions';

export type TypeTimeOptionsUpdateProps = {
  id: number,
  visible: boolean,
  setVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setReFetch: React.Dispatch<React.SetStateAction<boolean>>,
}

const TimeOptionUpdate: FC<TypeTimeOptionsUpdateProps> = ({ id, visible, setVisible, setReFetch }): JSX.Element => {

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [clicked, setClicked] = useState<boolean>(false);
  const [faculty_id, setFacultyId] = useState<number | null>()
  const [edu_semestr_id, setEduSemestrId] = useState<number | null>()
  const [edu_plan_id, setEduPlanId] = useState<number | null>()
  const [edu_year_id, setEduYearId] = useState<number | null>()
  const [language_id, setLanguageId] = useState<number | null>()

  const name_data = [...Array(26)];
  const faculties = useGetAllData({ url: `faculties`, isCall: "auto", perPage: 0 })
  const eduPlans = useGetAllData({ url: `edu-plans?sort=-id&filter={"faculty_id": ${faculty_id}}`, isCall: "auto", perPage: 0 })
  const eduSemestrs = useGetAllData({ url: `edu-semestrs?sort=-id&filter={"edu_plan_id": ${edu_plan_id}}`, isCall: "auto", perPage: 0 })
  const eduYears = useGetAllData({ url: `edu-years?sort=-id&`, isCall: "auto", perPage: 0 })
  const languages = useGetAllData({ url: `languages`, isCall: "auto", perPage: 0 })

  useEffect(() => {
    if (visible) {
      (
        async () => {
          const data = await instance({ url: `time-options/${id}`, method: "GET" })
          const a = data.data.data
          setFacultyId(Number(a.faculty_id));
          setEduPlanId(Number(a.edu_plan_id));
          form.setFieldsValue({
            "key": a.key,
            "capacity": a.capacity,
            "faculty_id": a.faculty_id,
            "edu_plan_id": a.edu_plan_id,
            "edu_semester_id": a.edu_semester_id,
            "edu_year_id": a.edu_year_id,
            "language_id": a.language_id,
          })
        }
      )()
    }

  }, [visible])

  const onSubmit = async (values: any) => {
    try {
      setClicked(true)
      const formdata = new FormData();
      for (const [key, value] of Object.entries(values)) {
        if (key !== 'faculty_id' && key !== "edu_plan_id") {
          formdata.append(key, String(value));
        }
      }

      console.log(formdata);

      const response = await instance({ url: `time-options/${id}`, method: 'PUT', data: formdata })

      if (response.data.status === 1) {
        asyncN("success", 'create', response.data?.message)
          .then(() => {
            resetFields();
            setVisible(false);
            setReFetch(prevState => !prevState);
          })
      } else {
        asyncN("error", 'update', response.data?.message)
      }
      setClicked(false)
    } catch (error: any) { }
  }

  const resetFields = () => form.resetFields();

  return (
    <Modal
      title={"Time table variant update"}
      visible={visible}
      onCancel={() => { setVisible(prevState => !prevState); resetFields() }}
      width={600}
      footer={null}
      closeIcon={<BiX size={20} />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          status: true
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name={`key`}
              label={t`Name`}
              rules={[
                {
                  required: true,
                  message: `${t("Fill in the field!")}`,
                },
              ]}>
              <Select
                allowClear
                placeholder={t("Select a name")}
              >
                {
                  name_data.map((element, i: number) => (
                    <Select.Option key={i} value={String.fromCharCode(i + 65)} >{String.fromCharCode(i + 65)}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={`capacity`}
              label={t`Capacity`}
              rules={[
                {
                  required: true,
                  message: `${t("Fill in the field!")}`,
                },
              ]}>
              <InputNumber className='w-100' min={0} placeholder={t("Enter a capacity")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={`faculty_id`}
              label={t`Faculty`}
              rules={[
                {
                  required: true,
                  message: `${t("Fill in the field!")}`,
                },
              ]}>
              <Select
                allowClear
                onChange={(e) => setFacultyId(e)}
                placeholder={t("Select a faculty")}
                onFocus={() => !faculties.items?.length && faculties.fetch()}
                onClear={() => { setEduPlanId(null); setEduSemestrId(null); form.setFieldsValue({ "edu_semestr_id": null }); form.setFieldsValue({ "edu_plan_id": null }) }}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  faculties.items?.length ? faculties.items?.map((element, i) => (
                    <Select.Option key={i} value={element?.id} >{element?.name}</Select.Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={`edu_plan_id`}
              label={t`Edu plan`}
              rules={[
                {
                  required: true,
                  message: `${t("Fill in the field!")}`,
                },
              ]}>
              <Select
                allowClear
                onChange={(e) => setEduPlanId(e)}
                onFocus={() => eduPlans.fetch()}
                onClear={() => { setEduSemestrId(null); form.setFieldsValue({ "edu_semestr_id": null }) }}
                placeholder={t("Select a edu plan")}
                disabled={!faculty_id}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  eduPlans.items?.length ? eduPlans.items?.map((element, i) => (
                    <Select.Option key={i} value={element?.id} >{element?.name}</Select.Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={`edu_semester_id`}
              label={t`Edu semestr`}
              rules={[
                {
                  required: true,
                  message: `${t("Fill in the field!")}`,
                },
              ]}>
              <Select
                allowClear
                onChange={(e) => setEduSemestrId(e)}
                onFocus={() => eduSemestrs.fetch()}
                placeholder={t("Select a edu semestr")}
                disabled={!edu_plan_id}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  eduSemestrs.items?.length ? eduSemestrs.items?.map((element, i) => (
                    <Select.Option key={i} value={element?.id} >{element?.name}</Select.Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={`edu_year_id`}
              label={t`Edu Year`}
              rules={[
                {
                  required: true,
                  message: `${t("Fill in the field!")}`,
                },
              ]}>
              <Select
                allowClear
                onChange={(e) => setEduYearId(e)}
                onFocus={() => !eduYears.items?.length && eduYears.fetch()}
                placeholder={t("Select a edu year")}
                showSearch
                filterOption={cf_filterOption}
              >
                {
                  eduYears.items?.length ? eduYears.items?.map((element, i) => (
                    <Select.Option key={i} value={element?.id} >{element?.name}</Select.Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={`language_id`}
              label={t`Language`}
              rules={[
                {
                  required: true,
                  message: `${t("Fill in the field!")}`,
                },
              ]}>
              <Select
                allowClear
                onChange={(e) => setLanguageId(e)}
                onFocus={() => !languages.items?.length && languages.fetch()}
                placeholder={t("Select a language")}
              >
                {
                  languages.items?.length ? languages.items?.map((element, i) => (
                    <Select.Option key={i} value={element?.id} >{element?.name}</Select.Option>
                  )) : null
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <div className="text-end mt-2">
          <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { setVisible(prevState => !prevState); resetFields() }}>{t("Cancel")}</Button>
          <Button type="primary" htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
          <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
        </div>
      </Form>
    </Modal>
  )
}




export default TimeOptionUpdate;