import { FC, useState } from "react";
import { Button, Col, Form, Input, message, Row, Tabs, Upload } from "antd";
import InputMask from 'react-input-mask';
import { useTranslation } from "react-i18next";
import { FaRegAddressCard, FaRegNewspaper, FaUndo, FaUpload } from "react-icons/fa";
import EduDetails from "./crud/edu_details";
import OtherDetails from "./crud/other_details";

const { TabPane } = Tabs;
const {TextArea} = Input;

const TeacherStatistics: FC = (): JSX.Element => {

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const [clicked, setClicked] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [checkbox, setCheckbox] = useState<Array<{name: string, key: string, isDone: boolean, arrays: Array<{key:string}>}>>([
    {
      name: "Maqolalari",
      key: "maqola",
      isDone: false,
      arrays: [{key: "maqola_1"}],
    },
    {
      name: "Ilmiy ishlari",
      key: "ilmiy_ish",
      isDone: false,
      arrays: [{key: "maqola_1"}],
    },
    {
      name: "Yutuqlari",
      key: "yutuq",
      isDone: false,
      arrays: [{key: "maqola_1"}],
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPreview = async (file: any) => {
    let src = file.url;
    let name: string = file?.name;

    if (name) {

      if (!src) {
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);

    }
  };


  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  const file_upload = () => (<> <FaUpload /> &nbsp;&nbsp; Yuklash</>)

  const handleChangeFile = (name: string, { fileList: file }: any) => {

    if (Array.isArray(file) && file?.length) {
      if (file[0]?.size < 204800) {
        setFileList([{
          uid: "-1",
          name: file[0]?.originFileObj?.name,
          status: "done",
          url: window.URL.createObjectURL(file[0]?.originFileObj),
          file: file[0]?.originFileObj
        }]);
      } else {
        message.error("Rasm hajmi 200kb dan oshmasligi kerak !");
      }
    }
  }

  const handleTabClick = (e: any) => {
    setActiveTabKey(e);
  }

  return (
    <div>
      <h5>Teacher statistics</h5>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          status: true
        }}
        className="mt-4"
      >
        <Tabs defaultActiveKey="3" activeKey={activeTabKey} onTabClick={handleTabClick}>
          <TabPane
            tab={
              <span>
                <FaRegAddressCard size={20} /> &nbsp;{t("Personal details")}
              </span>
            }
            key="1"
          >
            <Row gutter={[12, 0]}>
              <Col lg={{ span: 3 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  label={t("Image")}
                  name="avatar"
                >
                  <Upload
                    customRequest={dummyRequest}
                    listType="picture-card"
                    fileList={fileList}
                    maxCount={1}
                    accept='.png'
                    onChange={(e) => handleChangeFile('image', e)}
                    onPreview={onPreview}
                    onRemove={() => setFileList([])}
                  >
                    {fileList?.length < 1 && file_upload()}
                  </Upload>
                </Form.Item>
              </Col>
              <Col lg={{ span: 7 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  label={t("Firstname")}
                  name="first_name"
                  rules={[{ required: true, message: t("Fill in the field!") }]}
                >
                  <Input placeholder={t("Enter first name ... ")} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 7 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  label={t("Lastname")}
                  name="last_name"
                  rules={[{ required: true, message: t("Fill in the field!") }]}
                >
                  <Input placeholder={t("Enter last name ... ")} onBlur={() => { String(form.getFieldValue('last_name')).endsWith('a') ? form.setFieldsValue({ gender: '0' }) : form.setFieldsValue({ gender: '1' }) }} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 7 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  label={t("Middle name")}
                  name="middle_name"
                  rules={[{ required: true, message: t("Fill in the field!") }]}
                >
                  <Input placeholder={t("Enter middle name ... ")} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  label={t("Phone number")}
                  name="phone"
                  rules={[{ required: true, message: t("Fill in the field!") }]}
                >
                  <InputMask className="ant-input" mask="+\9\98 (99) 999 99 99" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item
                  label={t("Email")}
                  name="email"
                  rules={[{ required: true, message: t("Fill in the field!") }]}
                >
                  <Input placeholder={t("Enter email")} type="email" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Form.Item
                  label={t("Bio")}
                  name="description"
                  rules={[{ required: false }]}
                >
                  <Input.TextArea rows={1} placeholder={t("enter additional information about student") + " ..."} />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={
              <span>
                <FaRegNewspaper size={18} /> &nbsp;
                {t("Education details")}
              </span>
            }
            key="2"
          >
            <EduDetails/>
          </TabPane>
          <TabPane
            tab={
              <span>
                <FaRegNewspaper size={18} /> &nbsp;
                {t("Other details")}
              </span>
            }
            key="3"
          >
            <OtherDetails/>
          </TabPane>
        </Tabs>
        <Col span={24} className="mt-2 text-end">
          <Button danger htmlType="reset" className="me-2" onClick={() => { form.resetFields() }} icon={<FaUndo className="me-1" />}>{t("Reset")}</Button>
          {Number(activeTabKey) > 1 && <Button onClick={() => { setActiveTabKey(String(Number(activeTabKey) - 1)) }} type="primary" htmlType="button" className="me-2" disabled={clicked}>{t("Prev")}</Button>}
          <Button type="primary" style={{ display: Number(activeTabKey) < 3 ? "" : "none" }} htmlType="button" disabled={clicked} onClick={() => { Number(activeTabKey) <= 3 && setActiveTabKey(String(Number(activeTabKey) + 1)) }}>{t("Next")}</Button>
          <Button type="primary" style={{ display: Number(activeTabKey) < 3 ? "none" : "" }}
            htmlType={"submit"} disabled={clicked}>{t("Submit")}</Button>
        </Col>
      </Form>
    </div>
  )
}

export default TeacherStatistics;