import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import instance from "config/_axios";
import { getUserAccessTypes } from "pages/user/servise";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import { getAllLanguages } from "services";
import { asyncN } from "utils/notifications";
import { dailyHoursType, visibleType } from "../types";

type propType = { form: any, visible: visibleType, setVisible: (visible: visibleType) => void, type?: boolean }
const UiForms: FC<propType> = ({ form, visible, setVisible, type }): JSX.Element => {

    const { t, i18n } = useTranslation();
    const [langs, setLangs] = useState<{ all: Array<string>, noneSelected: Array<string>, selected: Array<string> }>(
        {
            all: ["uz", "ru", "en"],
            noneSelected: ["uz", "ru", "en"].filter((i: string) => i !== i18n.language),
            selected: ["uz", "ru", "en"].filter((i: string) => i === i18n.language)
        }
    );
    const [weekDays, setWeekDays] = useState<Array<String>>([
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ])
    const [daylyHours, setDaylyHours] = useState<dailyHoursType>()
    const [languages, setLanguages] = useState<Array<any>>([]);

    useEffect(() => {
        (
            async () => {
                const response: any = await getAllLanguages();
                setLanguages(response?.items)
            }
        )()
    }, [])

    const removeInput = (lang: string) => {
        setLangs({
            ...langs,
            noneSelected: [...langs?.noneSelected, lang],
            selected: langs?.selected?.filter((t: string) => t !== lang)
        })
    }

    const getName = (code: string) => languages?.filter((item: { name: string, lang_code: string }) => item?.lang_code === code)[0]?.name;

    const addSelectedLang = (lang: string) => setLangs({
        ...langs,
        selected: [...langs?.selected, lang],
        noneSelected: langs?.noneSelected?.filter((t: string) => t !== lang)
    })

    const setSelectedLang = (newLang: string, oldLang: string) => setLangs({
        ...langs,
        selected: langs?.selected?.map((item: string) => item !== oldLang ? newLang : item),
        noneSelected: langs?.noneSelected?.map((t: string) => t !== newLang ? oldLang : t)
    })

    useEffect(() => {
        if (visible?.item) {
            let vals: any = {
                [`name[${i18n.language}]`]: visible?.item?.name,
                [`description[${i18n.language}]`]: visible?.item?.description,
                is_leader: visible?.item?.status === 1,
                ...visible?.item
            }
            if (visible?.item?.daily_hours) {
                Object.entries(visible?.item?.daily_hours)?.forEach(([key, value]: any) => {
                    vals = {
                        ...vals,
                        [`day_hour-${key}`]: value
                    }
                })
            }
            setDaylyHours(visible?.item?.daily_hours)
            form?.setFieldsValue(vals)
        } else {
            form?.resetFields();
            setDaylyHours(undefined)
        }
    }, [visible?.item, visible?.type, visible?.item && visible?.item?.id])

    useEffect(() => {
        if (visible?.item == null) {
            setDaylyHours(undefined)
        }
    }, [visible?.type])

    const addOrRemoveDay = (type: "add" | "remove", key: number) => {
        if (type == "add") {
            if (daylyHours) {
                console.log(["1", "2", "3", "4", "5", "6", "7"]?.filter((k: string) => !Object?.keys(daylyHours)?.includes(k)));
            }

            setDaylyHours({
                ...daylyHours,
                [`${daylyHours ? ["1", "2", "3", "4", "5", "6", "7"]?.filter((k: string) => !Object?.keys(daylyHours)?.includes(k))[0] : 1}`]: form?.getFieldValue("hour_day") ?? null
            })
        } else {
            if (daylyHours) {
                let dh: dailyHoursType = {}
                Object?.entries(daylyHours)?.forEach(([key1, value]: any) => {
                    if (key != key1) {
                        dh = { ...dh, [`${key1}`]: value }
                    }
                })
                setDaylyHours(dh)
            }
        }
    }


    return (
        <Row gutter={24}>
            {
                !visible?.item ? langs?.selected?.map((item: string, i: number) =>
                    <Col key={i} span={24}>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={8} lg={6}>
                                <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`name[${item}]`} label={`Name (${item})`}>
                                    <Input placeholder={`Name (${item})`} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={14}>
                                <Form.Item name={`description[${item}]`} label={`Description (${item})`}>
                                    <Input placeholder={`Description (${item})`} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={4} lg={4}>
                                <div className="d-flex mt-1">
                                    <Select className="mt-4" defaultValue={item} style={{ width: '100px' }} onChange={(newElement) => { setSelectedLang(newElement, item) }
                                    }>
                                        {
                                            langs?.all && langs?.all?.map((l: string, i: number) =>
                                                !langs?.selected.includes(l) || l === item ? <Select.Option key={i} value={l}>{getName(l)}</Select.Option> : ''
                                            )
                                        }
                                    </Select>
                                    <Button className="mt-4 ms-1 d-flex justify-content-center align-items-center" shape="circle" onClick={() => removeInput(item)} disabled={langs?.selected?.length === 1} >
                                        <FaTimes />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                ) :
                    <>
                        <Col xs={24} sm={24} md={9} lg={7}>
                            <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`name[${i18n.language}]`} label={`Name`}>
                                <Input placeholder={`Name`} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={15} lg={17}>
                            <Form.Item name={`description[${i18n.language}]`} label={`Description`}>
                                <Input placeholder={`Description`} />
                            </Form.Item>
                        </Col>
                    </>
            }
            {!visible?.item ? <Col span={24}>
                <Button onClick={() => addSelectedLang(langs?.noneSelected[0])} disabled={langs?.noneSelected?.length === 0} className="m-0 p-0" type="link">+ Til qo'shish</Button>
                <hr />
            </Col> : ''}
            <Col xs={24} sm={24} md={8} lg={6}>
                <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`type`} label={`Type`}>
                    <Select placeholder={`Type`}>
                        <Select.Option value={1}>1</Select.Option>
                        <Select.Option value={2}>2</Select.Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
                <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`rate`} label={`Rate`}>
                    <Select placeholder={`Rate`}>
                        <Select.Option value={0.25}>0.25</Select.Option>
                        <Select.Option value={0.5}>0.5</Select.Option>
                        <Select.Option value={0.75}>0.75</Select.Option>
                        <Select.Option value={1}>1</Select.Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
                <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`weekly_hours`} label={`Weekly hours`}>
                    <Input type="number" placeholder={`Type`} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
                <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`hour_day`} label={`Hour day`}>
                    <Input type="number" placeholder={`Hour day`} />
                </Form.Item>
            </Col>
            <Col span={24}>
                <hr />
                <h6>By week days</h6>
            </Col>
            {
                daylyHours && Object?.entries(daylyHours)?.map(([key, value]: any) =>
                    <Col className="d-flex pt-1" xs={24} sm={24} md={3} lg={3}>
                        <div>
                            <Form.Item rules={[{ required: true, message: "Please type a hour!" }]} initialValue={value} name={`day_hour-${key}`} label={`${weekDays[Number(key - 1)]}`}>
                                <Input type="number" placeholder={`${weekDays[Number(key - 1)]}`} />
                            </Form.Item>
                        </div>
                        <div className="mt-2">
                            <Button onClick={() => addOrRemoveDay("remove", key)} style={{ marginTop: "22px" }} type="primary" danger icon={<FaMinus />}></Button>
                        </div>
                    </Col>
                )
            }
            <Col xs={24} sm={24} md={3} lg={3}>
                <div className="mt-2">
                    <Button disabled={daylyHours && Object?.keys(daylyHours).length === 7} onClick={() => addOrRemoveDay("add", daylyHours ? Object.keys(daylyHours).length + 1 : 1)} style={{ marginTop: "21px" }} type="link">+ Add day</Button>
                </div>
            </Col>
            <Col span={24}>
                <Form.Item valuePropName="checked" name={"status"} label="Status">
                    <Switch />
                </Form.Item>
            </Col>
            <Col className="d-flex justify-content-end" span={24}>
                <Button className="m-1" type="primary" danger htmlType="button" onClick={() => setVisible({ item: null, type: "hide" })}>Bekor qilish</Button>
                <Button className="m-1" htmlType="reset">Tozalash</Button>
                <Button className="m-1" type="primary" htmlType="submit">Saqlash</Button>
            </Col>
        </Row>
    )
}

export default UiForms;