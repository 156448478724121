import store from 'store/index';
import { restoreUi } from 'store/structure';



export const save_before_load = () => {
    const ui = store.getState().ui;
    const sidebar = ui.sidebar;
    const theme = ui.theme;

    localStorage.setItem('sidebar', JSON.stringify(sidebar));
    localStorage.setItem('theme', JSON.stringify(theme));
}

export const save_after_load = () => {
    const sidebar = JSON.parse(localStorage.getItem('sidebar') || "{}");
    const theme = JSON.parse(localStorage.getItem('theme') || "{}");

    if (sidebar && Object.getOwnPropertyNames(sidebar).length) {
        store.dispatch(restoreUi({ sidebar: sidebar }))
    }
    if (theme && Object.getOwnPropertyNames(theme).length) {
        store.dispatch(restoreUi({ theme: theme }));
    }

    localStorage.removeItem('sidebar');
    localStorage.removeItem('theme');
    sessionStorage.removeItem("page_reloading");

}
