import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './login.scss'
import SignIn from './signIn';
import { Button, Form, Input } from "antd";
import loginImg from '../../assets/images/loginImg.png'
import { useHistory } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';

const Login: FC = (): JSX.Element => {

    const dispatch: any = useDispatch();
    const history = useHistory();
    const [isLoad, setIsLoad] = useState<boolean>(false);


    const onFinish = async (values: any) => {
        const formdata = new FormData();
        for (const [key, value] of Object.entries<any>(values)) {
            formdata.append(key, value)
        }
        formdata.append("is_main", '1')
        const arg = {
            type: 'login',
            data: formdata
        }
        await dispatch(SignIn(arg));
    };


    useEffect(() => {
        history.listen((location) => {
            if (location.pathname === localStorage.getItem("_url")) {
                localStorage.removeItem("_url");
            }
        })
    }, [])


    return (
        <div className="login-page" style={{ position: "relative", overflow: "hidden" }}>
            {/* <Snow /> */}
            <div className="login-box">
                <Form
                    name="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <div className='d-f justify-content-center'>
                        <div style={{ border: "1px solid rgba(13, 138, 114, 1)", borderRadius: "50%", backgroundColor: "rgba(13, 138, 114, .9)" }} className="mb-3">
                            <FaUserAlt color='white' size={35} className="m-3" />
                        </div>
                    </div>
                    <p className="form-title">Welcome to Texnikum TSUL</p>
                    <p className='text-center'>Login to the Dashboard</p>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input
                            placeholder="Username"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            LOGIN
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
export default Login;