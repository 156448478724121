import { FC } from "react"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table"
import { Tooltip, Popconfirm, Pagination } from "antd";
import {  FaFileSignature } from 'react-icons/fa';
import { TbFileInvoice, TbFileOff, TbFilePencil } from "react-icons/tb";
import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
import { delete_data } from "services";
import { getAllDirections } from "./service";
import { asyncN } from "utils/notifications";
import EmptyTable from "components/EmptyTable";
import IndexPageTitle from "components/IndexPageTitle";
import TotalCount from "components/totalCountTag";
import { TableLineLoader } from "components/SimpleComponents";
import EditDirection from "./crud/edit";
import CreateDirection from "./crud/create";
import DirectionView from "./crud/view";
import { ButtonCreate } from "components/Buttons";
import DiscriptionStyle from "components/DescriptionStyle";
import ForTable from "components/ForTable";
import { AiOutlineCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import ForTitle from "components/ForTitles";


const Directions: FC = (): JSX.Element => {

    const { i18n, t } = useTranslation();
    const [data, setData] = useState([]);
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [reRequest, setReRequest] = useState<boolean>(false);
    const [tableOption, setTableOption] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10)
    const [currentPage, setCurrentPage] = useState<number>(1)

    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true)
                    const response = await getAllDirections(perPage, currentPage, 'expand=description,faculty');
                    setData(response?.items)
                    setTableOption(response?._meta)
                    setLoading(false);
                } catch (error: any) {
                    asyncN('error', 'read', error?.response?.message)
                }
            }
        )()
    }, [i18n.language, reRequest, perPage, currentPage])


    const deleteOne = (id: number) => {
        delete_data('directions', id).then(() => setReRequest(!reRequest))
    }

    return (
        <div>
            <ForTitle title="Directions" buttons={[isHasAuthRoute('direction_create') && <ButtonCreate onClick={() => setVisibleCreate(true)} />]}/>
            { visibleView && id ? <DirectionView visible={visibleView} setVisible={setVisibleView} id={id} /> : null}
            { visibleEdit && id ? <EditDirection visible={visibleEdit} setVisible={setVisibleEdit} id={id} setReRequest={setReRequest} reRequest={reRequest} /> : null}
            { visibleCreate ? <CreateDirection visible={visibleCreate} setVisible={setVisibleCreate} setReFetch={setReRequest} /> : null }
            
            <ForTable totalCount={tableOption?.totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} datas={data} theads={
                    [
                        <>{t("Name")}</>,
                        <>{t("Description")}</>,
                        <>{t("Texnikum")}</>,
                        <>{t("Status")}</>,
                        <>{t("Actions")}</>
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            this.name,
                            <div style={{ maxWidth: "700px" }} > <DiscriptionStyle col={2}>{this?.description}</DiscriptionStyle></div>,
                            this?.faculty?.name,
                            <BadgeStatus status={this.status} />,
                            <div className="crud_actions_table" >
                                {isHasAuthRoute("direction_view") ? <Tooltip placement="top" title={t("View")}><TbFileInvoice  className="view_btn" onClick={() => { setVisibleView(true); setId(this.id) }} /></Tooltip> : null}
                                {isHasAuthRoute("direction_update") ? <Tooltip placement="top" title={t("Edit")}><TbFilePencil className="edit_btn" onClick={() => { setVisibleEdit(true); setId(this.id) }} /></Tooltip> : null}
                                {isHasAuthRoute("direction_delete") ?
                                    <Tooltip placement="top" title={t("Delete")}>
                                        <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`} okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>} onConfirm={() => deleteOne(this.id)}>
                                            <TbFileOff className="delete_btn" />
                                        </Popconfirm>
                                    </Tooltip> : null}
                            </div>,
                        ]
                    }
                } loading={loading}/>
        </div>
    )
}

export default Directions;
