import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type TypeExamInitialState = {
    filters: {
        filter: {
            subject_id: undefined | number,
            status_id: undefined | number
        },
        filter_like: {
            text: string
        },
        elements: {
            subject_id: { [key: string | number]: any },
            status_id: { [key: string | number]: any },
        },
        perPage: number,
        currentPage: number
    }
}

const initialState: TypeExamInitialState = {
    filters: {
        filter: {
            subject_id: undefined,
            status_id: undefined
        },
        filter_like: {
            text: ''
        },
        elements: {
            subject_id: {},
            status_id: {},
        },
        perPage: 15,
        currentPage: 1
    }
}

const examSlice = createSlice({
    name: 'exam',
    initialState,
    reducers: {
        changeFilterExam(state: TypeExamInitialState, action: PayloadAction<{ name: string, value: undefined | number | string, elements: Array<{ [key: string | number]: any }> }>) {

            const { name, value, elements } = action.payload;

            if (name) {

                if (String(name).endsWith("_id") && (typeof value === 'number' || typeof value === 'undefined')) {

                    const filed_name: keyof TypeExamInitialState['filters']['filter'] = name as keyof TypeExamInitialState['filters']['filter'];

                    if (elements && Array.isArray(elements) && elements.length) {

                        const findElement = elements.find(e => e.id === value);

                        if (findElement && Object.getOwnPropertyNames(findElement).length) {

                            state.filters.elements[filed_name] = findElement || {};

                        }

                    }

                    state.filters.filter[filed_name] = value;

                } else if (typeof value === 'string') {

                    const filed_name: keyof TypeExamInitialState['filters']['filter_like'] = name as keyof TypeExamInitialState['filters']['filter_like'];

                    state.filters.filter_like[filed_name] = value;

                } else {

                    if (name === 'perPage') {

                        state.filters.perPage = Number(action.payload.value) || 15;

                    } else {

                        state.filters.currentPage = Number(action.payload.value) || 1;

                    }

                }
            }
        }
    }
})


export default examSlice;