import { Popconfirm, Tooltip } from "antd";
import useDeleteOne from "hooks/useDeleteOne";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { FaFileSignature } from "react-icons/fa";
import { TbFileOff } from "react-icons/tb";
import { TbFilePencil } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { TiDocumentDelete } from "react-icons/ti";
import { delete_data } from "services";
import isHasAuthRoute from "utils/isHasRoute";

type ActionsType = {
  id: number,
  url: string,
  setReFetch: React.Dispatch<React.SetStateAction<boolean>>,
  setId: React.Dispatch<React.SetStateAction<number | null>>,
  setVisibleView: React.Dispatch<React.SetStateAction<boolean>>,
  setVisibleEdit: React.Dispatch<React.SetStateAction<boolean>>,
  viewPermission: string,
  editPermission: string,
  deletePermission: string,
}


const Actions: React.FC<ActionsType> = ({ id, url, setId, setReFetch, setVisibleView, setVisibleEdit, viewPermission, editPermission, deletePermission }) => {
  const {t} = useTranslation();
  const deleteData = () => delete_data(url, id).then(() => setReFetch(prevState => !prevState))

  return (
    <div className="crud_actions_table" >
      {isHasAuthRoute(viewPermission) ? <Tooltip placement="top" title={t("View")}><TbFileInvoice  className="view_btn" onClick={() => { setVisibleView(true); setId(id) }} /></Tooltip> : null}
      {isHasAuthRoute(editPermission) ? <Tooltip placement="top" title={t("Edit")}><TbFilePencil className="edit_btn" onClick={() => { setVisibleEdit(true); setId(id) }} /></Tooltip> : null}
      {isHasAuthRoute(deletePermission) ?
        <Tooltip placement="top" title={t("Delete")}>
          <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`} okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>} onConfirm={deleteData}>
            <TbFileOff className="delete_btn" />
          </Popconfirm>
        </Tooltip> : null}
    </div>
  )
}

export default Actions;