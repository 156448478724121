import { Button, Checkbox, Col, Row, Spin, Tag } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { getAllLanguages } from "services";
import { excelExport } from "utils/excelExport";
import { getAllStudents } from "../service";
import { TypeStudentEDD } from "../types";

const ExcelExport: FC<{
    excelElements: TypeStudentEDD,
    setExcelElements: (excelElements: TypeStudentEDD) => void,
    filter?: string,
    perPage?: number,
    current?: number
}> = ({
    excelElements,
    setExcelElements,
    filter,
    perPage,
    current
}): JSX.Element => {

        const { t } = useTranslation()
        const [allData, setAllData] = useState<Array<any>>([])
        const [data, setData] = useState<Array<any>>([])
        const [langs, setLangs] = useState<Array<any>>([]);
        const [loading, setLoading] = useState<boolean>(false);

        const dic: any = {
            address: t("Address"),
            area: t("Area"),
            birthday: t("Birthday"),
            country: t("Country"),
            edu_plan: t("Education plan"),
            faculty: t("Texnikum"),
            first_name: t("First name"),
            lang: t("Education language"),
            last_name: t("Last name"),
            middle_name: t("Middle name"),
            nationality: t("Nationality"),
            passport_given_by: t("Passport given by"),
            passport_given_date: t("Passport given date"),
            passport_issued_date: t("Passport issued date"),
            passport_pin: t("Passport pin"),
            passport_seria: t("Passport seria"),
            password: t("Password"),
            region: t("Region"),
            phone: t("Phone"),
            secound_phone: t("Secound phone"),
            username: t("Username")
        }

        useEffect(() => {
            (
                async () => {
                    const resp = await getAllLanguages();
                    if (resp?.items) {
                        setLangs(resp?.items);
                    }
                }
            )()
        }, [])

        useEffect(() => {
            (
                async () => {
                    setLoading(true)
                    const resp = await getAllStudents(
                        perPage ? perPage : 100,
                        current ? current : 1,
                        `expand=profile,usernamePass,user,faculty,direction,eduYear,eduType,eduPlan,eduLang,eduForm,course,createdBy,updatedBy,region,area,country,permanentArea,permanentCountry,permanentRegion,citizenship,nationality,socialCategory,residenceStatus,categoryOfCohabitant,studentCategory${filter ? '&' + filter : ''}`)
                    setAllData(resp?.items)
                    setLoading(false)
                }
            )()
        }, [perPage, current, filter])

        const getLang = (id: number): string => {
            if (id)
                return langs?.filter((item: { id: number, name: string }) => id === item?.id)[0]?.name;
            return ""
        }

        useEffect(() => {
            (
                async () => {
                    if (allData?.length > 0) {
                        setLoading(true)
                        let newData: Array<any> = [];
                        allData?.map((item: any) => {
                            let a = {};
                            if (excelElements?.first_name) {
                                a = { ...a, first_name: item?.profile?.first_name }
                            }
                            if (excelElements?.last_name) {
                                a = { ...a, last_name: item?.profile?.last_name }
                            }
                            if (excelElements?.middle_name) {
                                a = { ...a, middle_name: item?.profile?.middle_name }
                            }
                            if (excelElements?.edu_plan) {
                                a = { ...a, edu_plan: item?.eduPlan?.name }
                            }
                            if (excelElements?.password) {
                                a = { ...a, password: item?.usernamePass?.password }
                            }
                            if (excelElements?.username) {
                                a = { ...a, username: item?.usernamePass?.username }
                            }
                            if (excelElements?.birthday) {
                                a = { ...a, birthday: item?.profile?.birthday }
                            }
                            if (excelElements?.faculty) {
                                a = { ...a, faculty: item?.faculty?.name }
                            }
                            if (excelElements?.country) {
                                a = { ...a, country: item?.country?.name }
                            }
                            if (excelElements?.region) {
                                a = { ...a, region: item?.region?.name }
                            }
                            if (excelElements?.area) {
                                a = { ...a, area: item?.area?.name }
                            }
                            if (excelElements?.address) {
                                a = { ...a, address: item?.profile?.address }
                            }
                            if (excelElements?.lang) {
                                a = { ...a, lang: getLang(item?.edu_lang_id) }
                            }
                            if (excelElements?.passport_seria) {
                                a = { ...a, passport_seria: item?.profile?.passport_seria + " " + item?.profile?.passport_number }
                            }
                            if (excelElements?.passport_pin) {
                                a = { ...a, passport_pin: item?.profile?.passport_pin }
                            }
                            if (excelElements?.passport_given_date) {
                                a = { ...a, passport_given_date: item?.profile?.passport_given_date }
                            }
                            if (excelElements?.passport_issued_date) {
                                a = { ...a, passport_issued_date: item?.profile?.passport_issued_date }
                            }
                            if (excelElements?.passport_given_by) {
                                a = { ...a, passport_given_by: item?.profile?.passport_given_by }
                            }
                            if (excelElements?.nationality) {
                                a = { ...a, nationality: item?.nationality?.name }
                            }
                            if (excelElements?.phone) {
                                a = { ...a, nationality: item?.nationality?.name }
                            }
                            newData.push(a)
                        });
                        setData(newData);
                        setLoading(false)
                    }
                }
            )()
        }, [excelElements, allData, langs])

        const loadExcel = async () => {
            let newData = data?.map((item: any) => {
                let obj: any = {}
                Object.entries(item).map(([key, value]: any) => {
                    obj = {
                        ...obj,
                        [`${dic[`${key}`]}`]: value
                    }
                })

                return obj
            })

            excelExport(newData, "students")

        }

        return (
            <Spin spinning={loading}>
                <Row gutter={24}>
                    <Col span={24} className="pb-2">
                        <div className="d-flex flex-wrap justify-content-start">
                        {
                            Object.entries(excelElements)?.map(([key, value]: any) => {
                                return (
                                    key !== "visible" && <div className="m-1">
                                        <Checkbox onChange={(e) => {
                                            setExcelElements({
                                                ...excelElements,
                                                [`${key}`]: e?.target?.checked
                                            })
                                        }} checked={value}>{dic[`${key}`]}</Checkbox>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col style={{ height: "80vh", overflow: "auto" }} span={24}>
                        <Table>
                            <Thead className="bg-success text-light">
                                <Tr>
                                    <Th>#</Th>
                                    {excelElements?.first_name && <Th>{t("First name")}</Th>}
                                    {excelElements?.last_name && <Th>{t("Last name")}</Th>}
                                    {excelElements?.middle_name && <Th>{t("Middle name")}</Th>}
                                    {excelElements?.username && <Th>{t("Username")}</Th>}
                                    {excelElements.password && <Th>{t("Password")}</Th>}
                                    {excelElements?.edu_plan && <Th>{t("Education plan")}</Th>}
                                    {excelElements?.birthday && <Th>{t("Birthday")}</Th>}
                                    {excelElements?.faculty && <Th>{t("Texnikum")}</Th>}
                                    {excelElements?.country && <Th>{t("Country")}</Th>}
                                    {excelElements?.region && <Th>{t("Region")}</Th>}
                                    {excelElements?.area && <Th>{t("Area")}</Th>}
                                    {excelElements?.address && <Th>{t("Address")}</Th>}
                                    {excelElements?.lang && <Th>{t("Education language")}</Th>}
                                    {excelElements?.passport_seria && <Th>{t("Passport seria")}</Th>}
                                    {excelElements?.passport_pin && <Th>{t("Passport pin")}</Th>}
                                    {excelElements?.passport_given_by && <Th>{t("Passport given by")}</Th>}
                                    {excelElements?.passport_given_date && <Th>{t("Passport given date")}</Th>}
                                    {excelElements?.passport_issued_date && <Th>{t("Passport issued date")}</Th>}
                                    {excelElements?.nationality && <Th>{t("Nationality")}</Th>}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    data?.map((item: any, i: number) => {
                                        return <Tr key={i}>
                                            <Td>{i + 1}</Td>
                                            {excelElements?.first_name && <Td>
                                                {item?.first_name}
                                            </Td>}
                                            {excelElements?.last_name && <Td>
                                                {item?.last_name}
                                            </Td>}
                                            {excelElements?.middle_name && <Td>
                                                {item?.middle_name}
                                            </Td>}
                                            {excelElements?.username && <Td>
                                                {item?.username}
                                            </Td>}
                                            {excelElements?.password && <Td>
                                                {item?.password}
                                            </Td>}
                                            {excelElements?.edu_plan && <Td>
                                                {item?.edu_plan}
                                            </Td>}
                                            {excelElements?.birthday && <Td>
                                                {item?.birthday}
                                            </Td>}
                                            {excelElements?.faculty && <Td>
                                                {item?.faculty}
                                            </Td>}
                                            {excelElements?.country && <Td>
                                                {item?.country}
                                            </Td>}
                                            {excelElements?.region && <Td>
                                                {item?.region}
                                            </Td>}
                                            {excelElements?.area && <Td>
                                                {item?.area}
                                            </Td>}
                                            {excelElements?.address && <Td>
                                                {item?.address}
                                            </Td>}
                                            {excelElements?.lang && <Td>
                                                {getLang(item?.lang)}
                                            </Td>}
                                            {excelElements?.passport_seria && <Td>
                                                {item?.passport_seria}
                                            </Td>}
                                            {excelElements?.passport_pin && <Td>
                                                {item?.passport_pin}
                                            </Td>}
                                            {excelElements?.passport_given_by && <Td>
                                                {item?.passport_given_by}
                                            </Td>}
                                            {excelElements?.passport_given_date && <Td>
                                                {item?.passport_given_date}
                                            </Td>}
                                            {excelElements?.passport_issued_date && <Td>
                                                {item?.passport_issued_date}
                                            </Td>}
                                            {excelElements?.nationality && <Td>
                                                {item?.nationality}
                                            </Td>}
                                        </Tr>
                                    })
                                }
                            </Tbody>
                        </Table>
                    </Col>
                    <Col span={24} className="d-flex justify-content-end mt-2">
                        <Button type="primary" onClick={loadExcel}>{t("Download")}</Button>
                    </Col>
                </Row>
            </Spin>
        )
    }

export default ExcelExport;