import { FC, useEffect, useState } from "react";
import { getAllEduSemestrs } from "../service";
import { asyncN } from "utils/notifications";
import CustomBreadcrumb from "components/Breadcrumb";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table";
import { Switch, Tooltip } from "antd";
import instance from "config/_axios";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import { TbFileInvoice } from "react-icons/tb";
import SemesterEditModal from "./semesterEditModal";
import isHasAuthRoute from "utils/isHasRoute";
import { NavLink, useLocation } from "react-router-dom";
import ForTable from "components/ForTable";

const _breadcrump = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Education Plans",
    path: "edu_plan_index",
  },
  {
    name: "Semesters of edu plan",
    path: "edu-plan_view",
  },
];

const EduPlanViewSemestr: FC = (props: any): JSX.Element => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [refresh, setrefresh] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false)
  const [id, setid] = useState<number | null>(null);
  const location: any = useLocation();
  const a: Array<string> = location?.pathname.split('/');
  const _id = a[a?.length - 1];
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(20)

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        // const response = await getAllEduSemestrs("expand=createdBy,updatedBy,description,faculty,direction,eduYear,eduType,eduSemestrs");
        const response = await getAllEduSemestrs(
          `expand=description,eduYear,course, eduPlan&filter={"edu_plan_id":${_id}}&per-page=${perPage}&page=${currentPage}`
        );
        setData(response);
        setLoading(false)
      } catch (error: any) {
        asyncN("error", "read", error?.response?.message);
      }
    })();
  }, [refresh, currentPage, perPage]);

  const onSubmit = async (id: number, status: boolean, type: string) => {
    try {
      const formdata = new FormData();
      if (type === "status") {
        formdata.append("status", status ? "1" : "0")
      } else {
        formdata.append("is_checked", status ? "1" : "0")
      }

      const response = await instance({
        url: `/edu-semestrs/${id}`,
        method: "PUT",
        data: formdata,
      });
      if (response.data.status === 1) {
        setrefresh(!refresh)
      }
    } catch (error: any) {
      if (error?.response?.status === 422) {
        if (error?.response?.data?.status === 0) {

        }
      }
      asyncN("error", "create", error?.response?.message);
    }
  };

  console.log(data);
  

  return (
    <>
      <CustomBreadcrumb arr={_breadcrump} />
      {/* { visible, setVisible, id, setReRequest, reRequest } */}
      <SemesterEditModal setVisible={setVisible} visible={visible} setReRequest={setrefresh} reRequest={refresh} id={id} />
      {data ? (
        <div>
          <h5 style={{ fontWeight: 400 }} className="my-3">
            {data[0]?.eduPlan?.name}
          </h5>
          <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={data?._meta?.totalCount} datas={data?.items} theads={[
            <>{t("Name")}</>,
            <>{t("Course")}</>,
            <>{t("Semestr")}</>,
            <>{t("Academic year")}</>,
            <>{t("Term")}</>,
            <>{t("Confirmation")}</>,
            <>{t("Status")}</>,
            <>{t("Subject")}</>,
            <>{t("Edit")}</>
          ]} tbodys={function(this: any){
            return [
                <>{this?.name}</>,
                <>{this?.course?.name}</>,
                <>{this?.semestr_id}</>,
                <>{this?.eduYear?.name}</>,
                <>
                  {this?.start_date} - {this?.end_date}
                </>,
                <>
                  <Switch
                    checked={Number(this?.is_checked) === 1}
                    onChange={(e: boolean) => onSubmit(this.id, e, "check")}
                    size="small"
                    checkedChildren="on"
                    unCheckedChildren="off"
                  />
                </>,
                <>
                  <Switch
                    checked={Number(this?.status) === 1}
                    onChange={(e: boolean) => onSubmit(this.id, e, "status")}
                    size="small"
                    checkedChildren="on"
                    unCheckedChildren="off"
                  />
                </>,
                <>
                  {isHasAuthRoute("edu-semestr-subject_index") && (
                    <NavLink to={{
                      pathname: `/edu-semestr-subject_create/${this?.id}`,
                      state: {
                        id: this?.id,
                        parent_path: [
                          {
                            name: "Education Plans",
                            path: "edu_plan_index",
                          },
                          {
                            name: "Semesters of edu plan",
                            path: `edu-plan_view/${_id}`,
                          },
                        ]
                      }
                    }} className="d-block">
                      {t("Attach")}
                    </NavLink>
                  )}
                </>,
                <>
                  <div className="crud_actions_table">
                    {isHasAuthRoute("edu-semestr_view") && (
                      <Tooltip placement="top" title={t("View")}>
                        <NavLink to={{
                          pathname: `/edu-semestr_view/${this?.id}`,
                          state: {
                            id: this?.id,
                            parent_path: [
                              {
                                name: "Education Plans",
                                path: "edu_plan_index",
                              },
                              {
                                name: "Semesters of edu plan",
                                path: `edu-plan_view/${_id}`,
                              },
                            ]
                          }
                        }} className="d-block">
                          <TbFileInvoice  className="view_btn" />
                        </NavLink>
                      </Tooltip>
                    )}
                    {isHasAuthRoute("edu-semestr_update") && (
                      <Tooltip placement="top" title={t("Edit")}>
                        <FaEdit className="edit_btn" style={{ cursor: 'pointer' }} onClick={() => { setVisible(true); setid(this.id) }} />
                      </Tooltip>
                    )}
                  </div>
                </>
            ]
          }} loading={loading}/>
        </div>
      ) : null
    }
    </>
  );
};

export default EduPlanViewSemestr;
