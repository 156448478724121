import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Row, Col, Select, Button } from 'antd';
import { FaTimes } from 'react-icons/fa';
import { getAllLanguages } from 'services';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

const EduDetails: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const [languages, setLanguages] = useState<Array<any>>([]);
    const [names, setNames] = useState<Array<string>>(['uz'])

    useEffect(() => {
        (
            async () => {
                const response:any = await getAllLanguages();
                setLanguages(response?.items)
            }
        )()
    }, [])

    const onAddNewForm = () => {
        const langId = languages.filter(e => !names.includes(e.lang_code))
        if (langId.length) {
            setNames(prevState => ([...prevState, langId[0].lang_code]))
        }
    }


    const removeInput = (id: string) => {
        const newNames = names.filter(e => e !== id);
        setNames(newNames)
    }

    const selectLanguage = (newValue: string, oldValue: string) => {
        const index = names.indexOf(oldValue);

        if (!names.includes(newValue)) {
            names.splice(index, 1, newValue)
            setNames([...names]);
        } else {
            return
        }
    }

    return (
        <>
                <Row gutter={[12, 0]}>
                    {
                        names.map((e: any) => {
                            return (
                                <React.Fragment key={e} >
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                        <Form.Item
                                            label={`${t("Ilmiy unvoni")} (${e})`}
                                            name={`ilmiy_unvon[${e}]`}
                                            shouldUpdate
                                            rules={[{ required: true, message: `${t("Fill in the field")} (${e})!` }]}
                                        >
                                            <TextArea rows={1} placeholder={`${t("Ilmiy unvoningizni kiriting")} (${e}) ...`} />
                                        </Form.Item>
                                    </Col>
                                    <Col key={e} xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} className="form_ui_flex">
                                        <Form.Item
                                            label={`${t("Ilmiy datajasi")} (${e})`}
                                            name={`ilmiy_daraja[${e}]`}
                                            shouldUpdate
                                            rules={[{ required: true, message: `${t("Fill in the field")} (${e})!` }]}
                                            className="form_ui_flex_form_item"
                                        >
                                            <TextArea rows={1} placeholder={`${t("Ilmiy darajangizni kiriting")} (${e}) ...`} />
                                        </Form.Item>&nbsp;
                                        <span style={{ marginTop: '30px' }}>
                                            <Select defaultValue={e} style={{ width: '100px' }} className="me-2" onChange={(newElement) => selectLanguage(newElement, e)}>
                                                {
                                                    languages.length && languages.map((l: any) => {
                                                        if (!names.includes(l.lang_code) || l.lang_code === e) {
                                                            return <Option key={l.id} value={l.lang_code}>{l.name}</Option>
                                                        }
                                                    })
                                                }
                                            </Select>
                                            <Button type="primary" danger shape="circle" onClick={() => removeInput(e)} disabled={names.length === 1} >
                                                <FaTimes />
                                            </Button>
                                        </span>
                                    </Col>
                                </React.Fragment>
                            )
                        })
                    }
                    <Col lg={{ span: 24 }} className="mb-2">
                        {names.length !== languages.length ?
                            <Button type="link" className="ps-0" onClick={onAddNewForm}>+ {t("Add translation")}</Button> :
                            <Button type="link" className="ps-0" danger>+ {t("Add translation")}</Button>}
                    </Col>
                </Row>
        </>
    )
}


export default EduDetails;