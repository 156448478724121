import { ChangeEvent, FC } from "react"
import { useEffect, useState } from "react";
import { Link, NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table"
import { Tooltip, Popconfirm, Pagination, Input, Tag, Select, Row, Col, Button } from "antd";
import { FaChartLine } from 'react-icons/fa';
import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
// @ts-ignore
import SubjectView from "./crud/view";
// @ts-ignore
import EditSubject from "./crud/edit";
import { getAllSillabuses } from "./service";
import { asyncN } from "utils/notifications";
import EmptyTable from "components/EmptyTable";
import useDebounce from "hooks/useDebounce";
import { sillabusType } from "./types";
import IndexPageTitle from "components/IndexPageTitle";
import CreateButton from "components/CreateButton";
import useDeleteOne from "hooks/useDeleteOne";
import useGetAllData from "hooks/useGetAllData";
import instance from "config/_axios";
import { TableLineLoader } from "components/SimpleComponents";
import { BiRightArrowAlt } from "react-icons/bi";
import TotalCount from "components/totalCountTag";
import CreateSubject from "./crud/create";
import { ButtonCreate } from "components/Buttons";
import { Action } from "bizcharts";
import Actions from "components/Actions";
import { useAppSelector } from "store/services";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";


const { Search } = Input;
const { Option } = Select;

const Subjects: FC = (): JSX.Element => {

    const { i18n, t } = useTranslation();
    const history = useHistory();
    const auth: any = useAppSelector(state => state.auth);
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [reRequest, setReRequest] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(15)
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [text, setText] = useState<string>('');
    const [kafedra, setKafedra] = useState<Array<any>>();
    const [currentKafedra, setCurrentKafedra] = useState<number>();
    const [sillabus, setSillabus] = useState<any>(undefined)
    const debouncedValue = useDebounce<string>(text, 500);
    const _delete = useDeleteOne();
    const _subjects = useGetAllData({ url: `/subjects?sort=-id&expand=description,subjectSillabus&filter={"kafedra_id":${currentKafedra}}&q=${debouncedValue}`, perPage, currentPage, debouncedValue: [debouncedValue], isCall: 'auto', refetch: [_delete.refetch, reRequest, currentKafedra, debouncedValue] });


    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true);
                    _getKafedra();
                    setLoading(false);
                } catch (error: any) {
                    asyncN('error', 'read', error?.response?.message)
                }
            }
        )()
    }, [i18n.language, reRequest, perPage, currentPage, debouncedValue, _delete.refetch]);

    const _getKafedra = async () => {
        if (!kafedra?.length && isHasAuthRoute('kafedra_index')) {
            const res = await instance({ method: 'GET', url: '/kafedras?per-page=0' });
            if(res.data.status === 1){
                setKafedra(res.data?.data?.items);
            } else {
                asyncN("error", "read", "Malumot olishda xatolik");
            }
        }
    }

    return (
        <div>
            <ForTitle title="Subjects" buttons={[
                isHasAuthRoute('subject_index') && <Button onClick={() => history.push("/statistic-question")} type="primary" className="me-1 forStatistics" icon={<FaChartLine className="me-1" />}>Statistic</Button>,
                isHasAuthRoute('subject_create') && <ButtonCreate onClick={() => setVisibleCreate(true)} />
            ]}/>
            {visibleView && id ? <SubjectView id={id} visible={visibleView} sillabus={sillabus} setVisible={setVisibleView} /> : null}
            {visibleEdit && id ? <EditSubject visible={visibleEdit} setVisible={setVisibleEdit} id={id} setReRequest={setReRequest} reRequest={reRequest} /> : null}
            {visibleCreate ? <CreateSubject visible={visibleCreate} setVisible={setVisibleCreate} reRequest={reRequest} setReRequest={setReRequest} /> : null}
            <Row className="my-2" >
                <Col span={12}>
                    <Select
                        placeholder={t("Filter by lectern")}
                        value={currentKafedra}
                        allowClear
                        onChange={(e: number) => { _getKafedra(); setCurrentKafedra(e) }}
                        style={{ width: 300 }}
                        disabled={!isHasAuthRoute('kafedra_index')}
                    >
                        {kafedra?.length && kafedra.map((item: { id: number, name: string }, i: number) =>
                            <Option key={i} value={item?.id}>{item?.name}</Option>
                        )}
                    </Select>
                </Col>
                <Col span={12} className="d-flex justify-content-end" >
                    <Search placeholder={`${t("Input search text")} ...`} value={text} onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)} style={{ width: 300 }} />
                </Col>
            </Row>
            <ForTable totalCount={_subjects.data._meta.totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} loading={loading} datas={_subjects.data.items} theads={
                [
                    <>{t("Id")}</>,
                    <>{t("Name")}</>,
                    <>{t("Semestr")}</>,
                    <>{t("Topic")}</>,
                    <>{t("Sillabus")}</>,
                    <>{t("Status")}</>,
                    <>{t("Actions")}</>,
                ]
            } tbodys={
                function(this: any){
                    return [
                        this.id,
                        <Link to={(auth?.user?.role?.length == 1 && auth?.user?.role[0] === "teacher") ?
                                                `/subjects/${this.id}/${auth?.user?.user_id}/topics`
                                                : isHasAuthRoute('teacher-access_index') ? 
                                                `/subjects/${this.id}/teachers`
                                                : auth?.user?.role?.includes('teacher') ? 
                                                `/subjects/${this.id}/${auth?.user?.user_id}/topics`
                                                : null
                                            }>{this.name}</Link>,
                        this.semestr_id,
                        <Link to={(auth?.user?.role?.length == 1 && auth?.user?.role[0] === "teacher") ?
                                                `/subjects/${this.id}/${auth?.user?.user_id}/topics`
                                                : isHasAuthRoute('teacher-access_index') ? 
                                                `/subjects/${this.id}/teachers`
                                                : auth?.user?.role?.includes('teacher') ? 
                                                `/subjects/${this.id}/${auth?.user?.user_id}/topics`
                                                : null
                                            }>{t("Topic")}<BiRightArrowAlt /></Link>,
                        <Link to={{
                            pathname: '/subject-sillabus',
                            state: {
                                id: this.id,
                                name: this.name,
                                sillabus: this.subjectSillabus
                            }
                        }}>{t(this.subjectSillabus ? "Update" : "Attach")}</Link>,
                        <BadgeStatus status={this.status} />,
                        <Actions
                            id={this.id}
                            url="/subjects"
                            setId={setId}
                            setReFetch={setReRequest}
                            setVisibleView={setVisibleView}
                            setVisibleEdit={setVisibleEdit}
                            viewPermission="subject_view"
                            editPermission="subject_update"
                            deletePermission="subject_delete"
                        />
                    ]
                }
            }/>
        </div>
    )
}

export default Subjects;



/*

kafedra_create
kafedra_delete
kafedra_index
kafedra_update
kafedra_user-access
kafedra_view

*/