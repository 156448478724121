
import EmptyTable from 'components/EmptyTable';
import { Tag, Pagination, Input, Tooltip, Button, Collapse, Popover, Select, Form } from 'antd';
import CustomBreadcrumb from 'components/Breadcrumb';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Thead, Tr, Th, Tbody, Td } from 'react-super-responsive-table';
import { useRouteMatch, match } from 'react-router';
import useDebounce from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import useGetAllData from 'hooks/useGetAllData';
import { useHistory } from 'react-router-dom';
import _delete from 'pages/jobs/components/delete';
import { TbFilePencil } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import isHasAuthRoute from 'utils/isHasRoute';
import UpdateExamStudents from './crud/update';
import moment from 'moment';
import useGetOneData from 'hooks/useGetOneData';
import { exam_status_list, exam_student_status_list } from '../exam_status_list';
import { EmptySpaceLine, TableLineLoader } from 'components/SimpleComponents';
import { BiChevronDown, BiChevronUp, BiPlus } from 'react-icons/bi';
import InputMask from 'react-input-mask';
import { clearObjectFields } from 'utils/auxiliary_functions';
import { useAppDispatch, useAppSelector } from 'store/services';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import FormLabel from 'components/FormLabel';
import "../styles.scss";
import { IExamStudents } from 'models/exam';
import UpdateBallByTypeExamStudent from './crud/update_ball_by_type';
import { excelExport } from 'utils/excelExport';
import { RiFileExcel2Line } from 'react-icons/ri';

const { Panel } = Collapse;

function checkObjects(obj: { [key: string | number]: any }, type: 'filter' | 'filter-like') {

    const deepTypes = type === 'filter' ? [undefined] : type === 'filter-like' ? [undefined, null, ''] : [];

    const new_obj = clearObjectFields(obj, deepTypes);

    if (Object.getOwnPropertyNames(new_obj).length) return new_obj;

    return false;
}


export const encodeKey = (key: string): string => {
    if (Boolean(Number(key))) {
        let a: string = ""
        for (let i = 0; i < key?.length; i++) {
            a += String.fromCharCode(Number(key[i]) + 97)
        }
        return a
    } else {
        return "no encode";
    }
}

export const decodeKey = (key: string): string => {
    let a: string = ""
    for (let i = 0; i < key?.length; i++) {
        a += String(Number(key.charCodeAt(i) - 97));
    }

    return a;
}

const { Option } = Select;

const ExamStudents: FC = (): JSX.Element => {

    const match: match<any> | null = useRouteMatch('/student-exam-index/:exam_id');
    const dispatch: any = useAppDispatch();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm()
    const [updateModal, setUpdateModal] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>();
    const [refetch, setRefetch] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState<string>('');
    const [popoverBall, setPopoverBall] = useState<number | undefined>(undefined);



    const exam_id = match?.params?.exam_id;

    if (!exam_id) {
        history.goBack();
    }


    const { value, writeToUrl } = useUrlQueryParams({});
    const debouncedLastName = useDebounce<string>(value.filter_like?.last_name, 500);
    const debouncedFirstName = useDebounce<string>(value.filter_like?.first_name, 500);
    const debouncedPassportSeria = useDebounce<string>(value.filter_like?.passport_seria, 500);
    const debouncedPassportNumber = useDebounce<string>(value.filter_like?.passport_number, 500);
    const debouncedPassportPin = useDebounce<string>(value.filter_like?.passport_pin, 500);

    const student_exams = useGetAllData({ url: `/exam-students?expand=description,examStudentAnswers,allBall,student.profile,${exam_id ? `&filter={"exam_id":${exam_id}}` : null}${checkObjects(value.filter_like, 'filter-like') ? `&filter-like=${JSON.stringify(checkObjects(value.filter_like, 'filter-like'))}` : ''}`, isCall: 'auto', perPage: value?.perPage, currentPage: value.currentPage, refetch: [refetch] });
    const _exam = useGetOneData({ url: `/exams/${exam_id}?expand=subjectName`, isCall: 'auto' });


    const _breadcrump = useMemo(() => {
        return [
            {
                name: "Home",
                path: "/",
            },
            {
                name: "Exams",
                path: "/exam_index",
            },
            {
                name: _exam?.data?.name,
                path: '',
            },
        ];
    }, [_exam])

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;

        if (value !== '_______' && value !== '__') {
            writeToUrl({ name, value })
            // dispatch(changeFilterByExamStudent({ name, value }));

        }
    }

    useEffect(() => {
        student_exams.fetch()
    }, [i18n.language, value.perPage, value.currentPage, debouncedLastName, debouncedFirstName, debouncedPassportPin, debouncedPassportSeria, debouncedPassportNumber])


    const updateForAct = async (exam_student_id: number, act: number | string) => {
        try {
            const formdata = new FormData();
            formdata.append("act", Number(act) === 1 ? '0' : '1');
            const response = await instance({ url: `/exam-students/${exam_student_id}`, method: "PUT", data: formdata });
            if (response.data?.status === 1) {
                asyncN("success", 'create', response.data?.message).then(() => { setRefetch(!refetch) })
            } else {
                asyncN("error", 'update', response.data?.message);
            }
        } catch (error) {

        }
    }

    const exportStatistic = async () => {

        const res = await instance({
            method: "get",
            url: `/exam-students?expand=description,examStudentAnswers,student.profile&filter={"exam_id":${exam_id}}`,
            params: { "per-page": 0 }
        })

        if (res.data.status === 1) {
            let data: Array<any> = [];
            res.data.data.items?.map((e: any) => {
                data.push({
                    ["ID"]: e?.id,
                    [`${t("Familiya")}`]: e?.student?.profile?.last_name,
                    [`${t("Ism")}`]: e?.student?.profile?.first_name,
                    [`${t("Passport seria va raqam")}`]: `${e?.student?.profile?.passport_seria} ${e?.student?.profile?.passport_number}`,
                    [`${t("SHRJ")}`]: e?.student?.profile?.passport_pin,
                    [`${t("ON1/ON2/YN/All")}`]: `${e?.on1 ? e?.on1 : "_"} / ${e?.on2 ? e?.on2 : "_"} / ${e?.ball || e?.ball === 0 ? e?.ball : "_"} / ${(e?.ball ? e?.ball : 0) + e?.on1 + e?.on2}`,
                    [`${t("Chetlatish")}`]: Number(e?.act) === 1 ? "Chetlatilgan" : "",
                })
            })
            excelExport(data, "Exam Student");
        } else {
            asyncN("error", "read", res?.data?.message);
        }
    }

    return (
        <div className='' >
            <UpdateExamStudents visible={updateModal} setVisible={setUpdateModal} id={selectedData?.id} refetch={refetch} setRefetch={setRefetch} />
            <Collapse
                bordered={false}
                activeKey={activeKey}
                className="site-collapse-custom-collapse"
            >
                <Panel header={
                    <div className="w-100 d-f justify-content-between" onClick={e => e.stopPropagation()}>
                        <CustomBreadcrumb arr={_breadcrump} />
                        <div>
                            <Button onClick={exportStatistic} className="me-3 bg-success" icon={<RiFileExcel2Line className="m-1" />} type="primary">Excel</Button>
                            <Button type="link" className="text-primary fs-13" onClick={() => { setActiveKey(activeKey === '1' ? '' : '1') }} >Imtihon (batafsil) &nbsp;{activeKey === '1' ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}</Button>
                        </div>
                    </div>
                } key="1" showArrow={false} className="site-collapse-custom-panel ">
                    <Table id="tech-companies-1" className="table mt-2" style={{ border: '' }}>
                        <Thead>
                            <Tr>
                                <Th className="info_text_key text-center">{t('Exam')}</Th>
                                <Th className="info_text_key text-center">{t('Subject')}</Th>
                                <Th className="info_text_key text-center">{t('Ball')}</Th>
                                <Th className="info_text_key text-center">{t('Start time')}</Th>
                                <Th className="info_text_key text-center">{t('Finish time')}</Th>
                                <Th className="info_text_key text-center">{t('Duration')}</Th>
                                <Th className="info_text_key text-center">{t('Status')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td className="info_text_key text-center">{_exam?.data?.name}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.subjectName}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.ball}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.start}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.finish}</Td>
                                <Td className="info_text_key text-center">{_exam?.data?.duration ? moment.utc(Number(_exam?.data?.duration) * 1000).format("HH:mm:ss") : null}</Td>
                                <Td className="info_text_key text-center">{exam_status_list(_exam?.data?.status)}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Panel>
            </Collapse>
            <TableLineLoader loading={student_exams.loading} />
            <Table id="tech-companies-1" className="table mt-2">
                <Thead>
                    <Tr>
                        <Th className="table_head_c" style={{ width: "3%" }}>№</Th>
                        <Th className="table_head_c">{t("Familiya")}</Th>
                        <Th className="table_head_c">{t("Ism")}</Th>
                        {/* <Th className="table_head_c">{t("Teacher")}</Th> */}
                        <Th className="table_head_c">{t("Passport seria va raqam")}</Th>
                        <Th className="table_head_c">{t("SHRJ")}</Th>
                        <Th className="table_head_c">ON1/ON2/YN/All</Th>
                        <Th className="table_head_c text-center">{t("Chetlatish")}</Th>
                        <Th className="table_head_c">{t("Ball")}</Th>
                        <Th className="table_head_c">{t("Finish time")}</Th>
                        <Th className="table_head_c">{t("Duration")}</Th>
                        <Th className="table_head_c text-center">{t("Status")}</Th>
                        {isHasAuthRoute("exam-student_create") && isHasAuthRoute("exam-student_update") && isHasAuthRoute("exam-student_view") &&
                            <Th className="table_head_c text-center">{t("Actions")}</Th>
                        }
                    </Tr>
                </Thead>
                <Tbody>

                    <Tr className="bg-white" style={{ backgroundColor: "white" }}>
                        <Td></Td>
                        <Td className="table_head_c"><Input name="last_name" placeholder="Search by last name" value={value.filter_like?.last_name} onChange={handleSearch} allowClear /></Td>
                        <Td className="table_head_c"><Input name="first_name" placeholder="Search by first name" value={value.filter_like.first_name} onChange={handleSearch} allowClear /></Td>
                        {/* <Td className="table_head_c"></Td> */}
                        <Td className="table_head_c d-flex w-100" style={{ minWidth: "150px" }}>
                            <InputMask mask="aa" placeholder="AA" className='ant-input' name="passport_seria" value={value.filter_like.passport_seria} onChange={handleSearch} style={{ width: "50px", borderRight: "none" }} />
                            <InputMask mask="9999999" placeholder="9999999" className='ant-input' name="passport_number" value={value.filter_like.passport_number} onChange={handleSearch} style={{ width: "150px" }} />
                        </Td>
                        <Td className="table_head_c" style={{ maxWidth: "150px" }}><Input name="passport_pin" placeholder="Search by SHRJ" value={value.filter_like.passport_pin} onChange={handleSearch} allowClear /></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        {isHasAuthRoute("exam-student_create") && isHasAuthRoute("exam-student_update") && isHasAuthRoute("exam-student_view") &&
                            <Td></Td>
                        }
                    </Tr>
                    {student_exams.data.items.length ? student_exams.data.items.map((element: IExamStudents, i: number) => (
                        <Tr key={i}>
                            <Td> {!student_exams.loading ? value.currentPage * value.perPage - value.perPage + i + 1 : "-"}</Td>
                            <Td>{element?.student?.profile?.last_name}</Td>
                            <Td>{element?.student?.profile?.first_name}</Td>
                            {/* <Td>{element?.teacherAccess?.teacher?.last_name}&nbsp;{element?.teacherAccess?.teacher?.first_name}&nbsp;{element?.teacherAccess?.teacher?.middle_name} &nbsp; {encodeKey(String(element.student_id)) + '-' + encodeKey(String(element?.id)) + ' - javob'}</Td> */}
                            <Td>{element?.student?.profile?.passport_seria}&nbsp;{element?.student?.profile?.passport_number}</Td>
                            <Td>{element?.student?.profile?.passport_pin}</Td>
                            <Td>{element?.on1 ?? "_"} / {element?.on2 ?? "_"} / {element?.ball || element?.ball === 0 ? element?.allBall ?? element?.ball : "_"} / <span style={{ color: "#1890FF" }} >{(element?.ball ? Number(element?.allBall) ?? element?.ball : 0) + element?.on1 + element?.on2}</span></Td>
                            <Td className='text-center'>
                                {
                                    Number(element?.act) === 1 ?
                                        <Tag color="#FCE8E6" style={{ color: '#C5221F' }} className="min_w_120 cursor-pointer" onClick={() => updateForAct(element.id, element?.act)} >Chetlatilgan</Tag> :
                                        <Tag color="#E6F4EA" style={{ color: '#137333' }} className="min_w_120 cursor-pointer" onClick={() => updateForAct(element.id, element?.act)}  >Chetlatish</Tag>
                                }
                            </Td>
                            <Td className="text-center">
                                {element?.examStudentAnswers?.length || element?.start ? element.ball :
                                    <Popover
                                        content={<UpdateBallByTypeExamStudent element={element} setPopoverBall={setPopoverBall} setRefetch={setRefetch} />}
                                        title={<span className='text-primary fs-12'>{element?.student?.profile?.last_name}&nbsp;{element?.student?.profile?.first_name}</span>}
                                        trigger="click"
                                        visible={element?.id === popoverBall}
                                        onVisibleChange={e => setPopoverBall(element?.id)}
                                    >
                                        <Button type='primary' size="small" danger={element?.type !== null}>{element?.ball ?? <BiPlus />}</Button>
                                    </Popover>}
                            </Td>
                            <Td>{element?.finish ? moment.unix(element?.finish).format("YYYY-MM-DD HH:mm:ss") : <EmptySpaceLine />}</Td>
                            <Td>{element?.duration ? moment.utc(Number(element?.duration) * 1000).format("HH:mm") : <EmptySpaceLine />}</Td>
                            <Td className="text-center">{exam_student_status_list(element.status)}</Td>

                            {isHasAuthRoute("exam-student_create") && isHasAuthRoute("exam-student_update") && isHasAuthRoute("exam-student_view") &&
                                <Td>
                                    <div className="crud_actions_table">
                                        {isHasAuthRoute("exam-student_view") &&
                                            <Tooltip placement="top" title={t("View")}> <TbFileInvoice  className="edit_btn" onClick={() => { history.push(`/student_exam_detail/${element?.id}`) }} /> </Tooltip>
                                        }
                                        {isHasAuthRoute("exam-student_create") && isHasAuthRoute("exam-student_update") && (
                                            <Tooltip placement="top" title={t("Edit")}> <TbFilePencil className="edit_btn" onClick={() => { setUpdateModal(true); setSelectedData(element) }} /> </Tooltip>
                                        )}
                                    </div>
                                </Td>
                            }
                        </Tr>
                    )) : null}
                </Tbody>
            </Table>
            <EmptyTable data={student_exams.data.items} loading={student_exams.loading} />
            {student_exams.data?._meta.totalCount ? (
                <div className="table_bottom_footer">
                    <div>
                        <Tag color="#F2F2F2" className="text-dark">Jami {student_exams.data?._meta.totalCount} ta</Tag>
                    </div>
                    <Pagination
                        defaultCurrent={value.currentPage}
                        total={student_exams.data?._meta.totalCount}
                        defaultPageSize={value.perPage}
                        onChange={(e) => writeToUrl({ name: 'currentPage', value: e })}
                        onShowSizeChange={(e, pageSize) => writeToUrl({ name: 'perPage', value: pageSize })}
                        showSizeChanger
                        pageSizeOptions={["10", "15", "20", "30", "50", "100"]}
                    />
                </div>
            ) : null}
        </div>
    )
}


export default ExamStudents;