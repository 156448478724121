import { ChangeEvent } from "react";
import { private_routes } from "routes";
import store from "store";
import { TypeInitialStateAuth } from "store/auth";
import { RoutesTypeChildren, RoutesTypeSubmenu } from "routes/types";
import isRole from "./isRole";
import { exam_by_roles_statuses_array, exam_others_statuses_array, question_dean_statuses, question_dean_statuses_array, question_edu_admin_statuses, question_edu_admin_statuses_array, question_mudir_statuses, question_mudir_statuses_array, question_others_statuses, question_others_statuses_array, question_teacher_statuses, question_teacher_statuses_array } from "./variables";
import { question_status_list } from "pages/questions/confirm_status";


/**
 * @goal for removing all styles, html tags from text
 * @param text
 * @returns text without any styles and html tags or empty string
 */
export const getTextWithoutAnyStyles = (text: string) => {

    if (!text) return ''
    let a: string = text.replace(/<\/?[^>]+(>|$)/g, "")
    let question = a.slice(0, 25).concat('...')
    return question.replace(/&nbsp;/g, " ");
}



/**
 * @goal find element by id and will return element if it has or empty object
 * @param arr array includes any elements
 * @param id number
 * @param by_field string it helps finding element by field name
 * @returns object with fields or empty
 */

type TypePropsOfGetElementById = {
    arr: any[],
    id: number | undefined,
    by_field: string
}

export const findElementById = (params: TypePropsOfGetElementById) => {

    const { arr = [], id, by_field } = params;

    if (!arr.length && !id && !by_field) return {}

    let _element = null;

    if (typeof id === 'number') {

        _element = arr.find(e => Number(e[by_field]) === id);

    } else {

        _element = arr.find(e => String(e[by_field]) === id);

    }

    return _element ? _element : {};
}



/**
 * @goal removing from object field according to the given parameters
 * @param _object 1-param. object any field
 * @param keys 2-param. array of any elements
 * @returns object
 */


export const clearObjectFields = (_object: any, keys: any[]) => {
    const cloneObject: any = Object.assign({}, _object);
    for (let key in cloneObject) {
        if (keys.includes(cloneObject[key])) {
            delete cloneObject[key]
        }
    }

    return cloneObject;
}



/**
 * @goal convert array to object for convenient using
 * @param languages array of object elements thet includes id and name
 * @returns objectlike this {id:name}
 */

type TypeLanguageAsObject = { [key: number]: string }

export const getLanguageAsObject = (languages: any[]): TypeLanguageAsObject => {

    if (languages.length) {

        let newObj: TypeLanguageAsObject = {};

        languages.forEach((element) => {
            newObj[element.id] = element?.name;
        })

        return newObj;
    }

    return {};
}



/**
 * @goal defining question statuses by rol
 * @returns return array that includes element like this {id:1, name: Active}
 */

export const getQuestionStatusesByRole = (): { statuses_as_obj: any, statuses_as_arr: Array<{ value: number[], name: string }> } => {

    const _user: any = store.getState().auth;
    const _roles: string[] = _user.user?.role || [];

    if (_roles.includes('mudir')) {

        return {
            statuses_as_obj: question_mudir_statuses,
            statuses_as_arr: question_mudir_statuses_array
        }

    } else if (_roles.includes('dean')) {

        return {
            statuses_as_obj: question_dean_statuses,
            statuses_as_arr: question_dean_statuses_array
        }

    } else if (_roles.includes('edu_admin')) {

        return {
            statuses_as_obj: question_edu_admin_statuses,
            statuses_as_arr: question_edu_admin_statuses_array
        }

    } else if (_roles.includes('teacher')) {

        return {
            statuses_as_obj: question_teacher_statuses,
            statuses_as_arr: question_teacher_statuses_array
        }
    } else {

        return {
            statuses_as_obj: question_others_statuses,
            statuses_as_arr: question_others_statuses_array
        }

    }
}








/**
 * @goal filtering by permissions private routes for sidebar
 * @returns filtered array
 */

export const filterLinksForSidebar = (): Array<RoutesTypeChildren> => {

    const user_data = store.getState().auth as TypeInitialStateAuth;


    const _permissions = user_data.permissions || [];

    if (_permissions.length) {

        const sidebar_links: Array<RoutesTypeChildren> = [];

        for (let index = 0; index < private_routes.length; index++) {

            const parent_element = private_routes[index];

            if ((_permissions.includes(parent_element.config.key) && parent_element.config.isShowLink) || (!parent_element.config.key && !parent_element.component) || (parent_element.config.key === "unlock" && parent_element.config.isShowLink)) {

                let new_sub_element: RoutesTypeSubmenu[] = [];

                if (parent_element.submenu.length) {

                    new_sub_element = parent_element.submenu.filter((element) => (_permissions.includes(element.config.key) && element.config.isShowLink) || (element.config.key === 'unlock' && element.config.isShowLink));

                }

                if (new_sub_element.length === 1) {

                    sidebar_links.push({ ...new_sub_element[0], submenu: [] });

                } else {

                    sidebar_links.push({ ...parent_element, submenu: new_sub_element });

                }
            }

        }

        return sidebar_links;

    }

    return [];
}

/**
 * @goal defining exam statuses by rol
 * @returns return array that includes element like this {id:1, name: Active}
 */

export const getExamStatusesByRole = (): { statuses_as_obj: any, statuses_as_arr: Array<{ value: number[], name: string }> } => {

    if (isRole(['mudir', 'dean', 'edu_admin', 'admin', 'rektor'])) {

        return {
            statuses_as_obj: [],
            statuses_as_arr: exam_by_roles_statuses_array
        }

    } else {

        return {
            statuses_as_obj: [],
            statuses_as_arr: exam_others_statuses_array
        }

    }
}


/**
 *
 * @param roles params
 * @returns boolean true or false
 */

export const isRoleExact = (roles: string[]): boolean => {

    const auth = store.getState().auth as TypeInitialStateAuth;
    const _roles = auth.user?.role;
    if (Array.isArray(_roles) && _roles.length && roles.length) {
        let isHas = _roles.every((e: string) => roles.includes(e));

        return isHas;
    }
    return false;
}


export const getQuestionStatusAsArray = (obj: Array<number | string>) => {
    if (obj?.length) {
        const _new_arr = question_status_list.filter(e => obj.includes(String(e.status[0])));
        return _new_arr;
    } else {
        return [];
    }
}

/**
 * @goal checking object has or not gfield in object
 * @param obj object
 * @returns boolean
 */
export const isHasFieldsObject = (obj: { [key: string | number]: any }) => Object.getOwnPropertyNames(obj).length > 0;




export const getExamsCountByLanguages = ({ type, languages, depend_array, return_type }: { type?: undefined | 'sum', languages: any[], depend_array: any[], return_type: 'object' | 'array' }) => {

    if (languages.length && depend_array.length) {


        let _value_obj: { [key: string]: any; } = {};
        let _value_arr: any[] = [];


        if (type === 'sum') {

        } else {
            if (return_type === 'object') {
                languages.forEach((element) => {
                    const exsistingElement = findElementById({ id: element?.id, arr: depend_array, by_field: 'lang_id' });
                    if (isHasFieldsObject(exsistingElement)) {
                        _value_obj[element?.lang_code] = { lang_id: element?.id, count: exsistingElement?.count };
                    }
                })
            } else {
                languages.forEach((element) => {
                    const exsistingElement = findElementById({ id: element?.id, arr: depend_array, by_field: 'lang_id' });
                    if (isHasFieldsObject(exsistingElement)) {
                        _value_arr.push({
                            ...exsistingElement,
                            [element.lang_code]: exsistingElement?.count
                        });
                    }
                })
            }
        }

        return return_type === 'array' ? _value_arr : _value_obj;
    }

    return return_type === 'array' ? [] : {};
}

export const isImage = (_url: string) => {
    if (_url) {
        const _url_arr = _url.split('.');
        let isHasImage = ['JPEG', 'JPG', 'PNG', 'GIF', 'TIFF', 'PSD', , 'EPS', 'AI', 'INDD', 'RAW', 'jpeg', 'jpg', 'png', 'gif', 'tiff', 'psd', 'eps', 'ai', 'indd', 'raw'].includes(_url_arr[_url_arr.length - 1]);
        return isHasImage;
    }
}

export const cf_filterOption = (input: string, option: any) => {

    return String(option?.children).toLowerCase().indexOf(String(input).toLowerCase()) >= 0
}



const encodeUrl = (value: { name: string, id: number | string | undefined }) => {

}