import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import instance from "config/_axios";
import moment from "moment";
import { userAccessType } from "pages/user/components/types";
import { getUserAccessTypes } from "pages/user/servise";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { asyncN } from "utils/notifications";
import { tableStoreType, userAccessType1 } from "../type";

const { Option } = Select

const CreateTableModal: FC<{ refresh: boolean, setRefresh: (refresh: boolean) => void, item: tableStoreType | null, visible: boolean, setVisible: (visible: boolean) => void }> = ({ item, visible, setVisible, refresh, setRefresh }): JSX.Element => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [userAccessTypes, setUserAccessTypes] = useState<Array<userAccessType>>([]);
    const [tables, setTables] = useState<Array<{ id: number, name: string, userAccess: Array<{ id: number, name: string }> }>>([]);

    useEffect(() => {
        (
            async () => {
                const response: any = await getUserAccessTypes();
                setUserAccessTypes(response?.items)
            }
        )()
    }, [])

    const getAccess = async (id: number) => {
        if (id) {
            try {
                let url = userAccessTypes?.filter((item: { id: number, url: string }) => item?.id === id)[0]?.url;
                const resp = await instance({
                    method: 'get',
                    url: `/${url}?per-page=0`
                })
                if (resp?.data?.status === 1) {
                    setTables(resp?.data?.data?.items)
                } else {
                    asyncN("error", "read", resp?.data?.message);
                }
            } catch (error: any) {
                asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message);
            }
        }
    }

    useEffect(() => {
        if (item) {
            getAccess(item?.user_access_type_id)
            form?.setFieldsValue({
                user_access_type_id: item?.user_access_type_id,
                table_id: item?.table_id,
                description: item?.description,
                type: item?.type,
                year_month: moment(new Date(item?.year, item?.month, 0))
            })
        } else {
            form.resetFields()
        }
    }, [item])

    const onFinish = async (values: any) => {
        console.log(values);
        let data = new FormData();
        Object.entries(values)?.forEach(([key, value]: any) => {
            if (key === "year_month") {
                let a = moment(value).format("YYYY-MM");
                let d: Array<string> = a?.split("-");
                data.append("month", d[1])
                data.append("year", d[0])
            } else {
                data.append(key, value)
            }
        })
        try {
            const resp = await instance({
                url: `/table-stores${item !== null ? "/" + item?.id : ''}`,
                method: item !== null ? "put" : "post",
                data: data
            })
            if (resp?.data?.status === 1) {
                setVisible(false)
                if (item !== null) {
                    setRefresh(!refresh)
                } else {
                    let dataResp = resp?.data?.data;
                    history?.push(`/employee-tabel/${dataResp?.user_access_type_id}/${dataResp?.table_id}/${dataResp?.id}/${dataResp?.year}/${dataResp?.month}/${dataResp?.type}`)
                }
            } else {
                asyncN("error", "create", resp?.data?.message);
            }
        } catch (error: any) {
            if (error?.response?.status === 422) {
                asyncN("error", "create", error?.response?.data?.errors[0]?.type);
            } else {
                asyncN("error", "create", error?.response ? error?.response?.data?.message : error?.message);
            }
        }

    }

    return (
        <Modal title="Create tabel!" visible={visible} onCancel={() => setVisible(false)} footer={false}>
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name="user_access_type_id" label="User access type">
                            <Select disabled={item !== null} placeholder="Select user access type!" onChange={(e) => {
                                getAccess(e)
                                form?.setFieldsValue({
                                    table_id: null
                                })
                            }} allowClear onClear={() => {
                                setTables([])
                                form?.setFieldsValue({
                                    table_id: null
                                })
                            }}>
                                {
                                    userAccessTypes?.map((item: userAccessType, i: number) =>
                                        <Option key={i} value={item?.id}>{
                                            item?.name
                                        }</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="table_id" label="User access">
                            <Select disabled={item !== null} placeholder="Select user access!" allowClear>
                                {
                                    tables?.map((item: { id: number, name: string }, i: number) =>
                                        <Option key={i} value={item?.id}>{
                                            item?.name
                                        }</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="description" label="Description">
                            <Input placeholder="Description!" />
                        </Form.Item>
                    </Col>
                    <Col className="d-flex" span={24}>
                        <Form.Item initialValue={moment(new Date().getTime())} className="w-50" name="year_month" label="Date">
                            <DatePicker disabled={item !== null} className="w-100" placeholder="Select date!" allowClear picker="month" format={"MM/YYYY"} />
                        </Form.Item>
                        <Form.Item initialValue={new Date().getDate() > 15 ? 2 : 1} className="w-25 ms-1" name="type" label="Type">
                            <Select disabled={item !== null} placeholder="Select type!" allowClear>
                                <Option value={1}>1-15</Option>
                                <Option value={2}>16-30|31</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="d-flex justify-content-end">
                        <Button danger type="primary" htmlType="button" onClick={() => setVisible(false)}>
                            Cancel
                        </Button>
                        <Button className="mx-1" htmlType="reset">
                            Reset
                        </Button>
                        <Button htmlType="submit" type="primary">
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default CreateTableModal;