import { FC, useEffect, useState } from 'react';
import { Form, Modal, Input, Switch, Button, Col, DatePicker, Tooltip } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { useTranslation } from 'react-i18next';
import { getOneEduSemester } from '../../edu_semester/service';
import moment from 'moment';

type SemesterEditModal = {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setReRequest: (reRequest: boolean) => void,
    id: number | null,
    reRequest: boolean
}


const SemesterEditModal: FC<SemesterEditModal> = ({ visible, setVisible, id, setReRequest, reRequest }) => {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    // const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true);
    const [is_checked, setis_checked] = useState<boolean>(true);


    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOneEduSemester(id, 'expand=course,eduPlan,eduYear&self=1')
                        form.setFieldsValue({
                            name: response.name,
                            course_id: response?.course?.name,
                            edu_plan_id: response?.eduPlan?.name,
                            edu_year_id: response?.eduYear?.name,
                            start_date: moment(response?.start_date),
                            end_date: moment(response?.end_date),
                            status: Number(response.status) === 1 ? setStatus(true) : setStatus(false),
                            is_checked: Number(response.is_checked) === 1 ? setis_checked(true) : setis_checked(false)
                        })
                    } catch (error: any) {
                        // setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])


    const onSubmit = async (values: any) => {
        try {
            // setClicked(true)
            const formdata = new FormData();
            formdata.append(`start_date`, moment(values?.start_date).format('YYYY-MM-DD'))
            formdata.append(`end_date`, moment(values?.end_date).format('YYYY-MM-DD'))
            formdata.append('status', status ? '1' : '0');
            formdata.append('is_checked', is_checked ? '1' : '0');

            const response = await instance({ url: `/edu-semestrs/${id}`, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)

                    })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)
                    })
            }
            // setClicked(false)
        } catch (error: any) {
            // if (error?.response?.status === 422) {
            //     if (error?.response?.data?.status === 0) {
            //         form.setFields(ServerErrors(error?.response?.data?.errors[0]))
            //     }
            // }
            // setClicked(false)
            setReRequest(!reRequest)
            // asyncN("error", 'update', error?.response?.data?.errors[0])
            //     .then(() => {
            //         setVisible(false);
            //         resetFields()
            //     })
        }
    }

    const resetFields = () => form.resetFields();


    return (
        <>
            <Modal
                title={t("Edu semester Edit")}
                visible={visible}
                onCancel={() => { setVisible(false); resetFields() }}
                okText="Submit"
                footer={null}
                width={1200}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <Tooltip placement="top" title={t("Cannot be changed")}>
                       <Form.Item
                        label={t("Name")}
                        name="name"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <Input placeholder={t("Enter semester name")+" ..."} readOnly={true} />
                    </Form.Item>
                      </Tooltip>
                    </div>
                    <div className="col-md-4">
                    <Tooltip placement="top" title={t("Cannot be changed")}>
                      <Form.Item
                          label={t("Course")}
                          name="course_id"
                          shouldUpdate
                          rules={[{ required: false, message: t("Fill in the field!") }]}
                      >
                          <Input placeholder={t("Enter for exam type")+" ..."} readOnly={true} />
                      </Form.Item>
                      </Tooltip>
                    </div>
                    <div className="col-md-4">
                    <Tooltip placement="top" title={t("Cannot be changed")}>
                      <Form.Item
                          label={t("Curriculum")}
                          name="edu_plan_id"
                          shouldUpdate
                          rules={[{ required: false, message: t("Fill in the field!") }]}
                      >
                          <Input placeholder={t("Enter edu plan")+" ..."} readOnly={true} />
                      </Form.Item>
                      </Tooltip>
                    </div>
                    <div className="col-md-4">
                    <Tooltip placement="top" title={t("Cannot be changed")}>
                      <Form.Item
                          label={t("Academic year")}
                          name="edu_year_id"
                          shouldUpdate
                          rules={[{ required: false, message: t("Fill in the field!") }]}
                      >
                          <Input placeholder={t("Enter academic year")+" ..."} readOnly={true} />
                      </Form.Item>
                      </Tooltip>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                          label={t("Start date")}
                          name="start_date"
                          shouldUpdate
                          rules={[{ required: false, message: t("Fill in the field!") }]}
                      >
                          <DatePicker placeholder={t("Select date")+" ..."} className="w-100" />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                          label={t("End date")}
                          name="end_date"
                          shouldUpdate
                          rules={[{ required: false, message: t("Fill in the field!") }]}
                      >
                          <DatePicker placeholder={t("Select date")+" ..."} className="w-100" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2">
                      <Form.Item
                        label={t("Status")}
                        name="status"
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                    </div>
                    <div className="col-md-2">
                      <Form.Item
                        label={t("Tasdiq")}
                        name="is_checked"
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" checked={is_checked} onChange={() => setis_checked(!is_checked)} />
                    </Form.Item>
                    </div>
                  </div>


                    <hr />
                    <Col span={24} className="text-end mt-2">
                        <Button htmlType="reset" className="me-2" onClick={() => setVisible(false)}>{t("Cancel")}</Button>
                        <Button type="primary" htmlType="submit">{t("Submit")}</Button>
                    </Col>
                </Form>
            </Modal>
        </>
    )
}


export default SemesterEditModal;