import React from 'react';
import { useCallback, useState } from 'react';
import { useHistory, useRouteMatch, match } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Divider, Form, Input, InputNumber, Row, Select, Spin, Tabs, Tag, Empty, message, Alert, Image } from "antd";
import { Card, CardBody } from "reactstrap";
import useGetOneData from "hooks/useGetOneData";
import { CaretRightOutlined } from '@ant-design/icons';
import { IExamAppeal, IExamStudentAnswer, IExamStudentAnswerSubQuestion } from 'models/exam';
import { InputFieldRule } from 'utils/messages';
import styles from './styles.module.scss';
import FormLabel from 'components/FormLabel';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { FaArrowLeft } from 'react-icons/fa';
import isRole from 'utils/isRole';
import { FILE_URL } from 'config/utils';

export const encodeKey = (key: string): string => {
    if (Boolean(Number(key))) {
        let a: string = ""
        for (let i = 0; i < key?.length; i++) {
            a += String.fromCharCode(Number(key[i]) + 97)
        }
        return a
    } else {
        return "no encode";
    }
}

export const decodeKey = (key: string): string => {
    let a: string = ""
    for (let i = 0; i < key?.length; i++) {
        a += String(Number(key.charCodeAt(i) - 97));
    }

    return a;
}


const appealTypes: Array<{ id: number, name: string }> = [
    { id: 1, name: 'Shikoyat asosli' },
    { id: 2, name: 'Shikoyat asossiz' },
    { id: 3, name: 'Texnik' },
    { id: 4, name: 'Shikoyat asosli va Texnik' },
]


const appealTypeStyle = (id: number) => {
    switch (id) {
        case 1:
            return { backgroundColor: '#E6F4EA', color: '#137333' }
        case 2:
            return { backgroundColor: '#FCE8E6', color: '#A50E0E' }
        case 3:
            return { backgroundColor: '#E8F0FE', color: '#1967D2' }
        case 4:
            return { backgroundColor: '#F1F3F4', color: '#5F6368' }
    }
}

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const AppelationChecking: React.FC = (): JSX.Element => {

    const { t } = useTranslation();
    const history = useHistory()
    const [refetch, setRefetch] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [formAppeal] = Form.useForm();
    const match: match<any> | null = useRouteMatch("/appelation_checking/:appeal_id");
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [appealType, setAppealType] = useState<number | null>(null);

    if (!match) {
        history.goBack();
    }

    const { data, loading } = useGetOneData({
        url: `/exam-appeals/${match?.params?.appeal_id}?expand=subject,examStudent.allBall,examStudent.examStudentAnswers.question,examStudent.examStudentAnswers.examStudentAnswerSubQuestion`,
        isCall: 'auto',
        refetch: [refetch]
    }) as { data: IExamAppeal, loading: boolean }


    React.useMemo(() => {
        formAppeal.setFieldsValue({
            teacher_conclusion: data?.teacher_conclusion,
            type: data?.type
        })
        setAppealType(data?.type)
    }, [data]);

    React.useMemo(() => {
        if (data.examStudent?.examStudentAnswers && data.examStudent?.examStudentAnswers.length) {

            const { examStudentAnswers } = data.examStudent;


            examStudentAnswers.forEach(parentElement => {

                form.setFieldsValue({
                    [`${parentElement?.id}_appeal_teacher_conclusion`]: parentElement?.appeal_teacher_conclusion
                });

                if (parentElement.examStudentAnswerSubQuestion && parentElement.examStudentAnswerSubQuestion.length) {

                    parentElement.examStudentAnswerSubQuestion.forEach(({ appeal_teacher_conclusion, ball, sub_question_id, old_ball }) => {
                        form.setFieldsValue({
                            [`${sub_question_id}_appeal_teacher_conclusion`]: appeal_teacher_conclusion ?? undefined,
                            [`${sub_question_id}_ball`]: old_ball || old_ball === 0 ? ball : undefined,
                        });
                    })
                }
            })
        }
    }, [data])

    const updateAppeal = async (id: number) => {

        if (data && data?.id) {
            const { teacher_conclusion, type } = formAppeal.getFieldsValue()
            const formdata = new FormData();
            formdata.append('teacher_conclusion', teacher_conclusion);
            formdata.append('type', type);
            const response = await instance({ url: `/exam-appeals/${data.id}`, method: 'PUT', data: formdata });
            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => {
                        setRefetch(!refetch);
                    })
            } else {
                asyncN("error", 'update', response.data?.message)
            }
        } else {
            message.warning("Appelation not found.")
        }

    }

    const updateChecking = async (examStudentAnswer: IExamStudentAnswer, subQuestionId: number) => {

        if (examStudentAnswer.examStudentAnswerSubQuestion) {

            const _element = examStudentAnswer.examStudentAnswerSubQuestion.find(e => e.sub_question_id === subQuestionId);

            if (_element && _element.id) {

                const appeal_teacher_conclusion = form.getFieldValue(`${subQuestionId}_appeal_teacher_conclusion`);
                const ball = form.getFieldValue(`${subQuestionId}_ball`);


                const formdata = new FormData();

                formdata.append('subQuestionAnswersAppealChecking', JSON.stringify([{ exam_student_answer_sub_question_id: _element.id, appeal_teacher_conclusion, ball }]));

                formdata.append('exam_appeal_id', String(data?.id));

                if (appeal_teacher_conclusion && ball) {

                    const response = await instance({ url: `/exam-checkings/${examStudentAnswer.id}/appeal`, method: 'PUT', data: formdata });


                    if (response.data.status === 1) {
                        asyncN("success", 'create', response.data?.message)
                            .then(() => { setRefetch(!refetch) })
                    } else {
                        asyncN("error", 'update', response.data?.message)
                    }

                } else {
                    message.warning(`Iltimos ${!ball ? 'ballni' : ''} ${!appeal_teacher_conclusion ? 'izohni ' : ''} kiriting !`);
                }

            }

        }



    }

    const updateCheckingCanclusion = async (examStudentAnswerId: number) => {

        const appeal_teacher_conclusion = form.getFieldValue(`${examStudentAnswerId}_appeal_teacher_conclusion`);


        const formdata = new FormData();
        formdata.append('appeal_teacher_conclusion', appeal_teacher_conclusion);
        formdata.append('exam_appeal_id', String(data?.id));

        const response = await instance({ url: `/exam-checkings/${examStudentAnswerId}/appeal`, method: 'PUT', data: formdata });


        if (response.data.status === 1) {
            asyncN("success", 'create', response.data?.message)
                .then(() => { setRefetch(!refetch) })
        } else {
            asyncN("error", 'update', response.data?.message)
        }

    }

    const getAnswerBySubQuestionId = useCallback((id: number, examStudentAnswerSubQuestion: IExamStudentAnswerSubQuestion[] | undefined): IExamStudentAnswerSubQuestion | null | undefined => {

        if (data?.examStudent) {

            const { examStudentAnswers } = data.examStudent;

            if (examStudentAnswers && examStudentAnswers.length) {

                const findeElement = examStudentAnswerSubQuestion?.find(e => e.sub_question_id === id);

                return findeElement
            }

            return null;
        }

    }, [data])


    return (
        <div className=''>
            <Spin tip="Loading..." spinning={loading}>
                <h6 className='mt-1 mb-3' > <FaArrowLeft className='me-2 cursor-pointer' onClick={() => history.goBack()} /> {data?.subject?.name}</h6>
                <div style={{ backgroundColor: "#F5F7F9" }} className="p-3">
                    <Card>
                        <CardBody>
                            <Alert
                                message={
                                    <span>
                                        <strong>Apelatsiya: &nbsp;&nbsp; </strong>
                                        <strong style={{ color: '#5A9000' }} >Shikoyat asosli-</strong> bo'lgan holatda izoh yozishingiz talab etiladi va talaba uchun qo'yilgan ball va izohlarni tahrirlash imkoni bo'ladi.&nbsp;
                                        <strong style={{ color: '#D81D24' }} >Shikoyat asossiz-</strong> izoh yozishingiz talab etiladi.&nbsp;
                                        <strong style={{ color: '#DB5903' }}>Texnik-</strong> izoh yozishingiz talab etilmaydi.&nbsp;
                                        <strong style={{ color: '#DB5903' }}>Shikoyat asosli</strong> & <strong style={{ color: '#DB5903' }}>Texnik - </strong> izoh yozishingiz talab etiladi.&nbsp;
                                    </span>
                                }
                                type="info"
                                banner
                                showIcon={false}
                                className='mb-3'
                            />
                            <Divider className={styles.conculision_divider}> {isRole('admin') ? data?.student_id : encodeKey(String(data?.student_id))}-{isRole('admin') ? data?.exam_student_id : encodeKey(String(data?.exam_student_id))} - apelatsiya</Divider>
                            <span style={{ color: '#5F6368', display: 'block', textAlign: 'justify', fontSize: "15px" }} >{data?.appeal_text}</span>
                            <Divider className='my-3' ></Divider>
                            <Form
                                form={formAppeal}
                                layout='vertical'
                                onFinish={updateAppeal}
                            >
                                <Row gutter={[12, 12]}>
                                    <Col xl={16} >
                                        <Form.Item
                                            label={<FormLabel>Apelatsiya bo'yicha xulosa</FormLabel>}
                                            name='teacher_conclusion'
                                            rules={[{ required: appealType === 3 ? false : true }]}
                                        >
                                            <TextArea rows={3} className='w-100'></TextArea>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={8} >
                                        <Form.Item
                                            label={<FormLabel>Apelatsiya turini tanlang</FormLabel>}
                                            rules={InputFieldRule}
                                            className='mb-2'
                                            name='type'
                                        >
                                            <Select
                                                placeholder="Apelatsiya turini tanlang"
                                                className="w-100 ant-select_custom"
                                                allowClear
                                                onChange={(e) => { setAppealType(e) }}
                                            >
                                                {appealTypes.map((e) => <Option key={e.id} value={e.id} className="mt-1" style={appealTypeStyle(e.id)} >{e.name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                        <div className='d-flex mt-2'>
                                            {
                                                data && data.examStudent?.examStudentAnswers ?
                                                    <Tag color="#E8F0FE" className="text-center d-block fs-14 h-100 w-50" style={{ color: '#1967D2', padding: "6px 0", verticalAlign: 'middle', fontWeight: 'bold' }}>Ball: &nbsp;&nbsp;{data?.examStudent.allBall}&nbsp;/&nbsp;{data?.examStudent.examStudentAnswers[questionIndex]?.max_ball}</Tag>
                                                    : null
                                            }
                                            <Button type="primary" onClick={() => history.goBack()} className='w-50 ms-2' htmlType='submit'>{t('Save')}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    {
                        <Form
                            form={form}
                            layout="vertical"
                            className='mt-3'
                        >
                            <Tabs defaultActiveKey="1" centered className='mt-3' onChange={(e) => setQuestionIndex(Number(e))}>
                                {
                                    Array.isArray(data.examStudent?.examStudentAnswers) && data.examStudent?.examStudentAnswers.length ?
                                        data.examStudent?.examStudentAnswers.map((parentElement, parentIndex) => {
                                            return (
                                                <TabPane tab={`${parentIndex + 1} - kazus`} key={parentIndex}>
                                                    <Card className='mb-3'>
                                                        <CardBody>
                                                            <span className={styles.question_body} dangerouslySetInnerHTML={{ __html: parentElement.question?.question ?? '' }} ></span>
                                                            {parentElement.question?.file ? <Image src={FILE_URL + `${parentElement.question?.file}`} className="w-100" /> : null}
                                                        </CardBody>
                                                    </Card>
                                                    <Collapse
                                                        bordered={false}
                                                        defaultActiveKey={['0']}
                                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                        className="site-collapse-custom-collapse p-0"
                                                    >
                                                        {
                                                            Array.isArray(parentElement?.question?.subQuestion) && parentElement?.question?.subQuestion.length ?
                                                                parentElement?.question?.subQuestion.map((childElement, childIndex) => {
                                                                    return (
                                                                        <Panel
                                                                            key={childIndex}
                                                                            header={
                                                                                <div className='w-100'>
                                                                                    <div className='d-flex justify-content-between align-items-center w-100 '>
                                                                                        <span className='d-flex align-contetn-center'>
                                                                                            <strong className='me-2' >{childIndex + 1}-savol. </strong>
                                                                                            <span className='text_size_question_14' dangerouslySetInnerHTML={{ __html: childElement?.question ?? '' }} ></span>
                                                                                        </span>
                                                                                        {
                                                                                            getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.old_ball ?
                                                                                                <Tag color="#E6F4EA" style={{ color: "#137333", fontWeight: 'bold' }} >{getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.ball}</Tag> : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            className="site-collapse-custom-panel p-0"
                                                                        >
                                                                            {
                                                                                getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.answer !== null && getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.answer !== undefined ?
                                                                                    <Row gutter={[10, 12]}>
                                                                                        <Col xl={24} sm={16} md={24} xs={24} className='border py-3 px-2' >
                                                                                            <span dangerouslySetInnerHTML={{ __html: getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.answer ?? '' }} ></span>
                                                                                        </Col>
                                                                                        <Col xl={12} md={12} lg={12} sm={24} xs={24}>
                                                                                            <div style={{ backgroundColor: "#F7F9F9" }} className="h-100 p-3">
                                                                                                <Divider className={styles.conculision_divider}>O'qituvchi xulosasi ({getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.old_ball ?? getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.ball} &nbsp;/&nbsp;{Array.isArray(data?.examStudent?.examStudentAnswers) && data?.examStudent?.examStudentAnswers.length ? (data?.examStudent?.examStudentAnswers[0]?.max_ball * (childElement?.percent / 100)) : null})</Divider>
                                                                                                {getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.teacher_conclusion}
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col xl={12} md={12} lg={12} sm={24} xs={24}>
                                                                                            <Form.Item
                                                                                                name={`${childElement.id}_appeal_teacher_conclusion`}
                                                                                                className="w-100 m-0"

                                                                                            >
                                                                                                <TextArea rows={4} placeholder="Xulosa matnini kiriting ..." disabled={data?.type !== 1 && data?.type !== 4} />
                                                                                            </Form.Item>
                                                                                            <div className='w-100 mt-2 d-flex' >
                                                                                                <Form.Item
                                                                                                    name={`${childElement.id}_ball`}
                                                                                                    className="p-0 m-0 w-75"
                                                                                                >
                                                                                                    <InputNumber
                                                                                                        min={0}
                                                                                                        type={'number'}
                                                                                                        className="d-block w-100"
                                                                                                        disabled={data?.type !== 1 && data?.type !== 4}
                                                                                                        addonBefore={<span>Max: {(parentElement.max_ball * (childElement?.percent / 100))}</span>}
                                                                                                        onWheel={(e) => e.currentTarget.blur()}
                                                                                                    />
                                                                                                </Form.Item>
                                                                                                <Button type="primary" htmlType='submit' disabled={data?.type !== 1 && data?.type !== 4}
                                                                                                    onClick={() => {
                                                                                                        if (parentElement.max_ball * (childElement?.percent / 100) >= Number(form.getFieldValue(`${childElement.id}_ball`))) {
                                                                                                            const _ball =
                                                                                                                getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.old_ball ??
                                                                                                                getAnswerBySubQuestionId(childElement.id, parentElement.examStudentAnswerSubQuestion)?.ball;

                                                                                                            if (_ball) {
                                                                                                                if (Number(form.getFieldValue(`${childElement.id}_ball`) < _ball)) {
                                                                                                                    message.warning("Dastlabki tekshiruvdagi baldan past bo'lmasligi lozim !")
                                                                                                                } else {
                                                                                                                    updateChecking(parentElement, childElement.id)
                                                                                                                }
                                                                                                            } else {
                                                                                                                updateChecking(parentElement, childElement.id)
                                                                                                            }
                                                                                                        } else {

                                                                                                            message.warning("Noto'gri ball !")
                                                                                                        }
                                                                                                    }
                                                                                                    } className='w-25 ms-2' block>Saqlash</Button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row> :
                                                                                    <>
                                                                                        <Divider className='mt-0' />
                                                                                        <Empty description="No answer" />
                                                                                    </>
                                                                            }
                                                                        </Panel>
                                                                    )
                                                                }) : null
                                                        }
                                                        <div className='card_shadow rounded mb-3 p-3'>
                                                            <Row gutter={[12, 12]}>
                                                                <Col xl={24} >
                                                                    <FormLabel>Kazus bo'yicha umumiy xulosa</FormLabel>
                                                                </Col>
                                                                <Col xl={12}>
                                                                    <div style={{ backgroundColor: "#F7F9F9" }} className="h-100 p-3">
                                                                        <Divider className={styles.conculision_divider}>O'qituvchining kazus bo'yicha umumiy xulosasi </Divider>
                                                                        {parentElement.teacher_conclusion}
                                                                    </div>
                                                                </Col>
                                                                <Col xl={12}>
                                                                    <Form.Item name={`${parentElement.id}_appeal_teacher_conclusion`}>
                                                                        <TextArea rows={3} disabled={data?.type !== 1} placeholder="Xulosa matni ..." />
                                                                    </Form.Item>
                                                                    <Button type="primary" block disabled={data?.type !== 1} onClick={() => updateCheckingCanclusion(parentElement.id)} >Xulosa matnini saqlash</Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Collapse>
                                                </TabPane>
                                            )
                                        }) : <Empty />
                                }
                            </Tabs>
                        </Form>
                    }
                </div >
            </Spin >
        </div >
    )

}


export default AppelationChecking;


/** 
@permissons
exam-appeal_create
exam-appeal_delete
exam-appeal_index
exam-appeal_update
exam-appeal_view
*/

