import { Button, Col, Input, Row, Select, Tag } from "antd";
import Item from "antd/lib/list/Item";
import CustomBreadcrumb from "components/Breadcrumb";
import EmptyTable from "components/EmptyTable";
import ForTable from "components/ForTable";
import instance from "config/_axios";
import useGetAllData from "hooks/useGetAllData";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { asyncN } from "utils/notifications";
import { useQueryParams } from "./service/useQueryParams";
import { teacherAccessType } from "./types";

const SubjectTeachers: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();
    const location: any = useRouteMatch("/subjects/:subjectId/teachers")
    const [refresh, setRefresh] = useState<boolean>(false)
    const [teacherAccesses, setTeacherAccesses] = useState<Array<teacherAccessType>>([]);
    const queryParams: { set?: number } = useQueryParams();
    const _breadcrump = queryParams?.set === undefined ? [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "subjects",
        },
        {
            name: "Subject teachers",
            path: `/subjects/${location?.params?.subjectId}/teachers`,
        }
    ] : [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Contenter subjects",
            path: "/subjects-content-checking",
        },
        {
            name: "Subject teachers",
            path: `/subjects/${location?.params?.subjectId}/teachers`,
        }
    ];

    const _languages = useGetAllData({ url: "/languages", isCall: "auto" })

    const _data = useGetAllData({
        url: `/teacher-accesses?order=id&filter={"subject_id":${location?.params?.subjectId}}&per-page=0&expand=subject,teacher,user`, isCall: "auto", refetch: [refresh]
    })


    useEffect(() => {
        if (_data && _data?.items) {
            let data: Array<teacherAccessType> = []
            _data?.items?.map((item: teacherAccessType) => {
                let ta = data?.filter((itemD: teacherAccessType) => item?.user_id === itemD?.user_id && item?.subject_id == itemD?.subject_id);
                if (ta?.length > 0) {
                    data = data?.map((itemD: teacherAccessType) => {
                        if (ta[0]?.id == itemD?.id) {
                            return {
                                ...ta[0],
                                isLec: [...ta[0]?.isLec, { accId: item?.id, type: item?.is_lecture, langId: item?.language_id }],
                                langs: [...ta[0]?.langs, _languages?.items?.filter((lang: { id: number, name: string }) => item?.language_id == lang?.id)[0]]
                            }
                        } else return itemD;
                    })
                } else {
                    data.push({
                        ...item,
                        isLec: [{ accId: item?.id, type: item?.is_lecture, langId: item?.language_id }],
                        langs: _languages?.items?.filter((lang: { id: number, name: string }) => item?.language_id == lang?.id)
                    })
                }
            })
            setTeacherAccesses(data)
        }
    }, [_data?.loading, refresh])

    const updateTeacherAccess = async (id: number, val: number) => {
        try {
            let data = new FormData();
            data.append("is_lecture", `${val}`)
            const resp = await instance({
                method: "put",
                url: `/teacher-accesses/${id}`,
                data: data
            })
            if (resp?.data?.status == 1) {
                asyncN("success", "update", resp?.data?.message).then(() => {
                    setRefresh(!refresh);
                })
            } else {
                asyncN("error", "update", resp?.data?.message);
            }
        } catch (error: any) {
            asyncN("error", "update", error?.response ? error?.response?.data?.message : error?.message);
        }
    }

    return (
        <Row gutter={24}>
            <Col span={24} className="d-f justify-content-between mb-2">
                <CustomBreadcrumb arr={_breadcrump} />
                {queryParams?.set === undefined ? <Button onClick={() => {
                    history.push(`/subjects/${location?.params?.subjectId}/:teacherId/topics`)
                }} type="primary">
                    {t("Topics")}
                </Button> : ''}
            </Col>
            <Col span={24}>
                {
                    teacherAccesses.length ?
                    <div className="tableWrap">
                        <Table className="table">
                            <Thead>
                                <Tr>
                                    <Th>Teacher name</Th>
                                    <Th>Languages</Th>
                                    <Th>Lesson type</Th>
                                    <Th >Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    teacherAccesses?.map((item: teacherAccessType, index: number) => {
                                        return (
                                            <>
                                                <Tr>
                                                    <Td style={{ verticalAlign: "middle" }}>
                                                        <Link to={`/view-user/${item?.user_id}`}>
                                                            {
                                                                item?.teacher?.first_name?.toUpperCase() ||
                                                                item?.teacher?.last_name?.toUpperCase() ||
                                                                item?.teacher?.middle_name?.toUpperCase() ?
                                                                `${item?.teacher?.first_name?.toUpperCase() ?? "Ismi"} 
                                                                ${item?.teacher?.last_name?.toUpperCase() ?? "Familyasi"} 
                                                                ${item?.teacher?.middle_name?.toUpperCase() ?? "Otasining ismi"}`
                                                                : item?.user?.username?.toUpperCase()
                                                            }
                                                        </Link>
                                                    </Td>
                                                    <Td style={{ verticalAlign: "middle" }}>{<Tag>{item?.langs[0]?.name}</Tag>}</Td>
                                                    <Td>
                                                        <Select disabled={queryParams?.set !== undefined} className="w-100" onChange={(e) => {
                                                            updateTeacherAccess(item?.id, e);
                                                        }} value={item?.isLec?.filter((item1: { accId: number, type: number }) => item1?.accId == item?.id)[0]?.type} placeholder="Select type!">
                                                            <Select.Option value={1}>Ma'ruzachi</Select.Option>
                                                            <Select.Option value={0}>Ma'ruzachi emas</Select.Option>
                                                        </Select>
                                                    </Td>
                                                    <Td style={{ verticalAlign: "middle" }}>
                                                        <Link to={queryParams?.set == 0
                                                            ? `/subjects/${location?.params?.subjectId}/${item?.user_id}/topics?lang_id=${item?.langs[0]?.id}&taid=${item?.isLec[0]?.accId}&set=0`
                                                            : `/subjects/${location?.params?.subjectId}/${item?.user_id}/topics`}>
                                                            {t("Topics")} <FaArrowAltCircleRight />
                                                        </Link>
                                                    </Td>
                                                </Tr>
                                                {item?.langs?.length > 1 ?
                                                    item?.langs?.slice(1, item?.langs?.length)?.map((lang: { id: number, name: string }, i: number) => <Tr key={i}>
                                                        <Td></Td>
                                                        <Td style={{ verticalAlign: "middle" }}><Tag>{lang?.name}</Tag></Td>
                                                        <Td>
                                                            <Select disabled={queryParams?.set !== undefined} className="w-100" onChange={(e) => {
                                                                updateTeacherAccess(item
                                                                    ?.isLec?.filter((lections: { accId: number, type: number, langId: number }) =>
                                                                        lections?.langId == lang?.id)[0]?.accId, e)
                                                            }} value={item
                                                                ?.isLec?.filter((lections: { accId: number, type: number, langId: number }) =>
                                                                    lections?.langId == lang?.id)[0]?.type
                                                            } placeholder="Select type!">
                                                                <Select.Option value={1}>Ma'ruzachi</Select.Option>
                                                                <Select.Option value={0}>Ma'ruzachi emas</Select.Option>
                                                            </Select>
                                                        </Td>
                                                        <Td style={{ verticalAlign: "middle" }}>
                                                            <Link to={queryParams?.set == 0
                                                                ? `/subjects/${location?.params?.subjectId}/${item?.user_id}/topics?lang_id=${lang?.id}&taid=${item
                                                                    ?.isLec?.filter((lections: { accId: number, type: number, langId: number }) =>
                                                                        lections?.langId == lang?.id)[0]?.accId}&set=0`
                                                                : `/subjects/${location?.params?.subjectId}/${item?.user_id}/topics`}>
                                                                {t("Topics")} <FaArrowAltCircleRight />
                                                            </Link>
                                                        </Td>
                                                    </Tr>) : ''}
                                            </>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
                    </div> :
                    <EmptyTable data={teacherAccesses} loading={_data.loading}/>
                }
            </Col>
        </Row>
    )
}

export default SubjectTeachers