import {Card} from 'antd'
import React from 'react'
import { BiDotsVerticalRounded, BiEditAlt, BiTrash } from 'react-icons/bi'

function LessonCard(
    {title, lessons, index, setCanOpen, canOpen, canEdit}: 
    {title: string, lessons?: Array<any>, index: number, 
    setCanOpen: React.Dispatch<React.SetStateAction<number>>, canOpen: number, canEdit?: boolean}
    ): JSX.Element {
    return (
        <Card title={title}>
                {
                    canEdit ?
                    <>
                        <div className={`${index == canOpen ? 'open' : ''} delChan`}>
                            <div>
                                <BiTrash />
                            </div>
                            <div>
                                <BiEditAlt />
                            </div>
                        </div>
                        <div className="dotsAction" onClick={() => {
                            if(canOpen == index){
                                setCanOpen(-1)
                            } else {
                                setCanOpen(index)
                            }
                        }}>
                            <BiDotsVerticalRounded/>
                        </div>
                    </>
                    : null
                }
                {
                    lessons ? lessons.map((item: any, index: number) => (
                        <div className='raspisaniye' key={index}>
                            {index + 1}. {item.name}. ( {item.type} ) {item.build} / {item.room} / {index + 1} para / {item.start}-{item.end}
                        </div>
                    )) : <div className='raspisaniye'>Dars mavjud emas</div>
                }
        </Card>
    )
}

export default LessonCard