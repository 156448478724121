import { Button, Pagination, Popconfirm, Tag, Tooltip } from "antd";
import { ButtonCreate } from "components/Buttons";
import CreateButton from "components/CreateButton";
import EmptyTable from "components/EmptyTable";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import useDeleteOne from "hooks/useDeleteOne";
import useGetAllData from "hooks/useGetAllData";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import isHasAuthRoute from "utils/isHasRoute";
import HolidayCreate from "./crud/create";
import HolidayEdit from "./crud/update";
import HolidayView from "./crud/view";

const Holiday: FC = (): JSX.Element => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [id, setId] = useState<number>()
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [viewVisible, setViewVisible] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const _data = useGetAllData({ url: `/holidays?sort=-id&expand=description`, perPage: perPage, currentPage: currentPage, isCall: 'auto', refetch: [refresh, perPage, currentPage] });
  const _delete = useDeleteOne();

  return (
    <div>
      <HolidayCreate refresh={refresh} setRefresh={setRefresh} visible={createVisible} setVisible={setCreateVisible} />
      <HolidayEdit id={id} refresh={refresh} setRefresh={setRefresh} visible={editVisible} setVisible={setEditVisible} />
      <HolidayView id={id} refresh={refresh} setRefresh={setRefresh} visible={viewVisible} setVisible={setViewVisible} />
      <ForTitle title='Holiday' buttons={[<ButtonCreate onClick={() => setCreateVisible(true)} />]}/>
      <ForTable currentPage={currentPage} perPage={perPage} setCurrentPage={setCurrentPage} setPerPage={setPerPage} totalCount={_data.data?._meta.totalCount} loading={loading} datas={_data.items} theads={
          [
            <>{t("Name")}</>,
            <>{t("Description")}</>,
            <>{t("Start date")}</>,
            <>{t("End date")}</>,
            <>{t("Actions")}</>,
          ]
        } tbodys={
          function(this: any){
            return [
              <span className="fio_link" onClick={() => { setId(this?.id); setViewVisible(true) }} >{this?.name}</span>,
              this?.description,
              this?.start_date,
              this?.finish_date,
              <div className="crud_actions_table">
                {isHasAuthRoute("holiday_view") && (
                  <Tooltip placement="top" title={t("View")}>
                    <TbFileInvoice  className="view_btn mx-0" onClick={() => { setId(this?.id); setViewVisible(true) }} />
                  </Tooltip>)}
                {isHasAuthRoute("holiday_update") && (
                  <Tooltip placement="top" title={t("Edit")}>
                    <TbFilePencil className="edit_btn" onClick={() => { setId(this?.id); setEditVisible(true) }} />
                  </Tooltip>
                )}
                {isHasAuthRoute("holiday_delete") && (
                  <Tooltip placement="top" title={t("Delete")}>
                    <Popconfirm
                      placement="topLeft"
                      title={`${t("Deleted it")} ?`}
                      okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                      onConfirm={() => { _delete.fetch(`/holidays/${this?.id}`); setRefresh(!refresh) }}
                    >
                      <TbFileOff className="text-danger" />
                    </Popconfirm>
                  </Tooltip>
                )}
              </div>
            ]
          }
        }/>
    </div>
  )
}

export default Holiday;