import { Button, Col, Input, Pagination, Row, Select, Spin, Tag } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import { QR_LOGIN_URL } from "config/utils";
import useDebounce from "hooks/useDebounce";
import useGetAllData from "hooks/useGetAllData";
import { _meta } from "pages/role_and_permission/components/types";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPrint } from "react-icons/fa";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { studentLoginPasswordType } from "../types";
import InputMask from 'react-input-mask';
import './style.scss'
import useUrlQueryParams from "hooks/useUrlQueryParams";
import FormLabel from "components/FormLabel";
import FilterSelectComponent from "components/FilterSelect";
import CustomPagination from "components/Pagination";



const { Option } = Select;

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Students",
        path: 'student_index'
    },
    {
        name: "Student login and password",
        path: '/student-login-password'
    },
]

const LoginAndPasswordPage: FC = (): JSX.Element => {

    const { t, i18n } = useTranslation();
    let studentRef: any = useRef()
    const { value, writeToUrl } = useUrlQueryParams({});

    const debouncedLastName = useDebounce<string>(value.filter_like?.last_name, 500);
    const debouncedFirstName = useDebounce<string>(value.filter_like?.first_name, 500);
    const debouncedMiddleName = useDebounce<string>(value.filter_like?.middle_name, 500);
    const debouncedPassportSeria = useDebounce<string>(value.filter_like?.passport_seria, 500);
    const debouncedPassportNumber = useDebounce<string>(value.filter_like?.passport_number, 500);
    const debouncedPassportPin = useDebounce<string>(value.filter_like?.passport_pin, 500);
    const debouncedFacultyId = useDebounce<string | number | undefined>(value?.filter.faculty_id, 500);
    const debouncedCourseID = useDebounce<string | number | undefined>(value?.filter.course_id, 500);
    const debouncedEduPlanId = useDebounce<string | number | undefined>(value?.filter.edu_plan_id, 500);
    const debouncedLangId = useDebounce<string | number | undefined>(value?.filter.edu_lang_id, 500);

    const std_passwords = useGetAllData({
        url: `/students?expand=usernamePass,profile,faculty&fields=profile.last_name,profile.first_name,profile.middle_name,faculty.name&filter-like=${JSON.stringify(value.filter_like)}&filter=${JSON.stringify(value.filter)}`,
        perPage: value.perPage,
        currentPage: value.currentPage,
        isCall: "auto",
        refetch: [i18n.language, debouncedLastName, debouncedFirstName, debouncedPassportPin, debouncedMiddleName, debouncedPassportSeria, debouncedPassportNumber, debouncedFacultyId, debouncedCourseID, debouncedEduPlanId, debouncedLangId]
    })

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        writeToUrl({ name, value });
    }

    return (
        <Spin spinning={std_passwords.loading}>
            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <CustomBreadcrumb arr={_breadcrump} />
                        </div>
                        <div>
                            <ReactToPrint
                                trigger={() => {
                                    return <Button type="primary" icon={<FaPrint size={20} className="pe-1" />}>Chop etish</Button>;
                                }}
                                content={() => {
                                    return studentRef;
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Row gutter={[12, 12]} className="mb-2">
                    <FilterSelectComponent label="faculty" name="faculty_id" permission="faculty_index" url="faculties" child_names={["edu_plan_id"]} />
                    <FilterSelectComponent label="edu plan" name="edu_plan_id" permission="edu-plan_index" url="edu-plans" parent_name="faculty_id" />
                    <FilterSelectComponent label="course" name="course_id" permission="course_index" url="courses" />
                    <FilterSelectComponent label="language" name="edu_lang_id" permission="languages_index" url="languages" />
                    <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                        <FormLabel>Search by last name</FormLabel>
                        <Input name="last_name" placeholder="Search by last name" value={value.filter_like?.last_name} onChange={handleSearch} allowClear />
                    </Col>
                    <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                        <FormLabel>Search by first name</FormLabel>
                        <Input name="first_name" placeholder="Search by first name" value={value.filter_like?.first_name} onChange={handleSearch} allowClear />
                    </Col>
                    <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                        <FormLabel>Search by passport seria and number</FormLabel>
                        <div className="d-flex">
                            <InputMask mask="aa" placeholder="AA" className='ant-input' name="passport_seria" value={value.filter_like?.passport_seria} onChange={handleSearch} style={{ borderRight: "none", width: "70px" }} />
                            <InputMask mask="9999999" placeholder="9999999" className='ant-input' name="passport_number" value={value.filter_like?.passport_number} onChange={handleSearch} style={{}} />
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                        <FormLabel>Search by JSHIR</FormLabel>
                        <Input name="passport_pin" placeholder="Search by SHRJ" value={value.filter_like?.passport_pin} onChange={handleSearch} allowClear />
                    </Col>
                </Row>
                <Col span={24}>
                    <div ref={(el) => {
                        studentRef = el
                    }} className="d-flex flex-wrap justify-contet-center">
                        {
                            std_passwords.items.length ? std_passwords.items?.map((item: studentLoginPasswordType, i: number) =>
                                <>
                                    <div className="p-2 d-flex student-info-card">
                                        <div key={i} className="ps-3 pe-3 pt-3 pb-2 d-flex card shadow student-info-card-in">
                                            <div className="text-center border-bottom mb-3 pb-2">
                                                <div>Digital TSUL</div>
                                                <div style={{ fontSize: "12px" }}>(http://std.tsul.uz talaba kabineti)</div>
                                            </div>
                                            <div className="" style={{ width: "100%", borderRadius: "10px" }}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="pe-1" style={{ fontSize: "13px" }}>
                                                        <div>
                                                            <span><strong>Texnikum</strong>: <span >{item?.faculty?.name}</span>.</span>
                                                        </div>
                                                        <div>
                                                            <span><strong>Full name</strong>: <span >{item?.profile?.first_name + " " + item?.profile?.last_name + " " + item?.profile?.middle_name}</span>.</span>
                                                        </div>
                                                        <div>
                                                            <span><strong>Login</strong>: <span style={{ fontWeight: 800, fontFamily: 'Roboto Mono' }}>{item?.usernamePass?.username}</span></span>
                                                        </div>
                                                        <div>
                                                            <span><strong>Password</strong>: <span style={{ fontWeight: 800, fontFamily: 'Roboto Mono' }}>{item?.usernamePass?.password}</span></span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <QRCode value={`${QR_LOGIN_URL}/${item?.usernamePass?.username}/${item?.usernamePass?.password}`} size={85} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (i + 1) % 10 === 0 && <div className="page-break-cont"></div>
                                    }
                                </>
                            ) : null
                        }
                    </div>
                </Col>
                <Col span={24}>
                    <CustomPagination currentPage={value.currentPage} perPage={value.perPage} totalCount={std_passwords.data._meta.totalCount} />
                </Col>
            </Row>
        </Spin >
    )
}

export default LoginAndPasswordPage;