import { message } from "antd";
import instance from "config/_axios"
import store from "store";
import { asyncN } from "utils/notifications";
import getAsyncInitialData from "./async_requests";
import { question_status_list } from "pages/questions/confirm_status";


export const delete_data = async (url: string, id: number, data?: any): Promise<any> => {
    try {
        const response = await instance(data ? { url: `${url}/${id}`, method: "DELETE", data: data } : { url: `${url}/${id}`, method: "DELETE" });

        if (response.data?.status) {
            asyncN('success', 'delete', response.data?.message)
        } else {
            asyncN('error', 'delete', response.data?.message)
        }

        return new Promise((resolve, reject) => {
            resolve("success")
            reject("Something went wrong while deleting data!")
        })

    } catch (error) {
        message.error("Something went wrong !", 1)
    }

}




export const getAllLanguages = async (path_prefix?: string) => {
    try {
        const { data } = store.getState().data.languages;

        if (store.getState().data.languages.data.length === 0) {
            await store.dispatch(getAsyncInitialData({ key: 'languages', url: 'languages', path_prefix }))
            return {
                items: store.getState().data.languages.data
            }
        } else {
            return { items: data }
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllStatuses = async (path_prefix?: string) => {
    try {
        const response = await instance({ url: `/user-statuses?${path_prefix ? path_prefix : ''}`, method: 'GET' });
        if (response.data?.status === 1) {
            return response.data.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error) {

    }
}

export const changeItemStatus = async (path_prefix?: string, status?: boolean) => {
    try {
        const formdata = new FormData();
        formdata.append("status", status ? "1" : "0")
        const response = await instance({ url: path_prefix, method: 'PUT', data: formdata });
        if (response?.data?.status === 1) {
            if (response?.data?.data?.status === "1") {
                return {
                    backgroundColor: "green",
                }
            } else {
                return {
                    backgroundColor: "red",
                }
            }
        } else {
            return new Error(response.data?.message)
        }
    } catch (error) {

    }
}


export const getPassword = async (id: number) => {
    try {
        const response = await instance({ url: 'passwords/' + id, method: 'GET' });
        if (response?.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response?.data?.message)
        }
    } catch (error) {

    }
}


export const getAllWeeks = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/weeks${path_prefix ? '?' + path_prefix : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllEduSemesters = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/edu-semestrs${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllTracherAccess = async (perPage?: number | string, currentPage?: number | string, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/teacher-accesses${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}



export const getAllCountries = async (perPage?: number | string, path_prefix?: string) => {
    try {
        const { data } = store.getState().data.countries;

        if (store.getState().data.countries.data.length === 0) {
            await store.dispatch(getAsyncInitialData({ key: 'countries', url: 'countries', path_prefix, params: { "per-page": perPage } }))
            return {
                items: store.getState().data.countries.data
            }
        } else {
            return { items: data }
        }

    } catch (error: any) {
        message.error(error?.response?.message)
    }
}



export const getAllRegions = async (path_prefix?: string) => {
    try {
        const { data } = store.getState().data.regions;

        if (store.getState().data.regions.data.length === 0) {
            await store.dispatch(getAsyncInitialData({ key: 'regions', url: 'regions', path_prefix }))
            return {
                items: store.getState().data.regions.data
            }
        } else {
            return { items: data }
        }

    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllRegionsWithCountryId = async (id: number) => {
    try {
        const { data } = store.getState().data.other;
        if (!data[`country_id_${id}`] && id) {
            await store.dispatch(getAsyncInitialData({ key: 'country_id_' + id, url: 'regions', path_prefix: String('country_id=' + id) }))
            return {
                items: store.getState().data.other.data[`country_id_${id}`]
            }
        } else {
            return { items: data[`country_id_${id}`] }
        }

    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getAllAreas = async (path_prefix?: string) => {
    try {

        const response = await instance({ url: `/areas${path_prefix ? (path_prefix.startsWith('/') ? path_prefix : '?' + path_prefix) : ''}`, method: "GET" });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export const getNotifications = async () => {
    try {
        await store.dispatch(getAsyncInitialData({ key: 'messages', url: 'notifications', path_prefix: "sort=-id&expand=statusName" }))
        return {
            items: store.getState().data.messages.messages
        }

    } catch (error: any) {
        message.error(error?.response?.message)
    }
}


export const getAllQuestionTypes = async (perPage?: number, currentPage?: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/question-types${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getQuestionStatusList = (): { _fetch: () => void, _statuses: Array<{ id: number, status: number[], title: string, status_ui: any }> } => {

    let _statuses: Array<{ id: number, status: number[], title: string, status_ui: any }> = [];

    const _fetch = async () => {

        const response = await instance({ url: '/questions/status-list' });

        if (response.data?.status === 1) {

            const data = response.data?.data;

            if (Object.getOwnPropertyNames(data).length) {
                question_status_list.forEach((element) => {
                    if (data.hasOwnProperty(2)) {
                        console.log("data -> ", data)
                        _statuses.push(element);
                    }
                })
            }

        } else {
            new Error(response.data?.message);
        }
    }

    return { _fetch, _statuses }
}

export async function _getAllTutors(){
    const response = await instance({ url: `/users?expand=user&filter={%22-role_name%22:[%22student%22],%22role_name%22:%22tutor%22}&per-page=0`, method: "GET"});
    if (response.data?.status === 1) {
        return response.data?.data
    } else {
        return new Error(response.data?.message)
    }
} 