import { FC, useState } from "react";
import { Button, Checkbox, Col, Form, Input, message, Row, Select, Tabs, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from "antd-img-crop";


const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

const OtherDetails: FC = (): JSX.Element => {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [checkbox, setCheckbox] = useState<Array<{ name: string, key: string, isDone: boolean, arrays: Array<{ nameKey: string, urlKey?: string }> }>>([
    {
      name: "Maqolalar",
      key: "maqola",
      isDone: false,
      arrays: [{ nameKey: "maqola_0", urlKey: "maqola_url_0" }],
    },
    {
      name: "Ilmiy ishlar",
      key: "ilmiy_ish",
      isDone: false,
      arrays: [{ nameKey: "ilmiy_ish_0" }],
    },
    {
      name: "Yutuqlar",
      key: "yutuq",
      isDone: false,
      arrays: [{ nameKey: "yutuq_0" }],
    },
  ]);

  const checked = (e: any, i: string) => {
    checkbox.filter(item => item?.key === i)[0].isDone = e.target.checked;
    setCheckbox(checkbox);
    setIsLoading(!isLoading);
  }

  const addInput = (key: { nameKey: string, urlKey?: string }, i: number, idx: number) => {
    checkbox[i]?.arrays?.push({ nameKey: `${key?.nameKey}_${idx + 1}` });
    setCheckbox(checkbox);
    setIsLoading(!isLoading);
  }

  const removeInput = (i: number, idx: number) => {
    checkbox[i]?.arrays?.splice(idx, 1);
    setCheckbox(checkbox);
    setIsLoading(!isLoading);
  }

  const file_upload = () => (<div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>)

  const handleChangeFile = (e: any) => {
    if (e?.file?.status !== "removed") {
      if (e?.file?.size < 5242880) {
        setFileList([
          ...fileList,
          {
            name: e?.file?.originFileObj?.name,
            uid: Number(fileList[fileList?.length - 1]?.uid),
            url: window.URL.createObjectURL(e?.file?.originFileObj)
          }]);
      } else {
        message.error("Rasm hajmi 5Mb dan oshmasligi kerak !");
      }
    } else {
      setFileList(e?.fileList);
    }
  }

  return (

    <Row gutter={[12, 0]} className="mb-4" >
      <Col span={24} className="mb-4" >
        {
          checkbox?.length && checkbox?.map((element, i) => (
            <Checkbox key={i} checked={element?.isDone} onChange={(e) => checked(e, element?.key)} >{element?.name}</Checkbox>
          ))
        }
      </Col>
      {
        checkbox.length && checkbox?.map((e, i) => (
          e?.isDone && e?.arrays?.length ? e?.arrays?.map((key, idx) => (
            <Col key={idx} span={24}>
              {
                e?.key !== "yutuq" ? <>
                  <div className="form_ui_flex w-100" >
                    <Form.Item
                      label={idx < 1 && t(e?.name)}
                      name={key?.nameKey}
                      shouldUpdate
                      rules={[{ required: true, message: `${t("Fill in the field")}!` }]}
                      className="form_ui_flex_form_item w-100 mb-2"
                    >
                      <TextArea rows={1} placeholder={`${t(`${e?.name} nomini kiriting`)}...`} />
                    </Form.Item>
                    {
                      e?.key === "maqola" && <Form.Item
                        label={idx < 1 && t("url")}
                        name={key?.urlKey}
                        shouldUpdate
                        rules={[{ required: true, message: `${t("Fill in the field")}!` }]}
                        className="form_ui_flex_form_item w-100 mb-2"
                        style={{ marginLeft: "1rem" }}
                      >
                        <TextArea rows={1} placeholder={`${t(`${e?.name} linkini kiriting`)} ...`} />
                      </Form.Item>
                    }
                    {e?.arrays?.length !== 1 && <Button style={{ marginTop: `${idx === 0 && '30px'}`, marginLeft: "1.2rem" }} onClick={() => removeInput(i, idx)} danger shape="circle" type="primary"><FaTimes /></Button>}
                  </div>
                  {idx === e?.arrays?.length - 1 && <><Button className="m-0 p-0" onClick={() => addInput(key, i, idx)} type="link">+ {e?.name}ni qo'shish</Button><hr className="mt-0 mb-4" /></>}
                </> : <Form.Item
                  label={t(e?.name)}
                  name={key?.nameKey}
                >
                  {/* <ImgCrop>
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      maxCount={6}
                      multiple={true}
                      onChange={(e) => handleChangeFile(e)}
                    >
                      {fileList.length < 6 && file_upload()}
                    </Upload>
                  </ImgCrop> */}
                </Form.Item>
              }
            </Col>
          )) : null
        ))
      }
    </Row>

  )
}

export default OtherDetails;