export const onPreview = async (file: any) => {
    let src = file.url;
    let name: string = file?.name;

    if (name) {
        switch (name.substring(name.lastIndexOf(".") + 1, name.length)) {
            case "pdf": {
                let a = document.createElement('a');
                a.target = '_blank';
                a.href = src;
                a.click();
                a.remove();
            }; break

            case "doc": {
                let a = document.createElement('a');
                a.target = '_blank';
                a.href = src;
                a.click();
                a.remove();
            }; break

            case "img": {
                if (!src) {
                    src = await new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.originFileObj);
                        reader.onload = () => resolve(reader.result);
                    });
                }
                const image = new Image();
                image.src = src;
                const imgWindow = window.open(src);
                imgWindow?.document.write(image.outerHTML);
            }; break

            case "png": {
                if (!src) {
                    src = await new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.originFileObj);
                        reader.onload = () => resolve(reader.result);
                    });
                }
                const image = new Image();
                image.src = src;
                const imgWindow = window.open(src);
                imgWindow?.document.write(image.outerHTML);
            }; break

            case "image": {
                if (!src) {
                    src = await new Promise(resolve => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.originFileObj);
                        reader.onload = () => resolve(reader.result);
                    });
                }
                const image = new Image();
                image.src = src;
                const imgWindow = window.open(src);
                imgWindow?.document.write(image.outerHTML);
            }; break

            default: break
        }
    }
};


export const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
}
