import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStudent } from "models/student";
import { comparingStudentData, TypeComparingStudentData } from "pages/student/utils";



export type TypeInitialStateStudent = {
    isMilitary: boolean,
    activeTabKey: string,
    imageFile: Array<any>,
    passportFile: Array<any>,
    visible: 'dormitory' | 'export' | 'compare' | null,
    petition_id: number | null,
    compare_fields: Array<TypeComparingStudentData>,
    info: IStudent | null,
}

const initialState: TypeInitialStateStudent = {
    isMilitary: false,
    activeTabKey: '1',
    imageFile: [],
    passportFile: [],
    visible: null,
    petition_id: null,
    compare_fields: comparingStudentData,
    info: null,
}

const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        setIsMilitary(state) {
            state.isMilitary = !state.isMilitary;
        },
        setActiveTabKey(state, action: PayloadAction<{ key: string | number }>) {
            const { key } = action.payload;
            state.activeTabKey = typeof key === 'string' ? key : String(key);
        },
        closeModal(state) {
            state.visible = null
        },
        openModal(state, action: PayloadAction<{ modal_name: TypeInitialStateStudent['visible'], petition_id?: number }>) {

            const { modal_name, petition_id } = action.payload;
            state.visible = modal_name;
            if (petition_id) {
                state.petition_id = petition_id
            }
        },
        removeField(state, action: PayloadAction<{ field_id: number }>) {

            const { field_id } = action.payload;

            state.compare_fields = state.compare_fields.filter(fieldItem => fieldItem.id !== field_id);
        },
        clearChanges(state) {
            state.compare_fields = comparingStudentData;
        },
        addInfo(state, action: PayloadAction<{ info: IStudent }>) {
            state.info = action.payload.info;
        },

    }
})


export const STUDENT_ACTIONS = studentSlice.actions;



export default studentSlice;
