import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import instance from "config/_axios";

const SignIn = createAsyncThunk(
    'user/SignIn',
    async (data: { type: string, data?: any }, { rejectWithValue }) => {
        try {
            let response = null;
            if (data.type === 'login') {
                response = await instance({ url: '/auth/login', method: 'POST', data: data?.data ? data.data : null });
            } else {
                response = await instance({ url: '/users/me', method: 'GET', params: { is_main: '1' } });
            }
            const _data = response.data;            
            if (_data?.status === 1) {
                if (!_data?.data?.permissions.length) {
                    message.error("ERR: user permissions are invalid !!!");
                } else {
                    if (_data?.data?.access_token) {
                        localStorage.setItem('access_token', _data.data.access_token);
                    }
                }

                return _data;
            }
            else {
                return rejectWithValue(new Error("Error !"));
            }


        } catch (error: any) {
            message.error(error.response.data.message)
            for (let index = 0; index < error.response.data.errors.length; index++) {
                message.error(error.response.data.errors[index])
            }
            return rejectWithValue(error.response);
        }
    }
)

export default SignIn;

