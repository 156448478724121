import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Upload, DatePicker, Tabs, Switch } from "antd";
import UserAvatar from "components/userAvatar/avatar";
import InputMask from 'react-input-mask';
import moment from 'moment';
import instance from "config/_axios";
import { useLocation, useHistory } from 'react-router-dom'
import CustomBreadcrumb from 'components/Breadcrumb';
import { asyncN } from "utils/notifications";
import { FaUpload, FaUndo, FaRegAddressCard, FaIdCard, FaUserCog, FaCheck, FaTrash } from "react-icons/fa";
import { CgCheckO } from "react-icons/cg";
import { role, teacherAccess, userAccessType } from "./types";
import { getAllUsers, getGenders, getOneUsers, getTeacherAccessByUserId, getUserAccessTypes, getUserStatuses } from '../servise';
import { FILE_URL } from 'config/utils';
import { useTranslation } from 'react-i18next';
import { getAllRoles } from '../../role_and_permission/service';
import { getAllSubjects } from '../../subjects/service';
import { BiLocationPlus } from 'react-icons/bi';
import { getAllRegionsWithCountryId } from 'services';
import { Tbody, Td, Th, Thead, Tr, Table } from 'react-super-responsive-table';
import { ServerErrors } from 'utils/validationFunctions';
import UserAccess from './userAccess';
import TeacherAccess from 'pages/teachers/crud/teacherAccess';
import { useAppSelector } from 'store/services';
import { diplomaTypeType } from "pages/diploma_type/types";
import { degreeType } from "pages/degree/types";
import { academicDegreeType } from "pages/academic_degree/types";
import { degreeInfos } from "pages/degree_info/types";
import { partyaType } from "pages/party/types";
import { getAllDegrees } from "pages/degree/service";
import { getAllDiplomaTypes } from "pages/diploma_type/service";
import { getAllDegreeInfo } from "pages/degree_info/service";
import { getAllPartiyas } from "pages/party/service";

const layout = {
    labelCol: { offset: 0, span: 24 },
    wrapperCol: { offset: 0, span: 24 },
};
const { Option } = Select;

const { TabPane } = Tabs

const { RangePicker } = DatePicker;

const EditUser = () => {


    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const auth: any = useAppSelector(state => state.auth);
    const [countries, setCountries] = useState<any>([]);
    const [avatar, setAvatar] = useState<any>(null);
    const [avatarStr, setAvatarStr] = useState<string>();
    const dateFormat = 'YYYY-MM-DD';
    const [roles, setRoles] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>("");
    const [pass, setPass] = useState<string>("");
    const [errRePass, setErrRePass] = useState<boolean>(false);
    const [showPass, setShowPass] = useState<boolean>(false);
    const [date, setDate] = useState<any>();
    const [userStatus, setUserStatus] = useState<any>([]);
    const state1 = useLocation<any>()?.pathname;
    let a: Array<string> = state1?.split("/")
    let id = a[a?.length - 1]
    const history = useHistory()
    const [subjects, setSubjects] = useState<Array<{ id: number; name: string }>>(
        []
    );
    const [regionsP, setRegionsP] = useState<any>([]);
    const [regions, setRegions] = useState<any>([]);
    const [subjectId, setSubjectId] = useState<number>(0);
    const [subjectIds, setSubjectIds] = useState<Array<number>>([]);
    const [selectedLangs, setSelectedLangs] = useState<any>({});
    const [areas, setAreas] = useState<any>([]);
    const [roleTeacher, setRoleTeacher] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any>([]);
    const [teacher_access_list, setTeacherAccessList] = useState({});
    const [langs, setLangs] = useState<Array<{ id: number; name: string }>>([]);
    const [state, setState] = useState<any>(state1);
    const [refresh, setrefresh] = useState(true);
    const [areasP, setAreasP] = useState<any>([]);
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const [tabPageStatus, setTabPageStatus] = useState<'success' | 'error'>('success');
    const [fields, setFields] = useState<any>(new Object());
    const [gender, setGender] = useState<any>([
        {
            id: 1,
            name: "Erkak"
        },
        {
            id: 2,
            name: "Ayol"
        }
    ]);

    const [userAccessTypes, setUsetAccessTypes] = useState<Array<userAccessType>>([]);
    const [userAccessVariants, setUserAccessVariants] = useState<Array<{ id: number, name: string }>>([])
    const [userAccesses, setUserAccesses] = useState<any>({});
    const [accessVariantsObj, setAccessVariantsObj] = useState<any>({})
    const [errorResponseCheck, setErrorResponseCheck] = useState<boolean>(false);
    const [errorTabs, setErrorTabs] = useState<Array<string>>([]);
    const [visibleTutorField, setVisibleTutorField] = useState<boolean>(false)
    const [tutors, setTutor] = useState<Array<{ last_name: string, first_name: string, id: number }>>([])
    const [addressCheck, setAddressCheck] = useState<{ region: boolean, area: boolean, regionP: boolean, areaP: boolean, address: boolean, addressP: boolean }>({
        region: false, area: false, regionP: false, areaP: false, address: false, addressP: false
    })
    const [disabled, setDisabled] = useState<{ is_leader: boolean, user_access: boolean }>({
        is_leader: true, user_access: true
    })
    const [diplomaTypes, setDiplomaTypes] = useState<Array<diplomaTypeType>>([])
    const [degrees, setDegrees] = useState<Array<degreeType>>([])
    const [academicDegrees, setAcademicDegrees] = useState<Array<academicDegreeType>>([])
    const [degreeInfos, setDegreeInfos] = useState<Array<degreeInfos>>([])
    const [partyas, setPartyas] = useState<Array<partyaType>>([])

    let _locationTabError: any = {
        address: "3",
        area_id: "3",
        country_id: "3",
        dob: "1",
        email: "1",
        first_name: "1",
        gender: "1",
        last_name: "1",
        middle_name: "1",
        passport_date: "2",
        passport_file: "2",
        passport_given_by: "2",
        passport_pin: "2",
        passport_seria_and_number: "2",
        permanent_address: "3",
        permanent_area_id: "3",
        permanent_country_id: "3",
        permanent_region_id: "3",
        phone: "1",
        phone_secondary: "1",
        region_id: "3",
        role: "1",
        status: "1",
        username: "1"
    }

    const _breadcrump = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Users",
            path: "users",
        },
        {
            name: state?.view ? "View user" : "Edit Users",
            path: "edit-user",
        },
    ];

    const _diplomaTypes = async () => {
        const resp = await getAllDiplomaTypes(1, 0);
        setDiplomaTypes(resp?.items);
    }

    const _degrees = async () => {
        const resp = await getAllDegrees(1, 0);
        setDegrees(resp?.items);
    }

    const _academicDegrees = async () => {
        const resp = await getAllDegrees(1, 0);
        setAcademicDegrees(resp?.items);
    }

    const _degreeInfos = async () => {
        const resp = await getAllDegreeInfo(1, 0);
        setDegreeInfos(resp?.items);
    }

    const _partyas = async () => {
        const resp = await getAllPartiyas(1, 0);
        setPartyas(resp?.items);
    }

    useEffect(() => {
        _partyas();
        _degreeInfos();
        _academicDegrees();
        _degrees();
        _diplomaTypes();
    }, [])

    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true)
                    const statusResp = await getUserStatuses()
                    let s: Array<any> = [];
                    Object.entries(statusResp).forEach(([key, value]: any) => {
                        s.push({ key: key, value: value })
                    })
                    setUserStatus(s);
                    const roleResp = await getAllRoles()
                    setRoles(roleResp?.items);
                    const genderResp = await getGenders()
                    let a = genderResp;
                    let b: any = []
                    Object.entries(a).map(([key, value]: any) => {
                        b = [
                            ...b,
                            { id: Number(key), name: value },
                        ];
                    })
                    setGender(b);
                    const userRes = await getOneUsers(Number(id), "expand=profile,teacherAccess,userAccess")
                    let data = userRes?.data

                    if (data?.userAccess) {
                        let acc: any = {};
                        data?.userAccess?.map((item: any) => {
                            getUserAccessVariants(Number(item?.user_access_type_id))
                            if (acc[`${item?.user_access_type_id}`]?.length > 0) {
                                acc = { ...acc, [`${item?.user_access_type_id}`]: [...acc[`${item?.user_access_type_id}`], `${item?.table_id}-${item?.is_leader}`] }
                            } else {
                                acc = { ...acc, [`${item?.user_access_type_id}`]: [`${item?.table_id}-${item?.is_leader}`] }
                            }
                        })
                        setUserAccesses(acc)

                    }

                    setAvatarStr(data?.profile?.image ? FILE_URL + data?.profile?.image : "");
                    setDate(data?.profile?.dob);
                    setFirstName(data?.profile?.first_name);
                    data?.profile?.passport_file && data?.profile?.passport_file !== "undefined" && setFileList([{
                        uid: '-1',
                        name: 'Passport file.' + data?.profile?.passport_file.substring(data?.profile?.passport_file.lastIndexOf(".") + 1, data?.profile?.passport_file.length),
                        status: 'done',
                        url: FILE_URL + data?.profile?.passport_file,
                        thumbUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAllBMVEX///8Aark2icgAX7UAY7be6PMAYLXS5fKYudwAZ7gAZbfO3u76/f4AXLTt9fsGbLovgcSArddqoNHM2esAZbGlvt4AWp8pd7MfbakATIaKs9pDg8NalMsAcb2nxuO0z+cOfMMigsUAWp0AYam91eoddLhMjMjp8fgZZ6QMWZYAUZAvgL4RWpIlcasAc7oAV6V8pNJhmM2KPTNXAAAELElEQVR4nO3da3eaQBSFYYlIHLwba5oo0XptTWrj//9zxVy7ulCHwDn76NrvV13SpzPEEQErFcYYY4wxxhhjjLGLa92Q6KaGdr33MIqr5RdVW8kETXupPYpcINFgsPxhgdhOYhFfKpwvr6YGiKtQCBgMht+vrvCjOImkgMFglgrxxJXUHH0bQzhxLeb7EIL3xVpVXogdRRUhlKgjRE5UJSFwFLWEOKKaEEbUE6L2RUUhaBQ1hRiiqhAyUXWFCKKyEDBRtYX6RHWh+kTVF2qPIkCoTEQIdYkQoeq+iBFqjiJIqEhECfUmKkyoRsQJtSYqUKhERAp1JipUqDKKWKEGESxUIKKF8vsiXCg+inihNNGAUHiiWhDKjqIJoSjRhlCSaEQouC9aEcqNohmhGFFW+DOHUGqiygp/5REKEUWF881tLqLIRJUVzvINoghRUphO002uPVFkosoK57O7nMTyR1FU+EJ8esQSZYV74ubu6fetf4/bxVkJU+JwttncebcZ/qmflzAYDObz4XA29Gs2nJ+dcG/MVXh+wpxRSCGF+Cik8MvC+Oi1Ne7z4qnsy6ic99VVKGG8Wx97mWb9XRBnr5ybz75ElDBpn3ih8es1jG534PFmYlvoRqde6P51jOL+pQuDcFFpZmV9lvoLg6iTZOS8LwK0LywahRRSSCGF6b8wCjPyv22DeWHYq2XVWPkSrQvj1YHHvS+LNy98PvB4c+s5iNaFQefAPWkeLmZd6jrjrPxvTmFemE5Ul5Uv8ByEBaOQQgopPC10YZSRv9+8MB49ZFXvXMq61HUPbcj6mqbbPPFCvVPr0qXtden+MOjR2snbMe/pgf+KhvExTDfc/3akevI+Qm6a+YR7z80gv12Lsz66f/T5PHfqCWaFWlFIIYX4UEIXF834qi0Y94s2Nr2miRolbGrhdy9YiNBNS9mWFxA0hq6MTbUNj2HgVovCty9fjPx2RNSnp6j4DcxN/6XRjEIKKcQHW5cWz7YwXHaLtvU8kAFalz5knm+Yr7ofEbMu3ZayLctrmqSUbfl90Y0Rhr11u2jrvmVhEAadogWe5yrw/ZBCCvFRSOEXhXGrcFXTRzHifvF3/NrO8jt+p5RtceUNFAZRGbfj8DyHFvSXJtoW/gSceP6EHY9iSAn1opBCCvHx2zUZYTya3BRt0rW8polPnV3qk+VvuQ+e+JsvLyBqXVrGT4g3DI9h+vHpuVe0vteGgPc2OXr+rE++F6/x/ZBCCvFRSCGF+CikkEJ8FFJIIT4KKaQQH4UUUoiPQgopxEchhRTio5BCCvFRmLd2By36v2rJv39Y8bwKQq9OGWeY/ZvnmUpqRSVP0rSeqT0xHpU9hGk7Q8Rwe+pXl75Ur2pkX3StlQiwUrkeV6tZt8ZVrtWV+gX5fbVrfELjxxhjjDHGGGOMnXl/ASwK93DMPv2HAAAAAElFTkSuQmCC"
                    }]);
                    if (data?.role.includes('teacher')||data?.role.includes('edu_quality')) {
                        const responseAcc = await getTeacherAccessByUserId(data?.id)
                        let responseAc = responseAcc?.data
                        const itemList: any = responseAc?.items;
                        let access: any = {};
                        itemList.map((item: teacherAccess) => {
                            if (access[item?.subject_id])
                                access = { ...access, [item?.subject_id]: [...access[item?.subject_id], item?.language_id] };
                            else
                                access = { ...access, [item?.subject_id]: [item?.language_id] }
                        });
                        setTeacherAccessList(access);

                        getLangs();
                        getSubjects()
                        setRoleTeacher(true);
                    }

                    onChangeGetCountry();

                    form.setFieldsValue({
                        address: data?.profile?.address,
                        area_id: data?.profile?.area_id,
                        birthDay: data?.profile?.birthday ? moment(data?.profile?.birthday) : "",
                        country_id: data?.profile?.country_id,
                        passport_given_by: data?.profile?.passport_given_by,
                        passport_number: data?.profile?.passport_number,
                        passport_pin: data?.profile?.passport_pin,
                        permanent_address: data?.profile?.permanent_address,
                        permanent_area_id: data?.profile?.permanent_area_id,
                        permanent_country_id: data?.profile?.permanent_country_id,
                        permanent_region_id: data?.profile?.permanent_region_id,
                        region_id: data?.profile?.region_id,
                        phone_secondary: data?.profile?.phone_secondary,
                        email: data?.email,
                        first_name: data?.profile?.first_name,
                        gender: data?.profile?.gender,
                        last_name: data?.profile?.last_name,
                        middle_name: data?.profile?.middle_name,
                        phone: data?.profile?.phone,
                        partiya_id: data?.profile?.partiya_id,
                        degree_info_id: data?.profile?.degree_info_id,
                        academic_degree_id: data?.profile?.academic_degree_id,
                        degree_id: data?.profile?.degree_id,
                        diploma_type_id: data?.profile?.diploma_type_id,
                        role: data?.role,
                        sort: data?.sort,
                        status: data?.status,
                        username: data?.username,
                        passport_date: data?.profile?.passport_given_date && data?.profile?.passport_issued_date && [moment(data?.profile?.passport_given_date), moment(data?.profile?.passport_issued_date)],
                        passport_seria_and_number: data?.profile?.passport_seria && data?.profile?.passport_number && data?.profile?.passport_seria + ' ' + data?.profile?.passport_number
                    });
                    getRegionsP(data?.profile?.permanent_country_id);
                    getRegions(data?.profile?.country_id);
                    onChangeGetAreaP(data?.profile?.permanent_region_id);
                    onChangeGetArea(data?.profile?.region_id);
                    setLoading(false);

                } catch (e: any) {
                    setLoading(false)
                    asyncN('error', "read", e?.response?.data?.message)
                }
            }
        )()

    }, []);

    const getSubjects = async () => {
        try {
            const response = await getAllSubjects();

            setSubjects(response?.items);
        } catch (e: any) {
            asyncN("error", "read", e?.response?.message);
        }
    };

    const getTutors = async () => {
        const resp = await getAllUsers(`filter=${JSON.stringify({ "role_name": ["tutor"] })}&per-page=0`)
        setTutor(resp?.data?.items)
    }

    const getLangs = async () => {
        try {
            const response = await instance({
                method: "get",
                url: "/languages",
            });

            if (response?.data?.status === 1) {
                setLangs(response?.data?.data?.items);
                form.setFieldsValue({
                    languages: [response?.data?.data?.items[0]?.id],
                    subject: null,
                });
            }
        } catch (e: any) {
            asyncN("error", "read", e?.response?.message);
        }
    };


    const onChangeGetCountry = async () => {
        try {
            const response = await instance({
                method: "get",
                url: `/countries?sort=name&per-page=239`,
            });

            if (response?.data?.status === 1) {
                setCountries(response?.data?.data?.items);
                form.setFieldsValue({
                    country_id: 229,
                    permanent_country_id: 229
                })

            } else {
                asyncN("error", "read", response?.data?.message);
            }
        } catch (e: any) {
            asyncN("error", "read", e?.response?.message);
        }
    };

    const onFinish = async (values: any) => {
        let data = new FormData();
        for (const [key, value] of Object.entries<any>(values)) {
            if (value) {
                if (key === "role") {
                    data.append(key, JSON.stringify(value));
                } else if (key === "birthDay") {
                    data.append("birthday", moment(value._d).format(dateFormat));
                } else if (key === "subject") {
                    data.append("teacherAccess", JSON.stringify(teacher_access_list));
                } else if (key === "passport_file") {
                    data.append(key, value?.file?.originFileObj)
                } else if (key === "passport_date") {
                    data.append("passport_given_date", moment(value[0]).format(dateFormat));
                    data.append("passport_issued_date", moment(value[1]).format(dateFormat))
                } else if (key === "passport_seria_and_number") {
                    let strings = value.split(" ");
                    data.append("passport_seria", strings[0]);
                    data.append("passport_number", strings[1])
                } else {
                    data.append(key, value);
                }
            }
        }
        if (avatar) data.append("avatar", avatar);
        if (Object.keys(teacher_access_list).length > 0) {
            data.append("teacherAccess", JSON.stringify(teacher_access_list));
        }
        if (values?.role && Object.keys(userAccesses).length > 0) {
            data.append("user_access", JSON.stringify(userAccesses))
        }
        try {
            const r = await instance({
                method: 'put',
                url: '/users/' + id,
                data: data
            })

            if (r.data.status === 1) {
                message.success(r?.data?.message);
                history.push("/users")
            }
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    Object.entries(error?.response?.data?.errors[0]).map(([key, value]: any) => {
                        if (!errorResponseCheck) {
                            setErrorResponseCheck(true)
                            setActiveTabKey(_locationTabError[key])
                            form.setFields(ServerErrors(error?.response?.data?.errors[0]));
                        }
                    }
                    )

                }
            } else {
                asyncN("error", "update", error?.response ? error?.response?.data?.message : error?.message ? error?.message : "Error update user!")
            }
        }
    };

    const getRegions = async (id: number) => {

        const response: any = await getAllRegionsWithCountryId(id)
        setRegions(response?.items);
    };


    const getRegionsP = async (id: number) => {
        const response: any = await getAllRegionsWithCountryId(id)
        setRegionsP(response?.items);
    };
    useEffect(() => {
        onChangeGetCountry();
    }, []);

    const onChangeGetArea = async (e: any) => {
        try {
            const response = await instance({
                method: "get",
                url: `/areas?filter={"region_id":${e}}`,
            });

            if (response?.data?.status === 1) {
                setAreas(response?.data?.data?.items);

            } else {
                asyncN("error", "read", e?.response?.message);
            }
        } catch (e: any) {
            asyncN("error", "read", e?.response?.message);
        }
    };

    const onChangeGetAreaP = async (e: any) => {
        try {
            const response = await instance({
                method: "get",
                url: `/areas?filter={"region_id":${e}}`,
            });

            if (response?.data?.status === 1) {
                setAreasP(response?.data?.data?.items);

            } else {
                asyncN("error", "read", e?.response?.message);
            }
        } catch (e: any) {
            asyncN("error", "read", e?.response?.message);
        }
    };

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    const onChange = ({ fileList: newFileList }: any) => {


        if (Array.isArray(newFileList) && newFileList.length) {
            if (newFileList[0]?.size < 5242880) {
                setFileList([{
                    uid: "-1",
                    name: newFileList[0]?.originFileObj?.name,
                    status: "done",
                    url: window.URL.createObjectURL(newFileList[0]?.originFileObj),
                    thumbUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAllBMVEX///8Aark2icgAX7UAY7be6PMAYLXS5fKYudwAZ7gAZbfO3u76/f4AXLTt9fsGbLovgcSArddqoNHM2esAZbGlvt4AWp8pd7MfbakATIaKs9pDg8NalMsAcb2nxuO0z+cOfMMigsUAWp0AYam91eoddLhMjMjp8fgZZ6QMWZYAUZAvgL4RWpIlcasAc7oAV6V8pNJhmM2KPTNXAAAELElEQVR4nO3da3eaQBSFYYlIHLwba5oo0XptTWrj//9zxVy7ulCHwDn76NrvV13SpzPEEQErFcYYY4wxxhhjjLGLa92Q6KaGdr33MIqr5RdVW8kETXupPYpcINFgsPxhgdhOYhFfKpwvr6YGiKtQCBgMht+vrvCjOImkgMFglgrxxJXUHH0bQzhxLeb7EIL3xVpVXogdRRUhlKgjRE5UJSFwFLWEOKKaEEbUE6L2RUUhaBQ1hRiiqhAyUXWFCKKyEDBRtYX6RHWh+kTVF2qPIkCoTEQIdYkQoeq+iBFqjiJIqEhECfUmKkyoRsQJtSYqUKhERAp1JipUqDKKWKEGESxUIKKF8vsiXCg+inihNNGAUHiiWhDKjqIJoSjRhlCSaEQouC9aEcqNohmhGFFW+DOHUGqiygp/5REKEUWF881tLqLIRJUVzvINoghRUphO002uPVFkosoK57O7nMTyR1FU+EJ8esQSZYV74ubu6fetf4/bxVkJU+JwttncebcZ/qmflzAYDObz4XA29Gs2nJ+dcG/MVXh+wpxRSCGF+Cik8MvC+Oi1Ne7z4qnsy6ic99VVKGG8Wx97mWb9XRBnr5ybz75ElDBpn3ih8es1jG534PFmYlvoRqde6P51jOL+pQuDcFFpZmV9lvoLg6iTZOS8LwK0LywahRRSSCGF6b8wCjPyv22DeWHYq2XVWPkSrQvj1YHHvS+LNy98PvB4c+s5iNaFQefAPWkeLmZd6jrjrPxvTmFemE5Ul5Uv8ByEBaOQQgopPC10YZSRv9+8MB49ZFXvXMq61HUPbcj6mqbbPPFCvVPr0qXtden+MOjR2snbMe/pgf+KhvExTDfc/3akevI+Qm6a+YR7z80gv12Lsz66f/T5PHfqCWaFWlFIIYX4UEIXF834qi0Y94s2Nr2miRolbGrhdy9YiNBNS9mWFxA0hq6MTbUNj2HgVovCty9fjPx2RNSnp6j4DcxN/6XRjEIKKcQHW5cWz7YwXHaLtvU8kAFalz5knm+Yr7ofEbMu3ZayLctrmqSUbfl90Y0Rhr11u2jrvmVhEAadogWe5yrw/ZBCCvFRSOEXhXGrcFXTRzHifvF3/NrO8jt+p5RtceUNFAZRGbfj8DyHFvSXJtoW/gSceP6EHY9iSAn1opBCCvHx2zUZYTya3BRt0rW8polPnV3qk+VvuQ+e+JsvLyBqXVrGT4g3DI9h+vHpuVe0vteGgPc2OXr+rE++F6/x/ZBCCvFRSCGF+CikkEJ8FFJIIT4KKaQQH4UUUoiPQgopxEchhRTio5BCCvFRmLd2By36v2rJv39Y8bwKQq9OGWeY/ZvnmUpqRSVP0rSeqT0xHpU9hGk7Q8Rwe+pXl75Ur2pkX3StlQiwUrkeV6tZt8ZVrtWV+gX5fbVrfELjxxhjjDHGGGOMnXl/ASwK93DMPv2HAAAAAElFTkSuQmCC"
                }]);
            } else {
                message.error("Rasm hajmi 5Mb dan oshmasligi kerak !");
            }
        }
    };

    const onPreview = async (file: any) => {
        let src = file.url;
        let name: string = file?.name;

        if (name) {
            switch (name.substring(name.lastIndexOf(".") + 1, name.length)) {
                case "pdf": {
                    let a = document.createElement('a');
                    a.target = '_blank';
                    a.href = src;
                    a.click();
                    a.remove();
                }; break

                case "doc": {
                    let a = document.createElement('a');
                    a.target = '_blank';
                    a.href = src;
                    a.download = name;
                    a.click();
                    a.remove();
                }; break

                case "docx": {
                    let a = document.createElement('a');
                    a.target = '_blank';
                    a.href = src;
                    window.URL.revokeObjectURL(src);
                    a.click();
                    a.remove();
                }; break

                case "img": {
                    if (!src) {
                        src = await new Promise(resolve => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file.originFileObj);
                            reader.onload = () => resolve(reader.result);
                        });
                    }
                    const image = new Image();
                    image.src = src;
                    const imgWindow = window.open(src);
                    imgWindow?.document.write(image.outerHTML);
                }; break

                case "png": {
                    if (!src) {
                        src = await new Promise(resolve => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file.originFileObj);
                            reader.onload = () => resolve(reader.result);
                        });
                    }
                    const image = new Image();
                    image.src = src;
                    const imgWindow = window.open(src);
                    imgWindow?.document.write(image.outerHTML);
                }; break

                case "image": {
                    if (!src) {
                        src = await new Promise(resolve => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file.originFileObj);
                            reader.onload = () => resolve(reader.result);
                        });
                    }
                    const image = new Image();
                    image.src = src;
                    const imgWindow = window.open(src);
                    imgWindow?.document.write(image.outerHTML);
                }; break

                default: break
            }
        }
    };

    const imgUpload = () => (<Button disabled={state?.view}> <FaUpload /> &nbsp;&nbsp; {t("Upload")}</Button>);

    const getName = (type: string, id: number): string => {

        let name: string = ""

        if (type === "subject") {
            return name = subjects.filter((item: { id: number, name: string }) => {
                return item?.id === id
            })[0]?.name
        } else {
            return langs.filter((item: { id: number; name: string }) => Number(item?.id) === id)[0]
                ?.name;
        }

    };

    const subjectAdd = () => {

        if (form.getFieldValue("subject") !== null) {
            setTeacherAccessList((prevState) => ({
                ...prevState,
                [form.getFieldValue("subject")]: form.getFieldValue("languages"),
            }));
            form.setFieldsValue({
                subject: null,
                languages: [],
            });
        } else {
            message.warning("Fanni tanlang!");
        }
    };



    const deleteSubject = (key: number) => {
        let obj: any = teacher_access_list;
        delete obj[key];
        setTeacherAccessList(obj);
        setrefresh(!refresh);
    };

    const catchTabPageErrors = (tab: string | undefined) => {
        if (tab && tab !== activeTabKey) {
            form.validateFields()
                .then((resp: any) => {
                    let newArr: Array<any> = [];
                    let existingArr: Array<any> = [];
                    for (let key in fields) {
                        key as string;
                        existingArr.push(...fields[key])
                    }
                    for (let key in resp) {
                        key as any;
                        if (!existingArr.includes(key)) {
                            newArr.push(key);
                        }
                    }
                    if (activeTabKey) {
                        setFields({ ...fields, [activeTabKey]: newArr });
                    }
                    let a: Array<string> = []
                    if (errorTabs.length > 0 && activeTabKey) {
                        a = errorTabs?.filter((item: any) => {
                            return String(item) !== activeTabKey
                        });
                    }
                    if (form.getFieldValue("passport_pin") && form.getFieldValue("passport_pin").includes("_")) {
                        form.setFields([{
                            name: ["passport_pin"],
                            errors: ["Passport pin must be 14 characters long!"],
                            validating: false
                        }])
                    }
                    setErrorTabs(a);
                    setTabPageStatus('success')
                    setActiveTabKey(tab)
                })
                .catch(async (err: any) => {
                    setTabPageStatus('error')
                    let a = await counter(err?.errorFields);
                    if (a === 0) {
                        setErrorTabs(errorTabs?.filter((item: string) => item !== activeTabKey));
                        setActiveTabKey(tab)
                    } else {
                        if (!errorTabs.includes(activeTabKey)) {
                            setErrorTabs([
                                ...errorTabs, activeTabKey
                            ]);
                        }
                        setTabPageStatus('error')
                    }
                    if (form.getFieldValue("passport_pin") && form.getFieldValue("passport_pin").includes("_")) {
                        form.setFields([{
                            name: ["passport_pin"],
                            errors: ["Passport pin must be 14 characters long!"],
                            validating: false
                        }])
                    }
                })
        }
    }


    const counter = async (feilds: Array<{ errors: Array<string>, name: Array<string> }>) => {
        let a: Array<{ errors: Array<string>, name: Array<string> }> = feilds?.filter((item: { errors: Array<string>, name: Array<string> }) => {
            if (_locationTabError[item?.name[0]] === activeTabKey) {
                return item
            }
        })
        return a.length
    }
    const catchTabPageError = (tab: string | undefined) => {
        if (tab && tab !== activeTabKey) {
            form.validateFields()
                .then((resp: any) => {
                    let a: Array<string> = []
                    a = errorTabs?.filter((item: any) => {
                        return String(item) !== activeTabKey
                    });
                    setErrorTabs(a);
                    setTabPageStatus('success');
                    setActiveTabKey(tab);
                })
                .catch(async (err: any) => {
                    let a = await counter(err?.errorFields);
                    if (a === 0) {
                        setErrorTabs(errorTabs?.filter((item: string) => item !== activeTabKey));
                        setActiveTabKey(tab)
                    } else {
                        if (!errorTabs.includes(activeTabKey)) {
                            setErrorTabs([
                                ...errorTabs, activeTabKey
                            ]);
                        }
                        setActiveTabKey(tab);
                        setTabPageStatus('error')
                    }
                })
        }
    }

    const getUserAccessVariants = async (id: number, urlType?: string) => {
        if (id) {
            let url: string = urlType ? urlType : userAccessTypes?.filter((item: userAccessType) => item?.id === id)[0]?.url
            if (accessVariantsObj[`${id}`]?.length) {
                setUserAccessVariants(accessVariantsObj[`${id}`])
            } else {
                if (url !== undefined) {
                    try {
                        const response = await instance({
                            method: 'get',
                            url: url
                        })
                        if (response?.data?.status === 1) {
                            setUserAccessVariants(response?.data?.data?.items);
                            setAccessVariantsObj({
                                ...accessVariantsObj,
                                [`${id}`]: response?.data?.data?.items
                            })
                        } else {
                            asyncN("error", "read", response?.data?.message);
                            setUserAccessVariants([]);
                        }
                    } catch {
                        asyncN("error", "read", "error read " + url)
                        setUserAccessVariants([]);
                    }
                }
            }
        } else {
            asyncN("error", "read", "Url not found!!!");
        }
    }


    useEffect(() => {
        getUserAccessTypesList()
    }, [])

    const getUserAccessTypesList = async () => {
        const data: any = await getUserAccessTypes('expand=tableName');
        setUsetAccessTypes(data?.items)
        let a: any = accessVariantsObj
        await data?.items?.map(async (item: userAccessType) => {
            if (item?.url && !accessVariantsObj[`${item?.id}`]) {
                try {
                    const response = await instance({
                        method: 'get',
                        url: item?.url
                    })
                    if (response?.data?.status === 1) {
                        a = {
                            ...a,
                            [`${item?.id}`]: response?.data?.data?.items
                        }

                    } else {
                        asyncN("error", "read", response?.data?.message);
                    }
                } catch {
                    asyncN("error", "read", "error read " + item?.url)
                }
            }
        })
        setTimeout(() => {
            setAccessVariantsObj(a)
        }, 3000)

        setDisabled({
            ...disabled,
            user_access: false
        })
    }

    const setUserAccessesList = () => {
        let type_id: number = form?.getFieldValue("access_name");
        let variant_id: number = form?.getFieldValue("user_access");
        let is_leader: number = form?.getFieldValue("is_leader");
        if (type_id && variant_id) {
            if (userAccesses[`${type_id}`]) {
                if (!userAccesses[`${type_id}`]?.includes(`${variant_id}-${is_leader ? 1 : 0}`)) {
                    setUserAccesses({
                        ...userAccesses,
                        [`${type_id}`]: [...userAccesses[`${type_id}`], `${variant_id}-${is_leader ? 1 : 0}`]
                    })
                }
            } else {
                setUserAccesses({
                    ...userAccesses,
                    [`${type_id}`]: [`${variant_id}-${is_leader ? 1 : 0}`]
                })
            }
            form.setFieldsValue({
                user_access: null,
                is_leader: false,
                access_name: null
            })
        }
    }

    const deleteUserAccess = async (item: string, key: string) => {
        let b: any = userAccesses;
        if (b[`${key}`]) {
            setUserAccesses({
                ...userAccesses,
                [`${key}`]: userAccesses[`${key}`]?.filter((item1: string) => item !== item1)
            })
        }
    }

    return (
        <div style={{ height: "100%" }}>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <CustomBreadcrumb arr={_breadcrump} />
                </Col>
            </Row>
            <Form {...layout}
                form={form}
                layout="vertical"
                name="advanced_search"
                className="mt-3 text-start"
                style={{ height: "100%" }}
                onFinish={onFinish}>
                {loading ? (
                    <div className="loader-line"></div>
                ) : (
                    <div style={{ height: "3px" }}></div>
                )}
                <Tabs defaultActiveKey="1" activeKey={activeTabKey} onTabClick={(a) => catchTabPageError(a)}>
                    <TabPane tab={
                        <span style={tabPageStatus === 'error' && errorTabs.includes('1') ? { color: "#f5222d" } : {}}>
                            <FaRegAddressCard size={20} /> &nbsp;{t("Personal details")}
                        </span>
                    }
                        key="1">
                        <Row gutter={24}>
                            <Col span={24}>

                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} className="text-center mt-3">
                                <div>
                                    <UserAvatar upload={true} uploadedFile={(e: any) => {
                                        setAvatar(e?.file)
                                    }}
                                        fileSize={204800}
                                        fileType='image/png'
                                        avatar={avatar ? URL.createObjectURL(avatar) : avatarStr} size={130}
                                        text={firstName} />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={16}>
                                <Row className="d-flex justify-content-center" gutter={24}>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item name={`username`}
                                            label={t`Username`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("Fill in the field!"),
                                                },
                                            ]}>
                                            <Input onBlur={(e: any) => {
                                                let val: string = e?.target?.value;
                                                if (val.includes(" ")) {
                                                    form?.setFields([{
                                                        name: ["username"],
                                                        errors: ["Username (Login) maydonini to'ldirishda bo'shliq mavjud! so'zlarni ajratishda _ belgisidan foydalaning!"]
                                                    }])
                                                }
                                            }} placeholder={t("Enter username")} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item name={`first_name`}
                                            label={t`First name`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${t("Fill in the field!")}`,
                                                },
                                            ]}
                                            initialValue={firstName}
                                        >
                                            <Input onChange={(e) => {
                                                if (e.target.value.length > 0) {
                                                    setFirstName(e?.target?.value[0]?.toUpperCase() + e?.target?.value?.substring(1))
                                                }
                                            }} placeholder={t("Enter first name ... ")} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item name={`last_name`}
                                            label={t`Last name`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${t("Fill in the field!")}`,
                                                },
                                            ]}>
                                            <Input placeholder={t("Enter last name ... ")} />
                                        </Form.Item>
                                    </Col>
                                    <Col sm={24} xs={24} md={12} lg={12}>
                                        <Form.Item
                                            name={`middle_name`}
                                            label={t`Middle name`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${t("Fill in the field!")}`,
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t`Middle name`} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`birthDay`}
                                    label={t`Birth date`}
                                    initialValue={moment(date)}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}>
                                    <DatePicker placeholder={t("Select date")} defaultValue={moment(date)} format={dateFormat}
                                        style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`phone`}
                                    label={t`Phone number`}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}>
                                    <InputMask
                                        mask="+\9\98 (99) 999 99 99"
                                        className='ant-input'
                                        placeholder={t`Phone number`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`phone_secondary`}
                                    label={t`Second number`}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}>
                                    <InputMask
                                        mask="+\9\98 (99) 999 99 99"
                                        className='ant-input'
                                        placeholder={t`Second number`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`email`}
                                    label={t`Email`}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}>
                                    <Input type="email" placeholder={t`Email`} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`role`}
                                    label={t`Roles`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Fill in the field!"),
                                        },
                                    ]}>
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        placeholder={t`Role`}
                                        onChange={(e: any) => {
                                            if (e.includes("teacher")) {
                                                setRoleTeacher(true);
                                                getLangs();
                                                getSubjects();
                                            } else setRoleTeacher(false);
                                            if (e?.includes("student")) {
                                                setVisibleTutorField(true);
                                                getTutors();
                                            }
                                        }}
                                    >
                                        {roles &&
                                            roles.map((item: role, i: number) => (
                                                item?.name === "student" ?
                                                    auth?.user?.role?.some((item1: string) => ["admin", "dean", "deputy_dean"].includes(item1)) ?
                                                        <Option key={"" + i} value={item?.name}>
                                                            {item?.name}
                                                        </Option> : ""
                                                    : <Option key={"" + i} value={item?.name}>
                                                        {item?.name}
                                                    </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`gender`}
                                    label={t`Gender`}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Choose your gender!")}`,
                                        },
                                    ]}>
                                    <Select disabled={state?.view}
                                        placeholder={t("Select gender")}
                                    >
                                        {
                                            gender && gender.map((item: any, i: number) =>
                                                <Option key={"" + i} value={item.id}>{item.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`status`}
                                    label={t`Status`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Select status"),
                                        },
                                    ]}>
                                    <Select disabled={state?.view} placeholder={t("Select status")} >
                                        {
                                            userStatus.map((item: any, i: number) =>
                                                <Option key={"" + i} value={Number(item.key)}>{item.value}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`password`}
                                    label={t`Password`}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}>
                                    <Input.Password
                                        readOnly={state?.view}
                                        onChange={(e) => {
                                            setPass(e.target.value);
                                        }
                                        } autoComplete="new-password" placeholder={t`Password`} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item name={`rePassword`}
                                    label={t`Re-password`}
                                    validateStatus={errRePass ? "error" : ""}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}>
                                    <Input.Password onChange={(e) => {
                                        if (e.target.value === pass) {
                                            setErrRePass(false)
                                        } else {
                                            setErrRePass(true)
                                        }
                                    }} type={showPass ? "text" : "password"} placeholder={t`Re-password`} />
                                </Form.Item>
                            </Col>
                            {
                                visibleTutorField ? <Col sm={24} xs={24} md={12} lg={8}>
                                    <Form.Item
                                        name={"tutor_id"}
                                        label="Tyutorni tanlang"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select tutor!"
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                            placeholder="Tutor">
                                            {
                                                tutors?.length && tutors?.map((item: { last_name: string, first_name: string, id: number }, i: number) =>
                                                    <Option key={i} value={item?.id}>{item?.last_name + " " + item?.first_name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col> : ""
                            }
                        </Row>
                    </TabPane>
                    <TabPane tab={
                        <span style={tabPageStatus === 'error' && errorTabs.includes('2') ? { color: "#f5222d" } : {}}>
                            <FaIdCard size={18} />&nbsp;
                            &nbsp;{t("Passport details")}
                        </span>
                    }
                        key="2">
                        <Row gutter={24}>
                            <Col md={24}>
                                <h6 className="fw-normal">
                                    <strong>
                                        {t("Passport details")}
                                    </strong>
                                </h6>
                                <hr />
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={8}>
                                <Form.Item
                                    name={`passport_seria_and_number`}
                                    label={t`Passport seria and number`}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <InputMask mask="aa 9999999" placeholder={t("Passport seria and number")} className='ant-input' onChange={(e: ChangeEvent<HTMLInputElement>) => { form.setFieldsValue({ passport_seria_and_number: e.target.value?.toUpperCase() }) }} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={8}>
                                <Form.Item
                                    name={`passport_pin`}
                                    label={t`Passport JSHIR`}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <InputMask
                                        onBlur={() => {
                                            if (form.getFieldValue("passport_pin")) {
                                                if (form.getFieldValue("passport_pin").includes("_")) {
                                                    form.setFields([{
                                                        name: ["passport_pin"],
                                                        errors: ["Passport pin must be 14 characters long!"],
                                                        validating: false
                                                    }])
                                                } else {
                                                    form.setFields([{
                                                        name: ["passport_pin"],
                                                        errors: [""],
                                                        validating: true
                                                    }])
                                                }
                                            }
                                        }}
                                        readOnly={state?.view}
                                        mask="99999999999999"
                                        placeholder="_ _ _ _ _ _ _ _ _ _ _ _ _ _"
                                        className='ant-input'
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={24} lg={8}>
                                <Form.Item
                                    name={`passport_file`}
                                    label={t`Passport file`}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Upload
                                        style={{ width: "100%" }}
                                        customRequest={dummyRequest}
                                        fileList={fileList}
                                        accept={"application/pdf,,.docx,image/png,image/jpeg"}
                                        onChange={onChange}
                                        listType={"picture"}
                                        onPreview={onPreview}
                                        onRemove={() => setFileList([])}
                                    >
                                        {fileList.length < 1 && !state?.view && imgUpload()}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={8}>
                                <Form.Item
                                    name={`passport_given_by`}
                                    label={t`Passport given by`}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Input placeholder={t`Passport given by`} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={8}>
                                <Form.Item
                                    name={`passport_date`}
                                    label={t`Passport date`}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <RangePicker onCalendarChange={
                                        (e, a, d) => {
                                            if (d?.range === "start") {
                                                let start = new Date(a[0]);
                                                let end = new Date(a[0]);
                                                end.setFullYear(start.getFullYear() + 10)
                                                end.setDate(start.getDate() - 1)
                                                let mmnts = [moment(start), moment(end)]


                                                form.setFieldsValue({
                                                    passport_date: mmnts
                                                })

                                            }
                                            if (d?.range === "end") {
                                                let start = new Date(a[1]);
                                                let end = new Date(a[1]);
                                                start.setFullYear(end.getFullYear() - 10)
                                                start.setDate(end.getDate() + 1)
                                                let mmnts = [moment(start), moment(end)]


                                                form.setFieldsValue({
                                                    passport_date: mmnts
                                                })

                                            }

                                        }
                                    } disabled={state?.view} placeholder={[`${t("Given date")}`, `${t("Issued date")}`]} format={dateFormat}
                                        style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={
                        <span style={tabPageStatus === 'error' && errorTabs.includes('3') ? { color: "#f5222d" } : {}}>
                            <BiLocationPlus size={18} /> &nbsp;{t("Address details")}
                        </span>
                    }
                        key="3">
                        <Row gutter={24}>
                            <Col className="mt-4" sm={24} xs={24} lg={24} md={24}>
                                <h6 className="fw-normal">
                                    <strong>
                                        {t("Address details")}
                                    </strong>
                                </h6>
                                <hr />
                            </Col>
                            <Col sm={24} xs={24} md={8} lg={8}>
                                <Form.Item
                                    name={`country_id`}
                                    label={t("Country")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select a country"
                                        onChange={(e: number) => {
                                            getRegions(e);
                                            getRegionsP(e)
                                            form.setFieldsValue({
                                                area_id: null,
                                                region_id: null,
                                                address: ""
                                            })
                                            if (form?.getFieldValue("permanaent_country_id")?.length > 0) {
                                                setAddressCheck({ ...addressCheck, region: false })
                                            } else {
                                                setAddressCheck({
                                                    ...addressCheck, region: false, regionP: false
                                                })
                                                form.setFieldsValue({
                                                    permanent_country_id: e
                                                })
                                            }

                                        }
                                        }
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countries && countries?.map((value: any, index: number) => (
                                            <Option key={index} value={value.id}>
                                                {t(`${value.name}`)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={8} lg={8}>
                                <Form.Item
                                    name={`region_id`}
                                    label={t("Region")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={addressCheck?.region}
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder={t("Select a region")}
                                        onChange={(e: number) => {
                                            onChangeGetArea(e);
                                            onChangeGetAreaP(e);
                                            form.setFieldsValue({
                                                area_id: null
                                            })
                                            if (form.getFieldValue("permanent_region_id")) {
                                                setAddressCheck({ ...addressCheck, area: false });
                                            } else {
                                                setAddressCheck({ ...addressCheck, area: false, areaP: false });
                                                form.setFieldsValue({
                                                    permanent_region_id: e
                                                });
                                            }
                                        }
                                        }
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {regions && regions?.map((value: any, index: number) => (
                                            <Option key={index} value={value.id}>
                                                {value.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={8} lg={8}>
                                <Form.Item
                                    name={`area_id`}
                                    label={t("Area")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={addressCheck?.area}
                                        onChange={
                                            (e) => {

                                                if (form.getFieldValue("permanent_area_id")) {
                                                    setAddressCheck({
                                                        ...addressCheck,
                                                        address: false
                                                    })
                                                } else {
                                                    setAddressCheck({
                                                        ...addressCheck,
                                                        address: false,
                                                        addressP: false
                                                    })
                                                    form.setFieldsValue({
                                                        permanent_area_id: e
                                                    });
                                                }
                                            }
                                        }
                                        showSearch
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        placeholder={t("Select an area")}
                                        filterOption={(input: any, option: any) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {areas?.map((value: any, index: number) => (
                                            <Option key={index} value={value.id}>
                                                {value.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={12} xs={24} md={24} lg={24}>
                                <Form.Item
                                    name={`address`}
                                    label={t("Address")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Input.TextArea disabled={addressCheck?.address} placeholder={t("Current address")} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={8} lg={8}>
                                <Form.Item
                                    name={`permanent_country_id`}
                                    label={t("Permanent Country")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder={t("Select a country")}
                                        onChange={(e: number) => {
                                            getRegionsP(e)
                                            form.setFieldsValue({
                                                permanent_region_id: null,
                                                permanent_area_id: null,
                                                permanent_address: null
                                            })
                                        }
                                        }
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countries && countries?.map((value: any, index: number) => (
                                            <Option key={index} value={value.id}>
                                                {t(`${value.name}`)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={8}>
                                <Form.Item
                                    name={`permanent_region_id`}
                                    label={t("Permanent region")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        disabled={addressCheck?.regionP}
                                        style={{ width: "100%" }}
                                        placeholder={t("Select a region")}
                                        onChange={(e: number) => {
                                            onChangeGetAreaP(e);
                                            form.setFieldsValue({
                                                permanent_area_id: null,
                                                permanent_address: null
                                            })

                                        }}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {regionsP && regionsP?.map((value: any, index: number) => (
                                            <Option key={index} value={value.id}>
                                                {value.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={8}>
                                <Form.Item
                                    name={`permanent_area_id`}
                                    label={t("Permanent area")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={addressCheck?.areaP}
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder={t("Select an area")}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {areasP && areasP?.map((value: any, index: number) => (
                                            <Option key={index} value={value.id}>
                                                {value.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={12} xs={24} md={24} lg={24}>
                                <Form.Item
                                    name={`permanent_address`}
                                    label={t("Permanent address")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Input.TextArea disabled={addressCheck?.addressP} placeholder={t("Permanent address")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={
                        <span style={tabPageStatus === 'error' && errorTabs.includes('4') ? { color: "#f5222d" } : {}}>
                            <FaUserCog size={18} /> &nbsp;{t("Other details")}
                        </span>
                    }
                        key="4">
                        <Row gutter={24}>
                            <Col sm={24} xs={24} md={8} lg={6}>
                                <Form.Item
                                    name={`diploma_type_id`}
                                    label={t("Diploma type")}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select onFocus={() => {
                                        if (diplomaTypes?.length === 0) {
                                            _diplomaTypes();
                                        }
                                    }} placeholder={t("Select diploma type!")}>
                                        {
                                            diplomaTypes?.map((item: diplomaTypeType, i: number) =>
                                                <Option key={i} value={item?.id}>{
                                                    i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                                }</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={8} lg={6}>
                                <Form.Item
                                    name={`degree_id`}
                                    label={t("Degree")}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select onFocus={() => {
                                        if (degrees?.length === 0) {
                                            _degrees();
                                        }
                                    }} placeholder={t("Select degree!")}>
                                        {
                                            degrees?.map((item: degreeType, i: number) =>
                                                <Option key={i} value={item?.id}>{
                                                    i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                                }</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={8} lg={6}>
                                <Form.Item
                                    name={`academic_degree_id`}
                                    label={t("Academic degree")}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select onFocus={() => {
                                        if (academicDegrees?.length === 0) {
                                            _academicDegrees();
                                        }
                                    }} placeholder={t("Select academic degree!")}>
                                        {
                                            academicDegrees?.map((item: academicDegreeType, i: number) =>
                                                <Option key={i} value={item?.id}>{
                                                    i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                                }</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={8} lg={6}>
                                <Form.Item
                                    name={`degree_info_id`}
                                    label={t("Degree info")}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select onFocus={() => {
                                        if (degreeInfos?.length === 0) {
                                            _degreeInfos();
                                        }
                                    }} placeholder={t("Select degree info!")}>
                                        {
                                            degreeInfos?.map((item: degreeInfos, i: number) =>
                                                <Option key={i} value={item?.id}>{
                                                    i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                                }</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} xs={24} md={8} lg={6}>
                                <Form.Item
                                    name={`partiya_id`}
                                    label={t("Party")}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select onFocus={() => {
                                        if (partyas?.length === 0) {
                                            _partyas();
                                        }
                                    }} placeholder={t("Select party!")}>
                                        {
                                            partyas?.map((item: partyaType, i: number) =>
                                                <Option key={i} value={item?.id}>{
                                                    i18n.language == "uz" ? item?.name : i18n?.language === "ru" ? item?.name_ru : item?.name_en
                                                }</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <UserAccess form={form} setUserAccesses={setUserAccesses} userAccesses={userAccesses} />
                        <hr />
                        <TeacherAccess edit={true} setTeacherAccessList={setTeacherAccessList} teacher_access_list={teacher_access_list} form={form} />
                    </TabPane>
                </Tabs>
                <Row gutter={24} className="bottom-0">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button
                            style={{ margin: "0 8px" }}
                            onClick={() => {
                                form.resetFields();
                                form.setFieldsValue({
                                    country_id: 229,
                                    permanent_country_id: 229
                                })
                            }}
                            icon={<FaUndo className="me-1" />}
                        >
                            {t("Reset")}
                        </Button>
                        <Button
                            onClick={() => history.push("/users")}
                            type="primary"
                            danger
                            className="m-1"
                        >
                            {t("Cancel")}
                        </Button>
                        {Number(activeTabKey) > 1 && <Button
                            style={{ textAlign: "center" }}
                            disabled={errRePass}
                            onClick={() => {
                                if (Number(activeTabKey) > 1) {
                                    setActiveTabKey(String(Number(activeTabKey) - 1))
                                }
                            }}
                            type="primary"
                            className="m-1"
                            htmlType={"button"}
                        >
                            {t("Prev")}
                        </Button>}
                        <Button
                            style={{ textAlign: "center", display: Number(activeTabKey) < 4 ? "" : "none" }}
                            disabled={errRePass}
                            onClick={() => {
                                catchTabPageErrors(String(Number(activeTabKey) + 1))
                            }}
                            type="primary"
                            htmlType={"button"}
                        >
                            {t("Next")}
                        </Button>
                        <Button
                            style={{ textAlign: "center", display: !(Number(activeTabKey) < 4) ? "" : "none" }}
                            disabled={errorTabs?.length > 0}
                            type="primary"
                            onClick={() => {
                                if (errorTabs?.length > 0) {
                                    setActiveTabKey(errorTabs[0])
                                }
                            }}
                            htmlType={"submit"}
                        >
                            {t("Save")}
                        </Button>
                    </Col>
                </Row>

            </Form>
        </div >
    )
};

export default EditUser
