import { FC, useEffect, useState } from 'react';
import { Form, Modal, Input, Switch, Button, Col, Row, DatePicker } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getOneEduYear } from '../service';
import { useTranslation } from 'react-i18next';
import { ServerErrors } from 'utils/validationFunctions';
import moment from 'moment';

type EditEduYearType = {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setReRequest: (reRequest: boolean) => void,
    id: number | null,
    reRequest: boolean
}

type Values = {
    name: any,
    year: string,
    description: string,
    status: boolean,
}

const {RangePicker} = DatePicker;

const EditEduYear: FC<EditEduYearType> = ({ visible, setVisible, id, setReRequest, reRequest }) => {

    const { i18n, t } = useTranslation();
    const [form] = Form.useForm();
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true);
    const [year, setYear] = useState<number>();
    const [yearName, setYearName] = useState<Array<string>>();




    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOneEduYear(id, 'expand=description')

                        setYear(Number(response?.year))
                        setYearName(String(response?.name)?.split("-"))
                        form.setFieldsValue({
                            year: moment(response?.year),
                            name: [moment(String(response?.name)?.split("-")[0]), moment(String(response?.name)?.split("-")[1])],
                            description: response?.description,
                            status: Number(response.status) === 1 ? setStatus(true) : setStatus(false)
                        })
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible])


    const onSubmit = async (values: Values) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            formdata.append(`year`, String(moment(values?.year).format("YYYY")))
            formdata.append(`name[${i18n.language}]`, String( new Date(String(moment(values?.name[0]).format("YYYY"))).getFullYear() + "-" + new Date(String(moment(values?.name[1]).format("YYYY"))).getFullYear()))
            formdata.append(`description[${i18n.language}]`, values?.description)
            formdata.append('status', status ? '1' : '0');

            const response = await instance({ url: `/edu-years/${id}`, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)

                    })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => {
                        setVisible(false);
                        resetFields()
                        setReRequest(!reRequest)
                    })
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            setClicked(false)
            setReRequest(!reRequest)
            asyncN("error", 'update', error?.response?.data?.errors[0])
                .then(() => {
                    setVisible(false);
                    resetFields()
                })
        }
    }

    const resetFields = () => form.resetFields();


    return (
        <>
            <Modal
                title={t("Edu year edit")}
                visible={visible}
                onCancel={() => { setVisible(false); resetFields() }}
                okText="Submit"
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Row gutter={24}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Year")}
                        name="year"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <DatePicker placeholder={t("Select year")} format="YYYY" picker="year" style={{ width: '100%', }} />
                    </Form.Item>
                    </Col>
                    <Col xl={{ span: 16 }} lg={{ span: 16 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Name")}
                        name="name"
                        shouldUpdate
                        rules={[{ required: true, message: t("Fill in the field!") }]}
                    >
                        <RangePicker placeholder={[t("Start date"), t("End date")]} format="YYYY" picker="year" style={{ width: '100%', }} />
                    </Form.Item>
                    </Col>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Description")}
                        name="description"
                        shouldUpdate
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                    >
                        <Input placeholder={t("Enter description for edu year")+" ..."} />
                    </Form.Item>
                    </Col>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                    <Form.Item
                        label={t("Status")}
                        name="status"
                    >
                        <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                    </Form.Item>
                    </Col>
                    <hr />
                    <Col span={24} className="text-end mt-2">
                        <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}


export default EditEduYear;