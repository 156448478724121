import { Button, Col, Modal, Row, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import BadgeStatus from 'components/BadgeStatus';
import { FILE_URL } from 'config/utils';
import { asyncN } from 'utils/notifications';
import { getAllLanguages } from 'services';
import { getAllQuestionOptions } from '../servise';
import { optionType, questionType } from './types';
import { useTranslation } from 'react-i18next';


const ViewQuestion: FC<{ visiblity: boolean, setVisiblity: (visblity: boolean) => void, item: questionType['items'][0] }> = ({ visiblity, setVisiblity, item }): JSX.Element => {

    const [options, setOptions] = useState<Array<optionType>>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [langs, setLangs] = useState<Array<{ id: number, name: string }>>()
    const history=useHistory();
    const {t} = useTranslation();


    useEffect(() => {
        (
            async () => {
                if (item?.questionType?.name === "TEST") {
                    setIsLoading(true)
                    try {
                        const response = await getAllQuestionOptions(item?.id);
                        setOptions(response?.items);
                        setIsLoading(false)
                    } catch (e: any) {
                        asyncN("error", "read", "Error for get question options!!!")
                        setIsLoading(false)
                    }
                }
            }
        )()
    }, [item?.id])

    useEffect(() => {
        (
            async () => {
                try {
                    const resp = await getAllLanguages();
                    setLangs(resp?.items)
                } catch (e: any) {
                    asyncN("error", "read", "Error for get languages!!!")
                }
            }
        )()
    }, [])

    const getLangName = (id: number): string => {
        let a = ""
        if (Array.isArray(langs)) {
            a = langs?.filter((item: { id: number, name: string }) => {
                return item?.id === id && item
            })[0]?.name
        }
        return a
    }
    return (
        <Modal title={t("View question")} width={800} footer={false} visible={visiblity} onCancel={() => setVisiblity(false)}>
            <Row gutter={24} style={{
                height: "60vh",
                overflow: "auto"
            }}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <p className="d-flex flex-column">
                        <strong>{t("Question")}: </strong><span dangerouslySetInnerHTML={{ __html: item?.question }}></span>
                    </p>
                </Col>
                {item?.questionType?.name === "TEST" && <Col span={24}>
                    {isLoading ? (
                        <div className="loader-line"></div>
                    ) : (
                        <div style={{ height: "3px" }}></div>
                    )}
                    {options?.length>0&&<Col className='pb-2' span={24}>
                        <strong>{t("Options")}:</strong>
                    </Col>}
                    {options?.length>0&&<Table className="table table-bordred table-striped">
                        <Thead>
                            <Tr>
                                <Th>No.</Th>
                                <Th>{t("Option")}</Th>
                                <Th>{t("Is correct")}</Th>
                                <Th>{t("File")}</Th>
                                <Th>{t("Status")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                options && options?.map((item1: optionType, i: number) =>
                                    <Tr key={i}>
                                        <Th>{i + 1}</Th>
                                        <Th><div dangerouslySetInnerHTML={{ __html: item1?.option }} style={{ fontWeight: 300 }}></div></Th>
                                        <Th><Switch size='small' checked={item1?.is_correct === 1} /></Th>
                                        <Th>{
                                            item1?.file ? <Button type='link' onClick={() => {
                                                let a = document.createElement('a');
                                                a.target = '_blank';
                                                a.href = FILE_URL + item1?.file;
                                                a.click();
                                                a.remove();
                                            }}>{t("Download")}</Button> :
                                                <Button type='link'>🙄</Button>
                                        }</Th>
                                        <Th><BadgeStatus status={item1?.status} /></Th>
                                    </Tr>
                                )
                            }
                        </Tbody>
                    </Table>}
                </Col>}
                <Col xs={24} sm={24} md={8} lg={6}>
                    <p className="d-flex flex-column">
                        <strong>{t("Ball")}: </strong><span>{item?.ball}</span>
                    </p>
                </Col>
                <Col xs={24} sm={24} md={8} lg={6}>
                    <p className="d-flex flex-column">
                        <strong>{t("Level")}: </strong><span>{item?.level}</span>
                    </p>
                </Col>
                <Col xs={24} sm={24} md={8} lg={6}>
                    <p className="d-flex flex-column">
                        <strong>{t("Language")}: </strong><span>{getLangName(item?.lang_id)}</span>
                    </p>
                </Col>
                <Col xs={24} sm={24} md={8} lg={6}>
                    <p className="d-flex flex-column">
                        <strong>{t("Question type")}: </strong><span>{item?.questionType?.name}</span>
                    </p>
                </Col>

                {item?.question_file&&<Col xs={24} sm={24} md={8} lg={6}>
                    <p className="d-flex flex-column">
                        <strong>{t("Question file")}: </strong><a target={'_blank'} href={FILE_URL + item?.question_file} className='p-0 m-0'>Download</a>
                    </p>
                </Col>}
                <Col xs={24} sm={24} md={8} lg={6}>
                    <p className="d-flex flex-column">
                        <strong>{t("Semestr")}: </strong><span>{item?.semestr?.name}</span>
                    </p>
                </Col>
                <Col xs={24} sm={24} md={8} lg={6}>
                    <p className="d-flex flex-column">
                        <strong>{t("Course")}: </strong><span>{item?.course?.name}</span>
                    </p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <p className="d-flex flex-column">
                        <strong>{t("Subject")}: </strong><span>{item?.subject?.name}</span>
                    </p>
                </Col>
                <Col span={24}>
                    <hr />
                    <Row gutter={24} className='bordered'>
                        {item?.createdBy && <Col xs={24} sm={24} md={24} lg={24}>
                            <strong>{t("Created by")}: </strong>
                            <Row className='pt-3' gutter={24}>
                                <Col xs={24} sm={24} md={8} lg={6}>
                                    <p className="d-flex flex-column">
                                        <strong>{t("Full name")}: </strong><span>{item?.createdBy?.first_name}</span>
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6}>
                                    <p className="d-flex flex-column">
                                        <strong>{t("Last name")}: </strong><span>{item?.createdBy?.last_name}</span>
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6}>
                                    <p className="d-flex flex-column">
                                        <strong>{t("Username")}: </strong><span>{item?.createdBy?.username}</span>
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6}>
                                    <p className="d-flex flex-column">
                                        <strong>{t("Email")}: </strong><span>{item?.createdBy?.email}</span>
                                    </p>
                                </Col>
                            </Row>
                        </Col>}
                        {item?.updatedBy && <Col xs={24} sm={24} md={24} lg={24}>
                            <hr />
                            <strong>{t("Updated by")}: </strong>
                            <Row className='pt-3' gutter={24}>
                            <Col xs={24} sm={24} md={8} lg={6}>
                                    <p className="d-flex flex-column">
                                        <strong>{t("Full name")}: </strong><span>{item?.updatedBy?.first_name}</span>
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6}>
                                    <p className="d-flex flex-column">
                                        <strong>{t("Last name")}: </strong><span>{item?.updatedBy?.last_name}</span>
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6}>
                                    <p className="d-flex flex-column">
                                        <strong>{t("Username")}: </strong><span>{item?.updatedBy?.username}</span>
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={6}>
                                    <p className="d-flex flex-column">
                                        <strong>{t("Email")}: </strong><span>{item?.updatedBy?.email}</span>
                                    </p>
                                </Col>
                            </Row>
                        </Col>}
                    </Row>
                </Col>
            </Row>
            <Row className='pt-3' gutter={24}>
                <Col span={24}>
                    <Button onClick={() => {
                        history.push({
                            pathname: "/edit-questions",
                            state: {
                                id: item?.id
                            }
                        })
                    }} style={{ float: "right" }} type='primary'><FaEdit /></Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default ViewQuestion