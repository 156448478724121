import { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Col, Form, Row, message } from 'antd';
import CustomBreadcrumb from "components/Breadcrumb";
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import moment from 'moment';
import { page_errors } from './utils';
import { Card, CardBody } from 'reactstrap';
import StudentNewUi from './new_ui';
import UserMainInfo from './froms/main_info';
import CardContainer from 'components/Widgets/CardContainer';

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Students",
        path: 'student_index'
    },
    {
        name: "Student create",
        path: 'student_create'
    },
]  

const CreateStudent: FC = (): JSX.Element => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [clicked, setClicked] = useState<boolean>(false)

    const ref: any = useRef();

    const onSubmit = async (values: any) => {
        try {

            const { phone, passport_seria_and_number, passport_pin, phone_secondary, parent_phone, res_person_phone } = values;

            const isPhoneSecondary = phone_secondary && String(phone_secondary).includes('_');
            const isParentPhone = parent_phone && String(parent_phone).includes('_');
            const isResPersonPhone = res_person_phone && String(res_person_phone).includes('_');
            const isPhoneNumber = String(phone).includes('_');
            const isPassport = String(passport_seria_and_number).includes('_');
            const isPassportPin = String(passport_pin).includes('_')

            if (!isPhoneNumber && !isPassport && !isPassportPin && !isPhoneSecondary && !isParentPhone && !isResPersonPhone) {
                setClicked(true);
                const formdata = new FormData();
                Object.entries(values).forEach(([key, value]: any) => {
                    if (value) {
                        if (key === "birthday") {
                            formdata.append(key, moment(value).format("YYYY-MM-DD"));
                        } else if (['avatar', 'passport_file'].includes(key)) {
                            formdata.append(key, value.file.originFileObj)
                        } else if (key === 'passport_date') {
                            formdata.append("passport_given_date", moment(value[0]).format("YYYY-MM-DD"))
                            formdata.append("passport_issued_date", moment(value[1]).format("YYYY-MM-DD"))
                        } else if (key === 'passport_seria_and_number') {
                            formdata.append("passport_seria", value.split(" ")[0])
                            formdata.append("passport_number", value.split(" ")[1])
                        } else {
                            formdata.append(key, String(value));
                        }
                    }
                });
                const response = await instance({ url: '/students', method: 'POST', data: formdata })

                if (response.data.status === 1) {
                    asyncN("success", 'create', response.data?.message).then(() => { resetFields(); history.goBack() })
                } else {
                    asyncN("error", 'update', response.data?.message).then(() => resetFields())
                }
            } else {
                message.error(`${isPhoneNumber ? "Telefon raqam " : ''}${isPassport ? "Passport seriya va raqam " : ''}${isPassportPin ? "Passport JSHSHIR, " : ''}${isPhoneSecondary ? "Qo'shimcha telefon raqam, " : ''}${isParentPhone ? "Ota onasi telefon raqam, " : ''}${isResPersonPhone ? "Ma'sul shaxs telefon raqam " : ''} ma'lumotida format xatoligi !`, 5);
            }


            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    const _errors = error?.response?.data?.errors[0];
                    let active_field = undefined;
                    for (let key in _errors) {
                        active_field = key;
                        break
                    }
                    for (let i = 0; i < page_errors.length; i++) {
                        const element = page_errors[i];
                        if (active_field && element.fields.includes(active_field)) {
                            ref.current?.catchErrorFromBackend(element.tab);
                        }
                    }
                    form.setFields(ServerErrors(_errors));
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)

        }
    }

    const resetFields = () => form.resetFields();

    return (
        <>
            <div className="bg-light p_12 rounded rounded-3">
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <Card style={{ position: "relative", zIndex: 100 }} >
                            <CardBody className='p-2'>
                                <CustomBreadcrumb arr={_breadcrump} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={10} lg={24} md={24} sm={24} xs={24}>
                        <UserMainInfo />
                    </Col>
                    <Col xl={14} lg={24} md={24} sm={24} xs={24}>
                        <CardContainer>
                            <StudentNewUi form={form} onSubmit={onSubmit} />
                        </CardContainer>
                    </Col>
                </Row>
            </div >
            {/* <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                    initialValues={{
                        status: '10',
                        is_contract: '1',
                        country_id: 229,
                        permanent_country_id: 229,
                        citizenship_id: 1,
                        nationality_id: 1,
                    }}
                    className='custom_form_style'
                >
                    <StudentUi
                        resetFields={resetFields}
                        clicked={clicked}
                        fileList={fileList}
                        setFileList={setFileList}
                        filePassport={filePassport}
                        setFilePassport={setFilePassport}
                        form={form}
                        is="create"
                        ref={ref}
                        getStudentByPinfl={getStudentByPinfl}
                    />
                </Form> */}
        </>
    )
}


export default CreateStudent;