import instance from "config/_axios";
import {message} from "antd";
import { asyncN } from "utils/notifications";


export const getAllDepartments=async (path_prefix?:string,perPage?:number,currentPage?:number)=>{

    try{
        const response= await instance({
            url:`/departments${path_prefix?'?'+path_prefix:''}`,
            params:{
                "per-page": perPage,
                page: currentPage
            },
            method:'get'
        });

        if (response?.data?.status===1){
            return response?.data?.data;
        }else {
            return new Error(response?.data?.message)
        }
    }catch (e:any) {
        message.error(e?.response?.message);
    }

};

export const getOneDepartment=async (id?:number|null,path_prefix?:string)=>{
    try {
        if (!id){
            return new Error("Id bo'yicha ma'lumot olinmadi !")
        }
        const response = await instance({
            url:'/departments/'+id+'?'+path_prefix,
            method:'get'
        })
        if (response?.data?.status===1){
            return response?.data?.data;
        }else {
            return new Error(response?.data?.message)
        }
    }catch (e:any) {
        message.error(e?.response?.data?.message);
    }
}

export const getDepartmentTypes=async ()=>{
    try{
        const response =await instance({
            url:'/departments/types',
            method:'GET'
        })
        if(response?.status===200){
            return response?.data
        }else{
            asyncN("error","read","Error read department types!!!")
        }
    }catch(e){
        asyncN("error","read","Error read department types!!!");
    }
}