import { Popover } from "antd";
import { FC, useState } from "react"
import styled from "styled-components"
import './style.scss'

const DescriptionStyle: FC<{ children: any, col?: number | undefined, event?: "click" | "hover", wrapper?: string | undefined }> = ({ children, col, event, wrapper }) => {

  const [width, setWidth] = useState<number>()
  // const [childHeight, setChildHeight] = useState<number>();
  // const [parentHeight, setParentHeight] = useState<number>();

  const handleEvent = (e: any) => {
    setWidth(width || e.target.clientWidth);
    // setChildHeight(e.target.clientHeight);
    // setParentHeight(e.nativeEvent.srcElement.ownerDocument.scrollingElement.scrollHeight)
  }

  // console.log("width: ", width, " childHeight: ", childHeight, " ParentHeight: ", parentHeight);

  return (
    <Popover content={<span style={{ width: `${width}px` ?? '500px', display: 'block' }} dangerouslySetInnerHTML={{__html: children}}></span>} trigger={event ?? "click"} >
      <Wrapper onClick={handleEvent} onMouseEnter={handleEvent} col={col ?? 2} width={width ?? 500}>{wrapper}</Wrapper>
    </Popover>
  )
}
export default DescriptionStyle;

// console.log("text", e.nativeEvent.srcElement.ownerDocument.scrollingElement.scrollHeight)

const Wrapper = styled.div<{ col: number, width: number }>`
  -webkit-line-clamp: ${p => p.col};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* text-align: justify; */
  cursor: pointer;
`;