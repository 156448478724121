/** @format */

export const personal_data_fields = [
  "avatar",
  "first_name",
  "last_name",
  "middle_name",
  "phone",
  "phone_secondary",
  "birthday",
  "status",
  "gender",
  "description",
  "passport_seria_and_number",
  "passport_pin",
  "passport_given_by",
  "passport_date",
  "citizenship_id",
  "nationality_id",
];
export const education_data_fields = [
  "diplom_seria",
  "diplom_number",
  "is_contract",
  "course_id",
  "faculty_id",
  "direction_id",
  "edu_year_id",
  "edu_plan_id",
  "edu_type_id",
  "edu_form_id",
  "edu_lang_id",
];
export const passport_data_fields = [
  "permanent_country_id",
  "permanent_region_id",
  "permanent_area_id",
  "permanent_address",
  "country_id",
  "region_id",
  "area_id",
  "address",
];
export const additional_data_fields = [
  "partners_count",
  "category_of_cohabitant_id",
  "residence_status_id",
  "live_location",
  "email",
  "parent_phone",
  "res_person_phone",
  "social_category_id",
  "student_category_id",
  "last_education",
];

export const page_errors = [
  {
    tab: "1",
    fields: personal_data_fields,
  },
  {
    tab: "2",
    fields: education_data_fields,
  },
  {
    tab: "3",
    fields: passport_data_fields,
  },
  {
    tab: "4",
    fields: additional_data_fields,
  },
];


// -------------------------------------------------------------------------

export const cu_personal_data_fields = [
  "avatar",
  "first_name",
  "last_name",
  "middle_name",
  "phone",
  "phone_secondary",
  "birthday",
  "status",
  "gender",
  "description",
  "passport_seria_and_number",
  "passport_pin",
  "passport_given_by",
  "passport_date",
  "citizenship_id",
  "nationality_id",
  "diplom_seria",
  "diplom_number",
  "diplom_file",
  "email",
];
export const cu_education_fields = [
  "is_contract",
  "course_id",
  "faculty_id",
  "direction_id",
  "edu_year_id",
  "edu_plan_id",
  "edu_type_id",
  "edu_form_id",
  "edu_lang_id",
  "student_category_id",
  "tutor_id",
];

export const cu_additional_fields = [
  "email",
  "phone_secondary",
  "country_id",
  "region_id",
  "area_id",
  "address",
  "diplom_seria",
  "diplom_number",
  "diplom_file",
  "last_education",
  "social_category_id",
  "partners_count",
  "category_of_cohabitant_id",
  "residence_status_id",
  "live_location",
];
export const cu_parent_info_fields = [
  "father_name",
  "phone_secondary",
  "father_info",
  "mather_name",
  "mather_phone",
  "mather_info",
];

export const studentTabFields: Record<number, string[]> = {
  1: cu_education_fields,
  2: cu_additional_fields,
  3: cu_parent_info_fields,
  5: [
    'contract_id', 'contract_number'
  ]
};

export const checkStatusState = (status: number) => {
  switch (status) {
    case 10:
      return {
        color: "#73d13d",
        text: "active",
      };
    case 5:
      return {
        color: "#ffe58f",
        text: "banned",
      };
    default:
      return {
        color: "#ff7875",
        text: "pending",
      };
  }
};

/*

from hemis
*/

export const comparingStudentData = [
  {
    title: "Shaxsni tasdiqlovchi hujjat JSHSHIR",
    field1: "pinfl",
    field2: "passport_pin",
  },
  {
    title: "Shaxsni tasdiqlovchi hujjat seriyasi va raqami",
    field1: "serial_number",
    field2: "",
  },
  {
    title: "Talaba ismi",
    field1: "firstname",
    field2: "first_name",
  },
  {
    title: "Talaba familiyasi",
    field1: "lastname",
    field2: "last_name",
  },
  {
    title: "Talaba otasining ismi",
    field1: "fathername",
    field2: "middle_name",
  },
  {
    title: "Tug'ilgan sana",
    field1: "birthday",
    field2: "birthday",
  },
  {
    title: "Jinsi",
    field1: "gender_name",
    field2: "gender",
  },
  {
    title: "Millati",
    field1: "nationality_name",
    field2: "nationality_id",
  },
  {
    title: "Fuqaroligi",
    field1: "citizenship_name",
    field2: "citizenship",
  },
  {
    title: "Ta'lim turi",
    field1: "education_type_name",
    field2: "edu_type_id",
  },
  {
    title: "Ta'lim shakli",
    field1: "education_form_name",
    field2: "edu_form_id",
  },
  {
    title: "To'lov shakli",
    field1: "payment_type_name",
    field2: "is_contract",
  },
  {
    title: "Texnikum",
    field1: "faculty_name",
    field2: "faculty_id",
  },
  {
    title: "Yo'nalish",
    field1: "speciality_name",
    field2: "direction_id",
  },
  {
    title: "Kursi",
    field1: "course",
    field2: "course_id",
  },
  {
    title: "Mamlakat",
    field1: "country_name",
    field2: "country_id",
  },
  {
    title: "Viloyat",
    field1: "region",
    field2: "region_id",
  },
  {
    title: "Tuman",
    field1: "district",
    field2: "area_id",
  },
  {
    title: "Doimiy manzil",
    field1: "address",
    field2: "permanent_address",
  },
  {
    title: "Vaqincha manzil",
    field1: "address_current",
    field2: "address",
  },
  {
    title: "",
    field1: "accomodation_name",
    field2: "",
  },
  {
    title: "Birga yashaydiganlar toifasi",
    field1: "social_category_name",
    field2: "social_category_id",
  },
  {
    title: "Ta'lim tili",
    field1: "education_language_name",
    field2: "edu_lang_id",
  },
  {
    title: "Holat",
    field1: "status_name",
    field2: "status",
  },
];