import React, { FC, useState } from "react";
import { Row, Col, Typography, Spin, Empty, Collapse, Divider, Tag, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import useGetOneData, { ReturnTypeUseGetOneData } from "hooks/useGetOneData";
import { useRouteMatch, match } from 'react-router';
import styles from './styles.module.scss'
import { Card, CardBody } from "reactstrap";
import './style.scss'
import CustomBreadcrumb from "components/Breadcrumb";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import isHasAuthRoute from "utils/isHasRoute";
import { NavLink } from "react-router-dom";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { BiBookOpen, BiUserVoice } from "react-icons/bi";
import { FiInfo, FiKey } from "react-icons/fi";
import useDebounce from "hooks/useDebounce";
import { FaFileExcel, FaRegBuilding } from "react-icons/fa";
import { cf_filterOption } from "utils/auxiliary_functions";
// import { IEducationSemesterSubject } from "models/education";
import { IStudentTimeTable, ITimeOptions } from "models/others";
import { ISubject } from "models/subject";
// import TimeTableByChild from "pages/time_table/pages/GroupTimeTable";
import TableTh from "components/Table/TableTh";
import { excelExport } from "utils/excelExport";

const { Text } = Typography;

const _breadcrump = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Time table variants",
    path: "time_options",
  },
  {
    name: "Time table variant view",
    path: "time_options_view",
  },
];

const { Option } = Select;

const TimeOptionView: FC = (): JSX.Element => {

  const { t } = useTranslation();
  const match: match<any> | null = useRouteMatch("/time_option_view/:id");
  const [currentId, setCurrentId] = useState<number | undefined>();
  const [searchText, setSearchText] = useState<string>('');
  const [subjectId, setSubjectId] = useState<number | undefined>(undefined);
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [timeTableId, setTimeTableId] = React.useState<undefined | number>(undefined);
  const [timeTables, setTimeTables] = React.useState<Array<{ id: number, name: string }>>([]);


  const student_time_tables = useGetAllData({ url: `/student-time-tables?expand=student.profile&filter=${JSON.stringify({ time_table_id: timeTableId })}`, perPage: 0 }) as TypeReturnUseGetAllData<IStudentTimeTable>

  const debouncedSearch = useDebounce<string>(searchText, 500)

  const { data, loading } = useGetOneData({
    url: `time-options/${match?.params?.id}?fields=*,studentTimeOption.student.id,studentTimeOption.student.profile.last_name,studentTimeOption.student.profile.first_name,studentTimeOption.student.profile.middle_name&expand=createdBy,updatedBy,studentTimeOption.student.profile,selected,selectedCount,faculty,language,eduYear,eduSemester.course,eduSemester.semestr,timeTables.week,timeTables.subject,timeTables.para,timeTables.subjectCategory,timeTables.teacher,timeTables.room,timeTables.building,timeTables.language,timeTables.child.week,timeTables.child.para,timeTables.child.room,timeTables.child.building,timeTables.child.subjectCategory,timeTables.child.subject,timeTables.child.language,timeTables.child.teacher,timeTables.seminar.week,timeTables.seminar.room,timeTables.seminar.building,timeTables.seminar.subjectCategory,timeTables.seminar.subject,timeTables.seminar.language,timeTables.seminar.teacher,timeTables.seminar.para,timeTables.seminar.selected,timeTables.seminar.selectedCount,timeTables.seminar.child.week,timeTables.seminar.child.room,timeTables.seminar.child.building,timeTables.seminar.child.language,timeTables.seminar.child.subjectCategory,timeTables.seminar.child.subject,timeTables.seminar.child.teacher,timeTables.seminar.child.para`,
    isCall: "auto",
  }) as ReturnTypeUseGetOneData<ITimeOptions>


  const weeks = useGetAllData({ url: `/weeks`, isCall: "auto" });
  const para = useGetAllData({ url: `/paras`, isCall: "auto" });


  const filterTimeTable = React.useCallback((para_id: number, week_id: number) => {
    if (data.timeTables?.length) {

      let filteredTimeTables: Array<any> = [];


      data.timeTables.forEach((element: any) => {
        if (element.week_id === week_id && element.para_id === para_id) {
          filteredTimeTables.push(element)
        }
        element.child?.forEach((item: any) => {
          if (item.week_id === week_id && item.para_id === para_id) {
            filteredTimeTables.push(item)
          }
        })

        element.seminar?.forEach((item: any) => {
          if (item.week_id === week_id && item.para_id === para_id) {
            filteredTimeTables.push(item)
          }

          item.child?.forEach((e: any) => {
            if (e.week_id === week_id && e.para_id === para_id) {
              filteredTimeTables.push(e)
            }
          })
        })

      });

      return filteredTimeTables;

    }
  }, [data])

  const getSubjects = () => {
    if (!subjects.length && data.timeTables.length) {
      let new_arr: ISubject[] = [];

      data.timeTables.forEach((element) => {
        const findElement = new_arr.find(e => e.id === element.subject_id);
        if (!findElement && element.subject) {
          new_arr.push(element.subject);
        }
      })

      setSubjects(new_arr);
    }
  }


  const filterTimeTableBySubject = (subject_id: number) => {
    const findElement = data.timeTables.find(element => element.subject_id === subject_id);

    let new_arr: Array<{ id: number, name: string }> = [];

    if (findElement) {

      if (findElement.subject_category_id === 1) {
        new_arr.push({
          id: findElement.id,
          name: `${findElement.week?.name} / ${findElement.subjectCategory?.name} / ${findElement.para?.start_time}-${findElement.para?.end_time} / ${findElement.teacher?.middle_name} ${findElement.teacher?.first_name} ${findElement.teacher?.first_name}`
        })
        findElement.seminar.forEach((element) => {
          new_arr.push({
            id: element.id,
            name: `${element.week?.name} / ${element.subjectCategory?.name} / ${element.para?.start_time}-${element.para?.end_time} / ${element.teacher?.last_name} ${element.teacher?.first_name} ${element.teacher?.middle_name}`
          })
        })
      } else {
        const filteredTimeTables = data.timeTables.filter(e => e.subject_category_id !== 1 && e.subject_id === subject_id);
        if (filteredTimeTables.length) {
          filteredTimeTables.forEach((element) => {
            new_arr.push({
              id: element.id,
              name: `${element.week?.name} / ${element.subjectCategory?.name} / ${element.para?.start_time}-${element.para?.end_time} / ${element.teacher?.last_name} ${element.teacher?.first_name} ${element.teacher?.middle_name}`
            })
          })
        }

      }

      setTimeTables(new_arr);

    }


  }


  React.useEffect(() => {
    if (timeTableId) {
      student_time_tables.fetch();
    }
  }, [timeTableId])


  const filterStudentList = (e: any) => {
    return (
      !!String(e?.student?.profile?.last_name).toUpperCase().includes(String(debouncedSearch).toUpperCase()) ||
      !!String(e?.student?.profile?.first_name).toUpperCase().includes(String(debouncedSearch).toUpperCase()) ||
      !!String(e?.student?.profile?.middle_name).toUpperCase().includes(String(debouncedSearch).toUpperCase())
    )
  }

  const exportExcel = () => {
    let new_arr = []
    if (timeTableId) {
      new_arr = student_time_tables.items.map((element) => {
        return {
          [`${data?.faculty?.name} VARIANT-${data?.name} F.I.O`]: `${element.student.profile?.last_name} ${element.student.profile?.first_name} ${element.student.profile?.middle_name}`
        }
      })
    } else {
      new_arr = data?.studentTimeOption?.map((element) => {
        return {
          [`${data?.faculty?.name} VARIANT-${data?.name} F.I.O`]: `${element.student.profile?.last_name} ${element.student.profile?.first_name} ${element.student.profile?.middle_name}`
        }
      })
    }
    excelExport(new_arr, `${data?.faculty?.name} VARIANT-${data?.name} F.I.O`)
  }

  return (
    <>
      <Col span={24}>
        <CustomBreadcrumb arr={_breadcrump} />
      </Col>
      <Divider className="mt-2" />
      <Spin spinning={loading} >

        <Row gutter={[12, 12]}>
          {/* <Col span={12} ><strong>Name: </strong>&nbsp;{data?.name}</Col>
        <Col span={12} ><strong>Capacity: </strong>&nbsp; <Tag color="#C26C03" className="border-0" >{data?.selectedCount} / {data?.capacity}</Tag></Col>
        <Col span={12} ><strong>Faculty: </strong>&nbsp;{data?.faculty?.name}</Col>
        <Col span={12} ><strong>Course: </strong>&nbsp;{data?.eduSemester?.course?.name}</Col>
        <Col span={12} ><strong>Edu year: </strong>&nbsp;{data?.eduYear?.name}</Col>
        <Col span={12} ><strong>Semester: </strong>&nbsp;{data?.eduSemester?.semestr?.name}</Col>
        <Col span={12} ><strong>Language: </strong>&nbsp;{data?.language?.name}</Col>
        <Col span={12} ><strong>Edu semester: </strong>&nbsp;{data?.eduSemester?.name}</Col> */}
          {/* <Col className="d-f justify-content-between" span={24}><span></span> <Button type="link" onClick={() => setShowCreated(!showCreated)} >details</Button> </Col> */}
        </Row>

        <div className="bg-light p-3 mt-3">

          <Card>
            <Collapse className="rounded" bordered={false}>
              <Collapse.Panel key={0} header={"Time tables ( Subject )"} className="p-0" >
                {data.timeTables?.length ? <div className="p-0 pt-1 bg-light">
                  {
                    data.timeTables.map((element: any) => {
                      return (
                        <Card key={element.id} className="mt-3">
                          <CardBody>
                            <div className={styles.time_table_subjects}>
                              <span>{element.subject?.name}</span>
                            </div>
                            <Row gutter={[12, 12]} className="mt-3" >
                              <Col xl={6} lg={6} md={8} sm={12} xs={24}>
                                <div className={styles.filtered_time_table_card}>
                                  <div className={`${styles.time_table_card} mt-2`}>
                                    <div className="pr-2">
                                      <span>{element?.subjectCategory?.name} &nbsp;&nbsp;<i style={{ color: '#1967D2', fontSize: '12px' }} >(&nbsp;{element?.language?.name}&nbsp;)</i> </span>
                                    </div>
                                    <p>
                                      {element?.week?.name}&nbsp;/&nbsp;{element?.building?.name}&nbsp;/&nbsp;{String(element?.room?.name)?.replace(/\D/g, '')}-xona. ({element?.room?.capacity})&nbsp;/&nbsp;{element?.para?.name}.&nbsp;({element?.para?.start_time}-{element?.para?.end_time})<br />
                                      <span>{element?.teacher?.last_name}&nbsp;{element?.teacher?.first_name}&nbsp;{element?.teacher?.middle_name}</span>
                                    </p>
                                    {/* <span className={styles.selected_count} >Tanlandi: {element.selectedCount} / {element?.room?.capacity}</span> */}
                                  </div>
                                  {
                                    element?.child?.map((element: any) => {
                                      return (
                                        <div key={element.id} className={`${styles.time_table_card} mt-2`}>
                                          <div className="pr-2">
                                            <span>{element?.subjectCategory?.name}&nbsp;&nbsp;<i style={{ color: '#1967D2', fontSize: '12px' }} >(&nbsp;{element?.language?.name}&nbsp;)</i> </span>
                                          </div>
                                          <p>
                                            {element?.week?.name}&nbsp;/&nbsp;{element?.building?.name}&nbsp;/&nbsp;{String(element?.room?.name)?.replace(/\D/g, '')}-xona ({element?.room?.capacity})&nbsp;/&nbsp;{element?.para?.name}.&nbsp;({element?.para?.start_time}-{element?.para?.end_time})<br />
                                            <span>{element?.teacher?.last_name}&nbsp;{element?.teacher?.first_name}&nbsp;{element?.teacher?.middle_name}</span>
                                          </p>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </Col>
                              {
                                element?.seminar?.map((parentElement: any) => {
                                  return (
                                    <Col xl={6} lg={6} md={8} sm={12} xs={24} key={parentElement.id}>
                                      <div className={styles.filtered_time_table_card}>
                                        <div className={`${styles.time_table_card} mt-2`}>
                                          <div className="pr-2">
                                            <span>{parentElement?.subjectCategory?.name}&nbsp;&nbsp;<i style={{ color: '#1967D2', fontSize: '12px' }} >(&nbsp;{parentElement?.language?.name}&nbsp;)</i> </span>
                                          </div>
                                          <p>
                                            {parentElement?.week?.name}&nbsp;/&nbsp;{parentElement?.building?.name}&nbsp;/&nbsp;{String(parentElement?.room?.name)?.replace(/\D/g, '')}-xona({parentElement?.room?.capacity})&nbsp;/&nbsp;{parentElement?.para?.name}.&nbsp;({parentElement?.para?.start_time}-{parentElement?.para?.end_time})<br />
                                            <span>{parentElement?.teacher?.last_name}&nbsp;{parentElement?.teacher?.first_name}&nbsp;{parentElement?.teacher?.middle_name}</span>
                                          </p>
                                          <span className={styles.selected_count} >Tanlandi: {parentElement.selectedCount ?? 0} / {parentElement?.room?.capacity}</span>
                                        </div>
                                        {
                                          parentElement?.child?.map((childElement: any) => {
                                            return (
                                              <div key={childElement.id} className={`${styles.time_table_card} mt-2`}>
                                                <div className="pr-2">
                                                  <span>{childElement?.subjectCategory?.name}&nbsp;&nbsp;<i style={{ color: '#1967D2', fontSize: '12px' }} >(&nbsp;{childElement?.language?.name}&nbsp;)</i> </span>
                                                </div>
                                                <p>
                                                  {childElement?.week?.name}&nbsp;/&nbsp;{childElement?.building?.name}&nbsp;/&nbsp;{String(childElement?.room?.name)?.replace(/\D/g, '')}-xona ({childElement?.room?.capacity})&nbsp;/&nbsp;{childElement?.para?.name}.&nbsp;({childElement?.para?.start_time}-{childElement?.para?.end_time})<br />
                                                  <span>{childElement?.teacher?.last_name}&nbsp;{childElement?.teacher?.first_name}&nbsp;{childElement?.teacher?.middle_name}</span>
                                                </p>
                                              </div>
                                            )
                                          })
                                        }
                                      </div >
                                    </Col >
                                  )
                                })
                              }
                            </Row >
                          </CardBody>
                        </Card>
                      )
                    })
                  }

                </div> : <Empty className="mb-2" />}
              </Collapse.Panel>
            </Collapse>
          </Card>

          <Card className="my-3">
            <Collapse className="rounded" bordered={false} >
              <Collapse.Panel key={0} header={"Time tables ( Week )"} className="p-0" >
                {data?.timeTables?.length ? <Table
                  id="tech-companies-1"
                  className="table table-bordered"
                >
                  <Thead>
                    <Tr className="bg-light" >{weeks.items?.length ? weeks.items?.map((weekItem, weekIndex) => Boolean(weekItem?.status) ? <Th key={weekIndex}>{weekItem?.name}</Th> : null) : null}</Tr>
                  </Thead>
                  <Tbody>

                    {
                      para.items?.length ? para.items?.map((paraItem) => {

                        return (
                          <Tr key={paraItem.id}>
                            {
                              weeks.items?.length ? weeks.items?.map((weekItem) => {

                                if (!Boolean(weekItem?.status)) return null;

                                const _options = filterTimeTable(paraItem?.id, weekItem?.id);

                                return (
                                  <Td key={weekItem?.id} style={{ width: 'calc(100% / 5)' }}>
                                    <div className={styles.time_table_title}>
                                      <span className={styles.time_lesson} >
                                        {paraItem?.name}&nbsp;&nbsp;◷&nbsp;{paraItem?.start_time}-{paraItem?.end_time}
                                      </span>
                                    </div>
                                    {
                                      <span>
                                        {
                                          _options?.map((timeTableItem: any) => {
                                            return (
                                              <div
                                                key={timeTableItem.id}
                                                className={styles.time_table_select_card}
                                                style={timeTableItem?.parent_id === currentId || timeTableItem?.id === currentId || timeTableItem?.lecture_id === currentId ? { cursor: "pointer", border: `1px solid ${timeTableItem?.subject_category_id === 1 ? "#1F86EF" : "#faad14"}`, background: timeTableItem?.subject_category_id === 1 ? "#E8F0FE" : "#fde8cf85" } : { cursor: "pointer" }}
                                                onClick={() => setCurrentId(timeTableItem?.lecture_id ?? timeTableItem?.parent_id ?? timeTableItem?.id)}
                                                onDoubleClick={() => setCurrentId(undefined)}
                                              >
                                                <span><FiKey /><span>ID: <i style={{ color: 'red' }}>{timeTableItem?.id}</i></span></span>
                                                <span><FaRegBuilding /><span>{timeTableItem?.building?.name}&nbsp;&nbsp;{timeTableItem?.room?.name?.replace(/\D/g, '')}-xona ({timeTableItem?.room?.capacity})</span></span>
                                                <span><FiInfo /><span><i style={{ color: 'blue' }}>{timeTableItem?.subjectCategory?.name}</i>&nbsp;/&nbsp;<i style={{ color: 'blue' }}>{timeTableItem?.language?.name}</i></span></span>
                                                <span><BiBookOpen />{timeTableItem.subject?.name}</span>
                                                <span><BiUserVoice />{timeTableItem.teacher?.last_name}&nbsp;{timeTableItem.teacher?.first_name}.&nbsp;{String(timeTableItem.teacher?.middle_name).charAt(0).toUpperCase()}</span>
                                              </div>
                                            )
                                          })
                                        }
                                      </span>
                                    }
                                  </Td>
                                )

                              }) : null
                            }
                          </Tr>
                        )
                      }) : null
                    }
                  </Tbody>
                </Table> : <Empty className="mb-2" />}
              </Collapse.Panel>
            </Collapse>
          </Card>

          <Card>
            <Collapse
              className="rounded"
              bordered={false}
              defaultActiveKey={"0"}
              accordion={false}
            >
              <Collapse.Panel
                key={0}
                header={
                  <div className="time_option_collapse_header" onClick={e => e.stopPropagation()}>
                    <Row gutter={[12, 12]}>
                      <Col xl={12}>
                        <span className="title" >{data?.faculty?.name}-{data?.eduSemester?.name}  &nbsp;&nbsp; <strong style={{ color: "#1890FF" }}>{data.name}</strong></span>
                      </Col>
                      <Col xl={12} className="text-end">
                        <FaFileExcel color="#02723b" style={{ fontSize: "20px" }} onClick={exportExcel} />&nbsp;&nbsp;
                        <Tag color="blue" className="border-0 py-1 px-2">Jami: {student_time_tables.data._meta.totalCount ? student_time_tables.data._meta.totalCount + " /" : null} {data?.selectedCount}</Tag>
                        <Input allowClear placeholder="Search by F.I.O" style={{ width: "300px" }} onChange={(e) => setSearchText(e.target.value)} />
                      </Col>
                    </Row>
                    <hr />
                    <Row gutter={[12, 12]} className="w-100">
                      <Col xl={10}>
                        <Select
                          showSearch
                          placeholder={t(`Fanni tanlang`)}
                          optionFilterProp="children"
                          filterOption={cf_filterOption}
                          allowClear
                          className="w-100"
                          onFocus={getSubjects}
                          onChange={(e) => { setSubjectId(e); filterTimeTableBySubject(e) }}
                        >
                          {
                            subjects?.map((element) => <Option value={element.id}>{element.name}</Option>)
                          }
                        </Select>
                      </Col>
                      <Col xl={14}>
                        <Select
                          showSearch
                          placeholder={t(`Dars jadvalini tanlang`)}
                          optionFilterProp="children"
                          filterOption={cf_filterOption}
                          allowClear
                          className="w-100"
                          onChange={(e) => setTimeTableId(e)}
                          disabled={!subjectId}
                        >
                          {
                            timeTables?.map((element) => <Option value={element.id}>{element.name}</Option>)
                          }
                        </Select>
                      </Col>
                    </Row>
                  </div>
                }
                showArrow={false}
              >
                {data?.studentTimeOption?.length ? <div className="p-3">
                  {/* <Spin spinning={student_time_tables.loading}> */}
                  <Table id="tech-companies-1" className="table for-table">
                    <Thead>
                      <Tr>
                        <Th className="table_head_c" style={{ width: "3%" }}>№</Th>
                        <Th className="table_head_c text-start"><TableTh>F.I.O</TableTh></Th>
                      </Tr>
                    </Thead>
                    <Tbody>

                      {
                        timeTableId ?
                          (student_time_tables.items.length ? student_time_tables.items.filter(filterStudentList)?.map((element, index: number) => (
                            <Tr key={element.id}>
                              <Td className="table_body_c text-center">{index + 1}</Td>
                              <Td className="" style={{ color: '#4B4E52', fontSize: '13px'}}  >
                                {isHasAuthRoute("student_view") ?
                                  <NavLink to={`/student_view/${element.id}`} className="d-block fio_link text-start">
                                    {element?.student.profile?.last_name}&nbsp;{element?.student?.profile?.first_name}&nbsp;{element?.student?.profile?.middle_name}</NavLink> :
                                  <>{element?.student.profile?.last_name}&nbsp;{element?.student?.profile?.first_name}&nbsp;{element?.student?.profile?.middle_name}</>
                                }
                              </Td>
                            </Tr>
                          )) : null) :
                          data.studentTimeOption.filter(filterStudentList)?.map((element, index) => {
                            return (
                              <Tr key={element.student.id}>
                                <Td className="table_body_c text-center">{index + 1}</Td>
                                <Td className="" style={{ color: '#4B4E52', fontSize: '13px' }}  >
                                  {isHasAuthRoute("student_view") ?
                                    <NavLink to={`/student_view/${element.student.id}`} className="d-block fio_link text-start">
                                      {element?.student.profile?.last_name}&nbsp;{element?.student?.profile?.first_name}&nbsp;{element?.student?.profile?.middle_name}</NavLink> :
                                    <>{element?.student.profile?.last_name}&nbsp;{element?.student?.profile?.first_name}&nbsp;{element?.student?.profile?.middle_name}</>
                                  }
                                </Td>
                              </Tr>
                            )
                          })
                      }
                    </Tbody>
                  </Table>
                  {/* </Spin> */}
                </div> : <Empty className="mb-2" />}
              </Collapse.Panel>
            </Collapse>
          </Card>
          <Divider />

          {/* <Row gutter={[24, 24]} className="mx-0 bg-light">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            {data.createdBy ? <Card><Row gutter={[12, 12]} className="border-0 rounded p-2">
              <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Created By")}</Text></Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("First Name")}: </strong>&nbsp; {data.createdBy.first_name}</Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("Last Name")}: </strong>&nbsp; {data.createdBy.last_name}</Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("Login")}: </strong>&nbsp; {data.createdBy.username}</Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("Role")}: </strong>&nbsp; {data.createdBy.role}</Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("Created at")}: </strong>&nbsp; {moment(data?.created_at).format('MM-DD-YYYY')}</Col>
            </Row></Card> : null}
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            {data.updatedBy ? <Card><Row gutter={[12, 12]} className="border-0 rounded p-2">
              <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Updated By")}</Text></Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("First Name")}: </strong>&nbsp; {data.updatedBy.first_name}</Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("Last Name")}: </strong>&nbsp; {data.updatedBy.last_name}</Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("Login")}: </strong>&nbsp; {data.updatedBy.username}</Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("Role")}: </strong>&nbsp; {data.updatedBy.role}</Col>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}><strong>{t("Updated at")}: </strong>&nbsp; {moment(data?.updated_at).format('MM-DD-YYYY')}</Col>
            </Row></Card> : null}
          </Col>
        </Row> */}
        </div>


      </Spin>
    </>
  )
}


export default TimeOptionView;