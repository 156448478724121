import { FC, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Divider, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaRegNewspaper, FaIdCard } from 'react-icons/fa';
import { MdFamilyRestroom } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from 'store/services';
import { STUDENT_ACTIONS } from 'store/student';
import EduInfos from './froms/edu_info';
import AdditionalInfos from './froms/additional_info';
import ParentsInfos from './froms/parents_info';
import SportInfos from './froms/sport_info';
import "../styles.scss"
import Contract_info from './froms/contract_info';

const { TabPane } = Tabs;

type PropsInformerStudentUI = {
    form: FormInstance<any>,
    ref?: any,
    onSubmit?: (values: Record<string | number, any>) => void
}

const StudentNewUi: FC<PropsInformerStudentUI> = forwardRef(({ form, onSubmit }, ref): JSX.Element => {

    const { t } = useTranslation();
    const { activeTabKey } = useAppSelector(state => state.student);
    const dispatch: any = useAppDispatch();

    return (
        <>
            <Tabs defaultActiveKey="1" activeKey={activeTabKey} onTabClick={(key) => dispatch(STUDENT_ACTIONS.setActiveTabKey({ key }))}>
                <TabPane
                    key="1"
                    tab={<span><FaRegNewspaper size={18} />&nbsp;{t("Education details")}</span>}
                >
                    <EduInfos form={form} onSubmit={onSubmit} />
                </TabPane >
                <TabPane
                    key="2"
                    tab={<span><FaIdCard size={18} />&nbsp;{t("Additional information")}</span>}
                >
                    <AdditionalInfos form={form} onSubmit={onSubmit} />
                </TabPane>
                <TabPane
                    key="3"
                    tab={<span><MdFamilyRestroom size={18} />&nbsp;{t("Parent information")}</span>}
                >
                    <ParentsInfos form={form} onSubmit={onSubmit}/>
                </TabPane>
                <TabPane
                    key="4"
                    tab={<span><FaIdCard size={18} />&nbsp;{t("Sport information")}</span>}
                >
                    <SportInfos form={form} />
                </TabPane>
                <TabPane
                    key="5"
                    tab={<span><FaIdCard size={18} />&nbsp;{t("Contract information")}</span>}
                >
                    <Contract_info form={form} onSubmit={onSubmit}/>
                </TabPane>
                {/* <TabPane
                    key="5"
                    tab={<span><GoFileSubmodule size={18} />&nbsp; {t("Student documents")}</span>}
                >
                </TabPane> */}
            </Tabs >
        </>
    )
})


export default StudentNewUi;