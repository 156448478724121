import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Th, Thead, Tr } from "react-super-responsive-table";

const TableHeader: FC<{ thData: Array<string> }> = ({ thData }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Thead>
      <Tr>
        
        {
          thData?.length ? thData?.map((e, i: number) => (
            <Th key={i} >{t(e)}</Th>
          )) : null
        }
        <Th className="text-center">{t("Actions")}</Th>
      </Tr>
    </Thead>
  )
}

export default TableHeader