import { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tr, Td } from "react-super-responsive-table";
import isHasAuthRoute from "utils/isHasRoute";
import { EmptyTable } from "components/Empty";
import IndexPageTitle from "components/IndexPageTitle";
import { ButtonCreate } from "components/Buttons";
import SimpleTable from "components/simpleTable";
import { Col, Row, Input, Tooltip, Popconfirm, Tag, Button } from "antd";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import CustomPagination from "components/Pagination";
import TimeOptionCreate from "./crud/create";
import TimeOptionUpdate from "./crud/edit";
import { NavLink, useHistory } from "react-router-dom";
import { FaEye, FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { delete_data } from "services";
import { ITimeOptions } from "models/others";


import { exportExcelTimeOption } from "./utils";
import FilterSelectComponent from "components/FilterSelect";
import ForTitle from "components/ForTitles";

const { Search } = Input;

const TimeOptions: FC = (): JSX.Element => {
  const { i18n, t } = useTranslation();
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [id, setId] = useState<number | null>(null);
  const [isExport, setIsExport] = useState<boolean>(false);
  const history = useHistory();
  const { value, writeToUrl } = useUrlQueryParams({});

  const data = useGetAllData({
    url: `time-options?sort=-id&expand=faculty,selectedCount,language,eduYear,eduPlan,eduSemester,eduSemester.course,eduSemester.semestr&filter=${JSON.stringify(
      { ...value.filter, key: value.filter_like.key }
    )}`,
    isCall: "auto",
    // currentPage: value.currentPage,
    perPage: 0,
    refetch: [
      i18n.language,
      reFetch,
      value.filter.faculty_id,
      value.filter.edu_plan_id,
      value.filter.edu_semester_id,
      value.filter.language_id,
      value.filter_like.key,
    ],
  }) as TypeReturnUseGetAllData<ITimeOptions>;

  const deleteData = (id: number) =>
    delete_data("/time-options", id).then(() =>
      setReFetch((prevState) => !prevState)
    );

  const isLoadExport = (value: boolean) => {
    setIsExport(value);
  }

  return (
    <div>
      <ForTitle title="Guruhlar" buttons={[
        <Button type="primary" onClick={() => { exportExcelTimeOption(isLoadExport); }} className="me-2" loading={isExport} >Export excel</Button>,
        isHasAuthRoute("time-option_create") ? <ButtonCreate onClick={() => setVisibleCreate(true)} /> : null
      ]}/>
      {id ? (
        <TimeOptionUpdate
          id={id}
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          setReFetch={setReFetch}
        />
      ) : null}
      {
        <TimeOptionCreate
          visible={visibleCreate}
          setVisible={setVisibleCreate}
          setReFetch={setReFetch}
        />
      }
      <Row gutter={[12, 12]} className="mb-3">
        <FilterSelectComponent span={{lg:6, xl: 4, xxl:4}} label="faculty" name="faculty_id" url="faculties" permission="faculty_index" child_names={["edu_plan_id", "edu_semestr_id"]} />
        <FilterSelectComponent span={{lg:6, xl: 4, xxl:4}} label="edu paln" name="edu_plan_id" url="edu-plans" permission="edu-plan_index" parent_name="faculty_id" child_names={["edu_semestr_id"]} />
        <FilterSelectComponent span={{lg:6, xl: 4, xxl:4}} label="edu semestr" name="edu_semestr_id" url="edu-semestrs" permission="faculty_index" parent_name="edu_plan_id" />
        <FilterSelectComponent span={{lg:6, xl: 4, xxl:4}} label="language" name="language_id" url="languages" permission="faculty_index" />
        <Col xs={24} sm={24} md={{ span: 24 }} lg={{ span: 12, offset: 12 }} xl={{ span: 4, offset: 4 }} >
          <Search
            allowClear
            placeholder={t("Search by name ...")}
            name="passport_pin"
            value={value.filter_like.key}
            onChange={(e) => writeToUrl({ name: "key", value: e.target.value })}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <SimpleTable
        header={[
          "№",
          "Name",
          "Capacity",
          "Faculty",
          "Edu plan",
          "Edu year",
          "Course",
          "Semester",
          "Language",
        ]}
        loading={data.loading}
      >
        {data?.items
          ?.sort((a, b) => b.selectedCount - a.selectedCount)
          ?.map((element: any, i: number) => (
            <Tr key={i}>
              <Td>{!data.loading ? i + 1 : "-"}</Td>
              <Td>
                {isHasAuthRoute("time-option_view") ? (
                  <NavLink
                    to={`/time_option_view/${element.id}`}
                    className="d-block fio_link"
                  >
                    {element?.name}
                  </NavLink>
                ) : (
                  <>{element?.name}</>
                )}
              </Td>
              <Td>
                {" "}
                <Tag color="blue" className="border-0">
                  {element?.selectedCount} / {element?.capacity}
                </Tag>{" "}
              </Td>
              <Td>{element?.faculty?.name}</Td>
              <Td>{element?.eduPlan?.name}</Td>
              <Td>{element?.eduYear?.name}</Td>
              <Td>{element?.eduSemester?.course?.name}</Td>
              <Td>{element?.eduSemester?.semestr?.name}</Td>
              <Td>{element?.language?.name}</Td>
              <Td>
                <div className="crud_actions_table">
                  {isHasAuthRoute("time-option_view") ? (
                    <Tooltip placement="topLeft" title={t("View")}>
                      <FaEye
                        className="view_btn"
                        onClick={() => {
                          history.push(`/time_option_view/${element.id}`);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                  {isHasAuthRoute("time-option_update") ? (
                    <Tooltip placement="topLeft" title={t("Edit")}>
                      <FaRegEdit
                        className="edit_btn"
                        onClick={() => {
                          setVisibleEdit(true);
                          setId(element?.id);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                  {isHasAuthRoute("time-option_delete") ? (
                    <Tooltip placement="left" title={t("Delete")}>
                      <Popconfirm
                        placement="topLeft"
                        title={`${t("Deleted it")} ?`}
                        okText={t("Yes")}
                        cancelText={t("No")}
                        onConfirm={() => deleteData(element?.id)}
                      >
                        <FaTrashAlt className="delete_btn" />
                      </Popconfirm>
                    </Tooltip>
                  ) : null}
                </div>
              </Td>
            </Tr>
          ))}
      </SimpleTable>
      <EmptyTable data={data.items} loading={data.loading} />
      <CustomPagination
        totalCount={data?.data?._meta?.totalCount}
        perPage={value?.perPage}
        currentPage={value?.currentPage}
      />
    </div>
  );
};

export default TimeOptions;
