import { ChangeEvent, useEffect, useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Popconfirm, Input, Pagination } from "antd";
import "./style.scss";
import EditDepartment from "./crud/edit";
import AddDepartment from "./crud/add";
import { useTranslation } from "react-i18next";
import CustomModal from "./crud/modal";
import instance from "../../config/_axios";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Tooltip } from "antd";
import isHasAuthRoute from "utils/isHasRoute";
import useDebounce from "hooks/useDebounce";
import { asyncN } from "utils/notifications";
import { _meta } from "../role_and_permission/components/types";
import BadgeStatus from "components/BadgeStatus";
import { getAllDepartments, getDepartmentTypes } from "./service";
import ViewDepartment from "./crud/view";
import { Link, useHistory } from "react-router-dom";
import TotalCount from "components/totalCountTag";
import { TableLineLoader } from "components/SimpleComponents";
import { ButtonCreate } from "components/Buttons";
import ForTable from "components/ForTable";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import ForTitle from "components/ForTitles";
import CustomPagination from "components/Pagination";

const { Search } = Input;

const Department = () => {

  const [data, setData] = useState<any>([]);
  const [department, setDepartment] = useState();
  const [n, setN] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [types, setTypes] = useState<any>({});
  const { i18n, t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [text, setText] = useState<string>("");
  const [_meta, setMeta] = useState<_meta>();

  const debouncedValue = useDebounce<string>(text, 500);

  useEffect(() => {
    (
      async () => {
        setLoading(true);
        const response = await getAllDepartments(`expand=type,userAccess&q=${debouncedValue}`, perPage, currentPage)
        setData(response?.items);
        setMeta(response?._meta);
        setLoading(false);
        const typesList = await getDepartmentTypes();
        setTypes(typesList)
      }
    )()
  }, [i18n.language, debouncedValue, refresh, perPage, currentPage]);

  const deleteItem = (id: number) => {
    if (id) {
      instance({
        method: "delete",
        url: "/departments/" + id,
      })
        .then((r) => {
          asyncN("success", "delete", "Ma'lumot o'chirildi!");
          setRefresh(!refresh);
        })
        .catch((e) => { });
    }
  };

  const section = (n: number) => {
    switch (n) {
      case 1:
        return (
          <EditDepartment
            items={data}
            setRefresh={setRefresh}
            refresh={refresh}
            item={department}
            setVisible={setVisible}
          />
        );
      case 2:
        return (
          department && <ViewDepartment
            item={department}
            setRefresh={setRefresh}
            refresh={refresh}
            setVisible={setVisible} />
        )
      case 3:
        return (
          <AddDepartment
            setRefresh={setRefresh}
            refresh={refresh}
            setVisible={setVisible}
            items={data}
          />
        );
      default:
        return "";
    }
  };


  const getDepartment = (id: number): string => {
    let name: string;

    name = data?.filter((item: { id: number, name: string }) => {
      return item?.id === id && item
    })[0]?.name

    return name;
  }

  return (
    <div>
      <div>
        <div className="mb-2">
          <ForTitle title={"Departments"} buttons={[isHasAuthRoute("department_create") && <ButtonCreate
                onClick={() => {
                  setN(3);
                  setVisible(true);}}
              >
                {t("Add")}
              </ButtonCreate>]}/>
          <div className="d-flex justify-content-end">
            <Search
              placeholder={t("Input search text ...")}
              value={text}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setText(e.target.value)
              }
              style={{ width: 300 }}
            />
          </div>
        </div>
        <TableLineLoader loading={loading}/>
        <ForTable perPage={perPage} setPerPage={setPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} totalCount={_meta?.totalCount}  datas={data} theads={
            [
              <>{t("Name")}</>,
              <>{t("Type")}</>,
              <>{t("Employees")}</>,
              <>{t("Status")}</>,
              <>{t("Department")}</>,
              isHasAuthRoute("department_delete") ||
              isHasAuthRoute("department_update") ||
              isHasAuthRoute("department_view") ? <>
                {t("Actions")}
              </> : null
            ]
          } tbodys={
            function(this: any){
              return  [
                <>{this?.name}</>,
                <>{types[String(this?.type)]}</>,
                <Link to={{
                  pathname: '/department-employees',
                  state: {
                    department: this
                  }
                }}>{t("Edit")}</Link>,
                <>
                  <BadgeStatus status={this?.status} />
                </>,
                <>
                  {getDepartment(this?.parent_id)}
                </>,
                isHasAuthRoute("department_delete") ||
                isHasAuthRoute("department_update") ||
                isHasAuthRoute("department_view")
                ?
                <div className="crud_actions_table">
                  <Tooltip placement="top" title={t("View")}>

                    <TbFileInvoice  className="view_btn"
                      style={{
                        display: isHasAuthRoute("department_view")
                          ? ""
                          : "none",
                      }}
                      onClick={() => {
                        setDepartment(this);
                        setN(2);
                        setVisible(true);
                      }} />
                  </Tooltip>
                  <Tooltip placement="top" title={t("Edit")}>
                    <TbFilePencil className="edit_btn"
                      style={{
                        display: isHasAuthRoute("department_update")
                          ? ""
                          : "none",
                      }}
                      onClick={() => {
                        setDepartment(this);
                        setN(1);
                        setVisible(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title={t("Delete")}>
                    <Popconfirm
                      placement="topLeft"
                      title={`${t("Deleted it")} ?`}
                      okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                      onConfirm={() => deleteItem(this?.id)}
                    >
                      <TbFileOff
                        className="delete_btn"
                        style={{
                          display: isHasAuthRoute("department_delete")
                            ? ""
                            : "none",
                        }}
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>
                : null
              ]
            }
          } loading={loading}/>

        <CustomModal
          visible={visible}
          n={n}
          section={section(n)}
          setVisible={setVisible}
        />
      </div>
    </div>
  );
};

export default Department;
