import { FC } from "react";
import styles from './styles.module.scss';


const IndexPageTitle: FC<{ title: string }> = ({ title }): JSX.Element => {


    return (
        <span className={styles.page_header_title}>{title}</span>
    )
}

export default IndexPageTitle;