import { Button, Col, Select, Form, Input, Modal, Popconfirm, Row, Tag, Tooltip, Pagination, Switch } from "antd";
import { ButtonCreate } from "components/Buttons";
import EmptyTable from "components/EmptyTable";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import instance from "config/_axios";
import i18next from "i18next";
import { _meta } from "pages/role_and_permission/components/types";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { getAllLanguages } from "services";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import CreateJobTitle from "./crud/create";
import { getAllJobTitles } from "./service";
import { jobTitleType, visibleType } from "./types";

const JobTitles: FC = (): JSX.Element => {

    const { t, i18n } = useTranslation();
    const [form] = Form.useForm()
    const [jobTitles, setJobTitles] = useState<Array<jobTitleType>>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)
    const [meta, setMeta] = useState<_meta>()
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [visible, setVisible] = useState<visibleType>({ item: null, type: "hide" });

    useEffect(() => {
        (
            async () => {
                setLoading(true)
                const data = await getAllJobTitles(currentPage, perPage, 'expand=description,userAccessTypes,table');
                setJobTitles(data?.items);
                setMeta(data?._meta);
                setLoading(false)
            }
        )()
    }, [currentPage, perPage, refresh])


    const deleteOne = async (id: number) => {
        try {
            const resp = await instance({
                url: `/job-titles/${id}`,
                method: 'DELETE'
            })
            if (resp?.data?.status === 1) {
                asyncN("success", 'delete', resp?.data?.message).then(() => {
                    setRefresh(!refresh)
                })
            } else {
                asyncN("error", 'delete', resp?.data?.message);
            }
        } catch (error: any) {
            asyncN("error", 'delete', error?.response ? error?.response?.data?.message : error?.message);
        }
    }

    useEffect(() => {
        if (visible?.item !== null && visible?.type == "edit") {
            form?.setFieldsValue(visible?.item)
        }
    }, [visible?.item, visible?.type])

    return (
        <Row gutter={24}>
            <Col span={24}>
                <ForTitle title='Academic degrees' buttons={[isHasAuthRoute("academic-degree_create") && <ButtonCreate onClick={() => {
                        form?.resetFields()
                        setVisible({ item: null, type: "edit" })
                    }}/>]}/>
            </Col>
            <Col span={24}>
                <ForTable setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} totalCount={meta?.totalCount} loading={loading} datas={jobTitles} theads={
                        [
                            <>Nomi</>,
                            <>Izoh</>,
                            <>Boshliq</>,
                            <>Actions</>,
                        ]
                    } tbodys={
                        function(this: any){
                            return [
                                <>
                                    {
                                        this?.name
                                    }
                                </>,
                                <>
                                    {this?.description}
                                </>,
                                <><Switch checked={this?.is_leader == 1} /></>,
                                <>
                                    <div className="crud_actions_table" >
                                        {/* {isHasAuthRoute("academic-degree_view") && <Tooltip placement="top" title={t("View")}><TbFileInvoice  className="view_btn" onClick={() => { setVisible({ item: this, type: "show" }) }} /></Tooltip>} */}
                                        {isHasAuthRoute("academic-degree_update") && <Tooltip placement="top" title={t("Edit")}><TbFilePencil className="edit_btn" onClick={() => { setVisible({ item: this, type: "edit" }) }} /></Tooltip>}
                                        {isHasAuthRoute("academic-degree_delete") &&
                                            <Tooltip placement="top" title={t("Delete")}>
                                                <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`}  okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>} onConfirm={() => deleteOne(this.id)}>
                                                    <TbFileOff className="delete_btn" />
                                                </Popconfirm>
                                            </Tooltip>}
                                    </div>
                                </>
                            ]
                        }
                    }/>
                <Modal visible={visible?.type == "show"}
                    cancelText="Yopish"
                    okText="Tahrirlash"
                    onOk={() => setVisible({ ...visible, type: "edit" })}
                    onCancel={() => setVisible({ item: null, type: "hide" })}
                    title="Show academic degree!"
                >
                    {
                        visible?.item ? <Row gutter={24}>
                            <Col span={24}>
                                <span className="d-flex justify-content-start">
                                    <span style={{ fontWeight: 600 }}>Name (uz)</span>
                                    <span>{visible?.item?.name}</span>
                                </span>
                                {/* <span className="d-flex justify-content-start">
                                    <span style={{ fontWeight: 600 }}>Name (ru)</span>
                                    <span>{visible?.item?.name_ru}</span>
                                </span>
                                <span className="d-flex justify-content-start">
                                    <span style={{ fontWeight: 600 }}>Name (en)</span>
                                    <span>{visible?.item?.name_en}</span>
                                </span> */}
                            </Col>
                        </Row> : ''
                    }
                </Modal>
                <Modal width={1000} visible={visible?.type == "edit"}
                    footer={false}
                    onCancel={() => setVisible({ item: null, type: "hide" })}
                    title={visible?.item ? "Edit academic degree!" : "Create academic degree!"}
                >
                    <CreateJobTitle visible={visible} setVisible={setVisible} refresh={refresh} setRefresh={setRefresh} />
                </Modal>
            </Col>
        </Row>
    )
}

export default JobTitles;