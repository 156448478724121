import React, { ReactNode } from "react";
import { Row, Col, Form, Select, FormInstance, Button } from "antd"
import FormLabelCrud from "components/FormLabel/FormLabelCrud"
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData"
import { ICourse, IDirection, IFaculty } from "models/others"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "store/services";
import useLanguage from "hooks/useLanguage";
import { IEducationCategory, IEducationForm, IEducationPlan, IEducationType, IEducationYear } from "models/education";
import useStoreData, { ReturnTypeUseStoreData } from "hooks/useStoreData";
import { useLocation } from "react-router-dom";
import { cf_filterOption } from "utils/auxiliary_functions";



const { Option } = Select;

type PropsTypeCUEducation = {
    form: FormInstance<any>,
    onSubmit?: (values: Record<string | number, any>) => void
}

type FormsType = {
    title: ReactNode,
    name: string,
    required: boolean,
    items: Array<any>,
    fetchData: () => void,
    loading: boolean,
}

const EduInfos: React.FC<PropsTypeCUEducation> = ({ form, onSubmit }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const { activeTabKey, info } = useAppSelector(state => state.student);

    const { languages } = useLanguage();
    const edu_plans = useGetAllData({ url: `/edu-plans?filter=${JSON.stringify({ faculty_id: form.getFieldValue('faculty_id') })}`, perPage: 0, }) as TypeReturnUseGetAllData<IEducationPlan>;
    const edu_years = useGetAllData({ url: '/edu-years', perPage: 0, }) as TypeReturnUseGetAllData<IEducationYear>;
    const directions = useGetAllData({ url: `/directions?filter=${JSON.stringify({ faculty_id: form.getFieldValue('faculty_id') })}`, perPage: 0, }) as TypeReturnUseGetAllData<IDirection>;

    const edu_types = useStoreData({ key: 'edu_types', params: { 'per-page': 0 } }) as ReturnTypeUseStoreData<IEducationType>;
    const edu_forms = useStoreData({ key: 'edu_forms', params: { 'per-page': 0 } }) as ReturnTypeUseStoreData<IEducationForm>;
    const student_category = useStoreData({ key: "student_categories", params: { 'per-page': 0 } }) as ReturnTypeUseStoreData<IEducationCategory>;
    const faculties = useStoreData({ key: 'faculties', params: { 'per-page': 0 } }) as ReturnTypeUseStoreData<IFaculty>;
    const courses = useStoreData({ key: 'courses', params: { 'per-page': 0 } }) as ReturnTypeUseStoreData<ICourse>;

    const tutors = useGetAllData({ url: `/users?sort=-id&filter=${JSON.stringify({ role_name: ["tutor"] })}`, isCall: 'auto', perPage: 0 });

    const isRequired = React.useCallback(() => activeTabKey === '1', [activeTabKey])

    const forms: Array<FormsType> = [
        {
            title: <>Education type</>,
            name: 'edu_type_id',
            required: isRequired(),
            items: !edu_types.items.length && info?.eduType ? [info.eduType] : edu_types.items,
            fetchData: edu_types._fetch,
            loading: edu_types.loading
        },
        {
            title: <>Education form</>,
            name: 'edu_form_id',
            required: isRequired(),
            items: !edu_forms.items.length && info?.eduForm ? [info.eduForm] : edu_forms.items,
            fetchData: edu_forms._fetch,
            loading: edu_forms.loading
        },
        {
            title: <>Faculty</>,
            name: 'faculty_id',
            required: isRequired(),
            items: !faculties.items.length && info?.faculty ? [info.faculty] : faculties.items,
            fetchData: faculties._fetch,
            loading: faculties.loading
        },
        {
            title: <>Direction</>,
            name: 'direction_id',
            required: isRequired(),
            items: !directions.items.length && info?.direction ? [info.direction] : directions.items,
            fetchData: directions.fetch,
            loading: directions.loading
        },
        {
            title: <>Education year</>,
            name: 'edu_year_id',
            required: isRequired(),
            items: !edu_years.items.length && info?.eduYear ? [info.eduYear] : edu_years.items,
            fetchData: edu_years.fetch,
            loading: edu_years.loading
        },
        {
            title: <>Course</>,
            name: 'course_id',
            required: isRequired(),
            items: !courses.items.length && info?.course ? [info.course] : courses.items,
            fetchData: courses._fetch,
            loading: courses.loading
        },
        {
            title: 'Education plan',
            name: 'edu_plan_id',
            required: isRequired(),
            items: !edu_plans.items.length && info?.eduPlan ? [info.eduPlan] : edu_plans.items,
            fetchData: edu_plans.fetch,
            loading: edu_plans.loading
        },
        {
            title: <>Education language</>,
            name: 'edu_lang_id',
            required: isRequired(),
            items: !languages.items.length && info?.eduLang ? [info.eduLang] : languages.items,
            fetchData: () => { },
            loading: languages.loading
        },
        {
            title: <>Form of payment</>,
            name: 'is_contract',
            required: isRequired(),
            items: [{ id: '1', name: t("Contract") }, { id: '0', name: t("Grant") }],
            fetchData: () => { },
            loading: false
        },
        // {
        //     title: <>Education category</>,
        //     name: 'student_category_id',
        //     required: isRequired(),
        //     items: !student_category.items.length && info?.studentCategory ? [info.studentCategory] : student_category.items,
        //     fetchData: student_category._fetch,
        //     loading: student_category.loading
        // },
    ]



    return (
        <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
        >
            <Row gutter={[12, 0]} className="custom_form_style">
                {
                    forms.map((element: FormsType) => {

                        return (
                            <Col key={element.name} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <Form.Item
                                    label={<FormLabelCrud>{element.title}</FormLabelCrud>}
                                    name={element.name}
                                    rules={[{ required: element.required, message: t("Fill in the field!") }]}
                                    className='d-flex'
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={cf_filterOption}
                                        onFocus={element.fetchData}
                                        loading={element.loading}
                                        onChange={() => {
                                            if (element.name === 'faculty_id') {
                                                form.setFieldsValue({ direction_id: undefined });
                                                directions.setState([]);
                                            }
                                            if (['faculty_id', 'direction_id', 'edu_year_id'].includes(element.name)) {
                                                form.setFieldsValue({ edu_plan_id: undefined });
                                                edu_plans.setState([]);
                                            }
                                        }}
                                    >
                                        {
                                            element.items.length ? element.items.map((element, i: number) => <Option key={i} value={element.id}>{element.name}</Option>) : null
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        )
                    })
                }
                <Col xl={12}>
                    <Form.Item
                        label={<FormLabelCrud>Talaba tyutori</FormLabelCrud>}
                        name="tutor_id"
                        rules={[{ required: false, message: t("Fill in the field!") }]}
                        className='d-flex'
                    >
                        <Select
                            className="w-100"
                            showSearch
                            filterOption={cf_filterOption}
                            loading={tutors.loading}
                            onFocus={() => {
                                if (!tutors.items.length) {
                                    tutors.fetch()
                                }
                            }
                            }
                        >
                            {
                                tutors.items.length ?
                                    tutors.items.map((element) => <Option key={element.id} value={element.id} >{element.last_name}&nbsp;{element.first_name}&nbsp;{element.middle_name}</Option>) :
                                    (info?.tutor ? [info.tutor].map((element) => <Option key={element.id} value={element.id} >{element.last_name}&nbsp;{element.first_name}&nbsp;{element.middle_name}</Option>) : null)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={{offset: 12, span:12}}>
                    <div className="text-end mt-4">
                        <Button type="primary" htmlType='submit' className='px-4' disabled={!location.pathname.includes('/student_update')}  >{t("Save")}</Button>
                    </div>
                </Col>
            </Row>
        </Form>
    )

}


export default EduInfos;
