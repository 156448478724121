import { FC, useState } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction"



const Note: FC = (): JSX.Element => {

    const [events, setEvents] = useState<Array<any>>([]);

    const handleDateClick = (arg: any) => {
      setEvents((prevState) => ([...prevState, { title: "events", date: arg.dateStr }]))
    }
  
    function renderEventContent(eventInfo: any) {
      eventInfo.backgroundColor = "grey";
      eventInfo.isDraggable = true;
      eventInfo.isDragging = true;
      return (
        <div className='date_box'>
          <i>{eventInfo.event.title}</i>
        </div>
      )
    }
  
    const injectCellContent = (args: any) => {
      return (
        <div>
          <button>{args.dayNumberText}</button>
        </div>
      )
    }

    return (
        <div>
            <FullCalendar
                 plugins={[dayGridPlugin, interactionPlugin]}
                 initialView="dayGridMonth"
                 dateClick={handleDateClick}
                 events={events}
                 eventContent={renderEventContent}
                 contentHeight={600}
            />
        </div>
    )
}


export default Note;