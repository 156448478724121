export const data = [
    { name: 'Chetlashtirilgan', year: '2010-2011-yillar', persent: "1%" },
    { name: 'Yiqilgan', year: '2010-2011-yillar', persent: "9%" },
    { name: 'Topshirgan', year: '2010-2011-yillar', persent: "90%" },
    { name: 'Chetlashtirilgan', year: '2011-2012-yillar', persent: "1%" },
    { name: 'Yiqilgan', year: '2011-2012-yillar', persent: "9%" },
    { name: 'Topshirgan', year: '2011-2012-yillar', persent: "90%" },
    { name: 'Chetlashtirilgan', year: '2012-2013-yillar', persent: "1%" },
    { name: 'Yiqilgan', year: '2012-2013-yillar', persent: "9%" },
    { name: 'Topshirgan', year: '2012-2013-yillar', persent: "90%" },
    { name: 'Chetlashtirilgan', year: '2013-2014-yillar', persent: "1%" },
    { name: 'Yiqilgan', year: '2013-2014-yillar', persent: "9%" },
    { name: 'Topshirgan', year: '2013-2014-yillar', persent: "90%" },
    { name: 'Chetlashtirilgan', year: '2014-2015-yillar', persent: "1%" },
    { name: 'Yiqilgan', year: '2014-2015-yillar', persent: "9%" },
    { name: 'Topshirgan', year: '2014-2015-yillar', persent: "90%" },
    { name: 'Chetlashtirilgan', year: '2015-2016-yillar', persent: "1%" },
    { name: 'Yiqilgan', year: '2015-2016-yillar', persent: "9%" },
    { name: 'Topshirgan', year: '2015-2016-yillar', persent: "90%" },
    { name: 'Chetlashtirilgan', year: '2016-2017-yillar', persent: "1%" },
    { name: 'Yiqilgan', year: '2016-2017-yillar', persent: "9%" },
    { name: 'Topshirgan', year: '2016-2017-yillar', persent: "90%" },
    { name: 'Chetlashtirilgan', year: '2020-2021-yillar', persent: "1%" },
    { name: 'Yiqilgan', year: '2020-2021-yillar', persent: "9%" },
    { name: 'Topshirgan', year: '2020-2021-yillar', persent: "90%" },
  ];



export const data1 = [
    {
        year: "1991",
        gender: "Qiz bolalar",
        count: 1200,
    },
    {
        year: "1991",
        gender: "O'g'il bolalar",
        count: 2500,
    },
    {
        year: "1992",
        gender: "Qiz bolalar",
        count: 1300,
    },
    {
        year: "1992",
        gender: "O'g'il bolalar",
        count: 3500,
    },
    {
        year: "1993",
        gender: "Qiz bolalar",
        count: 1500,
    },
    {
        year: "1993",
        gender: "O'g'il bolalar",
        count: 4000,
    },
    {
        year: "1994",
        gender: "Qiz bolalar",
        count: 1800,
    },
    {
        year: "1994",
        gender: "O'g'il bolalar",
        count: 4500,
    },
    {
        year: "1995",
        gender: "Qiz bolalar",
        count: 2300,
    },
    {
        year: "1995",
        gender: "O'g'il bolalar",
        count: 3500,
    },
    {
        year: "1996",
        gender: "Qiz bolalar",
        count: 3000,
    },
    {
        year: "1996",
        gender: "O'g'il bolalar",
        count: 5000,
    },
    {
        year: "1997",
        gender: "Qiz bolalar",
        count: 3300,
    },
    {
        year: "1997",
        gender: "O'g'il bolalar",
        count: 5350,
    },
    {
        year: "1998",
        gender: "Qiz bolalar",
        count: 3000,
    },
    {
        year: "1998",
        gender: "O'g'il bolalar",
        count: 5859,
    },
    {
        year: "1999",
        gender: "Qiz bolalar",
        count: 3205,
    },
    {
        year: "2000",
        gender: "O'g'il bolalar",
        count: 6023,
    },
    {
        year: "2001",
        gender: "Qiz bolalar",
        count: 2125,
    },
    {
        year: "2001",
        gender: "O'g'il bolalar",
        count: 6003,
    },
    {
        year: "2002",
        gender: "Qiz bolalar",
        count: 1800,
    },
    {
        year: "2002",
        gender: "O'g'il bolalar",
        count: 7321,
    },
    {
        year: "2003",
        gender: "Qiz bolalar",
        count: 3056,
    },
    {
        year: "2003",
        gender: "O'g'il bolalar",
        count: 8300,
    }
];

export const data_for_tutor = [
    {
        name: 'Yilik',
        data: [
            {
                month: "Jan",
                gender: "Erkak",
                count: 13
            },
            {
                month: "Jan",
                gender: "Ayol",
                count: 21
            },
            {
                month: "Feb",
                gender: "Erkak",
                count: 33
            },
            {
                month: "Feb",
                gender: "Ayol",
                count: 18
            },
            {
                month: "Mar",
                gender: "Erkak",
                count: 20
            },
            {
                month: "Mar",
                gender: "Ayol",
                count: 25
            },
            {
                month: "Apr",
                gender: "Erkak",
                count: 24
            },
            {
                month: "Apr",
                gender: "Ayol",
                count: 18
            },
            {
                month: "May",
                gender: "Erkak",
                count: 12
            },
            {
                month: "May",
                gender: "Ayol",
                count: 7
            },
        ]
    },
    {
        name: 'Oylik',
        data: [
            {
                month: "1",
                gender: "Erkak",
                count: 3
            },
            {
                month: "1",
                gender: "Ayol",
                count: 6
            },
            {
                month: "4",
                gender: "Erkak",
                count: 4
            },
            {
                month: "4",
                gender: "Ayol",
                count: 2
            },
            {
                month: "5",
                gender: "Erkak",
                count: 7
            },
            {
                month: "5",
                gender: "Ayol",
                count: 1
            },
            {
                month: "6",
                gender: "Erkak",
                count: 9
            },
            {
                month: "6",
                gender: "Ayol",
                count: 4
            },
            {
                month: "7",
                gender: "Erkak",
                count: 6
            },
            {
                month: "7",
                gender: "Ayol",
                count: 6
            },
            {
                month: "8",
                gender: "Erkak",
                count: 4
            },
            {
                month: "8",
                gender: "Ayol",
                count: 2
            },
            {
                month: "11",
                gender: "Erkak",
                count: 5
            },
            {
                month: "11",
                gender: "Ayol",
                count: 1
            },
            {
                month: "12",
                gender: "Erkak",
                count: 2
            },
            {
                month: "12",
                gender: "Ayol",
                count: 1
            },
            {
                month: "13",
                gender: "Erkak",
                count: 1
            },
            {
                month: "13",
                gender: "Ayol",
                count: 2
            },
            {
                month: "14",
                gender: "Erkak",
                count: 1
            },
            {
                month: "14",
                gender: "Ayol",
                count: 0
            },
            {
                month: "15",
                gender: "Erkak",
                count: 0
            },
            {
                month: "15",
                gender: "Ayol",
                count: 0
            },
            {
                month: "18",
                gender: "Erkak",
                count: 0
            },
            {
                month: "18",
                gender: "Ayol",
                count: 0
            },
            {
                month: "19",
                gender: "Erkak",
                count: 0
            },
            {
                month: "19",
                gender: "Ayol",
                count: 0
            },
            {
                month: "20",
                gender: "Erkak",
                count: 0
            },
            {
                month: "20",
                gender: "Ayol",
                count: 0
            },
            {
                month: "21",
                gender: "Erkak",
                count: 0
            },
            {
                month: "21",
                gender: "Ayol",
                count: 0
            },
            {
                month: "22",
                gender: "Erkak",
                count: 0
            },
            {
                month: "22",
                gender: "Ayol",
                count: 0
            },
            {
                month: "25",
                gender: "Erkak",
                count: 0
            },
            {
                month: "25",
                gender: "Ayol",
                count: 0
            },
            {
                month: "26",
                gender: "Erkak",
                count: 0
            },
            {
                month: "26",
                gender: "Ayol",
                count: 0
            },
            {
                month: "27",
                gender: "Erkak",
                count: 0
            },
            {
                month: "27",
                gender: "Ayol",
                count: 0
            },
            {
                month: "28",
                gender: "Erkak",
                count: 0
            },
            {
                month: "28",
                gender: "Ayol",
                count: 0
            },
            {
                month: "29",
                gender: "Erkak",
                count: 0
            },
            {
                month: "29",
                gender: "Ayol",
                count: 0
            },
        ]
    },
    {
        name: 'Haftalik',
        data: [
            {
                month: "Du",
                gender: "Erkak",
                count: 3
            },
            {
                month: "Du",
                gender: "Ayol",
                count: 5
            },
            {
                month: "Se",
                gender: "Erkak",
                count: 6
            },
            {
                month: "Se",
                gender: "Ayol",
                count: 4
            },
            {
                month: "Chor",
                gender: "Erkak",
                count: 8
            },
            {
                month: "Chor",
                gender: "Ayol",
                count: 5
            },
            {
                month: "Pay",
                gender: "Erkak",
                count: 4
            },
            {
                month: "Pay",
                gender: "Ayol",
                count: 1
            },
            {
                month: "Ju",
                gender: "Erkak",
                count: 2
            },
            {
                month: "Ju",
                gender: "Ayol",
                count: 3
            },
        ]
    }
]

export const student_data = [
    {
        name: "SHOXJAXON ABDURAXIMOV SHUXRAT O'G'LI",
        subject: "Soliq huquqi",
        count: 3
    },
    {
        name: "AZIZA ALIYEVA ALI QIZI",
        subject: "Konstitutsiyaviy huquq",
        count: 1
    },
    {
        name: "MOHIGUL ASRAROVA SHUHRAT QIZI",
        subject: "Konstitutsiyaviy huquq",
        count: 4
    },
    {
        name: "RO'ZIMUROD ATAMURODOV ABDUSAMAT O'G'LI",
        subject: "Soliq huquqi",
        count: 3
    },
    {
        name: "TO'LQINJON BAZARBAYEV  ZAFAR O'G'LI",
        subject: "Konstitutsiyaviy huquq",
        count: 1
    },
    {
        name: "SADAF TILAVOVA TO'LQIN QIZI",
        subject: "Soliq huquqi",
        count: 2
    },
    {
        name: "JONIBEK ALIMOV AKBAR O'G'LI",
        subject: "Davlat va huquq nazariyasi",
        count: 3
    },
    {
        name: "ABDULLAYEV ASQAR AKBAR O'G'LI",
        subject: "Yuristlar uchun ingliz tili",
        count: 3
    },
    {
        name: "SHOHISTA BAHROMOVA ALIJON QIZI",
        subject: "Davlat va huquq nazariyasi",
        count: 2
    },
    {
        name: "XIKMATULLA NAZIROV FOZILJON O'G'LI",
        subject: "Davlat va huquq nazariyasi",
        count: 5
    },
    {
        name: "O'LMASBEK QILICHOV DAVRONBEKOVICH",
        subject: "Soliq huquqi",
        count: 2
    },

    {
        name: "MAFTUNABONU KADIROVA NODIRBEK QIZI",
        subject: "Yuristlar uchun ingliz tili",
        count: 4
    },

    {
        name: "SUHROB RASULOV SALOM O'G'LI",
        subject: "Davlat va huquq nazariyasi",
        count: 5
    },
]