import { Button, Col, Row } from "antd";
import instance from "config/_axios";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import { asyncN } from "utils/notifications";
import { facultyStatisticType, kafedraStatisticType, subjectStatisticType } from "../../types";
import TableLoading from "./tableLoad";

const TeachersStatistic: FC<{ setAllTeachers?: (a: number) => void }> = ({ setAllTeachers }): JSX.Element => {

    const { t } = useTranslation()
    const [statistic, setStatistic] = useState<Array<kafedraStatisticType>>([])
    const [statisticByFaculty, setStatisticByFaculty] = useState<Array<facultyStatisticType>>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [openTeach, setOpenTeach] = useState<Array<number>>([]);
    const [openSubj, setOpenSubj] = useState<Array<number>>([]);
    const [subjectStatistics, setSubjectStatistiks] = useState<Array<subjectStatisticType>>([])

    useEffect(() => {
        (
            async () => {
                setLoading(true)
                try {
                    const response = await instance({
                        method: 'get',
                        url: '/statistics/kafedra?expand=teachersCount,questionsCount,questionsCount,subjects,subjectsCount,faculty,leader,direction,userAccess,description&per-page=0'
                    })

                    if (response?.data?.status === 1) {
                        setStatistic(response?.data?.data?.items)
                        setLoading(false)
                    } else {
                        asyncN("error", "read", response?.data?.message)
                        setLoading(false)
                    }
                } catch (err: any) {
                    asyncN("error", "read", err?.response ? err?.response?.data?.message : err?.message)
                    setLoading(false)
                }
            }
        )()
    }, [])

    useEffect(() => {
        if (statisticByFaculty?.length > 0 && setAllTeachers) {
            let a: number = 0;
            statisticByFaculty?.map((item: facultyStatisticType) => {
                a += item?.teacherCount
            })
            setAllTeachers(a)
        }
    }, [statisticByFaculty])

    useEffect(() => {
        if (statistic?.length > 0) {
            setLoading(true)
            let a: Array<facultyStatisticType> = [];
            statistic.map((item: kafedraStatisticType) => {
                let index = a.findIndex((item1: facultyStatisticType) => item1?.id === item?.faculty?.id)
                if (index > -1) {
                    a[index] = {
                        ...a[index],
                        kafedras: [...a[index]?.kafedras, item],
                        subjectCount: a[index]?.subjectCount + item?.subjectsCount,
                        teacherCount: a[index]?.teacherCount + item?.teachersCount,
                        questionsCount: a[index]?.questionsCount + item?.questionsCount,
                    }
                } else {
                    a.push({
                        id: item?.faculty?.id,
                        name: item?.faculty?.name,
                        kafedras: [item],
                        subjectCount: item?.subjectsCount,
                        teacherCount: item?.teachersCount,
                        questionsCount: item?.questionsCount
                    })
                }
            })
            setStatisticByFaculty(a)
            setLoading(false)
        }
    }, [statistic])

    return (
        <div>
            {
                loading ?
                <Row gutter={24}>
                    <Col span={24}>
                        <TableLoading row={7} col={3} />
                    </Col>
                </Row>
                :
                <div className="tableWrap">
                    <Table className="table">
                        <Thead>
                            <Tr>
                                <Th>{t("Kafedra")}</Th>
                                <Th>{t("Name")}</Th>
                                <Th>{t("Teacher count")}</Th>
                                <Th>{t("Subject count")}</Th>
                                <Th>{t("Question count")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                statisticByFaculty?.map((item: facultyStatisticType, i: number) =>
                                    <>
                                        <tr key={i}>
                                            <td >
                                                <Button onClick={() => {
                                                    if (openTeach.includes(i)) {
                                                        setOpenTeach(openTeach?.filter((item: number) => item !== i))
                                                    } else {
                                                        setOpenTeach([
                                                            ...openTeach,
                                                            i
                                                        ])
                                                    }
                                                }} className="p-0 m-0" type="link" icon={openTeach.includes(i) ? <FaMinusCircle /> : <FaPlusCircle />} />
                                            </td>
                                            <td>
                                                {item?.name}
                                            </td>
                                            <td>
                                                {item?.teacherCount}
                                            </td>
                                            <td>
                                                {item?.subjectCount}
                                            </td>
                                            <td>
                                                {item?.questionsCount}
                                            </td>
                                        </tr>
                                        {
                                            openTeach.includes(i) ? <tr key={i}>
                                                <td colSpan={7}>
                                                    <div className="w-100 bg-white">
                                                        <table className="w-100 table">
                                                            <thead className="bg-light">
                                                                <tr><th colSpan={7} className="text-center">{t("In the section of chairs")}</th></tr>
                                                                <tr>
                                                                    {/* <th style={{ width: "25px" }}></th> */}
                                                                    <th>№</th>
                                                                    <th>{t("Name")}</th>
                                                                    <th>{t("Chair holder")}</th>
                                                                    <th>{t("Teacher count")}</th>
                                                                    <th>{t("Subject count")}</th>
                                                                    <th>{t("Question count")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    item?.kafedras?.map((item: kafedraStatisticType, j: number) =>
                                                                        <>
                                                                            <tr key={j}>
                                                                                {/* <td >
                                                                                    <Button
                                                                                        onClick={() => {
                                                                                            if (openSubj.includes(j)) {
                                                                                                setOpenSubj(openSubj?.filter((item: number) => item !== j))
                                                                                            } else {
                                                                                                setOpenSubj([
                                                                                                    ...openSubj,
                                                                                                    j
                                                                                                ])
                                                                                            }
                                                                                        }}
                                                                                        className="p-0 m-0" type="link" icon={
                                                                                            openSubj.includes(j) ? <FaMinusCircle /> :
                                                                                                <FaPlusCircle />} />
                                                                                </td> */}
                                                                                <td>
                                                                                    {j + 1}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.name}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.leader?.first_name && item?.leader?.last_name ? item?.leader?.first_name + " " + item?.leader?.last_name : ""}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.teachersCount}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.subjectsCount}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.questionsCount}
                                                                                </td>
                                                                            </tr>
                                                                            {openSubj.includes(j) ? <tr key={j}>
                                                                                <td colSpan={7}>
                                                                                    <div className="w-100 bg-white">
                                                                                        <table className="w-100 table">
                                                                                            <thead className="bg-light">
                                                                                                <tr><th colSpan={7} className="text-center">{t("Number of questions in the section on sciences and languages")}</th></tr>
                                                                                                <tr>
                                                                                                    <th style={{ width: "25px" }}>№</th>
                                                                                                    <th>{t("Name")}</th>
                                                                                                    <th>{t("English")}</th>
                                                                                                    <th>{t("Russian")}</th>
                                                                                                    <th>{t("Uzbek")}</th>
                                                                                                    <th>{t("All")}</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    subjectStatistics?.filter((subj: subjectStatisticType) => subj?.kafedra?.id === item?.id)?.map((subj: subjectStatisticType, k: number) =>

                                                                                                        <tr key={k}>
                                                                                                            <td>
                                                                                                                {k + 1}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {subj?.name}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {subj?.questionEngCount}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {subj?.questionRuCount}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {subj?.questionUzCount}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {subj?.questionsCount}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> : ""}
                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </>
                                )
                            }
                        </Tbody>
                    </Table>
                </div>
            }
        </div>
    )
}

export default TeachersStatistic