import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Input, Row, Col, Select, Button, Switch, Modal } from 'antd';
import { FaTimes } from 'react-icons/fa';
import CustomBreadcrumb from "components/Breadcrumb";
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getAllLanguages } from 'services';
import { ServerErrors } from 'utils/validationFunctions';
import { getAllLecterns } from '../../lectern/service';
import { useTranslation } from 'react-i18next';
import { getAllSubjects } from '../service';
import { getAllSemestrs } from 'pages/semestr/service';
import MultipleInput from 'components/MultipleInput';
import ModalTitle from 'components/ModalTitle';

const { Option } = Select;

type EditSubjectType = {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    setReRequest: (reRequest: boolean) => void,
    reRequest: boolean
}

const CreateSubject: FC<EditSubjectType> = ({ visible, reRequest, setVisible, setReRequest }): JSX.Element => {
    const { i18n, t } = useTranslation();
    const [form] = Form.useForm();
    const [lecterns, setLecterns] = useState<Array<any>>([])
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true)
    const [subjects, setSubjects] = useState<Array<{ id: number, name: string }>>([])
    const [semestrs, setSemestrs] = useState<Array<{ id: number, name: string }>>([])

    useEffect(() => {
        (
            async () => {
                const response = await getAllLecterns(0);
                setLecterns(response?.items)
            }
        )()
    }, [i18n.language])

    useEffect(() => {
        (
            async () => {
                const response = await getAllSubjects(0);
                setSubjects(response?.items)
            }
        )()
    }, [i18n.language])

    useEffect(() => {
        (
            async () => {
                const response = await getAllSemestrs();
                setSemestrs(response?.items)
            }
        )()
    }, [])

    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            for (const [key, value] of Object.entries(values)) {
                if (value) {
                    if (key === 'status') {
                        formdata.append(key, status ? '1' : '0');
                    } else {
                        formdata.append(key, String(value));
                    }
                }
            }

            const response = await instance({ url: '/subjects', method: 'POST', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); setVisible(false); setReRequest(!reRequest) })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();

    return (
        <Modal
            title={'Subject create'}
            visible={visible}
            onCancel={() => {setVisible(false); resetFields()}}
            width={800}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
            >
                <Row gutter={[12, 0]}>
                    <Col span={24} className="mb-3" >
                        <MultipleInput layout='vertical' />
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Lecterns")}
                            name="kafedra_id"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Select
                                showSearch
                                placeholder={t("Select a lectern") + " ... "}
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    lecterns.length ? lecterns.map((e: any, i: number) => {
                                        return <Option key={i} value={e.id}>{e.name}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Semestr")}
                            name="semestr_id"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Select
                                showSearch
                                placeholder={t("Select a semestr") + " ... "}
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    semestrs.length ? semestrs.map((e: any, i: number) => {
                                        return <Option key={i} value={e.id}>{e.name}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 12 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Parent")}
                            name="parent_id"
                            rules={[{ required: false, message: t("Fill in the field!") }]}
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder={t("Select a subject") + " ... "}
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    subjects.length ? subjects.map((e: any, i: number) => {
                                        return <Option key={i} value={e.id}>{e.name}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Status")}
                            name="status"
                        >
                            <Switch checkedChildren="On" unCheckedChildren="Off" checked={status} onChange={() => setStatus(!status)} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Col span={24} className="mt-2">
                    <Row gutter={[12, 12]}>
                        <Col lg={{ span: 24 }} className="text-end">
                            <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { setVisible(false) }}>{t("Cancel")}</Button>
                            <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                            <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </Modal>
    )
}


export default CreateSubject;