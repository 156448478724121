import { Form, Select, Button, Modal, Row, Col, Switch } from "antd";
import FormLabel from "components/FormLabel";
import instance from "config/_axios";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import useLanguage from "hooks/useLanguage";
import { IEducationPlan, IEducationSemester, IEducationSemesterSubject } from "models/education";
import { IBuilding, IRoom } from "models/others";
import { ISubjectCategory } from "models/subject";
import React, { useState } from "react";
import { BiX } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "store/services";
import { TIMETABLE_ACTIONS } from "store/time_table";
import { asyncN } from "utils/notifications";
import styles from '../styles/styles.module.scss';
const { Option } = Select;

type TypeCreateValues = {
  edu_plan_id: undefined | number,
  edu_semester_id: undefined | number,
  building_id: undefined | number,
  room_id: undefined | number,
  subject_id: undefined | number,
  language_id: undefined | number,
  teacher_access_id: undefined | number
  subject_category_id: undefined | number
}

const initialValues: TypeCreateValues = {
  edu_plan_id: undefined,
  edu_semester_id: undefined,
  building_id: undefined,
  room_id: undefined,
  subject_id: undefined,
  language_id: undefined,
  teacher_access_id: undefined,
  subject_category_id: undefined,
}

const CreateTimeTable: React.FC<{ setRefetch: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setRefetch }): JSX.Element => {

  const [form] = Form.useForm();
  const dispatch: any = useAppDispatch();
  const { element, visible } = useAppSelector(state => state.time_table);
  const [status, setStatus] = useState<boolean>(true);

  const [rooms, setRooms] = useState<IRoom[]>([]);


  const [values, setValues] = React.useState<TypeCreateValues>(initialValues);

  const { languages } = useLanguage();
  const edu_plans = useGetAllData({ url: `/edu-plans`, perPage: 0 }) as TypeReturnUseGetAllData<IEducationPlan>;
  const edu_semesters = useGetAllData({ url: `/edu-semestrs?filter=${JSON.stringify({ edu_plan_id: values.edu_plan_id })}`, perPage: 0 }) as TypeReturnUseGetAllData<IEducationSemester>;
  const buildings = useGetAllData({ url: `/buildings`, perPage: 0 }) as TypeReturnUseGetAllData<IBuilding>;
  const subjects = useGetAllData({ url: `/edu-semestr-subjects?expand=subject&filter=${JSON.stringify({ edu_semestr_id: values.edu_semester_id })}`, perPage: 0 }) as TypeReturnUseGetAllData<IEducationSemesterSubject>;
  const subject_categories = useGetAllData({ url: `/subject-categories`, perPage: 0 }) as TypeReturnUseGetAllData<ISubjectCategory>;
  const teacher_access = useGetAllData({
    url: `/teacher-accesses/free?filter=${JSON.stringify(Number(values.subject_category_id) === 1 ? { language_id: values.language_id, subject_id: values.subject_id, is_lecture: 1 } : { language_id: values.language_id, subject_id: values.subject_id })}`,
    params: {
      para_id: element?.para_id,
      week_id: element?.week_id,
      semester_id: edu_semesters.items.find(e => e.id === values.edu_semester_id)?.semestr_id,
      edu_year_id: edu_plans.items.find(e => e.id === values.edu_plan_id)?.edu_year_id,
      subject_category_id: values.subject_id
    },
    perPage: 0
  });

  const getRooms = async () => {

    const formdata = new FormData();
    formdata.append('para_id', String(element?.para_id));
    formdata.append('edu_year_id', String(edu_semesters.items.find(e => e.id === values.edu_semester_id)?.edu_year_id));
    formdata.append('week_id', String(element?.week_id));
    formdata.append('semester_id', String(edu_semesters.items.find(e => e.id === values.edu_semester_id)?.semestr_id));
    const response = await instance({ url: `/rooms/free?sort=-id&filter=${JSON.stringify({building_id: values.building_id})}`, method: 'POST', data: formdata, params: { "per-page": 0 } });

    if (response.data?.status === 1) {
      setRooms(response.data?.data?.items);
    }

  }

  const handleChange = React.useCallback(({ name, value }: { name: string, value: number }) => {

    setValues((prevState) => ({ ...prevState, [name]: value }))

  }, [values])

  const onSubmit = async () => {
    try {

      const values = form.getFieldsValue();
      const formdata = new FormData();

      formdata.append('para_id', String(element?.para_id));
      formdata.append('week_id', String(element?.week_id));
      formdata.append('status', status ? '1' : '0');

      for (const [key, value] of Object.entries(values)) {
        if (key !== 'edu_plan_id') {
          formdata.append(key, String(value));
        }
      }

      const response = await instance({ url: "/time-tables", method: "POST", data: formdata });

      if (response.data.status === 1) {
        asyncN("success", 'create', response.data?.message).then(() => {
          form.resetFields();
          setValues(initialValues);
          setRefetch(prevState => !prevState);
          dispatch(TIMETABLE_ACTIONS.closeModal());
        })
      } else {
        asyncN("error", 'update', response.data?.message).then(() => form.resetFields())
      }
    } catch (error) { }
  };


  return (
    <Modal
      title={<span className={styles.modal_title}><span>YARATISH</span>{element?.week_name} / {element?.para_name}</span>}
      centered
      visible={visible === 'create'}
      onCancel={() => { dispatch(TIMETABLE_ACTIONS.closeModal()); form.resetFields() }}
      footer={null}
      closeIcon={<BiX size={20} />}
      width={1000}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={[12, 0]} >
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Ta'lim rejasi</FormLabel>}
              name="edu_plan_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                showSearch
                allowClear
                onFocus={() => { if (!edu_plans.items.length) { edu_plans.fetch() } }}
                onChange={(eventValue) => { handleChange({ name: 'edu_plan_id', value: eventValue }); form.setFieldsValue({ edu_semester_id: undefined, room_id: undefined, subject_id: undefined, language_id: undefined, teacher_access_id: undefined }) }}
                loading={edu_plans.loading}
                filterOption={(input, option) =>
                  (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                }
              >
                {
                  edu_plans.items.length ? edu_plans.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Ta'lim semester</FormLabel>}
              name="edu_semester_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                showSearch
                onFocus={() => edu_semesters.fetch()}
                onChange={(eventValue) => { handleChange({ name: 'edu_semester_id', value: eventValue }); form.setFieldsValue({ room_id: undefined, subject_id: undefined, language_id: undefined, teacher_access_id: undefined }) }}
                disabled={!Boolean(values.edu_plan_id)}
                loading={edu_semesters.loading}
                filterOption={(input, option) =>
                  (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                }
              >
                {
                  edu_semesters.items.length ? edu_semesters.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>O'quv binosi</FormLabel>}
              name="building_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                onFocus={() => buildings.fetch()}
                onChange={(eventValue) => { handleChange({ name: 'building_id', value: eventValue }); form.setFieldsValue({ room_id: undefined }) }}
                disabled={!Boolean(values.edu_semester_id)}
                loading={buildings.loading}
                showSearch
                filterOption={(input, option) =>
                  (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                }
              >
                {
                  buildings.items.length ? buildings.items.map((element) => <Option key={element.id} value={element?.id} >{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>O'quv xonasi</FormLabel>}
              name="room_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                }
                onFocus={() => { getRooms() }}
                onChange={(eventValue) => { handleChange({ name: 'room_id', value: eventValue }) }}
                disabled={!Boolean(values.building_id)}
              >
                {
                  rooms.length ? rooms.map((element) => <Option key={element.id} value={element?.id} >{element?.name}-xona. &nbsp;&nbsp;&nbsp;<span style={{ color: 'red' }}>Sig'imi: {element.capacity}</span></Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Fanlar</FormLabel>}
              name="subject_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                }
                onFocus={() => subjects.fetch()}
                onChange={(eventValue) => { handleChange({ name: 'subject_id', value: eventValue }); form.setFieldsValue({ language_id: undefined, teacher_access_id: undefined }) }}
                disabled={!Boolean(values.edu_semester_id)}
                loading={subjects.loading}
              >
                {
                  subjects.items.length ? subjects.items.map((element) => <Option key={element?.subject?.id} value={element?.subject?.id}>{element?.subject?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Mashg'ulot toifasi</FormLabel>}
              name="subject_category_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                onFocus={() => subject_categories.fetch()}
                onChange={(eventValue) => { handleChange({ name: 'subject_category_id', value: eventValue }); form.setFieldsValue({ language_id: undefined, teacher_access_id: undefined }) }}
                disabled={!Boolean(values.subject_id)}
                loading={subject_categories.loading}
              >
                {
                  subject_categories.items.length ? subject_categories.items.map((element) => <Option key={element.id} value={element?.id}>{element?.name}</Option>) : null
                }
                {/* <Option value="1">Ma'ruza</Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label={<FormLabel>Tillar</FormLabel>}
              name="language_id"
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                }
                onChange={(eventValue) => { handleChange({ name: 'language_id', value: eventValue }); form.setFieldsValue({ teacher_access_id: undefined }) }}
                loading={languages?.loading}
              >
                {
                  languages?.items.length ? languages.items.map((element) => <Option key={element.id} value={element?.id}>{element?.name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name='teacher_access_id'
              label={<FormLabel>O'qituvchi</FormLabel>}
              rules={[{ required: true, message: 'Select item' }]}
              className="mb-1"
            >
              <Select
                className="w-100"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (String(option!.children) as unknown as string).toLowerCase().includes(String(input).toLowerCase())
                }
                onFocus={() => teacher_access.fetch()}
                onChange={(eventValue) => handleChange({ name: 'teacher_access_id', value: eventValue })}
                disabled={!Boolean(values.language_id)}
                loading={teacher_access.loading}
              >
                {
                  teacher_access.items.length ? teacher_access.items.map((element) => <Option key={element.id} value={element?.id}>{element.teacher?.last_name}&nbsp;{element.teacher?.first_name}&nbsp;{element.teacher?.middle_name}</Option>) : null
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} className="mt-2">
            <Row gutter={[12, 12]}>
              <Col lg={{ span: 8 }}>
                <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
              </Col>
              <Col lg={{ span: 16 }} className="text-end">
                <Button type="primary" className="mx-2 min_w_100" >Reset</Button>
                <Button type="primary" className="min_w_100" onClick={onSubmit} >Saqlash</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )

};

export default React.memo(CreateTimeTable);
