import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import instance from "config/_axios";
import { StoredDataTypes } from "hooks/useStoreData";




const asyncThunkDefaults = createAsyncThunk(
    'defaults',
    async (data: { key: StoredDataTypes, url: string, params?: any }) => {
        try {
            const response = await instance({ url: data.url, method: 'GET', params: data.params ?? {} });
            if (response.data?.status === 1) {

                return {
                    key: data.key,
                    data: response.data?.data?.items,
                    _meta: response.data?.data?._meta
                }
            } else {
                message.error("Something went wrong while getting region data");
            }
        } catch (error: any) {
            message.error(error?.response?.message);
        }
    }
)

export default asyncThunkDefaults;
