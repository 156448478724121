import { HTMLAttributes } from "react";
import { Card, CardBody } from "reactstrap";


const CardContainer = (props:HTMLAttributes<any>): JSX.Element => {



    return (
        <Card {...props} >
            <CardBody className="p_12" >
                {props.children}
            </CardBody>
        </Card>
    )
}



export default CardContainer;