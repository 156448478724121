import { FC, useState } from "react";
import { Avatar, Button, Col, Form, Image, message, Modal, Row, Upload } from 'antd';
import './style.scss'
import { FaCloudUploadAlt } from "react-icons/fa";

const UserAvatar: FC<{
    upload?: boolean,
    setAvatar?: any,
    fileType?: 'image/png' | 'image/jpeg' | 'image/*',
    avatar?: any,
    text?: string,
    size: number,
    uploadedFile?: any,
    className?: string,
    fileSize?: number,
    preview?: boolean
}> = ({ fileType, setAvatar, upload, uploadedFile, avatar, text, size, className, fileSize, preview }) => {
    const [visibility, setVisibility] = useState<boolean>(false);
    const [url, setUrl] = useState<string | undefined>(undefined)


    function stringToHslColor(str: string, s: number, l: number) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        let h = hash % 360;
        return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
    }


    return (
        <>
            <div style={{ zIndex: 1 }} className={"custom-user-avatar-container"}>
                <div className="custom-user-avatar-conf">
                    <div onClick={() => {
                        if (avatar || url) setVisibility(true)
                    }} className="custom-user-avatar" >
                        <Avatar style={{
                            backgroundColor: text ? stringToHslColor(text, 50, 50) : "",
                            cursor: avatar ? "pointer" : ""
                        }} size={size} src={(avatar || url) &&
                            <Image preview={false} src={avatar || url} />} icon={text?.substring(0, 1).toUpperCase()} />
                    </div>
                    {
                        upload ?
                            <div className="custom-user-avatar-upload">
                                <Form.Item name={"avatar"}>
                                    <Upload
                                        maxCount={1}
                                        accept={fileType && fileType}
                                        multiple={false}
                                        showUploadList={false}
                                        customRequest={(e: any) => {

                                            if (fileSize) {
                                                if (e?.file?.size > fileSize) {
                                                    message.error("Fayl o'lchami 200kb dan kam bo'lishi kerak!!!")
                                                } else {
                                                    if (uploadedFile) {
                                                        uploadedFile(e);
                                                    }

                                                }
                                                setUrl(URL.createObjectURL(e?.file))
                                            } else {
                                                if (uploadedFile) {
                                                    uploadedFile(e);
                                                }
                                                setUrl(URL.createObjectURL(e?.file))
                                            }
                                        }}
                                    >
                                        <div style={{
                                            fontSize: size / 5 + "px",
                                            paddingBottom: (size / 3) * 2 + "px"
                                        }}>
                                            <FaCloudUploadAlt color="white" />
                                        </div>
                                    </Upload>
                                </Form.Item>
                            </div>
                            : ""
                    }
                </div>

            </div>
            <Modal footer={false} title={"User photo"} visible={visibility} onCancel={() => setVisibility(false)}>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <img src={avatar || url} width={"100%"} style={{
                            objectFit:"cover"
                        }} />
                    </Col>
                    {upload&&<Col className="pt-2 text-end" xs={24} sm={24} md={24} lg={24}>
                        <Button className="bg-danger text-light" color="red" onClick={() => { setUrl(undefined); setVisibility(false) }} type="default">Delete</Button>
                    </Col>}
                </Row>
            </Modal>
        </>
    )
};

export default UserAvatar;
