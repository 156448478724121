import { FC, useState } from "react";
import { Col, Form, message, Row, Spin } from "antd";
import "suneditor/dist/css/suneditor.min.css";
import { subQuestionType } from "./types";
import { asyncN } from "utils/notifications";
import CustomBreadcrumb from "components/Breadcrumb";
import instance from "config/_axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";
import FeildsUi from "./ui";

const AttachQuestions: FC = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [typeQuestion, setTypeQuestion] = useState<string>("");
  const [editorVal, setEditorVal] = useState<any>();
  const [subQuestions, setSubQuestions] = useState<Array<subQuestionType>>([
    {
      id: 1,
      percent: 20,
      question: "",
    },
    {
      id: 2,
      percent: 30,
      question: "",
    },
    {
      id: 3,
      percent: 50,
      question: "",
    },
  ]);
  const [sending, setSending] = useState<boolean>(false);
  const _breadcrump = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Questions",
      path: "questions",
    },
    {
      name: "Create question",
      path: "question_create",
    },
  ];

  const onFinish = async (values: any) => {
    let data = new FormData();
    let subQuestion: Array<{ percent: number; question: string }> = [];
    let percentCountAndSumm = { count: 0, summ: 0 };
    Object.entries(values).forEach(([key, value]: any) => {
      key as string;
      if (key === "question_file") {
        if (value) {
          data.append(key, value?.file?.originFileObj);
        }
      } else if (String(key)?.startsWith("persent")) {
        let a: string = key;
        let b: Array<string> = a.split("-");
        percentCountAndSumm = {
          count: percentCountAndSumm?.count + 1,
          summ: percentCountAndSumm?.summ + Number(value),
        };
        subQuestion = [
          ...subQuestion,
          {
            percent: value,
            question: values[`question-${b[1]}`],
          },
        ];
      } else if (String(key)?.startsWith("question_type_id")) {
        data.append("question_type_id", value);
      } else if (String(key)?.startsWith("question")) {
      } else if (key === "general_question") {
        data.append("question", value);
      } else if(key==="subject_id"){
        let a:Array<string>=value?.split("-")
        const semestr = a[1]
        data.append(key,a[0])
        // data.append("semestr_id", semestr)
        // data.append('course_id', String(Math.round(Number(semestr) / 2)))
      } else if(key === 'semestr_id'){
        data.append('semestr_id', value)
      } else if(key === 'course_id'){
        data.append('course_id', value)
      } else {
        data.append(key, String(value));
      }
    });
    if (subQuestion?.length > 0)
      data.append("sub_question", JSON.stringify(subQuestion));
    if (percentCountAndSumm?.summ === 100 || values["question_type_id"] == 2) {
      try {
          setSending(true)
        const res = await instance({
          method: "post",
          url: "/questions",
          data: data,
        });
        if (res?.data?.status === 1) {
          asyncN("success", "create", res?.data?.message).then(() => {
            history.push("/questions");
          setSending(false)
          });
        } else {
          asyncN("error", "create", res?.data?.message);
          setSending(false)
        }
      } catch (e) {
        asyncN("error", "create", "Error create question!!!");
        setSending(false)
      }
    } else {
      if (percentCountAndSumm?.summ > 100) {
        message.error(
          percentCountAndSumm?.summ +
            " umumiy foizlar yig'indisi! Foizlar yig'indisi 100 dan ko'p bo'lmasligi kerak!"
        );
      } else {
        message.error(
          percentCountAndSumm?.summ +
            " umumiy foizlar yig'indisi! Foizlar yig'indisi 100 dan kam bo'lmasligi kerak!"
        );
      }
    }
  };

  return (
    <Row gutter={24}>
      <Col span={24}>
        <CustomBreadcrumb arr={_breadcrump} />
      </Col>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="mt-2"
        >
          <Spin spinning={sending}>
            <FeildsUi
              onFinish={onFinish}
              editorVal={editorVal}
              setEditorVal={setEditorVal}
              subQuestions={subQuestions}
              setSubQuestions={setSubQuestions}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
            />
          </Spin>
        </Form>
      </Col>
    </Row>
  );
};

export default AttachQuestions;