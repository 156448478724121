import { useState } from 'react'
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';


const CustomModal = (props: any) => {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const handleOk = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false);
            props.setVisible(false)
        }, 3000);
    };

    const handleCancel = () => {
        props.setVisible(false);
    };

    return (
        <>
            <Modal
                visible={props.visible}
                width={800}
                title={props?.n===3?t("Create department"):props?.n===2?t("View department"):props?.n===1?t("Edit department"):""}
                onCancel={handleCancel}
                footer={false}
            >
                {props.section}
            </Modal>
        </>
    )
};
export default CustomModal
