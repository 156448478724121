import Popconfirm from "antd/lib/popconfirm";
import Tooltip from "antd/lib/tooltip";
import { Button } from 'antd'
import { useTranslation } from "react-i18next";
import { TbFileInvoice } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import React from "react";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { FaEye, FaRegEdit, FaTrashAlt } from "react-icons/fa";

interface ButtonsType {
    has: boolean,
    type: 'view' | 'edit' | 'delete' | 'refresh' | 'update',
    typeOpen: 'modal' | 'page',
    handler: {pathname: string, state: any} | any,
    confirm: false | {
        title: string
    }
}

function ForActions({buttons} : {buttons: ButtonsType[]}): JSX.Element{
    const {t} = useTranslation()

    return <>
        <div className="crud_actions_table">
            {
                (buttons && buttons.length) ? buttons.map((item: ButtonsType, index: number) => {
                    
                    if(item.has) return <React.Fragment key={index}>
                        <Tooltip placement="top" title={t(item.type[0].toUpperCase() + item.type.slice(1, item.type.length))}>
                            {
                                item.typeOpen === 'modal' ? 
                                item.confirm ? 
                                <Popconfirm placement="topLeft" title={`${t(item.confirm.title)} ?`} okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle />} onConfirm={() => item.handler()}>
                                    {
                                        item.type === 'view' ?
                                        <FaEye className={`view_btn`}/> : (item.type === 'edit' || item.type === 'update') ? <FaRegEdit className="edit_btn"/> : <FaTrashAlt className="delete_btn" />
                                    }
                                </Popconfirm> : <Button type="link" onClick={() => item.handler()}/>
                                : <NavLink to={item.handler} > {
                                    item.type === 'view' ?
                                    <FaEye className={`view_btn`}/> : (item.type === 'edit' || item.type === 'update') ? <FaRegEdit className="edit_btn"/> : <FaTrashAlt className="delete_btn" />
                                }</NavLink>
                            }
                            
                        </Tooltip>
                    </React.Fragment>
                }) : null
            }
        </div>
        {/* <div className="crud_actions_table" >
            <Tooltip placement="top" title={t("View")}>{isHasAuthRoute("user_view") &&
                <NavLink to={{ pathname: `/view-user/${context?.id}`, state: { id: context?.id } }} > <TbFileInvoice className="view_btn" /> </NavLink>}
            </Tooltip>
            <Tooltip placement="top" title={t("Edit")}>{isHasAuthRoute("user_update") &&
                <NavLink to={{ pathname: `/edit-user/${context?.id}`, state: { id: context?.id } }}> <TbFilePencil className="edit_btn" /> </NavLink>}
            </Tooltip>
            <Tooltip placement="top" title={t("Delete")}>{isHasAuthRoute("user_delete") &&
                <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`} okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle />} onConfirm={() => _delete.fetch(`/users/${context?.id}`)}>
                    <TbFileOff className="delete_btn" />
                </Popconfirm>}
            </Tooltip>
        </div> */}
    </>
}

export default ForActions