import { Button, Col, Divider, Row, Select } from "antd"
import { ITimeOptions, ITimeTableParentNull } from "models/others";
import React from "react";
import { useAppDispatch } from "store/services";
import isHasAuthRoute from "utils/isHasRoute";
import styles from '../styles/styles.module.scss';



const { Option } = Select;



type PropsInformer = {
    item: ITimeOptions | Partial<ITimeOptions>,
    timeOptionItems: ITimeOptions[],
    isType: "1" | "2",
    getVariants: (edu_semester_id: number, language_id: number) => void,
    submitOption: (time_table_id: number, time_option_id: number) => void,
    element: ITimeTableParentNull
}

const AttachingVariantsToTimeTable: React.FC<PropsInformer> = ({ item, getVariants, element, submitOption, timeOptionItems, isType }) => {

    const dispatch: any = useAppDispatch();
    const [timeOptionId, setTimeOptionId] = React.useState<number | undefined>(undefined);




    return (
        <Row gutter={[12, 12]}>
            <Col xl={isType === '1' ? 12 : 24} xs={24}>
                <div className={styles.variant_time_table_title} >
                    <span>variant - {item?.name ?? <span style={{ color: 'red' }}>?</span>}</span>{item.faculty?.name} - {item.eduSemester?.name}
                </div>
            </Col>
            <Col xl={isType === '1' ? 8 : 18} lg={isType === '1' ? 8 : 18} md={isType === '1' ? 8 : 18} sm={24} xs={24}>
                <Select
                    className="w-100"
                    onFocus={() => { getVariants(element.edu_semester_id, element.language_id) }}
                    onChange={(e) => setTimeOptionId(e)}
                >
                    {
                        timeOptionItems.length ? timeOptionItems?.map((element) => <Option key={element.id} value={element?.id} >{element?.name} - {element.faculty?.name} - {element.eduSemester?.name}</Option>) : null
                    }
                </Select>
            </Col>
            <Col xl={isType === '1' ? 4 : 6} lg={isType === '1' ? 4 : 6} md={isType === '1' ? 4 : 6} sm={24} xs={24}>
                <Button type="primary" disabled={!isHasAuthRoute('time-table_update') || !timeOptionId} style={{ width: '100%', color: "#fff" }} onClick={() => { if (timeOptionId) { submitOption(element.id, timeOptionId) } }} >Saqlash</Button>
            </Col>
            <Col xl={24} xs={24}>
                <Divider className="my-1" ></Divider>
            </Col>
        </Row>
    )
}


export default React.memo(AttachingVariantsToTimeTable);