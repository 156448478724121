import { useHistory, useRouteMatch, match } from 'react-router';
import { Col, Divider, Empty, Input, Modal, Pagination, Row, Select, Spin, Tag } from "antd";
import { BiCheck, BiDetail, BiQuestionMark, BiRightArrowAlt, BiX } from "react-icons/bi";
import { Card, CardBody } from "reactstrap";
import useGetAllData from "hooks/useGetAllData";
import useGetOneData from "hooks/useGetOneData";
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PageContentTitle from 'components/Title';
import { ChangeEvent, useState } from 'react';
import { TableLineLoader } from 'components/SimpleComponents';
import isHasAuthRoute from 'utils/isHasRoute';
import { exam_student_status_list } from '../exam_status_list';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import FormLabel from 'components/FormLabel';
import { useAppSelector } from 'store/services';
import useDebounce from 'hooks/useDebounce';
import isRole from 'utils/isRole';
import AppelationPopver from '../exam_students/components/AppelationPopover';
import ModalTitle from 'components/ModalTitle';
import { FaCheckSquare, FaMinusSquare, FaLaptopCode } from 'react-icons/fa';


export const encodeKey = (key: string): string => {
    if (Boolean(Number(key))) {
        let a: string = ""
        for (let i = 0; i < key?.length; i++) {
            a += String.fromCharCode(Number(key[i]) + 97)
        }
        return a
    } else {
        return "no encode";
    }
}

export const decodeKey = (key: string): string => {
    let a: string = ""
    for (let i = 0; i < key?.length; i++) {
        a += String(Number(key.charCodeAt(i) - 97));
    }

    return a;
}

const typeAppeal = (appealType: number | null) => {
    switch (appealType) {
        case 1:
            return <FaCheckSquare size={19} color='#5A9000' />
        case 2:
            return <FaMinusSquare size={19} color='#D81D24' />
        case 3:
            return <FaLaptopCode size={19} color='#DB5903' />
        case 4:
            return <><FaCheckSquare size={19} color='#5A9000' />-<FaLaptopCode size={19} color='#DB5903' /></>
        default:
            return <BiDetail size={19} color="#1967D2" />
    }
}


const { Option } = Select;

const Appelation: React.FC = (): JSX.Element => {

    const { t } = useTranslation();
    const userData: any = useAppSelector(state => state.auth);
    const history = useHistory();

    const [visible, setVisible] = useState<number | null>(null);
    const [selectedElement, setSelectedElement] = useState<{ [key: string | number]: any } | null>(null);
    const { value, writeToUrl } = useUrlQueryParams({ perPage: 0, currentPage: 1 });

    const exams = useGetAllData({ url: `/exams`, perPage: 0 })
    const exam_appeals = useGetAllData({ url: `/exam-appeals?expand=description&filter=${JSON.stringify({ exam_id: value.filter?.exam_id })}`, isCall: 'auto', perPage: 0, currentPage: value.currentPage, refetch: [value.filter?.exam_id] });


    return (
        <div className='not_user_select'>

            <Modal
                title={<ModalTitle>{isRole('admin') ? selectedElement?.id : encodeKey(String(selectedElement?.id))}-{isRole('admin') ? selectedElement?.exam_student_id : encodeKey(String(selectedElement?.exam_student_id))} - apelatsiya</ModalTitle>}
                visible={visible !== null}
                onCancel={() => setVisible(null)}
                closeIcon={<BiX size={20} />}
                footer={null}
            >
                <AppelationPopver text={selectedElement?.appeal_text} conclusion={selectedElement?.teacher_conculusion} />
            </Modal>
            <Spin tip="Loading..." spinning={exam_appeals.loading}>
                {(userData.user.role as string[]).includes('admin') ?
                    <Row gutter={[12, 12]} className="mb-3" >
                        <Col xl={6}>
                            <FormLabel>Imtihon bo'yicha saralash.</FormLabel>
                            <Select
                                style={{ width: 120 }}
                                className="w-100 ant-select_custom"
                                onFocus={() => { exams.fetch() }}
                                onChange={(e: number) => writeToUrl({ name: 'exam_id', value: e, items: exams.items })}
                                value={value.filter?.exam_id}
                                loading={exams.loading}
                                showSearch
                                allowClear
                                filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    exams.items?.length ?
                                        exams.items.map((element, index) => <Option key={index} value={element?.id}>{element?.name}</Option>) :
                                        [value.item?.exam_id].map((element, index) => <Option key={index} value={element?.id}>{element?.name}</Option>)
                                }
                            </Select>
                        </Col>
                        <Col xl={6}>
                            <FormLabel>Talaba id ma'lumoti bo'yicha qidirish.</FormLabel>
                            <Input
                                placeholder={`${t("Input search text")} ...`}
                                value={value.filter_like?.id_student}
                                onChange={(e: ChangeEvent<HTMLInputElement>): void => { writeToUrl({ name: 'id_student', value: e?.target?.value }) }}
                                className="w-100 ant_input_custom"
                            />
                        </Col>
                    </Row> : null}
                <Row gutter={[12, 12]} style={{ backgroundColor: "#F5F7F9" }} className="px-2 py-4">
                    {
                        exam_appeals.items?.length ? exam_appeals.items.sort((a, b) => b.type - a.type).map((element, index) => {
                            return (
                                <Col key={index} xl={6} lg={8} md={12} xs={24}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-between">
                                                <span className='fs-16' style={{ color: "#096dd9" }}>
                                                    {isRole('admin') ? element?.student_id : encodeKey(String(element?.student_id))}-{isRole('admin') ? element?.id : encodeKey(String(element?.id))} - apelatsiya</span>
                                                <span className='cursor-pointer' onClick={() => { setVisible(element.id); setSelectedElement(element) }} >
                                                    {typeAppeal(element?.type)}
                                                </span>
                                            </div>
                                            <Divider className='mb-3'></Divider>
                                            <div className={Number(element?.isChecked) && element?.plagiat_percent && Number(element?.plagiat_percent) !== 0 ? "d-f justify-content-between w-100" : 'w-100 text-end'}>

                                                {/* {Number(element?.isChecked) && element?.plagiat_percent && Number(element?.plagiat_percent) !== 0 ?
                                                    <Tag color={Number(element?.isPlagiat) ? "#FCE8E6" : "#E8F0FE"} className="rounded text-center" style={Number(element?.isPlagiat) ? { color: '#A50E0E' } : { color: '#1967D2' }} >
                                                        Plagiat: {element?.plagiat_percent}%
                                                    </Tag> : null} */}
                                                {
                                                    isHasAuthRoute('exam-checking_appeal') ?
                                                        (
                                                            <Tag color={element?.type ? "#E6F4EA" : "#E8F0FE"} className="cursor-pointer rounded min_w_150 text-center" style={{ color: element?.type ? '#137333' : '#1967D2' }} onClick={() => history.push(`/appelation_checking/${element?.id}`)} >{element?.type ? "Tekshirilgan" : "Tekshirish"}<BiRightArrowAlt color={element?.type ? '#137333' : '#1967D2'} /></Tag>
                                                        ) : exam_student_status_list(element?.status)
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>)
                        }) : null}
                </Row>
                <div className='text-end mt-2' >
                    <Pagination
                        defaultCurrent={value.currentPage}
                        total={exam_appeals?.data?._meta?.totalCount}
                        defaultPageSize={value.perPage}
                        onChange={(e) => writeToUrl({ name: 'currentPage', value: e })}
                        onShowSizeChange={(e, pageSize) => writeToUrl({ name: 'perPage', value: pageSize })}
                        showSizeChanger
                        pageSizeOptions={["10", "20", "30", "50", "100"]}
                    />
                </div>
            </Spin>
        </div >
    )

}




export default Appelation;


/** 
@permissons
exam-appeal_create
exam-appeal_delete
exam-appeal_index
exam-appeal_update
exam-appeal_view
*/



{/* <div className="appelation_card p-2">
<Row gutter={[12, 12]}>
    <Col xl={12} >
        <Select
            allowClear
            className="w-100"
            placeholder={t("Filter by lectern")}
        >
            <Option value={'s'}>dfgsdfgs</Option>
        </Select>
    </Col>
    <Col xl={12} >
        <Search placeholder="input search text" />
    </Col>
</Row>
<Table
    id="tech-companies-1"
    className="appelation_exam_table mt-2"
>
    <Thead>
        <Tr style={{ backgroundColor: "#F7FAFE", border: "0px solid white !important" }}>
            
            <Th>{t("Name")}</Th>
            <Th>{t("Status")}</Th>
        </Tr>
    </Thead>
    <Tbody style={{ border: "0px solid #EBF4F7" }} >
        {
            exams.items.length ? exams.items.map((element: IExam, index: number) => {
                return (
                    <Tr key={index} >
                        <Td>{index + 1}</Td>
                        <Td>{element?.name}</Td>
                    </Tr>
                )
            }) : null
        }
    </Tbody>
</Table>
</div> */}