import { ButtonHTMLAttributes } from "react";
import { useTranslation } from "react-i18next"
import { AiOutlinePlus } from "react-icons/ai";
import { BiCheckCircle, BiListPlus, BiPlusCircle } from "react-icons/bi";
import { HiOutlineDocumentAdd, HiPlus } from "react-icons/hi";
import { RiFileExcel2Line } from "react-icons/ri";
import styles from "./styles.module.scss";

export const ButtonCreate = (props: ButtonHTMLAttributes<any>): JSX.Element => {

    const { t } = useTranslation();

    return (
        <button {...props} className={styles.button_create}><HiOutlineDocumentAdd size={16} className="me-2" />{t('Create')}</button>
    )
}
export const ButtonExport = (props: ButtonHTMLAttributes<any>): JSX.Element => {

    const { t } = useTranslation();

    return (
        <button {...props} className={styles.button_export}><RiFileExcel2Line className="me-1" />{t('Export')}</button>
    )
}

export const ButtonConfirm = (props: ButtonHTMLAttributes<any>): JSX.Element => {

    const { t } = useTranslation();

    return (
        <button {...props} className={styles.button_confirm}><BiCheckCircle size={16} className="me-2" />{t('Confirmation')}</button>
    )
}
export const ButtonSave = (props: ButtonHTMLAttributes<any>): JSX.Element => {

    const { t } = useTranslation();

    return (
        <button {...props} className={styles.button_confirm}><BiListPlus size={18} className="me-2" />{t('Save')}</button>
    )
}
