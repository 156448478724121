import { FC, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Form } from 'antd';
import CustomBreadcrumb from "components/Breadcrumb";
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import EduPlanUi from './ui';
import { getOneEduPlan } from '../service';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const _breadcrump = [
    {
        name: "Home",
        path: '/',
    },
    {
        name: "Education Plans",
        path: 'edu_plan_index'
    },
    {
        name: "Education plan edit",
        path: 'edu-plan_update'
    },
]



const EditEduPlan: FC = (): JSX.Element => {

    const [form] = Form.useForm();
    const ref: any = useRef()
    const history = useHistory();
    const location: any = useLocation();
    const { i18n, t } = useTranslation();
    const paths:Array<string>=location?.pathname?.split("/");
    const id  = Number(paths[paths?.length-1])
    const [clicked, setClicked] = useState<boolean>(false);
    const [data, setData] = useState<any>()
    const [status, setStatus] = useState<boolean>(true);
    const [dates, setDates] = useState<{ fall_start: any, fall_end: any, spring_start: null, spring_end: null }>({
        fall_start: null,
        fall_end: null,
        spring_start: null,
        spring_end: null
    })





    useEffect(() => {
        (
            async () => {
                try {
                    if (!id) {
                        asyncN('error', 'read', "Id ma'lumotini olishda xatolik !").then(() => history.goBack())
                    }
                    const response = await getOneEduPlan(id, "expand=description,faculty,eduYear,direction,eduForm,eduType");
                    setData(response)
                    form.setFieldsValue({
                        [`name[${i18n.language}]`]: response?.name,
                        [`description[${i18n.language}]`]: response?.description,
                        course: response?.course,
                        semestr: response?.semestr,
                        faculty_id: response?.faculty_id,
                        direction_id: response?.direction_id,
                        edu_year_id: response?.edu_year_id,
                        edu_type_id: response?.edu_type_id,
                        edu_form_id: response?.edu_form_id,
                        fall_start: moment(response?.fall_start),
                        fall_end: moment(response?.fall_end),
                        spring_start: moment(response?.spring_start),
                        spring_end: moment(response?.spring_end),
                    })
                } catch (error: any) {
                    asyncN("error", "read", error?.response?.message)
                }
            }
        )()
    }, [i18n.language])

    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            Object.entries(values).forEach(([key, value]: any) => {
                if (key === 'status') {
                    formdata.append(key, status ? '1' : '0');
                } else if (['fall_start', 'fall_end', 'spring_start', 'spring_end'].includes(key)) {
                    formdata.append(key, moment(value).format("YYYY-MM-DD"));
                } else {
                    formdata.append(key, String(value));
                }
            });

            const response = await instance({ url: `/edu-plans/${id}`, method: 'PUT', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); history.goBack() })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();


    return (
        <>
            <CustomBreadcrumb arr={_breadcrump} />
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
                className="mt-4"
            >
                <EduPlanUi
                    ref={ref}
                    resetFields={resetFields}
                    status={status}
                    setStatus={setStatus}
                    clicked={clicked}
                    type="update"
                    dates={dates}
                    form={form}
                    data={data}
                />
            </Form>
        </>
    )
}


export default EditEduPlan;