import { FC } from 'react';
import SimpleIndex from 'pages/common/simple_index';

const Week: FC = () => {

  return (
    <div>
      <SimpleIndex
          url="weeks"
          indexTitle="Week"
          editTitle="Week edit"
          viewTitle="Week view"
          createTitle="Week create"
          permissions={{
              view_: "week_view",
              delete_: "week_delete",
              update_: "week_update",
              create_: "week_create"
          }}
      />
    </div>
  );
}

export default Week;