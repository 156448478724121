import { Button, Statistic } from 'antd';
import React, { useState, useRef, useEffect, FC } from 'react'
import { AiFillPauseCircle, AiFillPlayCircle } from 'react-icons/ai';
import { ImLoop2, ImPlay2 } from "react-icons/im"
import { ImStop } from "react-icons/im"
import { MdForward10, MdReplay10 } from 'react-icons/md';
import styled from "styled-components"
import './playerStyle.scss'
//import other audio files//
const { Countdown } = Statistic;
const AudioPlayer: FC<{ type: "AUDIO" | "VIDEO", drums: string, id: number, played: number, setPlayed: (played: number) => void }> = ({ type, drums, played, setPlayed, id }) => {
    const [duration, setDuration] = useState(0);
    const [seekTime, setSeekTime] = useState(0);
    const [appTime, setAppTime] = useState(0);
    const [loop, setLoop] = useState<boolean>(false)

    function fancyTimeFormat(duration: number) {
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":";
        }

        ret += (mins < 10 ? "0" : "") + "" + mins + ":";
        ret += (secs < 10 ? "0" : "") + "" + secs;
        return ret;
    }
    return (
        <div className='video-content-container' style={{ position: "relative", overflow: 'hidden', border: type === "VIDEO" ? "1px solid" : "none" }}>
            <div className='w-100 video-content-tag'>
                <Player
                    type={type}
                    loop={loop}
                    drum={drums}
                    playing={id === played}
                    seekTime={seekTime}
                    onTimeUpdate={(event: any) => setAppTime(event.target.currentTime)}
                    onLoadedData={(event: any) => setDuration(event.target.duration)} />

            </div>
            <div className={`d-flex p-2 align-items-center w-100 ${type === "AUDIO" ? 'justify-content-between border rounded-pill bg-light' : 'content-type-video justify-content-center'}`}>
                <Button className='m-1' shape='circle' type='primary' icon={played === id ? <AiFillPauseCircle /> : <AiFillPlayCircle />} onClick={() => setPlayed(played === -1 || played !== id ? id : -1)} />
                {/* <Button className='m-1' type={loop ? "primary" : "default"} shape='circle' icon={<ImLoop2 />} onClick={() => setLoop(!loop)} /> */}
                <Button className='m-1' shape='circle' onClick={() => setSeekTime(appTime - 10)} icon={<MdReplay10 />} />
                <div className='p-1'>
                    {fancyTimeFormat(appTime)}
                </div>
                <div className='p-1 d-f'>
                    <Seekbar
                        value={appTime}
                        min={0}
                        max={duration}
                        onInput={(event: any) => setSeekTime(event.target.value)}
                    />
                </div>
                <div className='p-1'>
                    {fancyTimeFormat(duration)}
                </div>
                <Button className='m-1' shape='circle' onClick={() => setSeekTime(appTime + 10)} icon={<MdForward10 />} />
            </div>
        </div>
    );
}

const Seekbar: FC<{ value: number, min: number, max: number, onInput: any }> = ({ value, min, max, onInput }) => {
    return (
        <input
            type="range"
            step="any"
            value={value}
            min={min}
            max={max}
            onInput={onInput}
        />
    );
}

const Player: FC<{ type: "AUDIO" | "VIDEO", playing: boolean, seekTime: any, loop: boolean, onTimeUpdate: any, onLoadedData: any, drum: string }> = ({ type, playing, loop, seekTime, onTimeUpdate, onLoadedData, drum }) => {
    const ref: any = useRef(null);
    if (ref.current) playing ? ref.current.play() : ref.current.pause();
    //updates audio element only on seekTime change (and not on each rerender):
    useEffect(() => (ref.current.currentTime = seekTime), [seekTime]);
    return (
        type === "AUDIO" ? <audio
            src={drum}
            ref={ref}
            loop={loop}
            autoPlay={false}
            onTimeUpdate={onTimeUpdate}
            onLoadedData={onLoadedData}
        /> : <video
            src={drum}
            ref={ref}
            loop={loop}
            autoPlay={false}
            onTimeUpdate={onTimeUpdate}
            onLoadedData={onLoadedData}
        />
    );
}
export default AudioPlayer