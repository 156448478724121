import { message } from "antd";
import instance from "config/_axios";



export const getOneLectern = async (id: number | null, path_prefix?: string) => {
    try {
        if (!id) {
            return new Error("Id bo'yicha ma'lumot olinmadi !");
        }

        const response = await instance({ url: `/kafedras/${id}${path_prefix ? '?' + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}





export const getAllLecterns = async (perPage?: number, currentPage?: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/kafedras${path_prefix ? '?' + path_prefix : ''}`, method: "GET", params: { "per-page": perPage, page: currentPage } });

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getUserAccessByLecternId = async (id: number, path_prefix?: string) => {
    try {

        const response = await instance({ url: `/user-accesses?filter={"user_access_type_id":2,"table_id":${id}}${path_prefix ? '&' + path_prefix : ''}`, method: "GET"});

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}