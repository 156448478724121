import { Col, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { asyncN } from "utils/notifications";
import { getAllExamTypes } from "../../exam_types/service";
import { getAllSubjectCategories } from "../../subject_category/service";
import { getOneSemesterSubject } from "../edu_semester_subject/service";
import { elementType, examType, subjectCategoryType } from "./type";

const ViewSemestrSubjects: FC<elementType> = ({ visible, edit, item, setElement, element, subjectName, refresh, setRefresh }): JSX.Element => {

    const [eduSemestrSubject, setEduSemestrSubject] = useState<any>();
    const [subjectCategory, setSubjectCategory] = useState<Array<subjectCategoryType>>([]);
    const [examTypes, setExamTypes] = useState<Array<examType>>([]);
    const {t} = useTranslation();


    useEffect(() => {
        (
            async () => {
                try {
                    const response = await getOneSemesterSubject(item?.id, "expand=eduSemestrExamsTypes,eduSemestrSubjectCategoryTimes,EduSemestrExamType,subject")
                    setEduSemestrSubject(response)
                    const res1 = await getAllSubjectCategories();
                    setSubjectCategory(res1?.items);
                    const res2 = await getAllExamTypes();
                    setExamTypes(res2?.items)
                } catch (e: any) {
                    asyncN("error", "read", "Error for get edu semestr subject!!!")
                }
            }
        )()
    }, [item, visible])
    const getExamName = (id: number): string => {
        let a: string = examTypes?.filter((item: examType) => {
            return item?.id === id && item
        })[0]?.name
        return a;
    }

    const getCategoryName = (id: number): string => {
        let a: string = subjectCategory?.filter((item: examType) => {
            return item?.id === id && item
        })[0]?.name
        return a;
    }

    return (
        <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={6}>
                <strong>{t("Subject name")}: </strong>{" " + eduSemestrSubject?.subject?.name}
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
                <strong>{t("Total score")}: </strong>{" " + eduSemestrSubject?.max_ball}
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
                <strong>{t("Total hours")}: </strong>{" " + eduSemestrSubject?.all_ball_yuklama}
            </Col>
            <Col xs={24} sm={24} md={8} lg={6}>
                <strong>{t("Credit")}: </strong>{" " + eduSemestrSubject?.credit}
            </Col>
            <Col className="mt-3" xs={24} sm={24} md={24} lg={24}>
                <strong>{t("Scores")}:</strong>
            </Col>

            {
                eduSemestrSubject?.eduSemestrExamsTypes && eduSemestrSubject?.eduSemestrExamsTypes?.map((item: any) =>
                    <Col xs={24} sm={24} md={8} lg={6}>
                        {
                            getExamName(item?.exams_type_id)+": "+item?.max_ball
                        }
                    </Col>
                )
            }

            <Col className="mt-3" xs={24} sm={24} md={24} lg={24}>
                <strong>{t("Hours")}:</strong>
            </Col>
            {
                eduSemestrSubject?.eduSemestrSubjectCategoryTimes && eduSemestrSubject?.eduSemestrSubjectCategoryTimes?.map((item: any) =>
                    <Col xs={24} sm={24} md={8} lg={6}>
                        {
                            getCategoryName(item?.subject_category_id)+": "+item?.hours
                        }
                    </Col>
                )
            }
        </Row>
    )
};

// @ts-ignore
export default ViewSemestrSubjects