import { Button, Col, message, Modal, Row, Typography } from "antd";
import instance from "config/_axios";
import useGetOneData from "hooks/useGetOneData";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { asyncN } from "utils/notifications";

const { Text } = Typography;

const HolidayView: FC<{ id: number | undefined, refresh: boolean, visible: boolean, setRefresh: (refresh: boolean) => void, setVisible: (visible: boolean) => void }> = ({ id, refresh, visible, setRefresh, setVisible }): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<any>();
  const [showCreated, setShowCreated] = useState<boolean>(false);

  const getHoliday = async () => {
    try {
      const resp = await instance({
        method: "get",
        url: `/holidays/${id}?expand=description,createdBy,updatedBy`
      })

      if (resp.data?.status === 1) {
        const data: any = resp?.data?.data;
        setData(data)
      } else {
        asyncN("error", 'update', resp.data?.message)
      }
    } catch (error) {
      message.warning("Ma'lumot yetarli emas !");
    }
  }

  useEffect(() => {
    if (id && visible) {
      getHoliday()
    }
  }, [visible])

  return (
    <Modal title={t("Holiday view")} visible={visible} onCancel={() => {setVisible(false); setShowCreated(false)}} footer={false}>
      <Row gutter={[12, 8]}>
        <Col span={24} ><strong>{t("Name")}:</strong> &nbsp; <span>{data?.name}</span> </Col>
        <Col span={24} ><strong>{t("Description")}:</strong> &nbsp; <span>{data?.description}</span> </Col>
        <Col span={24} ><strong>{t("Start date")}:</strong> &nbsp; <span>{data?.start_date}</span> </Col>
        <Col span={24} className="d-flex justify-content-between align-items-center" >
          <div><strong>{t("End date")}:</strong> &nbsp; <span>{data?.finish_date}</span></div>
          <Button type="link" onClick={() => setShowCreated(!showCreated)} >details</Button>
        </Col>
      </Row>
      {showCreated && <>{data?.createdBy ? <Row gutter={[12, 12]} className="border py-2 mt-2">
        <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Created By")}</Text></Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("First Name")}: </Text>{data?.createdBy?.first_name}</Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data?.createdBy?.last_name}</Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Username")}: </Text>{data?.createdBy?.username}</Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data?.createdBy?.role}</Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Created at")}: </Text>{moment(data?.created_at).format('MM-DD-YYYY')}</Col>
      </Row> : null}
        {data?.updatedBy ? <Row gutter={[12, 12]} className="border py-2 mt-2">
          <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Updated By")}</Text></Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("First Name")}: </Text>{data?.updatedBy?.first_name}</Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data?.updatedBy?.last_name}</Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Username")}: </Text>{data?.updatedBy?.username}</Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data?.updatedBy?.role}</Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Updated at")}: </Text>{moment(data?.updated_at).format('MM-DD-YYYY')}</Col>
        </Row> : null}</>}
    </Modal>
  )
}

export default HolidayView