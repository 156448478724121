import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Col, List, message, Row, Tabs, Tag } from 'antd';
import UserAvatar from 'components/userAvatar/avatar';
import { FaEdit, FaFile } from 'react-icons/fa';
import { TbFileInvoice } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import Avatar from 'antd/lib/avatar/avatar';
import { useAppSelector } from 'store/services';
import { ArrayChecker } from 'utils/ArrayChecker';
import { useHistory } from 'react-router-dom';
import { getOneUsers } from 'pages/user/servise';
import { getOneArea } from './servise';
import { getPassword } from 'services';
import { FILE_URL } from 'config/utils';
import ChangePassword from './changePassword';
import { AiOutlineEye } from 'react-icons/ai';

const { TabPane } = Tabs;
const { Meta } = Card;

const ProfilePage: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const history = useHistory();
    const auth: any = useAppSelector(state => state.auth);
    const _roles = auth.user?.role;
    const _id = auth?.user?.user_id;
    const [data, setData] = useState<any>();
    const [area, setArea] = useState<{area: string, region: string}>({area: '', region: ''});
    const [perArea, setPerArea] = useState<{area: string, region: string}>({area: '', region: ''});
    const [password, setPassword] = useState<string>('');
    const [visible, setVisible] = useState<boolean>(false);

    const checkRoles = (role_name: string) => {
        if (ArrayChecker(_roles)._) {
            return _roles.includes(role_name);
        } else if (_roles) {
            return _roles === role_name;
        }
    }

    useEffect(() => {
        (
            async () => {
                try {
                    const res = await getOneUsers(Number(_id), 'expand=profile,department');
                    if(res.status === 1){
                        setData(res?.data);
                        _getArea(res?.data?.profile?.area_id);
                        _getPerArea(res?.data?.profile?.permanent_area_id);
                    }
                } catch (error: any) {
                    message.error(error?.response?.message);
                }
            }
        )()
    },[])

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth < 769) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        })
        if (window.innerWidth < 769) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const _getArea = async(id: number) => {
        if(id){
            const res: any = await getOneArea(`${id}`);
            setArea({area: res?.name, region: res?.region?.name });
        }
    }
    const _getPerArea = async (id: number) => {
        if(id){
            const res: any = await getOneArea(`${id}`);
            setPerArea({area: res?.name, region: res?.region?.name });
        }
    }

    const showPassword = async () => {
        if(!password){
            const pass = await getPassword(Number(_id));
            setPassword(pass?.password);
        }
    }

    return (
        <div className="bg-light container-fluid" style={{ height: "100%" }}>
            <ChangePassword id={_id} visible={visible} setVisible={setVisible}/>
            <Row gutter={24} className="d- p-0">
                {<Col className="p-0" xs={24} sm={24} md={8} lg={6}>
                    <Card style={{ overflow: "hidden" }} className="shadow-sm p-0">
                        <Row className='p-0' gutter={24}>
                            <Col className='p-0' span={24} style={{ position: "relative", height: "190px" }}>
                                <div style={{
                                    // border: "1px solid",
                                    top: "-30px",
                                    left: "-15px",
                                    right: "-25px",
                                    bottom: 0,
                                    width: "130% !important",
                                    backgroundImage: "url(https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569__480.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    position: "absolute"
                                }}>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <List>
                                    <List.Item className="d-flex p-0 justify-content-center text-bold">
                                        <Row gutter={24}>
                                            <Col style={{ transform: "translateY(-28%)" }} className="d-flex justify-content-center flex-wrap" xs={24} sm={24} md={24} lg={24}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <UserAvatar fileType="image/*" fileSize={2048000} avatar={data?.avatar ? FILE_URL+data?.avatar : null}  text={data?.first_name} size={200} />
                                                    </Col>
                                                </Row>
                                                <Row className="pt-2" gutter={24} style={{ width: '100%' }}>
                                                    <Col xs={12} sm={12} md={12} lg={12} className="text-end">
                                                        <p style={{ fontWeight: 600 }}>Full name:</p>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>{data?.first_name} {data?.last_name} {data?.profile?.middle_name} </Col>
                                                </Row>
                                                <Row className="pt-0" gutter={24} style={{ width: '100%' }}>
                                                    <Col xs={12} sm={12} md={12} lg={12} className="text-end">
                                                        <p style={{ fontWeight: 600 }}>Username:</p>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>{data?.username}</Col>
                                                </Row>
                                                <Row className="pt-0" gutter={24} style={{ width: '100%' }}>
                                                    <Col xs={12} sm={12} md={12} lg={12} className="text-end">
                                                        <p style={{ fontWeight: 600 }}>Email:</p>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>{data?.email}</Col>
                                                </Row>
                                                <Row className="pt-0" gutter={24} style={{ width: '100%' }}>
                                                    <Col xs={12} sm={12} md={12} lg={12} className="text-end">
                                                        <p style={{ fontWeight: 600 }}>Password:</p>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12}>{ password ? password : '*********'}<Button onClick={() => showPassword()} className="m-0 p-0" type='link'>&nbsp;&nbsp;<AiOutlineEye color='#0d8a71' className="me-1" /></Button></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                </List>
                            </Col>
                        </Row>
                    </Card>
                </Col>}
                <Col className="p-2" xs={24} sm={24} md={16} lg={18}>
                    <Card style={{ height: "100%", position: "relative" }} className="shadow-sm">
                        <Tabs tabPosition="top">
                            <TabPane tab="Asosiy ma'lumotlar" key="1">
                                <Row className="pe-2 ps-2" gutter={24}>
                                    <Col xs={24} sm={24} md={{ span: 12 }} lg={{ span: 12 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Full name")} description={<p>{data?.first_name} {data?.last_name} {data?.profile?.middle_name}</p>} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 12 }} lg={{ span: 12 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Username")} description={<p>{data?.username}</p>} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Email")} description={<p>{data?.email}</p>} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Birthday")} description={data?.profile?.birthday} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Gender")} description={data?.profile?.gender === 1 ? "Male" : "Female"} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 24 }} lg={{ span: 24 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Bio")} description={
                                                <p>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid mollitia aspernatur hic, quam consequatur odio neque molestias vitae impedit quia laudantium quisquam sapiente officia alias quas? Animi id iste ut!
                                                </p>
                                            } />
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Passport ma'lumotlari" key="2">
                                <Row className="pe-2 ps-2" gutter={24}>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Passport seria and number")} description={<p>{data?.profile?.passport_seria} {data?.profile?.passport_number}</p>} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Passport PIN")} description={data?.profile?.passport_pin} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Given by")} description={data?.profile?.passport_given_by} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 12 }} lg={{ span: 12 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Passport date")} description={
                                                <p>
                                                    {data?.profile?.passport_given_date} / {data?.profile?.passport_issued_date}
                                                </p>
                                            } />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 12 }} lg={{ span: 12 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Passport file")} description={
                                                <div style={{ width: "65%" }}>
                                                    <Tag style={{ cursor: "pointer" }} className="p-2 d-flex align-items-center">
                                                        <Avatar size="small" icon={<FaFile />} />
                                                        <span className="ms-2 text-info text-underline"><u>Passport file.pdf</u></span>
                                                    </Tag>
                                                </div>
                                            } />
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Manzil ma'lumotlari" key="3">
                                <Row className="pe-2 ps-2" gutter={24}>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Country")} description={"Uzbekistan"} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Region")} description={area?.region} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Area")} description={area?.area} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 24 }} lg={{ span: 24 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Address")} description={
                                                <p>
                                                    {data?.profile?.address}
                                                </p>
                                            } />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Permanent country")} description={"Uzbekistan"} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Permanent region")} description={perArea?.region} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 8 }} lg={{ span: 8 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Permanent area")} description={perArea?.area} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={{ span: 24 }} lg={{ span: 24 }} className='mb-4'>
                                        <Card >
                                            <Meta title={t("Permanent address")} description={
                                                <p>
                                                    {data?.profile?.permanent_address}
                                                </p>
                                            } />
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                        <div style={{
                            position: "absolute",
                            right: "30px",
                            bottom: "10px",
                            paddingTop: "10px"
                        }}>
                            {
                                checkRoles('teacher') && <Button type='primary' onClick={() => history.push("teacher_statistics")} icon={<FaEdit className="me-1" />}>{t("Edit")}</Button>
                            }
                            <Button type='primary' onClick={() => setVisible(true)}>{t("Parolni o'zgartirish")}</Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ProfilePage