import { Form, message } from "antd"
import { useHistory, useRouteMatch, match } from 'react-router';
import React from "react";
import moment from "moment"
import instance from "config/_axios";
import { IMainCreateUser } from "models/user";
import { UploadFile } from "antd/lib/upload/interface";
import { AxiosRequestConfig } from "axios";
import { useLocation } from "react-router-dom";
import { asyncN } from "utils/notifications";
import { getOneStudent } from "pages/student/service";
import { FILE_URL } from "config/utils";
import { IStudentProfile } from "models/student";
import { useAppDispatch } from "store/services";
import { STUDENT_ACTIONS } from "store/student";
import CreateAndUpdateForm from "pages/user/components/create_and_update_form";

const UserMainInfo: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch: any = useAppDispatch();
    const match: match<any> | null = useRouteMatch("/student_update/:student_id");
    const [form] = Form.useForm();
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);

    const isStudentLocation = React.useMemo(() => (location.pathname.includes('student_create') || location.pathname.includes('student_update')), []);

    React.useEffect(() => {
        if (location.pathname.includes('student_update')) {
            (
                async () => {
                    try {
                        if (match) {
                            if (!match.params?.student_id) {
                                asyncN('error', 'read', "Id ma'lumotini olishda xatolik !").then(() => history.goBack())
                            }

                            const response = await getOneStudent(match.params?.student_id, "expand=course,direction,eduType,eduYear,eduForm,faculty,user,tutor,eduLang,profile,eduPlan,citizenship,country,region,area,permanentCountry,permanentRegion,permanentArea,nationality,socialCategory,residenceStatus,categoryOfCohabitant,studentCategory");

                            await dispatch(STUDENT_ACTIONS.addInfo({ info: response }));
                            const { last_name, first_name, middle_name, phone, gender, birthday, status, passport_given_date, passport_pin, passport_seria, passport_given_by, permanent_address, permanent_area_id, permanent_country_id, permanent_region_id, citizenship_id, nationality_id, passport_issued_date, passport_number } = response.profile as IStudentProfile;
                            form.setFieldsValue({
                                first_name: first_name || undefined,
                                last_name: last_name || undefined,
                                middle_name: middle_name || undefined,
                                phone: phone || undefined,
                                gender: String(gender) || undefined,
                                status: String(response?.status) || undefined,
                                birthday: birthday ? moment(birthday) : undefined,
                                passport_date: [passport_given_date ? moment(passport_given_date) : undefined, passport_issued_date ? moment(passport_issued_date) : undefined],
                                passport_pin: String(passport_pin).trim() || undefined,
                                passport_seria_and_number: passport_seria || passport_number ? passport_seria + " " + passport_number : undefined,
                                passport_given_by: passport_given_by || undefined,
                                permanent_country_id: permanent_country_id ? permanent_country_id : 229,
                                permanent_region_id: permanent_region_id || undefined,
                                permanent_area_id: permanent_area_id || undefined,
                                permanent_address: permanent_address?.replace(/(\r\n|\n|\r)/gm, "") || undefined,
                                citizenship_id: citizenship_id || undefined,
                                nationality_id: nationality_id || undefined,
                            })
                            if(response?.profile?.image){
                                setFileList([
                                    {
                                        uid: '-1',
                                        name: 'image.png',
                                        status: 'done',
                                        url: FILE_URL + response?.profile?.image,
                                    }
                                ]);
                            }
                            // if (response?.profile?.passport_file && response?.profile?.passport_file !== "undefined") {
                            //     setFilePassport([
                            //         {
                            //             uid: '-1',
                            //             name: 'Passport file.' + response?.profile?.passport_file.substring(response?.profile?.passport_file.lastIndexOf(".") + 1, response?.profile?.passport_file.length),
                            //             status: 'done',
                            //             url: FILE_URL + response?.profile?.passport_file,
                            //         }
                            //     ])
                            // }
                        }
                    } catch (error: any) {
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }

    }, [])

    const onSubmit = async (values: IMainCreateUser) => {
        try {

            const { passport_seria_and_number, phone, passport_pin } = values;

            const isValidateField = passport_seria_and_number?.includes('_') || phone?.includes('_') || passport_pin?.includes('_');

            if (isValidateField) {
                message.warning("Kiritilgan ma'lumotlar to'g'ri formatda ekanligini tekshiring!")
                return;
            }

            const formdata = new FormData();
            Object.entries(values).forEach(([key, value]: any) => {
                if (value) {
                    if (key === "birthday") {
                        formdata.append(key, moment(value).format("YYYY-MM-DD"));
                    } else if (['avatar', 'passport_file'].includes(key)) {
                        formdata.append(key, value.file.originFileObj)
                    } else if (key === 'passport_date') {
                        formdata.append("passport_given_date", moment(value[0]).format("YYYY-MM-DD"))
                        formdata.append("passport_issued_date", moment(value[1]).format("YYYY-MM-DD"))
                    } else if (key === 'passport_seria_and_number') {
                        formdata.append("passport_seria", value.split(" ")[0])
                        formdata.append("passport_number", value.split(" ")[1])
                    } else {
                        formdata.append(key, String(value));
                    }
                }
            });
            const options: AxiosRequestConfig = location.pathname.includes('student_update') ? { url: `/students/${match?.params.student_id}`, method: 'PUT', data: formdata } : { url: '/students', method: 'POST', data: formdata }
            const response = await instance(options);

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message).then(() => {
                    history.goBack()
                })
            } else {
                asyncN("error", 'update', response.data?.message)
            }

        } catch (error: any) {

        }
    }

    return (
        <div className="custom_form_style">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: '10'
                }}
            >
                <CreateAndUpdateForm form={form} fileList={fileList} setFileList={setFileList} isStudent={isStudentLocation} />
            </Form>
        </div >
    )

}


export default UserMainInfo;