import { LineConfig, Line, Pie, PieConfig } from '@ant-design/charts';
import { Card, Row, Col, Calendar } from 'antd';
import ForTitle from 'components/ForTitles';
import useGetAllData from 'hooks/useGetAllData';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { FaUsers, FaGraduationCap, FaBriefcase, FaBookReader } from 'react-icons/fa';
import { GiTeacher } from 'react-icons/gi';
import { GrUserWorker } from 'react-icons/gr';
import { MdPeopleOutline } from 'react-icons/md';
import { data1 } from '../common/charts/datas';
import LineChart from '../common/charts/lineChart';
import PizzaChart from '../common/charts/pizzaChart';
import MessageInDashboard from '../common/message';
import "../style.scss"

const config1: LineConfig = {
    data: data1,
    xField: 'year',
    yField: 'count',
    seriesField: 'gender',
    yAxis: {
        label: {
            formatter: (v: any) => v,
        },
    },
    legend: {
        position: 'top',
    },
    smooth: true,
    animation: {
        appear: {
            animation: 'path-in',
            duration: 1000,
        },
    },
  };

const config2: PieConfig = {
    appendPadding: 10,
    data: [{item: 'Erkaklar', value: 37.4},{item: 'Ayollar', value: 62.6}],
    angleField: 'value',
    colorField: 'item',
    radius: 0.9,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 14,
            textAlign: 'center',
        },
    },
    interactions: [
        {
            type: 'element-active',
        },
    ],
};

const config3: PieConfig = {
    appendPadding: 10,
    data: [{item: 'Grant', value: 17.7},{item: 'Kontrakt', value: 82.3}],
    angleField: 'value',
    colorField: 'item',
    radius: 0.9,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 14,
            textAlign: 'center',
        },
    },
    interactions: [
        {
            type: 'element-active',
        },
    ],
};

const DeanPanel: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const allStd = useGetAllData({url: '/students', isCall: 'auto'})
    const allTeach = useGetAllData({url: 'users?filter={%22-role_name%22:[%22student%22],%22role_name%22:%22teacher%22}&filter-like={}', isCall: 'auto'})
    const all = useGetAllData({url: '/users', isCall: 'auto'})

    return (
        <>
            <ForTitle title='Dashboard' buttons={[null]}/>
            <Row className='d-flex justify-content-between'>
                <Col lg={24} sm={24} md={24} xl={6} xs={24}>
                    <div className='forBorder mx-1'>
                        <Card bordered={false}>
                            <p className="text-truncate mb-2">{t("Students")}</p>
                            <h4 className="mb-0">{allStd.data._meta.totalCount}</h4>
                            <MdPeopleOutline className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.23% </span>
                            </div> */}
                        </Card>
                        <Card bordered={false}>
                            <p className="text-truncate mb-2">{t("Teachers")}</p>
                            <h4 className="mb-0">{allTeach.data._meta.totalCount}</h4>
                            <GiTeacher className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.03% </span>
                            </div> */}
                        </Card>
                        <Card bordered={false}>
                            <p className="text-truncate mb-2">{t("Masters")}</p>
                            <h4 className="mb-0">{all.data._meta.totalCount}</h4>
                            <GrUserWorker className="dashboard_card_icon" />
                            {/* <div className="mt-3 border-top pt-2 d-flex justify-content-between align-items-center">
                                <span style={{ fontSize: '14px' }}>{t("Comparing with the previous year")}</span>
                                <span className="badge badge-secondary bg-warning"> -0.05% </span>
                            </div> */}
                        </Card>
                    </div>
                </Col>
                <Col lg={24} sm={24} md={24} xl={18} xs={24} className='mb-3'>
                    <Row>
                        <Col lg={24} sm={24} md={24} xl={12} xs={24}>
                            {/* <Card className='mx-1' title={<h5>{t('Statistic by gender')}</h5>}>
                                <Line {...config1} />
                            </Card> */}
                            <Card className='mx-1' title={<h5>{t('Statistic by gender')}</h5>}>
                                <Pie {...config2}/>
                            </Card>
                        </Col>
                        <Col lg={24} sm={24} md={24} xl={12} xs={24}>
                            <Card className='mx-1' title={<h5>{t('Kalendar')}</h5>}>
                                <Calendar fullscreen={false} />
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col lg={24} sm={24} md={24} xl={24} xs={24}>
                    <Row>
                        {/* <Col lg={24} sm={24} md={24} xl={8}>
                            <Card className='mx-1' title={<h5>{t('Statistic by gender')}</h5>}>
                                <Pie {...config2}/>
                            </Card>
                        </Col> */}
                        <Col lg={24} sm={24} md={24} xl={12} xs={24}>
                            <Card className='mx-1' title={<h5>{t('Grantlar')}</h5>}>
                                <Pie {...config3}/>
                            </Card>
                        </Col>
                        <Col lg={24} sm={24} md={24} xl={12} xs={24}>
                            <Card className='mx-1' title={<h5>{t('Xabarlar')}</h5>}>
                                <MessageInDashboard/>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default DeanPanel;