import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import instance from "config/_axios";
import moment from "moment";
import { userAccessType1, userType, vocationType } from "pages/employee_tabel/type";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { getAllLanguages } from "services";
import { asyncN } from "utils/notifications";

type propType = {
    attr: { type: "edit" | "create" | "index", item: vocationType | null }
    userAccesses: Array<userAccessType1>
    user: userType
    refresh: boolean
    setRefresh: (refresh: boolean) => void
    setAttr: (attr: { type: "edit" | "create" | "index", item: vocationType | null }) => void
}
const VocationsCreateOrEditUi: FC<propType> = ({ attr, userAccesses, setAttr, user, refresh, setRefresh }): JSX.Element => {
    const [form] = Form.useForm();
    const { t, i18n } = useTranslation();
    const [langs, setLangs] = useState<{ all: Array<string>, noneSelected: Array<string>, selected: Array<string> }>(
        {
            all: ["uz", "ru", "en"],
            noneSelected: ["uz", "ru", "en"].filter((i: string) => i !== i18n.language),
            selected: ["uz", "ru", "en"].filter((i: string) => i === i18n.language)
        }
    );
    const [userAccessTypes, setUserAccessTypes] = useState<Array<{ id: number, name: string, url: string }>>([]);
    const [tables, setTables] = useState<Array<{ id: number, name: string }>>([])
    const [languages, setLanguages] = useState<Array<any>>([]);

    useEffect(() => {
        (
            async () => {
                const response: any = await getAllLanguages();
                setLanguages(response?.items)
            }
        )()
    }, [])


    const removeInput = (lang: string) => {
        setLangs({
            ...langs,
            noneSelected: [...langs?.noneSelected, lang],
            selected: langs?.selected?.filter((t: string) => t !== lang)
        })
    }

    const getName = (code: string) => languages?.filter((item: { name: string, lang_code: string }) => item?.lang_code === code)[0]?.name;

    const addSelectedLang = (lang: string) => setLangs({
        ...langs,
        selected: [...langs?.selected, lang],
        noneSelected: langs?.noneSelected?.filter((t: string) => t !== lang)
    })

    const setSelectedLang = (newLang: string, oldLang: string) => setLangs({
        ...langs,
        selected: langs?.selected?.map((item: string) => item !== oldLang ? newLang : item),
        noneSelected: langs?.noneSelected?.map((t: string) => t !== newLang ? oldLang : t)
    })

    const onFinish = async (values: any) => {
        try {
            let data = new FormData()
            Object.entries(values)?.forEach(([key, value]: any) => {
                if (["start_date", "finish_date"].includes(key)) {
                    data.append(key, moment(value).format("YYYY-MM-DD"))
                } else {
                    data.append(key, value)
                }
            })
            data.append("user_id", `${user?.id}`)
            const resp = await instance({
                url: `/vocations${attr?.item ? '/' + attr?.item?.id : ''}`,
                method: attr?.item ? 'put' : 'post',
                data: data
            })
            if (resp?.data?.status === 1) {
                setAttr({
                    type: "index",
                    item: null
                })
                setRefresh(!refresh)
                asyncN("success", "create", resp?.data?.message)
            } else {
                asyncN("error", "create", resp?.data?.message)
            }
        } catch (error: any) {
            asyncN("error", "create", error?.response ? error?.response?.data?.message : error?.message)
        }
    }

    useEffect(() => {
        if (attr?.item) {
            form.setFieldsValue({
                [`name[${i18n.language}]`]: attr?.item?.name,
                [`description[${i18n.language}]`]: attr?.item?.description,
                start_date: moment(attr?.item?.start_date),
                finish_date: moment(attr?.item?.finish_date)
            })
        }
    }, [])
    return (
        <div>
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={24}>
                    {
                        attr?.type === "create" ? langs?.selected?.map((item: string, i: number) =>
                            <Col key={i} span={24}>
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={8} lg={6}>
                                        <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`name[${item}]`} label={`Name (${item})`}>
                                            <Input placeholder={`Name (${item})`} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={14}>
                                        <Form.Item name={`description[${item}]`} label={`Description (${item})`}>
                                            <Input placeholder={`Description (${item})`} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4}>
                                        <div className="d-flex mt-1">
                                            <Select className="mt-4" defaultValue={item} style={{ width: '100px' }} onChange={(newElement) => { setSelectedLang(newElement, item) }
                                            }>
                                                {
                                                    langs?.all && langs?.all?.map((l: string, i: number) =>
                                                        !langs?.selected.includes(l) || l === item ? <Select.Option key={i} value={l}>{getName(l)}</Select.Option> : ''
                                                    )
                                                }
                                            </Select>
                                            <Button className="mt-4 ms-1" type="primary" shape="circle" onClick={() => removeInput(item)} disabled={langs?.selected?.length === 1} >
                                                <FaTimes />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        ) :
                            <>
                                <Col xs={24} sm={24} md={9} lg={7}>
                                    <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`name[${i18n.language}]`} label={`Name`}>
                                        <Input placeholder={`Name`} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={17}>
                                    <Form.Item name={`description[${i18n.language}]`} label={`Description`}>
                                        <Input placeholder={`Description`} />
                                    </Form.Item>
                                </Col>
                            </>
                    }
                    {attr.type === "create" ? <Col span={24}>
                        <Button onClick={() => addSelectedLang(langs?.noneSelected[0])} disabled={langs?.noneSelected?.length === 0} className="m-0 p-0" type="link">+ Til qo'shish</Button>
                        <hr />
                    </Col> : ''}
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <Form.Item name="start_date">
                            <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" placeholder="Select start vocation date!" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <Form.Item name="finish_date">
                            <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" placeholder="Select finish vocation date!" />
                        </Form.Item>
                    </Col>
                    <Col className="d-flex justify-content-end" span={24}>
                        <Button onClick={() => {
                            form.resetFields()
                            setAttr({ type: "index", item: null })
                        }} danger type="primary">Bekor qilish</Button>
                        <Button className="mx-1" htmlType="reset">Tozalash</Button>
                        <Button type="primary" htmlType="submit">Saqlash</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default VocationsCreateOrEditUi