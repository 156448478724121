import { Button, Col, Modal, Row } from "antd";
import BadgeStatus from "components/BadgeStatus";
import useGetOneData from "hooks/useGetOneData";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ViewTopic: FC<{ id: number, visible: boolean, setVisible: (visible: boolean) => void, setId: (id: number|null) => void }> = ({ id, visible, setVisible,setId }): JSX.Element => {
    const { t } = useTranslation()
    const [detail, setDetail] = useState<boolean>(false)

    const {data, fetch} = useGetOneData({url: `/subject-topics/${id}?expand=subject,lang,updatedBy,createdBy`})

    useEffect(() => {

        if(visible){
            fetch(`/subject-topics/${id}?expand=subject,lang,updatedBy,createdBy`)
        }
    }, [id])


    return (
        <Modal visible={visible} onCancel={() => {
            setVisible(false)
            // setId(null)
        }} title={"View subject topic"} footer={false}>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="d-flex m-2">
                        <strong>{t("Name")}: </strong>
                        <span className="ms-2">{data?.name}</span>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <div className="d-flex m-2">
                        <strong>{t("Subject")}: </strong>
                        <span className="ms-2">{data?.subject?.name}</span>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="d-flex m-2">
                        <strong>{t("Language")}: </strong>
                        <span className="ms-2">{data?.lang?.name}</span>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="d-flex m-2">
                        <strong>{t("Hours")}: </strong>
                        <span className="ms-2">{data?.hours}</span>
                    </div>
                </Col>


                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="d-flex m-2">
                        <strong>{t("Status")}: </strong>
                        <span className="ms-2">{data?.status !== undefined && <BadgeStatus status={String(data?.status)} />}</span>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="d-flex m-2">
                        <strong>{t("Craeted at")}: </strong>
                        <span className="ms-2">{moment(data?.created_at).format("DD-MM-YYYY HH:mm")}</span>
                    </div>
                </Col>
                <Col span={24}>
                    <div className="d-flex m-2">
                        <strong>{t("Description")}: </strong>
                        <span className="ms-2">{data?.description}</span>
                    </div>
                </Col>
                {detail && <Col span={24} style={{ borderTop: "1px solid" }}>
                    <Row gutter={24}>
                        <Col span={24} className="pt-2">
                            <strong className="m-2">{t("Created by")}</strong>
                        </Col>
                        <Col span={24}>
                            <div className="d-flex m-2">
                                <strong>{t("First name")}: </strong>
                                <span className="ms-2">{data?.createdBy?.first_name}</span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="d-flex m-2">
                                <strong>{t("Last name")}: </strong>
                                <span className="ms-2">{data?.createdBy?.last_name}</span>
                            </div>
                        </Col>
                    </Row>
                    {data?.updatedBy && <Row gutter={24}>
                        <Col span={24} className="pt-2">
                            <strong className="m-2">{t("Updated by")}</strong>
                        </Col>
                        <Col span={24}>
                            <div className="d-flex m-2">
                                <strong>{t("First name")}: </strong>
                                <span className="ms-2">{data?.updatedBy?.first_name}</span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="d-flex m-2">
                                <strong>{t("Last name")}: </strong>
                                <span className="ms-2">{data?.updatedBy?.last_name}</span>
                            </div>
                        </Col>
                    </Row>}
                </Col>}
                <Col span={24} className="d-flex justify-content-end">
                    <Button onClick={() => setDetail(!detail)} type="link">{detail ? t("Close") : t("Details")}</Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default ViewTopic