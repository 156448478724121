
import { Dispatch, FC, SetStateAction } from 'react';
import { Button, DatePicker, Form, message, Modal, TimePicker } from 'antd';
import instance from 'config/_axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { asyncN } from 'utils/notifications';



interface IPropsUpdateExamStudents {
    visible: boolean,
    setVisible: Dispatch<SetStateAction<boolean>>,
    id: number ,
    refetch: boolean,
    setRefetch: Dispatch<SetStateAction<boolean>>
}


const UpdateExamStudents: FC<IPropsUpdateExamStudents> = ({ visible, setVisible, id, refetch, setRefetch }): JSX.Element => {

    const { t } = useTranslation();
    const [form] = Form.useForm();



    const _onSubmit = async (values: any) => {
        const { duration, finish } = values;
        if (id && (duration || finish)) {
            const formdata = new FormData();
            if (finish) {
                formdata.append("finish", moment(finish).format("YYYY-MM-DD HH:mm:ss"));
            }
            if (duration) {
                formdata.append("duration", moment(duration).format("HH:mm"));
            }
            const response = await instance({ url: `/exam-students/${id}`, method: "PUT", data: formdata });
            if (response.data?.status === 1) {
                asyncN("success", 'create', response.data?.message).then(() => { form.resetFields(); setRefetch(!refetch) })
            } else {
                asyncN("error", 'update', response.data?.message).then(() => form.resetFields())
            }
        } else {
            message.warning("Ma'lumot yetarli emas !");
        }
    }

    return (

        <Modal
            title="Student imtihon ma'lumotini o'zgartirish"
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={_onSubmit}
            >
                <Form.Item
                    label="Imtihon tugash sanasini o'zgartirish"
                    name="finish"
                >
                    <DatePicker
                        placeholder={t("Select date")}
                        showNow={false}
                        className="w-100"
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                    />
                </Form.Item>
                <Form.Item
                    label="Imtihon davomiyligi vaqtini o'zgartirish"
                    name="duration"
                >
                    <TimePicker placeholder={t("Select duration")} className="w-100" format="HH:mm" showNow={false} />
                </Form.Item>
                <div className='text-end mt-3'>
                    <Button type="primary" htmlType='submit'>Saqlash</Button>
                </div>
            </Form>
        </Modal>

    )

}


export default UpdateExamStudents;