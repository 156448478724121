import { FC } from "react";
import "./styles.scss";






export const Loading: FC = (): JSX.Element => {


    return (
        <div className="login_loader">
            <div className="continuous-1">
                TEXNIKUM
            </div>
        </div>
    )

}