import { Form } from "antd";
import instance from "config/_axios";
import { FC } from "react";
import { asyncN } from "utils/notifications";
import { dailyHoursType, visibleType } from "../types";
import UiForms from "./ui";
type propType = {
    visible: visibleType,
    setVisible: (visible: visibleType) => void,
    refresh: boolean,
    setRefresh: (refresh: boolean) => void
}
const CreateAndUpdateWorkRate: FC<propType> = ({ visible, setVisible, refresh, setRefresh }): JSX.Element => {
    const [form] = Form.useForm()
    const onFinish = async (values: any) => {
        try {
            let data = new FormData();
            let dayHours: dailyHoursType = {};
            Object.entries(values)?.map(([key, value]: any) => {
                if (value !== null) {
                    if (key == "status") {
                        data?.append(key, value ? "1" : "0");
                    } else if (key?.startsWith("day_hour")) {
                        let sp = key?.split("-");
                        dayHours = { ...dayHours, [`${sp[1]}`]: value }
                    } else {
                        data.append(key, value)
                    }
                }
            })
            data.append("daily_hours", JSON.stringify(dayHours))
            const resp = await instance({
                url: `/work-rates${visible?.item ? '/' + visible?.item?.id : ''}`,
                method: visible?.item ? "put" : "post",
                data: data
            })
            if (resp?.data?.status === 1) {
                asyncN("success", visible?.item ? 'update' : 'create', resp?.data?.message).then(() => {
                    setRefresh(!refresh)
                    setVisible({ item: null, type: "hide" })
                })
            } else {
                asyncN("error", visible?.item ? 'update' : 'create', resp?.data?.message);
            }
        } catch (error: any) {
            asyncN("error", visible?.item ? 'update' : 'create', error?.response ? error?.response?.data?.message : error?.message);
        }

    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            <UiForms visible={visible} setVisible={setVisible} form={form} />
        </Form>
    )
}

export default CreateAndUpdateWorkRate