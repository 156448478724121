import { Skeleton } from 'antd';
import React, { FC, useEffect, useState } from 'react';

const TableLoading: FC<{ row: number, col: number }> = ({ row, col }): JSX.Element => {
    const [rows, setRows] = useState<Array<number>>([])
    const [cols, setCols] = useState<Array<number>>([])

    useEffect(() => {
        let rowsMake: Array<number> = [], colsMake: Array<number> = []
        for (let i = 0; i < row; i++) {
            rowsMake.push(i)
        }
        for (let i = 0; i < col; i++) {
            colsMake.push(i)
        }
        setCols(colsMake);
        setRows(rowsMake);
    }, [])
    return (
        <table className='table'>
            <tbody>
                <tr>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                    <td>
                        <div className="p-1">
                            <Skeleton.Input active={true} size="default" block={true} />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default TableLoading