import { FC, useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router';
import CustomBreadcrumb from "components/Breadcrumb";
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { getAllSubjectTypes } from "../../subject_type/service";
import { getAllSubjectCategories } from "../../subject_category/service";
import { getAllExamTypes } from "../../exam_types/service";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { useTranslation } from "react-i18next";
import isHasAuthRoute from "utils/isHasRoute";

const AttachSillabus: FC = (): JSX.Element => {
    const _breadcrump = [
        {
            name: "Home",
            path: '/',
        },
        {
            name: "Subjects",
            path: 'subjects'
        },
        {
            name: "Attach sillabus",
            path: 'subject_sillabus'
        },
    ];

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const location: any = useLocation();
    const [loading, setLoading] = useState<boolean>(false)
    const history = useHistory();
    const [checkboxes, setCheckboxes] = useState<Array<string>>([]);
    const [checkboxes1, setCheckboxes1] = useState<Array<string>>([]);
    const [totalBall, setTotalBall] = useState<number>(0);
    const [totalTime, setTotalTime] = useState<number>(0);
    const [subjectTypes, setSubjectTypes] = useState<Array<{ id: number, name: string }>>();
    const [subjectCategories, setSubjectCategories] = useState<Array<{ id: number, name: string }>>();
    const [examTypes, setExamTypes] = useState<Array<{ id: number, name: string }>>();
    const [a, setA] = useState<boolean>(false);

    console.log(location);
    

    const onFinish = async (values: any) => {
        let eset = {};
        let essct = {};
        let data = new FormData();
        Object.entries(values).forEach(([key, value]: any) => {

            if (value) {
                if (key.startsWith("item-")) {
                    let strings = key.split("-");
                    essct = { ...essct, [strings[1]]: value };
                } else if (key.startsWith("item1-")) {
                    let strings = key.split("-");
                    eset = { ...eset, [strings[1]]: value };
                } else {
                    data.append(key, value)
                }
            }
        });
        data.append("edu_semestr_exams_types", JSON.stringify(eset));
        data.append("edu_semestr_subject_category_times", JSON.stringify(essct));
        try {
            const resp = await instance({
                method: location?.state?.sillabus ? 'put' : 'post',
                url: location?.state?.sillabus ? '/subject-sillabuses/' + location?.state?.sillabus?.id : '/subject-sillabuses',
                data: data
            });
            if (resp.data.status === 1) {
                if (!location?.state?.sillabus) {
                    asyncN("success", "create", resp?.data?.message).then((e) => {
                        history.push("/subjects");
                    });
                } else {
                    asyncN("success", "update", resp?.data?.message).then((e) => {
                        history.push("/subjects");
                    });
                }
            }
        } catch (e) {
            asyncN("error", "create", "Error for sillabus")
        }
    };

    useEffect(() => {

        (

            async () => {
                setLoading(true);
                const response = await getAllSubjectTypes();
                setSubjectTypes(response?.items);
                const subjectCategories = await getAllSubjectCategories();
                setSubjectCategories(subjectCategories?.items);
                const examTypesRes = await getAllExamTypes();
                setExamTypes(examTypesRes?.items);
                setLoading(false)
            }

        )();
    }, []);

    useEffect(() => {
        if (location?.state?.sillabus) {
            let a1: any = {};
            let checks: Array<string> = [];
            let checks1: Array<string> = [];
            Object.entries(location?.state?.sillabus).forEach(([key, value]: any) => {
                if (key === "edu_semestr_exams_types") {
                    const eset = JSON.parse(value);
                    Object.entries(eset).forEach(([key1, value1]: any) => {
                        a1 = { ...a1, ["item1-" + key1]: value1 }
                        checks1 = [
                            ...checks1,
                            "item1-" + key1
                        ]
                    })
                } else if (key === "edu_semestr_subject_category_times") {
                    const essct = JSON.parse(value);
                    Object.entries(essct).forEach(([key1, value1]: any) => {
                        a1 = { ...a1, ["item-" + key1]: value1 };
                        checks = [
                            ...checks,
                            "item-" + key1
                        ]
                    })
                } else {
                    a1 = { ...a1, [key]: value }
                }
            });

            setCheckboxes(checks);
            setCheckboxes1(checks1);
            form.setFieldsValue(a1);
            setA(!a);
        }
    }, [subjectCategories, examTypes]);

    useEffect(() => {
        let totalBall1 = 0;
        let totalTime1 = 0;
        if (subjectCategories) {
            subjectCategories.map((item: { id: number, name: string }) => {
                if (form.getFieldValue("item-" + item?.id)) {
                    totalTime1 += parseInt(form.getFieldValue("item-" + item?.id));
                }
            })
        }
        if (examTypes) {
            examTypes.map((item: { id: number, name: string }) => {
                if (form.getFieldValue("item1-" + item?.id)) {
                    totalBall1 += parseInt(form.getFieldValue("item1-" + item?.id));
                }
            })
        }
        setTotalTime(totalTime1);
        setTotalBall(totalBall1);
        form.setFieldsValue({
            all_ball_yuklama: totalTime1, max_ball: totalBall1
        });
    }, [a]);

    return (
        <>
            <CustomBreadcrumb arr={_breadcrump} />

            <Form
                form={form}
                className="pt-3"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    subject_id: location?.state?.id
                }}
            >
                {loading ? (
                    <div className="loader-line"></div>
                ) : (
                    <div style={{ height: "3px" }}></div>
                )}
                <Row gutter={24} className="pt-2">
                    <Col xs={24} sm={24} md={6} lg={5}>
                        <Form.Item
                            name="subject_id"
                            label={t("Subject name")}
                            rules={[
                                {
                                    required: true,
                                    message: "Subject name"
                                }
                            ]}
                        >
                            <Select >
                                <Select.Option value={location?.state?.id}>{location?.state?.name ? location?.state.name : "Fan nomi yo'q"}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={5}>
                        <Form.Item
                            name="subject_type_id"
                            label={t("Subject type")}
                            rules={[
                                {
                                    required: true,
                                    message: t("Fill in the field!")
                                }
                            ]}
                        >
                            <Select placeholder={t("Select subject type") + " ... "}>
                                {
                                    subjectTypes && subjectTypes.map((item: { id: number, name: string }, i) =>
                                        <Select.Option key={i} value={item?.id}>{item?.name}</Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={5}>
                        <Form.Item
                            name="credit"
                            label={t("Credit")}
                            rules={[
                                {
                                    required: true,
                                    message: t("Fill in the field!")
                                }
                            ]}
                        >
                            <Input placeholder={t("Enter credit") + " ... "} type="number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={5}>
                        <Form.Item
                            name="all_ball_yuklama"
                            label={t("Total hour")}
                            rules={[
                                {
                                    required: false,
                                    message: "Subject name"
                                }
                            ]}
                        >
                            <Input value={totalTime} placeholder="0" readOnly={true} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={4}>
                        <Form.Item
                            name="max_ball"
                            label={t("Max ball")}
                            rules={[
                                {
                                    required: false,
                                    message: "Subject name"
                                }
                            ]}
                        >
                            <Input value={totalBall} placeholder="0" readOnly={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr className="mt-1" />
                <Row gutter={24}>
                    <Col span={24}>
                        <p style={{ fontSize: "16px", fontWeight: 600 }}>{t("distribution of total points")}</p>
                    </Col>
                </Row>
                {examTypes && <Row gutter={24}>

                    {
                        examTypes.map((item: {
                            id: number, name: string
                        }, i: number) =>
                            <Col key={i} xs={12} sm={8} md={4} lg={4}>
                                <Checkbox checked={checkboxes1.includes("item1-" + item?.id)} onChange={(e) => {
                                    let s = e?.target?.checked;
                                    setA(!a);
                                    if (s) {
                                        setCheckboxes1([
                                            ...checkboxes1,
                                            "item1-" + item?.id
                                        ]
                                        )
                                    } else {
                                        setCheckboxes1([
                                            ...checkboxes1.filter((itemC: string) => {
                                                return itemC !== "item1-" + item?.id && itemC;
                                            }),
                                        ]
                                        )
                                        form.setFieldsValue({
                                            ["item1-" + item?.id]: ""
                                        })
                                    }
                                }}>
                                    {item.name}
                                </Checkbox>
                                <Form.Item rules={[
                                    {
                                        required: checkboxes1.includes("item1-" + item?.id),
                                        message: "Please input " + item?.name
                                    }
                                ]} className="pt-1" name={"item1-" + item.id}>
                                    <Input onBlur={(e: any) => {
                                        setA(!a)
                                    }} maxLength={3} type="number"
                                        disabled={!checkboxes1.includes("item1-" + item?.id)}
                                        placeholder={t(item?.name)} />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>}
                <hr />
                <Row gutter={24}>
                    <Col span={24}>
                        <p style={{ fontSize: "16px", fontWeight: 600 }}>{t("Distribution of lesson hours")}</p>
                    </Col>
                </Row>
                {subjectCategories && <Row gutter={24}>
                    {
                        subjectCategories.map((item: {
                            id: number, name: string
                        }, i: number) =>
                            <Col key={i} xs={12} sm={8} md={4} lg={4}>
                                <Checkbox checked={checkboxes.includes("item-" + item?.id)} onChange={(e) => {
                                    let s = e?.target?.checked;
                                    setA(!a);
                                    if (s) {
                                        setCheckboxes([
                                            ...checkboxes,
                                            "item-" + item?.id
                                        ]
                                        )
                                    } else {
                                        setCheckboxes([
                                            ...checkboxes.filter((itemC: string) => {
                                                return itemC !== "item-" + item?.id && itemC;
                                            }),
                                        ]
                                        )
                                        form.setFieldsValue({
                                            ["item-" + item?.id]: ""
                                        })
                                    }
                                }}>
                                    {item.name}
                                </Checkbox>
                                <Form.Item rules={[
                                    {
                                        required: checkboxes.includes("item-" + item?.id),
                                        message: "Please input " + item?.name
                                    }
                                ]} className="pt-1" name={"item-" + item.id}>
                                    <Input onBlur={(e: any) => {
                                        setA(!a)
                                    }} maxLength={3} type="number" disabled={!checkboxes.includes("item-" + item?.id)}
                                        placeholder={t(item?.name)} />
                                </Form.Item>
                            </Col>
                        )
                    }
                </Row>}
                <hr />
                {(isHasAuthRoute("subject-sillabus_update") || isHasAuthRoute("subject-sillabus_create")) && <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item>
                            <Button style={{ float: "right" }} className="float-end m-1" type="primary"
                                htmlType="submit">{t("Save")}</Button>
                            <Button style={{ float: "right" }} className="float-end m-1" type="default" onClick={() => {
                                history.push("/subjects")
                            }}>{t("Cancel")}</Button>
                        </Form.Item>
                    </Col>
                </Row>}
            </Form>
        </>
    )
};

export default AttachSillabus