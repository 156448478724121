import styled, { keyframes } from "styled-components";

const icon_infinite = keyframes`
    0%{
        top:-4px;
        right: -4px;
        opacity:1;
    }
    55%{
        top:-10px;
        right: -10px;
        opacity:0.8;
    }
    100%{
        top:-12px;
        right: -12px;
        opacity:0;
    }
`

export const ItemUi = styled.div<{ color: string, active: boolean }>`

    width: 90%;
    margin: 10px auto;
    border-radius: 4px;
    background-color:${props => props.active ? "#1890FF" : "#FFFFFF"};
    color:${props => props.active ? "#ffffff" : ""};
    padding: 2px 6px;
    cursor:pointer;
    position:relative;

    &:hover {
        background-color:${props => !props.active ? '#e6f7ff' : '#40a9ff'};
    }

    & > svg {
        position:absolute;
        top:-4px;
        right: -4px;
        transform:rotate(-45deg);
        color:#096dd9;
        animation: ${icon_infinite} 0.5s infinite;
    }


    & > p {
        padding:0;
        margin:0;
        font-weight: 450;
        color:${props => props.active ? "#ffffff" : "#595959"};
        font-size: 13px;
        text-transform: capitalize;
    }
    & > span{
        display:flex;
        align-items:center;
        font-size: 11px;

        .message_status_icon {
            display:inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: ${props => props.color};
        }
    }


`

