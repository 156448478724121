import store from "store";


const isRole = (role: string | string[]): boolean => {

    const auth: any = store.getState().auth;
    if (Array.isArray(role) && role.length) {
        let isHas = false;
        for (let i = 0; i < role.length; i++) {
            if (auth?.user?.role?.includes(role[i])) {
                isHas = true;
                break
            }
        }
        return isHas;
    }
    return auth?.user?.role?.includes(role);
}


export default isRole;