import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import instance from "config/_axios";
import { getUserAccessTypes } from "pages/user/servise";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { getAllLanguages } from "services";
import { asyncN } from "utils/notifications";
import { visibleType } from "../types";

type propType = { form: any, visible: visibleType, setVisible: (visible: visibleType) => void, type?: boolean }
const UiForms: FC<propType> = ({ form, visible, setVisible, type }): JSX.Element => {

    const { t, i18n } = useTranslation();
    const [langs, setLangs] = useState<{ all: Array<string>, noneSelected: Array<string>, selected: Array<string> }>(
        {
            all: ["uz", "ru", "en"],
            noneSelected: ["uz", "ru", "en"].filter((i: string) => i !== i18n.language),
            selected: ["uz", "ru", "en"].filter((i: string) => i === i18n.language)
        }
    );
    const [userAccessTypes, setUserAccessTypes] = useState<Array<{ id: number, name: string, url: string }>>([]);
    const [tables, setTables] = useState<Array<{ id: number, name: string }>>([])
    const [languages, setLanguages] = useState<Array<any>>([]);

    useEffect(() => {
        (
            async () => {
                const response: any = await getAllLanguages();
                setLanguages(response?.items)
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                const response: any = await getUserAccessTypes();
                setUserAccessTypes(response?.items)
            }
        )()
    }, [])

    const removeInput = (lang: string) => {
        setLangs({
            ...langs,
            noneSelected: [...langs?.noneSelected, lang],
            selected: langs?.selected?.filter((t: string) => t !== lang)
        })
    }

    const getName = (code: string) => languages?.filter((item: { name: string, lang_code: string }) => item?.lang_code === code)[0]?.name;

    const addSelectedLang = (lang: string) => setLangs({
        ...langs,
        selected: [...langs?.selected, lang],
        noneSelected: langs?.noneSelected?.filter((t: string) => t !== lang)
    })

    const setSelectedLang = (newLang: string, oldLang: string) => setLangs({
        ...langs,
        selected: langs?.selected?.map((item: string) => item !== oldLang ? newLang : item),
        noneSelected: langs?.noneSelected?.map((t: string) => t !== newLang ? oldLang : t)
    })

    const getAccess = async (id: number) => {
        if (id) {
            try {
                let url = userAccessTypes?.filter((item: { id: number, url: string }) => item?.id === id)[0]?.url;
                const resp = await instance({
                    method: 'get',
                    url: `/${url}?per-page=0`
                })
                if (resp?.data?.status === 1) {
                    setTables(resp?.data?.data?.items)
                } else {
                    asyncN("error", "read", resp?.data?.message);
                }
            } catch (error: any) {
                asyncN("error", "read", error?.response ? error?.response?.data?.message : error?.message);
            }
        }
    }

    useEffect(() => {
        if (visible?.item && userAccessTypes?.length > 0) {
            getAccess(visible?.item?.user_access_type_id)
            form?.setFieldsValue({
                [`name[${i18n.language}]`]: visible?.item?.name,
                [`description[${i18n.language}]`]: visible?.item?.description,
                is_leader: visible?.item?.status === 1,
                user_access_type_id: visible?.item?.user_access_type_id,
                table_id: visible?.item?.table_id
            })
        }
    }, [visible?.item, userAccessTypes]) 

    return (
        <Row gutter={24}>
            {
                !visible?.item ? langs?.selected?.map((item: string, i: number) =>
                    <Col key={i} span={24}>
                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={8} lg={6}>
                                <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`name[${item}]`} label={`Name (${item})`}>
                                    <Input placeholder={`Name (${item})`} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={14}>
                                <Form.Item name={`description[${item}]`} label={`Description (${item})`}>
                                    <Input placeholder={`Description (${item})`} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={4} lg={4}>
                                <div className="d-flex mt-1">
                                    <Select className="mt-4" defaultValue={item} style={{ width: '100px' }} onChange={(newElement) => { setSelectedLang(newElement, item) }
                                    }>
                                        {
                                            langs?.all && langs?.all?.map((l: string, i: number) =>
                                                !langs?.selected.includes(l) || l === item ? <Select.Option key={i} value={l}>{getName(l)}</Select.Option> : ''
                                            )
                                        }
                                    </Select>
                                    <Button className="d-flex align-items-center justify-content-center mt-4 ms-1" shape="circle" onClick={() => removeInput(item)} disabled={langs?.selected?.length === 1} >
                                        <FaTimes/>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                ) :
                    <>
                        <Col xs={24} sm={24} md={9} lg={7}>
                            <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={`name[${i18n.language}]`} label={`Name`}>
                                <Input placeholder={`Name`} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={15} lg={17}>
                            <Form.Item name={`description[${i18n.language}]`} label={`Description`}>
                                <Input placeholder={`Description`} />
                            </Form.Item>
                        </Col>
                    </>
            }

            {!visible?.item ? <Col span={24}>
                <Button onClick={() => addSelectedLang(langs?.noneSelected[0])} disabled={langs?.noneSelected?.length === 0} className="m-0 p-0" type="link">+ Til qo'shish</Button>
                <hr />
            </Col> : ''}
            <Col xs={24} sm={24} md={6} lg={4}>
                <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={"user_access_type_id"} label="User access type">
                    <Select allowClear onClear={() => {
                        setTables([])
                        form?.setFieldsValue({
                            table_id: null
                        })
                    }} onChange={(e) => {
                        getAccess(e);
                        form?.setFieldsValue({
                            table_id: null
                        })
                    }} placeholder="User access type">
                        {
                            userAccessTypes?.map((item: { id: number, name: string }, i: number) =>
                                <Select.Option value={item?.id} key={i}>{item?.name}</Select.Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={18} lg={20}>
                <Form.Item rules={[{ required: true, message: "Fill in the field!" }]} name={"table_id"} label="User access">
                    <Select placeholder="User access">
                        {
                            tables?.map((item: { id: number, name: string }, i: number) =>
                                <Select.Option value={item?.id} key={i}>{item?.name}</Select.Option>
                            )
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item valuePropName="checked" name={"is_leader"} label="Is leader">
                    <Switch />
                </Form.Item>
            </Col>
            <Col className="d-flex justify-content-end" span={24}>
                <Button className="m-1" type="primary" danger htmlType="button" onClick={() => setVisible({ item: null, type: "hide" })}>Bekor qilish</Button>
                <Button className="m-1" htmlType="reset">Tozalash</Button>
                <Button className="m-1" type="primary" htmlType="submit">Saqlash</Button>
            </Col>
        </Row>
    )
}

export default UiForms;