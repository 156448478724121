import { Switch, Route, Redirect } from "react-router-dom";
import { private_routes, public_routes } from ".";
import isHasAuthRoute from "utils/isHasRoute";
import NotFoundPage from "pages/additional_pages/Page_404";
import RenderComponent from "./RenderRoutes";
import { useAppSelector } from "store/services";

const RoutesMiddleware = () => {

    const auth: any = useAppSelector(state => state.auth);


    if (auth.isAuthenticated) {
        return (
            <Switch>
                {
                    private_routes.length && private_routes.map((element, index) => {
                        if (element.submenu.length) {
                            return element.submenu.map((childElement, childIndex) => {
                                if (isHasAuthRoute(childElement.config.key) || childElement.config.key === 'unlock') {
                                    return (
                                        <RenderComponent
                                            key={childIndex}
                                            path={`${childElement.path}`}
                                            component={childElement.component}
                                            structure={childElement.config.structure}
                                            exact={childElement.config.exact}
                                        />
                                    )
                                }
                            })
                        } else {
                            if (isHasAuthRoute(element.config.key) || element.config.key === 'unlock') {
                                return (
                                    <RenderComponent
                                        key={index}
                                        path={element.path}
                                        component={element.component}
                                        structure={element.config.structure}
                                        exact={element.config.exact}
                                    />
                                )
                            }
                        }
                    })
                }
                <Route component={NotFoundPage} />
            </Switch>
        )
    }

    return (
        <Switch>
            {
                public_routes.length && public_routes.map((element, index) => {
                    if (element.submenu.length) {
                        element.submenu.map((childElement, childIndex) => {
                            return (
                                <RenderComponent
                                    key={childIndex}
                                    path={childElement.path}
                                    component={childElement.component}
                                    structure={childElement.config.structure}
                                    exact={childElement.config.exact}
                                />
                            )
                        })
                    } else {
                        return (
                            <RenderComponent
                                key={index}
                                path={element.path}
                                component={element.component}
                                structure={element.config.structure}
                                exact={element.config.exact}
                            />
                        )
                    }
                })
            }
            <Redirect to={!localStorage.getItem('access_token') ? '/' : window.location.pathname} />
        </Switch>
    )
}

export default RoutesMiddleware;