import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const SocialCategory: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="social-categories"
                indexTitle="Ijtimoiy toifa"
                viewTitle="Ijtimoiy toifa"
                editTitle="Ijtimoiy toifani tahrirlash"
                createTitle="Ijtimoiy toifa yaratish"
                permissions={{
                    view_: "social-category_view",
                    delete_: "social-category_delete",
                    update_: "social-category_update",
                    create_: "social-category_create",
                }}
            />
        </div>
    )
}

export default SocialCategory;
