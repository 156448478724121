/** @format */

import { message } from "antd";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";

export const getOneRegion = async (id: string) => {
  try {
    const res = await instance({ url: `regions/${id}`, method: "get" });
    return res.data;
  } catch (error: any) {
    message.error(error?.response?.message);
  }
};
export const getOneArea = async (id: string) => {
  try {
    const res = await instance({ url: `areas/${id}?expand=region`, method: "get" });
    if(res?.data?.status === 1){
      return res.data?.data;
    }else{
      asyncN('error', 'read',"Xatolik yuz berdi, qayta urinib ko'ring");
    }
  } catch (error: any) {
    message.error(error?.response?.message);
  }
};
