import Pagination from 'antd/es/pagination';
import Tag from 'antd/es/tag';
import EmptyTable from 'components/EmptyTable';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

interface ForTableType {
    datas: any, 
    tbodys: any, 
    theads: any, 
    searchInputs?: Array<{index: number, input: any}>, 
    loading: boolean,
    totalCount: number | undefined,
    currentPage?: number,
    perPage?: number,
    setCurrentPage?: Dispatch<React.SetStateAction<number>> | (({name, value} : {name: string, value: number}) => void),
    setPerPage?: Dispatch<React.SetStateAction<number>> | (({name, value} : {name: string, value: number}) => void),
    toUrl?: boolean,
    showAllData?: boolean
}

function ForTable({datas, tbodys, theads, searchInputs, loading, totalCount, currentPage, perPage, setCurrentPage, setPerPage, toUrl, showAllData}: ForTableType): React.ReactElement{
    const {t} = useTranslation()
    const { writeToUrl } = useUrlQueryParams({});
    
    function mapper(element: any){
        return tbodys.call(element)
    }

    function inputs(){
        if(searchInputs){
            const inputs = []
            for (let index = 0; index <= theads.length; index++) {
                for (let i = 0; i < searchInputs.length; i++) {
                    const element = searchInputs[i];
                    if(element.index === index) inputs[element.index] = element.input
                    else if(inputs[index] === undefined) inputs[index] = null
                }
            }
            return inputs
        }
        return []
    }

    return (<>
        {loading ? (<div className="loader-line"></div>) : (<div style={{ height: "3px" }}></div>)}
        <div className='tableWrap '>
            <Table className="for-table table">
                <Thead>
                    <Tr>
                        <Th style={{width: 10}}>
                            №
                        </Th>
                    {
                        theads.map((item: any, i: number) => (
                            <Th key={i}>
                                {
                                    item
                                }
                            </Th>
                        ))
                    }
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr className="searchBar">
                        {
                            (searchInputs && searchInputs.length) ? inputs().map((item: any, index: any) => (
                                <Td key={index}>
                                    {
                                        item
                                    }
                                </Td>
                            ))
                            : null
                        }
                    </Tr>
                    {
                        (datas && datas.length) ? datas.map((item: any, i: number) => {
                            return (<Tr key={i}>
                                <Td>
                                    {
                                        currentPage && perPage ?
                                        i + 1 + ((currentPage - 1) * perPage)
                                        : 
                                        i + 1
                                    }
                                </Td>
                                {
                                    mapper(item).map((element: any, index: number) => (
                                        <Td key={index}>
                                            {
                                                element
                                            }
                                        </Td>
                                    ))
                                }
                            </Tr>)
                        }) : null
                    }
                </Tbody>
            </Table>
        </div>
        <EmptyTable data={datas} loading={loading} />
        {
        (datas && datas.length) ? <div className="table_bottom_footer">
            <Tag color="#F2F2F2" className="text-dark">{t("Total")}: {totalCount ? totalCount : 0} ta</Tag>
            {!showAllData ?<Pagination
                // defaultCurrent={currentPage}
                current={currentPage}
                total={totalCount}
                defaultPageSize={perPage}
                onChange={(e) => {
                    if(toUrl) {
                        // @ts-ignore
                        return writeToUrl({ name: 'currentPage', value: e })
                    }
                    // @ts-ignore
                    return setCurrentPage(e)
                }}
                showSizeChanger={true}
                onShowSizeChange={(e, pageSize) => {
                    if(toUrl){
                        // @ts-ignore
                        return writeToUrl({ name: 'perPage', value: pageSize })
                    }
                    // @ts-ignore
                    return setPerPage(pageSize)
                }}
                pageSizeOptions={['10', '15', '20', '30', '50', '100']}
            /> : null}
        </div> : null
        }
    </>)
}

export default ForTable