import { Button, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, message, Row, Select, Upload } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabelCrud";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiUpload } from "react-icons/bi";
import { FaCheck, FaTrashAlt } from "react-icons/fa";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { dummyRequest, onPreview } from "./froms/utils";

type CertificateListType = Array<{
  id: number
  certificate_name: string,
  certificate_date: string,
  certificate_place: string,
  certificate_file: string
}>
type LangCertificateListType = Array<{
  id: number
  certificate_name: string,
  certificate_type: string,
  certificate_score: string,
  certificate_file: string
}>
type DocListType = Array<{
  id: number
  doc_name: string,
  doc_description: string,
  doc_file: string
}>

type StudentCertificateType = {
  form: FormInstance<any>,
  name: string,
  label: string,
  isInput?: boolean,
  isLangCertificate?: boolean,
  isStddocument?: boolean
}

const { Option } = Select;

const StudentCertficates: FC<StudentCertificateType> = ({ form, name, label, isInput, isLangCertificate, isStddocument }) => {

  const [certificateList, setCertificateList] = useState<any>([]);
  const [fileCertificate, setFileCertificate] = useState<Array<any>>([]);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const { t } = useTranslation();
  const certficateType = [
    { id: 0, name: "Jahon" },
    { id: 1, name: "Respublika" },
    { id: 2, name: "Shahar" },
  ]
  const langCertficateType = [
    { id: 0, name: "Ingliz tili" },
    { id: 1, name: "Rus tili" },
    { id: 2, name: "Kores tili" },
    { id: 2, name: "Nemis tili" },
  ]

  const handleChangeFile = (name: string, { fileList: file }: any) => {

    if (Array.isArray(file) && file.length) {
      if (name === 'image') {
        if (file[0]?.size < 204800) {
          setFileList([{
            uid: "-1",
            name: file[0]?.originFileObj?.name,
            status: "done",
            url: window.URL.createObjectURL(file[0]?.originFileObj),
            file: file[0]?.originFileObj
          }]);
        } else {
          message.error("Rasm hajmi 200kb dan oshmasligi kerak !");
        }
      } else {
        if (file[0]?.size < 5242880) {
          setFileCertificate([{
            uid: "-1",
            name: file[0]?.originFileObj?.name,
            status: "done",
            url: window.URL.createObjectURL(file[0]?.originFileObj),
            thumbUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAllBMVEX///8Aark2icgAX7UAY7be6PMAYLXS5fKYudwAZ7gAZbfO3u76/f4AXLTt9fsGbLovgcSArddqoNHM2esAZbGlvt4AWp8pd7MfbakATIaKs9pDg8NalMsAcb2nxuO0z+cOfMMigsUAWp0AYam91eoddLhMjMjp8fgZZ6QMWZYAUZAvgL4RWpIlcasAc7oAV6V8pNJhmM2KPTNXAAAELElEQVR4nO3da3eaQBSFYYlIHLwba5oo0XptTWrj//9zxVy7ulCHwDn76NrvV13SpzPEEQErFcYYY4wxxhhjjLGLa92Q6KaGdr33MIqr5RdVW8kETXupPYpcINFgsPxhgdhOYhFfKpwvr6YGiKtQCBgMht+vrvCjOImkgMFglgrxxJXUHH0bQzhxLeb7EIL3xVpVXogdRRUhlKgjRE5UJSFwFLWEOKKaEEbUE6L2RUUhaBQ1hRiiqhAyUXWFCKKyEDBRtYX6RHWh+kTVF2qPIkCoTEQIdYkQoeq+iBFqjiJIqEhECfUmKkyoRsQJtSYqUKhERAp1JipUqDKKWKEGESxUIKKF8vsiXCg+inihNNGAUHiiWhDKjqIJoSjRhlCSaEQouC9aEcqNohmhGFFW+DOHUGqiygp/5REKEUWF881tLqLIRJUVzvINoghRUphO002uPVFkosoK57O7nMTyR1FU+EJ8esQSZYV74ubu6fetf4/bxVkJU+JwttncebcZ/qmflzAYDObz4XA29Gs2nJ+dcG/MVXh+wpxRSCGF+Cik8MvC+Oi1Ne7z4qnsy6ic99VVKGG8Wx97mWb9XRBnr5ybz75ElDBpn3ih8es1jG534PFmYlvoRqde6P51jOL+pQuDcFFpZmV9lvoLg6iTZOS8LwK0LywahRRSSCGF6b8wCjPyv22DeWHYq2XVWPkSrQvj1YHHvS+LNy98PvB4c+s5iNaFQefAPWkeLmZd6jrjrPxvTmFemE5Ul5Uv8ByEBaOQQgopPC10YZSRv9+8MB49ZFXvXMq61HUPbcj6mqbbPPFCvVPr0qXtden+MOjR2snbMe/pgf+KhvExTDfc/3akevI+Qm6a+YR7z80gv12Lsz66f/T5PHfqCWaFWlFIIYX4UEIXF834qi0Y94s2Nr2miRolbGrhdy9YiNBNS9mWFxA0hq6MTbUNj2HgVovCty9fjPx2RNSnp6j4DcxN/6XRjEIKKcQHW5cWz7YwXHaLtvU8kAFalz5knm+Yr7ofEbMu3ZayLctrmqSUbfl90Y0Rhr11u2jrvmVhEAadogWe5yrw/ZBCCvFRSOEXhXGrcFXTRzHifvF3/NrO8jt+p5RtceUNFAZRGbfj8DyHFvSXJtoW/gSceP6EHY9iSAn1opBCCvHx2zUZYTya3BRt0rW8polPnV3qk+VvuQ+e+JsvLyBqXVrGT4g3DI9h+vHpuVe0vteGgPc2OXr+rE++F6/x/ZBCCvFRSCGF+CikkEJ8FFJIIT4KKaQQH4UUUoiPQgopxEchhRTio5BCCvFRmLd2By36v2rJv39Y8bwKQq9OGWeY/ZvnmUpqRSVP0rSeqT0xHpU9hGk7Q8Rwe+pXl75Ur2pkX3StlQiwUrkeV6tZt8ZVrtWV+gX5fbVrfELjxxhjjDHGGGOMnXl/ASwK93DMPv2HAAAAAElFTkSuQmCC"
          }]);
        } else {
          message.error("Fayl hajmi 5Mb dan oshmasligi kerak !");
        }
      }
    }
  }

  const addCertificateList = () => {
    setCertificateList([
      ...certificateList,
      {
        id: certificateList?.length ? certificateList[certificateList?.length - 1]?.id + 1 : 0,
        certificate_name: form?.getFieldValue(`${name}_name`),
        certificate_date: moment(form?.getFieldValue(`${name}_date`)).format("YYYY-MM-DD"),
        certificate_place: form?.getFieldValue(`${name}_place`),
        certificate_file: form?.getFieldValue(`${name}_file`)?.file?.name,
      }
    ]);

    form.setFieldsValue({
      [`${name}_name`]: null,
      [`${name}_date`]: null,
      [`${name}_place`]: null,
      [`${name}_file`]: null,
    });

    setFileCertificate([]);
  }

  const addLangCertificateList = () => {
    setCertificateList([
      ...certificateList,
      {
        id: certificateList?.length ? certificateList[certificateList?.length - 1]?.id + 1 : 0,
        certificate_name: form?.getFieldValue(`${name}_name`),
        certificate_type: form?.getFieldValue(`${name}_type`),
        certificate_score: form?.getFieldValue(`${name}_score`),
        certificate_file: form?.getFieldValue(`${name}_file`)?.file?.name,
      }
    ]);

    form.setFieldsValue({
      [`${name}_name`]: null,
      [`${name}_type`]: null,
      [`${name}_score`]: null,
      [`${name}_file`]: null,
    });

    setFileCertificate([]);
  }

  const addDocList = () => {
    setCertificateList([
      ...certificateList,
      {
        id: certificateList?.length ? certificateList[certificateList?.length - 1]?.id + 1 : 0,
        doc_name: form?.getFieldValue(`${name}_name`),
        doc_description: form?.getFieldValue(`${name}_description`),
        doc_file: form?.getFieldValue(`${name}_file`)?.file?.name,
      }
    ]);

    form.setFieldsValue({
      [`${name}_name`]: null,
      [`${name}_description`]: null,
      [`${name}_file`]: null,
    });

    setFileCertificate([]);
  }

  const deleteCertificate = (id: number) => {
    setCertificateList([...certificateList?.filter((e: any) => e?.id !== id)])
  }

  return (
    <>
      <Divider orientation="left"> {label} </Divider>
      <Row>
        <Col span={24}>
          {!isLangCertificate ? <Row gutter={12}>
            <Col span={6}>
              <Form.Item
                label={<FormLabelCrud>{`${label} name`}</FormLabelCrud>}
                name={`${name}_name`}
                rules={[{ required: false, message: t("Fill in the field!") }]}
              >
                {!isInput ? <Select placeholder={t("Select a field")}>
                  {
                    certficateType.map((e: any, i: number) => (
                      <Option key={i} value={e.name}>{e.name}</Option>
                    ))
                  }
                </Select> :
                  <Input placeholder={t("Enter information ...")} />
                }
              </Form.Item>
            </Col>
            {!isStddocument ? <> <Col span={6}>
              <Form.Item
                label={<FormLabelCrud>{`${label} date`}</FormLabelCrud>}
                name={`${name}_date`}
                rules={[{ required: false, message: t("Fill in the field!") }]}
              >
                <DatePicker className="w-100" />
              </Form.Item>
            </Col>
              <Col span={6}>
                <Form.Item
                  label={<FormLabelCrud>{`${label} place`}</FormLabelCrud>}
                  name={`${name}_place`}
                  rules={[{ required: false, message: t("Fill in the field!") }]}
                >
                  <Input placeholder={t("Enter information ...")} />
                </Form.Item>
              </Col> </> :
              <Col span={12}>
                <Form.Item
                  label={<FormLabelCrud>{`${label} description`}</FormLabelCrud>}
                  name={`${name}_description`}
                  rules={[{ required: false, message: t("Fill in the field!") }]}
                >
                  <Input.TextArea rows={1} placeholder={t("Enter information ...")} />
                </Form.Item>
              </Col>}
            <Col span={4}>
              <Form.Item
                label={<FormLabelCrud>{`${label} file`}</FormLabelCrud>}
                name={`${name}_file`}
                rules={[{ required: false, message: t("Fill in the field!") }]}
              >
                <Upload
                  style={{ width: "100%", height: "20px" }}
                  customRequest={dummyRequest}
                  fileList={fileCertificate}
                  accept={"application/pdf,.png,.doc"}
                  onChange={(e) => { handleChangeFile("doc", e) }}
                  onPreview={onPreview}
                  onRemove={() => setFileCertificate([])}
                >
                  <Button disabled={fileCertificate?.length > 0} icon={<BiUpload className='mb-1 me-2' size={15} />} className="w-100">{t("Select file")}</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={2} className="text-center" >
              <Button className="mt-4" shape="circle" disabled={!Boolean(
                form?.getFieldValue(`${name}_name`) && (isStddocument ? form?.getFieldValue(`${name}_description`) : (form?.getFieldValue(`${name}_date`) && form?.getFieldValue(`${name}_place`))) && form?.getFieldValue(`${name}_file`)
              )} onClick={() => { isStddocument ? addDocList() : addCertificateList() }} type="primary"><FaCheck /></Button>
            </Col>
          </Row> :
            <Row gutter={12}>
              <Col span={6}>
                <Form.Item
                  label={<FormLabelCrud>{`${label} name`}</FormLabelCrud>}
                  name={`${name}_name`}
                  rules={[{ required: false, message: t("Fill in the field!") }]}
                >
                  <Select placeholder={t("Select a field")}>
                    {
                      langCertficateType.map((e: any, i: number) => (
                        <Option key={i} value={e.name}>{e.name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={<FormLabelCrud>{`${label} type`}</FormLabelCrud>}
                  name={`${name}_type`}
                  rules={[{ required: false, message: t("Fill in the field!") }]}
                >
                  <Input placeholder={t("Enter information ...")} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={<FormLabelCrud>{`${label} ball`}</FormLabelCrud>}
                  name={`${name}_score`}
                  rules={[{ required: false, message: t("Fill in the field!") }]}
                >
                  <InputNumber min={0} className="w-100" placeholder={t("Enter information ...")} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={<FormLabelCrud>{`${label} file`}</FormLabelCrud>}
                  name={`${name}_file`}
                  rules={[{ required: false, message: t("Fill in the field!") }]}
                >
                  <Upload
                    style={{ width: "100%", height: "20px" }}
                    customRequest={dummyRequest}
                    fileList={fileCertificate}
                    accept={"application/pdf,.png,.doc"}
                    onChange={(e) => { handleChangeFile("doc", e) }}
                    onPreview={onPreview}
                    onRemove={() => setFileCertificate([])}
                  >
                    <Button disabled={fileCertificate?.length > 0} icon={<BiUpload className='mb-1 me-2' size={15} />} className="w-100">{t("Select file")}</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={2} className="text-center" >
                <Button className="mt-4" shape="circle" disabled={!Boolean(
                  form?.getFieldValue(`${name}_name`) && form?.getFieldValue(`${name}_type`) && form?.getFieldValue(`${name}_score`) && form?.getFieldValue(`${name}_file`)
                )} onClick={addLangCertificateList} type="primary"><FaCheck /></Button>
              </Col>
            </Row>}
        </Col>
        {certificateList?.length ? <Col span={22}>
          <Table id="tech-companies-1" className="table for-table">
            <Thead>
              <Tr>
                <Th className="py-1 px-2 bg-light" >{t("Name")}</Th>
                {!isStddocument ? <>
                  <Th className="py-1 px-2 bg-light" >{t(isLangCertificate ? "Type" : "Date")}</Th>
                  <Th className="py-1 px-2 bg-light" >{t(isLangCertificate ? "Ball" : "Place")}</Th>
                </> : <Th className="py-1 px-2 bg-light" >{t("Description")}</Th>}
                <Th className="py-1 px-2 bg-light" >{t("File")}</Th>
                <Th className="py-1 px-2 bg-light" >{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                isLangCertificate && certificateList?.length ? (certificateList as LangCertificateListType)?.map((element, i) => (
                  <Tr key={i}>
                    <Td className="py-1 px-2" >{element?.certificate_name}</Td>
                    <Td className="py-1 px-2" >{element?.certificate_type}</Td>
                    <Td className="py-1 px-2" >{element?.certificate_score}</Td>
                    <Td className="py-1 px-2" >{element?.certificate_file}</Td>
                    <Td className="py-1 px-2 text-center" style={{ width: 60 }} ><FaTrashAlt size={16} color="red" onClick={() => deleteCertificate(element.id)} /> </Td>
                  </Tr>
                )) :
                  isStddocument && certificateList?.length ? (certificateList as DocListType)?.map((element, i) => (
                    <Tr key={i}>
                      <Td className="py-1 px-2" >{element?.doc_name}</Td>
                      <Td className="py-1 px-2" >{element?.doc_description}</Td>
                      <Td className="py-1 px-2" >{element?.doc_file}</Td>
                      <Td className="py-1 px-2 text-center" style={{ width: 60 }}><FaTrashAlt size={15} color="red" onClick={() => deleteCertificate(element.id)} /> </Td>
                    </Tr>
                  )) :
                    certificateList?.length ? (certificateList as CertificateListType)?.map((element, i) => (
                      <Tr key={i}>
                        <Td className="py-1 px-2" >{element?.certificate_name}</Td>
                        <Td className="py-1 px-2" >{element?.certificate_date}</Td>
                        <Td className="py-1 px-2" >{element?.certificate_place}</Td>
                        <Td className="py-1 px-2" >{element?.certificate_file}</Td>
                        <Td className="py-1 px-2 text-center" style={{ width: 60 }}><FaTrashAlt size={15} color="red" onClick={() => deleteCertificate(element.id)} /> </Td>
                      </Tr>
                    )) : null
              }
            </Tbody>
          </Table>
        </Col> : null}
      </Row>
    </>
  )
}

export default StudentCertficates;