import instance from "config/_axios";
import { message } from 'antd';
export const getAllTeachers = async (prefix: string) => {
    try {
        const response = await instance({
            method: 'get',
            url: `/users?expand=profile,userAccess&sort=-id${prefix ? prefix : ''}`
        })
        if (response?.data?.status === 1) {
            return response?.data
        } else {
            return response?.data
        }

    } catch (e: any) {
        return e;
    }
}
export const getOneTeachers = async (id: string) => {
    try {
        const response = await instance({
            method: 'get',
            url: `/users/${id}?expand=profile`
        })
        if (response?.data?.status === 1) {
            return response?.data
        } else {
            return response?.data
        }

    } catch (e: any) {
        return e;
    }
}


export const getOneTeacher = async (id: number | null, path_prefix?: string) => {
    try {
        if (!id) {
            return new Error("Id bo'yicha ma'lumot olinmadi !");
        }

        const response = await instance({ url: `/users/${id}${path_prefix ? '?' + path_prefix : ''}`, method: 'GET' })

        if (response.data?.status === 1) {
            return response.data?.data
        } else {
            return new Error(response.data?.message)
        }
    } catch (error: any) {
        message.error(error?.response?.message)
    }
}

export const getTeacherAccessByUserId = async (id: number) => {
    try {
        const response = await instance({
            method: 'get',
            url: `teacher-accesses?filter={"user_id":${id}}&expand=subject`
        })
        if (response?.data?.status === 1) {
            return response?.data
        } else {
            return response?.data
        }

    } catch (e: any) {
        return e;
    }
}


export const getUserPasswordById = async (id: number) => {
    try {
        const response = await instance({
            method: 'get',
            url: `/passwords/${id}`
        })

        return response?.data
    } catch (e: any) {
        return e?.response?.data
    }
}


