import { Form } from "antd";
import instance from "config/_axios";
import { FC } from "react";
import { asyncN } from "utils/notifications";
import { visibleType } from "../types";
import UiForms from "./ui";
type propType = {
    visible: visibleType,
    setVisible: (visible: visibleType) => void,
    refresh: boolean,
    setRefresh: (refresh: boolean) => void
}
const CreateJobTitle: FC<propType> = ({ visible, setVisible, refresh, setRefresh }): JSX.Element => {
    const [form] = Form.useForm()
    const onFinish = async (values: any) => {
        try {
            let data = new FormData();
            Object.entries(values)?.map(([key, value]: any) => {
                if (value !== null) {
                    if (key === "is_leader") {
                        data?.append(key, value ? "1" : "0");
                    } else {
                        data.append(key, value)
                    }
                }
            })
            const resp = await instance({
                url: `/job-titles${visible?.item ? '/' + visible?.item?.id : ''}`,
                method: visible?.item ? "put" : "post",
                data: data
            })
            if (resp?.data?.status === 1) {
                asyncN("success", visible?.item ? 'update' : 'create', resp?.data?.message).then(() => {
                    setRefresh(!refresh)
                    setVisible({ item: null, type: "hide" })
                })
            } else {
                asyncN("error", visible?.item ? 'update' : 'create', resp?.data?.message);
            }
        } catch (error: any) {
            asyncN("error", visible?.item ? 'update' : 'create', error?.response ? error?.response?.data?.message : error?.message);
        }

    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            <UiForms visible={visible} setVisible={setVisible} form={form} />
        </Form>
    )
}

export default CreateJobTitle