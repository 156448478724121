import React, { StrictMode, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import RoutesMiddleware from './routes/RoutesMiddleware';
import _console from 'utils/console';
import { save_after_load, save_before_load } from 'utils/save_ui';
import useWindowSize from 'hooks/useWindowSize';
import manageResponsiveScreen from 'utils/manageResponsiveScreen';
import { Loading } from './components/Loading';
import verification from './config/_axios/verification';
// import ErrorBoundary from 'pages/error_boundary';
import { useAppSelector } from 'store/services';
import { getNotifications } from 'services';
import Language from 'services/language';
import {BrowserRouter as Router} from 'react-router-dom';
import ErrorBoundary from "./pages/error_boundary";

function App() {
  const windowSize = useWindowSize()
  const auth:any = useAppSelector(state => state.auth);


  if (!localStorage.getItem("_url") && window.location.pathname !== "/" && !localStorage.getItem("access_token")) {
    localStorage.setItem("_url", window.location.pathname);
  }


  useEffect(() => {
    manageResponsiveScreen(windowSize.width)
  }, [windowSize.width])

  // _console("auth", auth.permissions)
  // _console("auth", auth)

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (localStorage.getItem("access_token")) {
        sessionStorage.setItem("page_reloading", '_1_')
      } else {
        sessionStorage.removeItem("page_reloading")
      }
      save_before_load();
    });
    return () => window.removeEventListener('beforeunload', save_before_load);
  }, [])

  useEffect(() => {
    window.addEventListener("load", () => {
      verification()
      save_after_load()

    });
    return () => window.removeEventListener('load', save_after_load);
  }, [])


  // useEffect(() => {
  //   if (auth.isAuthenticated) {
  //     (
  //       async () => {
  //         // await getNotifications();
  //       }
  //     )()
  //   }
  // }, [auth.isAuthenticated])


  if (sessionStorage.getItem('page_reloading') === '_1_') {
    return <Loading />;
  }
  if (auth.isLoading) {
    return <Loading />
  };

  const _url:string = localStorage.getItem("_url") || window.location.pathname;


  return (
    <Router>
    <StrictMode>
      <RoutesMiddleware />
      {/* {auth.isAuthenticated && <Redirect to={_url} />} */}
    </StrictMode>
    </Router>
  );
}

export default App;