import { Button, Col, Pagination, Popconfirm, Row, Select, Tag, Image, Tooltip, Divider, Alert } from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import isHasAuthRoute from 'utils/isHasRoute';
import { asyncN } from 'utils/notifications';
import { questionType } from './crud/types';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { FaFilePdf, FaFileWord } from 'react-icons/fa';
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { delete_data, getQuestionStatusList } from 'services';
import ViewQuestion from './crud/view';
import { getAllLanguages } from 'services';
import EmptyTable from 'components/EmptyTable';
import './style.scss'
import DiscriptionStyle from 'components/DescriptionStyle';
import { question_confirm, question_confirm_status } from './confirm_status';
import useGetAllData from 'hooks/useGetAllData';
import { clearObjectFields, getLanguageAsObject, getQuestionStatusAsArray, getTextWithoutAnyStyles, isImage } from 'utils/auxiliary_functions';
import instance from 'config/_axios';
import isRole from 'utils/isRole';
import { FILE_URL } from 'config/utils';
import { EmptySpaceLine } from 'components/SimpleComponents';
import { ButtonCreate } from 'components/Buttons';
import ForTable from 'components/ForTable';
import { AiOutlineCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import ForTitle from 'components/ForTitles';

const { Option } = Select


const Questions: FC = (): JSX.Element => {

    const { t } = useTranslation()
    const history = useHistory()
    const [refetch, setRefetch] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [item, setItem] = useState<questionType['items'][0]>()
    const [langs, setLangs] = useState<Array<{ id: number, name: string }>>([])
    const [visible, setVisible] = useState<boolean>(false);
    const [statuses, setStatuses] = useState<string>('');
    const [filterByField, setFilterByField] = useState<{ subject_id: number | undefined, lang_id: number | undefined, kafedra_id: number | undefined }>({ subject_id: undefined, lang_id: undefined, kafedra_id: undefined });
    const [languages, setLanguages] = useState<any[]>([]);
    const [statusesCodes, setStatusesCodes] = useState<Array<string | number>>([]);

    const _questions = useGetAllData({
        url: `/questions?sort=-id&expand=subject,course,semestr,questionType,language,createdBy${Object.getOwnPropertyNames(clearObjectFields(filterByField, [undefined])).length ? '&filter=' + JSON.stringify(clearObjectFields(filterByField, [undefined])) : ''}${statuses ? '&statuses=' + String(statuses) : ''}`,
        isCall: 'auto',
        perPage,
        currentPage,
        params: {},
        refetch: [filterByField.lang_id, filterByField.subject_id, filterByField.kafedra_id, statuses, refetch]
    });

    const _subjects = useGetAllData({ url: `/subjects?${filterByField.kafedra_id ? `&filter={"kafedra_id":${filterByField.kafedra_id}}` : ''}`, perPage: 0 });
    const _kafedra = useGetAllData({ url: '/kafedras', perPage: 0 });


    useEffect(() => {
        (
            async () => {
                try {
                    const resp = await getAllLanguages();
                    setLanguages(resp?.items || []);
                } catch (e: any) {
                    asyncN("error", "read", "Error for get languages!!!")
                }
            }
        )()
    }, []);


    const _getStatuses = async () => {
        const response = await instance({ url: '/questions/status-list' });
        if (response.data?.status === 1) {
            const _data = response.data?.data;
            if (Object.getOwnPropertyNames(_data)) {
                const _status_id: Array<string | number> = [];
                for (let key in _data) {
                    _status_id.push(key);
                }
                setStatusesCodes(_status_id);
            }
        }
    }


    const _getLanguages = async () => {
        try {
            const resp = await getAllLanguages();
            if (resp?.items) {
                setLangs(resp?.items)
            }
        } catch (e: any) {
            asyncN("error", "read", "Error for get languages!!!")
        }
    }


    useEffect(() => {
        _getLanguages();
    }, [])

    const handleChange = (name: string, value: number | undefined) => { setFilterByField(prevState => ({ ...prevState, [name]: value })) }

    const _updateQuestion = async (id: number, status: string, course_id: string ) => {
        try {
            const formdata = new FormData();
            formdata.append('status', status);
            formdata.append('course_id', course_id)
            const response = await instance({ url: `/questions/status-update/${id}`, method: "PUT", data: formdata });

            if (response.data.status === 1) {
                asyncN("success", 'update', response.data?.message).then(() => setRefetch(!refetch))
            } else {
                asyncN("error", 'update', response.data?.message)
            }
        } catch (error) {
            asyncN("error", 'update', "Ma'lumot yangilashda xatolik!")
        }
    }


    useEffect(() => {
        getQuestionStatusList();
    }, [])

    const _download = (file_url: string) => <a href={FILE_URL + file_url} download={true}></a >;


    const fileType = (file_url: string) => {
        if (file_url) {

            if (file_url.endsWith('.pdf')) {
                return <a href={FILE_URL + file_url} target="_blank"> <Button type='primary' size='small' ghost className='fs-13 w-100 text-center' onClick={() => _download(file_url)}><FaFilePdf className='me-2' />Pdf</Button></a >;
            } else if (file_url.endsWith('.docx')) {
                return <a href={FILE_URL + file_url} target="_blank" download={true}> <Button type='primary' size='small' ghost className='fs-13 w-100 text-center' onClick={() => _download(file_url)}><FaFileWord className='me-2' /> Word</Button></a >;
            } else if (isImage(file_url)) {
                return <Image width={80} src={FILE_URL + file_url} />
            }
        } else {
            return <EmptySpaceLine color='#E8F0FE' />
        }
    }

    return (
        <div>
            <ForTitle title="Questions" buttons={[
                    <ButtonCreate
                        onClick={() => history.push("/create-questions")}
                        style={{ display: isHasAuthRoute("question_create") ? "" : "none" }}
                    >
                        {t("Add")}
                    </ButtonCreate>]}/>
            <Alert className='mb-2' message="Tasdiqlanmagan savollar imtihon jarayonida ko'rinmaydi va imtihon topshirish imkoniyati bo'lmaydi !" type="warning" banner showIcon />
            <Row gutter={[12, 12]} className="mb-2">
                <Col xl={7}>
                    <Select
                        allowClear
                        onChange={(e: number) => { setFilterByField((prevState) => ({ ...prevState, kafedra_id: e })) }}
                        onFocus={() => { _kafedra.fetch() }}
                        showSearch
                        filterOption={(input: any, option: any) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        className='w-100'
                        placeholder="Kafedra bo'yicha saralash"
                        loading={_kafedra.loading}
                        disabled={!isHasAuthRoute('kafedra_index')}
                    >
                        {
                            _kafedra.items.length ? _kafedra.items.map((item: { id: number, name: string }, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>) : null
                        }
                    </Select>
                </Col>
                <Col xl={7}>
                    <Select
                        allowClear
                        onChange={(e: number) => handleChange('subject_id', e)}
                        onFocus={() => { _subjects.fetch() }}
                        showSearch
                        filterOption={(input: any, option: any) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        className='w-100'
                        placeholder="Fan bo'yicha saralash"
                        loading={_subjects.loading}
                    >
                        {
                            _subjects.items.length ? _subjects.items.map((item: { id: number, name: string }, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>) : null
                        }
                    </Select>
                </Col>
                <Col xl={4}>
                    <Select
                        allowClear
                        onChange={(e: number) => handleChange('lang_id', e)}
                        onFocus={_getLanguages}
                        className="w-100"
                        placeholder="Til bo'yicha saralash"
                    >
                        {
                            langs.length ? langs?.map((item: { id: number, name: string }, i: number) => <Option key={i} value={item?.id}>{item?.name}</Option>) : null
                        }
                    </Select>
                </Col>
                <Col xl={6} className="d-flex">
                    <Select
                        allowClear
                        onChange={(e: string) => setStatuses(e)}
                        className="w-100"
                        placeholder="Holati bo'yicha saralash"
                        onFocus={_getStatuses}
                    >
                        {
                            getQuestionStatusAsArray(statusesCodes).length ?
                                getQuestionStatusAsArray(statusesCodes).map((element, index: number) => <Option key={index} value={JSON.stringify(element.status)}>{element.title}</Option>) : null
                        }
                    </Select>
                </Col>
            </Row>
            <ForTable currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} totalCount={_questions.data._meta.totalCount} loading={_questions.loading} datas={_questions.items} theads={
                    [
                        <>{t("Question")}</>,
                        <>{t("Subject")}</>,
                        <>{t("Teacher")}</>,
                        <>{t("Language")}</>,
                        <>{t("Type")}</>,
                        <>{t("File")}</>,
                        <>{t("Status")}</>,
                        isRole(['mudir', 'dean', 'edu_admin', 'admin']) ?
                                    <>{t("Confirmation")}</>
                        : null,
                        isRole(['mudir', 'dean', 'edu_admin', 'admin']) ?
                                    <>{t("Cancel")}</>
                        : null,
                        <>{t("Actions")}</>
                    ]
                } tbodys={function(this: any){
                    return [
                        <div style={{ maxWidth: "250px" }} >{getTextWithoutAnyStyles(this?.question)}</div>,
                        <><div className="question-window-line-clamp">{this?.subject?.name}</div></>,
                        <>{this?.createdBy?.last_name}&nbsp;{this?.createdBy?.first_name}</>,
                        <>{getLanguageAsObject(langs)[this?.lang_id]}</>,
                        <>{this?.questionType?.name}</>,
                        <div className="text-center">{fileType(this?.file)}</div>,
                        <div className="text-center" >{question_confirm_status(this?.status)}</div>,
                        isRole(['mudir', 'dean', 'edu_admin', 'admin']) ?
                        <>
                            <div className="ant-col">
                                <Button type='primary' size="small" style={{ fontSize: '12px' }} disabled={question_confirm(this?.status).disabled_confirm} onClick={() => _updateQuestion(this?.id, '1', this?.course_id)}>Tasdiqlash</Button>
                            </div>
                        </>
                        : null,
                        isRole(['mudir', 'dean', 'edu_admin', 'admin']) ?
                        <>
                            <div className="ant-col">
                                <Button type='primary' size="small" danger style={{ fontSize: '12px' }} disabled={question_confirm(this?.status).disabled_cancel} onClick={() => _updateQuestion(this?.id, '0', this?.course_id)} >Bekor qilish</Button>
                            </div>
                        </>
                        : null,
                        <div className="crud_actions_table">
                            {isHasAuthRoute("question_view") && (
                                <Tooltip placement="top" title={t("View")}>
                                    <TbFileInvoice  onClick={() => {
                                        if (this?.subQuestion?.length) {
                                            history.push(`/question-view/${this?.id}`)
                                        } else {
                                            setVisible(true)
                                            setItem(this)
                                        }
                                    }} className="view_btn"
                                    />
                                </Tooltip>
                            )}
                            {isHasAuthRoute("question_update") && (
                                <Tooltip placement="top" title={t("Edit")}>
                                    <TbFilePencil 
                                        onClick={() => {
                                            history.push({
                                                pathname: "/edit-questions/" + this?.id
                                            })
                                        }}
                                        className="edit_btn"
                                    />
                                </Tooltip>
                            )}
                            {isHasAuthRoute("question_delete") && (
                                <Tooltip placement="top" title={t("Delete")}>
                                    <Popconfirm
                                        placement="topLeft"
                                        title={`${t("Deleted it")} ?`}
                                        okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>}
                                        onConfirm={() => delete_data("/questions", this?.id).then((e: any) => {
                                            setRefetch(!refetch)
                                        })}
                                    >
                                        <TbFileOff className="delete_btn" />
                                    </Popconfirm>
                                </Tooltip>
                            )}
                        </div>
                    ]
                }}/>
            {item && <ViewQuestion visiblity={visible} item={item} setVisiblity={setVisible} />}
        </div>
    )
}

export default Questions