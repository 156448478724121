import { Button, Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import ModalTitle from "components/ModalTitle";
import MultipleInput from "components/MultipleInput";
import instance from "config/_axios";
import useGetAllData from "hooks/useGetAllData";
import { TypeSimpleCreateProps } from "pages/common/simple_create";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiX } from "react-icons/bi";
import { asyncN } from "utils/notifications";
import { ServerErrors } from "utils/validationFunctions";

const { Option } = Select;

const LanguageCertificateTypeCreate: FC<Omit<TypeSimpleCreateProps, 'title'>> = ({ url, visible, setVisible, setReFetch }): JSX.Element => {

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [clicked, setClicked] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(true);

  const languages = useGetAllData({ url: "languages?all=1", isCall: "auto" })

  const onSubmit = async (values: any) => {
    try {
      setClicked(true)
      const formdata = new FormData();
      for (const [key, value] of Object.entries(values)) {
        if (key === 'status') {
          formdata.append(key, status ? '1' : '0');
        } else {
          formdata.append(key, String(value));
        }
      }

      const response = await instance({ url, method: 'POST', data: formdata })

      if (response.data.status === 1) {
        asyncN("success", 'create', response.data?.message)
          .then(() => {
            resetFields();
            setVisible(false);
            setReFetch(prevState => !prevState);
          })
      } else {
        asyncN("error", 'update', response.data?.message)
          .then(() => resetFields())
      }
      setClicked(false)
    } catch (error: any) {
      if (error?.response?.status === 422) {
        if (error?.response?.data?.status === 0) {
          form.setFields(ServerErrors(error?.response?.data?.errors[0]))
        }
      }
      asyncN("error", 'create', error?.response?.message)
      setClicked(false)
    }
  }

  const resetFields = () => form.resetFields()

  return (
    <Modal
      title={'Language certificate type ceate'}
      visible={visible}
      onCancel={() => { setVisible(prevState => !prevState); resetFields() }}
      width={600}
      footer={null}
      closeIcon={<BiX size={20} />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          status: true
        }}
      >
        <MultipleInput layout="vertical" />
        <Col span={24}>
          <Form.Item
            label={t("Certificate language")}
            name="lang_id"
            shouldUpdate
            rules={[{ required: true, message: 'Fill in the field!' }]}
          >
            <Select placeholder={t("Select certificate language") + " ..."} >
              {
                languages.items?.length ? languages.items?.map((e, i) => (
                  <Option key={i} value={e?.id} >{e?.name} ({e?.lang_code})</Option>
                )) : null
              }
            </Select>
          </Form.Item>
        </Col>
        <hr />
        <Col span={24} className="mt-2">
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Form.Item
                name="status"
                className='mb-0'
              >
                <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
              </Form.Item>
            </Col>
            <Col span={16} className="text-end">
              <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
              <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { setVisible(prevState => !prevState); resetFields() }}>{t("Cancel")}</Button>
              <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </Modal>
  )
}

export default LanguageCertificateTypeCreate;