import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import asyncThunkDefaults from "services/asyncThunkDefaults";
import { IDefaultData } from 'models/data';
import { StoredDataTypes } from "hooks/useStoreData";


export type TypeStoredDataKeys = 'success' | 'pending' | 'error'

type TypeInitialStateDefaults = {
    [key in StoredDataTypes]: {
        items: any[],
        _meta: IDefaultData<any>['_meta'],
        status: TypeStoredDataKeys,
        loading: boolean,
    }
}

const initialState: Partial<TypeInitialStateDefaults> = {}




const defaultsSlice = createSlice({
    name: 'defults',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(asyncThunkDefaults.pending, (state: TypeInitialStateDefaults, action: any) => {

                const key = action.meta.arg.key as StoredDataTypes;
                const status = action.meta.requestStatus;

                state[key] = {
                    items: [],
                    _meta: {
                        currentPage: 0,
                        pageCount: 0,
                        perPage: 0,
                        totalCount: 0
                    },
                    loading: true,
                    status
                }

            })
            .addCase(asyncThunkDefaults.fulfilled, (state: TypeInitialStateDefaults, action: PayloadAction<{ key: StoredDataTypes, data: IDefaultData<any>['items'], _meta: IDefaultData<any>['_meta'] }>) => {
                if(action.payload){
                    const { data, _meta, key } = action.payload;

                    state[key] = {
                        items: data,
                        _meta,
                        loading: false,
                        status: 'success'
                    }
                }
            })
            .addCase(asyncThunkDefaults.rejected, (state: TypeInitialStateDefaults, action: PayloadAction<{ key: StoredDataTypes, data: IDefaultData<any>['items'], _meta: IDefaultData<any>['_meta'] }>) => {

                // const { key } = action.payload;

                // state[key].loading = false

                // console.log("rejected -> ", action)

            })

    }
});



export default defaultsSlice;