import { Button, Col, Pagination, Popconfirm, Row, Select, Tabs, Tag, Tooltip } from "antd";
import BadgeStatus from "components/BadgeStatus";
import CustomBreadcrumb from "components/Breadcrumb";
import EmptyTable from "components/EmptyTable";
import instance from "config/_axios";
import { _meta } from "pages/role_and_permission/components/types";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import ViewTopic from "./components/view";
import { getAllSubjectTopics } from "./servise";
import { subjectTopicType } from "./types";
import { getAllLanguages } from "services";
import { getAllSubjectCategories } from "pages/subject_category/service";
import { subjectCategoryType } from "pages/edu_plan/attachSubjects/type";
import { BiRightArrowAlt } from "react-icons/bi";
import { useAppSelector } from "store/services";
import TeacherComment from "./components/subject-topic-references";
import { useQueryParams } from "pages/subjects/service/useQueryParams";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import ForTitle from "components/ForTitles";
import { ButtonCreate } from "components/Buttons";
import ForTable from "components/ForTable";

const { TabPane } = Tabs;

type langType = {
    id: number,
    name: string
}

const SubjectTopics: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();
    const params: any = useRouteMatch("/subjects/:subjectId/:teacherId/topics")?.params;
    const location = useLocation()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [subjectTopics, setSubjectTopics] = useState<Array<subjectTopicType>>([]);
    const auth: any = useAppSelector(state => state.auth);
    const [meta, setMeta] = useState<_meta>()
    const [refresh, setRefresh] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(20);
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [visible, setVisible] = useState<boolean>(false);
    const [topicId, setTopicId] = useState<number | null>(null)
    const [langs, setLangs] = useState<Array<langType>>([]);
    const [subjectCategory, setSubjectCategory] = useState<Array<subjectCategoryType>>([])
    const [commentVisible, setCommentVisible] = useState<boolean>(false);
    const queryParams: { lang_id?: number, subject_category_id?: number, taid?: number, set?: number } = useQueryParams();
    const [filter, setFilter] = useState<{
        lang_id?: number,
        subject_category_id?: number,
        subject_id?: number,
        user_id?: number
    }>({
        subject_id: Number(params?.subjectId)
    })


    const _breadcrump = queryParams?.set === undefined ? auth?.user?.role?.length === 1 && auth?.user?.role[0] === "teacher" ? [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "subjects",
        },
        {
            name: "Subject topics",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
        }
    ] : [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "subjects",
        },
        {
            name: "Subject teachers",
            path: `/subjects/${params?.subjectId}/teachers`,
        },
        {
            name: "Subject topics",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
        }
    ] : [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Contenter subjects",
            path: "/subjects-content-checking",
        },
        {
            name: "Subject teachers",
            path: `/subjects/${params?.subjectId}/teachers?set=0`,
        },
        {
            name: "Subject topics",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics?lang_id=${queryParams?.lang_id}&taid=${queryParams?.taid}&set=0`,
        }
    ];
    useEffect(() => {
        (
            async () => {
                const resp = await getAllLanguages();
                if (resp?.items) {
                    setLangs(resp?.items)
                }
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                const resp = await getAllSubjectCategories();
                if (resp?.items) {
                    setSubjectCategory(resp?.items)
                }
            }
        )()
    }, [])

    useEffect(() => {
        (
            async () => {
                setIsLoading(true)
                const resp = await getAllSubjectTopics(`expand=subject,lang,updatedBy,createdBy,contentCount,subjectCategory&user_id=${params?.teacherId}&sort=-order&filter=${JSON.stringify(Object.keys(queryParams)?.length > 0 ? { ...filter, ...queryParams } : filter)}`, perPage, currentPage)
                setSubjectTopics(resp?.data?.items);
                setMeta(resp?.data?._meta);
                setIsLoading(false)
            }
        )()
    }, [refresh, currentPage, perPage, queryParams])

    const deleteItem = async (id: number) => {
        try {
            const resp = await instance({
                url: '/subject-topics/' + id,
                method: 'delete'
            })
            if (resp?.data?.status === 1) {
                asyncN('success', 'delete', resp?.data?.message)
                setRefresh(!refresh)
            } else {
                asyncN("error", "delete", resp?.data?.message)
            }
        } catch (err: any) {
            asyncN('error', "delete", err?.response?.data ? err?.response?.data?.message : "Error delete subject topic!")
        }
    }

    const replacePath = async (type: "SET" | "REMOVE", paramName: string, val?: string | number) => {
        if (type === "SET") {
            if (paramName && val) {
                if (!Object.keys(queryParams).includes(paramName)) {
                    if (location?.search?.startsWith("?")) {
                        history?.replace(`${location?.pathname + location?.search}&${paramName + "=" + val}`)
                    } else {
                        history?.replace(`${location?.pathname}?${paramName + "=" + val}`)
                    }
                } else {
                    let newParams: string = ""
                    Object?.entries(queryParams)?.forEach(([key, value]: any) => {
                        if (paramName === key) {
                            if (newParams?.startsWith("?")) {
                                newParams += "&" + key + "=" + val
                            } else {
                                newParams += "?" + key + "=" + val
                            }
                        } else {
                            if (newParams?.startsWith("?")) {
                                newParams += "&" + key + "=" + value
                            } else {
                                newParams += "?" + key + "=" + value
                            }
                        }
                    })
                    history?.replace(location?.pathname + newParams);
                }
            }
        } else {
            if (paramName && Object.keys(queryParams)?.length > 0) {
                let newParams: string = ""
                Object.entries(queryParams)?.forEach(([key, value]: any) => {
                    if (key !== paramName) {
                        if (newParams?.length > 0) {
                            newParams += '&' + key + '=' + value
                        } else {
                            newParams += '?' + key + '=' + value
                        }
                    }
                })
                history?.replace(location?.pathname + newParams);
            }
        }
    }

    return (
        <div>
            {topicId !== null && <ViewTopic setId={setTopicId} setVisible={setVisible} visible={visible} id={topicId} />}
            {topicId !== null && <TeacherComment visible={commentVisible} setVisible={setCommentVisible} topicId={topicId} />}
            <Row>
                <Col span={16} className="text-start">
                    <CustomBreadcrumb arr={_breadcrump
                        .map(item => {
                            item = { ...item, name: t(item?.name) }
                            return item
                        })
                    } />
                </Col>
            </Row>
            <ForTitle title={'Fan mavzulari'} buttons={[
                isHasAuthRoute("subject-topic_create") && (params?.subjectId && queryParams?.set === undefined)  ?
                <ButtonCreate onClick={() => history.push(`/subjects/${params?.subjectId}/${params?.teacherId}/topic-create`)}/>
                : null
            ]}/>
            <Row gutter={24}>
                <Col span={24}>
                    
                    <ForTable totalCount={meta?.totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} setPerPage={setPerPage} loading={isLoading} datas={subjectTopics} theads={[
                        'Id',
                        <>{t("Topic name")}</>,
                        <>
                            {t("Hours")}
                        </>,
                        <>
                            {t("Language")}
                            <Select allowClear onClear={() => replacePath("REMOVE", "lang_id")}
                                value={queryParams?.lang_id && Number(queryParams?.lang_id)} onChange={(e) => {
                                    replacePath("SET", "lang_id", e)
                                }} placeholder="Select language!" className="w-100" >
                                {
                                    langs?.map((item: langType, i: number) =>
                                        <Select.Option key={i} value={item?.id}>{item?.name}</Select.Option>
                                    )
                                }
                            </Select>
                        </>,
                        <>{t("Subject category")}
                            <Select onClear={() => replacePath("REMOVE", "subject_category_id")} allowClear
                                value={queryParams?.subject_category_id && Number(queryParams?.subject_category_id)} onChange={(e) => {
                                    replacePath("SET", "subject_category_id", e)
                                }} placeholder="Select type!" className="w-100" >
                                {
                                    subjectCategory?.map((item: subjectCategoryType, i: number) =>
                                        <Select.Option key={i} value={item?.id}>{item?.name}</Select.Option>
                                    )
                                }
                            </Select></>,
                        Boolean(parseInt(params?.teacherId)) ? <>{t("Kontent")}</> : null,
                        <>{t("Content count")}</>,
                        <>{t("Status")}</>,
                        queryParams?.set === undefined ? <>
                            {t("Actions")}
                        </> : null
                    ]} tbodys={function(this: any){
                        return [
                            <>{this?.id}</>,
                            <>{this?.name}</>,
                            <>{this?.hours}</>,
                            <>{this?.lang?.name}</>,
                            <>{this?.subjectCategory?.name}</>,
                            Boolean(parseInt(params?.teacherId)) ? <>
                                <Link to={queryParams?.set === undefined ? 
                                `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${this?.id}/contents` :
                                `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${this?.id}/contents?lang_id=${queryParams?.lang_id}&taid=${queryParams?.taid}&set=0`
                                }>{t("Kontent")}&nbsp;<BiRightArrowAlt /></Link>
                            </> : null,
                            <>{this?.contentCount}</>,
                            <><BadgeStatus status={this?.status} /></>,
                            queryParams?.set === undefined ? <>
                               <div className="crud_actions_table">
                                   {isHasAuthRoute("subject-topic_view") && (
                                       <Tooltip placement="top" title={t("View")}>
                                           <TbFileInvoice 
                                               className="view_btn"
                                               onClick={() => {
                                                   setTopicId(this?.id)
                                                   setVisible(true)
                                               }}
                                           />
                                       </Tooltip>
                                   )}
                                   {isHasAuthRoute("subject-topic_update") && (
                                       <Tooltip placement="top" title={t("Edit")}>
                                           <TbFilePencil 
                                               className="edit_btn"
                                               onClick={() => {
                                                   if (params?.subjectId) {
                                                       history.push({
                                                           pathname: `/subjects/${params?.subjectId}/${params?.teacherId}/topic-edit/` + this?.id
                                                       });
                                                   }
                                               }}
                                           />
                                       </Tooltip>
                                   )}
                                   {isHasAuthRoute("subject-topic_delete") && (
                                       <Tooltip placement="top" title={t("Delete")}>
                                           <Popconfirm
                                               placement="topLeft"
                                               title={`${t("Deleted it")} ?`}
                                               okText={<AiOutlineCheckCircle />}
                                               cancelText={<AiFillCloseCircle/>}
                                               onConfirm={() => deleteItem(this.id)}
                                           >
                                               <TbFileOff className="delete_btn" />
                                           </Popconfirm>
                                       </Tooltip>
                                   )}
                               </div>
                            </> : null
                        ]
                    }}/>
                </Col>
            </Row>
        </div >
    )
}

export default SubjectTopics;