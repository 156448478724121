import { Button, Col, Form, message, Row, Select } from "antd";
import ForTable from "components/ForTable";
import instance from "config/_axios";
import useGetAllData from "hooks/useGetAllData";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CgCheckO } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { TbFileOff } from "react-icons/tb";
import { Table } from "react-super-responsive-table";
import { asyncN } from "utils/notifications";
type teacherAccessPropType = {
    teacher_access_list: any,
    form: any,
    setTeacherAccessList: any,
    edit?: boolean
}

const TeacherAccess: FC<teacherAccessPropType> = ({
    teacher_access_list,
    form,
    setTeacherAccessList,
    edit
}): JSX.Element => {
    const { t } = useTranslation();
    const [subjects, setSubjects] = useState<Array<{ id: number; name: string }>>([]);
    const [subjectId, setSubjectId] = useState<number>(0);
    const [subjectIds, setSubjectIds] = useState<Array<number>>([]); const [langs, setLangs] = useState<Array<{ id: number; name: string }>>([]);
    const [refresh, setRefresh] = useState<boolean>(false)
    const [selectedLangs, setSelectedLangs] = useState<any>({});
    const [refreshSubj, setRefreshSubj] = useState<boolean>(false);
    const [refreshLang, setRefreshLang] = useState<boolean>(false);
    const [refreshSubCategory, setRefreshSubCategory] = useState<boolean>(false);

    const subjectCategory = useGetAllData({url: `subject-categories`})

    useEffect(() => {
        if(!subjectCategory.items?.length){
            subjectCategory.fetch();
        }
    },[refreshSubCategory])

    useEffect(() => {
        (async () => {
            try {
                const response = await instance({
                    method: "get",
                    url: "/subjects?per-page=0",
                });

                if (response?.data?.status === 1) {
                    setSubjects(response?.data?.data?.items);
                }
            } catch (e: any) {
                asyncN("error", "read", e?.response?.message);
            }
        })();
    }, [refreshSubj])

    useEffect(() => {
        (async () => {
            try {
                const response = await instance({
                    method: "get",
                    url: "/languages",
                });

                if (response?.data?.status === 1) {
                    setLangs(response?.data?.data?.items);
                    form.setFieldsValue({
                        languages: [response?.data?.data?.items[0]?.id],
                        subject: null,
                    });
                }
            } catch (e: any) {
                asyncN("error", "read", e?.response?.message);
            }
        })();

    }, [refreshLang])

    const getName = (type: "subject" | "lang", id: number): string => {
        return type === "subject"
            ? subjects.filter((item: { id: number; name: string }) => item?.id === id)[0]?.name
            : langs.filter((item: { id: number; name: string }) => item?.id === id)[0]?.name;
    };

    const subjectAdd = () => {
        if (form.getFieldValue("subject") !== null) {
            setTeacherAccessList({
                ...teacher_access_list,
                [form.getFieldValue("subject")]: form.getFieldValue("languages"),
            });
            form.setFieldsValue({
                subject: null,
                languages: [],
                subject_category: []
            });
        } else {
            message.warning("Fanni tanlang!");
        }
    };

    const deleteSubject = (key: number) => {
        let obj: any = teacher_access_list;
        delete obj[`${key}`];
        setTeacherAccessList(obj);
        setRefresh(!refresh)
    };


    return (
        <Row className={"mt-3"} gutter={12} >
            {
                edit ? <>
                    <Col span={24}>
                        <strong>{t("Attach subject")}</strong>
                    </Col>
                    <Col sm={24} xs={24} md={10} lg={10}>
                        <Row gutter={12}>
                            <Col span={24}>
                                <Form.Item
                                    name={`subject`}
                                    label={t`Fan`}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        onFocus={() => {
                                            if (subjects?.length === 0) {
                                                setRefreshSubj(!refreshSubj)
                                            }
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={subjectId}
                                        onChange={(e: number) => {
                                            setSubjectId(e);
                                        }}
                                        placeholder={"Select subject"}
                                    >
                                        {subjects &&
                                            subjects.map(
                                                (item: { id: number; name: string }, i: number) =>
                                                    !subjectIds.includes(item.id) ? (
                                                        <Select.Option key={i} value={item.id}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ) : (
                                                        ""
                                                    )
                                            )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={24} xs={24} md={14} lg={14}>
                        <Row gutter={12}>
                            <Col sm={12} xs={12} md={12} lg={12}>
                                <Form.Item
                                    name={`languages`}
                                    label={t`Language`}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        onFocus={() => {
                                            if (langs?.length === 0) {
                                                setRefreshLang(!refreshLang)
                                            }
                                        }}
                                        value={selectedLangs[subjectId]}
                                        onChange={(e) => {
                                            setSelectedLangs({
                                                ...selectedLangs,
                                                [subjectId]: e,
                                            });
                                        }}
                                        mode="multiple"
                                        placeholder={"Tillarni tanlang!"}
                                    >
                                        {langs &&
                                            langs.map(
                                                (
                                                    item: { id: number; name: string },
                                                    i: number
                                                ) => (
                                                    <Select.Option key={i} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                )
                                            )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={8} xs={8} md={8} lg={8}>
                                <Form.Item
                                    name={`subject_category`}
                                    label={t`Subject category`}
                                    rules={[
                                        {
                                            required: false,
                                            message: `${t("Fill in the field!")}`,
                                        },
                                    ]}
                                >
                                    <Select
                                        onFocus={() => {setRefreshSubCategory(prevState => !prevState)}}
                                        // value={selectedLangs[subjectId]}
                                        onChange={(e) => { }}
                                        mode="multiple"
                                        placeholder={"Select subject category!"}
                                    >
                                        {subjectCategory.items?.length ?
                                            subjectCategory.items?.map(
                                                (
                                                    item: { id: number; name: string },
                                                    i: number
                                                ) => (
                                                    <Select.Option key={i} value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                )
                                            ) : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                sm={4}
                                xs={4}
                                md={4}
                                lg={4}
                                style={{ paddingTop: "30px" }}
                            >
                                <Button className="ms-5" shape="circle" disabled={!Boolean(
                                    form?.getFieldValue("subject") && form?.getFieldValue("languages")
                                )} onClick={() => subjectAdd()}><FaCheck /></Button>
                            </Col>
                        </Row>
                    </Col>
                </> : ""
            }
            <Col span={24}>
                <Row gutter={24}>
                    <Col sm={24} xs={24} md={12} lg={12}>
                        {
                            Object.keys(teacher_access_list).length ? <ForTable totalCount={Object.keys(teacher_access_list).length} showAllData={true} loading={false} datas={Object.entries(teacher_access_list).map(([key, value]: any, index: number) => {
                                return {
                                    key,
                                    value
                                }
                            })} theads={[
                                <>{t("Subject")}</>,
                                <>{t("Languages")}</>,
                                edit && <>{t("Action")}</>
                            ]} tbodys={
                                function(this: any){
                                    return [
                                        <>{getName("subject", Number(this.key))}</>,
                                        <>
                                            {this.value &&
                                                this.value.map((e: number) => (
                                                    <span key={e} className="me-3">
                                                        {getName("lang", Number(e))}
                                                    </span>
                                                ))}
                                        </>,
                                        edit && <>
                                            <div className="d-flex justify-content-center aligin-items-center">
                                                <TbFileOff
                                                    onClick={() => deleteSubject(this.key)}
                                                    style={{ cursor: "pointer" }}
                                                    className="text-danger"
                                                />
                                            </div>
                                        </>
                                    ]
                                }
                            }/>
                            : null
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default TeacherAccess;