import { Button, Col, Form, Input, Modal, Popconfirm, Row, Tag, Tooltip } from "antd";
import { ButtonCreate } from "components/Buttons";
import EmptyTable from "components/EmptyTable";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import instance from "config/_axios";
import i18next from "i18next";
import { _meta } from "pages/role_and_permission/components/types";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import { getAllDegreeInfo } from "./service";
import { degreeInfos } from "./types";

const DegreeInfos: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const [form] = Form.useForm()
    const [degrees, setDegrees] = useState<Array<degreeInfos>>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)
    const [meta, setMeta] = useState<_meta>()
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false)
    const [visible, setVisible] = useState<{ item: degreeInfos | null, type: "show" | "edit" | "hide" }>({ item: null, type: "hide" });

    useEffect(() => {
        (
            async () => {
                setLoading(true)
                const data = await getAllDegreeInfo(currentPage, perPage, 'expand=description');
                setDegrees(data?.items);
                setMeta(data?._meta);
                setLoading(false)
            }
        )()
    }, [currentPage, perPage, refresh])


    const deleteOne = async (id: number) => {
        try {
            const resp = await instance({
                url: `/degree-infos/${id}`,
                method: 'DELETE'
            })
            if (resp?.data?.status === 1) {
                asyncN("success", 'delete', resp?.data?.message).then(() => {
                    setRefresh(!refresh)
                })
            } else {
                asyncN("error", 'delete', resp?.data?.message);
            }
        } catch (error: any) {
            asyncN("error", 'delete', error?.response ? error?.response?.data?.message : error?.message);
        }
    }

    const onFinish = async (values: { name: string, name_en: string, name_ru: string, description: string }) => {
        try {
            let data = new FormData();
            Object.entries(values)?.map(([key, value]: any) => {
                if (value !== null) {
                    data.append(key, value)
                }
            })
            const resp = await instance({
                url: `/degree-infos${visible?.item ? '/' + visible?.item?.id : ''}`,
                method: visible?.item ? "put" : "post",
                data: data
            })
            if (resp?.data?.status === 1) {
                asyncN("success", visible?.item ? 'update' : 'create', resp?.data?.message).then(() => {
                    setRefresh(!refresh)
                    setVisible({ item: null, type: "hide" })
                })
            } else {
                asyncN("error", visible?.item ? 'update' : 'create', resp?.data?.message);
            }
        } catch (error: any) {
            asyncN("error", visible?.item ? 'update' : 'create', error?.response ? error?.response?.data?.message : error?.message);
        }

    }

    useEffect(() => {
        if (visible?.item !== null && visible?.type == "edit") {
            form?.setFieldsValue(visible?.item)
        }
    }, [visible?.item, visible?.type])

    return (
        <>
        <ForTitle title='Degree infos' buttons={[isHasAuthRoute("degree-info_create") && <ButtonCreate onClick={() => {
                    form?.resetFields()
                    setVisible({ item: null, type: "edit" })
                }}/>]}/>
        <Row gutter={24}>
            <Col span={24}>
                <ForTable setCurrentPage={setCurrentPage} setPerPage={setPerPage} currentPage={currentPage} perPage={perPage} totalCount={meta?.totalCount} datas={degrees} theads={
                        [
                            <>{t('Name')}</>,
                            <>{t('Description')}</>,
                            <>{t('Actions')}</>
                        ]
                    } tbodys={
                        function(this: any){
                            return [
                                <>
                                    {
                                        i18next.language == "uz" ? this?.name : i18next.language == "ru" ? this?.name_ru : this?.name_en
                                    }
                                </>,
                                <>
                                    {this?.description}
                                </>,
                                <>
                                    <div className="crud_actions_table" >
                                        {isHasAuthRoute("degree-info_view") && <Tooltip placement="top" title={t("View")}><TbFileInvoice  className="view_btn" onClick={() => { setVisible({ item: this, type: "show" }) }} /></Tooltip>}
                                        {isHasAuthRoute("degree-info_update") && <Tooltip placement="top" title={t("Edit")}><TbFilePencil className="edit_btn" onClick={() => { setVisible({ item: this, type: "edit" }) }} /></Tooltip>}
                                        {isHasAuthRoute("degree-info_delete") &&
                                            <Tooltip placement="top" title={t("Delete")}>
                                                <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`} okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle/>} onConfirm={() => deleteOne(this.id)}>
                                                    <TbFileOff className="delete_btn" />
                                                </Popconfirm>
                                            </Tooltip>}
                                    </div>
                                </>
                            ]
                        }
                    } loading={loading}/>
                <Modal visible={visible?.type == "show"}
                    cancelText="Yopish"
                    okText="Tahrirlash"
                    onOk={() => setVisible({ ...visible, type: "edit" })}
                    onCancel={() => setVisible({ item: null, type: "hide" })}
                    title="Show degree info!"
                >
                    {
                        visible?.item ? <Row gutter={24}>
                            <Col span={24}>
                                <span className="d-flex justify-content-start">
                                    <span style={{ fontWeight: 600 }}>Name (uz)</span>
                                    <span>{visible?.item?.name}</span>
                                </span>
                                <span className="d-flex justify-content-start">
                                    <span style={{ fontWeight: 600 }}>Name (ru)</span>
                                    <span>{visible?.item?.name_ru}</span>
                                </span>
                                <span className="d-flex justify-content-start">
                                    <span style={{ fontWeight: 600 }}>Name (en)</span>
                                    <span>{visible?.item?.name_en}</span>
                                </span>
                            </Col>
                        </Row> : ''
                    }
                </Modal>
                <Modal visible={visible?.type == "edit"}
                    footer={false}
                    onCancel={() => setVisible({ item: null, type: "hide" })}
                    title={visible?.item ? "Edit degree info!" : "Create degree info!"}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name={"name"} label="Name (uz)">
                                    <Input placeholder="Name (uz)" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"name_ru"} label="Name (ru)">
                                    <Input placeholder="Name (ru)" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"name_en"} label="Name (en)">
                                    <Input placeholder="Name (en)" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={"description"} label="Description">
                                    <Input placeholder="Description" />
                                </Form.Item>
                            </Col>
                            <Col className="d-flex justify-content-end" span={24}>
                                <Button className="m-1" htmlType="reset">Tozalash</Button>
                                <Button className="m-1" type="primary" danger htmlType="button" onClick={() => setVisible({ item: null, type: "hide" })}>Bekor qilish</Button>
                                <Button className="m-1" type="primary" htmlType="submit">Saqlash</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Col>
        </Row>
        </>
    )
}

export default DegreeInfos;