import { Button, Col, Form, Row, Select, Switch } from "antd";
import ForTable from "components/ForTable";
import instance from "config/_axios";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaTrash } from "react-icons/fa";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { asyncN } from "utils/notifications";
import { getUserAccessTypes } from "../servise";
import { userAccessType } from "./types";
const { Option } = Select
const UserAccess: FC<{ form: any, userAccesses: any, setUserAccesses: any }> = ({ form, userAccesses, setUserAccesses }): JSX.Element => {
    const { t } = useTranslation()
    const [userAccessTypes, setUsetAccessTypes] = useState<Array<userAccessType>>([]);
    const [userAccessVariants, setUserAccessVariants] = useState<Array<{ id: number, name: string, faculty_id?: number }>>([])
    const [accessVariantsObj, setAccessVariantsObj] = useState<any>({})
    const [accessVariantsObjOne, setAccessVariantsObjOne] = useState<any>({})
    const [selectedFaculties, setSelectedFaculties] = useState<Array<number>>([]);
    const [selectedAccess, setSelectedAccess] = useState<number>(0)
    const [disabled, setDisabled] = useState<{ is_leader: boolean, user_access: boolean }>({
        is_leader: true, user_access: true
    })

    const getUserAccessTypesList = async () => {
        const data: any = await getUserAccessTypes();
        setUsetAccessTypes(data?.items)
        setDisabled({
            ...disabled,
            user_access: false
        })
    }

    useEffect(() => {
        let data: Array<number> = [];
        Object.entries(userAccesses)?.map(([key, value]: any) => {
            if (Number(key) === 1) {
                value?.map((item: string) => {
                    let [facultyId, isLeader] = item?.split("-")
                    data.push(Number(facultyId));
                })
            }
        })
        setSelectedFaculties([...selectedFaculties, ...data])
    }, [])

    const getUserAccessVariants = async (id: number, type?: any) => {
        if (id) {
            let url: string = userAccessTypes?.filter((item: userAccessType) => item?.id === id)[0]?.url
            try {
                const response = await instance({
                    method: 'get',
                    url: url + "?per-page=0",
                })
                if (response?.data?.status === 1) {
                    if (!type) setUserAccessVariants(response?.data?.data?.items);
                    setTimeout(() => {
                        setAccessVariantsObjOne({
                            [`${id}`]: response?.data?.data?.items
                        })
                    }, 2000)
                } else {
                    asyncN("error", "read", response?.data?.message);
                }
            } catch {
                asyncN("error", "read", "error read " + url)
            }
        } else {
            asyncN("error", "read", "Url not found!!!");
        }
    }

    useEffect(() => {
        getUserAccessTypesList()
    }, [])

    useEffect(() => {
        if (Object.keys(accessVariantsObjOne)?.length > 0) {
            setAccessVariantsObj({
                ...accessVariantsObj,
                ...accessVariantsObjOne
            })
        }
    }, [accessVariantsObjOne])

    useEffect(() => {
        if (userAccessTypes?.length > 0) {
            Object.keys(userAccesses)?.forEach((item: string) => {
                getUserAccessVariants(Number(item))
            })
        }
    }, [userAccessTypes])

    const setUserAccessesList = () => {
        let type_id: number = form?.getFieldValue("access_name");
        let variant_id: number = form?.getFieldValue("user_access");
        let is_leader: number = form?.getFieldValue("is_leader");
        if (type_id && variant_id) {
            if (userAccesses[`${type_id}`]) {
                if (!userAccesses[`${type_id}`]?.includes(`${variant_id}-${is_leader ? 1 : 0}`)) {
                    setUserAccesses({
                        ...userAccesses,
                        [`${type_id}`]: [...userAccesses[`${type_id}`], `${variant_id}-${is_leader ? 1 : 0}`]
                    })
                }
            } else {
                setUserAccesses({
                    ...userAccesses,
                    [`${type_id}`]: [`${variant_id}-${is_leader ? 1 : 0}`]
                })
            }
            form.setFieldsValue({
                user_access: null,
                is_leader: false,
                access_name: null
            })
        }
    }

    const deleteUserAccess = async (item: string, key: string) => {
        let b: any = userAccesses;
        if (b[`${key}`]) {
            setUserAccesses({
                ...userAccesses,
                [`${key}`]: userAccesses[`${key}`]?.filter((item1: string) => item !== item1)
            })
        }
    }
    

    return (
        <div>
            <Row gutter={24}>
                <Col span={24}>
                    <strong>User accesses</strong>
                </Col>
                <Col xs={24} sm={12} md={6} lg={4}>
                    <Form.Item
                        required={true}
                        name={`access_name`}
                        label={`Access type name`}
                    >
                        <Select onChange={(e: number) => {
                            getUserAccessVariants(e)
                            form.setFieldsValue({
                                user_access: null,
                                is_leader: false
                            })
                            setSelectedAccess(e)
                        }} onFocus={() => {
                            getUserAccessTypesList();
                        }} placeholder="Select access type name">
                            {
                                userAccessTypes?.map((item: userAccessType, i: number) =>
                                    <Option key={i} value={item?.id}>{item?.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8}>
                    <Form.Item
                        required={true}
                        name={`user_access`}
                        label={`User access`}
                    >
                        <Select showSearch filterOption={(input: any, option: any) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        } onChange={(e: number) => {
                            setDisabled({
                                ...disabled,
                                is_leader: false
                            })
                            if (selectedAccess === 1 && !selectedFaculties.includes(e)) {
                                setSelectedFaculties([...selectedFaculties, e])
                            }
                        }} placeholder="Select a user access!!!" disabled={disabled?.user_access}>
                            {
                                userAccessVariants
                                    ?.filter(
                                        (item: { id: number, name: string, faculty_id?: number }) => item?.faculty_id ? selectedFaculties.includes(item?.faculty_id) : true)
                                    ?.map((item: { id: number, name: string }, i: number) =>
                                        <Option key={i} value={item?.id}>{item?.name}</Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={3} lg={2}>
                    <Form.Item
                        name={`is_leader`}
                        label={t("Is leader")}
                        valuePropName="checked"
                    >
                        <Switch disabled={disabled?.is_leader} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={3} lg={2} style={{ paddingTop: 30 }} >
                    <Button shape="circle" disabled={!Boolean(
                        form?.getFieldValue("access_name") && form?.getFieldValue("user_access")
                    )} onClick={setUserAccessesList}><FaCheck /></Button>
                </Col>
            </Row>
            <Row className="mb-2" gutter={24}>
                <Col sm={24} xs={24} md={12} lg={12}>
                    {
                        <ForTable totalCount={Object.keys(userAccesses).length} showAllData={true} loading={false} datas={Object.entries(userAccesses).map(([key, value]: any, index: number) => {
                            return {
                                key,
                                value,
                                typeName: userAccessTypes?.filter((item: userAccessType) => Number(key) === item.id)[0]?.name,
                            }
                        })} theads={[
                            <>{t("Type name")}</>,
                            <>{t("Access name")}</>,
                            <>{t("Is leader")}</>,
                            <>{t("Actions")}</>
                        ]} tbodys={function(this: any){
                            let split: Array<string> = String(this.value).split("-");
                            let accessName: string = accessVariantsObj[`${this.key}`]?.filter((item: { id: number, name: string }) => item?.id === Number(split[0]))[0]?.name;
                            return [
                                <>{this.typeName}</>,
                                <>{accessName}</>,
                                <><Switch checked={Number(split[1]) === 1} /></>,
                                <>
                                    <div className="d-flex justify-content-center aligin-items-center">
                                        <Button type="link" danger onClick={() => {
                                            deleteUserAccess(this, this.key)
                                            setSelectedFaculties(selectedFaculties?.filter((item: number) => item !== Number(split[0])))
                                        }}><FaTrash /></Button>
                                    </div>
                                </>
                            ]
                        }}/>}
                </Col>
            </Row>
        </div>
    )
}

export default UserAccess;