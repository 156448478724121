import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Tabs, Avatar, Image, Tag } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { useLocation, NavLink } from 'react-router-dom'
import CustomBreadcrumb from 'components/Breadcrumb';
import isHasAuthRoute from "utils/isHasRoute";
import { useTranslation } from 'react-i18next';
import { BiLink } from "react-icons/bi";
import { FILE_URL } from 'config/utils';
import { getAllLanguages, getPassword } from 'services';
import styled from 'styled-components';
import { getTeacherAccessByUserId } from '../../user/servise';
import { checkStatusState } from 'pages/student/crud/utils';
import moment from 'moment';
import { teacherAccessType } from './types';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import useGetAllData from 'hooks/useGetAllData';
import useGetOneData from 'hooks/useGetOneData';
import { isTypedArray } from 'lodash';
const _breadcrump = [
    {
        name: "Home",
        path: "/",
    },
    {
        name: "O'qituvchilar bazasi",
        path: "teachers_index",
    },
    {
        name: "Teacher view",
        path: "teacher_view",
    },
];

type SubjectType = Array<{
    id: number,
    name: string,
    lang: Array<number>
}>;


const { TabPane } = Tabs;

const Teachersview = () => {

    const { t } = useTranslation();
    const location: any = useLocation();
    const path: string = location?.pathname;
    let a: Array<string> = path?.split("/");
    const id: number = Number(a[a.length - 1])
    const [auth_data, setAuth_data] = useState<any>(null);
    const [languages, setLanguages] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState<number>(1)
    const [userAccesses, setUserAccesses] = useState<any>({})
    const [teacherAccesses, setTeacherAccesses] = useState<SubjectType>([])
    const _data = useGetOneData({ url: `users/${id}?expand=teacherAccess,department,profile,user,faculty,direction,createdBy,updatedBy,region,area,country,permanentArea,permanetCountry,permanentRegion,citizenship,nationality`, isCall: 'auto' });
    const _userAccessTypes = useGetAllData({ url: '/user-access-types', isCall: 'auto' });

    useEffect(() => {
        (
            async () => {
                const languages = await getAllLanguages();
                if (Array.isArray(languages?.items) && languages?.items.length) {
                    setLanguages(languages?.items)
                }
            }
        )()
    }, [])

    useEffect(() => {
        if (_userAccessTypes.items?.length && Object.getOwnPropertyNames(_data.data)?.length) {
            const { department } = _data.data;
            let new_arr: any[] = [];
            if (department && Object.getOwnPropertyNames(department)?.length) {
                for (let i = 0; i < _userAccessTypes.items?.length; i++) {
                    const element = _userAccessTypes.items[i];
                    if (department.hasOwnProperty(element?.id) && Array.isArray(department[element?.id]) && department[element?.id]?.length) {
                        let arr: Array<string> = [];
                        department[element?.id]?.forEach((e: any) => arr.push(e?.name));
                        new_arr.push({
                            name: element?.name,
                            value: arr,
                        });
                    }
                }
                setUserAccesses(new_arr);
            }
        }
    }, [_userAccessTypes.items.length, _data.data]);

    useEffect(() => {
        (
            async () => {
                const responseAc = await getTeacherAccessByUserId(Number(id))

                const teacherAccesses = responseAc?.data?.items;
                let a: SubjectType = [];
                teacherAccesses.forEach((item:any) => {
                    a.map((e, i) => {
                        if (item.subject_id === e.id) a[i] = { ...a[i], [`lang`]: [...e.lang, item.language_id] };
                        else if (!a.filter(key => key.id === item.subject_id).length) a = [...a, { [`id`]: item.subject_id, [`name`]: item.subject.name, [`lang`]: [item.language_id] }];
                    })
                    if (!a.length) {
                        a = [...a, { [`id`]: item.subject_id, [`name`]: item.subject.name, [`lang`]: [item.language_id] }];
                    }
                })
                setTeacherAccesses(a)
            }
        )()
    }, [])

    const getAuthData = async () => {
        if (!auth_data) {
            setLoading(true);
            const data = await getPassword(_data.data?.profile?.user_id)
            setAuth_data(data)
            setLoading(false);
        }
    }


    const getName = (id: number): string => {
        return languages?.filter((item: { id: number, name: string }) => item?.id === id)[0]?.name
    };


    return (
        <div className="rounded">
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <CustomBreadcrumb arr={_breadcrump} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                    {isHasAuthRoute("user_update") && <NavLink to={`/update_teacher/${id}`} ><Button type="primary" className="float-end d-f" icon={<EditOutlined />}>{t("Edit")}</Button></NavLink>}
                </Col>
            </Row>

            <Row gutter={[16, 24]} className="mt-3">
                <Col xs={24} sm={24} md={8} lg={7}>
                    <div className="info_view_box px-3 py-2">
                        <div className="d-flex">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Avatar shape="square" size={120} src={_data.data?.profile?.image && <Image src={FILE_URL + _data.data?.profile?.image} style={{ width: 100 }} />}>{_data.data?.profile?.first_name[0]?.toUpperCase()}</Avatar>
                                </Col>
                                <Col className='ps-4' span={16}>
                                    <div><span className="info_text_key">Last name</span>: <span className="info_fio">{_data.data?.profile?.last_name}</span></div>
                                    <div><span className="info_text_key">First name</span>: <span className="info_fio">{_data.data?.profile?.first_name}</span></div>
                                    <div><span className="info_text_key">Status</span>: <span className="info_status1" style={{ backgroundColor: checkStatusState(Number(_data.data?.status)).color }}>{checkStatusState(Number(_data.data?.status)).text}</span></div>
                                </Col>
                            </Row>
                        </div>
                        <hr className="my-2" />
                        <Row gutter={[0, 10]}>
                            <Col xs={12}><span className="info_text_key">Tug'ilgan yili: </span><span className="info_text_value">{_data.data?.profile?.birthday}</span></Col>
                            <Col xs={12}><span className="info_text_key">Jinsi: </span><span className="info_text_value">{Number(_data.data?.profile?.gender) ? "Ekak" : "Ayol"}</span></Col>
                            <Col xs={12}><span className="info_text_key">Tel (asosiy): </span><span className="info_text_value" style={{ whiteSpace: "nowrap" }}>{_data.data?.profile?.phone}</span></Col>
                            <Col xs={12}><span className="info_text_key">Tel (qo'shimcha): </span><span className="info_text_value" style={{ whiteSpace: "nowrap" }}>{_data.data?.profile?.phone_secondary}</span></Col>
                            <Col xs={24}>
                                <span className="info_text_key">Tavsif: </span>
                                <div className="info_view_description">{_data.data?.description}</div>
                            </Col>
                            <Col xs={24}><a className="info_text_key" style={{ color: '#1890ff' }} onClick={getAuthData}>Login va parol: </a></Col>
                            {loading ? <div className="loader-line"></div> : <div style={{ height: '3px' }}></div>}
                            {
                                auth_data && Object.getOwnPropertyNames(auth_data).length ?
                                    <Col span={24}>
                                        <div><span className="info_text_key">username: </span><span className="info_text_value" style={{ whiteSpace: "nowrap" }}>{auth_data?.username}</span></div>
                                        <div><span className="info_text_key">password: </span><span className="info_text_value" style={{ whiteSpace: "nowrap" }}>{auth_data?.password}</span></div>
                                    </Col> : null
                            }

                        </Row>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={{ span: 15 }} lg={{ span: 17 }}>

                    <div className="info_view_box px-3">
                        <Tabs defaultActiveKey="1" onChange={(e: any) => {
                            setActiveTabKey(Number(e));

                        }}>
                            <TabPane tab="Kasbiy ma'lumotlari" key="1">
                                {/* <div className='info_view_box px-2 mt-3'> */}
                                <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                    <Col span={24}>
                                        <Row className="m-2" gutter={24}>
                                            {userAccesses?.length ? userAccesses?.map((element: any, index: number) => (
                                                <Col className={"p-2"} xs={24} sm={24} md={12} lg={12} key={index} >
                                                    <span className="border-bottom"><strong className="mb-1">{t(element?.name)}</strong>:&nbsp;
                                                        {element?.value?.map((e: string, j: number) => (
                                                            <React.Fragment key={j}>{e}{element?.value?.length !== 1 && element?.value?.length - 1 !== j ? "," : ""} &nbsp;&nbsp;</React.Fragment>
                                                        ))}</span>
                                                </Col>
                                            )) : null}
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <hr />
                                    </Col>
                                    <Col span={24}>{t("Teacher subjects")}</Col>
                                    <Col span={24}>
                                        <table className="w-100">
                                            <thead>
                                                <tr>
                                                    <th>№</th>
                                                    <th>Subject</th>
                                                    <th>Languages</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {teacherAccesses?.map((item, i) => (
                                                    <tr key={i}>
                                                        <th scope="row">{i + 1}</th>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            {item?.lang?.map((e) => (
                                                                <span key={e} className="me-3">
                                                                    {getName(e)}
                                                                </span>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                {/* </div> */}
                            </TabPane>
                            <TabPane tab="Passport ma'lumotlari" key="2">
                                <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                    <Col lg={8} sm={8} md={12} xs={24}>
                                        <div><span className="info_text_key">Passport seriyasi va raqami: </span> <span className="info_text_value">{_data.data?.profile?.passport_seria ? `${_data.data?.profile?.passport_seria} ${_data.data?.profile?.passport_number}` : ''}</span></div>
                                    </Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Passport JSHIR: </span> <span className="info_text_value">{_data.data?.profile?.passport_pin}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Kim tomonidan berilgan: </span> <span className="info_text_value">{_data.data?.profile?.passport_given_by}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Passport sanasi: </span> <span className="info_text_value">{_data.data?.profile?.passport_given_date && `${_data.data?.profile?.passport_given_date} - ${_data.data?.profile?.passport_issued_date}`}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Fuqarolik: </span> <span className="info_text_value">{_data.data?.citizenship?.name}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Millati: </span> <span className="info_text_value">{_data.data?.nationality?.name}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Passport fayli: </span>
                                        <span className="info_text_value"> {
                                            _data.data?.profile?.passport_file && _data.data?.profile?.passport_file !== 'undefined' ?
                                                <a href={FILE_URL + _data.data?.profile?.passport_file} target="_blank" rel="noopener noreferrer"><BiLink />&nbsp;link</a> : <span className="text-danger">Yuklanmagan</span>
                                        }</span>
                                    </div></Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Manzil ma'lumotlari" key="3">
                                <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                    <Col lg={24} sm={24} md={24} xs={24}>
                                        <div className="info_text_key">Vaqtincha yashash manzili</div>
                                        <hr className="p-0 m-0 text-secondary" />
                                    </Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Mamlakat: </span> <span className="info_text_value">{_data.data?.country?.name}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Viloyat: </span> <span className="info_text_value">{_data.data?.region?.name}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Tuman: </span> <span className="info_text_value">{_data.data?.area?.name}</span></div></Col>
                                    <Col lg={24} sm={24} md={24} xs={24}><div><span className="info_text_key">Manzil: </span> <span className="info_text_value">{_data.data?.profile?.address}</span></div></Col>
                                    <Col lg={24} sm={24} md={24} xs={24}>
                                        <div className="info_text_key">Doimiy yashash manzili</div>
                                        <hr className="p-0 m-0 text-secondary" />
                                    </Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Mamlakat: </span> <span className="info_text_value">{_data.data?.permanentCountry?.name}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Viloyat: </span> <span className="info_text_value">{_data.data?.permanentRegion?.name}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Tuman: </span> <span className="info_text_value">{_data.data?.permanentArea?.name}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Manzil: </span> <span className="info_text_value">{_data.data?.profile?.permanent_address}</span></div></Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Ilmiy darajalari" key="4">
                                <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Ma'lumot: </span> <span className="info_text_value">Ma'lumot mavjud emas</span></div></Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Kiritilgan va o'zgartirilgan" key="5">
                                <Row gutter={[16, 16]} className="px-2 pb-4 pt-1">
                                    <Col lg={24} sm={24} md={24} xs={24}><div className="info_text_key">Kiritilgan</div><hr className="p-0 m-0 text-secondary" /></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Foydalanuvchi: </span> <span className="info_text_value">{_data.data?.createdBy?.last_name}&nbsp;{_data.data?.createdBy?.first_name}&nbsp;{_data.data?.createdBy?.middle_name}</span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Role: </span> <span className="info_text_value">
                                        {
                                            Array.isArray(_data.data?.createdBy?.role) && _data.data?.createdBy?.role.map((e: string) => <React.Fragment key={e}>{e}</React.Fragment>)
                                        }
                                    </span></div></Col>
                                    <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Kiritgan vaqti: </span> <span className="info_text_value">{moment(_data.data?.created_at).format("DD-MM-YYYY")}</span></div></Col>
                                    {_data.data?.updatedBy && Object.getOwnPropertyNames(_data.data?.updatedBy).length ?
                                        <>
                                            <Col lg={24} sm={24} md={24} xs={24}><div className="info_text_key">O'zgartirilgan</div><hr className="p-0 m-0 text-secondary" /></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Foydalanuvchi: </span> <span className="info_text_value">{_data.data?.createdBy?.last_name}&nbsp;{_data.data?.createdBy?.first_name}&nbsp;{_data.data?.createdBy?.middle_name}</span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">Role: </span> <span className="info_text_value">
                                                {
                                                    Array.isArray(_data.data?.updatedBy?.role) && _data.data?.updatedBy?.role.map((e: string) => <React.Fragment key={e}>{e}</React.Fragment>)
                                                }
                                            </span></div></Col>
                                            <Col lg={8} sm={8} md={12} xs={24}><div><span className="info_text_key">O'zgartirgan vaqti: </span> <span className="info_text_value">{moment(_data.data?.updated_at).format("DD-MM-YYYY")}</span></div></Col>
                                        </> : null
                                    }
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        </div >
    )
};

export default Teachersview

const Wrapper = styled.div`
    .rounded{
        border-radius: 8px !important;
        background-color: white;
    }
    .h5{
        font-size: 19px;
        color: #223C50;
    }

`;