import { Calendar, Card, Col, Radio, Row } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { data_for_tutor, student_data } from "../common/charts/datas";
import MessageInDashboard from "../common/message";
import { WrapperTeacherPanel } from "../TeacherPanel/style";
import { Chart, LineAdvance } from "bizcharts";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import { Area, Line, LineConfig } from "@ant-design/charts";

const TutorPanel: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const [date, setDate] = useState<string>('0')

    const sortData = (data: Array<any>) => {
        var currentIndex = data.length, randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [data[currentIndex], data[randomIndex]] = [data[randomIndex], data[currentIndex]];
        }

        const random = Math.floor(Math.random()*5+1);
        return data.slice(0, random);
    }

    const config: LineConfig = {
        data: data_for_tutor[Number(date)]?.data,
        xField: 'month',
        yField: 'count',
        seriesField: 'gender',
        yAxis: {
          label: {
            formatter: (v: any) => v,
          },
        },
        legend: {
          position: 'top',
        },
        smooth: true,
        animation: {
          appear: {
            animation: 'path-in',
            duration: 2000,
          },
        },
      };

    return (
        <>
            <ForTitle title='Dashboard' buttons={[null]}/>
            <Row>
                <Col sm={24} md={24} lg={24} xl={16} xs={24} className='mb-3'>
                    <Card className="mx-1" title={
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>{t('Talabalar davomati')} ({t(data_for_tutor[Number(date)].name)})</h5>
                            <div className="radButWrap">
                                <Radio.Group defaultValue='0' size="small" value={date} buttonStyle="solid" onChange={e => setDate(e.target.value)} >
                                    <Radio.Button value='0'>Yillik</Radio.Button>
                                    <Radio.Button value='1'>Oylik</Radio.Button>
                                    <Radio.Button value='2'>Haftalik</Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                    }>
                        <Line {...config} />
                    </Card>
                </Col>
                <Col sm={24} md={24} lg={24} xl={8} xs={24}>
                    <Card className="mx-1" title={<h5>{t('Kalendar')}</h5>}>
                        <Calendar fullscreen={false} />
                    </Card>
                </Col>
                <Col sm={24} md={24} lg={24} xl={16} xs={24}>
                    <Card className="mx-1" title={<h5>{t('Ustozlar')}</h5>}>
                        {/* <ForTable loading={true} datas={sortData(student_data)} theads={[
                            <>{t("F.I.O")}</>,
                            <>{t("Fan")}</>,
                            <>{t("Soatlar")}</>,
                        ]} tbodys={function(this: any){
                            return [
                                <>{this?.name}</>,
                                <>{this?.subject}</>,
                                <>{this?.count}</>,
                            ]
                        }}/> */}
                    </Card>
                </Col>
                <Col sm={24} md={24} lg={24} xl={8} xs={24}>
                    <Card className="mx-1" title={<h5>{t('Xabarlar')}</h5>}>
                        <MessageInDashboard />
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default TutorPanel;