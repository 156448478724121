import { ITimeTable, ITimeTableParentNull } from "models/others"
import styles from '../styles/styles.module.scss';
import TimeTableUniqueCode from './TimeTableUniqueCode';
import { FaRegBuilding } from "react-icons/fa";
import { BiBookOpen, BiUserVoice } from "react-icons/bi";

type PropsInformerParentTimeTableItem = {
    element: ITimeTableParentNull | Omit<ITimeTableParentNull, 'seminar'>,
    update: (element: ITimeTableParentNull | ITimeTable) => void
    create: (element: ITimeTableParentNull | ITimeTable) => void
}
type PropsInformerChildTimeTableItem = {
    element: ITimeTable,
    update: (element: ITimeTableParentNull | ITimeTable) => void
}

export const ParentTimeTableItem: React.FC<PropsInformerParentTimeTableItem> = ({ element, update, create }): JSX.Element => {

    return (
        <span
            className={styles.created_time_table_box}
            onClick={() => create(element)}
            onContextMenu={(e) => { update(element); e.preventDefault() }}
        >
            <span className={styles.info_text_subject}><FaRegBuilding />&nbsp;<i style={{ color: 'blue' }}>{element?.week?.name}</i>&nbsp;/&nbsp;{element?.para?.name}.({element?.para?.start_time} - {element?.para?.end_time})&nbsp;/&nbsp;{element?.building?.name}&nbsp;&nbsp;{element?.room?.name} ({element?.room?.capacity}).</span><br />
            <span className={styles.info_text_subject}><BiBookOpen />&nbsp;{element?.subject?.name}&nbsp;(<i style={{ color: 'blue' }}>{element?.subjectCategory?.name}&nbsp;|&nbsp;{element?.language?.name}</i>)</span><br />
            <span className={styles.info_text_teacher}><BiUserVoice />&nbsp;{element.teacher?.last_name}&nbsp;{element.teacher?.first_name}&nbsp;{String(element.teacher?.middle_name).charAt(0)}.</span>
            <TimeTableUniqueCode id={element.id} lecture_id={element.lecture_id} parent_id={element.parent_id} subject_category_id={element.subject_category_id} />
        </span>
    )
}
export const ChildTimeTableItem: React.FC<PropsInformerChildTimeTableItem> = ({ element, update }): JSX.Element => {

    return (
        <span
            className={styles.created_time_table_box}
            onContextMenu={(e) => { update(element); e.preventDefault() }}
        >
            <span className={styles.info_text_subject}><FaRegBuilding />&nbsp;<i style={{ color: 'blue' }}>{element?.week?.name}</i>&nbsp;/&nbsp;{element?.para?.name}.({element?.para?.start_time} - {element?.para?.end_time})&nbsp;/&nbsp;{element?.building?.name}&nbsp;&nbsp;{element?.room?.name} ({element?.room?.capacity}).</span><br />
            <span className={styles.info_text_subject}><BiBookOpen />&nbsp;{element?.subject?.name}&nbsp;(<i style={{ color: 'blue' }}>{element?.subjectCategory?.name}&nbsp;|&nbsp;{element?.language?.name}</i>)</span><br />
            <span className={styles.info_text_teacher}><BiUserVoice />&nbsp;{element.teacher?.last_name}&nbsp;{element.teacher?.first_name}&nbsp;{String(element.teacher?.middle_name).charAt(0)}.</span>
            <TimeTableUniqueCode id={element.id} lecture_id={element.lecture_id} parent_id={element.parent_id} subject_category_id={element.subject_category_id} />
        </span>
    )
}