import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col, Typography, Button } from "antd";
import moment from "moment";
import { BadgeStatusText } from "components/BadgeStatus";
import { getOneRoom } from "../service";
import { asyncN } from "utils/notifications";

const { Text } = Typography;

const RoomView: FC<{ visible: boolean, setVisible: (visible: boolean) => void, id: number | null }> = ({ visible, setVisible, id }): JSX.Element => {

    const { t } = useTranslation();
    const [data, setData] = useState<any>(null);
    const [showCreated, setShowCreated] = useState<boolean>(false);

    useEffect(() => {
        if (visible) {
            (
                async () => {
                    try {
                        const response = await getOneRoom(id, `expand=createdBy,updatedBy,description`)
                        setData(response)
                    } catch (error: any) {
                        setVisible(false)
                        asyncN("error", "read", error?.response?.message)
                    }
                }
            )()
        }
    }, [visible]);

    return (
        <>
            {data ? <Modal
                title={t("Room View")}
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Row gutter={[12, 12]}>
                    <Col span={24} ><Text>{t("Name of room")}: </Text>&nbsp;{data.name}</Col>
                    <Col span={24} ><Text>{t("Description for room")}: </Text>&nbsp;{data?.description}</Col>
                    <Col span={24} ><Text>{t("Capacity of room")}: </Text>&nbsp;{data?.capacity}</Col>
                    <Col className="d-f justify-content-between" span={24}><span><Text>{t("Status")}: </Text><BadgeStatusText status={data.status} /></span> <Button type="link" onClick={() => setShowCreated(!showCreated)} >details</Button> </Col>
                </Row>
                {data.createdBy && showCreated ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Created By")}</Text></Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("First Name")}: </Text>{data.createdBy.first_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data.createdBy.last_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Login")}: </Text>{data.createdBy.username}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data.createdBy.role}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Created at")}: </Text>{moment(data?.created_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}
                {data.updatedBy && showCreated ? <Row gutter={[12, 12]} className="border py-2 mt-2">
                    <Col span={24} ><Text style={{ fontWeight: 'bold' }}>{t("Updated By")}</Text></Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("First Name")}: </Text>{data.updatedBy.first_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Last Name")}: </Text>{data.updatedBy.last_name}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Login")}: </Text>{data.updatedBy.username}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Role")}: </Text>{data.updatedBy.role}</Col>
                    <Col lg={{ span: 12 }} xs={{ span: 24 }}><Text>{t("Updated at")}: </Text>{moment(data?.updated_at).format('MM-DD-YYYY')}</Col>
                </Row> : null}
            </Modal>:null}
        </>
    )
}


export default RoomView;