import { Button, Col, Form, Modal, Popconfirm, Row, Select, Switch, Tag, Tooltip } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import instance from "config/_axios";
import UserAccessUpdate from "pages/employee_tabel/options/user_access_update";
import { editUserAccessStateType } from "pages/employee_tabel/type";
import { _meta } from "pages/user/components/types";
import { getAllUsers } from "pages/user/servise";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CgCheckO } from "react-icons/cg";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { useHistory, useLocation } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { delete_data } from "services";
import isHasAuthRoute from "utils/isHasRoute";
import { asyncN } from "utils/notifications";
import { getOneDepartment } from "../service";
type userAccessType = {
    id: number, is_leader: number, user:
    {
        id: number
        role: Array<string>
        profile: {
            last_name: string
            first_name: string
            middle_name: string
        }
    },
    workRate: { id: number, name: string }
    jobTitle: { id: number, name: string }
    tabel_number: number
}
const DepartmentEmployees: FC = (): JSX.Element => {

    const { t } = useTranslation();
    const [form] = Form.useForm()
    const location: any = useLocation();
    const history = useHistory();

    const _breadcrump = [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Departments",
            path: "departments",
        },
        {
            name: location?.state?.department?.name,
            path: "department-employees",
        },
    ];

    const [users, setUsers] = useState<Array<{ id: number, first_name: string, last_name: string, role: Array<string> }>>([]);
    const [userAccesses, setUserAccesses] = useState<Array<userAccessType>>([]);
    const [userIds, setUserIds] = useState<Array<number>>([])
    const [checkDisabled, setCheckDisabled] = useState<boolean>(true)
    const [_meta, setMeta] = useState<_meta>({
        perPage: 0,
        currentPage: 1,
        pageCount: 0,
        totalCount: 0
    })
    const [refreshAll, setRefreshAll] = useState<boolean>(false)
    const [editUserAccess, setEditUserAccess] = useState<editUserAccessStateType>({ item: {}, visibleModal: false, edit: false })



    useEffect(() => {
        (
            async () => {
                if (!location?.state?.department) history.push('/departments')
                const response = await getAllUsers(`filter={"-role_name":["student","test"]}&per-page=0&page=1`);
                setMeta(response?.data?._meta)
                setUsers(response?.data?.items)
            }
        )()
    }, [])

    useEffect(() => {
        refreshDepartment(location?.state?.department?.id)
    }, [location?.state?.department?.id])

    useEffect(() => {
        (
            async () => {
                let a: Array<number> = []
                userAccesses?.map(async (item: any) => {
                    a.push(item?.user_id)
                })
                setUserIds(a);
            }
        )()
    }, [userAccesses])

    const onFinish = async (values: any) => {
        let val: any = values?.user_access?.map((item: any) => "" + item)

        let data = new FormData();

        data.append("user_access", JSON.stringify(val));
        data.append("table_id", location?.state?.department?.id)

        try {
            const response = await instance({
                method: 'post',
                url: '/departments/user-access',
                data: data
            })
            if (response?.data?.status === 1) {
                asyncN("success", "create", response?.data?.message).then((e) => {
                    refreshDepartment(location?.state?.department?.id)
                    form.resetFields()
                })
            } else {
                asyncN("error", "create", response?.data?.message);
            }
        } catch (e) {
            asyncN("error", "create", "Error create add employee for " + location?.state?.department?.name + " department")
        }

    }

    const refreshDepartment = async (id: number) => {
        const response = await getOneDepartment(id, `expand=userAccess.user.profile,userAccess.workRate,userAccess.jobTitle`)
        setUserAccesses(response?.userAccess);
    }

    const getFullName = (id: number): string => {
        let a: any = users?.filter((item: any) => {
            return item?.id === id
        })

        return String(a[0]?.first_name + " " + a[0]?.last_name);
    }

    return (
        <div>
            <CustomBreadcrumb arr={_breadcrump} />
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row className="mt-2" gutter={24}>
                    <Col xs={24} sm={24} md={20} lg={14}>
                        <Form.Item name="user_access" label={t("User")} rules={[
                            {
                                required: true,
                                message: t("Please select a user")
                            }
                        ]}>
                            <Select
                                mode="multiple"
                                placeholder={t("Please select a user")}
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return option?.children[0]?.props?.children?.props?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                }}
                                onChange={(e) => {
                                    if (e) {
                                        setCheckDisabled(false)
                                    } else setCheckDisabled(true)
                                }}
                            >
                                {
                                    users?.map((item: { id: number, first_name: string, last_name: string, role: Array<string> }, i: number) =>
                                        !userIds?.includes(item?.id) && <Select.Option key={i} value={item?.id}>
                                            <span>
                                                <strong>{item?.first_name + ' ' + item?.last_name + ' '}</strong>
                                            </span>
                                            <span style={{ float: "right" }}>
                                                {
                                                    item?.role?.map((role: string) =>
                                                        <Tag>{role}</Tag>
                                                    )
                                                }
                                            </span>
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={8} sm={6} md={3} lg={2}>
                        <Form.Item
                            name=""
                            label=" "
                        >
                            <Button icon={
                                <CgCheckO
                                    color={"#40a9ff"}
                                    style={{ fontSize: "25px", cursor: "pointer" }}
                                />
                            } type="link" htmlType="submit"></Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row gutter={24}>
                <Col span={24}>
                    {userAccesses?.length > 0 && <Table className="table table-bordered">
                        <Thead>
                            <Tr>
                                <Th style={{ width: "35px" }}>№</Th>
                                <Th>{t("Full name")}</Th>
                                <Th>{t("Role")}</Th>
                                <Th>{t("Job title")}</Th>
                                <Th>{t("Work rate")}</Th>
                                <Th>{t("Tabel number")}</Th>
                                <Th>{t("Is leader")}</Th>
                                <Th style={{ width: "85px" }}>{t("Actions")}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                userAccesses?.sort((item1: { is_leader: number }, item2: { is_leader: number }) => item2?.is_leader - item1?.is_leader)?.map((item: userAccessType, i: number) =>
                                    <Tr className="table-row-hover">
                                        <Td>
                                            {i + 1}
                                        </Td>
                                        <Td>
                                            {
                                                item?.user?.profile?.first_name + " "
                                                + item?.user?.profile?.last_name + " "
                                                + item?.user?.profile?.middle_name
                                            }
                                        </Td>
                                        <Td>{item?.user?.role?.map((item1: string) =>
                                            <Tag>{item1}</Tag>
                                        )}</Td>
                                        <Td>{item?.jobTitle?.name}</Td>
                                        <Td>{item?.workRate?.name}</Td>
                                        <Td>{item?.tabel_number}</Td>
                                        <Td>
                                            <Switch checked={item?.is_leader === 1} />
                                        </Td>
                                        <Td>
                                            <div className="crud_actions_table">
                                                {isHasAuthRoute("user-access_update") && <Tooltip placement="top" title={t("Edit")}><TbFilePencil className="edit_btn" onClick={() => {
                                                    setEditUserAccess({
                                                        visibleModal: true,
                                                        item: item,
                                                        edit: true
                                                    })
                                                }} /></Tooltip>}
                                                <Tooltip placement="top" title={t("Delete")}>
                                                    <Popconfirm
                                                        placement="topLeft"
                                                        title={`${t("Deleted it")} ?`}
                                                        okText={t("Yes")}
                                                        cancelText={t("No")}
                                                        onConfirm={() => {
                                                            delete_data('/user-accesses', item?.id).then((e) => {
                                                                if (e === "success") {
                                                                    refreshDepartment(location?.state?.department?.id)
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <TbFileOff 
                                                            className="delete_btn"
                                                            style={{
                                                                display: isHasAuthRoute("department_delete")
                                                                    ? ""
                                                                    : "none",
                                                            }}
                                                        />
                                                    </Popconfirm>
                                                </Tooltip>
                                            </div>
                                        </Td>
                                    </Tr>
                                )
                            }
                        </Tbody>
                    </Table>}
                </Col>
                <Modal footer={false} visible={editUserAccess?.visibleModal} onCancel={() => setEditUserAccess({
                    item: null, visibleModal: false, edit: editUserAccess?.edit
                })}>
                    <UserAccessUpdate
                        refresh={refreshAll}
                        setRefresh={setRefreshAll}
                        editUserAccessState={editUserAccess}
                        setEditUserAccessState={setEditUserAccess}
                    />
                </Modal>
            </Row>
        </div>
    )
}
export default DepartmentEmployees