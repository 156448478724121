export const ArrayChecker = (arr: Array<any>) => {
    if (Array.isArray(arr) && arr.length) {
        return {
            array: arr,
            _: true
        };
    } else {
        return {
            _: false
        }
    }
}