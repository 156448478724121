import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const Course: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="courses"
                indexTitle="Courses"
                editTitle="Course edit"
                viewTitle="Course view"
                createTitle="Course create"
                permissions={{
                    view_: "course_view",
                    delete_: "course_delete",
                    update_: "course_update",
                    create_: "course_create"
                }}
            />
        </div>
    )
}

export default Course;