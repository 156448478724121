
import { useEffect, useState } from 'react';
import { Form, Modal, Input, Switch, Button, Col, Select } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { useTranslation } from 'react-i18next';
import { ServerErrors } from 'utils/validationFunctions';
import ModalTitle from 'components/ModalTitle';
import { BiX } from 'react-icons/bi';
import { TypeSimpleUpdateProps } from 'pages/common/simple_edit';
import useGetAllData from 'hooks/useGetAllData';

const { Option } = Select;

const LanguageCertificateTypeEdit: React.FC<Omit<TypeSimpleUpdateProps, 'title'>> = ({ url, visible, setVisible, setReFetch }) => {

  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();
  const [clicked, setClicked] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>(true);

  const languages = useGetAllData({ url: `languages?all=1`, isCall: "auto" });

  useEffect(() => {
    if (visible) {
      (
        async () => {
          try {
            const res = await instance({ method: 'GET', url });
            const data = res.data?.data;
            if (res.data.status === 1) {
              form.setFieldsValue({
                name: data?.name,
                lang_id: data?.lang_id,
                status: Number(data?.status) === 1 ? setStatus(true) : setStatus(false)
              })
              // console.log(languages.items.filter((e: any) => e?.lang_code === data?.lang)[0]?.id);

            } else {

            }
          } catch (error: any) {
            setVisible(false)
            asyncN("error", "read", error?.response?.message)
          }
        }
      )()
    }
  }, [visible]);

  const onSubmit = async (values: any) => {
    try {
      setClicked(true)
      const formdata = new FormData();
      formdata.append(`name[${i18n.language}]`, values?.name)
      formdata.append(`lang_id`, values?.lang_id)
      formdata.append('status', status ? '1' : '0');

      const response = await instance({ url, method: 'PUT', data: formdata })

      if (response.data.status === 1) {
        asyncN("success", 'update', response.data?.message)
          .then(() => {
            setVisible(false);
            resetFields()
            setReFetch(prevState => !prevState);
          })
      } else {
        asyncN("error", 'update', response.data?.message)
          .then(() => {
            setVisible(false);
            resetFields()
            setReFetch(prevState => !prevState)
          })
      }
      setClicked(false)
    } catch (error: any) {
      if (error?.response?.status === 422) {
        if (error?.response?.data?.status === 0) {
          form.setFields(ServerErrors(error?.response?.data?.errors[0]))
        }
      }
      setClicked(false)
      setReFetch(prevState => !prevState)
      asyncN("error", 'update', error?.response?.data?.errors[0])
        .then(() => {
          setVisible(false);
          resetFields()
        })
    }
  }

  const resetFields = () => form.resetFields();

  return (
    <>
      <Modal
        title={<ModalTitle>Language certificate type edit</ModalTitle>}
        visible={visible}
        onCancel={() => { setVisible(false); resetFields() }}
        okText="Submit"
        footer={null}
        closeIcon={<BiX size={20} />}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
        >
          <Form.Item
            label={t("Name")}
            name="name"
            shouldUpdate
            rules={[{ required: true, message: 'Please input edu type name !' }]}
          >
            <Input placeholder={t("Enter edu type name") + " ..."} />
          </Form.Item>
          <Form.Item
            label={t("Language")}
            name="lang_id"
            shouldUpdate
            rules={[{ required: false, message: 'Please input edu type name !' }]}
          >
            <Select  placeholder={t("Select certificate language") + " ..."} >
              {
                languages.items?.length ? languages.items?.map((e, i) => (
                  <Option key={i} value={e?.id} >{e?.name} ({e?.lang_code})</Option>
                )) : null
              }
            </Select>
          </Form.Item>
          <Form.Item
            label={t("Status")}
            name="status"
          >
            <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
          </Form.Item>
          <hr />
          <Col span={24} className="text-end mt-2">
            <Button type="primary" htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
            <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
          </Col>
        </Form>
      </Modal>
    </>
  )
}

export default LanguageCertificateTypeEdit;