import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabelCrud";
import CardContainer from "components/Widgets/CardContainer";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import useStoreData, { ReturnTypeUseStoreData } from "hooks/useStoreData";
import { ICountry, IRegion, IArea, ICitizenship, IRole, IParty, IDegreeInfo, IAcademicDegree, IDegree, IDiplomType } from "models/others";
import React from "react";
import { useTranslation } from "react-i18next";
import InputMask from 'react-input-mask';
import { useLocation } from "react-router-dom";
import NewUserAccess from "./user_access";
// import UserPosition from "./UserPosition";


const { Option } = Select;

type UserFormAdditionalPropsInformer = {
    form: FormInstance<any>,
    userAccess: Record<string, string[]>,
    setUserAccess: React.Dispatch<React.SetStateAction<Record<string, string[]>>>,
}

const UserAdditionalInfo = ({ form, userAccess, setUserAccess, }: UserFormAdditionalPropsInformer) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [country_id, setCountryId] = React.useState<number | undefined>(229);
    const [region_id, setRegionId] = React.useState<number | undefined>(undefined);
    const countries = useStoreData({ key: 'countries', params: { 'per-page': 0 } }) as ReturnTypeUseStoreData<ICountry>;
    const regions = useGetAllData({ url: `/regions?filter=${JSON.stringify({ country_id })}`, isCall: 'auto' }) as TypeReturnUseGetAllData<IRegion>;
    const areas = useGetAllData({ url: `/areas?filter=${JSON.stringify({ region_id })}`, perPage: 0 }) as TypeReturnUseGetAllData<IArea>;
    const statuses = useGetAllData({ url: '/user-statuses', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<ICitizenship>;
    const roles = useGetAllData({ url: '/roles', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<IRole>;
    const party = useGetAllData({ url: '/partiyas?sort=-id', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<IParty>;
    const degree_infos = useGetAllData({ url: '/degree-infos?sort=-id', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<IDegreeInfo>;
    const academic_degree = useGetAllData({ url: '/academic-degrees?sort=-id', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<IAcademicDegree>;
    const degree = useGetAllData({ url: '/degrees?sort=-id', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<IDegree>;
    const diplom_types = useGetAllData({ url: '/diploma-types?sort=-id', perPage: 0, isCall: 'auto' }) as TypeReturnUseGetAllData<IDiplomType>;

    return (
        <div className="custom_form_style">
            <CardContainer>
                <Row gutter={[12, 0]}>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Main phone number</FormLabelCrud>}
                            name="phone"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <InputMask mask="+\9\98 (99) 999 99 99" className='ant-input' placeholder='+998 (90) 999 99 99' />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Second phone number</FormLabelCrud>}
                            name="phone_secondary"
                            rules={[{ required: false, message: t("Fill in the field!") }]}
                        >
                            <InputMask mask="+\9\98 (99) 999 99 99" className='ant-input' placeholder='+998 (90) 999 99 99' />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Email</FormLabelCrud>}
                            name="email"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Username</FormLabelCrud>}
                            name="username"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Password</FormLabelCrud>}
                            name="password"
                            rules={[{ required: false, message: t("Fill in the field!") }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Re-password</FormLabelCrud>}
                            name="rePassword"
                            rules={[{ required: false, message: t("Fill in the field!") }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Role</FormLabelCrud>}
                            name="role"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Select
                                mode="multiple"
                                onClick={() => roles.fetch()}
                            >
                                {
                                    roles.items.length ?
                                        roles.items.map((element) => <Option key={element.name} value={element.name} >{element.name}</Option>) :
                                        <Option>Ma'lumot mavjud emas</Option>
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            name="status"
                            label={<FormLabelCrud>Status</FormLabelCrud>}
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                            initialValue={'10'}
                        >
                            <Select
                                className="w-100"
                                onFocus={() => statuses.fetch()}
                            >
                                {
                                    statuses.data ? Object.entries(statuses.data).map((e) => {
                                        const [key, value]: any = e;
                                        return <Option key={key} value={key} >{value}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Mamlakat (hozirgi)</FormLabelCrud>}
                            name="country_id"
                            rules={[{ required: true, message: t("Fill in the feild!") }]}
                        >
                            <Select
                                showSearch
                                onFocus={() => countries._fetch()}
                                onChange={(e: number) => {
                                    setCountryId(e);
                                    form.setFieldsValue({
                                        region_id: undefined,
                                        area_id: undefined,
                                        address: undefined
                                    })
                                }}
                                // placeholder={"Select a permanent country"}
                                optionFilterProp="children"
                                loading={countries.loading}
                                allowClear
                            >
                                {
                                    countries.items.length ? countries.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Region</FormLabelCrud>}
                            name="region_id"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Select
                                showSearch
                                // placeholder={"Select a permanent region"}
                                optionFilterProp="children"
                                onChange={(e: number) => {
                                    setRegionId(e);
                                    form.setFieldsValue({
                                        area_id: undefined,
                                        address: undefined
                                    });
                                }}
                                onFocus={() => regions.fetch()}
                                loading={regions.loading}
                                allowClear
                            >
                                {
                                    regions.items.length ? regions.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Area</FormLabelCrud>}
                            name="area_id"
                            rules={[{ required: true, message: t("Fill in the field!") }]}
                        >
                            <Select
                                showSearch
                                onChange={() => {
                                    form.setFieldsValue({
                                        address: undefined
                                    })
                                }}
                                // placeholder={t(`Select a permanent area`)}
                                optionFilterProp="children"
                                disabled={!regions.items.length}
                                onFocus={() => areas.fetch()}
                                loading={areas.loading}
                                allowClear
                            >
                                {
                                    areas.items.length ?
                                        areas.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Address</FormLabelCrud>}
                            name="address"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input.TextArea rows={1} />
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Diplom type</FormLabelCrud>}
                            name="diploma_type_id"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onFocus={() => diplom_types.fetch()}
                                loading={diplom_types.loading}
                                allowClear
                            >
                                {
                                    diplom_types.items.length ?
                                        diplom_types.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Degree</FormLabelCrud>}
                            name="degree_id"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                onFocus={() => degree.fetch()}
                                loading={degree.loading}
                            >
                                {
                                    degree.items.length ?
                                        degree.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Academic degree</FormLabelCrud>}
                            name="academic_degree_id"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onFocus={() => academic_degree.fetch()}
                                loading={academic_degree.loading}
                                allowClear
                            >
                                {
                                    academic_degree.items.length ?
                                        academic_degree.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Degree info</FormLabelCrud>}
                            name="degree_info_id"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onFocus={() => degree_infos.fetch()}
                                loading={degree_infos.loading}
                                allowClear
                            >
                                {
                                    degree_infos.items.length ?
                                        degree_infos.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Party</FormLabelCrud>}
                            name="partiya_id"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onFocus={() => party.fetch()}
                                loading={party.loading}
                                allowClear
                            >
                                {
                                    party.items.length ?
                                        party.items.map((e: any, i: number) => <Option key={i} value={e.id}>{e.name}</Option>) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            label={<FormLabelCrud>Xodim haqida qisqacha ma'lumot</FormLabelCrud>}
                            name="description"
                        >
                            <Input.TextArea></Input.TextArea>
                        </Form.Item>
                    </Col>
                </Row>
            </CardContainer>
            <CardContainer className="mt_12">
                <NewUserAccess userAccess={userAccess} setUserAccess={setUserAccess} />
            </CardContainer>
        </div>
    )

}




export default UserAdditionalInfo;