import { FC, useEffect, useState } from "react";
import { Col, Form, message, Row, Spin } from "antd";
import "suneditor/dist/css/suneditor.min.css";
import { asyncN } from "utils/notifications";
import CustomBreadcrumb from "components/Breadcrumb";
import instance from "config/_axios";
import { useHistory, useLocation } from "react-router-dom";
import { getOneQuestions } from "../servise";
import { FILE_URL } from "config/utils";
import { useTranslation } from "react-i18next";
import FeildsUi from "./ui";
import { subQuestionType } from "./types";
import { useRef } from "react";

const EditQuestions: FC = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const ref: any = useRef();
  const [fileList, setFileList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editorVal, setEditorVal] = useState<any>();
  const [typeQuestion, setTypeQuestion] = useState<string>("");
  const [subQuestions, setSubQuestions] = useState<Array<subQuestionType>>([]);
  const [sending, setSending] = useState<boolean>(false);
  const state: string = useLocation().pathname;
  let a: Array<string> = state?.split("/");

  const _breadcrump = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Questios",
      path: "questions",
    },
    {
      name: "Edit question",
      path: "question_edit",
    },
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const resp = await getOneQuestions(
          Number(a[a?.length - 1]),
          "expand=subject,course,semestr,questionType,language"
        );

        form.setFieldsValue({
          level: resp?.level,
          lang_id: resp?.lang_id,
          question_type_id: resp?.question_type_id,
          subject_id: String(resp?.subject_id + "-" + resp?.semestr_id),
          semestr_id: resp?.semestr_id,
          course_id: resp?.course_id,
        });
        setEditorVal(resp?.question);
        if (resp?.question_file !== null) {
          setFileList([
            {
              uid: "-1",
              name: "Question file",
              status: "done",
              url: FILE_URL + resp?.question_file,
              thumbUrl:
                "https://www.seaicons.com/wp-content/uploads/2015/11/document-file-icon.png",
            },
          ]);
        }
        if (ref?.current) {
          ref?.current?.getSemestrs(resp?.course_id);
        }

        setTypeQuestion(resp?.questionType?.name);
        if (resp?.subQuestion) {
          setSubQuestions(resp?.subQuestion);
        }
        setIsLoading(false);
      } catch (e: any) {
        asyncN("error", "update", "Error get question!!!");
        setIsLoading(false);
      }
    })();
  }, [a[a?.length - 1]]);

  const onFinish = async (values: any) => {
    let data = new FormData();
    let subQuestion: Array<{ percent: number; question: string }> = [];
    let percentCountAndSumm = { count: 0, summ: 0 };
    Object.entries(values).forEach(([key, value]: any) => {
      key as string;
      if (key === "question_file") {
        if (value) {
          data.append(key, value?.file?.originFileObj);
        }
      } else if (String(key)?.startsWith("persent")) {
        let a: string = key;
        let b: Array<string> = a.split("-");
        percentCountAndSumm = {
          count: percentCountAndSumm?.count + 1,
          summ: percentCountAndSumm?.summ + Number(value),
        };
        subQuestion = [
          ...subQuestion,
          {
            percent: value,
            question: values[`question-${b[1]}`],
          },
        ];
      } else if (String(key)?.startsWith("question_type_id")) {
        data.append("question_type_id", value);
      } else if (String(key)?.startsWith("question")) {
      } else if (key === "general_question") {
        data.append("question", value);
      } else if (key === "subject_id") {
        let a: Array<string> = value?.split("-")
        data.append(key, a[0])
        data.append("semestr_id", a[1])
      } else {
        data.append(key, String(value));
      }
    });
    data.append("sub_question", JSON.stringify(subQuestion));

    if (percentCountAndSumm?.summ === 100) {
      try {
        setSending(true);
        const res = await instance({
          method: "put",
          url: "/questions/" + a[a?.length - 1],
          data: data,
        });
        if (res?.data?.status === 1) {
          asyncN("success", "update", res?.data?.message).then((r: any) => {
            history.push("/questions");
            setSending(false);
          });
        } else {
          asyncN("error", "create", res?.data?.message);
          setSending(false);
        }
      } catch (e) {
        asyncN("error", "update", "Error create question!!!");
        setSending(false);
      }
    } else {
      if (percentCountAndSumm?.summ > 100) {
        message.error(
          percentCountAndSumm?.summ +
          " umumiy foizlar yig'indisi! Foizlar yig'indisi 100 dan ko'p bo'lmasligi kerak!"
        );
      } else {
        message.error(
          percentCountAndSumm?.summ +
          " umumiy foizlar yig'indisi! Foizlar yig'indisi 100 dan kam bo'lmasligi kerak!"
        );
      }
    }
  };

  return (
    <Row gutter={24}>
      <Col span={24}>
        <CustomBreadcrumb arr={_breadcrump} />
      </Col>
      {isLoading ? (
        <div className="loader-line"></div>
      ) : (
        <div style={{ height: "3px" }}></div>
      )}
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="mt-2"
        >
          <Spin spinning={sending}>
            <FeildsUi
              ref={ref}
              onFinish={onFinish}
              setEditorVal={setEditorVal}
              editorVal={editorVal}
              subQuestions={subQuestions}
              setSubQuestions={setSubQuestions}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
            />
          </Spin>
        </Form>
      </Col>
    </Row>
  );
};

export default EditQuestions;
