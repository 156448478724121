import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const QuestionTypes: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="question-types"
                indexTitle="Question types"
                createTitle="Create question types"
                editTitle="Question type edit"
                viewTitle="Question type view"
                permissions={{
                    view_: "question-type_view",
                    delete_: "question-type_delete",
                    update_: "question-type_update",
                    create_: "question-type_create"
                }}
            />
        </div>
    )
}

export default QuestionTypes;