import { ChangeEvent, useEffect, useState } from "react";
import { Tag, Row, Col, Select, Input, Pagination, Divider, Alert, message } from "antd";
import EmptyTable from "components/EmptyTable";
import IndexPageTitle from "components/IndexPageTitle";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Table, Thead, Tr, Th, Tbody, Td } from "react-super-responsive-table";
import isHasAuthRoute from "utils/isHasRoute";
import useGetAllData from "hooks/useGetAllData";
import { BiCheck, BiRightArrowAlt } from "react-icons/bi";
import { exam_student_status_list } from "../exam_status_list";
import useDebounce from "hooks/useDebounce";
import { BadgeStatusTag } from "components/BadgeStatus";
import { EmptySpaceLine, TableLineLoader } from "components/SimpleComponents";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import "./styles.scss";
import { StatusData } from "components/Status";
import { isRoleExact } from "utils/auxiliary_functions";
import isRole from "utils/isRole";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";


const { Search } = Input;
const { Option } = Select;

const TeacherRoleExams = () => {

    const { t } = useTranslation();
    const history = useHistory();

    const { value, writeToUrl } = useUrlQueryParams({ perPage: 20 });
    const debouncedValue = useDebounce<string>(value.filter_like?.text, 500);

    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(20)

    const _subjects = useGetAllData({ url: `/subjects?filter={"kafedra_id":${value.filter?.kafedra_id}}&q=${debouncedValue ?? ''}`, isCall: 'auto', currentPage: value.currentPage, perPage: value.perPage, refetch: [value.filter?.kafedra_id, debouncedValue] })
    const _exams = useGetAllData({ url: `/exams?sort=-id&expand=checkCount,examStudentCount,appeal,appealCount${value.filter?.subject_id ? `&subject_id=${value.filter?.subject_id}` : ''}`, refetch: [value.filter?.subject_id], perPage: perPage, currentPage: currentPage, isCall: 'auto' });
    const _kafedra = useGetAllData({ url: '/kafedras' });

    useEffect(() => {
        if (_subjects.items.length && !value.filter?.subject_id) {
            writeToUrl({ name: "subject_id", value: _subjects.items[0]?.id })
        }
    }, [_subjects.items.length])

    useEffect(() => {
        if (value.filter?.subject_id) {
            _exams.fetch()
        }
    }, [value.filter?.subject_id])

    return (
        <div className="not_user_select">
            <ForTitle title="Fanlar bo'yicha imtihonlar" buttons={[null]}/>
            <Row gutter={[12, 12]}>
                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                    <Row gutter={[12, 12]}>
                        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                            <Select
                                placeholder={t("Filter by lectern")}
                                value={value.filter?.kafedra_id}
                                allowClear
                                showSearch
                                onChange={(e: number) => writeToUrl({ name: "kafedra_id", value: e, items: _kafedra.items })}
                                onFocus={() => { if (!_kafedra.items?.length) { _kafedra.fetch() } }}
                                className="w-100"
                                disabled={!isHasAuthRoute('kafedra_index')}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }

                            >
                                {value.item?.kafedra_id && !_kafedra.items?.length ? <Option value={value.item?.kafedra_id?.id}>{value.item?.kafedra_id?.name}</Option> :
                                    _kafedra.items?.length && _kafedra.items.map((item: { id: number, name: string }, i: number) =>
                                        <Option key={i} value={item?.id}>{item?.name}</Option>
                                    )}
                            </Select>
                        </Col>
                        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                            <Search placeholder={`${t("Input search text")} ...`} value={value.filter_like?.text} onChange={(e: ChangeEvent<HTMLInputElement>) => writeToUrl({ name: "text", value: e.target.value })} />
                        </Col>
                        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
                            <Tag className="m-0">Jami {_subjects.data._meta.totalCount} ta</Tag>
                        </Col>
                    </Row>
                    <ForTable toUrl={true} perPage={value.perPage} setCurrentPage={() => {}} setPerPage={() => {}} currentPage={value.currentPage} totalCount={_subjects.data._meta.totalCount} loading={_subjects.loading} datas={_subjects.items} theads={
                            [
                                <>{t("Subjects")}</>
                            ]
                        } tbodys={
                            function(this: any){
                                return [
                                    <div className="exam_subject_row_table cursor-pointer" style={{ backgroundColor: this?.id === Number(value.filter?.subject_id) ? '#e6f7ff' : '' }} onClick={() => { writeToUrl({ name: "subject_id", value: this?.id }) }}>
                                        {this.name}
                                    </div>
                                ]
                            }
                        }/>
                </Col>
                <Col xl={14} lg={14} md={14} sm={24} xs={24} >
                    <Alert message="Imtihon natijalari e'lon qilingandan so'ng ishlarni tekshirish imkoni bo'lmaydi!" banner className="mb-2"/>
                    <ForTable perPage={perPage} setCurrentPage={setCurrentPage} setPerPage={setPerPage} currentPage={currentPage} totalCount={_exams.data._meta.totalCount} loading={_exams.loading} datas={_exams.items} theads={
                            [
                                <>{t("Exams")}</>,
                                <>{t('Ball')}</>,
                                <>{t("Status")}</>,
                                <><span style={{ color: "#108ee9" }}>{t("Ishlar")}</span> / <span style={{ color: "#f50" }}>{t("Apelatsiyalar")}</span></>,
                                /* <Th className="text-center">{t("Checking")}</Th> */
                            ]
                        } tbodys={
                            function(this: any){
                                return [
                                    (isHasAuthRoute("student-exam_index") && Number(this?.status) === 1) ?
                                    <Link to={`/student-exam-index/${this?.id}`}>
                                        {this?.name}
                                    </Link>
                                    :
                                    <div>
                                        {this?.name}
                                    </div>,
                                    // <div onClick={() => {
                                    //     if (isHasAuthRoute("student-exam_index") && Number(this?.status) === 1) {
                                    //         history.push(`/student-exam-index/${this?.id}`)
                                    //     }
                                    // }}
                                    //     className={isHasAuthRoute("student-exam_index") && Number(this?.status) === 1 ? "show-student-_exams-page-style" : ""}><a>{this?.name}</a></div>,
                                    <div className="text-center">{this?.min_ball} - {this?.max_ball}</div>,
                                    <div className="text-center">{exam_student_status_list(this?.status)}</div>,
                                    <div className="text-center w_180">
                                        {
                                            this?.status === 3 ?
                                            <Link className="w_70 d-inline-block cursor-pointer" to={`student-exam-questions/${this?.id}`}>
                                                {this?.checkCount} / {this?.examStudentCount}
                                            </Link>
                                            : 
                                            <Tag color="#108ee9" className="w_70 d-inline-block cursor-pointer" onClick={() => { message.warning('Imtihon tekshirish yakunlangan !') }} >{this?.checkCount} / {this?.examStudentCount}</Tag>
                                        }
                                        <Link to={`/appelation_cards/${this?.id}`} style={{color: '#f50'}} className="w_70 d-inline-block cursor-pointer">
                                            {0} / {this?.appealCount}
                                        </Link>
                                    </div>
                                    /* <Td style={{ width: "100px", maxWidth: '120px' }} className="text-center">
                                        {this?.status === 3 ? <NavLink to={`student-exam-questions/${this?.id}`}>
                                            <Tag color="success" style={{ minWidth: '90px' }} className="cursor-pointer" >Tekshirish <BiRightArrowAlt /></Tag>
                                        </NavLink> : <EmptySpaceLine />}
                                    </Td> */
                                ]
                            }
                        }/>                  
                </Col>
            </Row>
        </div>
    )

}


export default TeacherRoleExams;