import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Input, Row, Col, Select, Button, Switch, DatePicker, Modal } from 'antd';
import { FaTimes } from 'react-icons/fa';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { getAllLanguages } from 'services';
import { ServerErrors } from 'utils/validationFunctions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ModalTitle from 'components/ModalTitle';

type CreateEduYear = {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setReFetch: React.Dispatch<React.SetStateAction<boolean>>,
}

const { Option } = Select;
const { RangePicker } = DatePicker

const CreateEduYear: FC<CreateEduYear> = ({ visible, setVisible, setReFetch }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [clicked, setClicked] = useState<boolean>(false)
    const [languages, setLanguages] = useState<Array<any>>([]);
    const [status, setStatus] = useState<boolean>(true)
    const [names, setNames] = useState<Array<string>>(['uz'])

    useEffect(() => {
        (
            async () => {
                const response: any = await getAllLanguages();
                setLanguages(response?.items)
            }
        )()
    }, [])

    const onAddNewForm = () => {
        const langId = languages.filter(e => !names.includes(e.lang_code))
        if (langId.length) {
            setNames(prevState => ([...prevState, langId[0].lang_code]))
        }
    }


    const removeInput = (id: string) => {
        const newNames = names.filter(e => e !== id);
        setNames(newNames)
    }

    const selectLanguage = (newValue: string, oldValue: string) => {
        const index = names.indexOf(oldValue);

        if (!names.includes(newValue)) {
            names.splice(index, 1, newValue)
            setNames([...names]);
        } else {
            return
        }
    }

    const onSubmit = async (values: any) => {

        try {
            setClicked(true)
            const formdata = new FormData();
            Object.entries(values).forEach(([key, value]: any) => {
                if (key === 'status') {
                    formdata.append(key, status ? '1' : '0');
                } else {
                    if (key.startsWith("name")) {
                        formdata.append(key, String(new Date(String(moment(value[0]).format("YYYY"))).getFullYear() + "-" + new Date(String(moment(value[1]).format("YYYY"))).getFullYear()))
                    } else if (key === 'year') {
                        formdata.append(key, String(moment(value).format("YYYY")))
                    } else {
                        formdata.append(key, String(value));
                    }
                }
            })

            const response = await instance({ url: '/edu-years', method: 'POST', data: formdata });


            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); setVisible(false); setReFetch(propState => !propState) })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    const resetFields = () => form.resetFields();



    return (
        <Modal
            title={'Create edu year'}
            visible={visible}
            onCancel={() => { setVisible(false); resetFields() }}
            width={800}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
            >
                <Row gutter={[12, 0]}>
                    {
                        names?.map((e: any) => {
                            return (
                                <React.Fragment key={e} >
                                    <Col xl={{ span: 8 }} lg={{ span: 8 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                        <Form.Item
                                            label={t(`Year`)}
                                            name={`year`}
                                            shouldUpdate
                                            rules={[{ required: true, message: "Select year!" }]}
                                        >
                                            <DatePicker placeholder={t("Select year")} format="YYYY" picker="year" style={{ width: '100%', }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={{ span: 16 }} lg={{ span: 16 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                        <Form.Item
                                            label={`${t("Name")}(${e})`}
                                            name={`name[${e}]`}
                                            shouldUpdate
                                            rules={[{ required: true, message: `${t("Fill in the field!")}(${e}) ...` }]}
                                        >
                                            <RangePicker placeholder={[t("Start date"), t("End date")]} format="YYYY" picker="year" style={{ width: '100%', }} />
                                        </Form.Item>
                                    </Col>
                                    <Col key={e} span={24} className="form_ui_flex">
                                        <Form.Item
                                            label={`${t("Description")} (${e})`}
                                            name={`description[${e}]`}
                                            shouldUpdate
                                            rules={[{ required: false, message: `${t("Fill in the field!")}(${e}) ...` }]}
                                            className="form_ui_flex_form_item"
                                        >
                                            <Row wrap={false}>
                                            <Input.TextArea rows={1} placeholder={`${t("Enter description for edu year")} (${e}) ...`} />
                                            <Select defaultValue={e} style={{ width: '100px' }} className="mx-2" onChange={(newElement) => selectLanguage(newElement, e)}>
                                                {
                                                    languages?.length && languages?.map((l: any) => {
                                                        if (!names?.includes(l.lang_code) || l.lang_code === e) {
                                                            return <Option key={l.id} value={l.lang_code}>{l.name}</Option>
                                                        }
                                                    })
                                                }
                                            </Select>
                                            <Button className='d-flex justify-content-center align-items-center' shape="circle" onClick={() => removeInput(e)} disabled={names.length === 1} >
                                                <FaTimes />
                                            </Button>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </React.Fragment>
                            )
                        })
                    }
                    <Col span={24}>
                        {names?.length !== languages?.length ?
                            <Button type="link" className="ps-0" onClick={onAddNewForm}>+ {t("Add translation")}</Button> :
                            <Button type="link" className="ps-0" danger>+ {t("Add translation")}</Button>}

                    </Col>
                </Row>
                <hr />
                <Col span={24} className="mt-2">
                    <Row gutter={[12, 12]}>
                        <Col lg={{ span: 12 }}>
                            <Form.Item
                                name="status"
                                className='mb-0'
                            >
                                <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
                            </Form.Item>
                        </Col>
                        <Col lg={{ span: 12 }} className="text-end">
                            <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { setVisible(false); resetFields() }}>{t("Cancel")}</Button>
                            <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                            <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </Modal>
    )
}

export default CreateEduYear