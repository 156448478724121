import { FC, ChangeEvent } from "react"
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table"
import { Tooltip, Popconfirm, Button, Pagination, Input, Select, Row, Col, Tag } from "antd";

import isHasAuthRoute from "utils/isHasRoute";
import BadgeStatus from "components/BadgeStatus";
import { delete_data } from "services";
import { getAllLecterns } from "./service";
import { asyncN } from "utils/notifications";
import EmptyTable from "components/EmptyTable";
import useDebounce from "hooks/useDebounce";
import { getAllFaculties } from "../faculty/service";
import IndexPageTitle from "components/IndexPageTitle";
import DiscriptionStyle from "components/DescriptionStyle";
import CreateLectern from "./crud/create";
import { ButtonCreate } from "components/Buttons";
import EditLectern from "./crud/edit";
import LecternView from "./crud/view";
import Actions from "components/Actions";
import { TableLineLoader } from "components/SimpleComponents";
import TotalCount from "components/totalCountTag";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";

const { Search } = Input;
const { Option } = Select;

const Lectern: FC = (): JSX.Element => {

    const { i18n, t } = useTranslation();
    const history = useHistory()
    const [data, setData] = useState<Array<any>>([]);
    const [faculties, setFaculties] = useState<Array<any>>([]);
    const [visibleView, setVisibleView] = useState<boolean>(false);
    const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
    const [visibleCreate, setVisibleCreate] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [reRequest, setReRequest] = useState<boolean>(false);
    const [tableOption, setTableOption] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [text, setText] = useState<string>('')
    const [faculty_id, setFacultyId] = useState<string | number>('')

    const debouncedValue = useDebounce<string>(text, 500)

    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true)
                    const response = await getAllLecterns(perPage, currentPage, `expand=description&q=${debouncedValue}&filter={"faculty_id":${faculty_id}}`);
                    setData(response?.items)
                    setTableOption(response?._meta)
                    setLoading(false);
                } catch (error: any) {
                    asyncN('error', 'read', error?.response?.message)
                }
            }
        )()
    }, [i18n.language, reRequest, perPage, currentPage, debouncedValue, faculty_id])


    useEffect(() => {
        (
            async () => {
                const response = await getAllFaculties();
                setFaculties(response?.items)
            }
        )()
    }, []);

    return (
        <div>
            <ForTitle title={"Lecterns"} buttons={[isHasAuthRoute('kafedra_create') && <ButtonCreate onClick={() => setVisibleCreate(true)} />]}/>
            <Row gutter={[12, 8]}>
                <Col xl={{ span: 4 }} lg={{ span: 6 }} md={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 24 }}>
                    <Select className="w-100" placeholder={t("Select faculty")} onChange={(e: number | string) => setFacultyId(e)} allowClear>
                        {faculties && faculties.length ? faculties.map((e: any) => {
                            return <Option key={e.id} value={e.id}>{e.name}</Option>
                        }) : null}
                    </Select>
                </Col>
                <Col className="d-flex justify-content-between" xl={{ span: 6, offset: 14 }} lg={{ span: 8, offset: 10 }} md={{ span: 10, offset: 4 }} sm={{ span: 14 }} xs={{ span: 24 }}>
                    <Search placeholder={t("Input search text ...")} value={text} onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)} />
                </Col>
            </Row>
            { visibleCreate ? <CreateLectern visible={visibleCreate} setVisible={setVisibleCreate} setReFetch={setReRequest} /> : null }
            { visibleView && id ? <LecternView visible={visibleView} setVisible={setVisibleView} id={id} /> : null }
            { visibleEdit && id ? <EditLectern visible={visibleEdit} setVisible={setVisibleEdit} id={id} setReRequest={setReRequest} reRequest={reRequest} /> : null }
            <ForTable 
            setCurrentPage={setCurrentPage}
            totalCount={tableOption?.totalCount}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
            loading={loading} datas={data} theads={
                    [
                        <>{t("Name")}</>,
                        <>{t("Description")}</>,
                        <>{t("Employees")}</>,
                        <>{t("Status")}</>,
                        <>{t("Actions")}</>,
                    ]
                } tbodys={
                    function(this: any){
                        return [
                            this.name,
                            <DiscriptionStyle>{this?.description}</DiscriptionStyle>,
                            <Link to={{
                                pathname: "/lectern-employees/"+this?.id,
                                state: {
                                    lectern: this
                                }
                            }}>
                                {t("Edit")}
                            </Link>,
                            <><BadgeStatus status={this.status} /></>,
                            <>
                                <Actions
                                    id={this?.id}
                                    url="/kafedras"
                                    setId={setId}
                                    setReFetch={setReRequest}
                                    setVisibleView={setVisibleView}
                                    setVisibleEdit={setVisibleEdit}
                                    viewPermission="kafedra_view"
                                    editPermission="kafedra_update"
                                    deletePermission="kafedra_delete"
                                />
                            </>
                        ]
                }}/>
        </div>
    )
}

export default Lectern;
