import instance from "config/_axios"
import { asyncN } from "utils/notifications"


export const getAllQuestions= async (currentPage?:number,perPage?:number,prefix?:string)=>{

    try{
        const resp=await instance({
            method:'get',
            url:`/questions?${currentPage?'page='+currentPage:'page=1'}&per-page=${perPage!=undefined?perPage:0}&${prefix?prefix:''}`
        })

        if(resp?.data?.status===1){
            return resp?.data?.data
        }else{
            asyncN("error","read",resp?.data?.message)
        }
    }catch(e:any){
        asyncN("error","read","Error for get questions!!!")
    }
}

export const getOneQuestions= async (id:number,prefix?:string)=>{
    try{
        const resp=await instance({
            method:'get',
            url:`/questions/${id}?${prefix?prefix:''}`
        })

        if(resp?.data?.status===1){
            return resp?.data?.data
        }else{
            asyncN("error","read",resp?.data?.message)
        }
    }catch(e:any){
        asyncN("error","read","Error for get questions!!!")
    }
}

export const getAllQuestionOptions= async (id:number,prefix?:string)=>{
    try{
        const response=await instance({
            method:'get',
            url:`/question-options?filter={"question_id":${id}}`
        })
        //?filter={question_id:${id}}
        if(response?.data?.status===1){
            return response?.data?.data
        }else{
            asyncN("error","read",response?.data?.message)
        }
    }catch(e:any){
        asyncN("error","read","Error for question options!!!")
    }
}