import { Row, Col, Select, FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";
import StudentCertficates from "../certficates";


type PropsTypeSportInfo = {
    form: FormInstance<any>
}

const SportInfos: React.FC<PropsTypeSportInfo> = ({ form }) => {

    return (
        <Row gutter={[12, 0]}>
            <Col span={24}>
                <StudentCertficates form={form} name='sport_sertficate' label='Sport sertficate' />
            </Col>
            <Col span={24}>
                <StudentCertficates form={form} name='olimpic_sertficate' label='Olimpic sertficate' />
            </Col>
            <Col span={24}>
                <StudentCertficates isInput={true} form={form} name='other_sertficate' label='Other sertficate' />
            </Col>
        </Row>
    )

}


export default SportInfos;
