import React, { FC, useEffect, useState } from 'react';
import { Form, Row, Col, Select, Button, Switch, Modal } from 'antd';
import instance from 'config/_axios';
import { asyncN } from 'utils/notifications';
import { ServerErrors } from 'utils/validationFunctions';
import { useTranslation } from 'react-i18next';
import ModalTitle from 'components/ModalTitle';
import MultipleInput from 'components/MultipleInput';
import { getAllFaculties } from 'pages/faculty/service';

type CreateDirectionType = {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setReFetch: React.Dispatch<React.SetStateAction<boolean>>,
}

const { Option } = Select;

const CreateDirection: FC<CreateDirectionType> = ({ visible, setVisible, setReFetch }): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [faculties, setFaculties] = useState<Array<any>>([])
    const [clicked, setClicked] = useState<boolean>(false)
    const [status, setStatus] = useState<boolean>(true)


    const onSubmit = async (values: any) => {
        try {
            setClicked(true)
            const formdata = new FormData();
            for (const [key, value] of Object.entries(values)) {
                if (key === 'status') {
                    formdata.append(key, status ? '1' : '0');
                } else {
                    formdata.append(key, String(value));
                }
            }

            const response = await instance({ url: '/directions', method: 'POST', data: formdata })

            if (response.data.status === 1) {
                asyncN("success", 'create', response.data?.message)
                    .then(() => { resetFields(); setVisible(false); setReFetch(propState => !propState) })
            } else {
                asyncN("error", 'update', response.data?.message)
                    .then(() => resetFields())
            }
            setClicked(false)
        } catch (error: any) {
            if (error?.response?.status === 422) {
                if (error?.response?.data?.status === 0) {
                    form.setFields(ServerErrors(error?.response?.data?.errors[0]))
                }
            }
            asyncN("error", 'create', error?.response?.message)
            setClicked(false)
        }
    }

    useEffect(() => {
        (
            async () => {
                const resp=await getAllFaculties(0);
                setFaculties(resp?.items)
            }
        )()
    }, [])

    const resetFields = () => form.resetFields();


    return (
        <Modal
            title={'Direction create'}
            visible={visible}
            onCancel={() => { setVisible(false); resetFields() }}
            width={800}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    status: true
                }}
            >
                <Row gutter={[12, 0]}>
                    <Col span={24} className="mb-3">
                        <MultipleInput layout='vertical' />
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} sm={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }}>
                        <Form.Item
                            label={t("Texnikum")}
                            name="faculty_id"
                            rules={[{ required: true, message: t("Select a faculty") + "!" }]}
                        >
                            <Select
                                showSearch
                                placeholder={t("Select a faculty") + " ..."}
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    faculties.length ? faculties.map((e: any, i: number) => {
                                        return <Option key={i} value={e.id}>{e.name}</Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name="status" className='mb-0' >
                            <Switch checkedChildren="Active" unCheckedChildren="InActive" checked={status} onChange={() => setStatus(!status)} />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="text-end">
                        <Button type="primary" danger htmlType="button" className="me-2" onClick={() => { setVisible(false); resetFields() }}>{t("Cancel")}</Button>
                        <Button htmlType="reset" className="me-2" onClick={() => { resetFields() }}>{t("Reset")}</Button>
                        <Button type="primary" htmlType="submit" disabled={clicked}>{t("Submit")}</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}


export default CreateDirection;
