
import { ChangeEvent, FC } from "react";
import { Input, Row, Tag } from "antd";
import isHasAuthRoute from "utils/isHasRoute";
import useDebounce from "hooks/useDebounce";
import InputMask from 'react-input-mask';
import useGetAllData from "hooks/useGetAllData";
import useDeleteOne from "hooks/useDeleteOne";
import { StatusData } from "components/Status";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import TableTh from "components/Table/TableTh";
import DormitoryDocumentsStudent from "./detail";
import { useAppDispatch } from "store/services";
import { STUDENT_ACTIONS } from "store/student";
import "../styles.scss";
import ForTable from "components/ForTable";
import ForTitle from "components/ForTitles";
import FilterSelectComponent from "components/FilterSelect";
import CustomPagination from "components/Pagination";
import { useTranslation } from "react-i18next";

const DormitoryStudents: FC = (): JSX.Element => {
    const dispatch: any = useAppDispatch();
    const { value, writeToUrl } = useUrlQueryParams({});
    const {t} = useTranslation()

    const debouncedLastName = useDebounce<string>(value.filter_like?.last_name, 500);
    const debouncedFirstName = useDebounce<string>(value.filter_like?.first_name, 500);
    const debouncedMiddleName = useDebounce<string>(value.filter_like?.middle_name, 500);
    const debouncedUsername = useDebounce<string>(value.filter_like?.username, 500);
    const debouncedPassportSeria = useDebounce<string>(value.filter_like?.passport_seria, 500);
    const debouncedPassportNumber = useDebounce<string>(value.filter_like?.passport_number, 500);
    const debouncedPassportPin = useDebounce<string>(value.filter_like?.passport_pin, 500);
    const debouncedFacultyId = useDebounce<string | number | undefined>(value.filter?.faculty_id, 500);
    const debouncedCourseId = useDebounce<string | number | undefined>(value.filter?.course_id, 500);

    const _delete = useDeleteOne();
    const students = useGetAllData({
        url: `/hostel-apps?sort=-id&expand=profile&filter=${JSON.stringify(value.filter)}&filter-like=${JSON.stringify(value.filter_like)}`,
        isCall: 'auto',
        perPage: value.perPage,
        currentPage: value.currentPage,
        refetch: [
            debouncedLastName,
            debouncedFirstName,
            debouncedPassportPin,
            debouncedMiddleName,
            debouncedUsername,
            debouncedPassportSeria,
            debouncedPassportNumber,
            _delete.refetch,
            debouncedFacultyId,
            debouncedCourseId
        ]
    });

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        writeToUrl({ name, value });
    }

    return (
        <div>
            <ForTitle title="Students" buttons={[null]} />
            <DormitoryDocumentsStudent />
            <Row gutter={[12, 12]} className="mb-2">
                <FilterSelectComponent label="faculty" name="faculty_id" permission="faculty_index" url="faculties" />
                <FilterSelectComponent label="course" name="course_id" permission="course_index" url="courses" />
            </Row>
            <ForTable toUrl={true} currentPage={value.currentPage} perPage={value.perPage} totalCount={students.data._meta.totalCount} setCurrentPage={() => {}} setPerPage={() => {}} loading={students.loading} datas={students.data.items} theads={
                [
                    <>
                        <>Familiya</>
                    </>,
                    <>
                        <>Ism</>
                    </>,
                    <>
                        <>Sharif</>
                    </>,
                    <>
                        <>Passport seria va raqam</>
                    </>,
                    <>
                        {t('SHRJ')}
                    </>,
                    <>{t('Phone')}</>,
                    <>{t('Ball')}</>,
                    <>{t('Status')}</>,
                ]
            } searchInputs={[
                {
                    index: 1,
                    input: 
                    <Input name="last_name" placeholder="Search by last name" value={value.filter_like?.last_name} onChange={handleSearch} allowClear />
                },
                {
                    index: 2,
                    input: 
                        <Input name="first_name" placeholder="Search by first name" value={value.filter_like?.first_name} onChange={handleSearch} allowClear />
                },
                {
                    index: 3,
                    input: 
                        <Input name="middle_name" placeholder="Search by middle name" value={value.filter_like?.middle_name} onChange={handleSearch} allowClear />
                },
                {
                    index: 4,
                    input: 
                        <div className="d-flex w-100">
                            <span className="ant-input-affix-wrapper wp-25">
                                <InputMask mask="aa" placeholder="AA" className='ant-input' name="passport_seria" value={value.filter_like?.passport_seria} onChange={handleSearch} style={{ width: "50px", borderRight: "none" }} />
                            </span>
                            <span className="ant-input-affix-wrapper wp-75">
                                <InputMask mask="9999999" placeholder="9999999" className='ant-input' name="passport_number" value={value.filter_like?.passport_number} onChange={handleSearch} style={{ width: "100%" }} />
                            </span>
                        </div>
                },
                {
                    index: 5,
                    input: 
                        <Input name="passport_pin" placeholder="Search by SHRJ" value={value.filter_like?.passport_pin} onChange={handleSearch} allowClear />
                }
            ]}
            tbodys={
                function (this: any) {
                    return [
                        <div className="" style={{ color: '#4B4E52', fontSize: '13px' }}  >
                            {isHasAuthRoute("student_view") ? <span onClick={() => dispatch(STUDENT_ACTIONS.openModal({ modal_name: 'dormitory', petition_id: this.id }))} className="d-block fio_link">{this?.profile?.last_name}</span> : <>{this?.profile?.last_name}</>}
                        </div>,
                        <div className="table_body_c">
                            {isHasAuthRoute("student_view") ? <span onClick={() => dispatch(STUDENT_ACTIONS.openModal({ modal_name: 'dormitory', petition_id: this.id }))} className="d-block fio_link">{this?.profile?.first_name}</span> : <>{this?.profile?.first_name}</>}
                        </div>,
                        <div className="table_body_c">
                            {isHasAuthRoute("student_view") ? <span onClick={() => dispatch(STUDENT_ACTIONS.openModal({ modal_name: 'dormitory', petition_id: this.id }))} className="d-block fio_link">{this?.profile?.middle_name}</span> : <>{this?.profile?.middle_name}</>}
                        </div>,
                        <div className="table_body_c">{this?.profile?.passport_seria}&nbsp;{this?.profile?.passport_number}</div>,
                        <div className="select__pin" style={{ color: '#4B4E52', fontSize: '13px' }} >{this?.profile?.passport_pin}</div>,
                        <div className="select__pin" style={{ color: '#4B4E52', fontSize: '13px' }} >{this?.profile?.phone}</div>,
                        <div className="select__pin" style={{ color: '#4B4E52', fontSize: '13px' }} >{this?.ball ?? <Tag color="blue"></Tag>}</div>,
                        <StatusData status={this?.status} />,
                    ]
                }
            } />
        </div>
    )
}

export default DormitoryStudents;



/**


hostel-app_create
hostel-app_delete
hostel-app_index
hostel-app_update
hostel-app_view
hostel-category-type_create
hostel-category-type_delete
hostel-category-type_index
hostel-category-type_update
hostel-category-type_view
hostel-category_create
hostel-category_delete
hostel-category_index
hostel-category_update
hostel-category_view
hostel-doc_check
hostel-doc_create
hostel-doc_delete
hostel-doc_index
hostel-doc_not
hostel-doc_update
hostel-doc_view


 */