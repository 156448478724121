export const encodeKey = (key: string): string => {
    if (Boolean(Number(key))) {
        let a: string = ""
        for (let i = 0; i < key?.length; i++) {
            a += String.fromCharCode(Number(key[i]) + 97)
        }
        return a;
    } else {
        return "no encode";
    }
}

export const decodeKey = (key: string): string => {
    let a: string = ""
    for (let i = 0; i < key?.length; i++) {
        a += String(Number(key.charCodeAt(i) - 97));
    }

    return a;
}

export const encodeWithStr = (key: string, charAt: string): string => {
    let str: Array<string> = key?.split(charAt);
    console.log(str);
    
    let resp: string = "";
    str?.forEach((item: string, i: number) => {
        console.log(item);
        
        resp += `${encodeKey(item)}${i !== str.length - 1 ? charAt : ''}`
    })

    return resp;
}