import React from "react";
import { Button, Col, List, message, Row, Select } from "antd";
import { BiPlus, BiX } from "react-icons/bi";
import FormLabelCrud from "components/FormLabel/FormLabelCrud";
import useGetAllData, { TypeReturnUseGetAllData } from "hooks/useGetAllData";
import { UserAccessType } from "models/user";
import { useLocation } from "react-router-dom";
import useStoreData, { ReturnTypeUseStoreData } from "hooks/useStoreData";
import { IFaculty } from "models/others";
import { cf_filterOption } from "utils/auxiliary_functions";

type UserPositionPropsInformer = {
    userAccess: Record<string, string[]>,
    setUserAccess: React.Dispatch<React.SetStateAction<Record<string, string[]>>>
}

const NewUserAccess = ({ userAccess, setUserAccess }: UserPositionPropsInformer) => {

    const location = useLocation();

    const [userAccessTypeId, setUserAccessTypeId] = React.useState<number>();
    const [departmentId, setDepartmentId] = React.useState<number>();
    const [isLeader, setIsLeader] = React.useState<string>('0');

    const [dataList, setDataList] = React.useState<Array<{ key: string, office: string, department: string, position: string }>>([]);


    const access_types = useGetAllData({ url: '/user-access-types', isCall: 'auto', perPage: 0 }) as TypeReturnUseGetAllData<UserAccessType>;
    const departments = useGetAllData({ url: `/${access_types.items?.find(e => e.id === userAccessTypeId)?.url ?? ''}`, perPage: 0 });

    const faculties = useStoreData({ key: 'faculties' }) as ReturnTypeUseStoreData<IFaculty>;
    const kafedra = useStoreData({ key: 'kafedra', params:{"per-page":0} }) as ReturnTypeUseStoreData<IFaculty>;
    const department = useStoreData({ key: 'department', params:{"per-page":0} }) as ReturnTypeUseStoreData<IFaculty>;

    const selectItem = (id: string) => {

        switch (id) {
            case '1':
                return faculties.items
            case '2':
                return kafedra.items
            case '3':
                return department.items
        }
    }

    const addUserPosition = () => {

        const fieldKey = String(userAccessTypeId);
        const fieldValue = `${departmentId}-${isLeader}`;

        // const isHasError = Array.isArray(userPositions[fieldKey]) && (isLeader === '1' && userPositions[fieldKey].some(e => e.includes('1')) || userPositions[fieldKey].includes(fieldValue));
        const isHasError = Array.isArray(userAccess[fieldKey]) && userAccess[fieldKey].includes(fieldValue);

        if (!isHasError) {

            setUserAccess(prevState => ({
                ...prevState,
                [fieldKey]: userAccess.hasOwnProperty(fieldKey) ?
                    [...prevState[fieldKey], fieldValue] :
                    [fieldValue]
            }));
            setUserAccessTypeId(undefined);
            setDepartmentId(undefined);
            setIsLeader('0');
        } else {
            message.warning("Mavjud lavozim kiritilmoqda !");
        }
    }

    function removeUserPosition(key: string) {

        const ids = key.split('-');

        if (userAccess[ids[0]].length > 1) {
            setUserAccess(prevState => ({ ...prevState, [ids[0]]: prevState[ids[0]].filter(e => e !== `${ids[1]}-${ids[2]}`) }))
        } else {
            let newUserPositions = userAccess;
            delete newUserPositions[ids[0]]
            if (!Object.getOwnPropertyNames(newUserPositions).length) {
                setUserAccess({})
            } else {
                setUserAccess(newUserPositions);
            }
        }

        setDataList(prevState => ([...prevState.filter(e => e.key !== key)]));
    }

    React.useEffect(() => {

        if (access_types.items.length && Object.keys(userAccess).length) {
            let newDataList: Array<{ key: string, office: string, department: string, position: string }> = [];

            Object.keys(userAccess).forEach((key) => {

                const element = userAccess[key];
                const items = selectItem(key);
                const office = access_types.items.find(e => e.id === Number(key))?.name ?? '';

                element.forEach((item) => {

                    const [departmentId, isLeader] = item.split('-');
                    const department = items?.find(e => e.id === Number(departmentId))?.name ?? '';
                    const position = Number(isLeader) ? "Boshliq" : "Xodim";

                    newDataList.push({ key: `${key}-${departmentId}-${isLeader}`, office, department, position })
                })
            })

            setDataList(newDataList);
        }
    }, [userAccess])


    return (
        <>
            <Row gutter={[12, 0]}>
                <Col xl={7} lg={7} md={12} sm={24} xs={24}>
                    <FormLabelCrud>Tarkibiy tuzilma</FormLabelCrud>
                    <Select
                        aria-required={true}
                        className="w-100"
                        onChange={(itemId) => setUserAccessTypeId(itemId)}
                        allowClear
                        value={userAccessTypeId}
                    >
                        {
                            access_types.items.length ?
                                access_types.items.map((element) => <Select.Option key={element.id} value={element.id} >{element.name}</Select.Option>) : null
                        }
                    </Select>
                </Col>
                <Col xl={7} lg={7} md={12} sm={24} xs={24}>
                    <FormLabelCrud>Boshqarma, markaz, bo'lim</FormLabelCrud>
                    <Select
                        aria-required={true}
                        className="w-100"
                        onChange={(itemId) => setDepartmentId(itemId)}
                        onFocus={() => departments.fetch()}
                        disabled={!userAccessTypeId}
                        value={departmentId}
                        showSearch
                        filterOption={cf_filterOption}
                    >
                        {
                            departments.items.length ?
                                departments.items.map((element) => <Select.Option key={element.id} value={element.id} >{element.name}</Select.Option>) : null
                        }
                    </Select>
                </Col>
                <Col xl={7} lg={7} md={12} sm={24} xs={24}>
                    <FormLabelCrud>Xodim yoki boshliq</FormLabelCrud>
                    <Select
                        className="w-100"
                        onChange={(itemId) => setIsLeader(itemId)}
                        value={isLeader}
                    >
                        <Select.Option value="0" >Xodim</Select.Option>
                        <Select.Option value="1" >Boshliq</Select.Option>
                    </Select>
                </Col>
                <Col xl={3} lg={3} md={12} sm={24} xs={24} className="text-end">
                    <Button
                        type="primary"
                        size="small"
                        className="w_70"
                        style={{ marginTop: "26px" }}
                        disabled={!departmentId}
                        onClick={addUserPosition}
                    >
                        <BiPlus size={20} />
                    </Button>
                </Col>
            </Row>
                <table className="table table-bordered w-100 mt-3 mb-1">
                    <tbody>
                        {
                            dataList?.map((element) => {
                                return (
                                    <tr key={element.key}>
                                        <td className="px-2 py-0">{element.office}</td>
                                        <td className="px-2 py-0">{element.department}</td>
                                        <td className="px-2 py-0">{element.position}</td>
                                        <td className="text-center w_70 py-0"><Button type="link" size="small" danger onClick={() => removeUserPosition(element.key)}><BiX size={18} /></Button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
        </>
    )

}



export default NewUserAccess;