import { Button, Col, Form, Row, Spin, Tag } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import { FILE_URL } from "config/utils";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaFile,
  FaFilm,
  FaImage,
  FaMusic,
  FaTextWidth,
} from "react-icons/fa";
import { TbFilePencil } from "react-icons/tb";
import { TbFileOff } from "react-icons/tb";
import { TbFileInvoice } from "react-icons/tb";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useAppSelector } from "store/services";
import {
  getOneSubjectContent,
  getSubjectContentTypes,
  saveOrEditSubjectContent,
} from "../service";
import { Displaying } from "./displayType";
import styles from "./style.module.scss";

const SubjectContentEdit: FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const auth: any = useAppSelector(state => state.auth);
  const params: any = useRouteMatch("/subjects/:subjectId/:teacherId/topics/:topicId/content-edit/:id")?.params
  const history = useHistory();
  const location: string = useLocation()?.pathname;
  const [content, setContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fileFile, setFileFile] = useState<any>({});
  const [types, setTypes] = useState<any>();
  const [item, setItem] = useState<{
    id: number;
    type: string;
    form: any;
    maxSize?: number;
    acceptTypes?: string;
  }>();
  const [fileUrl, setFileUrl] = useState<any>({});
  const [data, setData] = useState<any>()

  const _breadcrump = auth?.user?.role?.length === 1 && auth?.user?.role[0] === "teacher" ? [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Subjects",
      path: "subjects",
    },
    {
      name: "Subject topics",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
    },
    {
      name: "Subject contents",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/contents`,
    },
    {
      name: "Subject content edit",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${params?.id}`,
    }
  ] : [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Subjects",
      path: "subjects",
    },
    {
      name: "Subject teachers",
      path: `/subjects/${params?.subjectId}/teachers`,
    },
    {
      name: "Subject topics",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
    },
    {
      name: "Subject contents",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/contents`,
    },
    {
      name: "Subject content create",
      path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/content-edit/${params?.id}`
    }
  ];

  useEffect(() => {
    (async () => {
      const respT = await getSubjectContentTypes();
      const resp = await getOneSubjectContent(
        Number(params?.id),
        "expand=subjectTopic,subject,lang,updatedBy,createdBy"
      );
      form.setFieldsValue({
        "0-status": resp?.status,
        "0-description": resp?.description,
      });
      setContent(resp?.content);
      setData(resp)
      addContentByType(respT[`${resp?.type}`][0], respT);
      if (resp?.file_url) {
        setFileUrl({
          [`0-${respT[`${resp?.type}`][0]?.toLowerCase()}`]:
            FILE_URL + resp?.file_url,
        });
        if (respT[`${resp?.type}`][0] === "FILE") {
          let a: Array<string> = resp?.file_url?.split(".");
          setFileFile({
            [`0-file`]: a[a.length - 1],
          });
        }
      }
    })();
  }, [params?.id]);

  const addContentByType = async (
    type: "TEXT" | "FILE" | "IMAGE" | "VIDEO" | "AUDIO",
    fileTypes?: any
  ) => {
    if (type === "TEXT") {
      setItem({
        id: 0,
        type: type,
        form: form,
      });
    } else {
      let a: any = Object.entries(fileTypes ? fileTypes : types)?.filter(
        ([key, value]: any) => value.includes(type)
      )["0"];
      setItem({
        id: 0,
        type: type,
        form: form,
        maxSize: Number(a[1][1]),
        acceptTypes: a[1][2],
      });
    }
    if (fileTypes && !types) {
      setTypes(fileTypes);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true)
    let data = new FormData();
    Object.entries(values)?.map(([key, value]: any, i: number) => {
      key = key?.split("-")[1];
      key as string;
      if (value) {
        if (String(key)?.startsWith("file_") && value?.file) {
          data.append(key, value?.file?.originFileObj);
        } else if (key === "status") {
          data.append(key, value ? "1" : "0");
        } else if (key === "text") {
          data.append("content", value?.toString())
        } else {
          data.append(key, value);
        }
      }
    });
    data.append("subject_topic_id", params?.topicId);
    const res = await saveOrEditSubjectContent(data, Number(params?.id));
    if (res === 1) {
      history.push(`/subjects/${params?.subjectId}/${params?.teacherId}/topics/${params?.topicId}/contents`);
    }
    setLoading(false)
  };

  const removeParentItem = () => {
    setItem(undefined);
    form.resetFields()
  };

  return (
    <Spin spinning={loading}>
      <CustomBreadcrumb arr={_breadcrump} />
      <Row gutter={24}>
        <Col span={24}>
          <div className={styles.content}>
            {" "}
            <div className={styles.content_header}>
              <button
                type="button"
                className={styles.add_btn}
                onClick={() => addContentByType("TEXT")}
              >
                <FaTextWidth /> text
              </button>
              <button
                type="button"
                className={styles.add_btn}
                onClick={() => addContentByType("IMAGE")}
              >
                <FaImage /> image
              </button>
              <button
                type="button"
                className={styles.add_btn}
                onClick={() => addContentByType("VIDEO")}
              >
                <FaFilm /> video
              </button>
              <button
                type="button"
                className={styles.add_btn}
                onClick={() => addContentByType("AUDIO")}
              >
                <FaMusic /> audio
              </button>
              <button
                type="button"
                className={styles.add_btn}
                onClick={() => addContentByType("FILE")}
              >
                <FaFile /> file
              </button>
            </div>
            <div
              className="all_card_box"
              style={{
                maxHeight: window.innerHeight * 0.8 + "px",
                overflow: "auto",
                width: "100%",
              }}
            >
              {item && <Row style={{ margin: 0 }} gutter={24}>
                <Col span={24}>
                  <Form
                    onFinish={onFinish}
                    className="p-1"
                    name={`form-${item?.id}`}
                    layout="vertical"
                  >
                    <div className={styles.uploading_card}>
                      <div className={styles.uploading_card_header}>
                        <div>
                          <Tag style={{ padding: "6px 12px" }}>
                            {item?.type}
                          </Tag>
                        </div>
                        <div>
                          {/* <button disabled={i === 0 ? true : false}><FaArrowUp /></button>
                                                        <button disabled={i === forms.length - 1 ? true : false}><FaArrowDown /></button> */}
                          <button type="button" onClick={() => removeParentItem()}>
                            <TbFileOff />
                          </button>
                        </div>
                      </div>
                      <div>
                        {item && (
                          <Displaying
                            maxSize={item?.maxSize ? item?.maxSize : 0}
                            accept={
                              item?.acceptTypes
                                ? "." + item?.acceptTypes.replaceAll(",", ",.")
                                : ""
                            }
                            typeFile={item?.type}
                            urlFile={fileUrl}
                            setFileUrl={setFileUrl}
                            id={item?.id}
                            setFileFile={setFileFile}
                            fileFile={fileFile}
                            item={data}
                          />
                        )}
                      </div>
                    </div>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Button style={{ float: "right" }} type="primary" className="m-1" htmlType="submit">Submit</Button>
                        <Button style={{ float: "right" }} className="m-1" htmlType="reset">Reset</Button>
                        <Button style={{ float: "right" }} className="m-1" danger onClick={() => history.goBack()}>Cancel</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>}
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default SubjectContentEdit;
