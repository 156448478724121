import { Button, Col, DatePicker, Divider, Form, Input, message, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import MultipleInput from "components/MultipleInput";
import instance from "config/_axios";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllLanguages } from "services";
import { asyncN } from "utils/notifications";

const HolidayEdit: FC<{ id: number | undefined, refresh: boolean, visible: boolean, setRefresh: (refresh: boolean) => void, setVisible: (visible: boolean) => void }> = ({ id, refresh, visible, setRefresh, setVisible }): JSX.Element => {
  const [form] = useForm();
  const { t, i18n } = useTranslation();

  const getHoliday = async () => {
    try {
      const resp = await instance({
        method: "get",
        url: `/holidays/${id}?expand=description`
      })

      if (resp.data?.status === 1) {
        const data: any = resp?.data?.data;
        console.log(data);

        form.setFieldsValue({
          name: data?.name,
          description: data?.description,
          start_date: moment(data?.start_date),
          finish_date: moment(data?.finish_date),
        })
      } else {
        asyncN("error", 'update', resp.data?.message).then(() => form.resetFields())
      }
    } catch (error) {
      message.warning("Ma'lumot yetarli emas !");
    }
  }

  useEffect(()=>{
    if (id && visible) {
      getHoliday()
    }
  },[visible])


  const onFinish = async (values: any) => {
    let formdata = new FormData();

    Object.entries(values)?.map(([key, value]: any) => {
      if (key === 'start_date' || key === 'finish_date') {
        formdata.append(key, moment(value).format('YYYY-MM-DD'));
      } else
      formdata.append(`${key}[${i18n.language}]`, value)
    })


    try {
      const resp = await instance({
        url: `/holidays/${id}?expand=description`,
        method: "put",
        data: formdata
      })

      if (resp.data?.status === 1) {
        asyncN("success", 'create', resp.data?.message).then(() => { form.resetFields(); })
        form.resetFields()
        setVisible(false)
        setRefresh(!refresh);
      } else {
        asyncN("error", 'update', resp.data?.message).then(() => form.resetFields())
      }
    } catch (err) {
      message.warning("Ma'lumot yetarli emas !");
    }
  }

  return (
    <Modal title={t("Holiday edit")} visible={visible} onCancel={() => setVisible(false)} footer={false} width={600}>
      <Form
        form={form}
        layout='vertical'
        className=""
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={t("Name")}
              name={`name`}
              // shouldUpdate
              rules={[{ required: true, message: `Please input name ...` }]}
            >
              <Input placeholder={t("Enter the name")+ ' ... '} />
            </Form.Item>

          </Col>
          <Col span={24}>
            <Form.Item
              label={t("Description")}
              name={`description`}
              // shouldUpdate
              rules={[{ required: false, message: `Please input description ...` }]}
            >
              <Input.TextArea rows={1} placeholder={`${t("Enter the description")} ...`} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("Start date")}
              name={`start_date`}
              rules={[{
                required: true,
                message: t("fill in the feilds")
              }]}
            >
              <DatePicker className="w-100" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("End date")}
              name={`finish_date`}
              rules={[{
                required: true,
                message: t("fill in the feilds")
              }]}
            >
              <DatePicker className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-end">
          <Col>
            <Button type="primary" danger onClick={() => { setVisible(false); form.resetFields() }} >{t("Cancel")}</Button>
            <Button htmlType="submit" type="primary" className="ms-2" >{t("Save")}</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default HolidayEdit