import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const SubjectCategory: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="subject-categories"
                indexTitle="Subject category"
                createTitle="Subject category create"
                editTitle="Subject category edit"
                viewTitle="Subject category view"
                permissions={{
                    view_: "subject-category_view",
                    delete_: "subject-category_delete",
                    update_: "subject-category_update",
                    create_: "subject-category_create"
                }}
            />
        </div>
    )
}

export default SubjectCategory;