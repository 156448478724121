import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const SubjectType: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="subject-types"
                indexTitle="Subject type"
                createTitle="Subject type create"
                editTitle="Subject type edit"
                viewTitle="Subject type view"
                permissions={{
                    view_: "subject-type_view",
                    delete_: "subject-type_delete",
                    update_: "subject-type_update",
                    create_: "subject-type_create"
                }}
            />
        </div>
    )
}

export default SubjectType;