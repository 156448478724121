import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Switch } from "antd";
import { Option } from "antd/es/mentions";
import instance from "config/_axios";
import { asyncN } from "utils/notifications";
import { getAllLanguages } from "services";
import { useTranslation } from "react-i18next";
import { getDepartmentTypes } from "../service";
import MultipleInput from "components/MultipleInput";

// const layout = {
//     labelCol: { offset: 0, span: 24 },
//     wrapperCol: { offset: 0, span: 24 },
// };

const AddDepartment = (props: any) => {
    const [form] = Form.useForm();
    const [values, setValues] = useState<any>();
    const { t } = useTranslation();
    const [typesList, setTypesList] = useState<any>({})

    useEffect(() => {
        (
            async () => {
                const types = await getDepartmentTypes();
                setTypesList(types)
            }
        )()
    }, [])

    const onFinish = async (values: any) => {
        let data = new FormData();
        Object.entries(values).map(([key, value]: any) => {
            if (value && key) {
                if (key === "status") {
                    data.append(key, value ? "1" : "0")
                } else
                    data.append(key, value)
            }
        })

        try {
            const response = await instance({
                method: 'post',
                url: '/departments',
                data: data
            })
            if (response?.data?.status === 1) {
                asyncN("success", "create", response?.data?.message).then((res) => {
                    props.setVisible(false);
                    props.setRefresh(!props.refresh);
                    form.resetFields();
                });
            } else {
                asyncN("error", "create", "Ma'lumot qo'shishda hatolik!");
                props.setVisible(false);
                props.setRefresh(!props.refresh)
            }
        } catch (e) {
            asyncN("error", "create", "Ma'lumot qo'shishda hatolik!");
            props.setRefresh(!props.refresh)
        }
    };

    return (
        <Row>
            <Form
                // {...layout}
                form={form}
                layout="vertical"
                name="advanced_search"
                className="container"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <MultipleInput layout="vertical" />
                    </Col>
                    <Col sm={24} lg={12} md={12}>
                        <Form.Item name="parent_id" label={t("Department")} rules={[{ required: false, message: ("Select a department") }]}>
                            <Select
                                placeholder={t("Select a department")}
                                allowClear
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        ["parent_id"]: e
                                    })
                                }}
                            >
                                {
                                    props?.items && props?.items.map((item: any, i: number) =>
                                        <Option key={"" + i} value={item?.id}>{item?.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} md={12}>
                        <Form.Item name="type" label={t("Tip")} rules={[{ required: true, message: t("Select a tip") }]}>
                            <Select
                                placeholder={t("Select a tip")}
                                allowClear>
                                {
                                    Object.entries(typesList).map(([key, value]: any, i: number) =>
                                        <Option key={"" + i} value={key}>{value}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} md={12}>
                        <Form.Item
                            name={`sort`}
                            label={t("Sort")}
                            rules={[
                                {
                                    required: true,
                                    message: t("Fill in the field!"),
                                },
                            ]}
                        >
                            <Input type="number" placeholder={t("Sort")} />
                        </Form.Item>
                    </Col>
                    <Col sm={24} lg={12} md={12}>
                        <Form.Item
                            name={`status`}
                            label={t`Status`}
                            rules={[
                                {
                                    required: true,
                                    message: t("Status must be active!"),
                                },
                            ]}
                        >
                            <Switch onChange={(e) => {
                                setValues({
                                    ...values,
                                    ["status"]: e
                                })
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            {t("Save")}
                        </Button>
                        <Button
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            {t("Clear")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Row>
    )
}
export default AddDepartment;
