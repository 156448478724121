import { Form } from "antd";
import CustomBreadcrumb from "components/Breadcrumb";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useAppSelector } from "store/services";
import { getSubjectIdWithUrl, saveOrEditSubjectTopic } from "../servise";
import FeildsUi from "./ui";

const layout = {
    labelCol: { offset: 0, span: 24 },
    wrapperCol: { offset: 0, span: 24 },
};

const SubjectTopicCreate: FC = (): JSX.Element => {

    const url: string = useLocation()?.pathname
    const auth: any = useAppSelector(state => state.auth);
    const params: any = useRouteMatch("/subjects/:subjectId/:teacherId/topic-create")?.params
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const history = useHistory()
    const [disabledFinish, setDisabledFinish] = useState<boolean>(false)
    const _breadcrump = auth?.user?.role?.length === 1 && auth?.user?.role[0] === "teacher" ? [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "subjects",
        },
        {
            name: "Subject topics",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
        },
        {
            name: "Subject topics create",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topic-create`,
        }
    ] : [
        {
            name: "Home",
            path: "/",
        },
        {
            name: "Subjects",
            path: "subjects",
        },
        {
            name: "Subject teachers",
            path: `/subjects/${params?.subjectId}/teachers`,
        },
        {
            name: "Subject topics",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topics`,
        },
        {
            name: "Subject topics create",
            path: `/subjects/${params?.subjectId}/${params?.teacherId}/topic-create`,
        }
    ]

    const onFinish = async (values: any) => {
        setDisabledFinish(true)
        let data = new FormData();
        Object.entries(values)?.forEach(([key, value]: any) => {
            data.append(key, value)
        })
        data?.append("subject_id", String(params?.subjectId))
        const response = await saveOrEditSubjectTopic(data)
        if (response?.status === 1) {
            history.push(`/subjects/${params?.subjectId}/${params?.teacherId}/topics`)
        }
        setDisabledFinish(false)
    }

    return (
        <div style={{ height: "100%" }}>
            <CustomBreadcrumb arr={_breadcrump} />
            <Form
                {...layout}
                form={form}
                layout="vertical"
                name="advanced_search"
                className="mt-3"
                style={{ height: "100%" }}
                onFinish={onFinish}
            >
                <FeildsUi disabledFinish={disabledFinish} />
            </Form>
        </div>
    )
}


export default SubjectTopicCreate;