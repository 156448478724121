import { FC } from "react"
import SimpleIndex from "pages/common/simple_index";

const ExamTypes: FC = (): JSX.Element => {

    return (
        <div>
            <SimpleIndex
                url="exams-types"
                indexTitle="Exam types"
                createTitle="Create exam type"
                editTitle="Exam type edit"
                viewTitle="Exam type view"
                permissions={{
                    view_: "exams-type_view",
                    delete_: "exams-type_delete",
                    update_: "exams-type_update",
                    create_: "exams-type_create"
                }}
            />
        </div>
    )
}

export default ExamTypes;