import { ChangeEvent } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip, Tag, Input, Row, Col, Select, Popconfirm, Switch } from "antd";
import isHasAuthRoute from "utils/isHasRoute";
import useGetAllData from "hooks/useGetAllData";
import useDeleteOne from "hooks/useDeleteOne";
import useDebounce from "hooks/useDebounce";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import FilterSelectComponent from "components/FilterSelect";
import ForTable from "components/ForTable";
import { TbFileInvoice, TbFileOff, TbFilePencil } from "react-icons/tb";
import { AiFillCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { cf_filterOption } from "utils/auxiliary_functions";
import ForTitle from "components/ForTitles";
import { ButtonCreate } from "components/Buttons";

const { Option } = Select;
const { Search } = Input;

const Employees = () => {

  const { i18n, t } = useTranslation();
  const { value, writeToUrl } = useUrlQueryParams({})

  const debouncedUsername = useDebounce<string>(value.filter_like.username, 500);
  const debouncedFirstName = useDebounce<string>(value.filter_like.first_name, 500);
  const debouncedLastName = useDebounce<string>(value.filter_like.last_name, 500);
  const debouncedMiddleName = useDebounce<string>(value.filter_like.middle_name, 500);
  const debouncedPassportNumber = useDebounce<string>(value.filter_like.passport_number, 500);
  const debouncedPassportPin = useDebounce<string>(value.filter_like.passport_pin, 500);
  const debouncedRegionId = useDebounce<string | number | undefined>(value.filter?.region_id, 500);
  const debouncedAreaId = useDebounce<string | number | undefined>(value.filter?.area_id, 500);
  const debouncedRoleName = useDebounce<string | number | undefined>(value.filter_like.role_name, 500);

  const filter_like = Object.keys(value.filter_like).filter(key => key !== "role_name").reduce((obj: any, key) => { obj[key] = value.filter_like[key]; return obj }, {});

  const roles = useGetAllData({ url: `/roles`, perPage: 100, isCall: 'auto' });
  const _delete = useDeleteOne()
  const employees = useGetAllData({
    url: `users?expand=profile,userAccess&sort=-id&filter=${JSON.stringify({ "-role_name": ["student"], "role_name": value.filter_like.role_name, ...value.filter })}&filter-like=${JSON.stringify(filter_like)}`,
    isCall: 'auto',
    perPage: value.perPage,
    currentPage: value.currentPage,
    refetch: [i18n.language, value.perPage, debouncedAreaId, debouncedRegionId, debouncedRoleName, value.currentPage, debouncedUsername, debouncedMiddleName, debouncedPassportNumber, debouncedPassportPin, debouncedFirstName, debouncedLastName]
  })

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (true) {
      writeToUrl({ name, value })
    }
  }

  return (
    <div>
      <ForTitle title="Employes" buttons={[isHasAuthRoute('user_create') && <NavLink to='/create_user' className="text-decoration-none"><ButtonCreate /></NavLink>]}/>
      <Row gutter={[12, 12]} className='mb-2'>
        <FilterSelectComponent label="region" name="region_id" url="regions" permission="region_index" child_names={["area_id"]} />
        <FilterSelectComponent label="area" name="area_id" url="areas" permission="area_index" parent_name="region_id" />
        <Col xs={24} sm={24} md={12} lg={8} xl={{ span: 6, offset: 6 }}>
          <Search
            allowClear
            placeholder={t("Search by passport PIN ...")}
            name='passport_pin'
            value={value.filter_like?.passport_pin}
            onChange={handleSearch}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <ForTable toUrl={true} setCurrentPage={() => {}} setPerPage={() => {}} currentPage={value.currentPage} perPage={value.perPage} totalCount={employees.data._meta.totalCount} datas={employees.data.items}
        theads={
          [
            <>
              {t("Last name")}
            </>,
            <>
              {t("First name")}
            </>,
            <>
              {t("Username")}
            </>,
            <>
              {t("Role")}
            </>,
            <>
              {t("Passport seria and number")}
            </>,
            <>{t("Status")}</>,
            <>{t("Actions")}</>
          ]
        } tbodys={
          function (this: any) {
            return [
              isHasAuthRoute("user_view") ? <Link to={`/view-user/${this?.id}`}>{this?.profile?.last_name}</Link> : this?.profile?.last_name,
              isHasAuthRoute("user_view") ? <Link to={`/view-user/${this?.id}`}>{this?.profile?.first_name}</Link> : this?.profile?.first_name,
              isHasAuthRoute("user_view") ? <Link to={`/view-user/${this?.id}`}>{this?.username}</Link> : this?.username,
              this?.role?.map((e: string, i: number) => (<Tag key={i}>{e}</Tag>)),
              <>{this?.profile?.passport_seria} {this?.profile?.passport_number} </>,
              <>
                {this.status === 0 ?
                  <Switch size="small" disabled={true} className='pending' checkedChildren='Pending...' defaultChecked={true} /> :
                  <Switch size="small" disabled={true} checkedChildren={t("Active")} unCheckedChildren={t('InActive')} defaultChecked={this?.status === 10 ? true : this.status === 5 ? false : undefined} />}
              </>,
              <div className="crud_actions_table" >
                <Tooltip placement="top" title={t("View")}>{isHasAuthRoute("user_view") &&
                  <NavLink to={{ pathname: `/view-user/${this?.id}`, state: { id: this?.id } }} > <TbFileInvoice className="view_btn" /> </NavLink>}
                </Tooltip>
                <Tooltip placement="top" title={t("Edit")}>{isHasAuthRoute("user_update") &&
                  <NavLink to={{ pathname: `/update_user/${this?.id}`, state: { id: this?.id } }}> <TbFilePencil className="edit_btn" /> </NavLink>}
                </Tooltip>
                <Tooltip placement="top" title={t("Delete")}>{isHasAuthRoute("user_delete") &&
                  <Popconfirm placement="topLeft" title={`${t("Deleted it")} ?`} okText={<AiOutlineCheckCircle />} cancelText={<AiFillCloseCircle />} onConfirm={() => _delete.fetch(`/users/${this?.id}`)}>
                    <TbFileOff className="delete_btn" />
                  </Popconfirm>}
                </Tooltip>
              </div>
            ]
          }
        } searchInputs={
          [
            {
              index: 1,
              input:
                <Input allowClear name="last_name" onChange={handleSearch} placeholder={t("Search by last name")} />
            },
            {
              index: 2,
              input:
                <Input allowClear name="first_name" onChange={handleSearch} placeholder={t("Search by first name")} />
            },
            {
              index: 3,
              input:
                <Input allowClear name="username" onChange={handleSearch} placeholder={t("Search by username")} />
            },
            {
              index: 4,
              input:
                <Select
                  className="w-100"
                  allowClear
                  placeholder={t("Filter by role")}
                  onFocus={() => !roles.items?.length && roles.fetch()}
                  value={value.filter_like.role_name}
                  onChange={(e: string) => writeToUrl({ name: "role_name", value: e })}
                  showSearch
                  filterOption={cf_filterOption}
                >
                  {roles.items?.length ? roles.items?.map((e: { id: number, name: string }, i: number) => (<Option key={i} value={e?.name} >{e?.name}</Option>)) : ''}
                </Select>
            },
            {
              index: 5,
              input:
                <Input allowClear name="passport_number" onChange={handleSearch} placeholder={t("Search by passport number")} />
            }
          ]
        } loading={employees.loading} />
    </div>
  )
}
export default Employees;
